import {MediaDirective} from "@generic/media.directive";

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProjectSingleCtrl', function ($scope, $routeParams, project, user, $rootScope, permissionAuth, routeType, packages, workscope, tender, $locale, $timeout, projectnote, $filter, $anchorScroll) {
            $scope.user = $rootScope.userDetails;

            $scope.projectId = $routeParams.id;
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.showWorkPackageSummaryModal = false;
            $scope.editNoteView = false;
            $scope.disabledCreateTenderBtnFlag = false;
            $scope.isMobile = MediaDirective.isMobile();

            $scope.getProjectDetails = function () {
                project.get({id: $routeParams.id}).$promise.then(function (data) {
                    if (data.siteDeliveryAddress) {
                        $scope.deliveryAddressComponents = [
                            data.siteDeliveryAddress.street1,
                            data.siteDeliveryAddress.street2,
                            data.siteDeliveryAddress.city,
                            data.siteDeliveryAddress.county,
                            data.siteDeliveryAddress.postcode,
                            data.siteDeliveryAddress.country
                        ].filter(component => component && component.length > 0);
                    } else {
                        $scope.deliveryAddressComponents = [];
                    }

                    return $scope.project = data;
                }).

                    // Populate user(s) data for additional details dropdowns
                    then(function (projectData) {
                        user.query({
                            limit: 200,
                            sort: 'username'
                        }).$promise.then(function (users) {
                            angular.forEach(users, function (user) {
                                if (projectData.assignedTo.filter(item => item.id === user.id).length > 0) {
                                    user.assignedToTicked = true;
                                }
                                if (projectData.responsible !== null && user.id === projectData.responsible.id) {
                                    user.responsibleTicked = true;
                                }
                                user.fullName = user.person.fullName;
                                user.iStevenButtonLabel = 'Add/remove users';
                            });
                            $scope.users = users;
                        });
                        return projectData;
                    }).

                    // Populate project status select
                    then(function (projectData) {
                        project.getProjectStatuses().$promise.then(function (projectStatuses) {
                            angular.forEach(projectStatuses, function (projectStatus) {
                                if (projectData.status !== null && projectStatus.id === projectData.status.id) {
                                    projectStatus.ticked = true;
                                }
                            });
                            $scope.projectStatuses = projectStatuses;
                        });
                    }).then(function () {
                    projectnote.query({
                        project: $routeParams.id,
                        sort: '-createdAt'
                    }).$promise.then(function (projectNotes) {
                        $scope.projectNotes = projectNotes;
                        $scope.newProjectNote = [];
                    }).then(function () {
                        $scope.formatContractValue();
                    });
                });
            };

            $scope.$on('stateTransitionComplete', function () {
                $scope.getProjectDetails();
            });

            $scope.getProjectDetails();

            $scope.closeModal = function () {
                $scope.showAssignPackage = false;
                delete $scope.estimators;
            };

            // Get packages to tender
            packages.packagesSuitableForTender({id: $routeParams.id}).$promise.then(function (data) {
                $scope.packagesToTender = data;
            });

            // Create a Tender
            $scope.toTender = {};
            $scope.createTender = function () {
                var assessments = [];
                $scope.disabledCreateTenderBtnFlag = true;
                angular.forEach($scope.toTender, function (value, key) {
                    if (value) {
                        assessments.push(key);
                    }
                });

                $scope.toTenderError = assessments.length === 0;

                if (!$scope.toTenderError) {
                    $scope.toTenderError = false;
                    tender.save({project: $scope.projectId, assessments: assessments}).$promise.then(function () {
                        $scope.getProjectDetails();
                        $scope.toTender = {};
                        $scope.disabledCreateTenderBtnFlag = false;
                    });
                } else {
                    $scope.disabledCreateTenderBtnFlag = false;
                }
            }

            $scope.saveAdditionalProjectDetails = function () {

                let contractValue = $scope.project.contractValue;

                if (contractValue && contractValue.length > 0 && contractValue.search(',') >= 0) {
                    contractValue = contractValue.replace(/,/g, '');
                }

                project.patchAdditionalProjectDetails({
                    id: $scope.projectId,
                    contractValue,
                    chaseDate: $scope.project.chaseDate,
                    tenderSubmitted: $scope.project.tenderSubmitted,
                    responsible: $scope.project.responsible,
                    assignedTo: $scope.project.assignedTo,
                    status: $scope.project.status
                }).$promise.then();

                $timeout(function () {
                    $scope.additionalDetails.$setPristine();
                }, 1000);
            };

            $scope.deleteProjectNote = function (projectNoteId, tableIndex) {
                projectnote.delete({id: projectNoteId}).$promise.then(function () {
                    $scope.projectNotes.splice(tableIndex, 1);
                });
            };

            $scope.addProjectNote = function () {
                projectnote.create({
                    project: $scope.projectId,
                    note: $scope.newProjectNote.note
                }).$promise.then(function (data) {
                    $scope.projectNotes.unshift(data);
                    $scope.newProjectNote = '';
                });
            };

            $scope.showAddNote = function () {
                $anchorScroll('noteTextArea');
            };

            $scope.workPackageSummaries = {};
            $scope.workPackageDataLoading = false;
            $scope.displayWorkPackageSummary = function (open, workPackageId) {
                // Check if mobile or desktop so tooltip positioning can be updated
                $scope.isMobile = MediaDirective.isMobile();
                if (!$scope.workPackageSummaries[workPackageId]) {
                    $scope.workPackageSummaries[workPackageId] = {open: false};
                }
                $scope.workPackageSummaries[workPackageId].open = open;
                if (open && !$scope.workPackageSummaries[workPackageId].hasOwnProperty('accepted')) {
                    $scope.workPackageDataLoading = true;
                    project.getWorkPackageSummary({
                        id: $scope.projectId,
                        workPackageId,
                    }).$promise.then(function (data) {
                        $scope.workPackageDataLoading = false;
                        data.open = $scope.workPackageSummaries[workPackageId].open;
                        $scope.workPackageSummaries[workPackageId] = data;
                    });
                }
            };

            $scope.editProjectNote = function (projectNote, tableIndex) {
                $scope.editNoteView = true;
                $scope.editingNote = angular.copy(projectNote);
                $scope.editingNoteTableIndex = tableIndex;
            };

            $scope.saveProjectNote = function () {
                projectnote.updateProjectNote({
                    id: $scope.editingNote.id,
                    note: $scope.editingNote.note
                }).$promise.then(function () {
                    $scope.projectNotes[$scope.editingNoteTableIndex].note = $scope.editingNote.note;
                    delete $scope.editingNote;
                });
            };

            $scope.cancelEditProjectNote = function () {
                delete $scope.editingNote;
            };

            $scope.getWorkPackagePopupPositionCSS = function () {
                return {
                    'left': $scope.workPackageDivLeft + 'px',
                    'top': $scope.workPackageDivTop + 'px'
                };
            };

            $scope.formatContractValue = function () {

                // The current method gets called upon page load.
                // We dont want to this function to run upon that happening due to unexpected type error
                if (!$scope.project.contractValue) {
                    return;
                }

                var filteredNumber = $filter('number')($scope.project.contractValue, 2);

                if (filteredNumber !== '') {
                    $scope.project.contractValue = $filter('number')($scope.project.contractValue, 2);
                }
            };
        });
})();
