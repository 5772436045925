angular.module('scswebappApp').config(function($provide, $httpProvider){
    // Interceptor for errors returned from Web Service
    $httpProvider.interceptors.push('notificationInterceptor');
}).factory('notificationInterceptor', function ($q, $rootScope) {
    return {
        response: function (response) {
            if(response.status == 202){
                $rootScope.$broadcast('showOrNotification', 'This may take some time. You will be notified when the job is complete.');
            }
            return response;
        }
    };
});
