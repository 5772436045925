angular.module('scswebappApp')
    .directive('applicationReview', function (
        productApplication,
        labourApplication,
        $location,
        PermPermissionStore
    ) {
        return {
            templateUrl: 'scripts/directives/application-review/application-review.html',
            restrict: 'E',
            scope: {
                applicationId: '=',
                mode: '='
            },
            link: function postLink(scope) {
                scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition(scope.mode === 'labour' ?
                    'canWriteLabourLabourApplicationReview' : 'canWriteApplicationsIncomingApplicationReview');
                scope.hasApprovePermission = PermPermissionStore.hasPermissionDefinition(scope.mode === 'labour' ?
                    'canWriteLabourApproveApplications' : 'canWriteApplicationsApproveIncomingApplications');
                scope.$emit('gbHeadAdjusted');

                scope.viewApplication = function () {
                    switch (scope.mode) {
                        case 'labour':
                            $location.path('/project-hub/labour-application/' + scope.applicationId);
                            break;
                        case 'product':
                        default:
                            $location.path('/project-hub/payment-application/' + scope.applicationId);
                    }
                };

                scope.deleteApplication = function () {
                    if (confirm('Are you sure you want to delete this Application?')) {
                        switch (scope.mode) {
                            case 'labour':
                                labourApplication.delete({id: scope.applicationId}).$promise.then(function () {
                                    scope.$emit('gbRefresh');
                                });
                                break;
                            case 'product':
                            default:
                                productApplication.delete({id: scope.applicationId}).$promise.then(function () {
                                    scope.$emit('gbRefresh');
                                });
                        }
                    }
                };

                scope.approveApplication = function () {
                    if (confirm('Are you sure you want to approve this Application?')) {
                        switch (scope.mode) {
                            case 'labour':
                                labourApplication.approveApplication({id: scope.applicationId}).$promise.then(function () {
                                    scope.$emit('gbRefresh');
                                });
                                break;
                            case 'product':
                            default:
                                productApplication.approveApplication({id: scope.applicationId}).$promise.then(function () {
                                    scope.$emit('gbRefresh');
                                });
                        }
                    }
                };
            }
        }
    });
