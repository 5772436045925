'use strict';

angular.module('scswebappApp')
    .directive('combinedSuppliersDropdown', function (fixedCostOrders) {

        return {
            template: require('./combined-suppliers-dropdown.html'),
            restrict: 'E',
            transclude: true,
            scope:{
                'model': '=?',
                'parentObject': '='
            },
            link: function postLink(scope, element, attrs) {
                scope.supplierOptions=[];

                scope.onItemClick = function(){
                    scope.$emit('supplierSelected',{
                        item: scope.selection[0],
                        element: element[0],
                        parentObject: scope.parentObject
                    });

                    scope.model = scope.selection[0];
                };

                scope.onReset = function(){
                    scope.$emit('supplierSelected',{
                        item: null,
                        element: element[0],
                        parentObject: scope.parentObject
                    });

                    scope.model = null;
                };

                fixedCostOrders.supplierOptions().$promise.then(function (data) {
                    scope.$watch('model',function(newValue, oldValue, scope){
                        if (newValue !== undefined && newValue !== null) {
                            angular.forEach(data, function (value) {
                                if (value.id === newValue || value.id === newValue.id) {
                                    value.ticked = true;
                                }
                            });
                        }
                    });
                    scope.supplierOptions = data;
                });
            }
        };
    });
