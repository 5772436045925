(function(){
	'use strict';
	/**
	 * {{ var | country }}
	 * $filter('country')( var );
	 */
	angular.module('scswebappApp')
		.filter('country', function(COUNTRIES) {
            return function(input){
                var country;
                for (var i = 0; i < COUNTRIES.length; i++) {
                    if(COUNTRIES[i].code === input){
                        country = COUNTRIES[i].name;
                        break;
                    }
                }
                return country;
            };

		});
})();