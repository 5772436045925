'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:alerts
 * @description
 * # alerts
 */
angular.module('scswebappApp')
    .directive('alerts', function (notification, $rootScope) {
        return {
            template: '<a><i class="icon-bell" ng-click="showAlerts()"><span ng-show="alerts.length > 0">{{alerts.length}}</span></i></a><div id="slide-out" class="slide-out user-alerts {{showAlert}}"><div>' +
            '<i class="icon-cancel-circled close-slideout" ng-click="showAlerts()"></i>' +
            '<h2>Notifications and Alerts</h2>' +
            '<div class="no-alerts" ng-show="alerts.length < 1"><i class="icon-thumbs-up"></i>You have no notifications</div>' +
            '<div class="alert-block">' +
            '<div ng-repeat="alert in alerts" class="alert-wrap">' +
            '<i class="icon-cancel icon-delete" ng-click="markAsRead({{alert.id}})"></i>' +
            '<h3>{{alert.name}}</h3>' +
            '<p>{{alert.createdAt | date:"dd/MM/yyyy"}}</p>' +
            '</div>' +
            '</div>' +
                '</div>' +
            '</div>',
            restrict: 'E',
            link: function postLink(scope) {
                document.body.appendChild(document.getElementById('slide-out'));

	            $rootScope.$watch('loggedIn', function(newVal){
		            if( newVal ){
			            notification.getUnread().$promise.then(function (data) {
				            scope.alerts = data;
			            });
		            }
	            });

                scope.showAlerts = function () {
                    scope.showAlert = ( scope.showAlert === 'show') ? '' : 'show';
                };

                // Mark as read
                scope.markAsRead = function(alertId){
	                notification.markAsRead({id:alertId}).$promise.then(function () {
		                angular.forEach( scope.alerts, function( value, key ){
			                if( value.id === alertId ){
				                scope.alerts.splice( key, 1 );
			                }
		                });
	                });
                };

            }
        };
    });
