(function(){
	'use strict';

	/**
	 * @ngdoc function
	 * @name scswebappApp.controller:MyaccountCtrl
	 * @description
	 * # MyaccountCtrl
	 * Controller of the scswebappApp
	 */
	angular.module('scswebappApp')
		.controller('MyaccountCtrl', function ($scope,currencies, $rootScope, user, $http, FileUploader, account, $locale) {

			$scope.data = JSON.parse( localStorage.getItem('userDetails') );

			$scope.update = function(){
				$scope.submitted = true;
                $scope.weakPasswordStrength = $scope.passwordStrength === 'weak';
				if( $scope.form.$valid && (!$scope.plainPassword || $scope.plainPassword&& $scope.passwordStrength !== 'weak') ){
					user.update( $scope.data ).$promise.then(function(data){
                        localStorage.setItem('userDetails', JSON.stringify(data) );
						$scope.submitted = false;
					})
				}
			};

            $scope.currency = currencies.getAvailableCurrencies();

            $scope.colorChanged = function(color) {
                $scope.data.colour = color;
            };

            // Password Checking
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
            $scope.passwordStrength = "Weak";

            $scope.analyze = function(value) {
                if(strongRegex.test(value)) {
                    $scope.passwordStrength = 'strong';
                } else if(mediumRegex.test(value)) {
                    $scope.passwordStrength = 'medium';
                } else {
                    $scope.passwordStrength = 'weak';
                }
            };

			// Company Branding
			account.get({id:$scope.data.account.id}).$promise.then(function(data){
				$scope.company = data;
				$scope.company.companyLogo = data.companyLogo ? data.companyLogo.file : null;
			});

			$scope.company = {};

		});
})();
