(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.assessment
	 * @description
	 * # assessment
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('account', function ( $resource, ENV ) {
			return $resource(
				ENV.apiEndpoint + '/accounts/:id', { id: '@id', shortName: '@shortName'}, {
                    updateAccount:{
                        method: 'PATCH'
                    },
                    getDashboard: {
                        url: ENV.apiEndpoint + "/dashboard"
					},
                    getNominalCodeCategories: {
                        url: ENV.apiEndpoint + "/nominalcodes/categories?onlygroups=nominalCodes_view"
                    },
                    getNominalCodeOptions: {
                        url: ENV.apiEndpoint + "/nominalcodes/options",
                        cache: true,
                        isArray: true
                    },
					saveNominalCode: {
                    	method: 'POST',
                        url: ENV.apiEndpoint + "/nominalcodes"
					},
                    saveRetentionNominalCode: {
                        method: 'POST',
                        url: ENV.apiEndpoint + "/nominalcodes/retention"
                    },
                    getIntegrations: {
                        url: ENV.apiEndpoint + "/integrations",
                        isArray: true
                    },
                    enableIntegration: {
                        method: 'POST',
                        url: ENV.apiEndpoint + "/integrations/:shortName"
                    },
                    disableIntegration: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + "/integrations/:shortName"
                    },
                    getAccountsMenuStats: {
                    	method: 'GET',
                        url: ENV.apiEndpoint + "/account/account-menu-stats"
                    }
				}
			);
		});
})();
