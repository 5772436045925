import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ChangeSupplierButtonComponent} from "./order/change-supplier-button.component";
import {ChangeSupplierModalComponent} from "./order/change-supplier-modal.component";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    providers: [{provide: '$scope', useExisting: '$rootScope'}],
    imports: [CommonModule, FormsModule, NgSelectModule],
    exports: [ChangeSupplierModalComponent, ChangeSupplierButtonComponent],
    declarations: [ChangeSupplierModalComponent, ChangeSupplierButtonComponent],
    entryComponents: [ChangeSupplierModalComponent, ChangeSupplierButtonComponent],
})
export class OrderModule
{
}
