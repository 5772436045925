'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:AssessmentComparisonCtrl
 * @description
 * # AssessmentComparisonCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
    .controller('AssessmentComparisonCtrl', function ($scope, $routeParams, assessment) {

        $scope.assessments = [];
        var sorted = false;
        var assessmentIds = $routeParams.id.split('&');

	    // For sorting array by date reversed
	    Array.prototype.sortBy = function(p) {
		    return this.slice(0).sort(function(a,b) {
			    return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
		    });
	    }

        angular.forEach(assessmentIds, function(value, key){
	        if( value.indexOf('-') > -1 ){
		        var theId = value.split('-')[0];
		        assessment.get({id: theId}).$promise.then(function(data){
			        $scope.assessments.push(data);
			        $scope.projectId = data.projectWorkPackage.project.id;
			        $scope.assessments.sortBy('createdAt').reverse();
		        });
	        }else{
		        assessment.updateAssessmentEstimates({id: value}).$promise.then(function(data){
			        $scope.assessments.push(data);
			        $scope.projectId = data.projectWorkPackage.project.id;
			        $scope.assessments.sortBy('createdAt').reverse();
		        });
	        }
        });

        $scope.sortColumns = function(){
            if( sorted ){
                $scope.assessments.reverse();
                sorted = false;
            }else{
                $scope.assessments.reverse();
                sorted = true;
            }
        }
    });
