(function(){
	'use strict';

	/**
	 * @ngdoc directive
	 * @name scswebappApp.directive:modal
	 * @description
	 *
	 * <modal></modal>
	 *
	 * SETUP:
	 *
	 // Quantity unit warning modal.
	 $scope.quantityUnitModal = {
		'text': 'Changing the quantity unit will <strong>delete all Material quantities</strong> Would you like to continue?',
		'cancelFunction': 'modalClosed()',
		'buttons': [
			{
				'text': 'Yes',
				'classes': '',
				'clickFunction': 'overwriteProduct'
			},
			{
				'text': 'Cancel',
				'classes': 'btn-red',
				'clickFunction': 'modalClosed'
			}
		]
	};
     scope.$on('overwriteProduct', function(){
        saveProduct(productForm, duplicate);
        scope.$broadcast('closeModal');
    });

     Show Modal:
     $scope.$broadcast('showModal',$scope.quantityUnitModal);

     A clickFunction of 'modalClosed' will automatically close the modal and will not $emit and event.

	 If you want to close the modal manually you can call scope.$broadcast('closeModal');
	 *
	 * # modal
	 */
	angular.module('scswebappApp')
		.directive('modal', function () {
			return {
			    template: require('./modal-directive.html'),
				restrict: 'E',
				link: function postLink(scope) {
                    scope.$on('showModal', function(event, modalData) {
						scope.modalData = modalData;
						scope.showModal = true;
						// Close Modal
						scope.closeModal = function(){
							scope.showModal = false;
						};
						scope.$on('closeModal', function(){
                            scope.modalData = null;
                            scope.closeModal();
						});
                        scope.broadcast = function(name){
                            if( name === 'closeModal' ){
                                scope.closeModal();
                            }else{
                                scope.$emit(name);
                            }
                        }
					});
				}
			};
		});
})();
