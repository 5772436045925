'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('wssSlider', function ($rootScope, workscope, $timeout) {
        return {
            template: require('./wss-slider.html'),
            restrict: 'E',
            scope: {
                ws: '=',
                wss: '='
            },
            link: {
                pre: function preLink(scope) {
                    // Calculate qty from percentage
                    var calcQtyProg = function (pc) {
                        return Math.round(((scope.ws.assessmentProductZoneQuantity.quantity / 100) * pc) * 100) / 100;
                    };
                    // Calculate percentage from qty
                    var calcPcProg = function (qty) {
                        return Math.round(qty / scope.ws.assessmentProductZoneQuantity.quantity * 100 * 100000) / 100000;
                    };

                    // Initialise slider positions
                    scope.slider = {pc: scope.wss.completion, qty: calcQtyProg(scope.wss.completion)};

                    // Progress percentage slider
                    scope.sliderOptionsPc = {
                        floor: 0,
                        ceil: 100,
                        precision: scope.wss.quantityMode ? 5 : 0,
                        step: scope.wss.quantityMode ? 0.00001 : 1,
                        translate: function (value) {
                            return value + '%';
                        },
                        disabled: (scope.ws.hasProgressGroups || scope.ws.isParentZoneMember)
                    };
                    scope.sliderOptionsQty = {
                        float: 0,
                        ceil: scope.ws.assessmentProductZoneQuantity.quantity,
                        precision: scope.wss.quantityMode ? 0 : 2,
                        step: scope.wss.quantityMode ? 1 : 0.01,
                        disabled: (scope.ws.hasProgressGroups || scope.ws.isParentZoneMember)
                    };

                    scope.toggleQuantityMode = function () {
                        // In quantity mode you want high precision, high step percentage slider, and low precision, low step quantity slider
                        // In percentage mode you high precision, high step quantity slider, and low precision, low step percentage slider
                        scope.sliderOptionsPc.step = scope.wss.quantityMode ? 0.00001 : 1;
                        scope.sliderOptionsPc.precision = scope.wss.quantityMode ? 5 : 0;
                        scope.sliderOptionsQty.step = scope.wss.quantityMode ? 1 : 0.01;
                        scope.sliderOptionsQty.precision = scope.wss.quantityMode ? 0 : 2;
                        if (scope.wss.quantityMode) {
                            console.log(scope.slider.qty);
                            // Will need to recalc percentage
                            scope.slider.pc = calcPcProg(scope.slider.qty.toFixed(0));
                            scope.wss.completion = scope.slider.pc;
                            updateWss(true);
                        } else {
                            console.log(scope.slider.pc);
                            // Will need to recalc quantity
                            scope.slider.qty = calcQtyProg(scope.slider.pc.toFixed(0));
                            // Our step has been adjusted, percentage will have changed
                            scope.wss.completion = scope.slider.pc;
                            updateWss(true);
                        }
                        $timeout(function() {
                            scope.$broadcast('rzSliderForceRender')
                        });
                    };
                },
                post: function postLink(scope) {
                    // Calculate qty from percentage
                    var calcQtyProg = function (pc) {
                        return Math.round(((scope.ws.assessmentProductZoneQuantity.quantity / 100) * pc) * 100) / 100;
                    };
                    // Calculate percentage from qty
                    var calcPcProg = function (qty) {
                        return Math.round(qty / scope.ws.assessmentProductZoneQuantity.quantity * 100 * 100000) / 100000;
                    };

                    // Do an update
                    var updateWss = function (quantityMode) {
                        workscope.patchStage({id: scope.wss.id}, {completion: scope.wss.completion, quantityMode: quantityMode ? 1 : null}, function (data) {
                            scope.ws.completion = data.workScope.completion;
                        });
                    };

                    scope.$on('slideEnded', function (data) {
                        if (scope.wss.quantityMode) {
                            // Qty slider was moved
                            scope.slider.pc = calcPcProg(scope.slider.qty);
                            scope.wss.completion = scope.slider.pc;
                            updateWss(true);
                        } else {
                            // Percentage slider was moved
                            scope.slider.qty = calcQtyProg(scope.slider.pc);
                            scope.wss.completion = scope.slider.pc;
                            updateWss(false);
                        }
                    });

                    // WSS issues
                    scope.openIssueModal = function (workScopeStage, issueId) {
                        console.log(workScopeStage);
                        if (issueId) {
                            scope.activeIssueId = issueId;
                        }
                        scope.activeWorkScopeStage = workScopeStage;
                        scope.issueModal[workScopeStage.id].open();

                        scope.issueModal[workScopeStage.id].onClose = function () {
                            scope.issue = {files: []};
                            scope.activeIssueId = null;
                            scope.activeWorkScopeStage = null;
                        };
                    };

                    scope.openIssueListModal = function (workScopeStageId) {
                        scope.issueListModal[workScopeStageId].open();
                    };

                    $rootScope.$on('issueSaved', function (event, data) {
                        scope.issueModal[scope.wss.id].close();
                    });
                }
            }
        };
    });
