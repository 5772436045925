'use strict';

angular.module('scswebappApp')
       .directive('rateBuildUpProductHeader', function(assessment, assessmentProduct, $rootScope) {
           return {
               template: require('./rate-build-up-product-header-directive.html'),
               restrict: 'E',
               scope: {
                   'currencySymbol': '=',
                   'assessmentProduct': '=',
                   'assessment': '=',
                   'productValues': '=',
                   'showProductValues': '=',
                   'estimateValues': '=',
                   'showTemplateLink': '=',
                   'reloadProduct': '&',
                   'closeEditProductModal': '&',
                   'disableGuesstimateBadge': '=',
                   'hasWritePermission': '='
               },
               link: function(scope, nxRbuIssueService) {
                   scope.cm = function() {
                       $rootScope.$broadcast('closeModal');
                   };
                   scope.onUpdate = function (ap) {
                       scope.assessmentProduct = ap;
                       $rootScope.$broadcast('rbuReloadAllProducts');
                       nxRbuIssueService.fetchRbuIssues(scope.assessment);
                   };
               }
           };
       });
