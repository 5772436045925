'use strict';

angular.module('orNgPermissions', [])
    .service('permissionAuth', function () {
        return {
            checkPermission: function (permission) {
                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if ( userDetails && userDetails.permissions.indexOf(permission) > -1) {
                    return true;
                }
            },
            checkRole: function (role) {
                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if ( userDetails && userDetails.roles) {
	                for( var i=0; i < userDetails.roles.length; i++){
		                if( role.indexOf(userDetails.roles[i].role) > -1 ){
			                return true;
		                }
	                }
                }

            }
        };
    })
    .run(function ($rootScope, user, permissionAuth) {
        $rootScope.checkPermission = function( permission ){
            return permissionAuth.checkPermission( permission ) ;
        };
        $rootScope.checkRole = function( role ){
            return permissionAuth.checkRole( role ) ;
        };
    });