(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.preliminary
	 * @description
	 * # preliminary
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('fixedcost', ['Resource', 'ENV', 'UNITSTEXT', function ( $resource, ENV ) {
			return $resource(
				ENV.apiEndpoint + '/fixedcosts/:id', { id: '@id', projectId: '@projectId', assessment: '@assessment' }, {
                    get: {
                        method: 'GET',
                        transformResponse: function(data){
                            var newData = angular.fromJson(data);
                            if( newData.period === null){
                                newData.period = 'l';
                            }
                            return newData;
                        }
                    },
					customSave: {
						method: 'PUT',
						transformRequest: function (data) {
							var pData = angular.fromJson(data);

                            if( pData.period === 'l'){
                                pData.period = null;
                            }

							var prelimData = {
								'name': pData.name,
                                'period': pData.period,
                                'pricePerPeriod': pData.pricePerPeriod,
                                'profitPerPeriod': pData.profitPerPeriod,
								'externalLinks': [],
								'dataSheets': []
							};

							angular.forEach(data.externalLinks, function(value){
								prelimData.externalLinks.push({url: value.url, label: value.label});
							});

							if( data.dataSheets ){
								angular.forEach(data.dataSheets, function(datasheet){
									var comment;
									if(datasheet.comment){
										comment = datasheet.comment;
									}else if(datasheet.file){
										comment = datasheet.file.comment;
									}
									var id = (datasheet.file) ? datasheet.file.id : datasheet.id;
									prelimData.dataSheets.push({comment: comment, file: id});
								});
							}

						    return angular.toJson(prelimData);
						}
					},
                    archive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/fixedcosts/:id/archive'
                    },
                    unarchive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/fixedcosts/:id/unarchive'
                    },
					fixedCostBill: {
						method: 'GET',
						isArray: true,
						url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/fixed-costs-bill'
					},
					filters: {
						method: 'GET',
						url:  ENV.apiEndpoint + '/projects/:projectId/fixed-costs-bill-filter-options'
					},
					myUpdate: {
						url: ENV.apiEndpoint + '/assessmentfixedcosts/:id',
						method: 'PATCH'
					}
				}
			);

	  }]);
})();
