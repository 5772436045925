(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.material
     * @description
     * # material
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('material', ['ENV', 'Resource', 'UNITSTEXT', function (ENV, $resource) {
            return $resource(
                ENV.apiEndpoint + '/materials/:id', {id: '@id', projectId: '@projectId', assessment: '@assessment'}, {
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/materials/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/materials/:id/unarchive'
                    },
                    customSave: {
                        transformRequest: function (data) {
                            var newObj = {
                                quantityUnit: data.quantityUnit,
                                wasteAllowancePercentage: data.wasteAllowancePercentage,
                                name: data.name,
                                brand: data.brand,
                                specifics: data.specifics,
                                singularName: data.singularName,
                                singularSize: data.singularSize,
                                packName: data.packName,
                                packSize: data.packSize,
                                subGroups: data.subGroups,
                                materialType: data.materialType,
                                externalLinks: [],
                                workPackages: [],
                                copyFromId: data.copyFromId
                            };

                            angular.forEach(data.externalLinks, function (value) {
                                newObj.externalLinks.push({url: value.url, label: value.label});
                            });

                            if (typeof data.workPackages[0] === 'object') {
                                angular.forEach(data.workPackages, function (value) {
                                    if (value.id !== 'null') {
                                        newObj.workPackages.push(value.id);
                                    }
                                });
                                newObj.workPackages = newObj.workPackages.filter(function (n) {
                                    return n !== undefined;
                                });
                            } else {
                                data.workPackages = data.workPackages.filter(function (n) {
                                    return n !== undefined;
                                });
                                newObj.workPackages = data.workPackages;
                            }

                            if (data.dataSheets) {
                                newObj.dataSheets = [];
                                angular.forEach(data.dataSheets, function (datasheet) {
                                    newObj.dataSheets.push(datasheet.id);
                                });
                            }

                            if (typeof data.materialType[0] === 'object') {
                                newObj.materialType = data.materialType[0].id;
                            }
                            return angular.toJson(newObj);
                        }
                    },
                    getMaterialBill: {
                        method: 'GET',
                        isArray: false,
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/material-bill'
                    },
                    getBillFilters: {
                        method: 'GET',
                        isArray: false,
                        url: ENV.apiEndpoint + '/projects/:id/material-bill-filter-options'
                    },
                    postMoizq: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/split'
                    },
                    forProject: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/projects/:projectId/materials'
                    },
                    pricesForProject: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/projects/:projectId/materials/:id/prices'
                    },
                    getOptions: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/materials/options'
                    }
                }
            );
        }]);
})();
