import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NullComponent} from "@generic/null.component";

const routes: Routes = [
    {
        path: "takeoff",
        loadChildren: () => import('./modules/slate.module').then(m => m.SlateModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'accounts',
        loadChildren: () => import('./modules/accounts.module').then(m => m.AccountsModule)
    },
    {
        path: 'projects',
        loadChildren: () => import('./modules/project-offsite.module').then(m => m.ProjectOffsiteModule)
    },
    {
        path: "dashboard",
        loadChildren: () => import('./modules/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: '**',
        component: NullComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {enableTracing: false})
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class AppRoutingModule
{
}
