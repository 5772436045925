import {NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import { PermissionService } from './slate/services/permission.service';
import { HttpClientModule } from "@angular/common/http";
import {routes} from "./dashboard/routes";
import {NgSelectModule} from "@ng-select/ng-select";
import {RouterModule} from "@angular/router";
import {GenericModule} from "./generic.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgxPermissionsModule} from "ngx-permissions";
import {DashboardChartsComponent} from "./dashboard/dashboard-charts/dashboard-charts.component";
import {DashboardSummaryComponent} from "./dashboard/dashboard-summary/dashboard-summary.component";
import {ToggleSwitchComponent} from "@generic/toggle-switch.component";
import {DashboardCtrlComponent} from "./dashboard/dashboard/dashboard.component";
import {NgxPopperModule} from "ngx-popper";
import {MarginTimeGraphComponent} from "./dashboard/margin-time-graph/margin-time-graph.component";

registerLocaleData(localeEnGb, 'en-GB');

@NgModule({
    declarations: [
        MarginTimeGraphComponent,
        DashboardChartsComponent,
        DashboardSummaryComponent,
        ToggleSwitchComponent,
        DashboardCtrlComponent
    ],
    imports: [
        NgSelectModule,
        RouterModule.forChild(routes),
        NgxPopperModule.forRoot({applyClass: 'new-tooltip'}),
        GenericModule,
        CommonModule,
        FormsModule,
        NgxPermissionsModule
    ],
    providers: [
        PermissionService,
        HttpClientModule
    ],
})
export class DashboardModule
{
}
