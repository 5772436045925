import {SimpleModalComponent} from "ngx-simple-modal";
import {Component} from "@angular/core";
import {
    Assessment,
    AssessmentCheckCode,
    AssessmentCheckResultItem
} from "../../chalkstring-api/services/assessment.service";
import {AssessmentAuditService} from "../../chalkstring-api/services/assessment.audit.service";
import {first} from 'rxjs/operators';
import {CsEvent} from "@generic/events";

export class RepairAssessmentModel
{
    assessment: Assessment;
    failures: AssessmentCheckResultItem[];
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content modal-content-size-s">
            <form #repairAssessmentForm="ngForm" (ngSubmit)="submitForm()" novalidate>
                <div class="modal-header" id="modal-repair-assessment">
                    <h2>Assessment issues</h2>
                </div>

                <div class="modal-body">
                    <ng-container *ngFor="let f of failures">
                        <p>{{failureMessage(f)}}</p>
                        <div class="p-margins" *ngIf="isFixable(f)">
                            <label class="checkbox-container">
                                <input type="checkbox" name="issue" [(ngModel)]="formModel[f.code]" />
                                <span class="checkbox"></span> Attempt automatic repair
                            </label>
                        </div>
                    </ng-container>
                    
                    <div class="badge block block-warning" *ngIf="canSubmit()">
                        WARNING: Automatic repairs cannot be reversed.
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn inline" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button *ngIf="hasFixableFailures()" type="submit" class="btn inline" [disabled]="!canSubmit()"><i class="icon-ok"></i> Repair</button>
                </div>
            </form>
        </div>
    `,
    styles: [
        `.modal-body p:not(:first-child) { margin-top: 1em; padding-top: 1em; border-top: 1px solid #eee }`
    ]
})
export class RepairAssessmentModalComponent extends SimpleModalComponent<RepairAssessmentModel, AssessmentCheckResultItem[]> implements RepairAssessmentModel
{
    assessment: Assessment;
    failures: AssessmentCheckResultItem[];
    formModel: any = {};
    working: boolean = false;

    constructor(private _aAuditService: AssessmentAuditService)
    {
        super();
    }

    mapDataObject(data: RepairAssessmentModel)
    {
        super.mapDataObject(data);
        CsEvent.reloadUserpilot();
        CsEvent.track('repairAssessmentModal', {a: this.assessment.id});
    }

    canSubmit(): boolean
    {
        return !this.working;
    }

    hasFixableFailures(): boolean
    {
        return this.failures.some(i => this.isFixable(i));
    }

    failureMessage(f: AssessmentCheckResultItem): string
    {
        switch (f.code) {
            case AssessmentCheckCode.TAKEOFF:
                return 'Measures in your bill of quantities do not currently match their linked measurements in Takeoff. ' +
                    'If you choose to continue, all cells in your bill of quantities with a Takeoff source will be updated using the most recent measurement data.';
            case AssessmentCheckCode.PARENT_ZONE_QUANTITY:
                return 'One or more main zones contains a product quantity which does not match the sum of its sub-zone quantities. ' +
                    'You can manually correct this in the bill of quantities or automatically set all main zone quantities to equal the sum of their sub-zones.';
            case AssessmentCheckCode.ZONE_VALIDITY:
                return 'Measures in your bill of quantities require a refresh. ' +
                    'If you choose to continue, all cells in your bill will refreshed and re-validated.';
            default:
                return 'Unexpected error';
        }
    }

    isFixable(f: AssessmentCheckResultItem): boolean
    {
        const fixableCodes = [
            AssessmentCheckCode.TAKEOFF,
            AssessmentCheckCode.ZONE_VALIDITY,
            AssessmentCheckCode.PARENT_ZONE_QUANTITY
        ];
        return fixableCodes.includes(f.code);
    }

    submitForm()
    {
        let resultsToFix = [];
        Object.entries(this.formModel).forEach(([code, fix], i) => {
            if (!!fix) {
                resultsToFix.push(this.failures.find(i => i.code.toString() === code));
            }
        });
        this._aAuditService.repair(this.assessment, resultsToFix).pipe(first()).subscribe(res => {
            this.working = false;
            this.result = res;
            this.close();
        });
    }
}
