import {CsUtils} from '../../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('LabourBillCtrl', function (
            $scope,
            $rootScope,
            $routeParams,
            $q,
            FileUploader,
            $locale,
            $route,
            $http,
            $window,
            ENV,
            project,
            subcontractor,
            labourApplication,
            labouractivity,
            assessment,
            $location,
            $filter,
            PermPermissionStore
        ) {
            $scope.hasCommercialDataPermission = PermPermissionStore.hasPermissionDefinition('canReadLabourCommercialData');

            $scope.currentApplicationItems = [];
            $scope.exportUrl = ENV.apiEndpoint + '/projects/' + $routeParams.id + '/export-labour-bill?access_token=' + localStorage.getItem("access_token");

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            if ($route.current.$$route.isBill) {
                $rootScope.projectId = $routeParams.id;
                $scope.projectId = $rootScope.projectId;
                project.get({id: $scope.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });
            }
            $scope.setAssessment = function (assessment) {
                $scope.assessment = assessment;
            };

            if ($route.current.$$route.isSingle) {
                $scope.form = {effectiveDate: null};
                $scope.modalInfo = {};

                const loadLabourApplication = function() {
                    labourApplication.get({
                        id: $routeParams.id,
                        groups: 'materialOrders_view'
                    }).$promise.then(function (data) {
                        $scope.labourApplication = data;
                        $rootScope.projectId = data.project.id;
                        $scope.projectId = $rootScope.projectId;
                        $scope.project = data.project;
                        if (!$scope.modalInfo.subcontractors) {
                            subcontractor.query({
                                hydration: 'array',
                                limit: 10000
                            }).$promise.then(function (subcontractors) {
                                angular.forEach(subcontractors, function (s) {
                                    if (data.subcontractor.id == s.id) {
                                        s.ticked = true;
                                    }
                                });
                                $scope.modalInfo.subcontractors = subcontractors;
                            });
                        }
                        $scope.form.effectiveDate = data.effectiveDate;
                        labourApplication.getNominalCodeAssignments({id: $routeParams.id}).$promise.then(function (data) {
                            $scope.nominalCodeAssignments = data;
                        });
                    });
                }

                loadLabourApplication();

                $scope.openAddItemsModal = function() {
                    if ($scope.labourApplication.state == 'Held' || confirm('This application has already been approved. Are you sure you want to add new items? Adding new items will update the value applied for.')) {
                        $scope.addItemsModal.open();
                        $scope.addItemsModal.onClose = function() {
                            loadLabourApplication();
                        }
                    }
                };

                const deleteItem = function(item) {
                    labourApplication.deleteItem({
                        projectId: $rootScope.projectId,
                        application: $routeParams.id,
                        item: item.id
                    }).$promise.then(() => loadLabourApplication());
                };

                $scope.confirmDeleteItem = function(item) {
                    const additional = $scope.labourApplication.state != 'Held' ? 'This application has already been approved. Deleting items will update the value applied for.' : '';
                    if (confirm(`Are you sure you want to delete this item from the application? ${additional}`)) {
                        deleteItem(item);
                    }
                };

                $scope.removeApplication = function () {
                    labourApplication.delete({id: $routeParams.id}).$promise.then(function (data) {
                        $location.path('/project-hub/previous-labour-applications/' + $rootScope.projectId);
                    });
                };

                var uploader = new FileUploader;
                uploader.onCompleteAll = function () {
                    labourApplication.update({id: $routeParams.id}, {
                        documents: $scope.labourApplication.documents.pluck("id", "file")
                    });
                };

                $scope.fileUploader = {
                    uploader: uploader
                };

                $scope.preDeleteFile = function (deletedFile) {
                    var deferred = $q.defer();
                    angular.forEach($scope.labourApplication.documents, function (file, fileIndex) {
                        if (file.id == deletedFile.id) {
                            $scope.labourApplication.documents.splice(fileIndex, 1);
                            labourApplication.update({id: $routeParams.id}, {
                                documents: $scope.labourApplication.documents.pluck("id", "file")
                            }).$promise.then(function () {
                                deferred.resolve();
                            });
                        }
                    });
                    return deferred.promise;
                };

                $scope.export = function () {
                    $http({
                        method: 'GET',
                        responseType: 'arraybuffer',
                        url: ENV.apiEndpoint + '/labourpaymentapplications/' + $routeParams.id + '/export'
                    }).success(function (data, status, headers) {
                        CsUtils.download(data, headers, $window);
                        $scope.$broadcast('closeModal');
                    });
                };

                $scope.edit = function () {
                    labourApplication.update({id: $scope.labourApplication.id}, {
                        subcontractor: $scope.form.subcontractor[0] ? $scope.form.subcontractor[0].id : null,
                        effectiveDate: $filter('date')($scope.form.effectiveDate, 'yyyy-MM-dd HH:mm:ss')
                    }).$promise.then(function () {
                        $location.path('/project-hub/labour-application/' + $scope.labourApplication.id);
                    });
                };

                $scope.showCIS = true;

                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if (userDetails != null) {
                    var defaultCurrency = userDetails.account.defaultCurrency;
                    if(defaultCurrency == "MYR" || defaultCurrency == "SGD") {
                        $scope.showCIS = false;
                    }
                }

            } else {
                $scope.projectId = $rootScope.projectId = $routeParams.id;
                $scope.project = project.get({id: $scope.projectId}, {onlygroups: 'basic,hub'});
            }

            if ($route.current.$$route.isList) {
                $scope.applicationTableServiceParams = {
                    'project': $rootScope.projectId,
                    'state[]': $route.current.$$route.applicationStates,
                };
            }

            var deregisterLocationChangeListener = $scope.$on('$locationChangeStart', function (e, nextLocation) {
                $scope.nextLocation = nextLocation.split('#');
                if (Object.keys($scope.currentApplicationItems).length > 0) {

                    e.preventDefault();
                    $scope.confirmationModal.open();
                }
            });

            $scope.leavePage = function () {
                $scope.confirmationModal.close();
                $location.path($scope.nextLocation[1]);
                deregisterLocationChangeListener();
            };
        });
})();
