'use strict';

angular.module('scswebappApp')
    .directive('applicationBill', function ($injector, $filter, $rootScope, $routeParams, $locale, assessment) {

        var $ = require('jquery');

        return {
            restrict: 'E',
            scope: {
                type: '=',
                rateService: '=',
                currentApplicationItems: '=',
                reviewMode: '=',
                rowStyle: '@',
                columnStyle: '@',
                directionOutgoing: '=?',
                serviceRowsProperty: '=?',
                serviceColumnsProperty: '=?'
            },
            template: require('./application-bill.html'),
            link: function postLink(scope, elem, attr) {
                var rateService = $injector.get(attr.rateService);

                scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
                scope.selectedAssessmentZone = null;
                scope.formVisibility = [];
                scope.rates = [];
                scope.filtersValid = false;
                scope.summary = null;

                scope.queryString = {
                    id: $routeParams.id,
                    limit: 30,
                    page: 1,
                    direction: 'outgoing'
                };

                // Saving filter local storage so they're persistent
                // function saveToLocalStorage(filterParams) {
                //     localStorage.setItem('filters_' + attr.rateService + '_getRates', angular.toJson(filterParams));
                // }
                // function removeFromLocalStorage() {
                //     localStorage.removeItem('filters_' + attr.rateService + '_getRates');
                // }
                // function getFromLocalStorage() {
                //     var filterParams = angular.fromJson(localStorage.getItem('filters_' + attr.rateService + '_getRates'));
                //     console.log(filterParams);
                //     return filterParams;
                // }

                scope.$on('applicationBillRefreshZoneValues', function () {
                    getRates();
                }, true);

                var getFilters = function () {
                    // Get data for filters
                    var params = {id: $routeParams.id};
                    rateService.getBillFilters(params).$promise.then(function (data) {
                        scope.filterArray = data;
                        scope.$emit('applicationFiltersGot', data);
                        // return data;
                    });
                    getRates();
                };

                var getRates = function () {
                    if (scope.queryString['assessment'] || scope.reviewMode == true) {
                        if (scope.filter) {
                            angular.forEach(scope.filter, function (value, key) {
                                scope.queryString[key] = value;
                            });
                        }

                        var loadData = function (data, header) {
                            console.log(data);
                            scope.rows = attr.serviceRowsProperty ? data[attr.serviceRowsProperty] : data;
                            scope.columns = attr.serviceColumnsProperty ? data[attr.serviceColumnsProperty] : (scope.rows[0] ? scope.rows[0].quantities : null);
                            if (data.totals) {
                                scope.totals = data.totals;
                            }
                            var objectCount = header()['x-total-count'];
                            scope.numPages = Math.ceil(objectCount / scope.queryString.limit);
                            scope.pagesArray = [];
                            for (var i = 0; i < scope.numPages; i++) {
                                scope.pagesArray.push(i + 1);
                            }
                        };

                        scope.filtersValid = true;
                        if (scope.reviewMode) {
                            rateService.getBillReview(scope.queryString, loadData);
                        } else if (!scope.directionOutgoing) {
                            rateService.getBillRatesIncoming(scope.queryString, loadData);
                        } else {
                            rateService.getBillRates(scope.queryString, loadData);
                        }
                    } else {
                        scope.filtersValid = false;
                    }
                };
                scope.$on('getRates', function () {
                    getRates();
                });
                if (scope.columnStyle === 'product') {
                    scope.$watch('directionOutgoing', function(){
                        getRates();
                    });
                }

                getFilters();

                scope.toggleEditForm = function (cellCoordinate) {
                    if (scope.formVisibility[cellCoordinate] == undefined) {
                        scope.formVisibility[cellCoordinate] = {visible: false};
                    }
                    scope.formVisibility[cellCoordinate].visible = !scope.formVisibility[cellCoordinate].visible;
                };

                scope.setAppliedQuantity = function (cellCoordinate, applicationItemId, quantity, rate) {
                    rateService.modifyBillItem({id: applicationItemId}, {
                        quantity: quantity,
                        rate: rate
                    }).$promise.then(function () {
                        scope.toggleEditForm(cellCoordinate);
                        getRates();
                    });
                };

                scope.approveApplication = function (application) {
                    var zoneId = scope.selectedAssessmentZone ? scope.selectedAssessmentZone : null;
                    rateService.approveApplication({
                        id: application.id,
                        assessmentZoneId: zoneId
                    }).$promise.then(function () {
                        getRates();
                        scope.$emit('applicationApproved');
                    });
                };

                scope.filter = {};
                scope.filterRates = function (reset, data) {
                    if (reset) {
                        scope.selectedAssessmentZone = null;
                        scope.filterArray.zones = [];
                        scope.filter = {};
                        scope.queryString = {
                            id: $routeParams.id,
                            limit: 30,
                            page: 1
                        };
                        angular.forEach(scope.filterArray.assessments, function (value) {
                            value.ticked = false;
                        });
                        angular.forEach(scope.filterArray.zones, function (value) {
                            value.ticked = false;
                        });
                        angular.forEach(scope.filterArray.quotes, function (value) {
                            value.ticked = false;
                        });
                        angular.forEach(scope.filterArray.trades, function (value) {
                            value.ticked = false;
                        });
                    } else if (data && typeof(data.selectedPriceInvalidQuote) !== 'boolean') {
                        angular.forEach(data, function (value, key) {
                            scope.queryString[key] = value;
                            if (key == 'assessmentZone') {
                                scope.selectedAssessmentZone = value;
                            }
                        })
                    } else if (typeof(data.selectedPriceInvalidQuote) === 'boolean') {
                        if (data.selectedPriceInvalidQuote) {
                            scope.queryString.selectedPriceInvalidQuote = true;
                        } else {
                            delete scope.queryString.selectedPriceInvalidQuote;
                        }
                    }

                    if (data && data.assessment != undefined) {
                        scope.filterArray = rateService.getBillFilters({
                            id: $routeParams.id,
                            assessment: data.assessment
                        }).$promise.then(function (filters) {
                            scope.filterArray = filters;
                            scope.filterArray.zones = $filter('zoneList')(scope.filterArray.zones);
                            angular.forEach(scope.filterArray.assessments, function (value) {
                                if (value.id == data.assessment) {
                                    value.ticked = true;
                                }
                            });
                        });
                    }
                    getRates();
                };

                scope.addToApplication = function (cellCoordinate, item) {
                    // Set current application item
                    scope.currentApplicationItems[cellCoordinate] = item;
                    if (item.isSubZone) {
                        // If the currently-added item belonged to a subzone, update the parent quantity displayed
                        var parentCellCoordinate = 'i' + item.rateId + 'p' + item.selectedPriceId + 'z' + item.parentZoneId;
                        if (!scope.currentApplicationItems[parentCellCoordinate]) {
                            // First time this parent quantity has been updated
                            scope.currentApplicationItems[parentCellCoordinate] = {
                                isContingency: item.isContingency,
                                isParent: true,
                                isSubZone: false,
                                parentZoneId: null,
                                quantity: item.quantity,
                                rate: item.rate,
                                rateId: item.rateId,
                                selectedPriceId: item.selectedPriceId,
                                zoneId: item.parentZoneId
                            };
                        } else {
                            // Need to look at all siblings to get current quantity
                            var parentQty = 0;
                            var siblingCoord;
                            var areSiblings = function (applicationItemA, applicationItemB) {
                                if (applicationItemA.parentZoneId === applicationItemB.parentZoneId &&
                                    applicationItemA.rateId === applicationItemB.rateId &&
                                    applicationItemA.selectedPriceId === applicationItemB.selectedPriceId) {
                                    return true;
                                } else {
                                    return false;
                                }
                            };
                            for (siblingCoord in scope.currentApplicationItems) {
                                if (scope.currentApplicationItems.hasOwnProperty(siblingCoord)) {
                                    if (areSiblings(scope.currentApplicationItems[siblingCoord], item)) {
                                        parentQty += scope.currentApplicationItems[siblingCoord].quantity;
                                    }
                                }
                            }
                            scope.currentApplicationItems[parentCellCoordinate].quantity = parentQty;
                        }
                    }

                    scope.$emit('applicationItemAdded', {
                        'item': item,
                        'cellCoordinate': cellCoordinate
                    });
                    scope.toggleEditForm(cellCoordinate);
                };
                scope.addFixedCostToApplication = function (cellCoordinate, item) {
                    scope.currentApplicationItems[cellCoordinate] = item;
                    scope.$emit('applicationFixedCostItemAdded', {
                        'item': item,
                        'cellCoordinate': cellCoordinate
                    });
                    scope.toggleEditForm(cellCoordinate);
                };

                scope.getThisApplicationTotal = function (assessmentId, activityId, priceId) {
                    var total = 0;

                    angular.forEach(Object.keys(scope.currentApplicationItems), function (key) {
                        var item = scope.currentApplicationItems[key];

                        if (item.selectedPriceId == priceId && item.rateId == activityId && !item.isParent) {
                            total += item.quantity;
                        }
                    });

                    return total;
                };

                scope.changePage = function (page) {
                    if (page !== 0 && page !== scope.pagesArray.length + 1) {
                        scope.queryString.page = page;
                        getRates();
                    }
                };

                scope.showSummary = function () {
                    scope.summary = rateService.getDraftSummary({'projectId': $routeParams.id});
                    scope.summaryVisible = true;
                };

                scope.hideSummary = function () {
                    scope.summary = null;
                    scope.summaryVisible = false;
                };

                // Scroll table
                $(function () {
                    $('#' + attr.id + ' .scroll-table > div').scroll(function (el) {
                        // Horizontal scroll
                        scope.tableHeaderOffset = $('#' + attr.id + ' .scroll-table > div > table').position().left;
                        scope.tableColOffset = $('#' + attr.id + ' .scroll-table > div > table').position().top;
                        scope.$apply();
                    });
                });

                scope.removeContingencyItem = function (assessmentId, contingencyLabourActivityId, priceId) {
                    assessment.removeContingencyLabourActivity({id: assessmentId}, {
                        originalLabourActivity: contingencyLabourActivityId,
                        selectedLabourRate: priceId
                    }).$promise.then(function () {
                        getRates();
                    });
                };
            }
        };
    });
