(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('fixedCostApplication', ['Resource', 'ENV', function ( $resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/fixedcostsapplications/:id', { id: '@id', projectId: '@projectId', groups: '@groups', assessmentZoneId: '@assessmentZoneId', transition: '@transition'}, {
                    getBillRates: {
                        method: 'GET',
                        isArray: false,
                        url: ENV.apiEndpoint + '/projects/:id/assessments/:assessment/fixed-costs-application-bill'
                    },
                    getBillFilters: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/fixed-costs-application-bill-filter-options' //TODO replace this with proper thing
                    },
                }
            );
        }]);
})();
