(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MaterialDeliveryCtrl', function ($scope, $rootScope, $routeParams, project, materialDeliveries, $route, $location, $http, ENV, $window, $httpParamSerializer, materialOrders) {
            if ($routeParams.projectId) {
                $rootScope.projectId = $routeParams.projectId;
                project.get({id: $routeParams.projectId},{onlygroups:'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });
            }

            if ($route.current.$$route.isList) {
                // $scope.selectOrderModal = {};
                $scope.selectOrderForm = {};
                $scope.openLogModal = function () {
                    $scope.selectOrderModal.open();
                    if (!$scope.orders) {
                        const deliverableStates = ['Sent', 'Partial', 'Fulfilled', 'Complete'];
                        materialOrders.query({project: $routeParams.projectId, sort: '-orderNumber', limit: 5000, 'stateStatus[]': deliverableStates}).$promise.then(function(data){
                            $scope.orders = data;
                        });
                    }
                };
                $scope.orderSelected = function () {
                    $scope.selectOrderModal.close();
                    $location.path('/project-hub/material-order/' + $scope.selectOrderForm.order[0].id + '/log-delivery').search({'viaList': 1}); //?viaList=1
                };

                // List page
                $scope.invoiceTableServiceParams = {'project': $rootScope.projectId};
            } else if ($route.current.$$route.isSchedule) {
                // Delivery schedule
                $scope.setExportUrl = function () {
                    var url = ENV.apiEndpoint + '/projects/' + $routeParams.projectId + '/delivery-schedule-export?';
                    var params = {};
                    if (localStorage.getItem('filters_materialDeliveries_getSchedule')) {
                        params = angular.fromJson(localStorage.getItem('filters_materialDeliveries_getSchedule'));
                        delete params.page;
                    }
                    params.access_token = localStorage.getItem("access_token");
                    $scope.exportUrl = url + $httpParamSerializer(params);
                };
                $scope.setExportUrl();
                $rootScope.$on('filterChanged', function () {
                    $scope.setExportUrl();
                });

                $scope.scheduleTableParams = {project: $routeParams.projectId};

            } else if ($routeParams.id) {
                // Single page
                materialDeliveries.get({id: $routeParams.id},{groups:'orders_view'}).$promise.then(function (data) {
                    $scope.projectId = data.materialOrder.project.id;
                    $scope.project = data.materialOrder.project;
                    $scope.order = data.materialOrder;
                    $scope.delivery = data;
                });
            }
        });
})();
