(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.product
	 * @description
	 * # product
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('product', ['Resource', 'ENV', 'UNITSTEXT', function ( $resource, ENV ) {

			function refactorProduct(product){

				var productData = {
					name: product.name,
					labourComponents: [],
	                description: product.description,
	                quantityUnit: product.quantityUnit,
                    workPackage: product.workPackage.id,
                    pricingNotes: product.pricingNotes,
                    subGroups: product.subGroups,
                    externalLinks: [],
                    dataSheets: []
				};

                angular.forEach(product.externalLinks, function(value){
                    productData.externalLinks.push({url: value.url, label: value.label});
                });

                angular.forEach( product.dataSheets, function( value ){
                    productData.dataSheets.push(value.id);
                });


				// Labour component and component ordering

                var stageOrder = 10;
				angular.forEach(product.labourComponents, function (labourComponent) {
					var newLabourComponent = {
						name: labourComponent.name,
						oldStageOrder: labourComponent.stageOrder,
						stageOrder: stageOrder,
						contingencyBudget: labourComponent.contingencyBudget,
						labourRate: labourComponent.labourRate,
						labourComponentMaterials: [],
						labourComponentActivities: []
					};
					if( labourComponent.deleted ){
						newLabourComponent.deleted = true;
					}
					stageOrder += 10;
					labourComponent.oldStageOrder = (labourComponent.stageOrder) ? labourComponent.stageOrder : 10000;
					angular.forEach(labourComponent.labourComponentMaterials, function (material) {
						var materialId;
						if (material.material && material.material.id) {
							materialId = material.material.id;
						} else {
							materialId = material.id;
						}

						var lcm = {
							material: materialId,
							quantityPerLabourComponent: material.quantityPerLabourComponent,
							wasteAllowancePercentage: material.wasteAllowancePercentage,
						};

						if (typeof material.materialConfiguration !== 'undefined' && material.materialConfiguration !== null) {
							lcm.materialConfiguration = material.materialConfiguration.id;
						}

						newLabourComponent.labourComponentMaterials.push(lcm);
					});
					angular.forEach(labourComponent.labourComponentActivities, function (value) {
						var id;
						if (value.labourActivity) {
							id = value.labourActivity.id;
						} else {
							id = value.id;
						}
						var lcla = {
							labourActivity: id,
							labourComponent: labourComponent.id,
							quantityPerLabourComponent: value.quantityPerLabourComponent
						};

						newLabourComponent.labourComponentActivities.push(lcla);
					});
					productData.labourComponents.push(newLabourComponent);
				});

				var labourObject = {};
				// Reorder Labour component array by oldStageOrder and delete oldStageOrder key/value.
				productData.labourComponents.sort(function(a, b){
					return parseFloat(a.oldStageOrder) - parseFloat(b.oldStageOrder);
				});
				angular.forEach(productData.labourComponents, function(c, k){
					if(c.deleted){
						delete productData.labourComponents[k];
					}else{
						delete c.oldStageOrder;
						labourObject[k] = c;
					}
				});
				productData.labourComponents = labourObject;
				return productData;
			}

	        return $resource(
	            ENV.apiEndpoint + '/products/:id', { id: '@id', movingObjectId: '@movingObjectId', referenceObjectId: '@referenceObjectId', fileId: '@fileId', linkId: '@linkId' }, {
			        update: {
				        method: 'PUT',
				        transformRequest: function (data) {
					        return angular.toJson( refactorProduct(data) );
				        }
			        },
			        create: {
				        method: 'POST',
				        transformRequest: function (data) {
					        return angular.toJson( refactorProduct(data) );
				        }
			        },
                    refactor: {
                        method: 'PUT',
                        url:  ENV.apiEndpoint + '/assessmentproducts/:id/modify',
                        transformRequest: function (data) {
                            return angular.toJson( refactorProduct(data) );
                        }
                    },
                    orderComponent: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/components/move/:movingObjectId/after/:referenceObjectId'
                    },
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/products/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/products/:id/unarchive'
                    },
                    addDatasheet: {
                          method: 'PATCH',
                      url: ENV.apiEndpoint + '/products/:id/add-datasheet/:fileId'
                    },
                    addExternalLink: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/products/:id/add-external-link'
                    },
                    removeExternalLink: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/products/:id/external-link/:linkId'
                    },
					pricesForProject: {
			        	method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/products/:id/prices',
						isArray: true
					},
                    getPreviousAssessmentProducts: {
                        url: ENV.apiEndpoint + "/products/:id/previous",
                        method: "GET",
                        isArray: true
                    }
	            }
	        );

	  }]);
})();
