'use strict';

angular.module('scswebappApp')
    .directive('apzqField', function (assessment) {
        let $ = require('jquery');

        return {
            templateUrl: 'scripts/directives/apzq-field/apzq-field.html',
            restrict: 'E',
            scope: {
                tabindex: '=',
                fieldData: '=',
                coordinate: '=',
                quantity: '=',
                quantityUnit: '=',
                apzqId: '='
            },
            link: function postLink(scope, element) {

                scope.focusInput = function () {
                    // Select text on focus
                    $(element).find('input[type=number]').select();
                };

                scope.blurInput = function (item) {
                    if (parseFloat(scope.quantity) !== parseFloat(item.quantity)) {
                        // A change has occurred
                        assessment.updateAssessmentQuantity({
                            id: item.id,
                            quantity: item.quantity
                        }).$promise.then(function () {
                            scope.$emit('gbRefresh', true);
                        });
                    }
                };
            }
        };
    });
