'use strict';

angular.module('scswebappApp')
    .directive('taxCodeDropdown', function ( $injector) {

        return {
            template: require('./tax-code-dropdown.html'),
            restrict: 'E',
            transclude: true,
            scope:{
                'selectedTaxCodeId': '=',
                'model': '=?',
                'taxCodeType': '@',
                'parentObject': '=',
                'required': '=',
                'defaultSpecial': '@',
                'nullOption': '@',
                'onlySpecial': '@',
                'noInitialClick': '=',
                'readonly': '@'
            },
            link: function postLink(scope, element, attrs) {
                scope.$watch('readonly', function() {
                    scope.isReadOnly = scope.readonly === "true";
                });
                scope.taxcodes = [];
                scope.selection = [];
                switch (scope.taxCodeType.toLowerCase()) {
                    case 'vat':
                        var service = $injector.get('vattaxcode');
                        break;
                    case 'cis':
                        var service = $injector.get('cistaxcode');
                        break;
                    default:
                        console.error('invalid tax code type');
                }

                scope.onItemClick = function(){
                    scope.model = scope.selection[0];

                    scope.$emit('taxCodeSelected',{
                        item: scope.selection[0],
                        element: element[0],
                        parentObject: scope.parentObject
                    });
                };

                service.query().$promise.then(function(data){
                    if (scope.nullOption) {
                        var nullOptionText = typeof scope.nullOption === 'string' ? scope.nullOption : 'None';
                        var nullOption = {
                          id: null,
                          name: nullOptionText,
                          rate: null
                        };
                        if (scope.model === null || scope.model === undefined){
                          nullOption.ticked = true;
                        }

                        scope.taxcodes.unshift(nullOption);

                    }

                    angular.forEach(data, function (value) {
                        if (scope.onlySpecial && scope.onlySpecial !== value.special) {
                            return;
                        }

                        value.name += " (" + value.rate + "%)";
                        scope.taxcodes.push(value);

                    });

                    scope.$watch('model',function(newValue, oldValue, scope){
                        if (newValue !== undefined && newValue !== null) {
                            angular.forEach(data, function (value) {
                                if (value.id === newValue || value.id === newValue.id) {
                                    value.ticked = true;
                                    scope.selection[0] = value;
                                    if (!scope.noInitialClick) {
                                      scope.onItemClick(); //simulate a click, so that event can be emitted otherwise initial selection won't end up in the form data
                                    }
                                }
                            });
                        } else if ((newValue === undefined || newValue === null) && scope.defaultSpecial && (!scope.parentObject || !scope.parentObject.id)) {
                            if (scope.parentObject) { //only set default vat when create new cost
                                angular.forEach(scope.taxcodes, function (value) {
                                    if (value.special === scope.defaultSpecial) {
                                        value.ticked = true;
                                        scope.selection[0] = value;
                                    }
                                });
                            }

                            if (!scope.noInitialClick) {
                                scope.onItemClick(); //simulate a click, so that event can be emitted otherwise initial selection won't end up in the form data
                            }
                        } else if (newValue === null && scope.nullOption) {
                            angular.forEach(scope.taxcodes, function (value) {
                                if (value.id === null) {
                                    value.ticked = true;
                                    scope.selection[0] = value;
                                    if (!scope.noInitialClick) {
                                      scope.onItemClick(); //simulate a click, so that event can be emitted otherwise initial selection won't end up in the form data
                                    }
                                }
                            });
                        }
                    });
                });
            }
        };
    });
