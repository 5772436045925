(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.assessmentLabourComponentActivity
     * @description
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('assessmentLabourComponentActivity', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/assessmentcomponentactivities/:id', {
                    id: '@id',
                    assessmentLabourComponentActivity: "@assessmentLabourComponentActivity",
                    newLabourActivity: "@newLabourActivity",
                    quantity: "@quantity",
                    newRateId: "@newRateId"
                }, {
                    delete: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/assessmentcomponentactivities/:id'
                    },
                    patch: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentcomponentactivities/:id'
                    },
                    add: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentcomponentactivities'
                    },
                    swapLabourActivity: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentcomponentactivities/:assessmentLabourComponentActivity/swap/:newLabourActivity?quantity=:quantity"
                    },
                    changeRate: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentcomponentactivities/:id/change-rate/:newRateId"
                    },
                    changeActivityOrder: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentcomponentactivities/activity-order',
                        params: {
                            'ids[]': '@ids'
                        },
                        isArray: true
                    }
                }
            );
        }]);
})();
