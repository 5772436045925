'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:SendNotificationCtrl
 * @description
 * # SendNotificationCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
	.controller('SendNotificationCtrl', function ($scope, $routeParams, project, projectRole, notification, $rootScope) {

		$scope.projectId = $routeParams.id;

		project.get( {id: $scope.projectId} ).$promise.then( function( data ){
			$scope.project = data;
		});

		projectRole.query().$promise.then( function( data ){
			$scope.roles = data;
			$scope.note = ($scope.note)? $scope.note : {};
			$scope.note.projectRole = 1;
		});


		$scope.sendNote = function(notificationForm){
			$scope.submitted = true;

			if( notificationForm.$valid ){
				notificationForm.$setPristine();
				$scope.submitted = false;

				notification.save( {id:$scope.projectId}, $scope.note ).$promise.then( function(){
					$scope.note = {};
					$scope.note.projectRole = 1;
				})

			}
		}


	});
