(function(){
	'use strict';

	/**
	 * @ngdoc directive
	 * @name scswebappApp.directive:uploadqueue
	 * @description
	 * # uploadqueue
	 *  <upload data="logoUpload" bind="company.companyLogo" size="2" single="true"></upload>
	 */
	angular.module('scswebappApp')
		.directive('upload', function (FileUploader, ENV, $rootScope, file) {
			return {
			    template: require('./upload-directive.html'),
				restrict: 'E',
				scope: {
					data: '=',
	                bind: '=',
	                single: '=?',
					newFormat: '=?'
				},
				link: function(scope, element, attrs) {

					scope.uploader = scope.data.uploader;

	                scope.uploader.alias = 'file';

					if( attrs.size ){
						scope.uploader.filters.push({
							'name': 'enforceMaxFileSize',
							'fn': function (item) {
								var maxSize = attrs.size * 1000000
								if( item.size > maxSize ){
									$rootScope.$broadcast('showOrNotification', 'File should be a maximum size of ' + attrs.size + 'MB', true);
								}
								return item.size <= maxSize; // 2 MiB to bytes
							}
						});
					}

					scope.uploader.onAfterAddingFile = function (fileData) {

                        fileData.status = 'Waiting';
                        var extension = fileData.file.name.split('.').pop();

                        if (extension === 'xls') {
                            fileData.file.type = 'application/vnd.ms-excel';
                        } else if (extension === 'xlsx') {
                            fileData.file.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        }

                        if (fileData.file.type === '' || !fileData.file.type) {
                            fileData.file.type ='application/octet-stream';
                        }

                        console.log(fileData);

                        file.sign({
	                        fileName: fileData.file.name,
	                        fileSize: fileData.file.size,
	                        mimeType: fileData.file.type
	                    }).$promise.then(function(data){

	                            fileData.policy = data;

	                            fileData.formData = data.s3PostPolicy.conditions;

	                            fileData.formData.push({
	                               policy: data.s3PostPolicyEncodedString
	                            });

	                            fileData.formData.push({
	                                'X-Amz-Signature': data.s3PostPolicySignature
	                            });

	                            fileData.s3id = data.id;

	                            fileData.url = data.s3UploadUrl;
	                            scope.uploader.addToQueue(fileData);
	                            fileData.upload();
	                    });

					};

					scope.uploader.onProgressItem = function (fileData) {
						if (fileData.progress === 100) {
	                        fileData.status = 'Processing';
						}
					};

					scope.uploader.onCompleteItem = function (fileData) {

	                    fileData.status = 'Complete';
	                    fileData.progress = 100;

	                    scope.uploader.removeFromQueue(fileData);

	                    var obj = {
	                        fileName: fileData.policy.fileName,
	                        fileSize: fileData.policy.fileSize,
	                        id: fileData.policy.id,
	                        mimeType: fileData.policy.mimeType,
	                        s3Url: fileData.policy.s3Url,
                            s3UrlInline: fileData.policy.s3UrlInline
                        };

                        if (scope.newFormat) {
                            obj = {
                                file: obj
                            };
                        }
                        if (!scope.single) {
                            scope.bind.push(obj);
                        } else {
                            scope.bind = obj;
                        }
                        console.log(scope.bind);

	                    scope.$apply();
                        // i suppose this might come in handy somewhere so i'm not gonna remove it just yet
                        //rootScope.$broadcast('uploadfinished', scope.policy);
                    };

                    scope.uploader.onBeforeUploadItem = function (fileData) {
                        fileData.status = 'Uploading';
                    };
                }
            };
        });
})();
