import * as angular from 'angular';
import {appName} from "./scripts/app"
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ConfigModule} from "./config";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpBackend} from '@angular/common/http';
import {AppRoutingModule} from "./app-routing.module";
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {GenericModule} from "./modules/generic.module";
import {FormsModule} from "@angular/forms";
import {ApiInterceptor} from "./modules/chalkstring-api/api.interceptor";
import {NgSelectModule} from '@ng-select/ng-select';
import {ChalkstringApiModule} from "./modules/chalkstring-api.module";
import {TableViewModule} from "./modules/table-view.module";
import {EditableTableModule} from "./modules/editable-table.module";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {QuantityBillGridBillComponent} from "./modules/bill/grid-styles/quantity-bill/quantity-bill-grid-bill.component";
import {BadgeComponent} from "@generic/badge/badge.component";
import {FileTableComponent} from "@generic/file-table/file-table.component";
import {AddressComponent} from "@generic/address/address.component";
import {AssessmentOffSiteNavListComponent} from "@generic/nav-list/lists/assessment-off-site-nav-list.component";
import {ImportBillOfQuantitiesButtonComponent} from "./modules/assessment/buttons/import-bill-of-quantities-button.component";
import {AssessmentModule} from "./modules/assessment.module";
import {defaultSimpleModalOptions, SimpleModalModule} from "ngx-simple-modal";
import {ProductModule} from "./modules/product.module";
import {LabourBillGridBillComponent} from "./modules/bill/grid-styles/labour-bill/labour-bill-grid-bill.component";
import {MaterialBillGridBillComponent} from "./modules/bill/grid-styles/material-bill/material-bill-grid-bill.component";
import {BillModule} from "./modules/bill.module";
import {AssessmentStatusButtonComponent} from "./modules/assessment/buttons/assessment-status-button.component";
import {TakeoffListButtonComponent} from "./modules/assessment/buttons/takeoff-list-button.component";
import {AssessmentOnSiteNavListComponent} from "@generic/nav-list/lists/assessment-on-site-nav-list.component";
import {ApplicationBillGridBillComponent} from "./modules/bill/grid-styles/application-bill/application-bill-grid-bill.component";
import {IncomingBillGridBillComponent} from "./modules/bill/grid-styles/incoming-bill/incoming-bill-grid-bill.component";
import {IfGeneratedNowComponent} from "./modules/bill/grid-styles/application-bill/if-generated-now/if-generated-now.component";
import {MoveProductLinkComponent} from "./modules/assessment/move-product-link.component";
import {ProgressBillGridBillComponent} from "./modules/bill/grid-styles/progress-bill/progress-bill-grid-bill.component";
import {ProgressGroupBillGridBillComponent} from "./modules/bill/grid-styles/progress-group-bill/progress-group-bill-grid-bill.component";
import {NgxPermissionsModule, NgxPermissionsService} from "ngx-permissions";
import {WorkScopeModule} from "./modules/work-scope.module";
import {EditableTableComponent} from './modules/editable-table/editable-table.component';
import {NewAssessmentButtonComponent} from "./modules/assessment/buttons/new-assessment-button.component";
import {RolePermissionsComponent} from "./modules/permissions/role-permissions.component";
import {PermissionsModule} from "./modules/permissions.module";
import {MainNavListComponent} from "@generic/nav-list/lists/main-nav-list.component";
import {HubNavListComponent} from "@generic/nav-list/lists/hub-nav-list.component";
import {ProjectRemovalButtonComponent} from "./modules/project-offsite/project-removal-button.component";
import {MaterialListComponent} from "./modules/product/tables/material-list.component";
import {LabourActivityListComponent} from "./modules/product/tables/labour-activity-list.component";
import {ConfirmationButtonComponent} from "@generic/confirmation-button/confirmation-button.component";
import {TableDataService} from "./modules/table-view/abstract-table.service";
import {PriceSelectComponent} from "./modules/product/price-select/price-select.component";
import {ProductOrderListComponent} from "./modules/product/tables/product-order-list.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AssessmentToolkitButtonsComponent} from "./modules/bill/buttons/assessment-toolkit-buttons.component";
import {HelpButtonComponent} from "@generic/button/help-button.component";
import {ProductTemplateListComponent} from "./modules/product/tables/product-template-list.component";
import {ChalkstringApiFormModule} from "./modules/chalkstring-api-form.module";
import {LabourApplicationListComponent} from "./modules/bill/tables/labour-application-list.component";
import {OrderInvoiceListComponent} from "./modules/product/tables/order-invoice-list.component";
import {MaterialDeliveryListComponent} from "./modules/product/tables/material-delivery-list.component";
import {CsDynamicFormModule, CsVerticalColourPickerComponent} from "cs-dynamic-form";
import {ChangeSupplierButtonComponent} from "./modules/order/change-supplier-button.component";
import {OrderModule} from "./modules/order.module";
import {SupplyAndFitApplicationListComponent} from "./modules/bill/tables/supply-and-fit-application-list.component";
import {ExternalCostListComponent} from "./modules/product/tables/external-cost-list.component";
import {ContactsModule} from "./modules/contacts.module";
import {ClientListComponent} from "./modules/contacts/tables/client-list.component";
import {SubcontractorListComponent} from "./modules/contacts/tables/subcontractor-list.component";
import {SupplierListComponent} from "./modules/contacts/tables/supplier-list.component";
import {OperativeListComponent} from "./modules/contacts/tables/operative-list.component";
import {FixedCostListComponent} from "./modules/product/tables/fixed-cost-list.component";
import {MaterialQuoteListComponent} from "./modules/product/tables/material-quote-list.component";
import {LabourQuoteListComponent} from "./modules/product/tables/labour-quote-list.component";
import {SupplyAndFitQuoteListComponent} from "./modules/product/tables/supply-and-fit-quote-list.component";
import {LabourQuoteTemplateListComponent} from "./modules/product/tables/labour-quote-template-list.component";
import {MaterialQuoteTemplateListComponent} from "./modules/product/tables/material-quote-template-list.component";
import {SupplyAndFitQuoteTemplateListComponent} from "./modules/product/tables/supply-and-fit-quote-template-list.component";
import {PaapiListComponent} from "./modules/bill/tables/paapi-list.component";
import {PafciListComponent} from "./modules/bill/tables/pafci-list.component";
import {PaoaiListComponent} from "./modules/bill/tables/paoai-list.component";
import {LabourQuotePriceListComponent} from "./modules/product/tables/labour-quote-price-list.component";
import {MaterialQuotePriceListComponent} from "./modules/product/tables/material-quote-price-list.component";
import {SupplyAndFitQuotePriceListComponent} from "./modules/product/tables/supply-and-fit-quote-price-list.component";
import {IssueListComponent} from "./modules/project-offsite/tables/issue-list.component";
import {MaterialDeliveryScheduleListComponent} from "./modules/product/tables/material-delivery-schedule-list.component";
import {ApplyToAllButtonComponent} from "./modules/rate-build-up/apply-to-all-button.component";
import {RateBuildUpModule} from "./modules/rate-build-up.module";
import {MaterialOrderItemListComponent} from "./modules/product/tables/material-order-item-list.component";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {UkDateAdapter} from "@generic/uk-date.adapter";
import {ProjectOffsiteModule} from "./modules/project-offsite.module";
import {QuillModule} from "ngx-quill";
import {MaterialQuotePriceEditListComponent} from "./modules/product/tables/material-quote-price-edit-list.component";
import {LabourQuotePriceEditListComponent} from "./modules/product/tables/labour-quote-price-edit-list.component";
import {SupplyAndFitQuotePriceEditListComponent} from "./modules/product/tables/supply-and-fit-quote-price-edit-list.component";
import {SelectWorkPackageButtonComponent} from "./modules/admin/buttons/select-work-package-button.component";
import {AdminModule} from "./modules/admin.module";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AssessmentAuditButtonComponent} from "./modules/assessment/buttons/assessment-audit-button.component";
import {ToastComponent} from "./modules/slate/ui/toast/toast.component";
import {ImportMaterialButtonComponent} from "./modules/product/buttons/import-material-button.component";
import {ImportLabourActivityButtonComponent} from "./modules/product/buttons/import-labour-activity-button.component";
import {DashboardModule} from "./modules/dashboard.module";
import {EditAssessmentProductPricingModeControlsComponent} from "./modules/rate-build-up/edit-assessment-product-pricing-mode-controls.component";
import {AssessmentProductPriceComponent} from "./modules/rate-build-up/assessment-product-price.component";
import {AssessmentGridComponent} from "./modules/project-offsite/tables/assessment-grid.component";
import {ProjectDetailsComponent} from "./modules/project-offsite/tables/project-details.component";
import {EstimatesComponent} from "./modules/assessment/estimates.component";
import {AssessmentConfigPanelComponent} from "./modules/assessment/panels/assessment-config-panel.component";
import {ApplicationModule} from "./modules/application.module";
import {
    GenerateOutgoingApplicationButtonComponent
} from "./modules/application/buttons/generate-outgoing-application-button.component";
import {RateBuildUpIssuesComponent} from "./modules/rate-build-up/issues/rate-build-up-issues.component";
import {RateBuildUpIssueFlagListComponent} from "./modules/rate-build-up/issues/rate-build-up-issue-flag-list.component";
import {RateBuildUpIssueFlagComponent} from './modules/rate-build-up/issues/rate-build-up-issue-flag.component';
import {RateBuildUpIssueService} from "./modules/rate-build-up/issues/rate-build-up-issue.service";
import {ReorderTableComponent} from "@generic/reorder-table/reorder-table.component";

const m = angular.module(appName);

m.directive('nxAddress', downgradeComponent({
    component: AddressComponent,
    inputs: [
        'address',
        'street1',
        'street2',
        'street3',
        'city',
        'county',
        'postcode',
        'country',
        'readonlyAddress'
    ],
    outputs: [
        'addressChange'
    ]
} as angular.IDirectiveFactory));
m.directive('nxApplicationBill', downgradeComponent({component: ApplicationBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentAuditButton', downgradeComponent({component: AssessmentAuditButtonComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentGrid', downgradeComponent({component: AssessmentGridComponent} as angular.IDirectiveFactory));
m.directive('nxProjectDetails', downgradeComponent({component: ProjectDetailsComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentStatusButton', downgradeComponent({component: AssessmentStatusButtonComponent} as angular.IDirectiveFactory));
m.directive('nxTakeoffListButton', downgradeComponent({
    component: TakeoffListButtonComponent,
    inputs: [
        'project',
        'projectId',
        'assessment'
    ]
} as angular.IDirectiveFactory));
m.directive('nxBadge', downgradeComponent({component: BadgeComponent} as angular.IDirectiveFactory));
m.directive('nxConfirmationButton', downgradeComponent({component: ConfirmationButtonComponent} as angular.IDirectiveFactory));
m.directive('nxEditableTable', downgradeComponent({
    component: EditableTableComponent,
    inputs: ['table'],
    outputs: ['onAction', 'onChange']
} as angular.IDirectiveFactory));
m.directive('nxFileTable', downgradeComponent({
    component: FileTableComponent,
    inputs: ['text']
} as angular.IDirectiveFactory));
m.directive('nxReorderTable', downgradeComponent({
    component: ReorderTableComponent,
    inputs: ['items'],
    outputs: ['onOrderChanged']
} as angular.IDirectiveFactory));
m.directive('nxIfGeneratedNow', downgradeComponent({component: IfGeneratedNowComponent} as angular.IDirectiveFactory));
m.directive('nxImportBillOfQuantitiesButton', downgradeComponent({component: ImportBillOfQuantitiesButtonComponent} as angular.IDirectiveFactory));
m.directive('nxIncomingBill', downgradeComponent({component: IncomingBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxLabourActivityList', downgradeComponent({component: LabourActivityListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourBill', downgradeComponent({component: LabourBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxMainNavList', downgradeComponent({component: MainNavListComponent, inputs: ['angular2RoutesOnly']} as angular.IDirectiveFactory));
m.directive('nxHubNavList', downgradeComponent({component: HubNavListComponent, inputs: ['angular2RoutesOnly']} as angular.IDirectiveFactory));
m.directive('nxMaterialBill', downgradeComponent({component: MaterialBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialList', downgradeComponent({component: MaterialListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialDeliveryList', downgradeComponent({component: MaterialDeliveryListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialDeliveryScheduleList', downgradeComponent({component: MaterialDeliveryScheduleListComponent} as angular.IDirectiveFactory));
m.directive('nxClientList', downgradeComponent({component: ClientListComponent} as angular.IDirectiveFactory));
m.directive('nxOperativeList', downgradeComponent({component: OperativeListComponent} as angular.IDirectiveFactory));
m.directive('nxFixedCostList', downgradeComponent({component: FixedCostListComponent} as angular.IDirectiveFactory));
m.directive('nxSubcontractorList', downgradeComponent({component: SubcontractorListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplierList', downgradeComponent({component: SupplierListComponent} as angular.IDirectiveFactory));
m.directive('nxProductOrderList', downgradeComponent({component: ProductOrderListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourApplicationList', downgradeComponent({component: LabourApplicationListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplyAndFitApplicationList', downgradeComponent({component: SupplyAndFitApplicationListComponent} as angular.IDirectiveFactory));
m.directive('nxPaapiList', downgradeComponent({component: PaapiListComponent} as angular.IDirectiveFactory));
m.directive('nxPafciList', downgradeComponent({component: PafciListComponent} as angular.IDirectiveFactory));
m.directive('nxPaoaiList', downgradeComponent({component: PaoaiListComponent} as angular.IDirectiveFactory));
m.directive('nxOrderInvoiceList', downgradeComponent({component: OrderInvoiceListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialQuoteList', downgradeComponent({component: MaterialQuoteListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialOrderItemList', downgradeComponent({component: MaterialOrderItemListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialQuotePriceList', downgradeComponent({component: MaterialQuotePriceListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialQuotePriceEditList', downgradeComponent({component: MaterialQuotePriceEditListComponent} as angular.IDirectiveFactory));
m.directive('nxMaterialQuoteTemplateList', downgradeComponent({component: MaterialQuoteTemplateListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourQuoteList', downgradeComponent({component: LabourQuoteListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourQuotePriceList', downgradeComponent({component: LabourQuotePriceListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourQuotePriceEditList', downgradeComponent({component: LabourQuotePriceEditListComponent} as angular.IDirectiveFactory));
m.directive('nxLabourQuoteTemplateList', downgradeComponent({component: LabourQuoteTemplateListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplyAndFitQuoteList', downgradeComponent({component: SupplyAndFitQuoteListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplyAndFitQuotePriceList', downgradeComponent({component: SupplyAndFitQuotePriceListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplyAndFitQuotePriceEditList', downgradeComponent({component: SupplyAndFitQuotePriceEditListComponent} as angular.IDirectiveFactory));
m.directive('nxSupplyAndFitQuoteTemplateList', downgradeComponent({component: SupplyAndFitQuoteTemplateListComponent} as angular.IDirectiveFactory));
m.directive('nxExternalCostList', downgradeComponent({component: ExternalCostListComponent} as angular.IDirectiveFactory));
m.directive('nxMoveProductLink', downgradeComponent({component: MoveProductLinkComponent} as angular.IDirectiveFactory));
m.directive('nxNavListAssessmentOffSite', downgradeComponent({component: AssessmentOffSiteNavListComponent} as angular.IDirectiveFactory));
m.directive('nxNavListAssessmentOnSite', downgradeComponent({component: AssessmentOnSiteNavListComponent} as angular.IDirectiveFactory));
m.directive('nxNewAssessmentButton', downgradeComponent({component: NewAssessmentButtonComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentToolkitButtons', downgradeComponent({component: AssessmentToolkitButtonsComponent} as angular.IDirectiveFactory));
m.directive('nxPriceSelect', downgradeComponent({component: PriceSelectComponent} as angular.IDirectiveFactory));
m.directive('nxProductTemplateList', downgradeComponent({component: ProductTemplateListComponent} as angular.IDirectiveFactory));
m.directive('nxSelectWorkPackageButton', downgradeComponent({component: SelectWorkPackageButtonComponent} as angular.IDirectiveFactory));
m.directive('nxProgressBill', downgradeComponent({component: ProgressBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxProgressGroupBill', downgradeComponent({component: ProgressGroupBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxProjectRemovalButton', downgradeComponent({component: ProjectRemovalButtonComponent} as angular.IDirectiveFactory));
m.directive('nxQuantityBill', downgradeComponent({component: QuantityBillGridBillComponent} as angular.IDirectiveFactory));
m.directive('nxRolePermissions', downgradeComponent({component: RolePermissionsComponent} as angular.IDirectiveFactory));
m.directive('nxHeaderHelpButton', downgradeComponent({component: HelpButtonComponent} as angular.IDirectiveFactory));
m.directive('nxChangeSupplierButton', downgradeComponent({component: ChangeSupplierButtonComponent} as angular.IDirectiveFactory));
m.directive('nxIssueList', downgradeComponent({component: IssueListComponent} as angular.IDirectiveFactory));
m.directive('nxVerticalColorPicker', downgradeComponent({component: CsVerticalColourPickerComponent} as angular.IDirectiveFactory));
m.directive('nxApplyToAllButton', downgradeComponent({component:ApplyToAllButtonComponent} as angular.IDirectiveFactory));
m.directive('nxAppToast', downgradeComponent({component: ToastComponent}));
m.directive('nxImportMaterialButton', downgradeComponent({component: ImportMaterialButtonComponent} as angular.IDirectiveFactory));
m.directive('nxImportLabourActivityButton', downgradeComponent({component: ImportLabourActivityButtonComponent} as angular.IDirectiveFactory));
m.directive('nxEditAssessmentProductPricingModeControls', downgradeComponent({component: EditAssessmentProductPricingModeControlsComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentProductPrice', downgradeComponent({component: AssessmentProductPriceComponent} as angular.IDirectiveFactory));
m.directive('nxEstimates', downgradeComponent({component: EstimatesComponent} as angular.IDirectiveFactory));
m.directive('nxAssessmentConfigPanel', downgradeComponent({component: AssessmentConfigPanelComponent} as angular.IDirectiveFactory));
m.directive('nxGenerateOutgoingApplicationButton', downgradeComponent({component: GenerateOutgoingApplicationButtonComponent} as angular.IDirectiveFactory));
m.directive('nxRbuIssues', downgradeComponent({component: RateBuildUpIssuesComponent} as angular.IDirectiveFactory));
m.directive('nxRbuIssueFlag', downgradeComponent({"component": RateBuildUpIssueFlagComponent} as angular.IDirectiveFactory));
m.directive('nxRbuIssueFlagList', downgradeComponent({component: RateBuildUpIssueFlagListComponent} as angular.IDirectiveFactory));
m.factory('nxRbuIssueService', downgradeInjectable(RateBuildUpIssueService as any));

const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{'header': [1, 2, 3, false]}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    ['clean'],
];

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpBackend), "i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AdminModule,
        AppRoutingModule,
        ApplicationModule,
        AssessmentModule,
        BillModule,
        BrowserAnimationsModule,
        ChalkstringApiFormModule,
        ChalkstringApiModule,
        CommonModule,
        ConfigModule,
        ContactsModule,
        CsDynamicFormModule,
        EditableTableModule,
        FormsModule,
        GenericModule,
        HttpClientModule,
        NgSelectModule,
        NgxPermissionsModule.forRoot(),
        OrderModule,
        PermissionsModule,
        ProductModule,
        ProjectOffsiteModule,
        QuillModule.forRoot({
            modules: {
                toolbar: toolbarOptions
            }
        }),
        SimpleModalModule.forRoot({container: document.body}, {
            ...defaultSimpleModalOptions, ...{
                // Modal option overrides go here
                closeOnEscape: true,
                closeOnClickOutside: false,
                autoFocus: true,
            }
        }),
        TableViewModule,
        UpgradeModule,
        WorkScopeModule,
        RateBuildUpModule,
        DashboardModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpBackend]
            },
            isolate: false
        }),
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'en-GB'},
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: DateAdapter, useClass: UkDateAdapter, deps: [MAT_DATE_LOCALE, LOCALE_ID]},
        {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: (ps: NgxPermissionsService) => function () {
                const userDetails = localStorage.getItem('userDetails');
                if (userDetails) {
                    const ud = JSON.parse(userDetails);
                    if ('permissions' in ud) {
                        return ps.loadPermissions(ud.permissions);
                    }
                }
            },
            deps: [NgxPermissionsService],
            multi: true
        },
        TableDataService
    ],
    entryComponents: [
        AddressComponent,
        AppComponent,
        ApplicationBillGridBillComponent,
        AssessmentOffSiteNavListComponent,
        AssessmentOnSiteNavListComponent,
        AssessmentStatusButtonComponent,
        BadgeComponent,
        ConfirmationButtonComponent,
        CsVerticalColourPickerComponent,
        FileTableComponent,
        ImportBillOfQuantitiesButtonComponent,
        LabourBillGridBillComponent,
        MainNavListComponent,
        HubNavListComponent,
        MaterialBillGridBillComponent,
        OperativeListComponent,
        QuantityBillGridBillComponent,
        RolePermissionsComponent,
        TakeoffListButtonComponent,
        ImportMaterialButtonComponent,
        ImportLabourActivityButtonComponent,
        AssessmentGridComponent,
        ProjectDetailsComponent
    ]
})
export class AppModule {

    constructor(private upgrade: UpgradeModule) {
    }

    ngDoBootstrap(app): void{
        this.upgrade.bootstrap(document.documentElement, [appName], {strictDi: false});

        app.bootstrap(AppComponent);
    }
}
