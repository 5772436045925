(function(){
    "use strict";

    /**
     * @ngdoc service
     * @name scswebappApp.price
     * @description
     * # price
     * Service in the scswebappApp.
     */
    angular.module("scswebappApp")
        .factory("cost", ['ENV', 'Resource', '$filter', function (ENV, $resource, $filter) {
            return $resource(
                ENV.apiEndpoint + "/costentities/:id", {projectId: "@projectId", id: "@id"}, {
                    getAll: {
                        url: ENV.apiEndpoint + "/costentities",
                        method: "GET",
                        isArray: true
                    },
                    customSave:{
                        transformRequest: function (data) {
                            console.log(data);
                            var costData = {
                                "name": data.name,
                                "assessment": data.assessment.id,
                                "project": data.project.id,
                                "materialAndWasteBudget": data.materialAndWasteBudget,
                                "materialAndWasteBudgetNominalCode": data.materialAndWasteBudgetNominalCode ? data.materialAndWasteBudgetNominalCode.nominalCode : null,
                                "labourBudget": data.labourBudget,
                                "labourBudgetNominalCode": data.labourBudgetNominalCode ? data.labourBudgetNominalCode.nominalCode : null,
                                "contingencyBudget": data.contingencyBudget,
                                "contingencyBudgetNominalCode": data.contingencyBudgetNominalCode ? data.contingencyBudgetNominalCode.nominalCode : null,
                                "fixedCostsBudget": data.fixedCostsBudget,
                                "fixedCostsBudgetNominalCode": data.fixedCostsBudgetNominalCode ? data.fixedCostsBudgetNominalCode.nominalCode : null,
                                "supplyAndFitBudget": data.supplyAndFitBudget,
                                "supplyAndFitBudgetNominalCode": data.supplyAndFitBudgetNominalCode ? data.supplyAndFitBudgetNominalCode.nominalCode : null,
                                "effectiveDate": $filter('date')(data.effectiveDate, 'yyyy-MM-dd HH:mm:ss'),
                                "supplier": data.supplier ? data.supplier.id : null,
                                "vatTaxCode": data.vatTaxCode ? data.vatTaxCode.id: null,
                                "documents": []
                            };

                            if( data.documents ) {
                                angular.forEach(data.documents, function (value) {
                                    console.log(value);
                                    var comment = (value.comment) ? value.comment : null;
                                    if (value.file && value.file.id) {
                                        costData.documents.push({'comment': comment, 'file': value.file.id});
                                    } else {
                                        costData.documents.push({'comment': comment, 'file': value.id});
                                    }
                                });
                            }

                            return angular.toJson(costData);
                        }
                    }
                }
            );

        }]);
})();
