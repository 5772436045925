import {CsUtils} from "../../other/csutils";

(function(){
	'use strict';

	/**
	 * @ngdoc function
	 * @name scswebappApp.controller:ClientsCtrl
	 * @description
	 * # ClientsCtrl
	 * Controller of the scswebappApp
	 */
	angular.module('scswebappApp')
	    .controller('ClientCtrl', function ($scope, $routeParams, $route, client, $rootScope, $location, routeType, ENV, $window, $http) {

            function getClientData(){
                client.get({id: $routeParams.id}).$promise.then(function(data){
                    return $scope.client = data;
                });
            }

            $scope.exportClients = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/clients/exportall'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };

			// Get trade data if edit or view routes
			if ( $routeParams.id ){
				getClientData();
			}

			// Set type keyword for edit and new routes
			$scope.keyWord = routeType.type( $route.current.$$route.isNew, $route.current.$$route.isEdit );

            if( $route.current.$$route.isNew ){
                $scope.client = {
                    emailAddresses: [],
                    telephoneNumbers: [],
                    addresses: [{}]
                };
            }

            $scope.addToData = function (e, objectName, data) {
                let duplicate,
                    newData;
                $scope.errors = [];

                // If client object doesn't exist, create it.
                $scope.client = ($scope.client) ? $scope.client : {};

                // Create array
                $scope.client[objectName] = ($scope.client[objectName]) ? $scope.client[objectName] : [];

                // Add data to the array
                function addData(objectName, data) {
                    $scope.client[objectName].push(data);
                    $scope.newEmail = null;
                    $scope.newTel = null;
                    $scope.newAddress = {};
                }

                // Check for duplicate data
                function duplicateCheck(objectName, data) {
                    angular.forEach($scope.client[objectName], function (value) {
                        if (angular.toJson(value) === angular.toJson(data)) {
                            duplicate = true;
                            $scope.errors[objectName] = true;
                        }
                    });
                    return duplicate;
                }

                // Add data to array if it exists
                if (data && objectName !== 'addresses') {
                    newData = {'detail': data};
                } else {
                    newData = {
                        'street1': data.street1,
                        'street2': data.street2,
                        'street3': data.street3,
                        'city': data.city,
                        'county': data.county,
                        'postcode': data.postcode,
                        'country': data.country
                    };
                }
                if (!duplicateCheck(objectName, newData)) {
                    addData(objectName, newData);
                }

                e.preventDefault();
            };

            $scope.remove = function (objectName, data) {
                angular.forEach($scope.client[objectName], function (value, key) {
                    if (value.detail === data) {
                        delete $scope.client[objectName].splice([key], 1);
                    }
                });
            };


			$scope.createClient = function(clientForm){
				$scope.submitted = true;
				if( clientForm.$valid ) {
					let saveClient = new client($scope.client);
					saveClient.$save().then(function(){
						clientForm.$setPristine();
                        $scope.submitted = false;
						$location.path('/clients');
					});
				}
			};

	    });
})();
