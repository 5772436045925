import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleModalService} from "ngx-simple-modal";

import {Router} from "@angular/router";
import {ApplyToAllModalComponent} from "./modal/apply-to-all-modal.component";
import {Assessment} from "../chalkstring-api/services/assessment.service";

@Component({
    template: `
        <a class="" (click)="openModal()"><i class="icon-calc"></i>Apply to all...</a>
    `,
    selector: 'apply-to-all-button'
})
export class ApplyToAllButtonComponent
{
    @Input() assessment: Assessment;
    @Output() assessmentUpdated = new EventEmitter<boolean>();

    constructor(private _modalService: SimpleModalService, private _router: Router)
    {
    }

    openModal()
    {
        this._modalService.addModal(ApplyToAllModalComponent, {
            assessment: this.assessment
        }).subscribe(res => {
            this.assessmentUpdated.emit(res);
        });
    }
}
