(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MaterialBillCtrl', function ($scope, $rootScope, $routeParams, project, materialOrders, $route, $location, $http, ENV, $window, material, price, FileUploader, assessment, supplier, operative, materialDeliveries, $filter, $locale) {
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.isFiltered = false;
            $scope.setAssessment = function (assessment) {
                $scope.assessment = assessment;
            };

            $rootScope.projectId = $routeParams.id;
            project.get({id: $routeParams.id}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                $scope.project = data;
            });
        });
})();
