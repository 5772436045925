(function(){
    'use strict';

    angular.module('scswebappApp')
        .factory('accountspackage', function ( $resource, ENV ) {
            return $resource(
                ENV.apiEndpoint + '/accountspackages/:id', { id: '@id'}, {
                    getBatches: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + "/batches?accountspackage=:id"
                    },
                    createNewBatch: {
                        method: 'POST',
                        url: ENV.apiEndpoint + "/accountspackages/:id/batches"
                    },
                    getBatch: {
                      method: 'GET',
                      url: ENV.apiEndpoint + "/batches/:id"
                    },
                    delete: {
                      method: 'DELETE',
                      url: ENV.apiEndpoint + "/batches/:id"
                    }
                }
            );
        });
})();
