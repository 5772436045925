import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from "@angular/core";
import {Assessment} from "../../chalkstring-api/services/assessment.service";
import {first} from "rxjs/operators";
import {RateBuildUpIssueService, RbuIssuesResponse, RateBuildUpIssueCount} from "./rate-build-up-issue.service";
import {Subscription} from "rxjs";

@Component({
    template: `
        <strong>Issues: </strong>
        <span *ngIf="loading"><i class="icon-spin3 inline-spinner"></i> Checking...</span>
        <rbu-issue-flag-list *ngIf="!loading && issues" (issueClicked)="handleClick($event)"></rbu-issue-flag-list>
        <span *ngIf="!loading && issues && !issues.assessment.length">None</span>
    `,
    selector: 'rbu-issues',
    styles: [
        'strong { line-height: 25px }',
        'badge { cursor: pointer }'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateBuildUpIssuesComponent implements OnDestroy
{
    loading: boolean = false;
    issues: RbuIssuesResponse;
    issuesSub: Subscription;
    workingSub: Subscription;
    private _assessment: Assessment;
    @Input() set assessment(a: Assessment)
    {
        this._assessment = a;
        if (a) {
            this._riService.fetchRbuIssues(this.assessment);
        }
    }
    get assessment(): Assessment
    {
        return this._assessment;
    }
    @Output() issueSelected: EventEmitter<RateBuildUpIssueCount> = new EventEmitter<RateBuildUpIssueCount>();
    
    public constructor(private _riService: RateBuildUpIssueService, private _change: ChangeDetectorRef)
    {
        this.issues = this._riService.lastResponse;
        this.issuesSub = this._riService.issuesFetched.subscribe(issues => {
            this.issues = issues;
            this._change.detectChanges();
        });
        this.workingSub = this._riService.working.subscribe(isWorking => {
            this.loading = isWorking;
            this._change.detectChanges();
        });
    }

    ngOnDestroy()
    {
        if (this.issuesSub) this.issuesSub.unsubscribe();
        if (this.workingSub) this.workingSub.unsubscribe();
    }

    public handleClick(i: RateBuildUpIssueCount)
    {
        this.issueSelected.emit(i);
    }
}
