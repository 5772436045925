import {Component, Input} from "@angular/core";
import {ProjectService} from "../../chalkstring-api/services/project.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CsUtils} from "../../../scripts/other/csutils";
import {AccessResource, PermissionsService} from "../../permissions/permissions.service";
import {DashboardCommercialData} from "../../chalkstring-api/services/dashboard.service";
import {MediaDirective} from "@generic/media.directive";
import {Trigger} from "ngx-popper";

@Component({
    selector: 'dashboard-summary',
    template: require('./dashboard-summary.component.html'),
    styles: ['.subtle { opacity: 0.7; }']
})
export class DashboardSummaryComponent
{
    canReadCommercialData: boolean = false;
    popperTrigger: Trigger = MediaDirective.isMobile() ? 'click' : 'hover';

    @Input() commercialData: DashboardCommercialData;
    @Input() public showOperational: boolean;

    public exportUrl: string;

    constructor(
        private _projectService: ProjectService,
        private _permissions: PermissionsService,
        protected _http: HttpClient,
    )
    {
        this._permissions.hasReadPermission(AccessResource.DashboardCommercialData)
            .then(has => this.canReadCommercialData = has);
    }

    exportCommercialData()
    {
        const url = '/dashboard/export-commercial-data';
        let params = new HttpParams();
        if (this.commercialData.projects.length > 0) {
            for (let project of this.commercialData.projects) {
                params = params.append('id[]', project.projectId.toString());
            }
        }

        this._http.get(
            url,
            {observe: 'response', responseType: "blob", params: params}
        ).subscribe(res => {
            CsUtils.download(res.body, res.headers, window);
        });
    };
}
