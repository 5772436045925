import {Component} from "@angular/core";
import {AbstractNavigableTableViewComponent} from "../../table-view/abstract-table-view.component";
import {Router} from "@angular/router";
import {TableViewColumn} from "../../table-view/table-view-column";
import {TableViewFilterText} from "../../table-view/filters/table-view-filter-text.component";
import {Observable, of} from "rxjs";
import {TableViewCellContactMethodCollectionComponent} from "../../table-view/cells/table-view-cell-contact-method-collection.component";
import {Operative, OperativeService} from "../../chalkstring-api/services/operative.service";
import {TableViewCellNamedCollectionComponent} from "../../table-view/cells/table-view-cell-named-collection.component";
import {TableViewFilterSelect} from "../../table-view/filters/table-view-filter-select.component";
import {OperativeTradeService} from "../../chalkstring-api/services/operative-trade.service";

@Component({
    selector: 'operative-list',
    styles: [require('../../table-view/abstract-table-view.component.scss')],
    template: require('../../table-view/abstract-table-view.component.html'),
})
export class OperativeListComponent extends AbstractNavigableTableViewComponent<Operative>
{
    constructor(
        protected _service: OperativeService,
        protected _router: Router,
        private _otService: OperativeTradeService
    )
    {
        super(_service, _router);
    };

    protected _initColumnSet(): Observable<TableViewColumn[]>
    {
        let cols = [];

        const lNameCol = new TableViewColumn('lastName', 'Last name');
        lNameCol.filter = new TableViewFilterText(lNameCol);
        lNameCol.filter.autoActivate = true;
        cols.push(lNameCol);

        const fNameCol = new TableViewColumn('firstName', 'First name');
        fNameCol.filter = new TableViewFilterText(fNameCol);
        cols.push(fNameCol);

        const phoneCol = new TableViewColumn('telephoneNumbers', 'Telephone numbers', TableViewCellContactMethodCollectionComponent);
        cols.push(phoneCol);

        const tradesCol = new TableViewColumn('trades', 'Trades', TableViewCellNamedCollectionComponent);
        tradesCol.filter = new TableViewFilterSelect(tradesCol, this._otService);
        tradesCol.filter.paramName = 'trade';
        cols.push(tradesCol);

        const cityCol = new TableViewColumn('addresses', 'City');
        cityCol.propertyAccessor = (row: Operative) => row.addresses ? row.addresses[0]?.city : null;
        cityCol.filter = new TableViewFilterText(cityCol);
        cityCol.filter.paramName = 'city';
        cols.push(cityCol);

        return of(cols);
    }
}
