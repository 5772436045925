import {CsUtils} from "../../other/csutils.js";

(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:RateBuildUpCtrl
     * @description
     * # RateBuildUpCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ExportRateBuildUpCtrl', function ($scope, $rootScope, $routeParams, assessment, $http, ENV, $window) {

            // Get assessment details
            assessment.get({id: $routeParams.id}).$promise.then(function(data){
                $scope.assessment = data;
            }).then(function(){
                var productsQuery = {
                    assessment: $routeParams.id,
                    limit: 3000,
                    sort: 'assessmentOrder'
                };
                assessment.getAssessmentProductsList(productsQuery).$promise.then(function (data) {
                    $scope.assessmentProducts = data;
                });
            });

            $scope.products = [];

            /* Add product to export list */
            $scope.addProduct = function(id){
                if( $scope.products.indexOf(id) > -1 ){
                    $scope.products.splice($scope.products.indexOf(id), 1);
                }else{
                    $scope.products.push(id);
                }
            };

            /* Select all products */
            $scope.selectAll = function(){
                $scope.products = [];
                angular.forEach($scope.assessmentProducts, function(value){
                    $scope.products.push(value.id);
                })
            };

            /* Export RBU */
            $scope.exportProducts = function(){
                if( $scope.products.length > 0 ){
                    $http({
                        method: 'POST',
                        data: {assessmentProducts: $scope.products},
                        responseType:'arraybuffer',
                        url: ENV.apiEndpoint + '/assessments/' + $routeParams.id + '/export-rate-build-up'
                    }).success(function(data, status, headers) {
                        CsUtils.download(data, headers, $window);

                    });
                }else{
                    $scope.$emit('showOrNotification', 'Please select at least 1 product to export', true);
                }
            };

        });
})();
