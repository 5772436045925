import {Component} from "@angular/core";
import {AccessResource, PermissionsService} from "../../permissions/permissions.service";
import {Project, ProjectService} from "../../chalkstring-api/services/project.service";
import {first} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {DashboardCommercialData, DashboardService} from "../../chalkstring-api/services/dashboard.service";

@Component({
    template: require('./dashboard.component.html'),
})
export class DashboardCtrlComponent
{
    private readonly _storageKey = 'dashboard-project-ids';

    public canReadCommercialData: boolean = false;
    public showOperational: boolean = false;
    public allProjects: Project[];
    public commercialData: DashboardCommercialData;

    private _selectedProjectIds = [];
    public set selectedProjectIds(ids: number[])
    {
        this._selectedProjectIds = ids;
        if (Array.isArray(ids)) {
            // Save IDs to local storage
            localStorage.setItem(this._storageKey, JSON.stringify(this.selectedProjectIds));
            this.fetchDashboardData();
        }
    }
    public get selectedProjectIds(): number[]
    {
        return this._selectedProjectIds;
    }

    constructor(
        private _dService: DashboardService,
        private _permissions: PermissionsService,
        private _pService: ProjectService
    )
    {
        _permissions.hasReadPermission(AccessResource.DashboardCommercialData)
            .then(has => this.canReadCommercialData = has);
        this._pService.getAllSimple().pipe(first()).subscribe(res => this.allProjects = res);

        // Load IDs from local storage
        this.selectedProjectIds = JSON.parse(localStorage.getItem(this._storageKey));
    }

    selectAll()
    {
        this.selectedProjectIds = this.allProjects.map(p => p.id);
    }

    unselectAll()
    {
        this.selectedProjectIds = [];
    }

    fetchDashboardData()
    {
        // Fetch project data
        let params = new HttpParams();
        for (let id of this.selectedProjectIds) {
            params = params.append('id[]', id.toString());
        }
        this._dService.getCommercialData(params).subscribe(data => {
            this.commercialData = data;
        });
    };
}
