(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MaterialInvoiceCtrl', function ($scope, $rootScope, $routeParams, project, PermPermissionStore, materialOrderInvoices, $route, $location, $http, ENV, $window, FileUploader, $filter, $locale, materialOrders) {
            $scope.hasVatCodePermission = PermPermissionStore.hasPermissionDefinition('canReadAccountsVATCodes');

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            // Document uploader
            $scope.invoiceUploader = {
                uploader: new FileUploader(),
                list: []
            };

            // Project data
            if ($routeParams.projectId) {
                $rootScope.projectId = $routeParams.projectId;
                project.get({id: $routeParams.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });
            } else if ($routeParams.orderId) {
                // Attach Invoice
                materialOrders.get({id: $routeParams.orderId}).$promise.then(function(data){
                    $scope.order = data;
                    $scope.project = data.project;
                    $rootScope.projectId = $scope.project.id;
                    if ($scope.hasVatCodePermission) {
                        materialOrders.getDefaultVatCode({id: data.id}).$promise.then(function (data) {
                            $scope.defaultVatCode = data;
                            if (!$scope.invoice.vatTaxCode) {
                                $scope.invoice.vatTaxCode = data;
                            }
                        });
                    }
                });

                $scope.invoice = {invoiceDate: new Date, vatTaxCode: null};
                $scope.raiseInvoiceForm = {};
                $scope.raiseInvoice = function (form, noDocument) {
                    $scope.submitted = true;
                    if (form.$valid) {
                        if (!form.invoiceDocument && !noDocument) {
                            $scope.invoiceDocumentWarning.open();
                        } else {
                            $scope.invoiceDocumentWarning.close();
                            var formData = {
                                order: $scope.order.id,
                                invoiceReference: form.invoiceReference.$modelValue,
                                invoiceDate: form.invoiceDate.$modelValue,
                                invoicedValue: form.invoicedValue.$modelValue
                            };
                            if ($scope.invoice.vatTaxCode) {
                                formData.vatTaxCode = $scope.invoice.vatTaxCode.id;
                            }
                            if (form.invoiceDocument) {
                                formData.invoiceDocument= form.invoiceDocument.id;
                            }
                            materialOrderInvoices.post(null, formData).$promise.then(function (data) {
                                $location.path('/project-hub/material-invoice/' + data.id);
                            });
                        }
                    }
                };

            } else if ($routeParams.id) {
                // Single page
                $scope.payForm = {approvedForPayment: 0};
                $scope.queryForm = {};
                $scope.resolutionForm = {};

                materialOrderInvoices.get({id: $routeParams.id}).$promise.then(function(data){
                    if (null === data.approvedValue) {
                        data.approvedValue = data.invoicedValue;
                    }

                    $scope.invoice = data;
                    $scope.invoice.invoiceDate = new Date(data.invoiceDate);
                    $scope.order = data.order;
                    $scope.project = data.order.project;

                    $rootScope.projectId = data.order.project.id;
                    $scope.payForm.approvedForPayment = data.approvedValue;

                    if ($route.current.$$route.isEdit) {
                        // Edit invoice
                        $scope.raiseInvoice = function (form) {
                            $scope.submitted = true;
                            if (form.$valid) {
                                var formData = {
                                    invoicedValue: form.invoicedValue.$modelValue,
                                    invoiceReference: form.invoiceReference.$modelValue,
                                    invoiceDate: form.invoiceDate.$modelValue,
                                    vatTaxCode: $scope.invoice.vatTaxCode.id
                                };
                                if (form.invoiceDocument) {
                                    formData.invoiceDocument = form.invoiceDocument.id;
                                }
                                materialOrderInvoices.patch({id: $scope.invoice.id}, formData).$promise.then(function (data) {
                                    $location.path('/project-hub/material-invoice/' + data.id);
                                });
                            }
                        };
                    }
                });

                $scope.approve = function () {
                    if ($scope.invoice.state === 'Approved') {
                        if (!confirm('This Invoice has already been approved. Update approved value?')) {
                            return;
                        }
                    }
                    console.log($scope.payForm);
                    $scope.submitted = true;
                    if (!$scope.invoice.approvedValue) {
                        $scope.payError = true;
                    } else {
                        materialOrderInvoices.approve({id: $routeParams.id}, {approvedForPayment: $scope.invoice.approvedValue}).$promise.then(function(data){
                            $scope.payError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.query = function () {
                    $scope.submitted = true;
                    if (!$scope.queryForm.queryNotes) {
                        $scope.queryError = true;
                    } else {
                        materialOrderInvoices.queryInvoice({id: $routeParams.id}, {queryNotes: $scope.queryForm.queryNotes}).$promise.then(function(data){
                            $scope.queryError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.resolve = function () {
                    $scope.submitted = true;
                    if (!$scope.resolutionForm.queryResolutionNotes) {
                        $scope.resolutionError = true;
                    } else {
                        materialOrderInvoices.resolve({id: $routeParams.id}, {queryResolutionNotes: $scope.resolutionForm.queryResolutionNotes}).$promise.then(function(data){
                            $scope.resolutionError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.pay = function () {
                    materialOrderInvoices.pay({id: $routeParams.id}).$promise.then(function(data){
                        $scope.invoice = data;
                    });
                };
                $scope.attachInvoice = function () {
                    var invoice = new materialOrderInvoices({
                        invoiceDate: $scope.invoice.invoiceDate,
                        invoiceDocument: $scope.invoice.invoiceDocument.id});
                    invoice.$patch({id: $routeParams.id});
                }
            }

            if ($route.current.$$route.isList) {
                $scope.invoiceTableServiceParams = {'project': $rootScope.projectId, 'orderCategory':'material'};
                // $scope.tableConfig = {
                //     config: {
                //         service: 'materialOrderInvoices',
                //         serviceMethod: 'query',
                //         singlePageUrl: 'project-hub/material-invoice',
                //         singlePageId: 'id',
                //         customQuery: {'project': $rootScope.projectId, 'orderCategory':'material'},
                //         itemsPerPage: 50,
                //         logData: false,
                //         sortBy: '-createdAt'
                //     },
                //     cols: [
                //         {
                //             text: 'Ref.',
                //             data: 'invoiceReference',
                //             hasTextFilter: true
                //         },
                //         {
                //             text: 'Invoice date',
                //             data: 'invoiceDate',
                //             hasDateFilter: true,
                //             dataFilter: {
                //                 type: 'date',
                //                 format: 'dd/MM/yyyy'
                //             }
                //         },
                //         {
                //             text: 'Order no.',
                //             data: 'orderNumber',
                //             hasTextFilter: true
                //         },
                //         {
                //             text: 'Supplier',
                //             data: 'supplierFullName',
                //             dropdownFilter: {
                //                 service: 'supplier',
                //                 method: 'query',
                //                 label: 'fullName',
                //                 queryString: 'supplier',
                //                 filterParam: 'id',
                //                 emptyText: 'Filter by supplier'
                //             }
                //         },
                //         {
                //             text: 'Status',
                //             data: 'state',
                //             dropdownFilter: {
                //                 service: [{state: 'Review'}, {state: 'Query'}, {state: 'Approved'}, {state: 'Rejected'}, {state: 'Paid'}],
                //                 label: 'state',
                //                 method: 'query',
                //                 queryString: 'state[]',
                //                 filterParam: 'state',
                //                 emptyText: 'Filter by status',
                //                 selectionMode: 'multiple',
                //                 helperElements: ''
                //             },
                //             greenCondition: 'Review', //e.g. stateStatus === 'Held'
                //             orangeCondition: 'Query', //e.g. stateStatus === 'Held'
                //             redCondition: 'Rejected' //e.g. stateStatus === 'Held'
                //         },
                //         {
                //             text:'Created',
                //             data: 'createdAt',
                //             dataFilter: {
                //                 type:'date',
                //                 format: 'dd/MM/yyyy'
                //             }
                //         },
                //         {
                //             text: 'Created by',
                //             data: 'createdByFullName'
                //         },
                //         {
                //             text: 'Invoiced value',
                //             data: 'invoicedValue',
                //             dataFilter: {
                //                 type:'currency'
                //             }
                //         },
                //         {
                //             text: 'Approved value',
                //             data: 'approvedValue',
                //             dataFilter: {
                //                 type:'currency'
                //             }
                //         }
                //     ]
                // };
            }
        });
})();
