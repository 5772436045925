/**
 * Created by jake on 25/08/2016.
 */

(function(){
    'use strict';

    /**
     * @ngdoc directive
     * @name scswebappApp.directive:stringToNumber
     * @description
     */
    angular.module('scswebappApp')
        .directive('stringToNumber', function() {
            return {
                require: 'ngModel',
                link: function(scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function(value) {
                        return '' + value;
                    });
                    ngModel.$formatters.push(function(value) {
                        return parseFloat(value);
                    });
                }
            };
        });
})();
