(function(){
	'use strict';
	angular.module('scswebappApp')
		.service('routeType', function () {
			return{
				type: function(isNew) {
					return (isNew) ? 'New' : 'Edit';
				}
			};
		});
})();