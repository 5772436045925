(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.assessment
	 * @description
	 * # assessment
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('assessmentFixedCost', ['Resource','ENV', function ($resource, ENV) {

            function transformObject(data) {
                var saveObj = {
                    assessment: data.assessment,
                    quantity: data.quantity,
                    originalFixedCost: data.originalFixedCost.id,
                    assessmentPricePeriodLength: data.assessmentPricePeriodLength,
                    fixedCostProfitPerPeriod: data.originalFixedCost.profitPerPeriod
                };
                return angular.toJson(saveObj);
            }

			return $resource(
			ENV.apiEndpoint + '/assessmentfixedcosts/:id', { id: '@id' }, {
				customSave: {
					transformRequest: function(data){
						return transformObject(data);
					}
				},
				update: {
					method: 'PATCH',
                    transformRequest: function(data){
                        return transformObject(data);
                    }
				}
			}
		);
	}]);
})();
