import {Component} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {AssessmentProduct} from "../../chalkstring-api/services/assessment-product.service";
import {Assessment} from 'app/modules/chalkstring-api/services/assessment.service';
import {
    EditAssessmentProductModel
} from "../../rate-build-up/modal/edit-assessment-product-pricing-modes-modal.component";

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content modal-content-size-xl">
            <div class="modal-header">
                <h2>Assessment quantities for <strong>{{assessmentProduct.name}}</strong></h2>
            </div>
            <div class="modal-body">
                <quantity-grid-bill *ngIf="assessmentProduct" [isHub]="onSite" [assessment]="assessment" [initFilters]="{assessmentProduct: assessmentProduct.id}" [hideFilters]="true"></quantity-grid-bill>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" (click)="close()">
                    <i class="icon-cancel"></i> Close
                </button>
            </div>
        </div>
    `,
})
export class AssessmentProductQuantitiesModalComponent extends SimpleModalComponent<EditAssessmentProductModel, AssessmentProduct> implements EditAssessmentProductModel
{
    onSite: boolean;
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
}
