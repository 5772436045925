(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.role
     * @description
     * # role
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('role', ['ENV', 'Resource', function (ENV, $resource) {
            return $resource(
                ENV.apiEndpoint + '/roles/:id', {id: '@id', permissionId: '@permissionId', groups: '@groups'}, {
                    update: {
                        method: 'PATCH'
                    },
                }
            );
        }]);
})();
