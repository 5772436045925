import ManyAssociationPatcher from '../../other/ManyAssociationPatcher.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:QuoteCtrl
     * @description
     * # QuoteCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('SupplyFitQuoteCtrl', function ($routeParams, $rootScope, $route, $scope, subcontractor, project, FileUploader, supplyFitQuote, $location, routeType, material, quotePrice, $locale) {

            $scope.quoteId = $routeParams.id;

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            $scope.patcher = new ManyAssociationPatcher('product');

            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

            $scope.fileUploader = {
                uploader: new FileUploader
            };

            let year = new Date().getFullYear();

            $scope.nextYear = parseInt(year + 1);

            $scope.quoteDate = new Date();
            $scope.quoteExpires = new Date();
            $scope.quoteExpires.setYear($scope.nextYear);


            $scope.formatForm = function () {
                $scope.quoteDate = new Date($scope.quote.quoteDate);
                $scope.quoteExpires = new Date($scope.quote.quoteExpires);
            };

            $scope.quote = {
                documents: [],
                prices: []
            };
            $scope.pinnedRows = [];
            $scope.quotePrices = [];

            $scope.newProduct = false;
            $scope.showNewProduct = function () {
                $scope.newProduct = true;

                //Scroll to top
                window.scrollTo(0, 0);
            };
            $scope.closeNewProductModal = function () {
                $scope.newProduct = false;
            };
            $scope.$on('productSaved', function () {
                console.log('CLOSED SUBMITTED');
                $scope.newProduct = false;
            });

            // Duplicating quotes
            $scope.showDuplicate = false;
            $scope.toggleModal = function () {
                $scope.showDuplicate = !$scope.showDuplicate;
            };
            $scope.duplicate = function () {
                $scope.duplicateSubmitted = true;

                if ($scope.duplicateForm.$valid) {
                    supplyFitQuote.clone({
                        sourceQuote: $scope.quoteId,
                        modifier: $scope.duplicateData.modifier,
                        name: $scope.duplicateData.name
                    }).$promise.then(function (data) {
                        $scope.duplicateForm.$setPristine();
                        $scope.quoteForm.$setPristine();
                        $location.path('/sf-quote/' + data.id);
                        $scope.duplicateSubmitted = false;
                    });
                }
            };

            let isUniqueProduct = function(item) {
                let isUnique = true;
                angular.forEach($scope.pinnedRows, function (pr) {
                    if (pr.id === item.id) {
                        isUnique = false;
                    }
                });
                angular.forEach($scope.quotePrices, function (qp) {
                    if (qp.productId === item.id) {
                        isUnique = false;
                    }
                });
                return isUnique;
            };
            $scope.setQuotePrices = function (prices) {
                $scope.quotePrices = prices;
            }
            $scope.addProductsToQuote = function (products) {
                angular.forEach(products, function (product) {
                    if (isUniqueProduct(product)) {
                        $scope.patcher.addAddOperation(
                            product,
                            {
                                'product': product.id
                            }
                        );
                        $scope.pinnedRows.push(product);
                    }
                });

                $scope.productListModal.close();

                if ($scope.patcher.isDirty()) {
                    $scope.quoteForm.$setDirty();
                }
            };

            if ($route.current.$$route.isNew) {
                $scope.priceTableParams = {quote: 0};
                subcontractor.query({limit: 5000}).$promise.then(function (data) {
                    $scope.subcontractors = data;
                }).then(function () {
                    project.query({limit: 5000}).$promise.then(function (data) {
                        $scope.projects = data;
                    })
                })
            } else {
                $scope.priceTableParams = {quote: $routeParams.id};
            }

            if ($route.current.$$route.isSingle) {
                supplyFitQuote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                    $scope.formatForm();
                });
            }

            if ($route.current.$$route.isEdit) {

                supplyFitQuote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                    $scope.formatForm();
                }).then(function (data) {
                    return subcontractor.query({limit: 5000}).$promise;
                }).then(function (data) {
                    // Map Subcontractors
                    angular.forEach(data, function (value) {
                        if ($scope.quote.subcontractor && value.id === $scope.quote.subcontractor.id) {
                            value.ticked = true;
                        }
                    });
                    $scope.subcontractors = data;
                }).then(function () {
                    return project.query({limit: 5000}).$promise;
                }).then(function (data) {
                    // Map projects
                    angular.forEach($scope.quote.projects, function (value) {
                        angular.forEach(data, function (value2) {
                            if (value.id === value2.id) {
                                value2.ticked = true;
                            }
                        })
                    });
                    $scope.projects = data;
                })
            }

            // Save quote
            $scope.saveQuote = function () {

                $scope.submitted = true;

                $scope.quote.quoteDate = $scope.quoteDate.toISOString().slice(0, 10);
                $scope.quote.quoteExpires = $scope.quoteExpires.toISOString().slice(0, 10);

                let saveQuote = new supplyFitQuote($scope.quote);

                $scope.subcontractorError = $scope.quote.subcontractor.length < 1;

                if ($scope.quoteForm.$valid && !$scope.subcontractorError) {

                    if ($scope.quoteId) {
                        let saveQuoteMethod = function () {
                            supplyFitQuote.update(saveQuote).$promise.then(function (data) {
                                $scope.quoteForm.$setPristine();

                                return data;
                            }).then(function (data) {
                                $scope.quoteForm.$setPristine();
                                $location.path('/sf-quote/' + data.id);
                            });
                        };

                        let operations = $scope.patcher.getChangeSet();
                        if ($scope.patcher.isDirty()) {
                            supplyFitQuote.patchPrices({id: $scope.quoteId}, operations, function () {
                                $scope.patcher.clear();
                                saveQuoteMethod();
                            });
                        } else {
                            saveQuoteMethod();
                        }
                    } else {
                        angular.forEach($scope.patcher.getAddQueue(), function (newPriceObject, productId) {
                            saveQuote.prices.push({
                                'product': productId,
                                'price': newPriceObject.price
                            });
                        });

                        saveQuote.$save().then(function (data) {
                            $scope.quoteForm.$setPristine();
                            $location.path('/sf-quote/' + data.id);
                        });
                    }

                }
            };
        });
})();
