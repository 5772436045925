(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:QuoteCtrl
     * @description
     * # QuoteCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('SupplyFitQuoteListCtrl', function ($routeParams, $rootScope, $route, $scope, subcontractor, project, FileUploader, supplyFitQuote, $location, routeType, material, quotePrice, $locale) {

            $scope.quoteId = $routeParams.id;

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            supplyFitQuote.stats().$promise.then(function (data) {
                $scope.stats = data;
            });
        });
    })();
