'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.notification
 * @description
 * # notification
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
	.factory('notification', ['Resource', 'ENV', function ( $resource, ENV ) {
		return $resource(
			ENV.apiEndpoint + '/projects/:id/notify-role', { id: '@id' }, {
				getUnread: {
					url: ENV.apiEndpoint + '/notifications/my-unread',
					method: 'GET',
					isArray: true
				},
				markAsRead: {
					url: ENV.apiEndpoint + '/notifications/:id/read',
					method: 'POST'
				}
			}
		);
	}]);