(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.supplyAndFitPrice
     * @description
     * # supplyAndFitPrice
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('supplyAndFitPrice', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/supplyandfitprices/:id', {id: '@id'}, {
                    create:{
                        method:'POST',
                        transformRequest: function(data){
                            console.log(data);
                            var supplyAndFitPrice = {
                                'price': data.price,
                                'unquotedSubcontractor': data.unquotedSubcontractor,
                                'product': data.product
                            };
                            console.log(supplyAndFitPrice);

                            return angular.toJson(supplyAndFitPrice);
                        }
                    },
                    update:{
                        method:'PUT',
                        transformRequest: function(data){
                            var supplyAndFitPrice = {
                                'price': data.price,
                                'unquotedSubcontractor': data.unquotedSubcontractor,
                                'product': data.product
                            };

                            return angular.toJson(supplyAndFitPrice);
                        }
                    },
                    archive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/supplyandfitprices/:id/archive'
                    },
                    unarchive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/supplyandfitprices/:id/unarchive'
                    }
                }
            );
        }]);
})();