(function() {
    'use strict';
    angular.module('scswebappApp')
           .filter('sentence', function() {
               return function(input) {
                   if (input.length == 0) {
                       return "";
                   }
                   let sentence = input.split(" ");
                   for (var i = 0 ; i < sentence.length ; i++) {
                       if (i == 0) {
                           sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
                       } else {
                           sentence[i] = sentence[i].toLowerCase();
                       }
                   }
                   return sentence.join(" ");
               };
           });
})();
