    (function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.card
	 * @description
	 * # card
	 * Service in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .service('entity', ['Resource','ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/:entity/:id/history', { id: '@id', entity: '@entity' }, {
					'get': {
						method: 'GET',
						transformResponse: function(data, header, status, config, statusText) {
							data = angular.fromJson(data);
							if (status === 404) {
								data.ignoreError = true;
							}
							return data;
						}
					}
	            }
	        );
	  }]);
})();
