(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.labourskills
	 * @description
	 * # labourskills
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('labouractivity', ['Resource', 'ENV', function ($resource, ENV) {
					return $resource(
							ENV.apiEndpoint + '/labouractivities/:id', {id: '@id', assessmentZoneId:'@assessmentZoneId', projectId: '@projectId', assessment: '@assessment'}, {
										customSave:{
												transformRequest: function(data){

														var newData = {
																'name': data.name,
																'description': data.description,
																'trade': data.trade,
																'quantityUnit': data.quantityUnit,
																'externalLinks': []
														};

														angular.forEach(data.externalLinks, function(value){
																newData.externalLinks.push({url: value.url, label: value.label});
														});

														if( data.dataSheets ){
																newData.dataSheets = [];
																angular.forEach(data.dataSheets, function(datasheet){
																		var comment;
																		if(datasheet.comment){
																				comment = datasheet.comment;
																		}else if(datasheet.file){
																				comment = datasheet.file.comment;
																		}
																		var id = (datasheet.file) ? datasheet.file.id : datasheet.id;
																		newData.dataSheets.push({comment: comment, file: id});
																});
														}

														return angular.toJson(newData);
												}
										},
										patchAssessmentLabourComponentActivities:{
												method: 'PATCH',
												url:  ENV.apiEndpoint + '/assessmentcomponentactivities/:id'
										},
										archive: {
												method: 'PATCH',
												url: ENV.apiEndpoint + '/labouractivities/:id/archive'
										},
										unarchive: {
												method: 'PATCH',
												url: ENV.apiEndpoint + '/labouractivities/:id/unarchive'
										},
										getBillReview: {
												method: 'GET',
												isArray: true,
												url: ENV.apiEndpoint + '/projects/:id/labour-application-review'
										},
										modifyBillItem: {
												method: 'PATCH',
												url: ENV.apiEndpoint + '/paymentapplicationlabouritems/:id'
										},
										approveApplication: {
												method: 'POST',
												url: ENV.apiEndpoint + '/paymentapplications/:id/approve?assessmentZone=:assessmentZoneId'
										},
										pricesForProject: {
												method: 'GET',
												isArray: true,
												url: ENV.apiEndpoint + '/projects/:projectId/labour-activities/:id/prices'
										}
							}
					);
		}]);
})();
