'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:AssessmentAdjustmentsCtrl
 * @description
 * # AssessmentAdjustmentsCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
    .controller('AssessmentAdjustmentsCtrl', function (
        $scope,
        $rootScope,
        $routeParams,
        assessment,
        project,
        PermissionStrategies,
        PermPermissionStore,
    ) {
        $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteProjectsOffsiteAssessments');
        $scope.PermissionStrategies = PermissionStrategies;
        $scope.assessmentId = $routeParams.assessmentId;

        $rootScope.$emit('updateEstimates', $scope.assessmentId);

        // Get assessment details
        $scope.getAssessment = function () {
            assessment.get({id: $routeParams.assessmentId}).$promise.then(function (data) {
                $scope.assessment = data;
                $scope.packageId = data.projectWorkPackage.id;
                $scope.totalAssessmentCost = data.estimationMaterials + data.estimationLabour + data.estimationPreliminaries + data.estimationAncillaries;
                return data;
            }).then(function (assessment) {
                //Get project details
                project.get({id: assessment.projectWorkPackage.project.id}).$promise.then(function (data) {
                    $scope.project = data;
                    $scope.projectId = data.id;
                });
            });
        };
        $scope.getAssessment();

        function updateAssessmentEstimates() {
            delete $scope.assessment.estimate;
            assessment.updateAssessmentEstimates({id: $scope.assessmentId}).$promise.then(function (data) {
                $scope.assessment = data;
            });
        }

        $scope.getAdjustments = function () {
            assessment.getAdjustments({id: $routeParams.assessmentId}).$promise.then(function (data) {
                $scope.adjustments = data;

                //Set next data.adjustmentOrder value
                $scope.data = ($scope.data) ? $scope.data : {};
                $scope.data.adjustmentOrder = 1;
                angular.forEach(data, function (value) {
                    if (value.adjustmentOrder >= $scope.data.adjustmentOrder) {
                        $scope.data.adjustmentOrder = value.adjustmentOrder + 1;
                    }
                });
            })
        };
        $scope.getAdjustments();

        $scope.saveAdjustment = function (adjustmentForm) {
            $scope.submitted = true;

            if (adjustmentForm.$valid) {
                $scope.data.assessment = $routeParams.assessmentId;
                assessment.addAdjustment({}, $scope.data).$promise.then(function (data) {
                    $scope.adjustments.push(data);
                    $scope.submitted = false;
                    $scope.getAssessment();
                    $scope.data.name = null;
                    $scope.data.percentageAdjustment = null;
                    $scope.data.clientDisclosed = false;
                }).then(function () {
                    updateAssessmentEstimates();
                })
            } else {
                console.log(adjustmentForm);
            }
        };

        $scope.deleteAdjustment = function (id) {
            assessment.deleteAdjustment({id: id}).$promise.then(function () {
                $scope.getAssessment();
                $scope.getAdjustments();
            }).then(function () {
                updateAssessmentEstimates();
            });
        };

        $scope.updateAdjustment = function (data) {
            assessment.updateAdjustment({id: data.id}, {
                name: data.name,
                clientDisclosed: data.clientDisclosed,
                percentageAdjustment: data.percentageAdjustment
            }).$promise.then(function () {
                $scope.getAssessment();
            }).then(function () {
                updateAssessmentEstimates();
            });
        }
    });
