
export class CsEvent
{
    public static track(eventName: string, meta: any)
    {
        window['userpilot'].track(eventName, meta);
    }

    public static reloadUserpilot()
    {
        window['userpilot'].reload();
    }
}
