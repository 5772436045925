(function () {
    'use strict';

    angular.module('my.resource', ['ngResource']).factory('Resource', function ($resource) {
        return function (url, params, methods, querySortBy) {

            var defaults = {
                update: {method: 'put', isArray: false},
                create: {method: 'post'}
            };

            if (querySortBy) {
                defaults.query = {
                    params: {
                        sort: querySortBy
                    },
                    isArray: true
                };
            }

            methods = angular.extend(defaults, methods);

            if (typeof methods.customSave !== 'undefined') {
                methods.create.transformRequest = methods.customSave.transformRequest;
                methods.update.transformRequest = methods.customSave.transformRequest;
            }

            var resource = $resource(url, params, methods);

            resource.prototype.$save = function (callback) {
                if (!this.id) {
                    return this.$create(function (data) {
                        if (callback) {
                            callback(data);
                        }
                    });
                } else {
                    var id = this.id;
                    delete this.id;
                    return this.$update({id: id}, function (data) {
                        if (callback) {
                            callback(data);
                        }
                    });
                }
            };

            return resource;
        };
    });
})();
