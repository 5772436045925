(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.quotePrice
	 * @description
	 * # quotePrice
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('quotePrice',['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/prices/:id', { id: '@id' }, {
	                customSave: {
	                    transformRequest: function(data){
	                        delete data.materials;
	                        delete data.duplicate;
	                        data.product = data.product[0].id;
	                        return angular.toJson(data);
	                    }
	                },
					querySupplyAndFit: {
                        url: ENV.apiEndpoint + '/prices/supply-and-fit',
                        isArray: true
					}
	            }
	        );
	  }]);
})();