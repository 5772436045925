(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ApplicationCtrl', function (ENV, $scope, $rootScope, $routeParams, $filter, $locale, $httpParamSerializer, PermPermissionStore, productApplication, project, FileUploader, $route, assessment, fixedcost, TIMETEXT) {
            $scope.timetext = TIMETEXT;

            $scope.accountsIntegrationEnabled = PermPermissionStore.hasPermissionDefinition('canReadAccountsNominalCodes');
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteApplicationsOutgoingApplications');

            $scope.$on('loader', function () {
                $scope.busy = $rootScope.numLoadings > 0;
            });

            $scope.paiTableParams = {paymentApplication: $routeParams.id};

            $scope.paymentOption = "per-package";
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.appPaymentForm = {assessments: {}, notes: ''};

            $scope.setGenerateDocUrl = function () {
                var url = ENV.apiEndpoint + '/productpaymentapplications/' + $scope.paymentApplication.id + '/export?';
                var params = {access_token: localStorage.getItem('access_token')};
                $scope.generateDocUrl = url + $httpParamSerializer(params);
            };

            $scope.loadApplication = function () {
                productApplication.additional({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.additional = data;
                });
                productApplication.get({id: $routeParams.id}, {groups: 'assessment_package_name'}).$promise.then(function (data) {
                    $scope.paymentApplication = data;
                    $scope.projectId = $rootScope.projectId = data.project.id;
                    $scope.project = project.get({id: $scope.projectId}, {onlygroups: 'basic,hub'});

                    project.getLiveAssessments({id: $scope.projectId}).$promise.then(function (data) {
                        $scope.liveAssessments = data;
                        angular.forEach(data, function (assessment) {
                            $scope.appPaymentForm.assessments[assessment.id] = {
                                measuredCostsValue: {contract: null, variations: null},
                                materialsOnSiteValue: {contract: null, variations: null},
                                fixedCostsValue: {contract: null, variations: null}
                            };
                        });
                        return data;
                    });
                    $scope.appPaymentForm.effectiveDate = $filter('date')(data.effectiveDate, "yyyy-MM-dd");

                    productApplication.getNominalCodeAssignments({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.nominalCodeAssignments = data;
                    });

                    $scope.setGenerateDocUrl();
                });

                $scope.showCIS = true;

                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if (userDetails != null) {
                    var defaultCurrency = userDetails.account.defaultCurrency;
                    if (defaultCurrency == "MYR" || defaultCurrency == "SGD") {
                        $scope.showCIS = false;
                    }
                }
            };
            $scope.loadApplication();

            $scope.setPaymentAsApplied = function (factor) {
                factor = factor || 1;
                angular.forEach($scope.liveAssessments, function (assessment) {
                    $scope.appPaymentForm.assessments[assessment.id].measuredCostsValue.contract =
                        $scope.paymentApplication.appliedFigures.product[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.product[assessment.id].appliedValue.contract) * 100) / 100).toFixed(2) : 0;
                    $scope.appPaymentForm.assessments[assessment.id].measuredCostsValue.variations =
                        $scope.paymentApplication.appliedFigures.product[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.product[assessment.id].appliedValue.variations) * 100) / 100).toFixed(2) : 0;
                    $scope.appPaymentForm.assessments[assessment.id].materialsOnSiteValue.contract =
                        $scope.paymentApplication.appliedFigures.materialsOnSite[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.materialsOnSite[assessment.id].appliedValue.contract) * 100) / 100).toFixed(2) : 0;
                    $scope.appPaymentForm.assessments[assessment.id].materialsOnSiteValue.variations =
                        $scope.paymentApplication.appliedFigures.materialsOnSite[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.materialsOnSite[assessment.id].appliedValue.variations) * 100) / 100).toFixed(2) : 0;
                    $scope.appPaymentForm.assessments[assessment.id].fixedCostsValue.contract =
                        $scope.paymentApplication.appliedFigures.fixedCosts[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.fixedCosts[assessment.id].appliedValue.contract) * 100) / 100).toFixed(2) : 0;
                    $scope.appPaymentForm.assessments[assessment.id].fixedCostsValue.variations =
                        $scope.paymentApplication.appliedFigures.fixedCosts[assessment.id] ?
                            parseFloat(Math.round(factor * parseFloat($scope.paymentApplication.appliedFigures.fixedCosts[assessment.id].appliedValue.variations) * 100) / 100).toFixed(2) : 0;
                });
                $scope.updateCertifiedTotal();
            };
            $scope.certifiedTotal = 0;
            $scope.updateCertifiedTotal = function () {
                var total = 0;
                angular.forEach($scope.appPaymentForm.assessments, function (assessment) {
                    var assessmentTotal = 0;
                    if (!isNaN(assessment.measuredCostsValue.contract)) {
                        assessmentTotal += 1 * assessment.measuredCostsValue.contract;
                    }
                    if (!isNaN(assessment.measuredCostsValue.variations)) {
                        assessmentTotal += 1 * assessment.measuredCostsValue.variations;
                    }
                    if (!isNaN(assessment.materialsOnSiteValue.contract)) {
                        assessmentTotal += 1 * assessment.materialsOnSiteValue.contract;
                    }
                    if (!isNaN(assessment.materialsOnSiteValue.variations)) {
                        assessmentTotal += 1 * assessment.materialsOnSiteValue.variations;
                    }
                    if (!isNaN(assessment.fixedCostsValue.contract)) {
                        assessmentTotal += 1 * assessment.fixedCostsValue.contract;
                    }
                    if (!isNaN(assessment.fixedCostsValue.variations)) {
                        assessmentTotal += 1 * assessment.fixedCostsValue.variations;
                    }

                    total += assessmentTotal;

                    if (assessment.vatTaxCode && $scope.accountsIntegrationEnabled) {
                        assessment.vat = assessmentTotal * (assessment.vatTaxCode.rate / 100);
                    }
                });
                $scope.certifiedTotal = Math.round(total * 100) / 100;
            };

            $scope.setFromSingleFigure = function () {
                var proportion = $scope.paymentApplication.totalValue ?
                    $scope.appPaymentForm.singleFigure / $scope.paymentApplication.totalValue : 0;
                $scope.setPaymentAsApplied(proportion);
                if ($scope.certifiedTotal !== $scope.appPaymentForm.singleFigure) {
                    var finalTweak = Math.round(($scope.appPaymentForm.singleFigure - $scope.certifiedTotal) * 100) / 100;
                    var tweaked = false;
                    angular.forEach($scope.appPaymentForm.assessments, function (assessment) {
                        if (tweaked) {
                            return;
                        }
                        if (assessment.measuredCostsValue.contract >= Math.abs(finalTweak)) {
                            assessment.measuredCostsValue.contract = 1 * assessment.measuredCostsValue.contract + finalTweak;
                            tweaked = true;
                        } else if (assessment.measuredCostsValue.variations >= Math.abs(finalTweak)) {
                            assessment.measuredCostsValue.variations = 1 * assessment.measuredCostsValue.variations + finalTweak;
                            tweaked = true;
                        } else if (assessment.measuredCostsValue.variations >= Math.abs(finalTweak)) {
                            assessment.measuredCostsValue.variations = 1 * assessment.measuredCostsValue.variations + finalTweak;
                            tweaked = true;
                        } else if (assessment.materialsOnSiteValue.contract >= Math.abs(finalTweak)) {
                            assessment.materialsOnSiteValue.contract = 1 * assessment.materialsOnSiteValue.contract + finalTweak;
                            tweaked = true;
                        } else if (assessment.materialsOnSiteValue.variations >= Math.abs(finalTweak)) {
                            assessment.materialsOnSiteValue.variations = 1 * assessment.materialsOnSiteValue.variations + finalTweak;
                            tweaked = true;
                        } else if (assessment.fixedCostsValue.contract >= Math.abs(finalTweak)) {
                            assessment.fixedCostsValue.contract = 1 * assessment.fixedCostsValue.contract + finalTweak;
                            tweaked = true;
                        } else if (assessment.fixedCostsValue.variations >= Math.abs(finalTweak)) {
                            assessment.fixedCostsValue.variations = 1 * assessment.fixedCostsValue.variations + finalTweak;
                            tweaked = true;
                        }
                    });
                    $scope.updateCertifiedTotal();
                }
            };

            $scope.acceptVal = function (source, destAssessment, key) {
                var value = 0;
                if (source !== undefined) {
                    value = Number(source);
                }

                destAssessment[key] = value;

                $scope.updateCertifiedTotal();
            };

            // File uploads
            $scope.documents = {
                uploader: new FileUploader(),
                list: [],
                type: 'attachments',
                uploadurl: '/files'
            };
            $scope.markAsSent = function () {
                productApplication.markAsSent({id: $scope.paymentApplication.id}).$promise.then(function (data) {
                    $route.reload();
                });
            };
            $scope.markAsApproved = function () {
                $scope.submitted = true;
                if (!$scope.appPaymentForm.effectiveDate) {
                    return;
                }

                var assessmentPayments = [];
                var documentData = [];

                angular.forEach($scope.appPaymentForm.assessments, function (assessment, assessmentId) {
                    var paap = {
                        "assessment": assessmentId,
                        "measuredCostsValue": assessment.measuredCostsValue,
                        "materialsOnSiteValue": assessment.materialsOnSiteValue,
                        "fixedCostsValue": assessment.fixedCostsValue
                    };
                    if ($scope.accountsIntegrationEnabled) {
                        paap.vatTaxCode = assessment.vatTaxCode ? assessment.vatTaxCode.id : null;
                    }
                    assessmentPayments.push(paap);
                });

                angular.forEach($scope.documents.list, function (document) {
                    documentData.push({'file': document.id});
                });

                productApplication.markAsApproved({id: $scope.paymentApplication.id}, {
                    "assessmentPayments": assessmentPayments,
                    "notes": $scope.appPaymentForm.notes,
                    "effectiveDate": $filter('date')($scope.appPaymentForm.effectiveDate, 'yyyy-MM-dd'),
                    "documents": documentData
                }).$promise.then(function (data) {
                    $route.reload();
                });
            };

            $scope.addOnAccountPaymentForm = {};
            $scope.addPaymentOnAccount = function () {
                if (!$scope.addOnAccountPaymentForm.assessment.length ||
                    !$scope.addOnAccountPaymentForm.name ||
                    !$scope.addOnAccountPaymentForm.value) {
                    return;
                }

                var params = {id: $scope.paymentApplication.id};
                var request = {
                    paymentApplication: $scope.paymentApplication.id,
                    assessment: $scope.addOnAccountPaymentForm.assessment[0].id,
                    name: $scope.addOnAccountPaymentForm.name,
                    applicationValue: $scope.addOnAccountPaymentForm.value
                };
                productApplication.postPaoai(params, request).$promise.then(function () {
                    $scope.addOnAccountPaymentModal.close();
                    setTimeout(function () {
                        $route.reload();
                    }, 1000);
                });
            };

            // Adding variations products
            $scope.showSelectProductModal = function () {
                $scope.selectProductModal.open();
            };
            $scope.addVariationProductForm = {};
            $scope.onSelectedProduct = function (product) {
                $scope.selectProductModal.close();
                $scope.addVariationProductForm.product = product;
                $scope.addVariationProductModal.open();
            };

            $scope.addVariationProduct = function () {
                var request = {
                    assessment: $scope.addVariationProductForm.assessment[0].id,
                    originalProduct: $scope.addVariationProductForm.product.id,
                    supplyAndFitPrice: $scope.addVariationProductForm.selectedSupplyAndFitRate.id
                };
                var billAssessment = $scope.addVariationProductForm.assessment[0];
                billAssessment.projectWorkPackage = {project: $scope.paymentApplication.project};
                $scope.billAssessment = billAssessment;
                assessment.addAssessmentProducts({}, request, function (data) {
                    $scope.boqForcedParams = {id: $scope.billAssessment.id, assessmentProduct: data.id};
                    $scope.applicationBillFilterDefaults = {
                        assessment: $scope.billAssessment.id,
                        assessmentProduct: data.id,
                        paymentApplication: $scope.paymentApplication.id,
                    };
                    $scope.applicationBillForcedFormParams = {
                        application: $scope.paymentApplication.id,
                        assessment: $scope.billAssessment.id
                    };
                    $scope.applicationBillForcedQueryParams = {
                        projectId: $scope.paymentApplication.project.id,
                        assessment: $scope.billAssessment.id,
                        paymentApplication: $scope.paymentApplication.id,
                        assessmentProduct: data.id
                    };
                    $scope.variationBoqModal.open();
                    $scope.addVariationProductModal.close();
                });
            };
            $scope.variationProductStep4 = function () {
                $scope.variationBillModal.open();
                $scope.variationBoqModal.close();
            };
            $scope.completeStep4 = function () {
                $scope.variationBillModal.close();
                setTimeout(function () {
                    $route.reload();
                }, 1000);
            };

            var testApId = 3004142;
            $scope.testStep4 = function () {
                var billAssessment = $scope.liveAssessments[0];
                billAssessment.projectWorkPackage = {project: $scope.paymentApplication.project};
                $scope.billAssessment = billAssessment;
                $scope.applicationBillFilterDefaults = {
                    assessment: $scope.billAssessment.id,
                    assessmentProduct: testApId,
                    paymentApplication: $scope.paymentApplication.id,
                };
                $scope.applicationBillForcedFormParams = {
                    application: $scope.paymentApplication.id,
                    assessment: $scope.billAssessment.id
                };
                $scope.applicationBillForcedQueryParams = {
                    projectId: $scope.paymentApplication.project.id,
                    assessment: $scope.billAssessment.id,
                    draftApplication: $scope.paymentApplication.id,
                    assessmentProduct: testApId,
                };
                $scope.variationBillModal.open();
            };

            $scope.$on('productSaved', function (event, item) {
                console.log('on');
                $scope.createProductTemplateModal.close();
                $scope.$emit('itemSelectedproduct', item);
            });

            // Variation fixed costs
            $scope.openFixedCostModal = function () {
                $scope.applicationBillFixedCostForcedFormParams = {
                    application: $scope.paymentApplication.id
                };
                $scope.applicationBillFixedCostForcedQueryParams = {
                    projectId: $scope.paymentApplication.project.id,
                    paymentApplication: $scope.paymentApplication.id
                };
                $scope.addFixedCostModal.open();
            };
            $scope.closeFixedCostModal = function () {
                $scope.addFixedCostModal.close();
                setTimeout(function () {
                    $route.reload();
                }, 1000);
            };
        });
})();
