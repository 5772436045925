import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
    Inject,
    LOCALE_ID,
    NgZone, HostListener
} from '@angular/core';
import { timer } from 'rxjs';
import { getLocaleCurrencySymbol } from '@angular/common';
import { SelectPriceModalComponent } from "../product/modals/select-price-modal.component";
import { SimpleModalService } from 'ngx-simple-modal';
import { ProductPrice } from "../chalkstring-api/services/product-price.service";

@Component({
    selector: 'editable-table',
    template: require('./editable-table.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableTableComponent implements OnChanges {
    private _cache = new Map<string, any>();
    private negativeRegEx: RegExp = null;

    @Input() table: EditableTable;
    @Input() hasWritePermission: boolean;
    @Input() batchChanges: boolean = false;

    @Output() onAction = new EventEmitter<{section: number, row: number, tag: string}>();
    @Output() onChange = new EventEmitter<{section: number, row: number, tag: string, value: any}>();
    @Output() onMultipleChanges = new EventEmitter<{section: number, row: number, tag: string, value: any}[]>();

    readonly colTypeIcon = 'icon';
    readonly colTypeText = 'text';
    readonly colTypeEdit = 'edit';
    readonly colTypeDropdown = 'dropdown';
    readonly colTypeNumber = 'number';
    readonly colTypePercentage = 'percentage';
    readonly colTypeCurrency = 'currency';
    readonly colTypeHtml = 'html';
    readonly colTypeDelete = 'delete';
    readonly colTypeEditPrice = 'price';
    readonly cacheTooltip = 'tooltip';
    readonly cacheTooltipClasses = 'tooltipClasses';
    readonly cacheTooltipTrigger = 'tooltipTrigger';
    readonly cacheValue = 'value';
    readonly cacheItems = 'items';
    readonly cacheData = 'data';
    readonly cacheColumnClass = 'columnClass';
    readonly cacheTextClass = 'textClass';

    selectedDropdownItem: Map<string, DropdownItem> = new Map();
    tableLocked: boolean = false;
    batchedChanges: Array<BatchedChange> = [];
    batchedChangesTimer = null;
    focusedInput = false;

    constructor(
        private _modalService: SimpleModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private zone: NgZone,
        @Inject(LOCALE_ID) private locale: string
    ) {
        const currencySymbol = getLocaleCurrencySymbol(this.locale);
        this.negativeRegEx = new RegExp(`-${currencySymbol}?[0-9]{1,}\.[0-9]+`);
    }

    @HostListener('wheel', ['$event'])
    onWheel(event: any) {
        if (event.srcElement.type === "number")
            event.preventDefault();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.table && this.table.columns && changes.table && (changes.table.firstChange || changes.table.previousValue !== changes.table.currentValue)) {
            this._cache = new Map();
            this.selectedDropdownItem = new Map();
            this.table.columns
                .forEach((col, index) => {
                    if (col.type != this.colTypeDropdown) {
                        return;
                    }
                    for (let section = 0 ; section < this.table.sectionCount ; section++) {
                        for (let row = 0 ; row < this.table.rowCount(section) ; row++) {
                            const items = this.cellItems(index, section, row);
                            this.selectedDropdownItem[this.dropdownKey(col.tag, section, row)] = items.find(i => i.selected) || null;
                        }
                    }
                });
            this.tableLocked = false;
            this.submitBatchedChanges();
        }
    }

    inputChanged(event: any, columnIndex: number, section: number, row: number, tag: string) {
        const newValue = event.target.value;
        this.clearCache(columnIndex, section, row);
        this.batchChange(new BatchedChange(section, row, tag, newValue));
    }

    emitAction(section: number, row: number, tag: string) {
        if (this.tableLocked) {
            return;
        }
        this.submitBatchedChanges();
        this.onAction.emit({
            section: section,
            row: row,
            tag: tag
        });
    }

    selectPrice(columnIndex: number, section: number, row: number, tag: string, data: {item: any, selectedPrice: any, serviceParams: any}) {
        if (this.tableLocked) {
            return;
        }
        this._modalService.addModal(SelectPriceModalComponent, {
            pricedItem: data.item.hasOwnProperty('originalMaterial') ? data.item.originalMaterial : data.item.originalLabourActivity,
            selectedPrice: data.selectedPrice,
            serviceParams: data.serviceParams
        }).subscribe((price: ProductPrice) => {
            if (price) {
                this.clearCache(columnIndex, section, row);
                this.batchChange(new BatchedChange(section, row, tag, price));
            }
        });
    }

    dropdownChange(column: EditableTableColumn, section: number, row: number, event: any) {
        if (this.selectedDropdownItem[this.dropdownKey(column.tag, section, row)]) {
            this.selectedDropdownItem[this.dropdownKey(column.tag, section, row)].selected = false;
        }
        const index: number = event.currentTarget.selectedIndex - 1;
        const columnIndex = this.table.columns.indexOf(column);
        const selectedValue = this.cellItems(columnIndex, section, row)[index];
        selectedValue.selected = true;
        this.selectedDropdownItem[this.dropdownKey(column.tag, section, row)] = selectedValue;
        this.batchChange({
            section: section,
            row: row,
            tag: column.tag,
            value: selectedValue
        }, true);
    }

    inputFocusChange(focused: boolean) {
        this.focusedInput = focused;
    }

    inputEnterPressed(event: any) {
        event.target.blur();
        this.submitBatchedChanges();
        return false;
    }

    batchChange(change: any, immediate: boolean = false) {
        if (this.batchedChangesTimer) {
            this.batchedChangesTimer.unsubscribe();
            this.batchedChangesTimer = null;
        }
        if (!this.batchChanges || this.onMultipleChanges.observers.length == 0) {
            this.onChange.emit(change);
        } else if (immediate) {
            this.tableLocked = true;
            if (this.batchedChanges.length == 0) {
                this.onChange.emit(change);
            } else {
                this.addBatchChange(change);
                this.onMultipleChanges.emit(this.batchedChanges);
            }
        } else {
            this.addBatchChange(change);
            this.startBatchedChangesTimer();
        }
    }

    addBatchChange(change: any) {
        this.batchedChanges = this.batchedChanges.filter(existingChange => !existingChange.shouldOverrideWith(change));
        this.batchedChanges.push(change);
    }

    startBatchedChangesTimer() {
        this.batchedChangesTimer = timer(500).subscribe(_ => {
            if (this.focusedInput) {
                this.startBatchedChangesTimer();
            } else {
                this.submitBatchedChanges();
            }
        });
    }

    submitBatchedChanges() {
        if (this.batchedChangesTimer) {
            this.batchedChangesTimer.unsubscribe();
            this.batchedChangesTimer = null;
        }
        if (this.batchedChanges.length > 0) {
            if (NgZone.isInAngularZone()) {
                this.tableLocked = true;
            } else {
                this.zone.run(() => {
                    this.tableLocked = true;
                });
            }
            const batchedChanges = this.batchedChanges;
            this.batchedChanges = [];
            this.onMultipleChanges.emit(batchedChanges);
        }
    }

    dropdownKey(tag: String, section: number, row: number): string {
        return `${tag}_${section}_${row}`;
    }

    iconContainerClass(name: string): any {
        return {
            [`rbu-icon-container-${name}`]: true
        };
    }

    iconClass(name: string): any {
        return {
            [`icon-${name}`]: true,
            icon: true
        };
    }

    cellValue(columnIndex: number, section: number, row: number, isNumber: boolean = false): any {
        return this.valueFor(this.cacheValue, columnIndex, section, row, () => {
            const valueFn = this.table.columns[columnIndex].value;
            if (valueFn !== undefined && valueFn !== null) {
                const value = valueFn(section, row);
                return isNumber && isNaN(Number(value)) ? "" : value;
            } else {
                return null;
            }
        });
    }

    cellItems(columnIndex: number, section: number, row: number): any {
        return this.valueFor(this.cacheItems, columnIndex, section, row, () => {
            const itemsFn = this.table.columns[columnIndex].getItems;
            if (itemsFn) {
                return itemsFn(section, row);
            } else {
                return [];
            }
        });
    }

    cellData(columnIndex: number, section: number, row: number): any {
        return this.valueFor(this.cacheData, columnIndex, section, row, () => {
            const dataFn = this.table.columns[columnIndex].data;
            if (dataFn) {
                return dataFn(section, row);
            } else {
                return null;
            }
        });
    }

    columnClass(column: EditableTableColumn, section: number = null, row: number = null): any {
        const columnIndex = this.table.columns.indexOf(column);
        return this.valueFor(this.cacheColumnClass, columnIndex || -1, section, row || -1, () => {
            let classNames: Array<string> = [];

            // Column widths can be either automatically determined based on the content type
            // or defined as absolute pixel widths in the table definition
            let cellSize = 'regular-cell';

            switch (column.type) {
                case this.colTypeIcon:
                case this.colTypeEdit:
                case this.colTypeDelete:
                    cellSize = 'super-tiny-cell';
                    break;
                case this.colTypeNumber:
                case this.colTypePercentage:
                    cellSize = column.type == this.colTypePercentage ? 'tiny-cell' : 'small-cell';
                    if (section != null && row != null && this.cellValue(columnIndex, section, row) != null) {
                        classNames.push('rbu-input');
                    }
                    break;
                case this.colTypeDropdown:
                    if (section != null && row != null && this.cellItems(columnIndex, section, row).length > 0) {
                        classNames.push('rbu-input');
                        cellSize = 'smallish-cell';
                    }
                    break;
                case this.colTypeCurrency:
                    cellSize = 'small-cell';
                    break;
                default:
                    break;
            }

            if (cellSize && (this.table.columnWidths === undefined || this.table.columnWidths[columnIndex] === 0)) {
                classNames.push(cellSize);
            }

            return classNames.reduce((result, item) => {
                result[item] = true;
                return result;
            }, {});
        });
    }

    columnStyle(columnIndex: number): any {
        if (this.table.columnWidths === undefined || this.table.columnWidths[columnIndex] === 0) {
            return {};
        } else {
            return {'width.px': this.table.columnWidths[columnIndex]};
        }
    }

    textClass(column: EditableTableColumn, section: number, row: number): any {
        const columnIndex = this.table.columns.indexOf(column);
        const classes = this.valueFor(this.cacheTextClass, columnIndex, section, row, () => {
            return {
                'rbu-emphasis': column.emphasis,
                'rbu-explicit-tooltip': this.tooltip(columnIndex, section, row) != null && this.tooltip(columnIndex, section, row).extraInfoAvailable === true,
                'rbu-negative-value': this.containsNegativeValue(this.cellValue(columnIndex, section, row)) && column.highlightNegative === true,
            };
        });
        if (column.disableIfRowEdited && this.batchedChanges.some(change => change.section == section && change.row == row)) {
            classes['rbu-disabled'] = true;
        }
        return classes;
    }

    inputClass(column: EditableTableColumn, section: number, row: number): any {
        const columnIndex = this.table.columns.indexOf(column);
        return {
            'rbu-empty': isNaN(this.cellValue(columnIndex, section, row))
        };
    }

    containsNegativeValue(text: string): boolean {
        return this.negativeRegEx.test(text);
    }

    tooltip(columnIndex: number, section: number, row: number) {
        return this.valueFor(this.cacheTooltip, columnIndex, section, row, () => {
            const tooltipFn = this.table.columns[columnIndex].tooltip;
            if (tooltipFn) {
                return tooltipFn(section, row);
            } else {
                return null;
            }
        });
    }

    tooltipTrigger(columnIndex: number, section: number, row: number) {
        return this.valueFor(this.cacheTooltipTrigger, columnIndex, section, row, () => {
            if (this.tooltip(columnIndex, section, row) != null && this.tooltip(columnIndex, section, row).showOnlyOnClick  === true) {
                return 'click';
            } else {
                return 'hover';
            }
        });
    }

    tooltipClasses(columnIndex:number, section: number, row: number) {
        return this.valueFor(this.cacheTooltipClasses, columnIndex, section, row, () => {
            const val = this.tooltip(columnIndex, section, row);
            let classes = ['editable-table-tooltip'];
            if (val != null && (val.rows == null || val.rows.length == 0)) {
                classes.push('tooltip-centre-header');
            }
            return classes.join(',');
        });
    }

    valueFor(name: string, columnIndex: number, section: number, row: number, generator: () => any): any {
        const cacheKey = this.cacheKeyFor(name, columnIndex, section, row)
        let cachedValue = this._cache[cacheKey];
        if (cachedValue === undefined) {
            cachedValue = generator();
            this._cache[cacheKey] = cachedValue;
        }
        return cachedValue;
    }

    dropdownItemSelectedId(tag: string, section: number, row: number): string {
        let selectedItem: any = this.selectedDropdownItem[this.dropdownKey(tag, section, row)];
        return selectedItem ? selectedItem.id : null;
    }

    private clearCache(columnIndex: number, section: number, row: number) {
        [
            this.cacheTooltip,
            this.cacheTooltipTrigger,
            this.cacheValue,
            this.cacheItems,
            this.cacheData,
            this.cacheColumnClass,
            this.cacheTextClass
        ].forEach(k => this.clearCacheKeyFor(k, columnIndex, section, row));
    }

    private clearCacheKeyFor(name: string, columnIndex: number, section: number, row: number) {
        const cacheKey = this.cacheKeyFor(name, columnIndex, section, row)
        delete this._cache[cacheKey];
    }

    private cacheKeyFor(name: string, columnIndex: number, section: number, row: number): string {
        return `${name}_${columnIndex}_${section}_${row}`;
    }
}

interface EditableTable {
    columns: Array<EditableTableColumn>;
    sectionCount: number;
    rowCount: (section: number) => number;
    sectionAddRowFooters: Array<EditableTableSectionAddRowFooter>;
    columnWidths: Array<number>;
}

interface EditableTableColumn {
    title: string;
    type: string;
    tag: string;
    expand: boolean;
    emphasis: boolean;
    value: (section: number, row: number) => string;
    tooltip: (section: number, row: number) => any;
    getItems: (section: number, row: number) => Array<DropdownItem>;
    isEditable: (section: number, row: number) => boolean;
    data: (section: number, row: number) => any;
    highlightNegative: boolean;
    disableIfRowEdited: boolean;
}

interface DropdownItem {
    id: string;
    label: String;
    selected: boolean;
}

interface EditableTableSectionAddRowFooter {
    icon: string;
    title: string;
}

class BatchedChange {
    section: number;
    row: number;
    tag: string;
    value: any;

    constructor(section: number, row: number, tag: string, value: any) {
        this.section = section;
        this.row = row;
        this.tag = tag;
        this.value = value;
    }

    shouldOverrideWith(change: BatchedChange) {
        return change.section == this.section && change.row == this.row && change.tag == this.tag;
    }
}
