(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.quotes
     * @description
     * # quotes
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('labourQuote', ['Resource', 'ENV', '$filter', function ($resource, ENV, $filter) {
            var saveTransform = function (data) {
                var newData = {
                    'name': data.name,
                    'subcontractor': data.subcontractor,
                    'quoteDate': null,
                    'quoteExpires': null,
                    'projects': [],
                    'documents': []
                };

                // Add leading 0's to date values for Safari and Firefox
                var qDate = data.quoteDate.split('-');
                angular.forEach(qDate, function (value, key) {
                    if (value < 10) {
                        qDate[key] = '0' + value;
                    }
                });
                qDate = qDate[0] + '-' + qDate[1] + '-' + qDate[2];
                var eDate = data.quoteExpires.split('-');
                angular.forEach(eDate, function (value, key) {
                    if (value < 10) {
                        eDate[key] = '0' + value;
                    }
                });
                eDate = eDate[0] + '-' + eDate[1] + '-' + eDate[2];
                newData.quoteDate = data.quoteDate + ' 00:00:00';
                newData.quoteExpires = data.quoteExpires + ' 00:00:00';

                // Documents
                var tmpDocs = [];
                angular.forEach(data.documents, function (document) {
                    tmpDocs.push(document.id);
                });
                newData.documents = tmpDocs;

                // Subcontractors
                if (data.subcontractor[0]) {
                    newData.subcontractor = data.subcontractor[0].id;
                } else {
                    newData.subcontractor = data.subcontractor.id;
                }

                // Projects
                var tmpProjects = [];
                angular.forEach(data.projects, function (project) {
                    tmpProjects.push(project.id);
                });

                newData.projects = tmpProjects;

                // Prices
                if (data.prices) {
                    newData.prices = [];
                    angular.forEach(data.prices, function (value) {
                        if (value.product.id) {
                            newData.prices.push({'product': value.product.id, 'price': value.price});
                        } else {
                            newData.prices.push({'product': value.product, 'price': value.price});
                        }
                    });
                }

                return angular.toJson(newData);
            };

            return $resource(
                ENV.apiEndpoint + '/labourquotes/:id', {id: '@id'}, {
                    query: {
                        isArray: true,
                        method: 'GET',
                        transformResponse: function (data) {
                            var quotes = angular.fromJson(data);
                            angular.forEach(quotes, function (quote) {
                                quote.quoteExpires = $filter('date')(quote.quoteExpires, 'dd/MM/yyyy');
                                quote.quoteDate = $filter('date')(quote.quoteDate, 'dd/MM/yyyy');
                            });
                            return quotes;
                        }
                    },
                    customSave: {
                        transformRequest: saveTransform
                    },
                    archive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/labourquotes/:id/archive'
                    },
                    unarchive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/labourquotes/:id/unarchive'
                    },
                    clone: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/labourquotes'
                    },
                    patchPrices: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/labourquotes/:id/prices"
                    },
                    update: {
                        method: 'PATCH',
                        transformRequest: saveTransform
                    }
                }
            );
        }]);
})();
