/**
 * @ngdoc function
 * @name scswebappApp.controller:IssueCtrl
 * @description
 * # IssueCtrl
 * Controller of the scswebappApp
 */
import {CsUtils} from "../../../other/csutils";
import {CsEvent} from "@generic/events";
import {
    EditAssessmentProductModel
} from "../../../../modules/rate-build-up/modal/edit-assessment-product-pricing-modes-modal.component";

angular.module("scswebappApp")
    .controller("CostCtrl", function ($scope, $routeParams, $route, $rootScope, project, routeType, $locale, cost, FileUploader, $location,PermPermissionStore, $http, $window, ENV) {
        "use strict";

        $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
        $scope.accountsIntegrationEnabled = PermPermissionStore.hasPermissionDefinition('canReadAccountsNominalCodes');

        // File uploads
        $scope.documents = {
            uploader: new FileUploader(),
            list: [],
            type: 'attachments',
            uploadurl: '/files'
        };

        if (!$route.current.$$route.isSingle && !$route.current.$$route.isEdit) {
            $rootScope.projectId = $routeParams.id;
            $scope.project = project.get({id: $routeParams.id, onlygroups: 'basic'});
            $scope.packages = project.getLiveAssessments({id: $routeParams.id});
        }

        if ($route.current.$$route.isList) {
            $scope.tableServiceParams = {'project': $routeParams.id};
                    // {
                    //     text: "Material & waste",
                    //     data: "materialAndWasteBudget",
                    //     dataFilter: {
                    //         type: "currency"
                    //     }
                    // },
                    // {
                    //     text: "Labour",
                    //     data: "labourBudget",
                    //     dataFilter: {
                    //         type: "currency"
                    //     }
                    // },
                    // {
                    //     text: "Contingency",
                    //     data: "contingencyBudget",
                    //     dataFilter: {
                    //         type: "currency"
                    //     }
                    // },
                    // {
                    //     text: "Fixed costs",
                    //     data: "fixedCostsBudget",
                    //     dataFilter: {
                    //         type: "currency"
                    //     }
                    // },
                    // {
                    //     text: "Supply & fit",
                    //     data: "supplyAndFitBudget",
                    //     dataFilter: {
                    //         type: "currency"
                    //     }
                    // },
                    // {
                    //     text:'Total value',
                    //     data: 'totalCost',
                    //     dataFilter: {
                    //         type:'currency',
                    //     },
                    //     sort: false
                    // },
                    // {
                    //     text:'VAT',
                    //     permissionOnly: 'canReadAccountsNominalCodes',
                    //     data: 'vatValue',
                    //     dataFilter: {
                    //         type:'currency'
                    //     }
                    // }
                // ]
            // };
        }

        $scope.exportCosts = function () {
            CsEvent.reloadUserpilot();
            CsEvent.track('exportExternalCosts', {p: $routeParams.id});
            $http({
                method: 'GET',
                responseType: 'arraybuffer',
                url: ENV.apiEndpoint + '/costentities/' + $routeParams.id + '/export/' + $rootScope.projectId
            }).success(function (data, status, headers) {
                CsUtils.download(data, headers, $window);
            });
        };

        if ($route.current.$$route.isEdit || $route.current.$$route.isSingle) {
            cost.get({id: $routeParams.id}).$promise.then(function (data) {
                $scope.project = project.get({id: data.project.id, onlygroups: 'basic'});
                data.effectiveDate = new Date(data.effectiveDate);
                $scope.data = data;
                $rootScope.projectId = data.project.id;

                $scope.data.assessment = {name: data.packageName};
                project.getLiveAssessments({id: $rootScope.projectId}).$promise.then(function (data) {
                    $scope.packages = data;
                    const found = $scope.packages.find(e => e.name === $scope.data.assessment.name);
                    if(found) {found.ticked = true;}
                });
            });

        }

        if ($route.current.$$route.isNew) {
            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);
            $scope.data = {
                documents: []
            };
        }

        if ($route.current.$$route.isEdit || $route.current.$$route.isNew) {
            $scope.save = function() {
                $scope.submitted = true;
                $scope.data.assessment.id = $scope.data.assessment.id[0].id;
/*
* TODO
* Package doesn't show http://0.0.0.0:8080/project-hub/cost/909
*
* */

                if ($scope.form.$valid) {
                    let saveCost = new cost($scope.data);
                    if ($scope.data.id) {
                        // Update
                        saveCost.$save(function (response) {
                            $scope.form.$setPristine();
                            $scope.submitted = false;
                            $location.path('/project-hub/cost/' + response.id);
                        });
                    } else {
                        // Save
                        saveCost.project = {id: $scope.projectId};
                        saveCost.$save(function (response) {
                            $scope.form.$setPristine();
                            $scope.submitted = false;
                            $location.path('/project-hub/cost/' + response.id);
                        });
                    }
                }
            };
        }
    });
