(function(){
    'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.operative
	 * @description
	 * # operative
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	    .factory('operative', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/operatives/:id', {id: '@id'}, {
	                customSave: {
	                    transformRequest: function(data){
	                        var newObj = {
	                            parent: data.parent ? data.parent.id : null,
	                            firstName: data.firstName,
	                            lastName: data.lastName,
	                            dob: data.dob,
	                            addresses: data.addresses,
	                            telephoneNumbers: data.telephoneNumbers,
	                            emailAddresses: data.emailAddresses,
	                            nationalInsuranceNumber: data.nationalInsuranceNumber,
	                            nextOfKin: data.nextOfKin,
	                            emergencyContactNumber: data.emergencyContactNumber,
	                            citbNumber: data.citbNumber,
	                            citbFundingInformation: data.citbFundingInformation,
	                            cscsCardExpiry: data.cscsCardExpiry,
	                            cscsCardNumber: data.cscsCardNumber,
	                            cscsCardType: data.cscsCardType,
	                            trades: [],
	                            qualifications: [],
                                cscsDocuments: []
                            };
                            if(data.photograph && data.photograph.id){
                                newObj.photograph = data.photograph.id;
                            }
                            if(data.cscsDocument && data.cscsDocument.id){
                                newObj.cscsDocument = data.cscsDocument.id;
                            }
                            if(data.workPermitDocument && data.workPermitDocument.id){
                                newObj.workPermitDocument = data.workPermitDocument.id;
                            }
                            if(data.nationality !== undefined){
                                newObj.nationality = data.nationality;
                            }

                            if (newObj.addresses.length)
                                delete newObj.addresses[0].id;
                            if (newObj.telephoneNumbers.length)
                                delete newObj.telephoneNumbers[0].id;
                            if (newObj.emailAddresses.length)
                                delete newObj.emailAddresses[0].id;

                            angular.forEach(data.qualifications, function(qual){

                                var newQual = {
                                    name: qual.name,
                                    referenceNumber: qual.referenceNumber,
                                    dateAchieved: qual.dateAchieved,
                                    dateExpires: qual.dateExpires,
                                    trainingFee: 200
                                };

                                if (qual.qualificationDocument){
                                    newQual.qualificationDocument = qual.qualificationDocument.id;
                                }

                                newObj.qualifications.push(newQual);

                            });

                            angular.forEach(data.selectedTrades, function(trade){
                                newObj.trades.push(trade.id);
                            });

                            angular.forEach(data.addresses, function(value){
                                delete value.contact;
                            });

                            angular.forEach(data.cscsDocuments, function(value){
                                newObj.cscsDocuments.push(value.id);
                            });

                            return JSON.stringify(newObj);
                        }
                    },
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/operatives/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/operatives/:id/unarchive'
                    },
                    getProjectUnassigned: {
                        url: ENV.apiEndpoint + '/projects/:project/operatives-unassigned',
                        method: 'GET',
                        isArray: true
                    }
                }
            );
        }]);
})();
