'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:ResetPasswordCtrl
 * @description
 * # ResetPasswordCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
	.controller('ResetPasswordCtrl', function ($scope, $rootScope, $routeParams, user, $location) {

		$scope.resetPassword = function(){

			$scope.submitted = true;

			if( $scope.form.$valid ){
				user.resetPassword( {token: $routeParams.token }, $scope.data ).$promise.then( function(){
					$scope.submitted = false;
					$location.path('/');
					$scope.$emit('showOrNotification', 'Your password has been reset. Please login');
				})
			}
		}

	});
