(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.assessmentLabourComponent
     * @description
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('assessmentLabourComponent', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/assessmentlabourcomponent/:id', {
                    id: '@id',
                    movingComponentId: "@movingComponentId",
                    referenceComponentId: "@referenceComponentId"
                }, {
                    delete: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/assessmentlabourcomponents/:id'
                    },
                    add: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentlabourcomponents'
                    },
                    patch: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentlabourcomponents/:id'
                    },
                    changeOrder: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentlabourcomponents/move/:movingComponentId/after/:referenceComponentId"
                    },
                }
            );
        }]);
})();
