(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.quotes
     * @description
     * # quotes
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('quote', ['Resource', 'ENV', '$filter', function ( $resource, ENV, $filter ) {

            var saveTransform = function(data){

                var newData = {
                    'name': data.name,
                    'supplier': data.supplier,
                    'quoteDate': null,
                    'quoteExpires': null,
                    'projects': [],
                    'documents': []
                };

                // Add leading 0's to date values for Safari and Firefox
                var qDate = data.quoteDate.split('-');
                angular.forEach(qDate, function(value, key){
                    if(value < 10){
                        qDate[key] = '0' + value;
                    }
                });
                qDate = qDate[0] + '-' + qDate[1] + '-' + qDate[2];
                var eDate = data.quoteExpires.split('-');
                angular.forEach(eDate, function(value, key){
                    if(value < 10){
                        eDate[key] = '0' + value;
                    }
                });
                eDate = eDate[0] + '-' + eDate[1] + '-' + eDate[2];
                newData.quoteDate = data.quoteDate + ' 00:00:00';
                newData.quoteExpires = data.quoteExpires + ' 00:00:00';

                // Documents
                var tmpDocs = [];
                angular.forEach(data.documents, function(document){
                    tmpDocs.push(document.id);
                });
                newData.documents = tmpDocs;

                // Suppliers
                if( data.supplier[0] ){
                    newData.supplier = data.supplier[0].id;
                }else{
                    newData.supplier = data.supplier.id;
                }

                // Projects
                var tmpProjects = [];
                angular.forEach(data.projects, function(project){
                    tmpProjects.push(project.id);
                });

                newData.projects = tmpProjects;

                if (data.prices) {
                    newData.prices = [];
                    // Prices
                    angular.forEach(data.prices, function (value) {
                        if (value.product.id) {
                            newData.prices.push({'product': value.product.id, 'price': value.price});
                        } else {
                            newData.prices.push({'product': value.product, 'price': value.price});
                        }
                    });
                }

                return angular.toJson(newData);
            };

            return $resource(
                ENV.apiEndpoint + '/materialquotes/:id', { id: '@id', projectId: '@projectId', assessmentId: '@assessmentId' }, {
                    aquery: {
                        isArray: true,
                        method: 'GET',
                        transformResponse: function(data){
                            var quotes = angular.fromJson(data);
                            angular.forEach(quotes, function(quote){
                                quote.quoteExpires = $filter('date')(quote.quoteExpires, 'dd/MM/yyyy');
                                quote.quoteDate = $filter('date')(quote.quoteDate, 'dd/MM/yyyy');
                            });
                            return quotes;
                        }
                    },
                    customSave: {
                        transformRequest: saveTransform,
                    },
                    update: {
                        method: 'PATCH',
                        transformRequest: saveTransform,
                    },
		            importLabour: {
			            method: 'POST',
			            url:  ENV.apiEndpoint + '/labourquotes/:id'
		            },
                    importSupplyAndFit: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/supplyandfitquotes/:id'
                    },
                    toQuoteRequest: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/materialquotes/:id/to-quote-template'
                    },
                    archive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/materialquotes/:id/archive"
                    },
                    unarchive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/materialquotes/:id/unarchive"
                    },
                    patchPrices: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/materialquotes/:id/prices"
                    },
                    clone: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/materialquotes'
                    }
                }
            );
        }]);
})();
