import {Component, Input} from "@angular/core";
import {SimpleModalService} from "ngx-simple-modal";
import {first} from "rxjs/operators";
import {GenerateOutgoingApplicationModalComponent} from "../modals/generate-outgoing-application-modal.component";
import {Project} from "../../chalkstring-api/services/project.service";

@Component({
    template: `
        <button href="" (click)="spawnModal()" class="btn btn-small btn-light inline">
            <i class="icon-clipboard"></i> Generate outgoing application</button>
    `,
    selector: 'generate-outgoing-application-button'
})
export class GenerateOutgoingApplicationButtonComponent
{
    @Input() project: Project;

    constructor(private _modalService: SimpleModalService)
    {
    }
    spawnModal()
    {
        this._modalService.addModal(GenerateOutgoingApplicationModalComponent, {
            project: this.project
        })
            .pipe(first())
            .subscribe(res => {

            })
    }
}
