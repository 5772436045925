(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.subcontractor
	 * @description
	 * # subcontractor
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('subcontractor', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/subcontractors/:id', {id: '@id'}, {
	                list: {
                        url: ENV.apiEndpoint + '/subcontractors/list',
                        method: 'GET',
                        isArray: true
                    },
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/subcontractors/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/subcontractors/:id/unarchive'
                    },
                    getProjectUnassigned: {
                        url: ENV.apiEndpoint + '/projects/:project/subcontractors-unassigned',
                        method: 'GET',
                        isArray: true
                    },
	                getOperatives: {
	                    url: ENV.apiEndpoint + '/subcontractors/:id/operatives',
	                    method: 'GET',
	                    isArray: true
	                },
                    create: {
                        method: 'POST',
                        transformRequest:function(data){

                            //Refactor data
                            if (data.companyVATDocument){
                                data.companyVATDocument = data.companyVATDocument.id;
                            }
                            if (data.companyInsuranceDocument){
                                data.companyInsuranceDocument = data.companyInsuranceDocument.id;
                            }
                            if (data.cisTaxCode){
                                data.cisTaxCode = data.cisTaxCode.id;
                            }
                            if (data.vatTaxCode){
                                data.vatTaxCode = data.vatTaxCode.id;
                            }
                            var newDocuments = angular.copy(data.documents);
                            data.documents = [];
                            angular.forEach(newDocuments, function(document){
                                data.documents.push(document.id);
                            });

                            var newRegions =[];
                            angular.forEach(data.regions, function(value){
                                newRegions.push(value.id);
                            });
                            data.regions = newRegions;

                            var newTrades =[];
                            angular.forEach(data.trades, function(value){
                                newTrades.push(value.id);
                            });
                            data.trades = newTrades;

                            data.companyInsuranceExpiry = data.companyInsuranceExpiry + ' 00:00:00';

                            return angular.toJson( data );
                        }
                    },
                    update: {
                        method: 'PUT',
                        transformRequest:function(theData){
                            var data = angular.fromJson( theData );

                            var newData = {
                                'fullName': data.fullName,
                                'companyRegistrationNumber': data.companyRegistrationNumber,
                                'companyVATNumber': data.companyVATNumber,
                                'nationalInsuranceNumber': data.nationalInsuranceNumber,
                                'companyUTR': data.companyUTR,
                                'companyCITBNumber': data.companyCITBNumber,
                                'companyInsuranceNumber': data.companyInsuranceNumber,
                                'companyInsuranceExpiry': data.companyInsuranceExpiry,
                                'regions': [],
                                'documents': [],
                                'subcontractorType': data.subcontractorType,
                                'cisTaxCode': data.cisTaxCode? data.cisTaxCode.id:null,
                                'vatTaxCode': data.vatTaxCode? data.vatTaxCode.id:null,
                                'retentionPercentage': data.retentionPercentage,
                                'accountRef': data.accountRef,
                                'trades': [],
                                'registeredAddress': data.registeredAddress ? {
                                    'street1': data.registeredAddress.street1,
                                    'street2': data.registeredAddress.street2,
                                    'city': data.registeredAddress.city,
                                    'county': data.registeredAddress.county,
                                    'postcode': data.registeredAddress.postcode,
                                    'country': data.registeredAddress.country
                                }: null,
                                'correspondenceAddress': data.correspondenceAddress ? {
                                    'street1': data.correspondenceAddress.street1,
                                    'street2': data.correspondenceAddress.street2,
                                    'city': data.correspondenceAddress.city,
                                    'county': data.correspondenceAddress.county,
                                    'postcode': data.correspondenceAddress.postcode,
                                    'country': data.correspondenceAddress.country
                                }: null,
                                'telephoneNumbers': data.telephoneNumbers,
                                'emailAddresses': data.emailAddresses
                            };

                            //Refactor data
                            if (data.companyVATDocument){
                                newData.companyVATDocument = data.companyVATDocument.id;
                            }
                            if (data.companyInsuranceDocument){
                                newData.companyInsuranceDocument = data.companyInsuranceDocument.id;
                            }

                            angular.forEach(data.documents, function(document){
                                newData.documents.push(document.id);
                            });
                            angular.forEach(data.regions, function(value){
                                newData.regions.push(value.id);
                            });
                            angular.forEach(data.trades, function(value){
                                newData.trades.push(value.id);
                            });
                            angular.forEach(newData.emailAddresses, function(value){
                                delete value.id;
                            });
                            angular.forEach(newData.telephoneNumbers, function(value){
                                delete value.id;
                            });

                            newData.companyInsuranceExpiry = newData.companyInsuranceExpiry + ' 00:00:00';

                            return angular.toJson( newData );
                        }
                    },
                    'getSimpleDisplayRetention': {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/subcontractors/all-display-retention'
                    }
	            },
						  'fullName'  // default sort for query
	        );
	  }]);
})();
