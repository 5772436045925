(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('labourApplication', ['Resource', 'ENV', function ( $resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/labourpaymentapplications/:id', { id: '@id', assessmentZoneId:'@assessmentZoneId', transition: '@transition', projectId: '@projectId', assessment: '@assessment', item: '@item'}, {
                    submitApplication: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/outgoingpaymentapplications/:id/transitions/submit'
                    },
                    approveApplication: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/labourpaymentapplications/:id/approvals?assessmentZone=:assessmentZoneId'
                    },
                    getLabourBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/labour-bill'
                    },
                    getBillFilters: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-labour-options'
                    },
                    getApplicationLabourReviewBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/application-labour-review-bill'
                    },
                    getReviewBillFilters: {
                      method: 'GET',
                      url:  ENV.apiEndpoint + '/projects/:id/application-labour-review-options'
                    },
                    modifyBillItem: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/paymentapplicationlabouritems/:id'
                    },
                    update: {
                        method: 'PATCH'
                    },
                    transition: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/labourpaymentapplications/:id/transitions/:transition'
                    },
                    getNominalCodeAssignments: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/labourpaymentapplications/:id/nominalcodeassignments',
                        isArray:true
                    },
                    patchPali: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/pali'
                    },
                    deleteItem: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/projects/:projectId/application/:application/item/:item'
                    }
                }
            );
        }]);
})();
