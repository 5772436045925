angular.module("scswebappApp")
    .directive("newOperative", function (
        operative,
        FileUploader,
        trades,
        subcontractor,
        COUNTRIES,
        PermissionStrategies,
        PermPermissionStore
    ) {
        "use strict";
        return {
            template: require("./new-operative.html"),
            restrict: "E",
            scope: {
                operativeId: "=?",
                form: "=?"
            },
            link: function postLink(scope) {
                scope.PermissionStrategies = PermissionStrategies;

                scope.cscsCardTypes = [
                    {name: "Academically Qualified Person"},
                    {name: "Advanced Craft/Supervisory"},
                    {name: "Apprentice"},
                    {name: "Construction Related Occupation (CRO)"},
                    {name: "Construction Site Operative (CSO)"},
                    {name: "Experienced Technical, Supervisor or Manager"},
                    {name: "Experienced Worker"},
                    {name: "Labourer"},
                    {name: "Manager"},
                    {name: "Plant Operator"},
                    {name: "Professionally Qualified Person"},
                    {name: "Provisional"},
                    {name: "Site Visitor"},
                    {name: "Skilled Worker"},
                    {name: "Trainee (craft and operative)"},
                    {name: "Trainee (technical, supervisory and management)"},
                    {name: "Trainee (temporary card)"}
                ];

                scope.countries = COUNTRIES;
                let nullCountry = scope.countries.findIndex(item => item.name === 'Please select a country');
                scope.countries.splice(nullCountry, 1);

                scope.operativeDob = new Date();
                scope.cscsCardExpiry = new Date();

                scope.hasWritePermission = false;

                scope.formatValues = function(){
                    let country = scope.countries.find(item => item.code === scope.operativeData.nationality);
                    if(country) {
                        country.ticked = true;
                    }

                    let cscsCardTypes = scope.cscsCardTypes.find(item => item.name === scope.operativeData.cscsCardType);
                    if(cscsCardTypes) {
                        cscsCardTypes.ticked = true;
                    }

                };

                scope.formatForm = function () {
                    scope.operativeDob = new Date(scope.operativeData.dob);
                    scope.cscsCardExpiry = new Date(scope.operativeData.cscsCardExpiry);

                    if (scope.operativeData.qualifications !== undefined) {
                        scope.qualifications = scope.operativeData.qualifications;
                        for (let temp of scope.qualifications) {
                            if (temp.dateAchieved) {
                                temp.dateAchieved = new Date(temp.dateAchieved);
                            }
                            if (temp.dateExpires) {
                                temp.dateExpires = new Date(temp.dateExpires);
                            }
                        }
                    }
                };

                scope.operativeDataTemp = {};
                scope.updateNationality = function(){
                    if (scope.operativeDataTemp.nationality[0]){
                        scope.operativeData.nationality = scope.operativeDataTemp.nationality[0].code;
                    }else{
                        scope.operativeData.nationality = undefined;
                    }
                };
                scope.updateCscsCardType = function(){
                    if (scope.operativeDataTemp.cscsCardType[0]) {
                        scope.operativeData.cscsCardType = scope.operativeDataTemp.cscsCardType[0].name;
                    }else{
                        scope.operativeData.cscsCardType = undefined;
                    }
                };

                let getOperativeData = function () {
                    if (scope.operativeId) {
                        operative.get({id: scope.operativeId}).$promise.then(function (data) {
                            scope.operativeData = data;

                            scope.formatForm();
                            scope.formatValues();

                            scope.operativeData.selectedTrades = data.trades;

                            angular.forEach(scope.operativeData.qualifications, function (data) {

                                var uploader = new FileUploader();

                                data.certificateData = {
                                    uploader: uploader,
                                    list: []
                                };
                            });

                            trades.query(function (data) {
                                scope.trades = data;
                            }).$promise.then(function () {
                                angular.forEach(scope.operativeData.trades, function (trade) {
                                    angular.forEach(scope.trades, function (availableTrade) {
                                        if (trade.id == availableTrade.id) {
                                            availableTrade.ticked = true;
                                        }
                                    });
                                });
                            });

                        }).then(function () {
                            subcontractor.query({limit: 5000, sort: "fullName", hydration: "array"}, function (data) {
                                scope.subcontractors = data;
                                if (scope.operativeData.parent) {
                                    angular.forEach(scope.subcontractors, function (value) {
                                        if (value.id === scope.operativeData.parent.id) {
                                            value.ticked = true;
                                        }
                                    });
                                }
                            });
                        });
                    } else {

                        scope.operativeData = {
                            dob: false,
                            cscsCardExpiry: false,
                            cscsDocuments: [],
                            workPermitDocument: [],
                            addresses: [
                                {}
                            ]
                        };

                        trades.query(function (data) {
                            scope.trades = data;
                        });

                        subcontractor.query({limit: 5000, sort: "fullName", hydration: "array"}, function (data) {
                            scope.subcontractors = data;
                        });
                    }
                };

                scope.$watch('operativeId', function () {
                    scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteContactsOperatives');
                    if (scope.operativeId === null) {
                        scope.data = {};
                    } else {
                        getOperativeData();
                    }
                });


                scope.photoUpload = new FileUploader();

                scope.photo = {
                    uploader: scope.photoUpload,
                    list: []
                };

                scope.operativeData = (scope.operativeData) ? scope.operativeData : {workPermitDocument: []};
                scope.scsDocumentUploader = new FileUploader();
                scope.cscsDocuments = {
                    uploader: scope.scsDocumentUploader,
                    list: scope.operativeData.cscsDocuments
                };

                scope.workDocuments = {
                    uploader: new FileUploader(),
                    list: scope.operativeData.workPermitDocument
                };


                scope.removeQualification = function (event, index) {
                    event.preventDefault();
                    scope.operativeData.qualifications.splice(index, 1);
                };

                scope.toggleQualification = function (event) {
                    event.preventDefault();
                    this.qualification.hidden = !this.qualification.hidden;
                };

                scope.addQualification = function (event) {
                    event.preventDefault();
                    if (scope.operativeData.qualifications === undefined) {
                        scope.operativeData.qualifications = [];
                        scope.qualifications = scope.operativeData.qualifications;
                    }

                    let uploader = new FileUploader();

                    let certificate = {
                        uploader: uploader,
                        list: []
                    };
                    scope.operativeData.qualifications.push({
                        hidden: false,
                        name: "",
                        certificateData: certificate,
                        dateAchieved: false,
                        dateExpires: false
                    });

                };

                scope.selectSubcontractor = function (data) {
                    scope.operativeData.parent = data;
                };

                const notEmpty = function (text) {
                    return typeof text === "string" && text.trim() !== "";
                }
                const addressIsInvalid = function (address) {
                    return [address.street1, address.street2, address.county, address.country, address.postcode].map(line => notEmpty(line)).some(test => test == false);
                };

                scope.createOperative = function () {
                    scope.submitted = true;

                    scope.operativeData.dob = scope.operativeDob.toISOString().split("T")[0];
                    scope.operativeData.cscsCardExpiry = scope.cscsCardExpiry.toISOString().split("T")[0]+ ' 00:00:00';
                    if (scope.qualifications !== undefined) {
                        scope.operativeData.qualifications = scope.qualifications;
                        for (const element of scope.operativeData.qualifications) {
                            for (let temp of scope.qualifications) {
                                if (temp.id === element.id) {
                                    if (temp.dateAchieved) {
                                        element.dateAchieved = temp.dateAchieved.toISOString().split("T")[0]+ ' 00:00:00';
                                    }

                                    if (temp.dateExpires) {
                                        element.dateExpires = temp.dateExpires.toISOString().split("T")[0]+ ' 00:00:00';
                                    }

                                }
                            }
                        }
                    }

                    let saveObj = new operative(scope.operativeData);


                    // Operative form is valid, has no addresses or has a valid address
                    // (see CORE-6148; ideally should either require addresses or not - atm they are required only when creating via form)
                    if (scope.form.$valid && (!scope.operativeData.addresses.length || !addressIsInvalid(scope.operativeData.addresses[0])) && scope.operativeData.cscsCardType) {
                        saveObj.$save().then(function (data) {
                            scope.form.$setPristine();
                            scope.$emit('operativeSaved', data);
                        });
                    } else {
                        scope.formatForm();

                        console.log(scope.form);
                    }
                };
            }
        };
    });
