'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.region
 * @description
 * # region
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
    .factory('region', ['Resource', 'ENV', function ($resource, ENV) {
        //get account defaultCurrency
        var localeLang = 'en-GB';
        var userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails != null) {
            var defaultCurrency = userDetails.account.defaultCurrency;
            if (defaultCurrency == "MYR") {
                localeLang = 'en-MY';
            } else if (defaultCurrency == "SGD") {
                localeLang = 'en-SG';
            }
        }

        return $resource(
            ENV.apiEndpoint + '/regions/:id', {id: '@id'}, {
                query:{
                    cache: false,
                    isArray: true,
                    params: {
                        sort: 'name',
                        locale: localeLang
                    }
                }
            }
        );
    }]);
