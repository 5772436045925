import {CsUtils} from '../../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProgressCtrl
     * @description
     * # ProgressCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProgressCtrl', function (
            $scope,
            $route,
            $rootScope,
            $routeParams,
            $filter,
            project,
            workscope,
            progressGroup,
            assessment,
            $http,
            $window,
            ENV,
            $location,
            productApplication
        ) {
            $rootScope.projectId = $scope.projectId = $routeParams.id;
            project.get({id: $routeParams.id}, {}, function (data) {
                $scope.project = data;
            });
            $scope.setAssessment = function (assessment) {
                $scope.assessment = assessment;
            };

            $scope.sliderOptions = {
                floor: 0,
                ceil: 100,
                translate: function (value) {
                    return value + '%';
                },
                disabled: false
            };

            // Progress group list
            if ($route.current.$$route.isGroupList) {
                productApplication.getBillFilters({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.filters = {
                        assessments: data.assessments,
                        zones: $filter('zoneList')(data.zones)
                    };
                });
                $scope.filterSliders = function (fetchOptions, options) {
                    if (fetchOptions) {
                        options.id = $routeParams.id;
                        productApplication.getBillFilters(options).$promise.then(function (data) {
                            angular.forEach(data.assessments, function (a) {
                                if (a.id === $scope.filters.selectedAssessments[0].id) {
                                    a.ticked = true;
                                }
                            });
                            $scope.filters = {
                                assessments: data.assessments,
                                zones: $filter('zoneList')(data.zones)
                            };
                        });
                    } else {
                        // Fetch the sliders
                        options.sort = 'pg.name';
                        options.groups = 'pgzpMixedGroups';
                        progressGroup.getZoneProgresses(options).$promise.then(function (data) {
                            $scope.zoneProgresses = data;
                            $scope.$$postDigest(function () {
                                $scope.$broadcast('rzSliderForceRender');
                            });
                        });
                    }
                };
            }

            // Single group page
            if ($route.current.$$route.isGroup) {
                progressGroup.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.group = data;
                    $scope.projectId = data.project.id;
                    $rootScope.projectId = data.project.id;
                });

                $scope.componentsLoading = false;
                $scope.loadComponents = function () {
                    $scope.componentsLoading = true;
                    progressGroup.getComponents({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.alcs = data;
                    });
                };
            }

            $scope.exportProgressData = function (isProduct) {
                var httpUrl = (isProduct) ? '/export-progress-products' : '/export-progress-groups';

                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/projects/' + $routeParams.id + httpUrl
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            }

        });
})();
