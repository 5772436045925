setupAccountsRoutes = function ($routeProvider) {
    $routeProvider
        .when('/accounts/orders', {
            data: {permissions: {'only': ['canReadAccountsOrders']}}
        })
        .when('/accounts/invoices', {
            data: {permissions: {'only': ['canReadAccountsInvoices']}}
        })
        .when('/accounts/external-costs', {
            data: {permissions: {'only': ['canReadCostsExternalCosts']}}
        })
        .when('/accounts/applications/supplyfit', {
            data: {permissions: {'only': ['canReadAccountsSupplyFitApplications']}}
        })
        .when('/accounts/applications/labour', {
            data: {permissions: {'only': ['canReadAccountsLabourApplications']}}
        })
        .when('/accounts/nominal-codes', {
            data: {permissions: {'only': ['canReadAccountsNominalCodes']}}
        })
        .when('/accounts/vat-codes', {
            data: {permissions: {'only': ['canReadAccountsVATCodes']}}
        })
        .when('/accounts/cis-codes', {
            data: {permissions: {'only': ['canReadAccountsCISCodes']}}
        })
        .when('/accounts/export', {
            data: {permissions: {'only': ['canReadAccountsExports']}}
        })
        .when('/accounts/export/:id', {
            data: {permissions: {'only': ['canReadAccountsExports']}}
        });
    return $routeProvider;
};
