import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleModalService} from "ngx-simple-modal";
import {ChangeSupplierModalComponent} from "./change-supplier-modal.component";
import {ProductOrder} from "../chalkstring-api/services/product-order.service";

@Component({
    template: `
        <a [ngClass]="{btn: !linkOnly}" (click)="spawnModal()"><i class="icon-edit"></i></a>
    `,
    selector: 'change-supplier-button'
})

export class ChangeSupplierButtonComponent
{
    @Input() materialOrder: ProductOrder;
    @Input() linkOnly: boolean;

    @Output() orderChanged = new EventEmitter<any>();

    constructor(private _simpleModalService: SimpleModalService)
    {
    }

    spawnModal()
    {
        console.log(this.materialOrder);
        this._simpleModalService.addModal(ChangeSupplierModalComponent,
            {materialOrder: this.materialOrder}).subscribe(success => {
            if (success) {
                this.orderChanged.emit(this.materialOrder);
            }
        });
    }
}
