(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('paymentApplication', ['Resource', 'ENV', function ( $resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/paymentapplications/:id', { id: '@id' }, {
                }
            );
        }]);
})();