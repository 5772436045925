'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:newLabouractivity
 * @description
 * # newLabouractivity
 */
angular.module('scswebappApp')
    .directive('newLabouractivity', function ($route, $routeParams, $rootScope, $location, trades, labouractivity, UNITSTEXT, FileUploader) {
        return {
            template: require('./new-labouractivity.html'),
            restrict: 'E',
            scope: {
                newLabourActivityAdded: '&'
            },
            link: function postLink(scope) {
                scope.unit = UNITSTEXT;
                scope.unitsArray = Object.entries(scope.unit).map(([attribute, name]) => ({ attribute, name }));


                scope.activity = {
                    externalLinks: [],
                    dataSheets: []
                };

                scope.formatTrade = function () {
                    if (scope.activity.trade[0]){
                        scope.activity.trade = scope.activity.trade[0].id;
                    }
                };
                scope.formatQuantity = function () {
                    if (scope.activity.quantityUnit[0]){
                        scope.activity.quantityUnit = scope.activity.quantityUnit[0].attribute;
                    }
                };

                scope.formatValues = function () {
                    const found = scope.allTrades.find(e => e.id === scope.activity.trade);
                    if(found) {found.ticked = true;}

                    const foundTwo = scope.unitsArray.find(e => e.attribute === scope.activity.quantityUnit);
                    if(foundTwo) {foundTwo.ticked = true;}
                };

                let loadOptions = function () {
                    // Get trades
                    trades.query({sort: 'name'}).$promise.then(function (trades) {
                        scope.activity.trade = scope.activity.trade ? scope.activity.trade.id || null : null;
                        scope.allTrades = trades;
                        scope.formatValues();
                    });
                };

                // Hack to get activity data for edit
                if ($route.current.$$route.isEdit &&
                    $route.current.$$route.originalPath.indexOf('new-product') < 0 &&
                    $route.current.$$route.originalPath.indexOf('edit-product') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-quote') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-quote-request') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-labour-quote') < 0 &&
                    $route.current.$$route.originalPath.indexOf('edit-labour-quote') < 0
                ) {
                    labouractivity.get({id: $routeParams.id}).$promise.then(function (data) {
                        scope.activity = data;
                        loadOptions();
                    });
                } else {
                    loadOptions();
                }



                // Add Link
                scope.addLink = function () {
                    scope.activity.externalLinks.push({label: '', url: ''});
                };

                // Remove Link
                scope.removeLink = function (index) {
                    scope.activity.externalLinks.splice(index, 1);
                };

                // Files/Documents
                scope.laDataSheetUploader = new FileUploader;

                scope.laUpload = {
                    uploader: scope.laDataSheetUploader
                };

                scope.deleteFile = function (index) {
                    scope.activity.dataSheets.splice(index, 1);
                };

                scope.saveForm = function (saveAsNew = false) {
                    scope.laSubmitted = true;
                    scope.formatQuantity();
                    scope.formatTrade();
                    console.log(scope.activity.quantityUnit)
                    console.log(scope.activity.trade)

                    if (scope.laForm.$valid) {
                        var saveObj = new labouractivity(scope.activity);
                        if (saveAsNew) {
                            delete(saveObj.id);
                        }
                        saveObj.$save().then(function (data) {
                            scope.laForm.$setPristine();
                            scope.laSubmitted = false;
                            scope.activity = {};
                            scope.$emit('newLabourActivityAdded', data);
                            scope.newLabourActivityAdded({labourActivity: data});
                            if ($route.current.originalPath === '/new-labour-activity' || $route.current.originalPath === '/edit-labour-activity/:id') {
                                $location.path('/labour-activities');
                            }
                        });
                    }
                }
            }
        };
    });
