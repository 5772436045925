'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('backButton', function ($window) {
        return {
            template: '<a href="" ng-click="goBack()" class="btn-back">{{btnCopy}}</a>',
            restrict: 'E',
            link: function postLink(scope, element, attrs) {
                scope.btnCopy = (attrs.customText) ? attrs.customText : 'Back';
                scope.goBack = function(){
                    $window.history.back();
                };
            }
        };
    });
