import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Assessment} from "../../chalkstring-api/services/assessment.service";
import {SimpleModalService} from "ngx-simple-modal";
import {ImportBoqModalComponent} from "../../bill/modals/import-boq-modal.component";

@Component({
    template: `
        <a [ngClass]="{btn: !linkOnly}" (click)="spawnModal()"><i class="icon-upload"></i> Import bill of quantities</a>
    `,
    selector: 'import-bill-of-quantities-button'
})

export class ImportBillOfQuantitiesButtonComponent
{
    @Input() assessment: Assessment;
    @Input() linkOnly: boolean;

    @Output() assessmentChanged = new EventEmitter<Assessment>();

    constructor(private _simpleModalService: SimpleModalService)
    {
    }

    spawnModal()
    {
        this._simpleModalService.addModal(ImportBoqModalComponent,
            {assessment: this.assessment}).subscribe(success => {
            if (success) {
                this.assessmentChanged.emit(this.assessment);
            }
        });
    }
}
