(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.labourskills
	 * @description
	 * # labourskills
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('LabourQuoteRequest', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/labourquotetemplates/:id', {id: '@id'}, {
                    customSave: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/labourquotetemplates',
                        transformRequest: function(data){

                            var newData = {
                                'name': data.name,
                                'priceRequests': []
                            };

                            if( data.sData && data.sData[0] && data.sData[0].id ){
                                newData.subcontractor = data.sData[0].id;
                            }

                            angular.forEach( data.priceRequests, function(value){
                                newData.priceRequests.push({labourActivity: value.material.id});
                            });
                            return angular.toJson(newData);
                        }
                    },
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/labourquotetemplates/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/labourquotetemplates/:id/unarchive'
                    },
                    getPriceRequests: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/labourquotetemplates/:id/pricerequests',
                        isArray: true
                    }
	            }
	        );
	  }]);
})();
