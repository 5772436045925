(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.assessmentMaterial
     * @description
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('assessmentMaterial', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/assessmentmaterials/:id', {
                    id: '@id',
                    assessmentMaterialId: "@assessmentMaterialId",
                    newMaterialId: "@newMaterialId",
                    newPriceId: "@newPriceId"
                }, {
                    delete: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/assessmentmaterials/:id'
                    },
                    add: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentmaterials'
                    },
                    patch: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentmaterials/:id'
                    },
                    swapMaterial: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentmaterials/:assessmentMaterialId/swap/:newMaterialId',
                        isArray: true
                    },
                    changePrice: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentmaterials/:id/change-price/:newPriceId'
                    }
                }
            );
        }]);
})();
