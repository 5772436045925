import {Component} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {
    Assessment,
    AssessmentContractScope,
    AssessmentDocumentationFormat, AssessmentMosMode, AssessmentService
} from "../../chalkstring-api/services/assessment.service";
import { NamedAssessmentDocumentFormat, AssessmentConfig } from '../panels/assessment-config-panel.component';
import {first} from "rxjs/operators";

export interface AssessmentConfigModel
{
    assessment: Assessment;
    onSite: boolean;
}

class AssessmentConfigForm
{
    name: string;
    contractScope: AssessmentContractScope;
    documentationFormat: AssessmentDocumentationFormat;
    materialOnSiteRatePercentage: number;
    mosMode: AssessmentMosMode;
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content">
            <div class="modal-header">
                <h2>Assessment configuration</h2>
            </div>
            <form #assessmentConfigForm="ngForm" (ngSubmit)="submitForm()">
                <div class="modal-body">
                    <label for="assessmentName">Assessment name</label>
                    <input type="text" id="assessmentName" name="name" [(ngModel)]="assessmentConfig.name" required/>
                    <p class="form-error" *ngIf="!assessmentConfig.name">Please enter an assessment name</p>

                    <label for="contractScope">Contract scope</label>
                    <select id="contractScope" name="contractScope" [(ngModel)]="assessmentConfig.contractScope">
                        <option value="" disabled selected>Please select…</option>
                        <option value="remeasure">Re-measure</option>
                        <option value="fixed_price">Lump sum</option>
                    </select>
                    <ng-container *ngIf="onSite && assessmentConfig.contractScope !== this.assessment.contractScope">
                        <div class="badge block block-warning">
                            <ng-container *ngIf="'remeasure' === assessmentConfig.contractScope">
                                WARNING: In remeasure mode, changes to products and quantities can result in the
                                package total differing from the tendered amount.
                            </ng-container>
                            <ng-container *ngIf="'fixed_price' === assessmentConfig.contractScope">
                                WARNING: In lump sum mode, changes to products will adjust their effective rates,
                                based on their tendered rates and quantities. Current effective rates may be lost.
                            </ng-container>
                            Are you sure you want to proceed?
                        </div>
                    </ng-container>

                    <label for="documentationFormat">Document format</label>
                    <select id="documentationFormat" name="documentationFormat" [(ngModel)]="assessmentConfig.documentationFormat">
                        <option value="" disabled selected>Please select…</option>
                        <option *ngFor="let df of documentationFormats" value="{{df.format}}">{{df.name}}</option>
                    </select>

                    <div class="row">
                        <label for="materialOnSiteRatePercentage">Material on site application rate</label>
                        <div *ngIf="!noMos" class="icon-input">
                            <input type="number" step="1" id="materialOnSiteRatePercentage" name="materialOnSiteRatePercentage" [(ngModel)]="assessmentConfig.materialOnSiteRatePercentage" required/>
                            <div>%</div>
                        </div>
                        <label class="checkbox-container">
                            <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="noMos">
                            <span class="checkbox"></span>No <abbr title="Material On Site">MOS</abbr>
                        </label>
                    </div>
                    <div class="row" *ngIf="!noMos">
                        <label for="mosMode">Material on site quantities sourced from:</label>
                        <select id="mosMode" name="mosMode" [(ngModel)]="assessmentConfig.mosMode">
                            <option value="" disabled selected>Please select…</option>
                            <option value="d">Logged deliveries</option>
                            <option value="o">Orders marked as sent</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button class="btn align-right" type="submit" [disabled]="assessmentConfigForm.submitted || !assessmentConfigForm.valid"><i class="icon-ok"></i> Save</button>
                </div>
            </form>
        </div>
    `
})
export class AssessmentConfigModalComponent extends SimpleModalComponent<AssessmentConfigModel, Assessment> implements AssessmentConfigModel
{
    assessment: Assessment;
    assessmentConfig: AssessmentConfigForm = new AssessmentConfigForm();
    onSite: boolean = false;

    noMos: boolean = false;

    documentationFormats: NamedAssessmentDocumentFormat[] = AssessmentConfig.documentationFormats;

    constructor(private _aService: AssessmentService)
    {
        super();
    }

    mapDataObject(data: AssessmentConfigModel)
    {
        super.mapDataObject(data);
        this.noMos = null === this.assessment.materialOnSiteRatePercentage;
        this.assessmentConfig.name = this.assessment.name;
        this.assessmentConfig.mosMode = this.assessment.mosMode;
        this.assessmentConfig.contractScope = this.assessment.contractScope;
        this.assessmentConfig.documentationFormat = this.assessment.documentationFormat;
        this.assessmentConfig.materialOnSiteRatePercentage = this.assessment.materialOnSiteRatePercentage;
    }

    submitForm()
    {
        if (this.noMos) {
            this.assessmentConfig.materialOnSiteRatePercentage = null;
        }
        const patchBody = new Assessment({...{id: this.assessment.id}, ...this.assessmentConfig});
        this._aService.patch(patchBody).pipe(first()).subscribe(res => {
            this.result = this.assessment = res;
            this.close();
        });
    }
}
