(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('productApplication', ['Resource', 'ENV', function ( $resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/productpaymentapplications/:id', { id: '@id', projectId: '@projectId', assessment: '@assessment', groups: '@groups', assessmentZoneId: '@assessmentZoneId', transition: '@transition'}, {
                    update: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id'
                    },
                    submitApplication: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/outgoingpaymentapplications/:id/transitions/submit'
                    },
                    approveApplication: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/approvals?assessmentZone=:assessmentZoneId'
                    },
                    saveDraftIncoming: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/save-draft'
                    },
                    importIncoming: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/import-application-incoming-saf-bill-template'
                    },
                    getBillRates: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/assessments/:assessment/application-bill'
                    },
                    getApplicationOutgoingSupplyAndFitBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/application-outgoing-saf-bill'
                    },
                    getApplicationOutgoingSupplyAndFitBillOptions: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-outgoing-saf-bill-options'
                    },
                    getApplicationOutgoingFixedCostsBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/application-outgoing-fixedcosts-bill'
                    },
                    getApplicationIncomingSupplyAndFitBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/application-incoming-saf-bill'
                    },
                    getApplicationIncomingSupplyAndFitBillOptions: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-incoming-saf-bill-options'
                    },
                    getApplicationIncomingSupplyAndFitDraft: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-incoming-saf-draft'
                    },
                    getApplicationIncomingSupplyAndFitReviewBill: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:projectId/application-incoming-saf-review-bill'
                    },
                    getApplicationIncomingFixedCostsReviewBill: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/application-incoming-fixedcosts-review-bill'
                    },
                    getApplicationIncomingFixedCostsBill: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/application-incoming-fixedcosts-bill'
                    },
                    getApplicationIncomingFixedCostsBillOptions: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-incoming-fixedcosts-bill-options'
                    },
                    getBillFilters: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-bill-filter-options' //TODO replace this with proper thing
                    },
                    getBillReview: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/projects/:id/product-application-review'
                    },
                    modifyBillItem: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/paymentapplicationproductitems/:id'
                    },
                    addItems: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/productpaymentapplications/items'
                    },
                    addFixedCostItem: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/productpaymentapplications/fixed-cost-items'
                    },
                    markAsApproved: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/paymentapplications/:id/approve'
                    },
                    markAsSent: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/transitions/send'
                    },
                    getDraftSummary: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:projectId/productpaymentapplications/draft/summary'
                    },
                    transition: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/transitions/:transition'
                    },
                    postPawssi: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/pawssi'
                    },
                    getPaapis: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/paapis'
                    },
                    postPaapi: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/paapi'
                    },
                    patchPaapi: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/paapi'
                    },
                    deletePaapi: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/paapis/:id'
                    },
                    clearPaapiRemeasurement: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/ap/:apId/clear-remeasurement'
                    },
                    getPafcis: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/pafcis'
                    },
                    postPafci: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/pafci'
                    },
                    patchPafci: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:projectId/assessments/:assessment/pafci'
                    },
                    getPaoais: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/paoais'
                    },
                    postPaoai: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/paoai'
                    },
                    deletePaoai: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/paoais/:id'
                    },
                    getNominalCodeAssignments: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/nominalcodeassignments',
                        isArray:true
                    },
                    deletePafci: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/pafcis/:id'
                    },
                    additional: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/productpaymentapplications/:id/additional'
                    },
                }
            );
        }]);
})();
