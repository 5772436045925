(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.file
	 * @description
	 * # file
	 * Service in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .service('file', function ($resource, ENV) {
	    // AngularJS will instantiate a singleton by calling "new" on this function
	        return $resource(
	            ENV.apiEndpoint + '/files/:id', { id: '@id' }, {
	                all: {
	                    url: ENV.apiEndpoint + '/files',
	                    method: 'GET'
	                },
	                byId: {
	                    url: ENV.apiEndpoint + '/files/:id',
	                    method: 'GET'
	                },
	                getType: {
	                    isArray: true,
	                    url: ENV.apiEndpoint + '/projects/:id/:type',
	                    method: 'GET'
	                },
	                sign: {
	                    url: ENV.apiEndpoint + '/files/sign',
	                    method: 'POST'
	                },
	                complete: {
	                    url: ENV.apiEndpoint + '/files/:id/complete',
	                    method: 'POST'
	                }
	            }
	        );
	  });
})();