import {Component, Input, ElementRef, LOCALE_ID, Inject} from "@angular/core";
import * as Highcharts from 'highcharts';
import {formatDate} from "@angular/common";
import {LocaleCurrencyPipe} from "@generic/pipes/locale-currency.pipe";
import {DashboardCommercialData} from "../../chalkstring-api/services/dashboard.service";

@Component({
    selector: 'margin-time-graph',
    template: '<div></div>',
    providers: [
        LocaleCurrencyPipe,
    ],
    styles: [':host { display: block; clear: left; }']
})
export class MarginTimeGraphComponent
{
    private _commercialData: DashboardCommercialData;
    @Input() set commercialData(data: DashboardCommercialData)
    {
        this._commercialData = data;
        this.renderMargin();
    }
    get commercialData(): DashboardCommercialData
    {
        return this._commercialData;
    }

    public colors;
    public margins;

    private series = [];
    private i = 0;
    private now = new Date();

    constructor(public _currencyPipe: LocaleCurrencyPipe, private elRef: ElementRef, @Inject(LOCALE_ID) public locale)
    {
        this.colors = [
            '#37a6de',
            '#63c94a',
            '#f39200',
            '#e6007e',
            '#ffde00',
            '#bccbd4',
            '#f12e2e',
            '#666',
            '#888',
            '#aaa',
            '#ccc'
        ];
        Highcharts.setOptions({
            credits: {enabled: false},
            lang: {
                thousandsSep: ','
            },
            colors: this.colors
        });
    }

    renderMargin()
    {
        this.series = [];
        for (let report of this.commercialData.projects) {
            let actualMarginData = [];
            let projectedMarginData = [];
            for (let datetime in report.twelveMonthsMarginsData) {
                let v = report.twelveMonthsMarginsData[datetime];
                let then = new Date(datetime);
                if (null !== v.pc) {
                    actualMarginData.push({
                        name: report.projectName + ', ' + formatDate(then, 'MMM yyyy', this.locale),
                        x: then,
                        y: v.pc,
                        revenue: v.r,
                        costs: v.c,
                        ohp: v.r - v.c,
                        revenueCurrency: this._currencyPipe.transform(v.r),
                        costsCurrency: this._currencyPipe.transform(v.c),
                        ohpCurrency: this._currencyPipe.transform(v.r - v.c),
                    });
                }
                projectedMarginData.push({
                    name: report.projectName + ', ' + formatDate(then, 'MMM yyyy', this.locale),
                    x: then,
                    y: v.p_pc,
                    revenue: v.p_r,
                    costs: v.p_c,
                    ohp: v.p_r - v.p_c,
                    revenueCurrency: this._currencyPipe.transform(v.p_r),
                    costsCurrency: this._currencyPipe.transform(v.p_c),
                    ohpCurrency: this._currencyPipe.transform(v.p_r - v.p_c),
                });
            }

            this.series.push({
                type: 'line',
                name: report.projectName,
                data: actualMarginData,
                color: this.colors[this.i],
                tooltip: {
                    pointFormatter: function () {
                        return 'Revenue to Date: <b>' + (this.revenueCurrency) + '</b><br>' +
                            'Costs to Date: <b>' + (this.costsCurrency) + '</b><br>' +
                            'Actual OHP: <b>' + (this.ohpCurrency) + '</b><br>' +
                            'Actual Margin: <b>' + this.y.toFixed(2) + '%</b>';
                    }
                },
                marker: {
                    radius: 3
                },
                negativeColor: '#f12e2e',
                events: {
                    legendItemClick: function () {
                        let projectedSeries = this.chart.series[this.index + 1];
                        if (this.visible) {
                            projectedSeries.hide();
                        } else {
                            projectedSeries.show();
                        }
                    }
                }
            });
            this.series.push(
                {
                    type: 'line',
                    name: 'Projected Margin',
                    data: projectedMarginData,
                    color: this.colors[this.i],
                    dashStyle: 'Dash',
                    showInLegend: false,
                    lineWidth: 1,
                    tooltip: {
                        pointFormatter: function () {
                            return 'Projected Revenue: <b>' + this.revenueCurrency + '</b><br>' +
                                'Projected Costs: <b>' + this.costsCurrency + '</b><br>' +
                                'Projected OHP: <b>' + this.ohpCurrency + '</b><br>' +
                                'Projected Margin: <b>' + this.y.toFixed(2) + '%</b>';
                        }
                    }
                }
            );
            this.i++;
        }

        // Draw the chart
        this.margins = Highcharts.chart(this.elRef.nativeElement, {
            chart: {
                type: 'line',
                height: 300,
                style: {
                    fontFamily: 'inherit;'
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 0
                    }
                }
            },
            title: {
                text: 'Margins'
            },
            xAxis: {
                type: 'datetime',
                min: new Date().setFullYear(this.now.getFullYear() - 1),
                // max: new Date(),
                dateTimeLabelFormats: {
                    day: '%b',
                    week: '%b',
                    month: '%b %y',
                    year: '%y'
                },
                tickInterval: 30 * 24 * 3600 * 1000,
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                title: {
                    text: 'Percentage'
                },
                tickInterval: 10,
                plotLines: [
                    {
                        color: '#999',
                        width: 1,
                        value: 0,
                        zIndex: 2
                    }
                ]
            },
            series: this.series
        });
    };
}
