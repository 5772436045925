'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:deleteButton
 * @description
 * # deleteButton
 * ( index, id to delete, objects array name (for splice), service )
 * <deletebutton data-id="{{product.id}}" data-redirect="product" data-itemservice="product"></deletebutton>
 */
angular.module('scswebappApp')
    .directive('contactsOffices', function ($rootScope, $routeParams, organisation, people, $location, $anchorScroll, $injector) {
        return {
            template: require('./contacts-offices.html'),
            restrict: 'E',
            scope:{},
            link: function postLink(scope, element, attrs) {

                let service = new $injector.get(attrs.service);
                scope.contactFamily = attrs.contactFamily;

                function getData(){
                    service.get({id: $routeParams.id}).$promise.then(function(data){
                        scope.children = data.children;
                        scope.hasOtherOffices = scope.children && scope.children.filter(child => !child.firstName).length > 0;
                        scope.hasOtherContacts = scope.children && scope.children.filter(child => child.firstName).length > 0;
                        return data;
                    }).then(function(data){
                        //Create array of offices
                        scope.offices = [];
                        angular.forEach(data.children, function(value){
                            if (!value.firstName) {
                                scope.offices.push(value);
                            }
                        });
                    });
                }
                getData();

                scope.officeKeyword = 'Add';
                scope.contactKeyword = 'Add';

                scope.addOffice = function () {
                    scope.officeSubmitted = true;
                    if (scope.officeForm.$valid) {
                        scope.officeData.parent = $routeParams.id;
                        var saveObj = new organisation(scope.officeData);
                        saveObj.$save().then(function (data) {
                            getData();
                            scope.officeSubmitted = false;
                            scope.officeKeyword = 'Add';
                            scope.officeData = {};
                        });
                    }
                };

                scope.deleteOffice = function(index, id){
                    organisation.delete({id: id}).$promise.then(function (data) {
                        getData();
                    });
                };

                scope.contactDataTemp = {};
                scope.formatOfficedata = function(){
                    scope.contactData.parent = scope.contactDataTemp.parent[0].id;
                };

                scope.editOffice = function(data){
                    scope.officeKeyword = 'Edit';
                    var old = $location.hash();
                    $location.hash('office');
                    $anchorScroll();
                    $location.hash(old);
                    scope.officeData = data;
                };

                scope.addContact = function () {

                    scope.contactSubmitted = true;

                    if (scope.contactForm.$valid) {

                        if( !scope.contactData.parent ){
                            scope.contactData.parent = $routeParams.id;
                        }

                        var saveObj = new people(scope.contactData);

                        saveObj.$save().then(function (data) {
                            getData();
                            scope.contactSubmitted = false;
                            scope.contactKeyword = 'Add';
                            scope.contactData = {};
                        });
                    }
                };

                scope.deleteContact = function(index, id){
                    people.delete({id: id}).$promise.then(function (data) {
                        getData();
                    });
                };

                scope.editContact = function(data, parentId){
                    scope.contactKeyword = 'Edit';
                    var old = $location.hash();
                    $location.hash('contact');
                    $anchorScroll();
                    $location.hash(old);
                    scope.contactData = data;
                    scope.contactData.parent = parentId;
                    //set contact office
                    if (parentId) {
                        angular.forEach(scope.offices, function (value) {
                            value.ticked = value.id === parentId;
                        });
                    }
                };

                scope.cancelEdit = function(){
                    scope.officeKeyword = 'Add';
                    scope.contactKeyword = 'Add';
                    scope.contactData = {};
                    scope.officeData = {};
                };


            }
        };
    });
