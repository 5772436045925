import {Component} from "@angular/core";
import {AbstractNavigableTableViewComponent} from "../../table-view/abstract-table-view.component";
import {Router} from "@angular/router";
import {TableViewColumn} from "../../table-view/table-view-column";
import {TableViewFilterText} from "../../table-view/filters/table-view-filter-text.component";
import {Observable, of} from "rxjs";
import {Client, ClientService} from "../../chalkstring-api/services/client.service";
import {TableViewCellContactMethodCollectionComponent} from "../../table-view/cells/table-view-cell-contact-method-collection.component";

@Component({
    selector: 'client-list',
    styles: [require('../../table-view/abstract-table-view.component.scss')],
    template: require('../../table-view/abstract-table-view.component.html'),
})
export class ClientListComponent extends AbstractNavigableTableViewComponent<Client>
{
    navigateTo = '/client';

    constructor(
        protected _service: ClientService,
        protected _router: Router,
    )
    {
        super(_service, _router);
    };

    protected _initColumnSet(): Observable<TableViewColumn[]>
    {
        let cols = [];

        const nameCol = new TableViewColumn('fullName', 'Name');
        nameCol.filter = new TableViewFilterText(nameCol);
        nameCol.filter.autoActivate = true;
        cols.push(nameCol);

        const emailCol = new TableViewColumn('emailAddresses', 'Email addresses', TableViewCellContactMethodCollectionComponent);
        cols.push(emailCol);

        const phoneCol = new TableViewColumn('telephoneNumbers', 'Telephone numbers', TableViewCellContactMethodCollectionComponent);
        cols.push(phoneCol);

        return of(cols);
    }
}
