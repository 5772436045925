import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GenericModule } from "./generic.module";
import {ApplyToAllModalComponent} from "./rate-build-up/modal/apply-to-all-modal.component";
import {ApplyToAllButtonComponent} from "./rate-build-up/apply-to-all-button.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {EditRateBuildUpProductModalComponent} from "./rate-build-up/modal/edit-rate-build-up-product-modal.component";
import {EditRateBuildUpProductDirective} from "./rate-build-up/modal/edit-rate-build-up-product.directive";
import {EditAssessmentProductPricingModesModalComponent} from "./rate-build-up/modal/edit-assessment-product-pricing-modes-modal.component";
import {EditAssessmentProductPricingModeControlsComponent} from "./rate-build-up/edit-assessment-product-pricing-mode-controls.component";
import {AssessmentProductPricingModalComponent} from "./rate-build-up/modal/assessment-product-pricing-modal.component";
import {AssessmentProductPriceComponent} from "./rate-build-up/assessment-product-price.component";
import { ChangeAssessmentProductScopeModalComponent } from './rate-build-up/modal/change-assessment-product-scope-modal.component';
import {RateBuildUpIssuesComponent} from "./rate-build-up/issues/rate-build-up-issues.component";
import {RateBuildUpIssueFlagListComponent} from "./rate-build-up/issues/rate-build-up-issue-flag-list.component";
import {RateBuildUpIssueService} from "./rate-build-up/issues/rate-build-up-issue.service";
import {RateBuildUpIssueFlagComponent} from "./rate-build-up/issues/rate-build-up-issue-flag.component";
import {UpgradeModule} from "@angular/upgrade/static";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GenericModule,
        NgSelectModule,
        UpgradeModule,
    ],
    exports: [
        AssessmentProductPriceComponent,
        ChangeAssessmentProductScopeModalComponent,
        EditRateBuildUpProductModalComponent,
        EditAssessmentProductPricingModeControlsComponent,
        RateBuildUpIssuesComponent,
        RateBuildUpIssueFlagListComponent,
        RateBuildUpIssueFlagComponent,
    ],
    declarations: [
        AssessmentProductPriceComponent,
        ChangeAssessmentProductScopeModalComponent,
        ApplyToAllButtonComponent,
        ApplyToAllModalComponent,
        AssessmentProductPricingModalComponent,
        EditAssessmentProductPricingModeControlsComponent,
        EditRateBuildUpProductDirective,
        EditRateBuildUpProductModalComponent,
        EditAssessmentProductPricingModesModalComponent,
        RateBuildUpIssuesComponent,
        RateBuildUpIssueFlagListComponent,
        RateBuildUpIssueFlagComponent,
    ],
    entryComponents: [
        ApplyToAllButtonComponent,
        ApplyToAllModalComponent,
        AssessmentProductPriceComponent,
        AssessmentProductPricingModalComponent,
        ChangeAssessmentProductScopeModalComponent,
        EditRateBuildUpProductModalComponent,
        EditAssessmentProductPricingModeControlsComponent,
        EditAssessmentProductPricingModesModalComponent,
        RateBuildUpIssuesComponent,
        RateBuildUpIssueFlagListComponent,
        RateBuildUpIssueFlagComponent,
    ],
    providers: [
        RateBuildUpIssueService,
    ]
})
export class RateBuildUpModule {

}
