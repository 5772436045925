(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.material
	 * @description
	 * # material
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	    .factory('materialOrders', ['ENV', 'Resource', 'UNITSTEXT', 'DateUtils', function (ENV, $resource, what, DateUtils) {
	        return $resource(
	            ENV.apiEndpoint + '/materialorders/:id', {id: '@id', index:'@index', groups: '@groups', viaList: '@viaList'}, {
                    saveOrder:{
                        method: 'POST',
                        isArray:true,
                        url:  ENV.apiEndpoint + '/materialorders/split'
                    },
                    sendOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/transitions/send'
                    },
                    cancelOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/transitions/cancel'
                    },
                    uncancelOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/uncancel'
                    },
                    completeOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/transitions/complete'
                    },
                    patchOrder:{
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/materialorders/:id',
                        transformRequest: function (data) {

                            if (data.documents) {
                                let documents = [];
                                angular.forEach(data.documents, function (value) {
                                    var comment = (value.comment) ? value.comment : null;
                                    if (value.file && value.file.id) {
                                        documents.push({'comment': comment, 'file': value.file.id});
                                    } else {
                                        documents.push({'comment': comment, 'file': value.id});
                                    }
                                });
                                data.documents = documents;
                            }

                            return angular.toJson(data);
                        }
                    },
                    invoiceOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/invoice'
                    },
                    addOrderItem:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorderitems'
                    },
                    patchOrderItem:{
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/materialorderitems/:id'
                    },
                    patchZoneQuantity:{
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/materialorderitemzonequantities/:id'
                    },
                    removeMaterial:{
                        method: 'DELETE',
                        url:  ENV.apiEndpoint + '/materialorderitems/:id'
                    },
                    addZoneQuantity: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorderitemzonequantities'
                    },
                    removeZoneQuantity: {
                        method: 'DELETE',
                        url:  ENV.apiEndpoint + '/materialorderitemzonequantities/:id'
                    },
                    filters: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/material-bill-filter-options'
                    },
                    confirmOrder: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/confirm',
                        transformRequest: function (data) {
                            if (data.hasOwnProperty('expectedDeliveryDate')) {
                                data.expectedDeliveryDate = DateUtils.toServerDateTime(data.expectedDeliveryDate);
                            } else if (data.hasOwnProperty('expectedDeliveryDates')) {
                                angular.forEach(data.expectedDeliveryDates, function (v, k) {
                                    data.expectedDeliveryDates[k] = DateUtils.toServerDateTime(v);
                                });
                            }

                            if (data.documents) {
                                let documents = [];
                                angular.forEach(data.documents, function (value) {
                                    var comment = (value.comment) ? value.comment : null;
                                    if (value.file && value.file.id) {
                                        documents.push({'comment': comment, 'file': value.file.id});
                                    } else {
                                        documents.push({'comment': comment, 'file': value.id});
                                    }
                                });
                                data.documents = documents;
                            }

                            return angular.toJson(data);
                        }
                    },
                    getDeliveredQuantities: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/materialorders/:id/delivered-quantities'
                    },
                    logDelivery: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/materialorders/:id/log-delivery',
                        transformRequest: function (data) {
                            var transformed = {
                                deliveryReference: data.deliveryReference,
                                deliveredQuantities: data.deliveredQuantities,
                                attachments: []
                            };
                            transformed.dateDelivered = DateUtils.toServerDateTime(data.dateDelivered);
                            angular.forEach(data.attachments, function (file) {
                                transformed.attachments.push(file.id);
                            });

                            return angular.toJson(transformed);
                        }
                    },
                    zoneBalances: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/materialorders/:id/zone-balances'
                    },
                    getDefaultVatCode: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/materialorders/:id/default-vat-code'
                    },
                    getPreviousAddresses: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/materialorders/previous-addresses',
                        isArray:true,
                    }
	            }
	        );
	    }]);
})();
