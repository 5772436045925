(function(){
    'use strict';

    /**
     * @ngdoc directive
     * @name scswebappApp.directive:estimates
     * @description
     * # estimates
     */
    angular.module('scswebappApp')
        .directive('dirtyStatusPoller', function ($injector, $timeout) {
            return {
                template: '<i ng-show="dirty" class="icon-spin3 inline-spinner"></i>',
                restrict: 'E',

                scope: {
                    id: '=',
                    service: '@'
                },

                link: function postLink(scope, elem, attr) {
                    scope.dirty = true;
                    var service = $injector.get(attr.service);


                    var unregisterWatch = scope.$watch('id', function(){
                        if (scope.id != undefined){
                            poll();
                            unregisterWatch();
                        }
                    });

                    var poll = function(){
                        service.isDirty({'id':scope.id}).$promise.then(function(data){
                            scope.dirty = data.dirty;
                            if (scope.dirty){
                                scope.timeout = $timeout(poll, 5000);
                            } else {
                                scope.timeout = $timeout(poll, 10000);
                            }
                        });
                    };

                    scope.$on(
                        "$destroy",
                        function( event ) {
                            $timeout.cancel( scope.timeout );
                        }
                    );
                }
            };
        });
})();