'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('newIssue', function (FileUploader, $route, issue, user, workscope, progressGroup, issuecategory) {
        return {
            template: require('./new-issue.html'),
            restrict: 'E',
            scope: {
                assessment: '=',
                assessmentZone: '=',
                workScopeStageId: '=',
                progressGroupZoneProgressId: '=',
                projectId: '=',
                issueId: '=',
                issue: '=?',
                issueSaved: '&'
            },
            transclude: true,
            link: function postLink(scope) {
                // Set type keyword for edit and new routes

                scope.fileUpload = {
                    uploader: new FileUploader
                };

                if (!scope.issue) {
                    scope.issue = {
                        files: []
                    };
                }

                scope.selectAssessment = function (data) {
                    if (scope.assessmentZone) {
                        angular.forEach(data.zones, function (az) {
                            if (az.id === scope.assessmentZone.id) {
                                az.ticked = true;
                            }
                        });
                    }
                    scope.zones = data.zones;
                };

                issuecategory.query().$promise.then(function (data) {
                    scope.issueCats = data;
                });

                let clearWatcher1 = scope.$watch('workScopeStageId', function () {
                    if (scope.workScopeStageId) {
                        scope.workScopeStage = workscope.getStage({id: scope.workScopeStageId}, {groups: 'workScopesStage_allNames'});
                    }
                });

                let clearWatcher1b = scope.$watch('progressGroupZoneProgressId', function () {
                    if (scope.progressGroupZoneProgressId) {
                        scope.progressGroupZoneProgress = progressGroup.getZoneProgress({id: scope.progressGroupZoneProgressId,groups:'pgzpMixedGroups'});
                      console.log(scope.progressGroupZoneProgress);

                    }
                });

                let clearWatcher1c = scope.$watch('projectId', function () {
                    if (scope.projectId) {
                        issue.getProjectIssueAssigneeOptions({projectId: scope.projectId}).$promise.then(function (data) {
                            scope.assigneeOptions = [];
                            return data;
                        }).then(function (data) {
                            angular.forEach(data, function (value, key) {
                                scope.assigneeOptions.push(value);
                            });
                        }).then(function () {
                            console.log(scope.assigneeOptions)
                            angular.forEach(scope.assigneeOptions, function (value, key) {
                                value.ticked = value.id === scope.issue.assignee;
                            });
                        }).then(function () {
                            issue.getProjectIssueAssessmentZoneOptionsAction({projectId: scope.projectId}).$promise.then(function(data){
                                if (scope.assessment) {
                                    angular.forEach(data, function (assessment) {
                                        if (assessment.id === scope.assessment.id) {
                                            assessment.ticked = true;
                                            scope.selectAssessment(assessment);
                                        }
                                    });
                                }
                                scope.packages = data;
                            });
                        });
                    }
                });


                let clearWatcher2 = scope.$watch('issueId', function () {
                    if (scope.issueId) {
                        loadIssue();
                    } else {

                        user.query().$promise.then(function (data) {
                            scope.users = [];
                            return data;
                        }).then(function (data) {
                            // Restructure user object for multi-select dropdown
                            angular.forEach(data, function (value, key) {
                                value.person.userId = value.id;
                                scope.users.push(value.person);
                            });
                        }).then(function () {
                            angular.forEach(scope.users, function (value) {
                                value.ticked = false;
                            });
                        });
                    }
                });

                let loadIssue = function () {
                    user.query().$promise.then(function (data) {
                        scope.users = [];
                        return data;
                    }).then(function (data) {
                        // Restructure user object for multi-select dropdown
                        angular.forEach(data, function (value, key) {
                            value.person.userId = value.id;
                            scope.users.push(value.person);
                        });
                    }).then(function () {
                        issue.get({id: scope.issueId}, {groups: 'workScopesStage_allNames,userHistory'}).$promise.then(function (data) {

                            if (data.assessmentZone) {
                                scope.assessmentZoneId = data.assessmentZone.id;
                            }

                            if (data.assessment){
                                scope.assessmentId = data.assessment.id;
                            }
                            scope.issue = (data) ? data : {files: []};


                            const found = scope.issueCats.find(e => e.id === scope.issue.issueType.id);
                            if(found) {found.ticked = true;}

                            scope.projectId = data.project.id;

                            if (data.workScopeStage) {
                                scope.workScopeStage = data.workScopeStage;
                            }
                            if (data.progressGroupZoneProgress) {
                                scope.progressGroupZoneProgress = data.progressGroupZoneProgress;
                            }

                            scope.$emit('issueLoaded', data);
                            scope.stateTransition = (scope.issue.stateResolution === 'Closed') ? 'reopen' : 'close';
                            scope.stateBtnText = (scope.issue.stateResolution === 'Closed') ? 'Reopen this issue' : 'Resolve this Issue';
                            return data;
                        }).then(function (data) {
                            angular.forEach(scope.users, function (value) {
                                if (data.owner && value.userId === data.owner.id) {
                                    value.ticked = true;
                                    scope.issue.owner = data.owner.id;
                                }
                            });

                            if (data.assignee && data.assignee.id) {
                                scope.issue.assignee = data.assignee.id;
                            }

                            issue.getProjectIssueAssessmentZoneOptionsAction({projectId: data.project.id}).$promise.then(function (assessments) {
                                scope.packages = assessments;
                                angular.forEach(assessments, function (assessment) {
                                    if (assessment.id === scope.assessmentId) {
                                        assessment.ticked = true;
                                        scope.zones = assessment.zones;
                                        angular.forEach(assessment.zones, function (zone) {
                                            if (zone.id === scope.assessmentZoneId) {
                                                zone.ticked = true;
                                            }
                                        })
                                    }
                                });
                            });
                        });
                    });

                };

                scope.transitionIssue = function (issueId, transition) {
                    issue[transition]({id: issueId}).$promise.then(function (data) {
                        scope.$emit('issueSaved', data);
                        scope.issueSaved(data);
                    });
                };

                scope.deleteFile = function (i) {
                    scope.issue.files.splice(i, 1);
                };

                scope.assignOwner = function (data) {
                    scope.issue = (scope.issue) ? scope.issue : {files: []};
                    scope.issue.owner = data.userId;
                };

                scope.assignAssignee = function (data) {
                    scope.issue = (scope.issue) ? scope.issue : {files: []};
                    scope.issue.assignee = data.id;
                };

                scope.saveIssue = function (issueForm) {
                    scope.submitted = true;
                    let error = false;
                    if (scope.issue.issueType.id[0]){
                        scope.issue.issueType.id = scope.issue.issueType.id[0].id;
                    }else{
                        error = true;
                    }

                    if (!issueForm.$invalid && !error) {
                        let saveIssue = new issue(scope.issue);
                        saveIssue.project = scope.projectId;
                        saveIssue.issueType = saveIssue.issueType.id;
                        saveIssue.dueDate = saveIssue.dueDate + '23:59:59';

                        if (scope.workScopeStageId) {
                            saveIssue.workScopeStage = scope.workScopeStageId;
                        } else if (saveIssue.workScopeStage) {
                            saveIssue.workScopeStage = saveIssue.workScopeStage.id;
                        }
                        if (scope.progressGroupZoneProgressId) {
                            saveIssue.progressGroupZoneProgress = scope.progressGroupZoneProgressId;
                        } else if (saveIssue.progressGroupZoneProgress) {
                            saveIssue.progressGroupZoneProgress = saveIssue.progressGroupZoneProgress.id;
                        }

                        saveIssue.$save().then(function (data) {
                            scope.submitted = false;
                            issueForm.$setPristine();
                            scope.$emit('issueSaved', data);
                            scope.issueSaved(data);
                        });
                    }
                };

                scope.comment = {};
                scope.leaveComment = function () {
                    scope.submitted = true;

                    scope.comment.issue = scope.issue.id;

                    issue.saveComment({}, scope.comment).$promise.then(function (data) {
                        scope.submitted = false;

                        loadIssue();
                        scope.comment = {};
                        scope.$emit('issueCommentSaved', data);
                    });
                };

                scope.$on('$destroy', function () {
                    clearWatcher1();
                    clearWatcher1b();
                    clearWatcher1c();
                    clearWatcher2();
                });
            }
        };
    });
