(function(){
    'use strict';

    /**
     * Forced Password Reset Controller
     */
    angular.module('scswebappApp')
        .controller('ForcedPasswordResetCtrl', function ($scope,$rootScope,user) {
            $scope.data = {};

            $scope.submit = function (form) {
                $scope.submitted = true;
                if (!form.$invalid) {
                    user.updatePassword({
                        id: $rootScope.userDetails.id,
                        plainPassword: $scope.data.plainPassword
                    }).$promise.then(function (){
                        $scope.data.confirm = true;
                    }, function(){
                        $scope.data.error = true;
                    });
                }
            };

        });
})();
