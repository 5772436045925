'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('navTab', function ($window, $location) {
        return {
            template: '<li ng-class="::{active: isActive}"><a ng-href="{{href}}" ng-transclude></a></li>',
            restrict: 'E',
            scope: {
                href: '@'
            },
            transclude: true,
            link: function postLink(scope) {
                scope.isActive = (scope.href.replace('#', '') == $location.path().replace('#', ''));
            }
        };
    });
