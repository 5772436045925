import {CsUtils} from '../../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MaterialOrderCtrl', function (
            $scope,
            $rootScope,
            $routeParams,
            project,
            materialOrders,
            $route,
            $location,
            $http,
            ENV,
            $window,
            material,
            price,
            FileUploader,
            assessment,
            supplier,
            operative,
            materialDeliveries,
            $filter,
            $locale,
            zone,
            PermPermissionStore
        ) {
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            $scope.isViewPage = $route.current.$$route.originalPath == '/project-hub/material-order/:id';
            $scope.hasCommercialDataPermission = PermPermissionStore.hasPermissionDefinition('canReadMaterialsCommercialData');
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteMaterialsOrders'); // todo: or canWriteAccountsOrders

            $scope.reload = function () {
                console.log('reloading');
                $route.reload();
            };

            var lineItemSort = function (a, b) {
                if (a.material.materialType === b.material.materialType) {
                    if (a.material.brand === b.material.brand) {
                        if (a.material.name === b.material.name) {
                            return (a.material.specifics < b.material.specifics) ? -1 : 1;
                        }
                        return (a.material.name < b.material.name) ? -1 : 1;
                    }
                    return (a.material.brand < b.material.brand) ? -1 : 1;
                }
                return (a.material.materialType < b.material.materialType) ? -1 : 1;
            }

            $scope.addressToString = function (address) {
                var pieces = [];
                angular.forEach(['street1', 'street2', 'street3', 'city', 'county', 'postcode', 'country'], function (f) {
                    if (address[f]) {
                        pieces.push(address[f]);
                    }
                });
                return pieces.join(', ');
            };

            var fetchOrder = function () {
                $scope.materialOrderItemListServiceParams = {order: $routeParams.id, groups: 'orders_view,prices_view'};
                materialOrders.get({id: $routeParams.id}, {groups: 'prices_view'}).$promise.then(function (data) {
                    if (data.invoiceDocument && data.invoiceDocument.s3UrlInline) {
                        data.invoiceDocument.s3UrlInline += '&embedded=true';
                    }
                    return data;
                }).then(function (data) {
                    data.materialOrderItems.sort(lineItemSort);
                    $scope.order = data;
                    $rootScope.projectId = data.project.id;

                    // Get deliveries for orders which may have them
                    $scope.deliveryTableServiceParams = {materialOrder: data.id};
                    $scope.invoiceTableServiceParams = {orderId: data.id};
                });
            };

            if ($route.current.$$route.isConfirmOrder || $route.current.$$route.isEdit) {
                $scope.orderId = $routeParams.id;

                if ($route.current.$$route.isEdit) {
                    $scope.isEditOrder = true;
                    var fetchZoneBalances = function () {
                        $scope.zoneBalances = null;
                        materialOrders.zoneBalances({id: $routeParams.id}).$promise.then(function (data) {
                            $scope.zoneBalances = data;
                        });
                    };
                    fetchZoneBalances();
                } else {
                    // Default date
                    var d = new Date();
                    d.setDate(d.getDate() + 1);
                    $scope.expectedDeliveryDate = d;
                }

                // Load the material order
                materialOrders.get({id: $routeParams.id}, {groups: 'quantityRounding,prices_view'}).$promise.then(function (data) {
                    data.materialOrderItems.sort(lineItemSort);
                    let tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    if (!data.expectedDeliveryDate) {
                        data.expectedDeliveryDate = tomorrow;
                    } else {
                        data.expectedDeliveryDate = new Date(data.expectedDeliveryDate);
                    }
                    data.materialOrderItems.sort(lineItemSort);

                    $scope.order = data;

                    $scope.formDeliveryAddress = {};
                    $scope.setDeliveryAddress = function (address) {
                        $scope.formDeliveryAddress.street1 = address.street1;
                        $scope.formDeliveryAddress.street2 = address.street2;
                        $scope.formDeliveryAddress.city = address.city;
                        $scope.formDeliveryAddress.county = address.county;
                        $scope.formDeliveryAddress.postcode = address.postcode;
                        $scope.formDeliveryAddress.country = address.country;
                    };
                    $scope.setDeliveryAddress(data.deliveryAddress);

                    angular.forEach(data.materialOrderItems, function (moi) {
                        if (!moi.expectedDeliveryDate) {
                            moi.expectedDeliveryDate = tomorrow
                        } else {
                            moi.expectedDeliveryDate = new Date(moi.expectedDeliveryDate);
                        }
                    });
                    $scope.order = data;

                    // Project
                    $rootScope.projectId = data.project.id;
                    $scope.projectId = data.project.id;

                    $scope.siteContactSelected = function(siteContact) {
                        $scope.deliveryOperative = siteContact;
                        $scope.showOperativeTelephoneNumbers(siteContact);
                        $scope.selectSiteContactModal.close();
                    }
                    $scope.selectSiteContact = function() {
                        $scope.selectSiteContactModal.open();
                    }

                    $scope.operativeTelephoneNumbers = $scope.order.project.siteDeliveryOperative ? $scope.order.project.siteDeliveryOperative.telephoneNumbers : null;
                    $scope.siteDeliveryTelephoneNumber = $scope.order.project.siteDeliveryTelephoneNumber;
                    $scope.deliveryOperative = $scope.order.project.siteDeliveryOperative;
                    //
                    // When an operative is picked, display their numbers
                    $scope.showOperativeTelephoneNumbers = function (data) {
                        if (!data.telephoneNumbers) {
                            return;
                        }
                        $scope.operativeTelephoneNumbers = data.telephoneNumbers;
                        if ($scope.operativeTelephoneNumbers.length) {
                            // Automatically default to the first in the list
                            $scope.siteDeliveryTelephoneNumber = $scope.operativeTelephoneNumbers[0];
                        }
                    };

                    materialOrders.getPreviousAddresses({id: $rootScope.projectId}).$promise.then(function (addresses) {
                        $scope.previousAddresses = addresses;
                        if ($route.current.$$route.isConfirmOrder) {
                            if (addresses.length) {
                                // Auto-select first address if on confirmation page
                                $scope.setDeliveryAddress(addresses[0]);
                            } else {
                                // Pre-populate with project address
                                $scope.setDeliveryAddress($scope.order.project.siteDeliveryAddress);
                            }
                        }
                    });

                    // Get the default supplier contact
                    supplier.projectDefaultContact({
                        id: data.supplier.id,
                        projectId: $routeParams.projectId
                    }).$promise.then(function (data) {
                        if (data.id) {
                            $scope.dpscTelephoneNumber = data.telephoneNumber;
                            $scope.dpscContact = data.contact;
                            $scope.dpscContactAddress = data.contactAddress;
                        }
                    });

                    // Get the supplier & the supplier telephone numbers
                    // Get supplier contact
                    supplier.get({id: data.supplier.id}).$promise.then(function (data) {
                        $scope.supplier = data;
                        var tns = data.telephoneNumbers;
                        angular.forEach(data.children, function (child) {
                            angular.forEach(child.telephoneNumbers, function (tn) {
                                tn.detail = child.fullName + ' (' + tn.detail + ')';
                                tns.push(tn);
                            });

                            angular.forEach(child.people, function (people) {
                                angular.forEach(people.telephoneNumbers, function (tn) {
                                    tn.detail = people.fullName + ' (' + tn.detail + ')';
                                    tns.push(tn);
                                });
                            });
                        });
                        $scope.supplierTelephoneNumbers = tns;

                        var addresses = [];
                        angular.forEach(data.addresses, function (address) {
                            address.fullAddress = $scope.addressToString(address);
                            if (!$scope.dpscContactAddress) {
                                $scope.dpscContactAddress = address;
                            }
                            addresses.push(address);
                        });

                        angular.forEach(data.organisations, function (child) {
                            angular.forEach(child.addresses, function (address) {
                                address.fullAddress = child.fullName + ' (' + $scope.addressToString(address) + ')';
                                addresses.push(address);
                            });
                        });
                        $scope.supplierAddresses = addresses;

                        var contact = [];
                        angular.forEach(data.children, function (child) {
                            angular.forEach(child.people, function (fn) {
                                fn.detail = child.fullName + ' (' + fn.fullName + ')';
                                contact.push(fn);
                            });
                        });

                        angular.forEach(data.people, function (fn) {
                            fn.detail = fn.fullName;
                            contact.push(fn);
                        });
                        $scope.supplierContacts = contact;
                    });

                    // Confirm the order
                    $scope.confirmOrder = function (lineItems, deliveryDateMode, expectedDeliveryDate, deliveryAddress, deliveryOperative, deliveryTelephoneNumber, dpscContact, dpscTelephoneNumber, dpscContactAddress, splitPacks) {
                        console.log(deliveryDateMode, expectedDeliveryDate);
                        var requestData = {
                            id: $routeParams.id,
                            deliveryAddress: {
                                street1: deliveryAddress.street1,
                                street2: deliveryAddress.street2,
                                city: deliveryAddress.city,
                                county: deliveryAddress.county,
                                postcode: deliveryAddress.postcode,
                                country: deliveryAddress.country,
                            },
                            siteDeliveryOperative: deliveryOperative ? deliveryOperative.id : null,
                            siteDeliveryTelephoneNumber: deliveryTelephoneNumber ? deliveryTelephoneNumber.id : null,
                            defaultProjectSupplierContact: dpscContact ? dpscContact.id : null,
                            defaultProjectSupplierTelephoneNumber: dpscTelephoneNumber ? dpscTelephoneNumber.id : null,
                            defaultProjectSupplierContactAddress: dpscContactAddress ? dpscContactAddress.id : null,
                            documents: $scope.order.documents,
                            deliveryDateMode: deliveryDateMode
                        };
                        if ('single' === deliveryDateMode) {
                            requestData['expectedDeliveryDate'] = $filter('date')(expectedDeliveryDate, 'yyyy-MM-dd HH:mm:ss');
                        } else {
                            requestData.expectedDeliveryDates = {};
                            angular.forEach(lineItems, function (moi) {
                                requestData.expectedDeliveryDates[moi.id] = $filter('date')(moi.expectedDeliveryDate, 'yyyy-MM-dd HH:mm:ss');
                            });
                        }
                        if (!$route.current.$$route.isEdit) {
                            // Update order properties and send split pack data
                            requestData.splitPacks = splitPacks;
                            materialOrders.confirmOrder(requestData,
                                function (data) {
                                    $location.path('/project-hub/material-order/' + data.id);
                                }
                            );
                        } else {
                            // Don't sent split pack data
                            materialOrders.patchOrder(requestData,
                                function (data) {
                                    $location.path('/project-hub/material-order/' + data.id);
                                }
                            );
                        }
                    };
                });

            } else if ($route.current.$$route.isLogDelivery) {
                $scope.orderId = $routeParams.id;

                // Log new delivery
                materialOrders.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.order = data;
                    $scope.project = data.project;
                    $rootScope.projectId = data.project.id;

                    // Set up form
                    var form = {deliveredQuantities: {}};
                    angular.forEach(data.materialOrderItems, function (moi) {
                        form.deliveredQuantities[moi.id] = {
                            deliveredQuantity: 0,
                            deliveredItems: 0,
                            deliveredPacks: 0,
                            invoicedPrice: moi.orderPrice
                        };
                    });

                    // Default delivery date
                    form.dateDelivered = new Date();

                    $scope.form = form;
                    var precision = 1 / 0.0001;
                    // Update all inputs to match required pack numbers
                    $scope.fulfillQuantities = function () {
                        angular.forEach(data.materialOrderItems, function (lineItem, i) {
                            $scope.form.deliveredQuantities[lineItem.id].deliveredQuantity = Math.round((lineItem.orderQuantity - lineItem.quantityDelivered) * precision) / precision;
                            $scope.deliverByQty(lineItem);
                        });
                    };
                    $scope.deliverByQty = function (lineItem) {
                        // Round to 2dp
                        var sSize = lineItem.singularSize || 1;
                        var pSize = lineItem.packSize || 1;
                        var qty = $scope.form.deliveredQuantities[lineItem.id].deliveredQuantity;
                        var items = Math.round((qty / sSize) * precision) / precision;
                        var packs = Math.round((items / pSize) * precision) / precision;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredItems = items;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredPacks = packs;
                    };
                    $scope.deliverByItems = function (lineItem) {
                        var sSize = lineItem.singularSize || 1;
                        var pSize = lineItem.packSize || 1;
                        var items = $scope.form.deliveredQuantities[lineItem.id].deliveredItems;
                        var packs = Math.round((items / pSize) * precision) / precision;
                        var qty = Math.round((items * sSize) * precision) / precision;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredQuantity = qty;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredPacks = packs;
                    };
                    $scope.deliverByPacks = function (lineItem) {
                        var sSize = lineItem.singularSize || 1;
                        var pSize = lineItem.packSize || 1;
                        var packs = $scope.form.deliveredQuantities[lineItem.id].deliveredPacks;
                        var items = Math.round((packs * pSize) * precision) / precision;
                        var qty = Math.round((items * sSize) * precision) / precision;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredQuantity = qty;
                        $scope.form.deliveredQuantities[lineItem.id].deliveredItems = items;
                    };

                    // Attachment uploader
                    $scope.attachmentUploader = new FileUploader();
                    $scope.attachmentsList = [];
                    $scope.attachments = {
                        uploader: $scope.attachmentUploader,
                        list: $scope.attachmentsList,
                        type: 'attachments',
                        uploadurl: '/files'
                    };

                    // Submit new delivery
                    $scope.logDelivery = function () {
                        $scope.form.id = $routeParams.id;
                        $scope.form.attachments = $scope.attachmentsList;
                        materialOrders
                            .logDelivery($scope.form, function (data) {
                                if ($routeParams.viaList) {
                                    $location.path('/project-hub/material-deliveries/' + $rootScope.projectId);
                                } else {
                                    $location.path('/project-hub/material-order/' + data.id);
                                }
                            });
                    };
                });

            } else if (!$route.current.$$route.isSingle && !$route.current.$$route.isEdit) {
                // Material sections
                $rootScope.projectId = $routeParams.id;

                project.get({id: $routeParams.id}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });

            } else {
                // Single view page
                $scope.orderId = $routeParams.id;
                fetchOrder();

                $scope.deleteOrder = function () {
                    materialOrders.delete({id: $scope.order.id}).$promise.then(function () {
                        $scope.confirmModal.close();
                        $location.path('/project-hub/open-material-orders/' + $scope.order.project.id);
                    });
                };
            }

            if ($route.current.$$route.isList) {
                $scope.orderTableServiceParams = {
                    'orderCategory': 'material',
                    'project': $rootScope.projectId,
                    'stateStatus[]': $route.current.$$route.orderStatuses
                };
                $scope.orderTableStates = $route.current.$$route.orderStatuses;
                $scope.pageTitle = $route.current.$$route.pageTitle;
            }

            $scope.send = function () {
                materialOrders.sendOrder({id: $routeParams.id}, {groups: 'prices_view'}).$promise.then(function (data) {
                    $scope.order = data;
                })
            };

            $scope.cancel = function () {
                materialOrders.cancelOrder({id: $routeParams.id}, {groups: 'prices_view'}).$promise.then(function (data) {
                    $scope.order = data;
                })
            };

            $scope.uncancel = function () {
                materialOrders.uncancelOrder({id: $routeParams.id}, {groups: 'prices_view'}).$promise.then(function (data) {
                    $scope.order = data;
                })
            };

            $scope.complete = function (reallyComplete) {
                if (reallyComplete || $scope.order.invoicedValue === $scope.order.approvedValue) {
                    materialOrders.completeOrder({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.order = data;
                        $scope.completeModal.close();
                    });
                } else {
                    $scope.completeModal.open();
                }
            };

            $scope.quoteExpired = function (from, to) {
                var now = new Date();
                from = new Date(from);
                to = new Date(to);
                return (from > now || to <= now);
            };

            $scope.$on('confirmOrder', function () {
                $scope.$broadcast('closeModal');
                downloadOrder();
            });

            $scope.downloadOrder = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/materialorders/' + $routeParams.id + '/export'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);

                    $scope.$broadcast('closeModal');
                });
            };
            $scope.downloadForm = {};
            $scope.saveNotesAndDownloadOrder = function () {
                if ($scope.downloadForm.orderNotes !== $scope.order.orderNotes) {
                    // Update first
                    materialOrders.patchOrder({id: $routeParams.id}, {orderNotes: $scope.downloadForm.orderNotes}).$promise.then(function (data) {
                        $scope.downloadModal.close();
                        $scope.order.orderNotes = $scope.downloadForm.orderNotes;
                        $scope.downloadOrder();
                    });
                } else {
                    // No change
                    $scope.downloadModal.close();
                    $scope.downloadOrder();
                }
            };

            // Editing material orders
            $scope.selectMaterialForm = {};
            $scope.changeMaterialOrderItemPrice = function (materialOrderItem, oldPrice, newPrice) {
                materialOrders.patchOrderItem({id: materialOrderItem.id}, {selectedPrice: newPrice.id});
            };
            $scope.swapMaterialForm = {};

            $scope.materialTableParams = null;
            $scope.setMaterialQuery = function () {
                $scope.materialTableParams = null;
                console.log($scope.materialTableParams);
                var materialTableParams = {};
                if (!$scope.chooseMaterialModal.getContext().isContingency) {
                    materialTableParams = {assessment: $scope.selectMaterialForm.assessment[0].id};
                }
                $scope.materialTableParams = materialTableParams;
            };
            $scope.chooseSwapMaterial = function (materialOrderItem) {
                $scope.swapMaterialOldMaterial = materialOrderItem.material;
                $scope.materialSelected = function(item) {
                    $scope.swapMaterialForm.configuration = null;
                    $scope.chooseMaterialModal.close();
                    $scope.swapMaterialNewMaterial = item;
                    $scope.swapMaterialModal.open({materialOrderItem: materialOrderItem});
                };
                $scope.assessments = null;
                project.getLiveAssessments({id: $scope.order.project.id}).$promise.then(function (data) {
                    $scope.assessments = data;
                });

                $scope.chooseMaterialModal.open({contingency: false});
            };
            $scope.swapMaterial = function () {
                var request = {material: $scope.swapMaterialNewMaterial.id, selectedPrice: null};
                if ($scope.swapMaterialForm.selectedConfiguration) {
                    request.selectedConfiguration = $scope.swapMaterialForm.selectedConfiguration;
                }
                materialOrders.patchOrderItem({id: $scope.swapMaterialModal.getContext().materialOrderItem.id}, request).$promise.then(function (data) {
                    fetchOrder();
                    $scope.swapMaterialModal.close();
                });
            };
            $scope.addMaterialForm = {};
            $scope.addActiveMaterial = function (context) {
                $scope.selectMaterialForm.assessment = [];
                $scope.materialSelected = function (item) {
                    $scope.addMaterialForm.selectedPrice = null;
                    $scope.fetchMaterialBillPrice(item.id, $scope.selectMaterialForm.assessment[0].id, $scope.order.project.id);

                    $scope.addMaterialForm.selectedConfiguration = null;
                    $scope.chooseMaterialModal.close();
                    $scope.addMaterialNewMaterial = item;
                    $scope.addMaterialModal.open();
                };
                $scope.assessments = null;
                project.getLiveAssessments({id: $scope.order.project.id}).$promise.then(function (data) {
                    $scope.assessments = data;
                });

                $scope.chooseMaterialModal.open(context);
            };
            $scope.fetchMaterialBillPrice = function (materialId, assessmentId, projectId) {
                $scope.fetchingMaterialBillPrice = true;
                material.getMaterialBill({
                    projectId: projectId,
                    assessment: assessmentId,
                    'xs[]': materialId
                }).$promise.then(function (data) {
                    $scope.fetchingMaterialBillPrice = false;
                    if (data.rows.length) {
                        $scope.addMaterialForm.selectedPrice = data.rows[0].selectedPrice;
                    }
                });
            };
            $scope.addMaterialToOrder = function () {
                materialOrders.addOrderItem({
                    order: $scope.order.id,
                    orderQuantity: 0,
                    material: $scope.addMaterialNewMaterial.id,
                    selectedConfiguration: $scope.addMaterialForm.selectedConfiguration,
                    selectedPrice: $scope.addMaterialForm.selectedPrice ? $scope.addMaterialForm.selectedPrice.id : null,
                    assessment: $scope.selectMaterialForm.assessment[0] ? $scope.selectMaterialForm.assessment[0].id : null
                }).$promise.then(function () {
                    fetchOrder();
                });
                $scope.addMaterialModal.close();
            };
            $scope.removeMaterialFromOrder = function (context) {
                $scope.confirmationQuestion = 'Are you sure you want to remove ' + context.material.name + ' from this order?';
                $scope.confirmationConfirm = function () {
                    materialOrders.removeMaterial({id: context.id}).$promise.then(function () {
                        $scope.confirmModal.close();
                        fetchOrder();
                    });
                };
                $scope.confirmModal.open();
            };
            $scope.editZoneQuantity = function (moi, moizq, moiIndex, moizqIndex, oldValue) {
                var data = {
                    'quantity': moizq.quantity
                };
                materialOrders.patchZoneQuantity({id: moizq.id}, data).$promise.then(function (data) {
                    $scope.order.materialOrderItems[moiIndex].orderQuantity = data.materialOrderItem.orderQuantity;
                    fetchZoneBalances();
                });
            };
            $scope.removeZoneQuantity = function (moi, moizq, moiIndex, moizqIndex) {
                $scope.confirmationQuestion = 'Remove ordered quantity from ' + moizq.assessmentZone.name + '?';
                $scope.confirmationConfirm = function () {
                    materialOrders.removeZoneQuantity({id: moizq.id}).$promise.then(function (data) {
                        $scope.order.materialOrderItems[moiIndex].zoneQuantities.splice(moizqIndex, 1);
                        if ($scope.order.materialOrderItems[moiIndex].zoneQuantities < 1) {
                            materialOrders.removeMaterial({id: moi.id}).$promise.then(function () {
                                $scope.order.materialOrderItems.splice(moiIndex, 1);
                            });
                        } else {
                            $scope.order.materialOrderItems[moiIndex].orderQuantity = data.materialOrderItem.orderQuantity;
                        }
                    });
                    $scope.confirmModal.close();
                };
                $scope.confirmModal.open();
            };
            $scope.newZoneQuantity = {};
            $scope.fetchOrderableZones = function () {
                $scope.zones = null;
                $scope.newZoneQuantity.assessmentZone = null;
                zone.getOrderableZones({
                    id: $scope.newZoneQuantity.assessment[0].id,
                    groups: 'subZones'
                }).$promise.then(function (data) {
                    $scope.zones = data;
                });
            };
            $scope.showNewZoneQuantityModal = function (moi) {
                var preventEdit = false;
                if ($scope.order.deliveredValue >0 ) {
                    angular.forEach($scope.order.materialOrderItems, function(orderItem){
                        angular.forEach(orderItem.zoneQuantities, function(zoneQuantity){
                            if (zoneQuantity.assessmentZone.assessment.mosMode === 'd') {
                                preventEdit = true;
                            }
                        });
                    });
                }

                project.getLiveAssessments({id: $scope.order.project.id}).$promise.then(function (data) {
                    $scope.assessments = data;
                });
                $scope.newZoneQuantity.assessment = [];
                $scope.newZoneQuantity.assessmentZone = [];
                $scope.newZoneQuantity.quantity = null;
                $scope.assessments = null;
                $scope.zones = null;

                $scope.newZoneQuantityModal.open({materialOrderItem: moi});
            };
            $scope.createZoneQuantity = function () {
                var options = {
                    materialOrderItem: $scope.newZoneQuantityModal.getContext().materialOrderItem.id,
                    assessmentZone: $scope.newZoneQuantity.assessmentZone[0].id,
                    quantity: $scope.newZoneQuantity.quantity
                };
                materialOrders.addZoneQuantity(options).$promise.then(function () {
                    fetchOrder();
                    fetchZoneBalances();
                    $scope.newZoneQuantityModal.close();
                });
            };
        });
})();
