'use strict';

/**
 * @ngdoc directive
 * @name or-ng.formchanged
 * @description
 * # orformchanged
 * Usage:  <progress-bar progress="50"></progress-bar>
 * Gradients generated at http://www.strangeplanet.fr/work/gradient-generator/index.php
 */

angular.module('scswebappApp')
    .directive('progressBar', function (){
        return {
            restrict: 'E',
	        scope: {
                progress: '@'
            },
            template: '<div class="progress-bar"><div style="width:{{progress}}%;background:{{colour}}"></div><div>{{progress}}&#37;</div></div>',
            link: function postLink(scope, element, attrs) {
                scope.progress = attrs.progress;
                scope.colours = {
                    0: {
                        'value': '#37A6DE'
                    },
                    10: {
                        'value': '#3BA9CF'
                    },
                    20: {
                        'value': '#3FADC0'
                    },
                    30: {
                        'value': '#44B0B1'
                    },
                    40: {
                        'value': '#48B4A2'
                    },
                    50: {
                        'value': '#4DB794'
                    },
                    60: {
                        'value': '#51BB85'
                    },
                    70: {
                        'value': '#55BE76'
                    },
                    80: {
                        'value': '#5AC267'
                    },
                    90: {
                        'value': '#5EC558'
                    },
                    100: {
                        'value': '#63C94A'
                    }
                };
                var closest = Math.round(scope.progress / 10) * 10;
                scope.colour = ( closest <= 100 ) ? scope.colours[closest].value : 100;
            }
        };
    });

