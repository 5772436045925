import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import "./polyfills.ts";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import "./scripts/app.js";
import { setUpLocationSync } from '@angular/router/upgrade';
import {enableProdMode} from "@angular/core";
import {ZoneDateUtils} from "./modules/bill/modals/zone/zone-date-utils";

if (window.location.href.includes('chalkstring.com')) {
    enableProdMode();
}

setAngularJSGlobal(angular);
ZoneDateUtils.updatePublicHolidays();

platformBrowserDynamic()
    .bootstrapModule(AppModule, [
        {
            defaultEncapsulation: 0 //ViewEncapsulation.Emulated this errors on build
        }
    ])
    .then(ref => {
        const upgrade = (<any>ref.instance).upgrade;
        setUpLocationSync(upgrade);
        return ref.injector;
    })
    .catch(reason => {
        console.log(reason);
    });
