'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:commercialSummary
 * @description
 * # commercialSummary
 */
angular.module('scswebappApp')
    .directive('commercialSummary', function () {
        return {
            template: require('./commercial-summary.html'),
            restrict: 'E',
            scope: {
                data: '='
            },
            link: function postLink(scope) {
                scope.lmode = 0;
                scope.rmode = 0;
                scope.lmodes = [
                    'Projected costs',
                    'Projected revenue'
                ];
                scope.rmodes = [
                    'Projected vs current margins',
                    'Applied vs certified revenues',
                    'Actual & accrued costs'
                ];
                scope.setLmode = function (n) {
                    scope.lmode = n;
                };
                scope.setRmode = function (n) {
                    scope.rmode = n;
                };
            }
        };
    });
