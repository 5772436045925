'use strict';

import '../styles/main.scss';
import * as angular from 'angular';
import 'angular-route';
import {setupHubRoutes} from "./routes/hub-routes";

const appName = 'scswebappApp';
export {appName};

var $ = require('jquery');
require('jquery-ui');
require('angular-animate');
require('angular-cookies');
require('angular-file-upload');
require('angular-multi-select/angular-multi-select.js');
require('angularjs-datepicker');
require('angular-resource');
require('angular-route');
require('angular-sanitize');
require('angular-touch');
require('angular-ui-sortable');
require('angularjs-slider');
require('angular-translate');
require('tinycolor2');
require('angularjs-color-picker');
require('angular-tooltips');
require('angular-permission');
require('popper.js');
require('zxcvbn');

// CSS
require('../../node_modules/angular-multi-select/angular-multi-select.css');
require('../../node_modules/angularjs-datepicker/src/css/angular-datepicker.css');
require('../../node_modules/angularjs-slider/dist/rzslider.css');
require('../../node_modules/angularjs-color-picker/dist/angularjs-color-picker.css');
require('../../node_modules/angular-tooltips/dist/angular-tooltips.min.css');
require('../styles/fontello.css');

// Images
require('../favicon-16x16.png');
require('../favicon-32x32.png');


// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
//
// Sentry.init({
//     dsn: __RAVEN_ENDPOINT__,
//     environment: __ENVIRONMENT_NAME__,
//     integrations: [
//         new Integrations.Angular(),
//     ],
// });

/**
 * @ngdoc overview
 * @name scswebappApp
 * @description
 * # scswebappApp
 *
 * Main module of the application.
 */
angular
    .module(appName, [
        'config',
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ngRoute',
        'ngSanitize',
        'ngTouch',
        // 'ngSentry',
        'coreNgUser',
        'coreNgNotifications',
        'orNgFormChanged',
        'orNgLoader',
        'orNgPermissions',
        'angularFileUpload',
        'my.resource',
        'units',
        'rzModule',
        'isteven-multi-select',
        'pascalprecht.translate',
        'color.picker',
        '720kb.datepicker',
        '720kb.tooltips',
        'permission',
        'permission.ng'
    ])
    .config(function ($routeProvider, $locationProvider, $compileProvider, $translateProvider, $permissionProvider, $provide) {

        setupMainRoutes($routeProvider);
        setupHubRoutes($routeProvider);
        setupAccountsRoutes($routeProvider);
        $routeProvider.otherwise({template: ''});

        // $routeProvider
        //    .otherwise({
        //        redirectTo: '/access-denied'
        //    });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $compileProvider.debugInfoEnabled(true);

        $translateProvider.translations('en-GB', {
            LOGGED_IN_AS: 'Logged in as',
            DASHBOARD_TITLE: 'Dashboard',
            DASHBOARD_SUBTITLE: 'Latest jobs and schedule',
            PROJECT_NAME: 'Project Name',
            PACKAGE: 'Package',
            JOB_DESCRIPTION: 'Job Description',
            DEADLINE: 'Deadline',
            SCHEDULED_DATES: 'Scheduled Dates',
            MY_SCHEDULE: 'My Schedule',
            VAT: 'VAT'
            })
            .translations('fr', {
                LOGGED_IN_AS: 'Connecté en tant que',
                DASHBOARD: 'Tableau de Bord',
                DASHBOARD_SUBTITLE: 'Dernières offres d\'emploi et calendrier',
                PROJECT_NAME: 'Mom du Projet',
                PACKAGE: 'Paquet',
                JOB_DESCRIPTION: 'Description de L\'emploi',
                DEADLINE: 'Date Limite',
                SCHEDULED_DATES: 'Dates Planifiées',
                MY_SCHEDULE: 'Mon Emploi du Temps',
                VAT: 'VAT'
            })
            .translations('en-MY', {
                LOGGED_IN_AS: 'Logged in as',
                DASHBOARD_TITLE: 'Dashboard',
                DASHBOARD_SUBTITLE: 'Latest jobs and schedule',
                PROJECT_NAME: 'Project Name',
                PACKAGE: 'Package',
                JOB_DESCRIPTION: 'Job Description',
                DEADLINE: 'Deadline',
                SCHEDULED_DATES: 'Scheduled Dates',
                MY_SCHEDULE: 'My Schedule',
                VAT: 'SST'
            })
            .translations('en-SG', {
                LOGGED_IN_AS: 'Logged in as',
                DASHBOARD_TITLE: 'Dashboard',
                DASHBOARD_SUBTITLE: 'Latest jobs and schedule',
                PROJECT_NAME: 'Project Name',
                PACKAGE: 'Package',
                JOB_DESCRIPTION: 'Job Description',
                DEADLINE: 'Deadline',
                SCHEDULED_DATES: 'Scheduled Dates',
                MY_SCHEDULE: 'My Schedule',
                VAT: 'GST'
            });
        $translateProvider.preferredLanguage('en-GB');
        var userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails != null) {
            var defaultCurrency = userDetails.account.defaultCurrency;
            if (defaultCurrency == "MYR") {
                $translateProvider.preferredLanguage('en-MY');
            } else if (defaultCurrency == "SGD") {
                $translateProvider.preferredLanguage('en-SG');
            }
        }
        $translateProvider.useSanitizeValueStrategy('sanitize');

        /**
         * A method for summing all values of the specified property in an array
         * Like summing a column in a table.
         *
         * From here: https://stackoverflow.com/a/23247980/202527
         *
         * @param prop the property to sum
         * @returns {number}
         */
        Array.prototype.sum = function (prop) {
            var total = 0;
            for (var i = 0, _len = this.length; i < _len; i++) {
                total += this[i][prop];
            }
            return total;
        };

        /**
         * A method for getting all values with a specified key from an array
         *
         * From here: https://stackoverflow.com/questions/25726066/equivalent-of-underscore-pluck-in-pure-javascript (but fixed
         *
         * @param prop the property to sum
         * @returns {number}
         */
        Array.prototype.pluck = function (prop, newProp) {
            var newArr = [];
            if (typeof (newProp) === 'undefined') {
                newProp = prop;
            }
            for (var i = 0, x = this.length; i < x; i++) {
                if (this[i].hasOwnProperty(prop)) {
                    if (newProp === false) {
                        newArr.push(this[i][prop])
                    } else {
                        var obj = {};
                        obj[newProp] = this[i][prop];
                        newArr.push(obj)
                    }
                }
            }
            return newArr;
        };

        Array.prototype.findOne = function (prop, value) {
            for (var i = 0, x = this.length; i < x; i++) {
                if (this[i].hasOwnProperty(prop)) {
                    if (this[i][prop] === value) {
                        return this[i];
                    }
                }
            }
            return false;
        };

        Array.prototype.where = function (prop, value) {
            var results = [];
            for (var i = 0, x = this.length; i < x; i++) {
                if (this[i].hasOwnProperty(prop)) {
                    if (this[i][prop] === value) {
                        results.push(this[i]);
                    }
                }
            }
            return results;
        };

        Array.prototype.deleteOne = function (prop, value) {
            for (var i = 0, x = this.length; i < x; i++) {
                if (this[i].hasOwnProperty(prop)) {
                    if (this[i][prop] === value) {
                        delete this[i];
                        return this;
                    }
                }
            }
            return false;
        };

        $permissionProvider.suppressUndefinedPermissionWarning(true);
        $permissionProvider.setDefaultOnAuthorizedMethod('authorizeElement');
        $permissionProvider.setDefaultOnUnauthorizedMethod('unauthorizeElement');
    })
    .run(['$templateCache', function($templateCache) {
        $templateCache.put('scripts/directives/application-bill/application-bill.html', require('./directives/application-bill/application-bill.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-cell-labour.html', require('./directives/application-bill/partials/bill-cell-labour.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-cell-review-labour.html', require('./directives/application-bill/partials/bill-cell-review-labour.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-columnhead-labour.html', require('./directives/application-bill/partials/bill-columnhead-labour.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-filters-labour.html', require('./directives/application-bill/partials/bill-filters-labour.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-rowhead-labour.html', require('./directives/application-bill/partials/bill-rowhead-labour.html'));
        $templateCache.put('scripts/directives/application-bill/partials/bill-summary-labour.html', require('./directives/application-bill/partials/bill-summary-labour.html'));
        $templateCache.put('scripts/directives/application-review/application-review.html', require('./directives/application-review/application-review.html'));
        $templateCache.put('scripts/directives/apzq-field/apzq-field.html', require('./directives/apzq-field/apzq-field.html'));
        $templateCache.put('scripts/directives/archive/archive.html', require('./directives/archive/archive.html'));
        $templateCache.put('scripts/directives/commercial-summary/commercial-summary.html', require('./directives/commercial-summary/commercial-summary.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-accrued-total.html', require('./directives/commercial-summary/partials/tooltip-costs-accrued-total.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-accrued.html', require('./directives/commercial-summary/partials/tooltip-costs-accrued.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-actual-total.html', require('./directives/commercial-summary/partials/tooltip-costs-actual-total.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-actual.html', require('./directives/commercial-summary/partials/tooltip-costs-actual.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-actualPlusAccrued.html', require('./directives/commercial-summary/partials/tooltip-costs-actualPlusAccrued.html'));
        $templateCache.put('scripts/directives/commercial-summary/partials/tooltip-costs-actualPlusAccrued-total.html', require('./directives/commercial-summary/partials/tooltip-costs-actualPlusAccrued-total.html'));
        $templateCache.put('scripts/directives/contacts-and-offices/contacts-offices.html', require('./directives/contacts-and-offices/contacts-offices.html'));
        $templateCache.put('scripts/directives/delete/delete.html', require('./directives/delete/delete.html'));
        $templateCache.put('scripts/directives/dropdowns/combined-suppliers-dropdown/combined-suppliers-dropdown.html', require('./directives/dropdowns/combined-suppliers-dropdown/combined-suppliers-dropdown.html'));
        $templateCache.put('scripts/directives/dropdowns/nominal-code-dropdown/nominal-code-dropdown.html', require('./directives/dropdowns/nominal-code-dropdown/nominal-code-dropdown.html'));
        $templateCache.put('scripts/directives/dropdowns/tax-code-dropdown/tax-code-dropdown.html', require('./directives/dropdowns/tax-code-dropdown/tax-code-dropdown.html'));
        $templateCache.put('scripts/directives/edit-rate-build-up-product/edit-rate-build-up-product-directive.html', require('./directives/edit-rate-build-up-product/edit-rate-build-up-product-directive.html'));
        $templateCache.put('scripts/directives/rate-build-up-product-header/rate-build-up-product-header-directive.html', require('./directives/rate-build-up-product-header/rate-build-up-product-header-directive.html'));
        $templateCache.put('scripts/directives/file-folders/file-folders.html', require('./directives/file-folders/file-folders.html'));
        $templateCache.put('scripts/directives/filestable/filestable-directive.html', require('./directives/filestable/filestable-directive.html'));
        $templateCache.put('scripts/directives/filestable/partials/file.html', require('./directives/filestable/partials/file.html'));
        $templateCache.put('scripts/directives/filestable/partials/file.html', require('./directives/filestable/partials/file.html'));
        $templateCache.put('scripts/directives/grid-bill/grid-bill.html', require('./directives/grid-bill/grid-bill.html'));
        $templateCache.put('scripts/directives/grid-bill/pagination.html', require('./directives/grid-bill/pagination.html'));
        $templateCache.put('scripts/directives/grid-bill/cells/fixedCost-fixedCostIncoming.html', require('./directives/grid-bill/cells/fixedCost-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/cells/fixedCost-fixedCostOutgoing.html', require('./directives/grid-bill/cells/fixedCost-fixedCostOutgoing.html'));
        $templateCache.put('scripts/directives/grid-bill/cells/labourApplication-activity.html', require('./directives/grid-bill/cells/labourApplication-activity.html'));
        $templateCache.put('scripts/directives/grid-bill/cells/productApplication-fixedCostIncoming.html', require('./directives/grid-bill/cells/productApplication-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/cells/productApplication-productIncoming.html', require('./directives/grid-bill/cells/productApplication-productIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/column-headers/fixedCost.html', require('./directives/grid-bill/column-headers/fixedCost.html'));
        $templateCache.put('scripts/directives/grid-bill/column-headers/labourApplication.html', require('./directives/grid-bill/column-headers/labourApplication.html'));
        $templateCache.put('scripts/directives/grid-bill/column-headers/productApplication.html', require('./directives/grid-bill/column-headers/productApplication.html'));
        $templateCache.put('scripts/directives/grid-bill/filters/fixedCost-fixedCostOutgoing.html', require('./directives/grid-bill/filters/fixedCost-fixedCostOutgoing.html'));
        $templateCache.put('scripts/directives/grid-bill/filters/fixedCost-fixedCostIncoming.html', require('./directives/grid-bill/filters/fixedCost-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/filters/labourApplication-activity.html', require('./directives/grid-bill/filters/labourApplication-activity.html'));
        $templateCache.put('scripts/directives/grid-bill/filters/productApplication-fixedCostIncoming.html', require('./directives/grid-bill/filters/productApplication-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/filters/productApplication-productIncoming.html', require('./directives/grid-bill/filters/productApplication-productIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/activity.html', require('./directives/grid-bill/row-headers/activity.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/fixedCostIncoming.html', require('./directives/grid-bill/row-headers/fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/fixedCostOutgoing.html', require('./directives/grid-bill/row-headers/fixedCostOutgoing.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/product.html', require('./directives/grid-bill/row-headers/product.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/productIncoming.html', require('./directives/grid-bill/row-headers/productIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/row-headers/productOutgoing.html', require('./directives/grid-bill/row-headers/productOutgoing.html'));
        $templateCache.put('scripts/directives/grid-bill/summaries/fixedCost-fixedCostIncoming.html', require('./directives/grid-bill/summaries/fixedCost-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/summaries/fixedCost-fixedCostOutgoing.html', require('./directives/grid-bill/summaries/fixedCost-fixedCostOutgoing.html'));
        $templateCache.put('scripts/directives/grid-bill/summaries/labourApplication-activity.html', require('./directives/grid-bill/summaries/labourApplication-activity.html'));
        $templateCache.put('scripts/directives/grid-bill/summaries/productApplication-productIncoming.html', require('./directives/grid-bill/summaries/productApplication-productIncoming.html'));
        $templateCache.put('scripts/directives/grid-bill/summaries/productApplication-fixedCostIncoming.html', require('./directives/grid-bill/summaries/productApplication-fixedCostIncoming.html'));
        $templateCache.put('scripts/directives/hub-charts/hub-charts.html', require('./directives/hub-charts/hub-charts.html'));
        $templateCache.put('scripts/directives/hub-charts/partials/tooltip-freshness.html', require('./directives/hub-charts/partials/tooltip-freshness.html'));
        $templateCache.put('scripts/directives/lists/_partials/table-cell-row-delete.html', require('./directives/lists/_partials/table-cell-row-delete.html'));
        $templateCache.put('scripts/directives/lists/contractor-list/contractor-list.html', require('./directives/lists/contractor-list/contractor-list.html'));
        $templateCache.put('scripts/directives/lists/operative-list/operative-list.html', require('./directives/lists/operative-list/operative-list.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-application-revenues.html', require('./directives/lists/project-work-package-list/_partials/table-cell-vat-application-revenues.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-supply-and-fit-costs.html', require('./directives/lists/project-work-package-list/_partials/table-cell-vat-supply-and-fit-costs.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-material-costs.html', require('./directives/lists/project-work-package-list/_partials/table-cell-vat-material-costs.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-fixed-costs.html', require('./directives/lists/project-work-package-list/_partials/table-cell-vat-fixed-costs.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-labour-costs.html', require('./directives/lists/project-work-package-list/_partials/table-cell-vat-labour-costs.html'));
        $templateCache.put('scripts/directives/lists/project-work-package-list/project-work-package-list.html', require('./directives/lists/project-work-package-list/project-work-package-list.html'));
        $templateCache.put('scripts/directives/modal/modal-directive.html', require('./directives/modal/modal-directive.html'));
        $templateCache.put('scripts/directives/modals/contractor-list-modal/contractor-list-modal.html', require('./directives/modals/contractor-list-modal/contractor-list-modal.html'));
        $templateCache.put('scripts/directives/modals/error-list-modal/error-list-modal.html', require('./directives/modals/error-list-modal/error-list-modal.html'));
        $templateCache.put('scripts/directives/modals/operative-list-modal/operative-list-modal.html', require('./directives/modals/operative-list-modal/operative-list-modal.html'));
        $templateCache.put('scripts/directives/modals/user-list-modal/user-list-modal.html', require('./directives/modals/user-list-modal/user-list-modal.html'));
        $templateCache.put('scripts/directives/new-fixed-cost/new-fixed-cost.html', require('./directives/new-fixed-cost/new-fixed-cost.html'));
        $templateCache.put('scripts/directives/new-issue/new-issue.html', require('./directives/new-issue/new-issue.html'));
        $templateCache.put('scripts/directives/new-labouractivity/new-labouractivity.html', require('./directives/new-labouractivity/new-labouractivity.html'));
        $templateCache.put('scripts/directives/new-labourrate/new-labourrate-directive.html', require('./directives/new-labourrate/new-labourrate-directive.html'));
        $templateCache.put('scripts/directives/new-material/new-material-directive.html', require('./directives/new-material/new-material-directive.html'));
        $templateCache.put('scripts/directives/new-operative/new-operative.html', require('./directives/new-operative/new-operative.html'));
        $templateCache.put('scripts/directives/new-product/new-product-directive.html', require('./directives/new-product/new-product-directive.html'));
        $templateCache.put('scripts/directives/new-subcontractor/new-subcontractor.html', require('./directives/new-subcontractor/new-subcontractor.html'));
        $templateCache.put('scripts/directives/password-form/password-form.html', require('./directives/password-form/password-form.html'));
        $templateCache.put('scripts/directives/pgzp-sliders/pgzp-sliders.html', require('./directives/pgzp-sliders/pgzp-sliders.html'));
        $templateCache.put('scripts/directives/project-roles/project-roles.html', require('./directives/project-roles/project-roles.html'));
        $templateCache.put('scripts/directives/remeasure-quantity/remeasure-quantity.html', require('./directives/remeasure-quantity/remeasure-quantity.html'));
        $templateCache.put('scripts/directives/tableview/partials/cell.html', require('./directives/tableview/partials/cell.html'));
        $templateCache.put('scripts/directives/tableview/partials/cell.html', require('./directives/tableview/partials/cell.html'));
        $templateCache.put('scripts/directives/tableview/partials/row.html', require('./directives/tableview/partials/row.html'));
        $templateCache.put('scripts/directives/tableview/partials/row.html', require('./directives/tableview/partials/row.html'));
        $templateCache.put('scripts/directives/tableview/tableview-directive.html', require('./directives/tableview/tableview-directive.html'));
        $templateCache.put('scripts/directives/upload/upload-directive.html', require('./directives/upload/upload-directive.html'));
        $templateCache.put('scripts/directives/wss-slider/wss-slider.html', require('./directives/wss-slider/wss-slider.html'));
        $templateCache.put('scripts/directives/zone-tools/zone-tools.html', require('./directives/zone-tools/zone-tools.html'));
        $templateCache.put('scripts/modules/project-hub/application-bill/partials/application-bill-nav.html', require('./modules/project-hub/application-bill/partials/application-bill-nav.html'));
        $templateCache.put('scripts/modules/project-hub/cost/partials/cost-nav.html', require('./modules/project-hub/cost/partials/cost-nav.html'));
        $templateCache.put('scripts/modules/project-hub/issue/partials/issue-nav.html', require('./modules/project-hub/issue/partials/issue-nav.html'));
        $templateCache.put('scripts/modules/project-hub/fixed-costs/partials/fixed-cost-bill-nav.html', require('./modules/project-hub/fixed-costs/partials/fixed-cost-bill-nav.html'));
        $templateCache.put('scripts/modules/project-hub/labour-bill/partials/labour-bill-nav.html', require('./modules/project-hub/labour-bill/partials/labour-bill-nav.html'));
        $templateCache.put('scripts/modules/project-hub/material-bill/partials/edit-material-order-properties.html', require('./modules/project-hub/material-bill/partials/edit-material-order-properties.html'));
        $templateCache.put('scripts/modules/project-hub/material-bill/partials/material-bill-nav.html', require('./modules/project-hub/material-bill/partials/material-bill-nav.html'));
        $templateCache.put('scripts/modules/project-hub/material-bill/partials/material-order-item-zone-quantities.html', require('./modules/project-hub/material-bill/partials/material-order-item-zone-quantities.html'));
        $templateCache.put('scripts/modules/project-hub/progress/partials/cell-progress-group-progress.html', require('./modules/project-hub/progress/partials/cell-progress-group-progress.html'));
        $templateCache.put('scripts/modules/project-hub/progress/partials/cell-progress-progress.html', require('./modules/project-hub/progress/partials/cell-progress-progress.html'));
        $templateCache.put('scripts/modules/project-hub/progress/partials/progress-nav.html', require('./modules/project-hub/progress/partials/progress-nav.html'));
        $templateCache.put('scripts/modules/rate-build-up/partials/assessment-product.html', require('./modules/rate-build-up/partials/assessment-product.html'));
        $templateCache.put('scripts/modules/sf-quote/partials/table-cell-component-weights.html', require('./modules/sf-quote/partials/table-cell-component-weights.html'));
        $templateCache.put('scripts/modules/sf-quote/partials/table-cell-quantities-quoted.html', require('./modules/sf-quote/partials/table-cell-quantities-quoted.html'));
        $templateCache.put('views/_partials/menu-assessment.html', require('../views/_partials/menu-assessment.html'));
        $templateCache.put('views/_partials/menu-contacts.html', require('../views/_partials/menu-contacts.html'));
        $templateCache.put('views/_partials/menu-hub-main.html', require('../views/_partials/menu-hub-main.html'));
        $templateCache.put('views/_partials/menu-project.html', require('../views/_partials/menu-project.html'));
        $templateCache.put('views/_partials/menu-resources.html', require('../views/_partials/menu-resources.html'));
    }])
    .run(function (PermPermissionStore, PermPermissionStrategies, $rootScope) {
        PermPermissionStrategies.authorizeElement = function (element) {
            element.removeClass('cs-hide');
        };
        PermPermissionStrategies.unauthorizeElement = function (element) {
            element.addClass('cs-hide');
        };

        $rootScope.$watch('userDetails', function (newVal, oldVal) {
            PermPermissionStore.clearStore();
            if (newVal && newVal.permissions) {
                PermPermissionStore.defineManyPermissions(newVal.permissions, /*@ngInject*/ function (permissionName) {
                    return newVal.permissions.indexOf(permissionName) !== false;
                });
            }
        });
    })
    .run(function ($cacheFactory, $interval) {
        var mins = 5;

        $interval(function () {
            $cacheFactory.get('$http').removeAll();
        }, mins * 60 * 1000);
    })
    .run(function ($rootScope, $location, PermPermissionStore) {
        const defaultTitle = 'Chalkstring - the all-in-one platform for managing project financials' ;
        $rootScope.page = {
            setTitle: function(title) {
                // Trick to decode any HTML entities
                const textarea = document.createElement('textarea');
                textarea.innerHTML = title + ': ' + defaultTitle;
                this.title = textarea.value;
            }
        };
        // register listener to watch route changes
        $rootScope.$on("$routeChangeStart", function (event, next, current) {
            if ($rootScope.loggedIn && $location.path() !== '/new-password' && PermPermissionStore.hasPermissionDefinition('forcePasswordReset')) {
                $location.path('/new-password');
            }
            if (next && next.$$route) {
                $rootScope.page.setTitle(next.$$route.title || defaultTitle);
            } else {
                $rootScope.page.setTitle(defaultTitle);
            }
        });

        $rootScope.$on('$routeChangePermissionDenied', function(event, nextRoute) {
            $location.path('/access-denied');
        });
    })
    .run(function($rootScope, $window) {

        $rootScope.$on('$locationChangeSuccess', function() {
            if ($window.userpilot) {
                $window.userpilot.reload();
            }
        });
    });
// Chalkstring
require('./config.js');
require('./directives/alerts.js');
require('./directives/application-bill/application-bill.js');
require('./directives/application-review/application-review.js');
require('./directives/apzq-field/apzq-field.js');
require('./directives/archive/archivebutton.js');
require('./directives/back-button.js');
require('./directives/checkbox-indeterminate.js');
require('./directives/commercial-summary/commercial-summary.js');
require('./directives/contacts-and-offices/contacts-offices.js');
require('./directives/delete/deletebutton.js');
require('./directives/dirty-status-poller.js');
require('./directives/dob.js');
require('./directives/dropdowns/combined-suppliers-dropdown/combined-suppliers-dropdown.js');
require('./directives/dropdowns/nominal-code-dropdown/nominal-code-dropdown.js');
require('./directives/dropdowns/tax-code-dropdown/tax-code-dropdown.js');
require('./directives/rate-build-up-product-header/rate-build-up-product-header.js');
require('./directives/edit-rate-build-up-product/edit-rate-build-up-product.js');
require('./directives/entity-details.js');
require('./directives/file-folders/file-folders.js');
require('./directives/filestable/filestable.js');
require('./directives/formchanged.js');
require('./directives/grid-bill/grid-bill.js');
require('./directives/hover-intent.js');
require('./directives/hub-charts/budget-pie.js');
require('./directives/hub-charts/hub-charts.js');
require('./directives/image-loaded.js');
require('./directives/lists/contractor-list/contractor-list.js');
require('./directives/lists/operative-list/operative-list.js');
require('./directives/lists/project-work-package-list/project-work-package-list.js');
require('./directives/lists/user-list/user-list.js');
require('./directives/match.js');
require('./directives/modal.js');
require('./directives/modal/modal.js');
require('./directives/modals/contractor-list-modal/contractor-list-modal.js');
require('./directives/modals/error-list-modal/error-list-modal.js');
require('./directives/modals/operative-list-modal/operative-list-modal.js');
require('./directives/modals/password-modal/password-modal.js');
require('./directives/modals/user-list-modal/user-list-modal.js');
require('./directives/nav-highlighting.js');
require('./directives/nav-tab.js');
require('./directives/new-fixed-cost/new-fixed-cost.js');
require('./directives/new-issue/new-issue.js');
require('./directives/new-labouractivity/new-labouractivity.js');
require('./directives/new-labourrate/new-labourrate.js');
require('./directives/new-material/new-material.js');
require('./directives/new-operative/new-operative.js');
require('./directives/new-product/new-product.js');
require('./directives/new-subcontractor/new-subcontractor.js');
require('./directives/notifications/notifications.js');
require('./directives/ok-password.js');
require('./directives/orfile.js');
require('./directives/password-form/password-form.js');
require('./directives/pgzp-sliders/pgzp-sliders.js');
require('./directives/progress-bar.js');
require('./directives/project-roles/project-roles.js');
require('./directives/remeasure-quantity/remeasure-quantity.js');
require('./directives/select-on-click.js');
require('./directives/string-to-number.js');
require('./directives/tableview/tableview.js');
require('./directives/toggle-class.js');
require('./directives/toggle-switch.js');
require('./directives/upload/uploadqueue.js');
require('./directives/wss-slider/wss-slider.js');
require('./directives/zone-tools/zone-tools');
require('./filters/country.js');
require('./filters/decimal.js');
require('./filters/first-permissible-route.js');
require('./filters/replace.js');
require('./filters/trust-src.js');
require('./filters/units.js');
require('./filters/zone-list.js');
require('./filters/sentence.js');
require('./modules/assessment-adjustment/assessment-adjustments.js');
require('./modules/assessment-comparison/assessment-comparison.js');
require('./modules/assessment-fixed-cost/assessmentfixedcosts.js');
require('./modules/assessment/assessment.js');
require('./modules/bill-of-quantities/bill-of-quantities.js');
require('./modules/cancel-requests/cancel-requests.js');
require('./modules/client/client.js');
require('./modules/currency/currency.js');
require('./modules/document-template/document-template.js');
require('./modules/error-handler/errorhandler.js');
require('./modules/export-rate-build-up/export-rate-build-up.js');
require('./modules/fixed-cost/fixedcosts.js');
require('./modules/import-quotation/import-quotation.js');
require('./modules/labour-activity/labour-activity.js');
require('./modules/labour-quote/labour-quote.js');
require('./modules/loader/loader.js');
require('./modules/login/main.js');
require('./modules/material-quote/material-quote.js');
require('./modules/material/material.js');
require('./modules/my-account/my-account.js');
require('./modules/navigation.js');
require('./modules/notification/interceptor.js');
require('./modules/notification/send-notification.js');
require('./modules/operative/operative.js');
require('./modules/password/reset-password.js');
require('./modules/permissions/permissions.js');
require('./modules/product/product.js');
require('./modules/project-hub/application-bill/application-incoming-bill.js');
require('./modules/project-hub/application-bill/application-outgoing-bill.js');
require('./modules/project-hub/application-bill/edit-payment-application.js');
require('./modules/project-hub/application-bill/payment-application.js');
require('./modules/project-hub/assessment/assessment.js');
require('./modules/project-hub/cost/cost.js');
require('./modules/project-hub/daywork/daywork.js');
require('./modules/project-hub/fixed-costs/fixed-cost-invoice.js');
require('./modules/project-hub/fixed-costs/fixed-cost.js');
require('./modules/project-hub/issue/issue.js');
require('./modules/project-hub/labour-bill/labour-bill.js');
require('./modules/project-hub/material-bill/material-bill.js');
require('./modules/project-hub/material-bill/material-delivery.js');
require('./modules/project-hub/material-bill/material-invoice.js');
require('./modules/project-hub/material-bill/material-order.js');
require('./modules/project-hub/progress/progress-grouping.js');
require('./modules/project-hub/progress/progress-map.js');
require('./modules/project-hub/progress/progress.js');
require('./modules/project-hub/project/project.js');
require('./modules/project-hub/roles/roles.js');
require('./modules/project-hub/workscope/workscope.js');
require('./modules/project/project-single.js');
require('./modules/quote-request/quote-request.js');
require('./modules/rate-build-up/rate-build-up.js');
require('./modules/sf-quote/sf-quote.js');
require('./modules/sf-quote/sf-quote-list.js');
require('./modules/sub-contractor/subcontractor.js');
require('./modules/supplier/supplier.js');
require('./modules/tender/tender.js');
require('./modules/user-auth/forced-password/forced-password.js');
require('./modules/user-auth/user-auth.js');
require('./modules/work-package/work-package.js');
require('./modules/zxcvbn.js');
require('./other/ManyAssociationPatcher.js');
require('./routes/accounts-routes.js');
require('./routes/hub-routes.js');
require('./routes/main-routes.js');
require('./services/account.js');
require('./services/accountspackage.js');
require('./services/assessment.js');
require('./services/assessmentfixedcosts.js');
require('./services/assessmentlabourcomponent.js');
require('./services/assessmentlabourcomponentactivity.js');
require('./services/assessmentlabourcomponentmaterial.js');
require('./services/assessmentmaterial.js');
require('./services/assessmentproduct.js');
require('./services/card.js');
require('./services/cistaxcode.js');
require('./services/client.js');
require('./services/configurablematerial.js');
require('./services/cost.js');
require('./services/currencies.js');
require('./services/date-utils.js');
require('./services/documenttemplate.js');
require('./services/entity-details.js');
require('./services/externalcostinvoices.js');
require('./services/externallink.js');
require('./services/file.js');
require('./services/filefolder.js');
require('./services/fixedcost.js');
require('./services/fixedcostapplication.js');
require('./services/fixedcostinvoices.js');
require('./services/fixedcostorders.js');
require('./services/groupcompany.js');
require('./services/issue.js');
require('./services/issuecategory.js');
require('./services/labouractivity.js');
require('./services/labourapplication.js');
require('./services/labourapplicationitem.js');
require('./services/labourquoterequest.js');
require('./services/labourquotes.js');
require('./services/labourrate.js');
require('./services/material.js');
require('./services/materialdeliveries.js');
require('./services/materialorderinvoices.js');
require('./services/materialorders.js');
require('./services/materialquoterequest.js');
require('./services/materialtype.js');
require('./services/notification.js');
require('./services/operative.js');
require('./services/orderinvoices.js');
require('./services/orders.js');
require('./services/organisation.js');
require('./services/package.js');
require('./services/paymentapplication.js');
require('./services/people.js');
require('./services/period.js');
require('./services/price.js');
require('./services/product.js');
require('./services/productapplication.js');
require('./services/progress-groups.js');
require('./services/project.js');
require('./services/projectrole.js');
require('./services/projectworkpackage.js');
require('./services/quote-price.js');
require('./services/quotes.js');
require('./services/region.js');
require('./services/resource.js');
require('./services/role.js');
require('./services/routetype.js');
require('./services/subcontractor.js');
require('./services/supplier.js');
require('./services/supplyandfitprice.js');
require('./services/sfquoterequest.js');
require('./services/sfquotes.js');
require('./services/tender.js');
require('./services/trades.js');
require('./services/user.js');
require('./services/variation.js');
require('./services/vattaxcode.js');
require('./services/workscope.js');
require('./services/zone.js');
require('./units.js');
require('./services/projectnote');
