import {CsUtils} from '../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:AssessmentCtrl
     * @description
     * # AssessmentCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('AssessmentCtrl', function (
            $scope,
            $routeParams,
            assessment,
            project,
            $route,
            $rootScope,
            ENV,
            $location,
            $http,
            $window,
            $locale,
            FileUploader,
            PermPermissionStore
        ) {
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteProjectsOffsiteAssessments');

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.assessmentId = $routeParams.id;
            $scope.ENV = ENV;
            $scope.token = localStorage.getItem("access_token");
            $scope.buttonTextAndClass = function () {

                $scope.buttons = {
                    rateBuildUp: {
                        text: 'Start',
                        cssClass: ''
                    },
                    quantifying: {
                        text: 'Start',
                        cssClass: ''
                    },
                    fixedCosts: {
                        text: 'Start',
                        cssClass: ''
                    }
                };

                if ($scope.assessment.snapshotComment) {
                    $scope.buttons.rateBuildUp.text = 'View';
                    $scope.buttons.quantifying.text = 'View';
                    $scope.buttons.fixedCosts.text = 'View';
                } else {
                    if ($scope.assessment.rateBuildUpStarted && !$scope.assessment.rateBuildUpCompleted) {
                        $scope.buttons.rateBuildUp.text = 'Continue';
                        $scope.buttons.rateBuildUp.cssClass = 'btn-orange';
                    } else if ($scope.assessment.rateBuildUpCompleted) {
                        $scope.buttons.rateBuildUp.text = 'Edit';
                        $scope.buttons.rateBuildUp.cssClass = 'btn-green';
                    }

                    if ($scope.assessment.quantificationStarted && !$scope.assessment.quantificationCompleted) {
                        $scope.buttons.quantifying.text = 'Continue';
                        $scope.buttons.quantifying.cssClass = 'btn-orange';
                    } else if ($scope.assessment.quantificationCompleted) {
                        $scope.buttons.quantifying.text = 'Edit';
                        $scope.buttons.quantifying.cssClass = 'btn-green';
                    }

                    if ($scope.assessment.fixedCostsStarted && !$scope.assessment.fixedCostsCompleted) {
                        $scope.buttons.fixedCosts.text = 'Continue';
                        $scope.buttons.fixedCosts.cssClass = 'btn-orange';
                    } else if ($scope.assessment.fixedCostsCompleted) {
                        $scope.buttons.fixedCosts.text = 'Edit';
                        $scope.buttons.fixedCosts.cssClass = 'btn-green';
                    }
                }

            };

            // Client bill template export and import
            $scope.fileUploader = {
                uploader: new FileUploader(),
                file: null
            };
            $scope.downloadClientBillTemplate = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/assessments/' + $scope.assessmentId + '/client-bill-template'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };
            $scope.importClientBillTemplate = function () {
                if (!$scope.fileUploader.file) {
                    return;
                }
                var file = $scope.fileUploader.file;
                assessment.clientBillImport({id: $scope.assessmentId, fileId: file.id}).$promise.then(function (data) {
                    $scope.importClientBillModal.close();
                }, function (response) {
                    $scope.importClientBillModal.close();
                    $scope.errors = response.data.errors;
                    console.log($scope.errors);
                    $scope.errorModal.open();
                });
            };

            // Supply and fit quote requests
            $scope.supplyFitQuoteRequest = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/assessments/' + $scope.assessmentId + '/supplyfitquoterequest'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };

            // Get assessment details
            assessment.get({id: $routeParams.id}).$promise.then(function (data) {
                $scope.assessment = data;
                $scope.packageId = data.projectWorkPackage.id;
                $scope.projectId = data.projectWorkPackage.project.id;
                $scope.assessmentName = {
                    name: data.name
                };
                $scope.totalAssessmentCost = data.estimationMaterials + data.estimationLabour + data.estimationPreliminaries + data.estimationAncillaries;
            }).then(function () {
                $scope.buttonTextAndClass();
            });

            $scope.updateEstimates = function () {
                assessment.updateAssessmentEstimates({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.assessment = data;
                });
            };

            $scope.setTotalModel = {};
            $scope.setTotal = function (form) {
                if (form.$valid) {
                    console.log($scope.setTotalModel.targetPrice);
                    assessment.setAssessmentTotal({
                        id: $routeParams.id,
                        targetPrice: $scope.setTotalModel.targetPrice
                    }).$promise.then(function () {
                        $scope.updateEstimates();
                    });
                }
            };

            $scope.resetAssessmentTotal = function () {
                assessment.resetAssessmentTotal({id: $routeParams.id}).$promise.then(function () {
                    $scope.updateEstimates();
                });
            };

            /* Generate quote request */
            $scope.generateQuoteRequest = function () {
                assessment.quoteRequest({id: $routeParams.id}).$promise.then(function () {

                });
            };

            /* Duplicate Assessment */
            $scope.duplicateAssessment = function (a) {
                assessment.duplicateAssessment({id: a.id, name: a.name + ' (Copy)', projectWorkPackage: a.projectWorkPackage.id}).$promise.then(function (data) {
                    $location.path('/assessment/' + data.id);
                });
            };

            $scope.showRateBuildUp = function (assessmentId) {
                $location.path('/rate-build-up/' + assessmentId);
            };

            $scope.showBillOfQuantities = function (projectId, assessmentId) {
                $location.path('/project/' + projectId + '/assessment/' + assessmentId + '/boq');
            };

            $scope.showFixedCosts = function (projectId, assessmentId) {
                $location.path('/assessment-fixed-costs/' + assessmentId).search({'projectId': projectId});
            };

            $scope.showContingencyOrders = function (assessmentId) {
                $location.path('/assessment/' + assessmentId + '/ancillaryorders');
            };

            $scope.downloadFile= function (url) {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + url
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };
        });
})();
