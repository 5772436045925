import {CsUtils} from "../../../other/csutils";

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ApplicationIncomingBillCtrl', function ($locale, $scope, $rootScope, $routeParams, $route, $http, $window, ENV, project, subcontractor, productApplication, $filter, FileUploader) {
            $scope.currentApplicationItems = [];
            $scope.measuredCosts = true;
            $scope.paymentOption = "per-package";
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.exportUrl = ENV.apiEndpoint + '/projects/' + $routeParams.id + '/export-application-incoming-saf-bill-template?access_token=' + localStorage.getItem("access_token");

            $scope.measuredCosts = true;
            $scope.switchApplicationType = function () {
                $scope.measuredCosts = !$scope.measuredCosts;
            };

            $scope.form = {};
            $scope.file = {};

            $scope.projectId = $rootScope.projectId = $routeParams.id;
            project.get({id: $scope.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                $scope.project = data;
            });

            // Save application modal
            $scope.modalInfo = {loading: false};
            $scope.draftApplication  = {effectiveDate: new Date()};
            $scope.openSaveModal = function () {
                $scope.modalInfo.loading = true;
                productApplication.getApplicationIncomingSupplyAndFitDraft({id: $scope.projectId}).$promise.then(function (draft) {
                    draft.effectiveDate = new Date();
                    $scope.draftApplication = draft;
                    $scope.modalInfo.loading = false;
                });
                if (!$scope.modalInfo.subcontractors) {
                    subcontractor.query({hydration: 'array', limit: 10000}).$promise.then(function (subcontractors) {
                        $scope.modalInfo.subcontractors = subcontractors;
                    });
                }
                $scope.saveApplicationModal.open();
            };
            $scope.closeSaveModal = function () {
                $scope.form.saveForm.$setPristine();
                $scope.saveApplicationModal.close();
            };
            $scope.saveApplication = function (draftApplication) {
                if (!$scope.form.saveForm.$valid || !draftApplication.subcontractor.length) {
                    return;
                }

                productApplication.saveDraftIncoming({id: draftApplication.id}, {
                    subcontractor: draftApplication.subcontractor[0].id,
                    effectiveDate: $filter('date')(draftApplication.effectiveDate, 'yyyy-MM-dd HH:mm:ss')
                }).$promise.then(function (application) {
                    $scope.saveApplicationModal.close();
                    $scope.$broadcast('gbRefresh');
                });
            };

            $scope.exportApplicationTemplate = function() {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/projects/' + $routeParams.id + '/export-application-incoming-saf-bill-template'
                }).success(function (data, status, headers) {
                    if (status === 204) {
                        alert('This assessment must contain at least one supply & fit product before a template can be generated');
                    } else {
                        CsUtils.download(data, headers, $window);
                        $scope.$broadcast('closeModal');
                    }
                });
            };

            // Import application modal
            $scope.fileData = [];
            $scope.file.importFile = null;
            $scope.documents = {
                uploader: new FileUploader,
                list: $scope.fileData
            };
            $scope.openImportModal = function () {
                $scope.documents.list = [];
                $scope.file.importFile = null;
                $scope.uploadedApplication = {effectiveDate: new Date()};
                if (!$scope.modalInfo.subcontractors) {
                    subcontractor.query({hydration: 'array', limit: 10000}).$promise.then(function (subcontractors) {
                        $scope.modalInfo.subcontractors = subcontractors;
                    });
                }
                $scope.importApplicationModal.open();
            };
            $scope.closeImportModal = function () {
                $scope.form.importForm.$setPristine();
                $scope.importApplicationModal.close();
            };
            $scope.importApplication = function (application) {
                if (!$scope.form.importForm.$valid || !application.subcontractor.length || !$scope.file.importFile) {
                    return;
                }

                productApplication.importIncoming({
                    projectId: $scope.projectId,
                    effectiveDate: $filter('date')(application.effectiveDate, 'yyyy-MM-dd HH:mm:ss'),
                    subcontractor: application.subcontractor[0].id,
                    file: $scope.file.importFile.id
                }).$promise.then(function (data) {
                    $scope.importApplicationModal.close();
                    $scope.$broadcast('gbRefresh');
                });
            };

            // List
            if ($route.current.$$route.isList) {
                $scope.titleApplicationType = $route.current.$$route.titleApplicationType;
                $scope.applicationStates = $route.current.$$route.applicationStates;
                $scope.direction = $route.current.$$route.applicationDirection;
                $scope.tableServiceParams = {
                    project: $rootScope.projectId,
                    'direction': $scope.direction,
                    'state[]': $scope.applicationStates
                };
            }
        });
})();
