/**
 * This class contains code which is here primarily to reduce the size of edit-rate-build-up-product.js
 * and to ensure that the RBU modal can be used from Angular via edit-rate-build-up-product.directive.ts.
 */
import {RateBuildUpIssueService} from "../../../modules/rate-build-up/issues/rate-build-up-issue.service";
import {
    AssessmentLabourComponent, AssessmentLabourComponentActivity,
    AssessmentLabourComponentMaterial
} from "../../../modules/chalkstring-api/services/assessment-product.service";
import {ReorderItem} from "@generic/reorder-table/reorder-table.component";

export class EditRateBuildUpProductHelpers {
    static entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': '&quot;',
        "'": '&#39;',
        "/": '&#x2F;'
    };

    private reorderItemsModalItems: ReorderItem[] = null;

    constructor(private _riService: RateBuildUpIssueService) {
    }

    addMaterialSetup(scope) {
        // Creating a Material
        scope.$on('newMaterialAdded', function (event, item) {
            scope.newMaterialModal.close();
            scope.materialSelected(item);
        });
    }

    chooseSwapMaterial(scope, options) {
        scope.swapMaterialOldMaterial = options.assessmentLabourComponentMaterial.originalMaterial;
        scope.materialSelected = function (item) {
            scope.swapMaterialForm.configuration = null;
            scope.chooseMaterialModal.close();
            scope.swapMaterialNewMaterial = item;
            scope.swapMaterialForm.selectedQuantity = scope.chooseMaterialModal.getContext().assessmentLabourComponentMaterial.quantityPerLabourComponent;
            scope.swapMaterialForm.conversionFactor = 1;
            scope.swapMaterialForm.exampleIn = scope.swapMaterialForm.selectedQuantity;
            scope.swapMaterialForm.exampleOut = scope.swapMaterialForm.selectedQuantity;
            scope.swapMaterialModal.open();
        };
        scope.chooseMaterialModal.open(options);
    }

    chooseSwapLabourActivity(scope, options, swappable, rootScope) {
        scope.swapLabourActivityOldLabourActivity = options.assessmentLabourComponentActivity.originalLabourActivity;
        scope.activitySelected = function (item) {
                if (swappable[0]) {
                    scope.chooseLabourActivityModal.close();
                    scope.swapLabourActivityNewLabourActivity = item;
                    scope.swapLabourActivityForm.selectedQuantity = scope.chooseLabourActivityModal.getContext().assessmentLabourComponentActivity.quantityPerLabourComponent;
                    scope.swapLabourActivityForm.conversionFactor = 1;
                    scope.swapLabourActivityForm.exampleIn = scope.swapLabourActivityForm.selectedQuantity;
                    scope.swapLabourActivityForm.exampleOut = scope.swapLabourActivityForm.selectedQuantity;
                    scope.swapLabourActivityModal.open();
                }
                else{
                    rootScope.$broadcast('showOrNotification', "You are not able to swap this activity out of the assessment scope, " +
                        "because it has already been included in previous labour applications. " +
                        "If you no longer require this activity, but instead require an alternative, " +
                        "we would recommend that in your rate build ups, you adjust the allowance of the original activity to cover progress to date, " +
                        "and add a new activity representing the balance.", true);
                }

        };
        scope.chooseLabourActivityModal.open(options);
    }

    removeMaterial(scope, component, material, assessmentMaterial, assessment) {
        const that = this;
        scope.confirmationQuestion = 'Are you sure you want to remove ' + material.name + ' from ' + component.name + '?';
        scope.confirmationConfirm = function () {
            assessmentMaterial.delete({id: material.id}).$promise.then(function () {
                scope.confirmModal.close();
                that.reload(scope, assessment, component);
            });
        };
        scope.confirmModal.open();
    }

    removeActiveLabourActivity(scope, component, activity, assessmentLabourComponentActivity, assessment) {
        const that = this;
        scope.confirmationQuestion = 'Are you sure you want to remove ' + activity.name + ' from ' + component.name + '?';
        scope.confirmationConfirm = function () {
            assessmentLabourComponentActivity.delete({id: activity.id}).$promise.then(function () {
                scope.confirmModal.close();
                that.reload(scope, assessment, component);
            });
        };
        scope.confirmModal.open();
    }

    reorderMaterials(scope, assessment, component, assessmentLabourComponentMaterial) {
        this.reorderItemsModalItems = null;
        const materials = component.assessmentLabourComponentMaterials.map(material => {
            return {
                id: material.id,
                label: material.name,
                order: material.displayOrder
            }
        });
        scope.reorderItemsModalOrderChanged = (updatedItems: ReorderItem[]) => {
            this.reorderItemsModalItems = updatedItems;
        };
        scope.reorderItemsModalSaveChanges = () => {
            if (this.reorderItemsModalItems) {
                assessmentLabourComponentMaterial.changeMaterialOrder({
                    'ids': this.reorderItemsModalItems.map(item => item.id)
                }).$promise.then(() => {
                    this.reload(scope, assessment, component);
                    scope.reorderItemsModal.close();
                    scope.reorderItemsModalSaveChanges = null;
                });
            } else {
                scope.reorderItemsModal.close();
                scope.reorderItemsModalSaveChanges = null;
            }
        };
        scope.reorderItemsModalRevertChanges = () => {
            // Need to set items to a new array otherwise change not detected
            scope.reorderItemsModal.getContext().items = [...materials];
            this.reorderItemsModalItems = null;
        };
        const options = {
            title: 'Re-order materials',
            instructions: 'Use the arrow buttons to move the display position up or down within the component list.',
            items: materials
        };
        scope.reorderItemsModal.open(options);
    }

    reorderActivities(scope, assessment, component, assessmentLabourComponentActivity) {
        this.reorderItemsModalItems = null;
        const activities = component.assessmentLabourComponentActivities.map(activity => {
            return {
                id: activity.id,
                label: activity.name,
                order: activity.displayOrder
            }
        });
        scope.reorderItemsModalOrderChanged = (updatedItems: ReorderItem[]) => {
            this.reorderItemsModalItems = updatedItems;
        }
        scope.reorderItemsModalSaveChanges = () => {
            if (this.reorderItemsModalItems) {
                assessmentLabourComponentActivity.changeActivityOrder({
                    'ids': this.reorderItemsModalItems.map(item => item.id)
                }).$promise.then(() => {
                    this.reload(scope, assessment, component);
                    scope.reorderItemsModal.close();
                    scope.reorderItemsModalSaveChanges = null;
                });
            } else {
                scope.reorderItemsModal.close();
                scope.reorderItemsModalSaveChanges = null;
            }
        };
        scope.reorderItemsModalRevertChanges = () => {
            // Need to set items to a new array otherwise change not detected
            scope.reorderItemsModal.getContext().items = [...activities];
            this.reorderItemsModalItems = null;
        };
        const options = {
            title: 'Re-order activities',
            instructions: 'Use the arrow buttons to move the display position up or down within the component list.',
            items: activities
        };
        scope.reorderItemsModal.open(options);
    }

    reload(scope, assessment: any, component = null, success = null) {
        const riService = this._riService;
        assessment.getAssessmentProduct({
            id: scope.assessmentProduct.id,
            sort: 'assessmentOrder',
            groups: ['products_externalLinks', 'prices_view']
        }).$promise.then(data => {
            data.extraLoaded = true;
            if (component) {
                const updatedComponent = data.assessmentLabourComponents.find(c => c.id == component.id);

                if (updatedComponent) {
                    scope.tables[component.id] = scope.generateTable(updatedComponent);
                }
            } else {
                scope.tables = {};
                // Recreate all tables
                data.assessmentLabourComponents.forEach(component => {
                    scope.tables[component.id] = scope.generateTable(component);
                });
            }
            riService.fetchRbuIssues(scope.assessment);
            scope.assessmentProduct = data;
            if (success) {
                success();
            }
        });
    }

    sortComponentMaterialsAndActivities(component: AssessmentLabourComponent) {
        component.assessmentLabourComponentMaterials.sort((material1: AssessmentLabourComponentMaterial, material2: AssessmentLabourComponentMaterial) => {
            if (material1.displayOrder == material2.displayOrder) {
                return material1.createdAt.localeCompare(material2.createdAt);
            } else if (material1.displayOrder < material2.displayOrder) {
                return -1;
            } else if (material1.displayOrder > material2.displayOrder) {
                return 1;
            } else {
                return 0;
            }
        });
        component.assessmentLabourComponentActivities.sort((activity1: AssessmentLabourComponentActivity, activity2: AssessmentLabourComponentActivity) => {
            if (activity1.displayOrder == activity2.displayOrder) {
                return activity1.createdAt.localeCompare(activity2.createdAt);
            } else if (activity1.displayOrder < activity2.displayOrder) {
                return -1;
            } else if (activity1.displayOrder > activity2.displayOrder) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    priceBadge(price) {
        if (!price) {
            return `<span class="rbu-quote rbu-no-price"><i class="icon-warning"></i> No price selected</span>`;
        } else if (!price.quote) {
            return `<span class="rbu-quote rbu-guesstimate"><i class="icon-warning"></i> Guesstimate</span>`;
        } else {
            let statusLabel = "";
            if (price.quote.archivedAt) {
                statusLabel = "ARCHIVED:";
            } else if (price.quote && !price.quote.isActiveNow && !price.quote.archivedAt) {
                statusLabel = "INACTIVE:";
            }

            if (statusLabel.length > 0) {
                return `<span class="rbu-quote rbu-warning name"><i class="icon-warning"></i> ${statusLabel}${price.quote.name}</span>`;
            }

            return `<span class="rbu-quote rbu-quoted name">${statusLabel}${price.quote.name}</span>`;
        }
    }

    updateMaterialPrice(scope, component, material, newPrice, callback, assessmentMaterial, assessment) {
        if (newPrice.selectable) {
            const that = this;
            assessmentMaterial.changePrice({
                id: material.id,
                newPriceId: newPrice.id
            }).$promise.then(function () {
                // Changing a price on a material updates it for all components, so we can't
                // just reload one component
                if (callback) {
                    callback();
                } else {
                    that.reload(scope, assessment);
                }
            });
        }
    }

    updateActivityRate(scope, component, activity, newRate, callback, assessmentLabourComponentActivity, assessment) {
        if (newRate.selectable) {
            const that = this;
            assessmentLabourComponentActivity.changeRate({
                id: activity.id,
                newRateId: newRate.id
            }).$promise.then(function () {
                // Changing a price on an activity updates it for all components, so we can't
                // just reload one component
                if (callback) {
                    callback();
                } else {
                    that.reload(scope, assessment);
                }
            });
        }
    }

    updateSupplyAndFitPrice(scope, product, newPrice, assessmentProduct, assessment) {
        const that = this;
        assessmentProduct.patch({
            id: product.id,
            supplyAndFitPrice: newPrice.id
        }).$promise.then(function () {
            that.reload(scope, assessment);
        })
    }

    updateMaterialConfiguration(scope, component, material, configuration, callback, assessmentMaterial, assessment) {
        const that = this;
        let request = {
            assessmentMaterialId: material.id,
            newMaterialId: material.originalMaterial.id,
            configuration: configuration.id
        };
        assessmentMaterial.swapMaterial(request).$promise.then(function () {
            if (callback) {
                callback();
            } else {
                that.reload(scope, assessment, component);
            }
        });
    }

    updateMaterialQuantity(scope, component, material, quantity, callback, assessmentLabourComponentMaterial, assessment) {
        const that = this;
        assessmentLabourComponentMaterial
            .patch(
                {id: material.id},
                {quantityPerLabourComponent: quantity})
            .$promise.then(function () {
            if (callback) {
                callback();
            } else {
                that.reload(scope, assessment, component);
            }
        });
    }

    updateActivityQuantity(scope, component, activity, quantity, callback, assessmentLabourComponentActivity, assessment) {
        const that = this;
        assessmentLabourComponentActivity
            .patch(
                {id: activity.id},
                {quantityPerLabourComponent: quantity})
            .$promise.then(function () {
            if (callback) {
                callback();
            } else {
                that.reload(scope, assessment, component);
            }
        });
    }

    updateMaterialWaste(scope, component, material, waste, callback, assessmentMaterial, assessment) {
        const that = this;
        assessmentMaterial
            .patch(
                {id: material.id},
                {wasteAllowancePercentage: waste})
            .$promise.then(function () {
            if (callback) {
                callback();
            } else {
                that.reload(scope, assessment, component);
            }
        });
    }

    htmlShortUnit(type, shortUnitsText) {
        /*
         * Convert quantity unit identifier into human-readable
         * short unit (e.g. m, kg)
         */
        let shortUnit = shortUnitsText[type] || '';
        return shortUnit.replace(/[0-9]+/gi, (num) => `<sup>${num}</sup>`);
    }

    escapeHtml(html) {
        return String(html).replace(/[&<>"'\/]/g, function (s) {
            return EditRateBuildUpProductHelpers.entityMap[s];
        });
    }
}
