angular.module('scswebappApp')
    .directive('remeasureQuantity', function (productApplication) {
        return {
            templateUrl: 'scripts/directives/remeasure-quantity/remeasure-quantity.html',
            restrict: 'E',
            scope: {
                apId: '=',
                applicationId: '=',
                azId: '=',
                quantity: '='
            },
            link: function postLink(scope, element, attrs) {
                scope.$emit('gbHeadAdjusted');

                scope.clearRemeasure = function () {
                    if (!scope.azId) {
                        return;
                    }
                    if (!confirm('Clear this remeasurement?')) {
                        return;
                    }

                    var options = {};
                    if (scope.azId.length) {
                        options.azId = scope.azId[0].id;
                    }
                    productApplication.clearPaapiRemeasurement({id: scope.applicationId, apId: scope.apId}, options).$promise.then(function (data) {
                        scope.$emit('gbRefresh', true);
                    });
                };
            }
        }
    });
