(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.workscope
	 * @description
	 * # workscope
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('workscope', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/workscopes/:id', {id: '@id', groups: '@groups'}, {
                    getWorkPlans: {
                        url: ENV.apiEndpoint + '/workplans',
                        method: 'GET',
                        isArray: true
                    },
					getWorkPlan: {
						url: ENV.apiEndpoint + '/workplans/:id',
						method: 'GET'
					},
	                patchStage: {
	                    url: ENV.apiEndpoint + '/workscopestages/:id',
	                    method: 'PATCH'
	                },
	                patchMeasurement: {
	                    url: ENV.apiEndpoint + '/workscopes/:id?groups=products_externalLinks,workScopeStage_openIssues',
	                    method: 'PATCH'
	                },
	                getStage: {
	                    url: ENV.apiEndpoint + '/workscopestages/:id',
	                    method: 'GET',
                        isArray: false
	                },
					getStages: {
						url: ENV.apiEndpoint + '/workscopestages',
						method: 'GET',
						isArray: true
					},
	                patchOperativeAssignment: {
	                    url: ENV.apiEndpoint + '/operativeassignments/:id',
	                    method: 'PATCH'
	                },
	                assignOperative: {
	                    url: ENV.apiEndpoint + '/operativeassignments',
	                    method: 'POST'
	                },
	                deleteOperativeAssignment: {
	                    url: ENV.apiEndpoint + '/operativeassignments/:id',
	                    method: 'DELETE'
	                },
                    createGroup: {
                        url: ENV.apiEndpoint + '/progressgroups/:id',
                        method: 'POST',
                        transformRequest: function(data){
                            var newData = {
                                'name': data.name,
                                'colour': data.colour,
                                'project': data.project,
                            };
                            return angular.toJson(newData);
                        }
                    },
                    getTableFilters:{
						url: ENV.apiEndpoint + '/projects/:id/workscope-filter-options',
						method: 'GET'
					},
                    getBillRates: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/assessments/:assessment/progress-bill'
                    },
                    getBillFilters: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/application-bill-filter-options' //TODO replace this with proper thing
                    },
					getHeatMapData: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/progress-heat-map',
                        isArray: false
					}
	            }
	        );
	  }]);
})();
