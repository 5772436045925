'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('coreModal', function () {
        return {
            template: '<div class="cs-modal-wrap module" ng-if="visible" ng-class="{\'show-modal\' : visible}"><i ng-if="!noClose" class="icon-cancel close-modal" ng-click="control.close($event)"></i><div class="cs-modal" ng-class="{\'large-modal\' :!small}"><ng-transclude ng-if="initialised"></ng-transclude></div></div>',
            restrict: 'E',
            scope: {
                control: '=?',
                small: '=?',
                visible: '=?',
                noClose: '=?'
            },
            transclude: true,
            link: function postLink(scope, elm) {
                scope.visible = false;

                if (scope.control == undefined){
                    scope.control = {};
                }

                scope.control.open = function(context){
                    if (elm[0]){
                        var largeModal = elm[0].querySelector('.large-modal');
                        if (largeModal) {
                            largeModal.scrollTo(0,0);
                        }
                    }
                    var body = angular.element(document).find('body').eq(0);
                    body.append(elm);

                    scope.visible = true;
                    scope.initialised = true; // set true to allow the modal content to load. This saves modal content from loading until modal is opened the first time
                    scope.context = context;
                    scope.control.onOpen();
                };

                scope.control.close = function($event){
                    scope.control.onClose();
                    scope.visible = false;
                    if ($event) {
                        $event.stopPropagation();
                    }
                };

                scope.control.getContext = function(){
                    return scope.context;
                };

                scope.control.onClose = function() {};
                scope.control.onOpen = function() {};
            }
        };
    });
