'use strict';

angular.module('scswebappApp')
    .directive('nominalCodeDropdown', function (account) {

        return {
            template: require('./nominal-code-dropdown.html'),
            restrict: 'E',
            transclude: true,
            scope:{
                'model': '=?',
                'parentObject': '=',
                'required': '='
            },
            link: function postLink(scope, element, attrs) {
                scope.nominalCodeOptions=[];

                scope.onItemClick = function(selection){
                    if (!selection) {
                        selection = scope.selection[0];
                    }
                    scope.$emit('nominalCodeSelected',{
                        item: selection,
                        element: element[0],
                        parentObject: scope.parentObject
                    });
                    scope.model = selection;
                };

                scope.onReset = function(){
                    scope.$emit('nominalCodeSelected',{
                        item: null,
                        element: element[0],
                        parentObject: scope.parentObject
                    });

                    scope.model = null;
                };

                account.getNominalCodeOptions().$promise.then(function (data) {
                    scope.nominalCodeOptions = data;
                    scope.$watch('model',function(newValue, oldValue, scope){
                        if (newValue !== undefined && newValue !== null) {
                            angular.forEach(data, function (value) {
                                if (value.nominalCode == newValue) {
                                    value.ticked = true;
                                    scope.onItemClick(value);
                                }
                            });
                        }
                    });
                });
            }
        };
    });
