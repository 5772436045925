'use strict';

angular.module('scswebappApp')
	.factory('HttpRequestTimeoutInterceptor', function ($q, HttpPendingRequestsService) {
		return {
			request: function (config) {
				config = config || {};
				if (config.timeout === undefined && !config.noCancelOnRouteChange) {
					config.timeout = HttpPendingRequestsService.newTimeout();
				}
				return config;
			}
		};
	})
	.service('HttpPendingRequestsService', function ($q) {
		var cancelPromises = [];
		return {
			newTimeout: function(){
				var cancelPromise = $q.defer();
				cancelPromises.push(cancelPromise);
				return cancelPromise.promise;
			},
			cancelAll: function(){
				angular.forEach(cancelPromises, function (cancelPromise) {
					cancelPromise.promise.isGloballyCancelled = true;
					cancelPromise.resolve();
				});
				cancelPromises = [];
			}
		};
	})
	.config(function($httpProvider) {
		$httpProvider.interceptors.push('HttpRequestTimeoutInterceptor');
	})
	.run(function ($rootScope, HttpPendingRequestsService) {
		$rootScope.$on('$routeChangeStart', function (event, next, current) {
			if(next !== current){
				HttpPendingRequestsService.cancelAll();
			}
		})
	});
