'use strict';

angular.module('scswebappApp')
    .directive('gridBill', function ($injector, $filter, $rootScope, $routeParams, $locale) {

        var $ = require('jquery');

        return {
            restrict: 'E',
            scope: {
                resourceService: '=',
                serviceMethod: '=',
                serviceOptionsMethod: '=',
                serviceFormMethod: '=',
                forcedQueryParams: '=',
                forcedFormParams: '=',
                filterDefaults: '=',
                hideFilters: '=',
                columnType: '@',
                rowType: '@',
                fetchOnLoad: '=',
                moduleTitle: '@'
            },
            templateUrl: 'scripts/directives/grid-bill/grid-bill.html',
            link: function postLink(scope, elem, attr) {
                var resourceService = $injector.get(attr.resourceService);

                if (attr.moduleTitle !== undefined) {
                    scope.moduleTitle = attr.moduleTitle;
                }
                scope.filters = {};
                scope.formProcessing = false;
                scope.loading = false;
                scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
                scope.projectId = (scope.forcedQueryParams && scope.forcedQueryParams.projectId) ?
                    scope.forcedQueryParams.projectId : $routeParams.id;
                scope.options = {};

                var getDefaultQueryParams = function () {
                    var params = scope.forcedQueryParams ? angular.copy(scope.forcedQueryParams) : {};
                    params.page = 1;
                    params.projectId = scope.projectId;
                    return params;
                };
                scope.queryParams = getDefaultQueryParams();
                scope.$watch('forcedQueryParams', function (newValue, oldValue) {
                    if (newValue !== undefined) {
                        scope.queryParams = getDefaultQueryParams();
                        scope.getGridData();
                    }
                });

                // Handle service options
                scope.getServiceOptions = function (params) {
                    if (!attr.serviceOptionsMethod) {
                        scope.serviceOptions = {};
                        return;
                    }

                    var doFilter = undefined !== params;
                    params = params || {};
                    params.id = scope.projectId;

                    var handleServiceResponse = function (data) {
                        // Persist chosen options between requests
                        var ticked = {};

                        // Defaults from controller, used when filters hidden
                        if (scope.filterDefaults) {
                            angular.forEach(scope.filterDefaults, function (v, k) {
                                ticked[k] = v;
                            });
                        }

                        // Remember chosen options...
                        angular.forEach(scope.serviceOptions, function (v, k) {
                            if (angular.isArray(v)) {
                                angular.forEach(v, function (sv) {
                                    if (sv.ticked) {
                                        ticked[k] = sv.id;
                                    }
                                });
                            }
                        });

                        // ...and tick them again if they come back as an option
                        angular.forEach(data, function (v, k) {
                            if (ticked.hasOwnProperty(k)) {
                                angular.forEach(v, function (dv) {
                                    if (ticked[k] === dv.id) {
                                        dv.ticked = true;
                                    }
                                });
                            }
                        });

                        scope.serviceOptions = data;
                    };
                    resourceService[attr.serviceOptionsMethod](params, handleServiceResponse);

                    if (doFilter) {
                        scope.resetAllFiltersBut(params);
                    }
                };
                scope.getServiceOptions();

                // Initialisation of grid
                var gbInit = function () {
                    // Highlighting cells
                    $('table.cellsTable td').hover(function () {
                        var col = $(this).parent().children().index($(this)) + 1;
                        var row = $(this).parent().parent().children().index($(this).parent()) + 1;
                        $(elem).find('th.row0:nth-child(' + col + ')').addClass('focused');
                        $(elem).find('tr:nth-child(' + row + ') th.col0').addClass('focused');
                    }, function () {
                        var col = $(this).parent().children().index($(this)) + 1;
                        var row = $(this).parent().parent().children().index($(this).parent()) + 1;
                        $(elem).find('th.row0:nth-child(' + col + ')').removeClass('focused');
                        $(elem).find('tr:nth-child(' + row + ') th.col0').removeClass('focused');
                    });
                };

                // Fetching grid data
                scope.getGridData = function (hideLoading) {
                    console.log('Fetching grid data...');
                    scope.loading = !hideLoading;
                    var handleServiceResponse = function (data) {
                        console.log(data);

                        scope.columns = data.columns;
                        scope.rows = data.rows;
                        scope.xPagination = data.xPagination;
                        scope.yPagination = data.yPagination;
                        scope.summary = data.summary;
                        scope.loading = false;

                        // Do things after page has reloaded
                        // I wish I could make this work without multiple calls
                        setTimeout(gbInit, 0);
                        setTimeout(gbInit, 100);
                        setTimeout(gbInit, 1000);
                    };
                    resourceService[attr.serviceMethod](scope.queryParams, handleServiceResponse);
                };
                scope.$on('gbRefresh', function () {
                    console.log('gbRefresh received');
                    if (scope.summary) {
                        scope.getGridData();
                    }
                });
                if (scope.fetchOnLoad) {
                    scope.getGridData();
                }

                // Handling filter changes
                scope.resetFilter = function (param) {
                    delete scope.filters[param];
                    scope.filterGrid();
                };
                scope.resetAllFiltersBut = function (params) {
                    var rememberedValues = {};
                    var assessmentId = null;
                    angular.forEach(params, function (v, k) {
                        if (scope.filters.hasOwnProperty(k)) {
                            rememberedValues[k] = scope.filters[k];
                        }

                        if(k === 'assessment') {
                            assessmentId = v;
                        }
                    });

                    angular.forEach(scope.filters, function (v, k) {
                        if (angular.isArray(v)) {
                            scope.filters[k] = [];
                        } else {
                            delete scope.filters[k];
                        }
                    });
                    angular.forEach(rememberedValues, function (v, k) {
                        if(assessmentId !== true) {
                            scope.filters[k] = v;
                        }
                    });

                    let values = angular.copy(scope.serviceOptions);
                    angular.forEach(values, function (v, k) {
                        if (('assessments' !== k && !params.hasOwnProperty(k) && angular.isArray(v)) || assessmentId === true) {
                            angular.forEach(v, function (sv) {
                                sv.ticked = false;
                            });
                        }
                    });
                    scope.serviceOptions = values;

                    scope.filterGrid();

                    //reset filter option if no assessment ID pass in
                    if(assessmentId === true) {
                        scope.getServiceOptions();
                    }
                };
                scope.filterGrid = function (xPage, yPage) {
                    scope.queryParams = getDefaultQueryParams();
                    scope.queryParams.xPage = xPage || 1;
                    scope.queryParams.yPage = yPage || 1;
                    angular.forEach(scope.filters, function (v, k) {
                        if (angular.isArray(v)) {
                            if (v.length) {
                                var filterValue = v[0];
                                scope.queryParams[k] = angular.isObject(filterValue) ? filterValue.id : filterValue;
                            }
                        } else {
                            scope.queryParams[k] = v;
                        }
                    });
                    scope.getGridData();
                };

                // Cell forms
                scope.cellCoords = function(xy) {
                    if ('string' === typeof xy && xy.includes(':')) {
                        return xy;
                    }
                    return xy.x + ':' + xy.y;
                };
                scope.cellFormVisibility = [];
                scope.toggleCellForm = function (coordinates) {
                    if (scope.cellFormVisibility[coordinates] === undefined) {
                        scope.cellFormVisibility[coordinates] = {visible: false};
                    }
                    scope.cellFormVisibility[coordinates].visible = !scope.cellFormVisibility[coordinates].visible;
                };
                scope.processCellForm = function (coordinates, form, formData) {
                    console.log(formData);
                    if (!form.$valid) {
                        return;
                    }

                    // Override form
                    if (scope.forcedFormParams) {
                        angular.forEach(scope.forcedFormParams, function (v, k) {
                            formData[k] = v;
                        });
                    }

                    // Submit the form data
                    scope.formProcessing = true;
                    var handleSuccess = function (data) {
                        console.log(data);
                        scope.formProcessing = false;
                        scope.cellFormVisibility[scope.cellCoords(coordinates)].visible = false;
                        scope.getGridData(true);
                    };
                    var handleFailure = function (data) {
                        console.log(data);
                        scope.formProcessing = false;
                    };
                    resourceService[attr.serviceFormMethod](formData).$promise.then(handleSuccess, handleFailure);
                };
            }
        };
    });
