(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:AssessmentpreliminariesCtrl
     * @description
     * # AssessmentpreliminariesCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('AssessmentFixedCostCtrl', function (
            $routeParams,
            $route,
            $rootScope,
            $scope,
            assessment,
            assessmentFixedCost,
            fixedcost,
            TIMETEXT,
            project,
            $filter,
            $locale,
            PermPermissionStore
        ) {
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
            $scope.assessmentId = $routeParams.id;
            $scope.timetext = TIMETEXT;
            $scope.isHub = $route.current.$$route.isHub;
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition($scope.isHub ?
                'canWriteProjectHubPackageAssessments' : 'canWriteProjectsOffsiteAssessments');

            assessment.get({id: $routeParams.id}).$promise.then(function (data) {
                $scope.assessment = data;
                $scope.packageId = data.projectWorkPackage.id;
                $scope.checkText();
                $scope.projectId = data.projectWorkPackage.project.id;
                $rootScope.projectId = data.projectWorkPackage.project.id;
            });

            $scope.updateAssessmentStatus = function(){
                if (!$scope.assessment.fixedCostsStarted && !$scope.assessment.snapshotComment) {
                    // Mark assessment as started
                    assessment.progressSection({id: $scope.assessment.id, section: 'fixedcosts', transition: 'start'});
                }
            };

            $scope.getFixedCosts = function () {
                assessment.getAssessmentFixedCosts({id: $routeParams.id}).$promise.then(function (data) {
                    return $scope.fixedCosts = data;
                }).then(function (data) {
                    // Set prelim total
                    $scope.prelimTotal = 0;
                    angular.forEach(data, function (value) {
                        $scope.prelimTotal += value.assessmentPricePeriodLength * value.assessmentPrice * value.quantity;
                    });
                });
            };

            $scope.getFixedCosts();

            function getFixedCosts() {
                fixedcost.query({limit: 5000}).$promise.then(function (data) {
                    $scope.allFixedCosts = data;
                });
            }

            getFixedCosts();

            $scope.deleteFixedCost = function (data) {
                assessmentFixedCost.delete({id: data}).$promise.then(function () {
                    $scope.getFixedCosts();
                    $scope.assessment.fixedCostsCompleted = null;
                    $scope.checkText();
                    if (!$scope.assessment.fixedCostsStarted) {
                        $scope.updateAssessmentStatus();
                    }
                });
            };

            $scope.data = {};
            $scope.save = function (data) {
                var newData;

                var refreshFixedCosts = function () {
                    $scope.data = {};
                    angular.forEach($scope.allFixedCosts, function (value) {
                        value.ticked = false;
                    });
                    $scope.assessment.fixedCostsCompleted = null;
                    $scope.checkText();
                    $scope.getFixedCosts();
                };

                if (data) {
                    newData = data;
                } else {
                    newData = angular.copy($scope.data);
                    newData.originalFixedCost = newData.originalFixedCost[0];
                }
                newData.assessment = $routeParams.id;
                var saveAssessmentFixedCost = new assessmentFixedCost(newData);
                if (newData.id) {
                    saveAssessmentFixedCost.$update().then(function () {
                        refreshFixedCosts();
                    });
                } else {
                    saveAssessmentFixedCost.$save().then(function () {
                        refreshFixedCosts();
                    });
                }
                if (!$scope.assessment.fixedCostsStarted) {
                    $scope.updateAssessmentStatus();
                }
            };

            $scope.saveForm = function (fixedCostForm) {
                $scope.submitted = true;
                $scope.fixedCostError = $scope.data.originalFixedCost.length < 1;

                if (fixedCostForm.$valid && !$scope.fixedCostError) {
                    $scope.save();
                    $scope.submitted = false;
                    $scope.updateAssessmentStatus();

                }
            };

            $scope.buttonText = ($scope.isHub) ? 'Mark Ready For Site' : 'Mark as complete';
            $scope.checkText = function () {
                if ($scope.assessment.fixedCostsCompleted) {
                    $scope.buttonText = ($scope.isHub) ? 'Ready For Site' : 'Complete';
                    $scope.alreadyComplete = true;
                } else if ($scope.isHub && !$scope.assessment.fixedCostsCompleted) {
                    $scope.buttonText = 'Mark Ready For Site';
                    $scope.alreadyComplete = false;
                }
            };

            $scope.markAsComplete = function () {
                if (!$scope.assessment.fixedCostsCompleted) {
                    assessment.progressSection({
                        id: $scope.assessment.id,
                        section: 'fixedcosts',
                        transition: 'complete'
                    }).$promise.then(function () {
                        $scope.assessment.fixedCostsCompleted = true;
                        $scope.checkText();
                    });
                }
            };

            /* New fixed cost modal */
            $scope.showNewFixedCostModal = function () {
                $scope.newFixedCost = !$scope.newFixedCost;
            };
            $scope.closeFixedCostModal = function () {
                $scope.newFixedCost = !$scope.newFixedCost;
            };
            $rootScope.$on('newFixedCostAdded', function () {
                getFixedCosts();
                $scope.closeFixedCostModal();
            });
        });
})();
