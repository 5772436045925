var zxcvbn = require('zxcvbn');
(function(){
    'use strict';
    angular.module('scswebappApp')
        .factory('zxcvbn', [function() {
            return {
                score: function() {
                    var compute = zxcvbn.apply(null, arguments);
                    return compute && compute.score;
                }
            };
        }])
})();
