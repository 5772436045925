(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.labourskills
     * @description
     * # labourskills
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('SupplyFitQuoteRequest', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/sfquotetemplates/:id', {id: '@id'}, {
                    customSave: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/sfquotetemplates',
                        transformRequest: function(data){

                            var newData = {
                                'name': data.name,
                                'priceRequests': []
                            };

                            if( data.sData && data.sData[0] && data.sData[0].id ){
                                newData.subcontractor = data.sData[0].id;
                            }

                            angular.forEach( data.priceRequests, function(value){
                                newData.priceRequests.push({product: value.material.id});
                            });
                            return angular.toJson(newData);
                        }
                    },
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/sfquotetemplates/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/sfquotetemplates/:id/unarchive'
                    },
                    getPriceRequests: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/sfquotetemplates/:id/pricerequests',
                        isArray: true
                    }
                }
            );
        }]);
})();
