'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.issuecategory
 * @description
 * # issuecategory
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
	.factory('issuecategory', ['Resource', 'ENV', function ( $resource, ENV ) {
		return $resource(
			ENV.apiEndpoint + '/issuetypes/:id', { id: '@id' }, {
				archive: {
					method: 'PATCH',
					url: ENV.apiEndpoint + '/issuetypes/:id/archive'
				},
				unarchive: {
					method: 'PATCH',
					url: ENV.apiEndpoint + '/issuetypes/:id/unarchive'
				}
			},
			'name'  // default sort order
		);
	}]);
