'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:Navigation active state
 * @description
 * # Navigation active state
 * nav-highlight="['/admin']"
 */
angular.module('scswebappApp')
    .directive('navHighlight', function ($location) {
        return {
            restrict: 'A',
            scope: {
                navHighlight: '='
            },
            link: function postLink(scope, element) {
                // Navigation active state
                function highlight(){
                    var location = $location.path().split('/');
                    location.shift();
                    var currentPath = ( location.length > 2 ) ? '/' + location.join('/') + '/' : $location.path();
                    var match = false;
                    angular.forEach(scope.navHighlight, function (highlightOn) {
                        let highlightRe = new RegExp('^' + highlightOn);
                        if (highlightRe.test(currentPath)) {
                            match = true;
                        }
                    });

                    if (match) {
                        element.addClass('active');
                    }else{
                        element.removeClass('active');
                    }
                }

                highlight();

                scope.$on('$routeChangeSuccess', function () {
                    highlight();
                });

            }
        };
    });
