(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.price
	 * @description
	 * # price
	 * Service in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .service('price', ['ENV', 'Resource', 'UNITSTEXT', function (ENV, $resource) {
	        return $resource(
	            ENV.apiEndpoint + '/prices/:id', { id: '@id' }, {
			        getMaterialPrices: {
				        method: 'GET',
				        isArray: true,
				        url: ENV.apiEndpoint + '/materials/:id/prices'
			        },
                    getValidMaterialPrices: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/prices?product=:id&validQuote=true'
                    },
                    archivePrice: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/prices/:id/archive'
                    },
                    unArchivePrice: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/prices/:id/unarchive'
                    }
	            }
	        );

	  }]);
})();