(function(){
    'use strict';

    /**
     * @ngdoc directive
     * @name scswebappApp.directive:dob
     * @description
     * # dob
     * <dob start="today" setDate="2/11/2016" data="quote.quoteDate"></dob>
     */
    angular.module('scswebappApp')
        .directive('dob', function () {
            return {
                template:
                '<select class="d-picker day" name="day" id="day" ng-model="dateObject.day" ng-options="day as day for day in days" ng-change="updateDate()" ng-disabled="isReadOnly">' +
                '</select>' +
                '<select class="d-picker month" name="month" id="month" ng-model="dateObject.month" ng-options="month.id as month.month for month in months" ng-change="updateDate()" ng-disabled="isReadOnly">' +
                '</select>' +
                '<select class="d-picker year" name="year" id="year" ng-model="dateObject.year" ng-options="year as year for year in years" ng-change="updateDate()" ng-disabled="isReadOnly">' +
                '</select>',
                restrict: 'E',
                transclude: true,
                scope: {
                    data: '=',
                    extra: '=',
                    start: '=',
                    time: '=',
                    readonly: '@'
                },
                link: function postLink(scope, element, attrs) {

	                // Split start var if full date
	                var dateSplit = (attrs.setdate) ? attrs.setdate.split('/') : null;

                    scope.extraYears =  (scope.extra ? scope.extra : 0);
                    scope.startYear = (scope.start ? scope.start : 1940);

                    scope.setDay = (attrs.setdate) ? parseInt(dateSplit[0]) : null;
                    scope.setMonth = (attrs.setdate) ? parseInt(dateSplit[1]) : null;
                    scope.setYear = (attrs.setdate) ? parseInt(dateSplit[2]) : null;

                    scope.$watch('readonly', function() {
                        scope.isReadOnly = scope.readonly === "true";
                    });
                    scope.$watch('data', function(newVal){
                        if (typeof newVal !== 'undefined'){
                            scope.process();
                        }
                    });

                    // Create days array based on month
                    scope.monthsDays = function( numberOfDays ){
                        if( !numberOfDays ){
                            numberOfDays = 31;
                        }
                        scope.days = [];
                        for( var i=1 ; i <= numberOfDays; i ++){
                            scope.days.push(i);
                        }
                    };

                    // Moths array
                    scope.months = [
                        {
                            id: 1,
                            month: '1'
                        },
                        {
                            id: 2,
                            month: '2'
                        },
                        {
                            id: 3,
                            month: '3'
                        },
                        {
                            id: 4,
                            month: '4'
                        },
                        {
                            id: 5,
                            month: '5'
                        },
                        {
                            id: 6,
                            month: '6'
                        },
                        {
                            id: 7,
                            month: '7'
                        },
                        {
                            id: 8,
                            month: '8'
                        },
                        {
                            id: 9,
                            month: '9'
                        },
                        {
                            id: 10,
                            month: '10'
                        },
                        {
                            id: 11,
                            month: '11'
                        },
                        {
                            id: 12,
                            month: '12'
                        }
                    ];

                    // Create years array
                    scope.createYearsArray = function(){
                        // Get current Year
                        var d = new Date(),
                            year = d.getFullYear();

                        // Create years array
                        scope.years = [];
                        for (var i = scope.startYear; i <= (year + scope.extraYears); i++) {
                            scope.years.push(i);
                        }

                        scope.updateDate();
                    };

                    scope.updateDate = function() {

                        var months = {
                            1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31
                        };
                        // Set Feb as 29 days if leap year
                        if (scope.dateObject.month === 2 && ((scope.dateObject.year % 4 === 0) && (scope.dateObject.year % 100 !== 0)) || scope.dateObject.month === 2 && (scope.dateObject.year % 400 === 0)) {
                            scope.monthsDays(29);
                        } else {
                            scope.monthsDays(months[scope.dateObject.month]);
                        }

                        scope.data = scope.dateObject.year + '-' + scope.dateObject.month + '-' + scope.dateObject.day;

                        if (scope.time){
                            scope.data += ' 00:00:00';
                        }

                    };

	                var firstPass = true;
                    scope.process = function() {

                        if (!scope.data) {

                            scope.dateObject = {
                                year: scope.startYear,
                                day: 1,
                                month: 1
                            };

                            scope.createYearsArray();

                        } else {

                            var dateArray;

                            if (scope.data.indexOf('T') > -1) {
                                dateArray = scope.data.split('T')[0].split('-');
                            } else {
                                dateArray = scope.data.split('-');
                            }

                            scope.dateObject = {};
                            scope.dateObject.year = (scope.setYear && firstPass) ? scope.setYear : parseInt(dateArray[0], 10);
                            scope.dateObject.month = (scope.setMonth && firstPass) ? scope.setMonth + 1 : parseInt(dateArray[1], 10);
                            scope.dateObject.day = (scope.setDay && firstPass) ? scope.setDay : parseInt(dateArray[2], 10);

	                        firstPass = false;

                            scope.createYearsArray();
                        }
                    };

                    scope.process();

                }
            };
        });
})();
