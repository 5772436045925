'use strict';

angular.module('scswebappApp')
    .directive('okPassword', ['zxcvbn', function(zxcvbn) {
        return {
            // restrict to only attribute and class
            restrict: 'AC',

            // use the NgModelController
            require: 'ngModel',
            // add the NgModelController as a dependency to your link function
            link: function($scope, $element, $attrs, ngModelCtrl) {
                $scope.password_strength = 0;
                $element.on('blur change keydown keyup', function(e) {
                    $scope.$evalAsync(function($scope) {
                        // update the $scope.password with the element's value
                        var pwd = $scope.password = $element.val();

                        // resolve password strength score using zxcvbn service
                        $scope.password_strength = pwd ? (pwd.length > 7 && zxcvbn.score(pwd) || 0) : null;

                        // define the validity criterion for okPassword constraint
                        ngModelCtrl.$setValidity('okPassword', $scope.password_strength > 2);
                    });
                });
            }
        };
    }]);
