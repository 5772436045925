'use strict';

function refactorUserData(data) {
    var userData = {
        'username': data.email,
        'email': data.email,
        'person': {
            'firstName': data.person.firstName,
            'lastName': data.person.lastName,
            'dob': data.person.dob
        },
        'roles': [],
        'projectAccessOption': data.projectAccessOption,
        'projectAccessProjects': [],
        'forcePassword': data.forcePassword ? data.forcePassword : null,
        'plainPassword': data.plainPassword ? data.plainPassword : null,
        'colour': data.colour ? data.colour : `#${Math.floor(Math.random()*16777215).toString(16)}`
    };

    angular.forEach(data.projectAccessProjects, function(project){
        userData.projectAccessProjects.push(project.id);
    });

    angular.forEach(data.roles, function (value) {
        userData.roles.push(value.id);
    });
    return userData;
}

angular.module('scswebappApp')
    .service('user', ['Resource', 'ENV', function User($resource, ENV) {
        return $resource(
            ENV.apiEndpoint + '/users/:username', { username: '@username', role: '@role', id: '@id' }, {
                'me': {
                    url: ENV.apiEndpoint + '/users/me'
                },
                getRoles : {
                    method: 'GET',
                    isArray: true,
                    url: ENV.apiEndpoint + '/roles'
                },
                canEstimate: {
                    method: 'GET',
                    isArray: true,
                    url: ENV.apiEndpoint + '/users/can-estimate'
                },
                getNotifications : {
                    method: 'GET',
                    isArray: true,
                    url:  ENV.apiEndpoint +  '/notifications'
                },
                delete: {
                    url:  ENV.apiEndpoint + '/users/:id',
                    method: 'DELETE'
                },
                resetPassword: {
                    url:  ENV.apiEndpoint + '/users/passwordreset/:token',
                    method: 'PUT'
                },
                create: {
                    method:'POST',
                    url: ENV.apiEndpoint + '/users',
                    transformRequest: function (data) {
                        return angular.toJson( refactorUserData(data) );
                    }
                },
                update: {
                    method:'PATCH',
                    url: ENV.apiEndpoint + '/users/:id',
                    transformRequest: function (data) {
                        return angular.toJson( refactorUserData(data) );
                    }
                },
                updatePassword: {
                    method:'PATCH',
                    url: ENV.apiEndpoint + '/users/:id?groups=permissions'
                },
                archive:{
                    method: 'PATCH',
                    url: ENV.apiEndpoint + '/users/:id/archive'
                },
                unarchive:{
                    method: 'PATCH',
                    url: ENV.apiEndpoint + '/users/:id/unarchive'
                }
            },
            'personLastName'
        );
    }]);
