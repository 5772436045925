(function(){
	'use strict';

	/**
	 * @ngdoc function
	 * @name scswebappApp.controller:NewFixedCostCtrl
	 * @description
	 * # NewFixedCostCtrl
	 * Controller of the scswebappApp
	 */
	angular.module('scswebappApp')
		.controller('FixedCostCtrl', function ($scope, $routeParams, fixedcost, $rootScope, $location, TIMEUNITS, routeType, $route, UNITSTEXT, period) {

			$scope.units = UNITSTEXT;

			// Get trade data if edit or view routes
			if ( $routeParams.id ){
                fixedcost.get({id: $routeParams.id}).$promise.then( function (data) {
                    $scope.fixedcost = data;
				});
			}

			// Set type keyword for edit and new routes
			$scope.keyWord = routeType.type( $route.current.$$route.isNew, $route.current.$$route.isEdit );

			$scope.timeUnits = TIMEUNITS;
		});
})();
