'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:newFixedCost
 * @description
 * # newFixedCost
 */
angular.module('scswebappApp')
	.directive('newFixedCost', function (fixedcost, $route, $rootScope, $location, $routeParams, UNITSTEXT, TIMEUNITS, FileUploader, $locale) {
		return {
		    template: require('./new-fixed-cost.html'),
			restrict: 'E',
			scope: {},
			link: function postLink(scope, element, attrs) {

                scope.units = UNITSTEXT;

                scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

                if ( $routeParams.id && !attrs.new ){
                    fixedcost.get({id: $routeParams.id}).$promise.then( function (data) {
                        scope.fixedcost = data;
                        scope.formatValues();
                    });
                }else{
	                scope.fixedcost = {
		                externalLinks: [],
		                dataSheets: []
	                }
                }

				// File upload
				scope.fileUpload = {
					uploader: new FileUploader
				};

				// Add Link
				scope.addLink = function(){
					scope.fixedcost.externalLinks.push({label: '', url: ''});
				};
				// Remove Link
				scope.removeLink = function(index){
					scope.fixedcost.externalLinks.splice(index, 1);
				};

                scope.timeUnits = TIMEUNITS;
                scope.timeUnits = Object.entries(scope.timeUnits).map(([value, name]) => ({ name, value }));

                scope.formatValues = function () {
                    const found = scope.timeUnits.find(e => e.value === scope.fixedcost.period);
                    if (found) {
                        found.ticked = true;
                    }
                }


                scope.saveForm = function () {

                    scope.submitted = true;
                    if (scope.fixedcost.period[0]){
                        scope.fixedcost.period = scope.fixedcost.period[0].value;
                    }

                    if (scope.form.$valid) {

                        let saveObj = new fixedcost(scope.fixedcost);

                        saveObj.$save().then(function(data){
                            scope.form.$setPristine();
                            if( attrs.new ){
                                $rootScope.$emit('newFixedCostAdded', data);
                                scope.submitted = false;
                                scope.fixedcost = {};
                            }else{
                                $location.path('/fixed-costs');
                            }
                        });

                    }
                };

                scope.deleteFile = function (i){
                    scope.fixedcost.dataSheets.splice(i, 1);
                };


			}
		};
	});
