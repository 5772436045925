import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SimpleModalComponent} from "ngx-simple-modal";

import {NgForm} from "@angular/forms";
import {Supplier, SupplierService} from "../chalkstring-api/services/supplier.service";
import {ProductOrder} from "../chalkstring-api/services/product-order.service";

export class ChangeSupplierModel
{
    materialOrder: ProductOrder;
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content modal-content-size-m">
            <form #changeSupplierForm="ngForm" (ngSubmit)="submitForm(changeSupplierForm)">
                <div class="modal-header">
                    <h2>
                        Change Supplier
                    </h2>
                </div>
                <div class="modal-body">
                    <p class="note-with-icon">Please select the supplier this order should change to. The cheapest valid price for this supplier will be selected automatically. Where no price is available a guesstimate of the current price will be created.</p>
                    <fieldset>
                        <ng-select name="supplier" [items]="suppliers" placeholder="Please select…" bindLabel="fullName" bindValue="id" [(ngModel)]="selectedSupplierId" required></ng-select>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn inline" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button type="submit" class="btn inline" [disabled]="changeSupplierForm.submitted || !changeSupplierForm.valid"><i class="icon-ok"> Change supplier</i></button>
                </div>
            </form>
        </div>
    `,
})
export class ChangeSupplierModalComponent extends SimpleModalComponent<ChangeSupplierModel, boolean> implements ChangeSupplierModel
{
    materialOrder: ProductOrder;
    suppliers: Supplier[];
    selectedSupplierId: number;

    constructor(private _http: HttpClient, _sService: SupplierService)
    {
        super();
        _sService.getAllSimple().subscribe(suppliers => {
            this.suppliers = suppliers;
        });
    }

    submitForm(form: NgForm)
    {
        this._http.post(
            '/materialorders/' + this.materialOrder.id + '/supplier',
            {
                supplier: this.selectedSupplierId
            }
        ).subscribe(res => {
            this.result = true;
            this.close();
        });
    }

}
