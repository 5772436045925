import {CsUtils} from '../../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('HubFixedCostCtrl', function (
            $scope,
            $rootScope,
            $routeParams,
            project,
            account,
            PermPermissionStore,
            $route,
            $location,
            $http,
            ENV,
            $window,
            $httpParamSerializer,
            TIMEUNITS,
            fixedcost,
            fixedCostOrders,
            operative,
            supplier,
            organisation,
            FileUploader,
            $locale,
            assessment
        ) {

            var $ = require('jquery');

            $scope.accountsIntegrationEnabled = PermPermissionStore.hasPermissionDefinition('canReadAccountsNominalCodes');

            $scope.timeUnits = TIMEUNITS;
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            if ($routeParams.projectId) {
                $rootScope.projectId = $routeParams.projectId;
                project.get({id: $routeParams.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });
            }

            $scope.addressToString = function (address) {
                var pieces = [];
                angular.forEach(['street1', 'street2', 'city', 'county', 'postcode', 'country'], function (f) {
                    if (address[f]) {
                        pieces.push(address[f]);
                    }
                });
                return pieces.join(', ');
            };

            $scope.orderForm = {};
            $scope.saveOrder = function(){
                if ($scope.orderForm.orderSupplier[0].id){
                    $scope.order.supplier = $scope.orderForm.orderSupplier[0];
                }
                $scope.order.$save().then(function (data) {
                    $location.path('/project-hub/fixed-cost-order/' + data.id);
                });
            };

            if ($route.current.$$route.isConfirmOrder) {
                // Default date
                var d = new Date();
                d.setDate(d.getDate() + 1); // Tomorrow

                // Form
                $scope.confirmation = {
                    id: $routeParams.id,
                    expectedDeliveryDate: d,
                    supplier: [],
                    siteDeliveryAddress: {},
                    siteDeliveryOperative: null,
                    siteDeliveryTelephoneNumber: null
                };

                // Load the FC order
                fixedCostOrders.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.order = data;

                    // Project
                    $rootScope.projectId = data.project.id;
                    $scope.confirmation.siteDeliveryAddress = data.project.siteDeliveryAddress ? data.project.siteDeliveryAddress : {};
                    $scope.confirmation.siteDeliveryOperative = data.project.siteDeliveryOperative;
                    $scope.confirmation.siteDeliveryTelephoneNumber = data.project.siteDeliveryTelephoneNumber;
                    $scope.confirmation.supplier[0] = data.supplier;

                    $scope.siteContactSelected = function(siteContact) {
                        $scope.confirmation.siteDeliveryOperative = siteContact;
                        $scope.showOperativeTelephoneNumbers(siteContact);
                        $scope.selectSiteContactModal.close();
                    }
                    $scope.selectSiteContact = function() {
                        $scope.selectSiteContactModal.open();
                    }

                    $scope.operativeTelephoneNumber = [];
                    if ($scope.order.project.siteDeliveryOperative) {
                        $scope.operativeTelephoneNumbers = $scope.order.project.siteDeliveryOperative.telephoneNumbers;
                    }
                    $scope.siteDeliveryTelephoneNumber = $scope.order.project.siteDeliveryTelephoneNumber;
                    $scope.siteDeliveryOperative = $scope.order.project.siteDeliveryOperative;

                    // When an operative is picked, display their numbers
                    $scope.showOperativeTelephoneNumbers = function (data) {
                        if (!data.telephoneNumbers) {
                            return;
                        }
                        $scope.operativeTelephoneNumbers = data.telephoneNumbers;
                        if ($scope.operativeTelephoneNumbers.length) {
                            // Automatically default to the first in the list
                            $scope.confirmation.siteDeliveryTelephoneNumber = $scope.operativeTelephoneNumbers[0];
                        }
                    };

                    $scope.selectSupplier = function () {
                        var selectedSupplier = $scope.confirmation.supplier[0];
                        supplier.projectDefaultContact({
                            id: selectedSupplier.id,
                            projectId: $rootScope.projectId
                        }).$promise.then(function (data) {
                            if (data.id) {
                                $scope.dpscTelephoneNumber = data.telephoneNumber;
                                $scope.dpscContact = data.contact;
                            }
                        });

                        // Get the supplier & the supplier telephone numbers
                        organisation.get({id: selectedSupplier.id}).$promise.then(function (data) {
                            $scope.supplier = data;
                            var tns = data.telephoneNumbers;
                            angular.forEach(data.children, function (child) {
                                angular.forEach(child.telephoneNumbers, function (tn) {
                                    tn.detail = child.fullName + ' (' + tn.detail + ')';
                                    tns.push(tn);
                                });
                            });
                            $scope.supplierTelephoneNumbers = tns;
                        });
                    };

                    $scope.selectSupplier();

                    // Confirm the order
                    $scope.confirmOrder = function () {
                        // Address validation
                        $scope.addressInvalid = (!$scope.confirmation.siteDeliveryAddress.street1 && !$scope.confirmation.siteDeliveryAddress.street2 && !$scope.confirmation.siteDeliveryAddress.street3 && !$scope.confirmation.siteDeliveryAddress.city && !$scope.confirmation.siteDeliveryAddress.county && !$scope.confirmation.siteDeliveryAddress.postcode && !$scope.confirmation.siteDeliveryAddress.country);

                        // Supplier validation
                        $scope.supplierInvalid = (!$scope.confirmation.supplier || !$scope.confirmation.supplier.length);
                        if ($scope.addressInvalid || $scope.supplierInvalid) {
                            return false;
                        }
                        var deliveryAddress = $scope.confirmation.siteDeliveryAddress;
                        // Submit the form data
                        fixedCostOrders.confirmOrder(
                            {
                                id: $routeParams.id,
                                supplier: $scope.confirmation.supplier.length ? $scope.confirmation.supplier[0].id : null,
                                expectedDeliveryDate: $scope.confirmation.expectedDeliveryDate,
                                deliveryAddress: {
                                    street1: deliveryAddress.street1,
                                    street2: deliveryAddress.street2,
                                    city: deliveryAddress.city,
                                    county: deliveryAddress.county,
                                    postcode: deliveryAddress.postcode,
                                    country: deliveryAddress.country,
                                },
                                siteDeliveryOperative: $scope.confirmation.siteDeliveryOperative ? $scope.confirmation.siteDeliveryOperative.id : null,
                                siteDeliveryTelephoneNumber: $scope.confirmation.siteDeliveryTelephoneNumber ? $scope.confirmation.siteDeliveryTelephoneNumber.id : null,
                                defaultProjectSupplierContact: $scope.confirmation.dpscContact ? $scope.confirmation.dpscContact.id : null,
                                defaultProjectSupplierTelephoneNumber: $scope.confirmation.dpscTelephoneNumber ? $scope.confirmation.dpscTelephoneNumber.id : null
                            },
                            function (data) {
                                $location.path('/project-hub/fixed-cost-order/' + data.id);
                            }
                        );
                    };

                    fixedCostOrders.getPreviousAddresses({id: $rootScope.projectId}).$promise.then(function (addresses) {
                        $scope.previousAddresses = addresses;
                    });
                    $scope.setDeliveryAddress = function (address) {
                        $scope.order.project.siteDeliveryAddress.street1 = address.street1;
                        $scope.order.project.siteDeliveryAddress.street2 = address.street2;
                        $scope.order.project.siteDeliveryAddress.city = address.city;
                        $scope.order.project.siteDeliveryAddress.county = address.county;
                        $scope.order.project.siteDeliveryAddress.postcode = address.postcode;
                        $scope.order.project.siteDeliveryAddress.country = address.country;
                    };
                });

            } else if ($route.current.$$route.isList) {
                // List page
                $scope.pageTitle = $route.current.$$route.pageTitle;
                $scope.orderTableServiceParams = {
                    'orderCategory': 'fixedCost',
                    'project': $rootScope.projectId,
                    'stateStatus[]': $route.current.$$route.orderStates
                };
                $scope.orderTableStates = $route.current.$$route.orderStates;

            } else if ($route.current.$$route.isBill || $route.current.$$route.isProgress) {
                $scope.selectFixedCostModal = {};
                $scope.addContingencyFixedCostModal = {};
                $scope.contingencyForm = {};
                $scope.contingencyFixedCostTableParams = {};
                // When a user selects a contingency material to add...
                $scope.fixedCostSelected = function (item) {
                    $scope.selectFixedCostModal.close();
                    $scope.contingencyFixedCost = item;
                    $scope.contingencyFixedCostRate = item.pricePerPeriod;
                    $scope.contingencyForm.originalFixedCost = item.id;
                    $scope.contingencyForm.profitPerPeriod = item.profitPerPeriod;
                    $scope.addContingencyFixedCostModal.open();
                };
                $rootScope.$on('newFixedCostAdded', function (event, item) {
                    $scope.newFixedCostModal.close();
                    $scope.fixedCostSelected(item);
                });
                $scope.submitted = false
                $scope.addContingencyFixedCost = function () {
                    $scope.submitted = true;
                    if ($scope.contingencyForm.assessment){
                        assessment.addContingencyFixedCost({id: $scope.contingencyForm.assessment}, $scope.contingencyForm).$promise.then(function(){
                            getFixedCosts();
                        });
                        $scope.addContingencyFixedCostModal.close();
                    }
                };
                $scope.removeContingencyFixedCost = function (assessmentId, contingencyFixedCostId) {
                    assessment.removeContingencyFixedCost({id: assessmentId}, {originalFixedCost: contingencyFixedCostId, profitPerPeriod: 0}).$promise.then(function(){
                        getFixedCosts();
                    });
                };

                $scope.selectedAssessmentId = '';
                $scope.selectedAssessment = function(data){
                    $scope.selectedAssessmentId = data.id;
                };

                $scope.exportFixedCostBill = function () {
                    let url = '/fixed-costs/' + $routeParams.projectId + '/fixed-cost-bill-export/'  + $scope.selectedAssessmentId;
                    console.log($scope.fixedCosts);
                    $http({
                        method: 'GET',
                        responseType:'arraybuffer',
                        url: ENV.apiEndpoint + url
                    }).success(function(data, status, headers) {
                        CsUtils.download(data, headers, $window);
                    });
                };

                // Query for table
                $scope.queryString = {
                    projectId: $routeParams.projectId,
                    limit: 30,
                    page: 1
                };

                // Get package data for filters
                $scope.filtersValid = false;
                fixedcost.filters({projectId: $routeParams.projectId}).$promise.then(function (filters) {
                    $scope.filterArray = filters;
                });

                // Fetch data from service
                $scope.fixedCosts = [];
                var getFixedCosts = function () {
                    $scope.filtersValid = false;

                    if ($scope.filter) {
                        angular.forEach($scope.filter, function (value, key) {
                            $scope.queryString[key] = value;
                        });
                    }

                    if (!$scope.queryString['assessment']) {
                        $scope.filtersValid = false;
                        $scope.fixedCosts = [];
                    } else {
                        $scope.filtersValid = true;

                        fixedcost.fixedCostBill($scope.queryString, function (data, header) {
                            $scope.fixedCosts = data;
                            var objectCount = header()['x-total-count'];
                            $scope.numPages = Math.ceil(objectCount / $scope.queryString.limit);
                            $scope.pagesArray = [];
                            for (var i = 0; i < $scope.numPages; i++) {
                                $scope.pagesArray.push(i + 1);
                            }
                        });
                        if (!$scope.supplierOptions) {
                            // Get the supplier options
                            fixedCostOrders.supplierOptions().$promise.then(function (data) {
                                $scope.supplierOptions = data;
                            });
                        }
                    }
                };
                getFixedCosts();

                $scope.changePage = function (page) {
                    if (page !== 0 && page !== $scope.pagesArray.length + 1) {
                        $scope.queryString.page = page;
                        getFixedCosts();
                    }
                };

                // Scroll table
                $(function () {
                    $('.scroll-table > div').scroll(function () {
                        // Horizontal scroll
                        $scope.tableHeaderOffset = $('.scroll-table > div > table').position().left;
                        $scope.tableColOffset = $('.scroll-table > div > table').position().top;
                        $scope.$apply();
                    });
                });

                $scope.orderFormVisibilty = [];
                $scope.showOrderForm = function (fixedCostIndex) {
                    $scope.fixedCosts[fixedCostIndex].visible = !$scope.fixedCosts[fixedCostIndex].visible;
                };
                $scope.filterFixedCosts = function (reset, data) {
                    if (reset) {
                        $scope.filter = {};
                        $scope.queryString = {
                            projectId: $routeParams.projectId,
                            limit: 30,
                            page: 1
                        };
                        angular.forEach($scope.filterArray.assessments, function (value) {
                            value.ticked = false;
                        });
                    } else {
                        angular.forEach(data, function (value, key) {
                            $scope.queryString[key] = value;
                        })
                    }

                    if (data && data.assessment !== undefined) {
                        fixedcost.filters({
                            projectId: $routeParams.projectId,
                            assessment: data.assessment
                        }).$promise.then(function (filters) {
                            $scope.filterArray = filters;
                            angular.forEach($scope.filterArray.assessments, function (value) {
                                if (value.id === data.assessment) {
                                    value.ticked = true;
                                }
                            });
                        });
                    }
                    getFixedCosts();
                };
                $scope.createOrder = function (orderForm, order, fixedCostIndex) {

                    $scope.submitted = true;
                    if (orderForm.$valid) {
                        var lineItems = angular.copy(order);
                        if ($scope.accountsIntegrationEnabled) {
                            lineItems.nominalCode = lineItems.nominalCode.nominalCode;
                        }
                        var supplierId = order.supplier.id;
                        delete lineItems.supplier;
                        var orderData = {
                            project: $routeParams.projectId,
                            supplier: supplierId,
                            fixedCostOrderItems: [
                                lineItems
                            ]
                        };
                        fixedCostOrders.saveOrder(null, orderData).$promise.then(function () {
                            getFixedCosts();
                            $scope.showOrderForm(fixedCostIndex);
                            $scope.submitted = false;
                        });
                    }
                };

                $scope.saveCompleted = function(index){
                    if ($scope.fixedCosts[index].completedQuantity === null || $scope.fixedCosts[index].completedQuantity === ''){
                        $scope.fixedCosts[index].completedQuantity = $scope.fixedCosts[index].oldCompletedQuantity;
                        return;
                    }
                    fixedcost.myUpdate({id: $scope.fixedCosts[index].assessmentFixedCost_id}, {completedQuantity:$scope.fixedCosts[index].completedQuantity });
                };

            } else if ($route.current.$$route.isReceiveInvoice) {
                // Receive Invoice
                fixedCostOrders.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.order = data;
                    $scope.project = data.project;
                    $rootScope.projectId = data.project.id;

                    // Set up form
                    var form = {invoiceQuantities: {}};
                    angular.forEach(data.fixedCostOrderItems, function (fcoi) {
                        form.invoiceQuantities[fcoi.id] = {quantityInvoiced: 0, invoicedPrice: fcoi.orderPrice};
                    });

                    // Default delivery date
                    form.dateInvoiced = new Date();

                    $scope.form = form;

                    // Update all inputs to match required pack numbers
                    $scope.fulfillQuantities = function () {
                        angular.forEach(data.fixedCostOrderItems, function (fcoi, i) {
                            $scope.form.invoiceQuantities[fcoi.id].quantityInvoiced = fcoi.orderQuantity - fcoi.quantityInvoiced;
                        });
                    };

                    // Attachment uploader
                    $scope.attachmentUploader = new FileUploader();
                    $scope.attachmentsList = [];
                    $scope.attachments = {
                        uploader: $scope.attachmentUploader,
                        list: $scope.attachmentsList,
                        type: 'attachments',
                        uploadurl: '/files'
                    };

                    // Submit new invoice
                    $scope.receiveInvoice = function () {
                        $scope.form.id = $routeParams.id;
                        $scope.form.attachments = $scope.attachmentsList;
                        fixedCostOrders
                            .receiveInvoice($scope.form, function (data) {
                                $location.path('/project-hub/fixed-cost-order/' + data.id);
                            });
                    };
                })

            } else if ($routeParams.id) {
                // Single order view page
                fixedCostOrders.get({id: $routeParams.id, groups: 'assessmentItem_assessment'}).$promise.then(function (data) {
                    $scope.order = data;
                    $scope.project = data.project;
                    $rootScope.projectId = data.project.id;

                    // Invoices
                    $scope.invoiceTableParams = {orderId: data.id};

                    if ($route.current.$$route.isEdit) {
                        // Get the supplier options
                        fixedCostOrders.supplierOptions().$promise.then(function (supplierOptionsData) {
                            // Map Suppliers
                            angular.forEach(supplierOptionsData, function (value) {
                                if ($scope.order.supplier && value.id === $scope.order.supplier.id) {
                                    value.ticked = true;
                                }
                            });
                            $scope.supplierOptions = supplierOptionsData;
                        });
                    }
                });

                $scope.cancel = function () {
                    fixedCostOrders.cancelOrder({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.order = data;
                    })
                };
                $scope.complete = function(){
                    fixedCostOrders.completeOrder({id: $routeParams.id}).$promise.then(function(data){
                        $scope.order = data;
                    })
                };
                $scope.send = function () {
                    fixedCostOrders.sendOrder({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.order = data;
                    })
                };
                $scope.print = function () {
                    // if(!$scope.order.hasGuesstimates && !$scope.order.hasGuesstimates){
                    //     sendOrder();
                    // }else {
                    //     $scope.$broadcast('showModal', $scope.warningModal);
                    // }
                };
                $scope.markAsInvoiced = function () {
                    fixedCostOrders.markAsInvoiced({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.order = data;
                    })
                };

                // Invoiced orders
                $scope.payForm = {};
                $scope.queryForm = {};
                $scope.resolutionForm = {};
                $scope.approvePayment = function () {
                    $scope.submitted = true;
                    if (!$scope.payForm.approvedForPayment) {
                        $scope.payError = true;
                    } else {
                        fixedCostOrders.approvePayment({id: $routeParams.id}, {approvedForPayment: $scope.payForm.approvedForPayment}).$promise.then(function (data) {
                            $scope.payError = false;
                            $scope.order = data;
                        });
                    }
                };
                $scope.queryInvoice = function () {
                    $scope.submitted = true;
                    if (!$scope.queryForm.queryNotes) {
                        $scope.queryError = true;
                    } else {
                        fixedCostOrders.queryInvoice({id: $routeParams.id}, {queryNotes: $scope.queryForm.queryNotes}).$promise.then(function (data) {
                            $scope.queryError = false;
                            $scope.order = data;
                        });
                    }
                };
                $scope.resolveQuery = function () {
                    $scope.submitted = true;
                    if (!$scope.resolutionForm.queryResolutionNotes) {
                        $scope.resolutionError = true;
                    } else {
                        fixedCostOrders.resolveQuery({id: $routeParams.id}, {queryResolutionNotes: $scope.resolutionForm.queryResolutionNotes}).$promise.then(function (data) {
                            $scope.resolutionError = false;
                            $scope.order = data;
                        });
                    }
                };
                $scope.printOrder = function () {
                    $http({
                        method: 'GET',
                        responseType: 'arraybuffer',
                        url: ENV.apiEndpoint + '/fixedcostorders/' + $routeParams.id + '/export'
                    }).success(function (data, status, headers) {
                        CsUtils.download(data, headers, $window);
                    });
                };
                $scope.downloadOrder = function () {
                    $http({
                        method: 'GET',
                        responseType: 'arraybuffer',
                        url: ENV.apiEndpoint + '/fixedcostorders/' + $routeParams.id + '/export'
                    }).success(function (data, status, headers) {
                        CsUtils.download(data, headers, $window);

                        $scope.$broadcast('closeModal');
                    });
                };
                $scope.downloadForm = {};
                $scope.saveNotesAndDownloadOrder = function () {
                    if ($scope.downloadForm.orderNotes !== $scope.order.orderNotes) {
                        // Update first
                        fixedCostOrders.patchOrder({id: $routeParams.id}, {orderNotes: $scope.downloadForm.orderNotes}).$promise.then(function (data) {
                            $scope.downloadModal.close();
                            $scope.order.orderNotes = $scope.downloadForm.orderNotes;
                            $scope.downloadOrder();
                        });
                    } else {
                        // No change
                        $scope.downloadModal.close();
                        $scope.downloadOrder();
                    }
                };
            }
        });
})();
