(function () {
    'use strict';

    angular.module('scswebappApp')
        .filter('replace', function () {
            return function (input, replacements) {
                if (input === undefined) {
                    return;
                }

                if (Array.isArray(replacements)){
                    angular.forEach(replacements, function (thing) {
                        var regex = new RegExp(thing.regex, 'gi');
                        input = input.toString().replace(regex, thing.replacement);
                    });
                } else {
                    angular.forEach(replacements, function (replacement, search) {
                        input = input.toString().replace(search, replacement);
                    });
                }
                return input;
            };
        });
})();