import {Component} from '@angular/core';
import {SimpleModalComponent, SimpleModalService} from "ngx-simple-modal";
import {AssessmentProduct, AssessmentProductService} from "../../chalkstring-api/services/assessment-product.service";
import {CurrencyUtils} from "@generic/models/currency.model";
import {NgForm} from "@angular/forms";
import { first } from 'rxjs/operators';
import {Assessment} from 'app/modules/chalkstring-api/services/assessment.service';
import {RateBuildUpIssueService} from "../issues/rate-build-up-issue.service";

export interface EditAssessmentProductModel
{
    onSite: boolean;
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
}

class ProductPricingFormModel
{
    fixMode: 'unfixed' | 'fixed' = 'unfixed';
    cavsMode: 'contract' | 'variation' = 'variation';
    clientHidden: boolean = false;
    fixedAdjustedPrice: number;
    tenderedPrice: number;
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content" id="modal-ap-pricing-modes">
            <form #productPricingForm="ngForm" (ngSubmit)="onSubmit(productPricingForm)">
                <div class="modal-header">
                    <h2>Update product pricing</h2>
                </div>
                <div class="modal-body">
                    <dl class="stacked highlighted-labels">
                        <dt>Product
                        </dt>
                        <dd>{{assessmentProduct.name}}</dd>
                        <ng-container *ngIf="productIsTendered() && assessmentProduct.tenderedPrice !== assessmentProduct.assessmentPrice">
                            <dt>Tendered rate</dt>
                            <dd>{{assessmentProduct.tenderedPrice | localeCurrency}}</dd>
                        </ng-container>
                        <dt>{{productIsTendered() && assessmentProduct.tenderedPrice !== assessmentProduct.assessmentPrice ? 'Effective' : 'Current'}}
                            rate
                        </dt>
                        <dd>{{assessmentProduct.assessmentPrice | localeCurrency}}</dd>
                        <dt>Cost</dt>
                        <dd>{{assessmentProduct.estimate.totalCost | localeCurrency}}</dd>
                        <dt></dt>
                        <dd>per {{assessmentProduct.quantityUnit | quantityUnit}}</dd>
                    </dl>
                    <fieldset>
                        <label for="ppmMode">Pricing mode</label>
                        <select id="ppmMode"
                                [(ngModel)]="formModel.fixMode"
                                (ngModelChange)="fixModeChanged($event)"
                                name="fixMode"
                                [disabled]="fixModeForced()"
                        >
                            <option value="fixed">Fixed</option>
                            <option value="unfixed">Unfixed</option>
                        </select>

                        <ng-container *ngIf="'fixed' === formModel.fixMode && !fixModeForced()">
                            <div class="badge block block-info" *ngIf="isLumpSumMode() && 'contract' === formModel.cavsMode">
                                <i class="icon-info"></i> Contract scope products must have a fixed price equal to their
                                tendered rate in lump sum mode.</div>
                            <div class="badge block block-warning">
                                Warning: Fixing this price will override any profit value
                                that may have already been set, and may affect your margins.</div>
                            <label for="ppmFixedPriceValue">Fixed price</label>
                            <div class="icon-input icon-left">
                                <div>{{currencySymbol}}</div>
                                <input id="ppmFixedPriceValue"
                                       name="price"
                                       [(ngModel)]="formModel.fixedAdjustedPrice"
                                       required step="0.01"
                                       [disabled]="fixModeForced()"
                                       string-to-number type="number"/>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="onSite">
                            <ng-container *ngIf="'contract' === formModel.cavsMode">
                            <label for="ppmTenderedPrice">Tendered rate</label>
                                <div class="badge block block-warning">
                                    Warning: Changes to tendered rate will show in applications for payment. Only change
                                this value if you have made a mistake when setting up the contract.</div>
                            <div class="icon-input icon-left">
                                <div>{{currencySymbol}}</div>
                                <input id="ppmTenderedPrice" name="tenderedPrice"
                                       [(ngModel)]="formModel.tenderedPrice"
                                       [disabled]="formModel.clientHidden"
                                       (ngModelChange)="tenderedPriceChanged($event)"
                                       required step="0.01"
                                       string-to-number type="number"/>
                            </div>
                            </ng-container>
                        </ng-container>

                        <label *ngIf="canHideProduct()" class="checkbox-container margin-bottom">
                            <input type="checkbox" [(ngModel)]="formModel.clientHidden" name="clientHidden" (ngModelChange)="clientHiddenToggle()">
                            <span class="checkbox"></span>Hide from applications
                        </label>
                        <div class="badge block block-info" *ngIf="formModel.clientHidden" [ngClass]="{'block-info': 'variation' === formModel.cavsMode, 'block-warning': 'contract' === formModel.cavsMode}">
                            <i *ngIf="'variation' === formModel.cavsMode" class="icon-info"></i> <span *ngIf="'contract' === formModel.cavsMode">Warning: </span>Hiding a product from applications will fix its price <span *ngIf="'contract' === formModel.cavsMode">and tendered rate </span>to zero
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="close()"><i class="icon-cancel"></i>
                        Cancel
                    </button>
                    <button type="submit" class="btn" [disabled]="!productPricingForm.form.valid"><i
                            class="icon-ok"></i> Save changes
                    </button>
                </div>
            </form>
        </div>
    `,
    styles: ['.checkbox-container { margin-top: 0.8rem; width: 45% }']
})
export class EditAssessmentProductPricingModesModalComponent extends SimpleModalComponent<EditAssessmentProductModel, AssessmentProduct> implements EditAssessmentProductModel
{
    onSite: boolean;
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
    formModel: ProductPricingFormModel;
    currencySymbol: string;

    constructor(private _apService: AssessmentProductService, private _modalService: SimpleModalService, private _riService: RateBuildUpIssueService)
    {
        super();
        this.formModel = new ProductPricingFormModel();
        this.currencySymbol = CurrencyUtils.getCurrencySymbol();
    }

    fixModeForced(): boolean
    {
        // Force a fixed price when hidden from clients, or an onsite lump sum contract scope product
        return this.formModel.clientHidden ||
            (this.isLumpSumMode() && 'contract' === this.formModel.cavsMode && this.onSite);
    }

    /**
     * Can only hide when:
     * onSite,
     * not already applied for,
     * and not in a lump sum assessment once the effective rate is different from the tendered rate
     */
    canHideProduct(): boolean
    {
        return this.onSite &&
            !(this.isLumpSumMode() && 'contract' === this.formModel.cavsMode && this.hasAdjustedPriceDifferentFromTenderedPrice) &&
            !this.assessmentProduct.hasOutgoingApplicationItems;
    }

    mapDataObject(data: EditAssessmentProductModel)
    {
        super.mapDataObject(data);
        this.formModel.fixMode = null === data.assessmentProduct.fixedAdjustedPrice ? 'unfixed' : 'fixed';
        this.formModel.clientHidden = !!data.assessmentProduct.clientHidden;
        this.formModel.cavsMode = data.assessmentProduct.variationDate ? 'variation' : 'contract';
        this.formModel.fixedAdjustedPrice = data.assessmentProduct.fixedAdjustedPrice;
        this.formModel.tenderedPrice = data.assessmentProduct.tenderedPrice;
        if (this.fixModeForced()) {
            // In case the user has changed the fix conditions before fixing
            this.formModel.fixMode = 'fixed';
            this.formModel.fixedAdjustedPrice = this.formModel.tenderedPrice;
        }
    }

    productIsTendered(): boolean
    {
        return this.assessmentProduct &&
            null !== this.assessmentProduct.tenderedPrice &&
            undefined !== this.assessmentProduct.tenderedPrice;
    }

    clientHiddenToggle()
    {
        if (this.formModel.clientHidden) {
            this.formModel.fixMode = 'fixed';
            this.formModel.fixedAdjustedPrice = 0;
            this.formModel.tenderedPrice = 0;
        } else {
            this.formModel.fixedAdjustedPrice = this.assessmentProduct.assessmentPrice;
            this.formModel.tenderedPrice = this.assessmentProduct.tenderedPrice;
        }
    }

    onSubmit(form: NgForm)
    {
        if (!form.valid) return;

        const fixedPrice = this.formModel.fixedAdjustedPrice;
        const tenderedPrice = this.formModel.tenderedPrice;

        this._apService.updatePricing(this.assessmentProduct, this.formModel.fixMode, this.formModel.clientHidden, fixedPrice, tenderedPrice).pipe(first()).subscribe(ap => {
            this.result = ap;
            this._riService.fetchRbuIssues(this.assessment); // Refresh RBU issues
            this.close();
        });
    }

    tenderedPriceChanged(price)
    {
        if (this.isLumpSumMode() && 'contract' === this.formModel.cavsMode) {
            /**
             * Force the fixed price to be the same as the tendered price
             * for contract scope products in lump sum assessments
             */
            this.formModel.fixedAdjustedPrice = price;
        }
    }

     isLumpSumMode(): boolean
    {
        return this.assessment && 'fixed_price' === this.assessment.contractScope;
    }

    fixModeChanged(mode)
    {
        if ('fixed' === mode) {
            // Default fixed price to current price when selecting fixed price mode
            this.formModel.fixedAdjustedPrice = this.assessmentProduct.assessmentPrice;
        }
    }

    hasAdjustedPriceDifferentFromTenderedPrice()
    {
        return this.assessmentProduct.tenderedPrice &&
            null !== this.assessmentProduct.tenderedPrice &&
            undefined !== this.assessmentProduct.tenderedPrice &&
            this.assessmentProduct.tenderedPrice !== this.assessmentProduct.assessmentPrice;
    }
}
