'use strict';

/**
 * @ngdoc directive
 * @name or-ng.notifications
 * @description
 * # ornotification
 * Usage: $rootScope.$broadcast('showOrNotification', 'A message', set this to true for error);
 */

angular.module('coreNgNotifications', [])
	.directive('corenotification', function ($rootScope, $timeout) {
		return {
			template: '<div class="or-ng-notification-wrapper">' +
			'<div ng-repeat="n in notifs" ng-class="{\'or-notification-error\': n.orNotificationClass}">' +
            '<a href="" class="dismiss margin-bottom" ng-click="dismissNotification($index)" ng-swipe-right="dismissNotification($index)"><i class="icon-cancel"></i></a>' +
			'<p>' +
			'{{n.orNotificationMessage}}' +
			'</p>' +
			'</div>' +
			'</div>',
			restrict: 'E',
			scope: {},
			link: function postLink(scope, element, attrs) {

				scope.notifs = [];

				$rootScope.$on('showOrNotification', function (event, orMessage, orError) {

					var note = {
						'showOrNotification': 'show-or-notification',
						'orNotificationMessage': orMessage,
						'orNotificationClass': orError
					};
					scope.notifs.push(note);

					var endNotifcation = function (note) {
						scope.notifs.splice(scope.notifs.indexOf(note), 1);
					};

					$timeout( function(){endNotifcation(note)}, 10000);

                    scope.dismissNotification = function(index){
                        scope.notifs.splice( index, 1 );
                    }

				});
			}
		};
	});

