import {Component} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {Assessment} from 'app/modules/chalkstring-api/services/assessment.service';
import {
    AssessmentProduct,
    AssessmentProductEstimate,
    AssessmentProductService
} from "../../chalkstring-api/services/assessment-product.service";
import { first } from 'rxjs/operators';
import {CsEvent} from "@generic/events";

export interface AssessmentProductPricingModel
{
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
}

@Component({
    styles: [
        '.modal-body table { margin-bottom: 1em }',
        '.modal-body h2 { border-bottom: 1px solid rgb(206, 206, 206); padding-bottom: 3px; }',
    ],
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content modal-content-size-m">
            <div class="modal-header">
                <h2>{{assessmentProduct.name}}</h2>
            </div>
            <div class="modal-body">
                <div *ngIf="!estimateValues" class="module-loader"></div>
                <ng-container *ngIf="estimateValues">
                    <div *ngIf="assessmentProduct.tenderedPrice && assessmentProduct.tenderedPrice !== estimateValues.adjustedPrice">
                        <h2>As tendered</h2>
                        <div>
                            <table class="flat two-column-description borderless">
                                <tr>
                                    <td class="medium-cell"><span class="heavy">Tendered price:</span></td>
                                    <td><strong class="heavy">{{assessmentProduct.tenderedPrice | localeCurrency}}</strong> per {{assessmentProduct.quantityUnit | quantityUnit}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    
                    <h2>As priced</h2>
                    <table class="flat two-column-description borderless">
                        <ng-container *ngIf="assessmentProduct.pricingMode === 'ml'">
                            <tr><td class="medium-cell"><span>Materials:</span></td><td><strong>{{estimateValues.materialCost | localeCurrency}}</strong></td></tr>
                            <tr><td class="medium-cell"><span>Waste:</span></td><td><strong>{{estimateValues.wasteCost | localeCurrency}}</strong></td></tr>
                            <tr><td class="medium-cell"><span>Inflation:</span></td><td><strong>{{estimateValues.inflationCost | localeCurrency}}</strong></td></tr>
                            <tr><td class="medium-cell"><span>Labour:</span></td><td><strong>{{estimateValues.labourCost | localeCurrency}}</strong></td></tr>
                        </ng-container>
                        <ng-container *ngIf="assessmentProduct.pricingMode === 'sf'">
                            <tr><td class="medium-cell"><span>Supply &amp; fit cost:</span></td><td><strong>{{assessmentProduct.supplyAndFitPrice.price | localeCurrency}}</strong></td></tr>
                            <tr><td class="medium-cell"><span>Inflation:</span></td><td><strong>{{estimateValues.inflationCost | localeCurrency}}</strong></td></tr>
                        </ng-container>
                        <tr><td class="medium-cell"><span>Contingency:</span></td><td><strong>{{estimateValues.contingencyCost | localeCurrency}}</strong></td></tr>
                        <tr><td class="medium-cell"><strong class="heavy">Cost price:</strong></td><td><strong class="heavy">{{estimateValues.totalCost | localeCurrency}}</strong> per {{assessmentProduct.quantityUnit | quantityUnit}}</td></tr>
                    </table>
                    
                    <table class="flat two-column-description borderless">
                        <tr>
                            <td class="medium-cell"><span class="heavy">Overhead:</span></td>
                            <td><strong>{{estimateValues.overhead | localeCurrency}}</strong> ({{assessmentProduct.overheadPercentage}}% on cost)</td>
                        </tr>
                        <tr>
                            <td class="medium-cell"><span class="heavy">Profit:</span></td>
                            <td><strong>{{assessmentProduct.estimate.profit | localeCurrency}}</strong>
                                ({{assessmentProduct.fixedAdjustedPrice !== null ? assessmentProduct.fixedProfitPercentage : assessmentProduct.profitPercentage}}% on cost plus overhead)</td>
                        </tr>
                        <tr>
                            <td class="medium-cell"><strong class="heavy">Unadjusted Price:</strong></td>
                            <td><strong class="heavy">{{estimateValues.unadjustedPrice | localeCurrency}}</strong> ({{estimateValues.unadjustedPriceOHPPercentage | number:'.2'}}% Mark up on cost)</td>
                        </tr>
                    </table>

                    <h2>Adjusted price</h2>
                    <table class="flat two-column-description borderless">
                        <tr>
                            <td class="medium-cell"><span class="heavy">Undisclosed adjustment:</span></td>
                            <td><strong *ngIf="!estimateValues.undisclosedAdjustment">N/A</strong>
                                <strong *ngIf="estimateValues.undisclosedAdjustment">{{estimateValues.undisclosedAdjustment | localeCurrency}}</strong></td>
                        </tr>
                        <tr>
                            <td class="medium-cell"><span class="heavy">Disclosed adjustment:</span></td>
                            <td><strong *ngIf="!estimateValues.disclosedAdjustment">N/A</strong>
                                <strong *ngIf="estimateValues.disclosedAdjustment">{{estimateValues.disclosedAdjustment | localeCurrency}}</strong></td>
                        </tr>
                        <tr>
                            <td class="medium-cell"><strong class="heavy">Adjusted price:</strong></td>
                            <td><strong class="heavy" *ngIf="!estimateValues.adjustedPrice && 0 !== estimateValues.adjustedPrice">N/A</strong>
                                <strong class="heavy" *ngIf="estimateValues.adjustedPrice || estimateValues.adjustedPrice === 0">{{estimateValues.adjustedPrice | localeCurrency}}</strong> per {{assessmentProduct.quantityUnit | quantityUnit}}
                                    ({{estimateValues.adjustedPriceOHPPercentage | number:'.2'}}% mark up on cost)</td>
                        </tr>
                    </table>
                    <table class="flat borderless" *ngIf="estimateValues.undisclosedAdjustment">
                        <tr class="note">
                            <th>The effect of adjustments applied to this Product require the disclosed pre-adjustment price & OHP to be {{estimateValues.clientSubtotal | localeCurrency}} per {{assessmentProduct.quantityUnit | quantityUnit}} ({{estimateValues.clientSubtotalOHPPercentage | number:'.2'}}% mark up on cost </th>
                        </tr>
                    </table>
                    <table class="flat borderless" *ngIf="assessmentProduct.tenderedPrice && 'fixed_price' === assessment.contractScope && assessmentProduct.tenderedPrice !== assessmentProduct.assessmentPrice">
                        <tr class="note">
                            <th>This is a lump sum assessment and the total quantity of this product has been adjusted from its
                                tendered quantity. To maintain the lump sum revenue value, the live rate has been adjusted accordingly.
                                Outgoing applications will still be based on the tendered rates and quantities.</th>
                        </tr>
                    </table>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" (click)="close()"><i class="icon-cancel"></i> Close</button>
            </div>
        </div>
    `
})
export class AssessmentProductPricingModalComponent extends SimpleModalComponent<AssessmentProductPricingModel, boolean> implements AssessmentProductPricingModel
{
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
    estimateValues: AssessmentProductEstimate;

    constructor(private _apService: AssessmentProductService)
    {
        super();
    }

    mapDataObject(data: AssessmentProductPricingModel)
    {
        super.mapDataObject(data);
        this._apService.getEstimate(this.assessmentProduct)
            .pipe(first())
            .subscribe(ape => this.estimateValues = ape);
        CsEvent.reloadUserpilot();
        CsEvent.track('apPricingModal', {a: this.assessment.id, p: this.assessmentProduct.id});
    }
}
