'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:IssueCategoryCtrl
 * @description
 * # IssueCategoryCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
    .controller('LabourActivityCtrl', function ($scope, $route, $rootScope, $routeParams, $location, UNITSTEXT, labouractivity, routeType, trades, labourrate, labourQuote, price, $filter) {

        if ($routeParams.id) {
            labouractivity.get({id: $routeParams.id}).$promise.then(function (data) {
                if ($route.current.$$route.isEdit && data.trade) {
                    data.trade = data.trade.id;
                }
                return data;
            }).then(function (data) {
                $scope.activity = data;
            });
        }

        if ($route.current.$$route.isSingle) {
            // Get Labour prices for labour activity
            labourrate.query({
                product: $routeParams.id,
                limit: 50000,
                groups: 'prices_view'
            }).$promise.then(function (data) {
                $scope.labourRates = data;

                console.log(data);
            });
        }

        /* Archive Price */
        $scope.archive = function (priceId, index) {
            labourrate.archive({id: priceId}).$promise.then(function () {
                $scope.labourRates[index].archivedAt = new Date();
            });
        };

        /* Unarchive Price */
        $scope.unarchive = function (priceId, index) {
            labourrate.unarchive({id: priceId}).$promise.then(function () {
                $scope.labourRates[index].archivedAt = null;
            });
        };

        /* Show Archived Prices */
        $scope.showArchivedPrices = function () {
            $scope.showArchived = !$scope.showArchived;
        };


        if ($route.current.$$route.isNew || $route.current.$$route.isEdit) {
            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);
        }

        $scope.viewQuote = function (labourRate) {
            if (labourRate.quoteId) {
                $location.path('/labour-quote/' + labourRate.quoteId);
            }
        };

        /* Check if quote has expired */
        $scope.quoteExpired = function (date) {
            var today = new Date();
            date = $filter('date')(date, "MM/dd/yyyy");
            var theDate = new Date(date);
            if (theDate < today) {
                return true;
            }
        };
    });
