(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.assessmentMaterial
     * @description
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('assessmentProduct', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/assessmentproducts/:id', {
                    id: '@id', fileId: '@fileId'
                }, {
                    // Methods
                    patchTemplate: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentproducts/:id/product-template'
                    },
                    postTemplate: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/assessmentproducts/:id/product-template'
                    },
                    applyTemplate: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentproducts/:id/template-apply'
                    },
                    patch: {
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/assessmentproducts/:id'
                    },
                    addDatasheet: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentproducts/:id/add-datasheet/:fileId'
                    },
                    addExternalLink: {
                      method: 'POST',
                      url: ENV.apiEndpoint + '/assessmentproducts/:id/add-external-link'
                    },
                }
            );
        }]);
})();
