import { Component, Inject } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {LOCALE_ID} from '@angular/core';

declare let require: any;

@Component({
    selector: 'ang2',
    template: '<nav-list-main [angular2RoutesOnly]="true"></nav-list-main><router-outlet></router-outlet>',
    providers: []
})
export class AppComponent  {
    defaultCurrency: String;

    constructor(router: Router, translate: TranslateService, @Inject(LOCALE_ID) private locale: string) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                window['userpilot'].reload();
            }
        });

        let storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.defaultCurrency = storedUserDetails?.account?.defaultCurrency || 'en-GB';

        translate.addLangs(['en-GB', 'en-MY', 'en-SG']);
        translate.setDefaultLang(this.locale);
        translate.use(this.locale);
        if (this.defaultCurrency == "MYR") {
            translate.use('en-MY');
        } else if (this.defaultCurrency == "SGD") {
            translate.use('en-SG');
        }
    }
}
