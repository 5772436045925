'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:TenderCtrl
 * @description
 * # TenderCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
    .controller('TenderCtrl', function ($scope, $rootScope, $routeParams, tender, groupCompany, $route, $location, documenttemplate, ENV, $httpParamSerializer) {

        $scope.tenderId = $routeParams.id;
        $scope.templates = null;
        $scope.termsForm = {};
        $scope.resolveTenderSubmitFlag = false;

        $scope.setGenerateDocUrl = function () {
            var url = ENV.apiEndpoint + '/tenders/' + $scope.tender.id + '/gen-doc?';
            var params = {access_token: localStorage.getItem('access_token')};
            $scope.generateDocUrl = url + $httpParamSerializer(params);
        };

        var formatAddress = function (addressObj) {
            var newAddress = '';
            if (addressObj.street1) {
                newAddress += addressObj.street1 + ' \n';
            }
            if (addressObj.street2) {
                newAddress += addressObj.street2 + ' \n';
            }
            if (addressObj.street3) {
                newAddress += addressObj.street3 + ' \n';
            }
            if (addressObj.city) {
                newAddress += addressObj.city + ' \n';
            }
            if (addressObj.county) {
                newAddress += addressObj.county + ' \n';
            }
            if (addressObj.postcode) {
                newAddress += addressObj.postcode + ' \n';
            }
            if (addressObj.country) {
                newAddress += addressObj.country;
            }
            return newAddress;
        };

        function getTenderData() {
            tender.get({id: $scope.tenderId}, {onlygroups: 'basic,tenders,tenders_view,assessment_estimate,projectCompanyGroup_view'}).$promise.then(function (data) {
                $scope.tender = data;
                if ($route.current.$$route.isSubmit) {
                    $scope.data = ($scope.data) ? $scope.data : {};
                    $scope.data.toCompany = data.project.client.fullName;
                }
                $scope.setGenerateDocUrl();
            }).then(function () {
                // Format client addresses for tender submission form
                if ($scope.tender.project.client && $scope.tender.project.client.addresses) {
                    $scope.clientAddresses = [];

                    angular.forEach($scope.tender.project.client.addresses, function (address) {
                        var newAddress = formatAddress(address);
                        $scope.clientAddresses.push({address: newAddress});
                    });
                    angular.forEach($scope.tender.project.client.children, function (office) {
                        angular.forEach(office.addresses, function (address) {
                            var newAddress = formatAddress(address);
                            $scope.clientAddresses.push({address: newAddress});
                        });
                    });
                }
            }).then(function () {
                $scope.officeAddresses = [];
                if ($scope.tender.project.scsCompanyGroup) {
                    angular.forEach($scope.tender.project.scsCompanyGroup.addresses, function (address) {
                        var newAddress = formatAddress(address);
                        $scope.officeAddresses.push({address: newAddress});
                    });
                }
            });
        }

        getTenderData();

        $scope.resolveTender = function (status) {
            if($scope.resolveTenderSubmitFlag){
                return;
            }
            $scope.resolveTenderSubmitFlag = true;
            var message;
            if (status === 'win') {
                message = 'won';
            } else if (status === 'lose') {
                message = 'lost';
            } else if (status === 'withdraw') {
                message = 'withdrawn';
            }

            tender.resolveTender({id: $scope.tenderId, type: status}).$promise.then(function () {
                getTenderData();
            });
        };

        if ($route.current.$$route.isSubmit) {
            loadTemplates();

            $scope.defaultTerms = '[Insert terms & conditions applicable to this tender here]';

            $scope.submitTender = function () {

                $scope.submitted = true;

                if ($scope.form.$valid) {
                    tender.submitTender({id: $scope.tenderId}, $scope.data).$promise.then(function () {
                        $scope.form.$setPristine();
                        $location.path('/tender/' + $scope.tenderId);
                    });
                }
            }
        }
    });
