import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from "@angular/core";
import {
    RateBuildUpIssueCount,
    RateBuildUpIssueService, RbuIssuesResponse
} from "./rate-build-up-issue.service";
import {Subscription} from "rxjs";

@Component({
    template: `
        <ng-container *ngIf="issues && assessmentProductId">
            <rbu-issue-flag *ngFor="let issue of issues.products[assessmentProductId]"
                            [issue]="issue"
            ></rbu-issue-flag>
        </ng-container>
        <ng-container *ngIf="issues && !assessmentProductId">
            <rbu-issue-flag *ngFor="let issue of issues.assessment"
                            [issue]="issue"
                            [clickable]="true"
                            (issueClicked)="onClick(issue)"
            ></rbu-issue-flag>
        </ng-container>
    `,
    selector: 'rbu-issue-flag-list',
    styles: [
        'badge.clickable { cursor: pointer }'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateBuildUpIssueFlagListComponent implements OnDestroy
{
    @Input() assessmentProductId: number;
    @Output() issueClicked: EventEmitter<RateBuildUpIssueCount> = new EventEmitter<RateBuildUpIssueCount>();
    issues: RbuIssuesResponse;
    issueSub: Subscription;

    constructor(private _riService: RateBuildUpIssueService, private _change: ChangeDetectorRef)
    {
        this.issues = this._riService.lastResponse;
        this.issueSub = this._riService.issuesFetched.subscribe(res => {
            this.issues = res;
            this._change.detectChanges();
        })
    }

    ngOnDestroy()
    {
        if (this.issueSub) this.issueSub.unsubscribe();
    }

    onClick(i: RateBuildUpIssueCount)
    {
        this.issueClicked.emit(i);
    }
}
