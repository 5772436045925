(function() {
	'use strict';

	/**
	 * @ngdoc directive
	 * @name scswebappApp.directive:filestable
	 * @description
	 * # filestable
	 */
	angular.module('scswebappApp')
		.directive('filestable', function(file, $q, filefolder) {
			return {
				template: require('./filestable-directive.html'),
				restrict: 'E',
				scope: {
					data: '=',
					buttons: '=',
					readonly: '@',
					preDelete: '=?',
					folders: '=?',
					updatePermission: '=',
					deletePermission: '=',
                    showNoFilesText: '@'
				},
				link: function postLink(scope, element, attrs) {
					scope.$watch("readonly", function() {
						if (scope.readonly === "true") {
							scope.hasDelete = false;
							scope.hasMove = false;
						}
					});
					if (scope.buttons) {
						scope.hasDownload = scope.buttons.download;
						scope.hasDelete = scope.buttons.delete;
						scope.hasMove = scope.buttons.move;
					} else {
						scope.hasDownload = true;
						scope.hasDelete = scope.readonly !== "true";
						if (scope.folders) {
							scope.hasMove = scope.readonly !== "true";
						}
					}

					scope.decodeUri = function(string) {
						return decodeURIComponent(string);
					};

					scope.formatFilesize = function(size) {

						var sizeKb = Math.round(size / 1024);

						if (sizeKb > 1024) {
							sizeKb = (sizeKb / 1024).toFixed(1) + ' mb';
						} else {
							sizeKb = sizeKb + ' kb';
						}

						return sizeKb;
					};


					if (!scope.preDelete) {
						scope.preDelete = function(file) {
							return $q.when();
						};
					}

					scope.deleteFile = function(index, id) {
						var dataObject = attrs.data.split('.')[0];
						var val = attrs.data.split('.')[1];
						var allFiles = (val) ? scope.$parent[dataObject][val] : scope.$parent[dataObject];

						scope.preDelete(scope.data[index]).then(function() {
							var removeFile = new file({ id: id });

							removeFile.$remove().then(function() {
								if (Array.isArray(allFiles)) {
									scope.data.splice(index, 1);
								} else {
									scope.$parent[dataObject][val] = {};
								}
							});
						});
					};

					scope.openMoveFileModal = function(index, fileId) {
						scope.movingFileId = fileId;
						scope.moveFileModal.open();
					};

					scope.moveFile = function(selectedFolder) {
						filefolder.moveFile({
							file: scope.movingFileId,
							folder: selectedFolder
						}
						).$promise.then(function(data) {
							scope.$emit('fileMoved', data);
							scope.moveFileModal.close();
						});
					}
				}
			};
		});
})();
