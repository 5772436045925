'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('toggleSwitch', function () {
        return {
            template: '<label class="switch"><input name="name" ng-model="value" type="checkbox" ng-click="click()"><div class="slider round"></div></label>',
            restrict: 'E',
            scope: {
                value: '=?',
                name: '@?'
            },
            link: function postLink(scope) {

                if (!scope.value){
                    scope.value = false;
                }
                scope.click = function(){
                    scope.$emit('toggleSwitchClicked', {value:scope.value, name:scope.name});
                }
            }
        };
    });



