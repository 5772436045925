(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProgressGroupingCtrl
     * @description
     * # ProgressGroupingCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProgressGroupingCtrl', function ($scope, $route, $rootScope, $routeParams, $filter, project, workscope, progressGroup, assessment, $http, $window, ENV, $location, $timeout) {
            $scope.projectId = $routeParams.id;
            project.get({id: $routeParams.id}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                $rootScope.projectId = $routeParams.id;
                $scope.project = data;
            });

            // Handle filters
            $scope.filters = {};
            $scope.currentPage = 1;
            $scope.assessments = project.getLiveAssessments({id: $routeParams.id, hydrate: 'array'});
            $scope.doFilter = function () {
                console.log($scope.filters);
                var assessmentId = $scope.filters.hasOwnProperty('assessment') && $scope.filters.assessment.length ?
                    $scope.filters.assessment[0].id : null;
                if (assessmentId) {
                    fetchGroupingData(assessmentId, $scope.filters.productName);
                }
            };
            var textTimeout;
            $scope.textChange = function () {
                $timeout.cancel(textTimeout);
                textTimeout = $timeout(function () {
                    $scope.currentPage = 1;
                    $scope.doFilter();
                }, 500);
            };

            // Handle page-leaving
            var deregisterLocationChangeListener = $scope.$on('$locationChangeStart', function (e, nextLocation) {
                var warned = false;
                $scope.nextLocation = nextLocation.split('#');

                angular.forEach($scope.workscopes, function (workscope) {
                    if (warned) {
                        return;
                    }
                    var hasGroups = false;
                    var hasUngrouped = false;

                    angular.forEach(workscope.assessmentLabourComponents, function (alc) {
                        if (alc.progressGroup != null) {
                            hasGroups = true;
                        } else if (alc.progressGroup === null) {
                            hasUngrouped = true;
                        }
                    });

                    if (hasGroups && hasUngrouped) {
                        e.preventDefault();
                        $scope.confirmationModal.open();
                        warned = true;
                    }
                });
            });
            $scope.leavePage = function () {
                $scope.confirmationModal.close();
                $location.path($scope.nextLocation[1]);
                deregisterLocationChangeListener();
            };

            // Get the progress groups
            $scope.workscopeGroups = progressGroup.query({project: $routeParams.id});

            var fetchGroupingData = function (assessmentId, productName) {
                // Fetching progress grouping data
                var options = {projectId: $routeParams.id, assessment: assessmentId, page: $scope.currentPage};
                if (productName && productName.length) {
                    options.search = productName;
                }
                assessment.getAssessmentProductsForProgress(options, function (data, headers) {
                    console.log(data);
                    // Get maximum number of components for table header length
                    $scope.maximumComponentLength = new Array(data.maxComponentCount);

                    // Pagination
                    $scope.objectCount = headers()['x-total-count'];

                    // Create an array of pages
                    $scope.itemsPerPage = 30;
                    $scope.numPages = $scope.objectCount / $scope.itemsPerPage;
                    $scope.pagesArray = [];
                    for (var i = 0; i < $scope.numPages; i++) {
                        $scope.pagesArray.push(i + 1);
                    }
                    console.log($scope.pagesArray);

                    $scope.workscopes = data.products;
                });
            };
            // Pagination
            $scope.changePage = function (page) {
                if (page !== 0 && page !== $scope.pagesArray.length + 1) {
                    $scope.currentPage = page;
                    $scope.doFilter();
                }
            };


            $scope.highlight = [];
            $scope.showHideForm = function () {
                $scope.newFormVisibility = !$scope.newFormVisibility;
                if (!$scope.newFormVisibility) {
                    $scope.group = {project: $scope.projectId, name: null, colour: null};
                }
            };

            // New group
            $scope.createGroup = function () {
                if ($scope.submitted){
                    return;
                }
                $scope.submitted = true;
                $scope.groupError = ($scope.group && $scope.group.name && $scope.group.colour) ? false : true;

                if (!$scope.groupError) {
                    if (!$scope.group.id) {
                        progressGroup.save($scope.group).$promise.then(function (data) {
                            $scope.workscopeGroups.push(angular.copy(data));
                            $scope.group = {project: $scope.projectId, name: null, colour: null};
                            $scope.showHideForm();
                            $scope.submitted = false;
                        })
                    } else {
                        progressGroup.updateGroup($scope.group).$promise.then(function (data) {
                            $scope.group = {project: $scope.projectId, name: null, colour: null};
                            $scope.showHideForm();
                            $scope.doFilter();
                            progressGroup.query({project: $routeParams.id}).$promise.then(function (data) {
                                $scope.workscopeGroups = data;
                                $scope.submitted = false;
                            });
                        })
                    }
                }
            };

            // Set active group
            $scope.activeGroup = function (id, index) {
                if ($scope.highlight[index]) {
                    $scope.highlight = [];
                    $scope.currentActiveGroup = null;
                } else {
                    $scope.highlight = [];
                    $scope.currentActiveGroup = id;
                    $scope.highlight[index] = true;
                }

                $scope.isActive = ($scope.highlight.length > 0);
            };

            // Add or remove an ALC from a group
            $scope.updateGroupComponent = function (component) {
                if ($scope.currentActiveGroup && !$scope.busy) {
                    $scope.busy = true;
                    if (component.progressGroup && component.progressGroup.id === $scope.currentActiveGroup) {
                        progressGroup.removeComponent({
                            id: $scope.currentActiveGroup,
                            alcId: component.id
                        }).$promise.then(function () {
                            component.progressGroup = null;
                            $scope.busy = false;
                        })
                    } else {
                        progressGroup.addComponent({
                            id: $scope.currentActiveGroup,
                            alcId: component.id
                        }).$promise.then(function (data) {
                            angular.forEach($scope.workscopeGroups, function (group) {
                                if (group.id == $scope.currentActiveGroup) {
                                    component.progressGroup = group;
                                }
                            });
                            $scope.busy = false;
                        })
                    }
                }
            };

            // Making changes to existing groups
            $scope.deleteGroup = function (id, index) {
                $scope.highlight = [];
                $scope.currentActiveGroup = null;
                progressGroup.delete({id: id}).$promise.then(function () {
                    $scope.workscopeGroups.splice(index, 1);
                }).then(function () {
                    $scope.doFilter();
                });
            };
            $scope.editGroup = function (group) {
                $scope.newFormVisibility = true;
                $scope.group = {
                    id: group.id,
                    project: $scope.projectId,
                    name: group.name,
                    colour: group.colour
                };
            };

            // Scroll table
            var $ = require('jquery');
            require(['tinycolor2'], function(tinycolor){
                window.tinycolor = tinycolor;
            });
            $(function () {
                $('.scroll-table > div').scroll(function () {
                    // Horizontal scroll
                    $scope.tableHeaderOffset = $('.scroll-table > div > table').position().left;
                    $scope.tableColOffset = $('.scroll-table > div > table').position().top;
                    $scope.$apply();
                });
            });

        });
})();
