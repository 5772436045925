import {Directive, ElementRef, Injector, Input} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";

@Directive({
    selector: 'edit-rate-build-up-product'
})
export class EditRateBuildUpProductDirective extends UpgradeComponent
{
    @Input() project: any;
    @Input() assessment: any;
    @Input() assessmentId: any;
    @Input() assessmentProduct: any;
    @Input() currencySymbol: string;

    constructor(elementRef: ElementRef, injector: Injector)
    {
        super('editRateBuildUpProduct', elementRef, injector);
    }
}
