'use strict';

angular.module('scswebappApp')
    .directive('passwordModal', function ($rootScope, $compile, user,PermPermissionStore) {

        return {
            restrict: 'E',
                template: '<core-modal control="control" small="true" no-close="true">' +
                    '   <div ng-if="!confirm">' +
                    '      <h2>Setting up your password</h2>' +
                    '      <p>Before you can begin to use Chalkstring, we need you to provide a new password. If you aren\'t ready to set up your account, you can <logout text="\'click here\'"></logout> to log out</p>' +
                    '      <form name="password_form" ng-submit="submit(this)">' +
                    '          <password-form ng-model="plainPassword"></password-form>' +
                    '          <input type="submit" class="btn btn-primary" value="Submit" ng-disabled="password_form.$invalid">' +
                    '      </form>' +
                    '   </div>' +
                    '   <div ng-if="confirm">' +
                    '       <h2>Password saved</h2>' +
                    '       <p>Thank you, your new password has been set up successfully.</p>' +
                    '       <button class="btn" ng-click="control.close()">Continue to Chalkstring</button>' +
                    '   </div>' +
                    '</core-modal>',
            scope: {},
            link: {post:function (scope, $element ) {
                var plainPassword = null;

                if($rootScope.userDetails) {
                    window.setTimeout(function(){
                        if (PermPermissionStore.hasPermissionDefinition('forcePasswordReset')) {
                            console.log('test');
                            scope.control.open();
                        }

                        $rootScope.$on('passwordFormChanged', function (e) {
                            plainPassword = e.targetScope.password;
                        });

                        scope.submit = function (form) {
                            scope.submitted = true;

                            if (!form.$invalid) {
                                user.updatePassword({
                                    id: $rootScope.userDetails.id,
                                    plainPassword: plainPassword
                                }).$promise.then(function (data) {
                                    localStorage.setItem('userDetails', JSON.stringify(data));
                                    scope.submitted = false;
                                });
                            }
                        };
                    },1000);
                }
            }}
        };
    });
