'use strict';

angular.module('scswebappApp')
    .directive('fileFolders', function (
        filefolder,
        FileUploader,
        $q,
        $rootScope,
        externallink,
        PermPermissionStore
    ) {

        return {
            template: require('./file-folders.html'),
            restrict: 'E',
            transclude: true,
            scope: {
                'projectId': '='
            },
            link: function postLink(scope, element, attrs) {
                scope.submitted = false;
                scope.folderName = null;
                scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteProjectHubFilesLinks');

                scope.selectedFolder = null;
                var getFolders = function () {
                    filefolder.get({}, {projectId: scope.projectId}).$promise.then(function (folders) {
                        angular.forEach(folders, function (folder) {
                            if (!scope.selectedFolder) {
                                scope.selectedFolder = folder.id;
                            }
                            var uploader = new FileUploader;
                            uploader.onCompleteAll = function () {
                                saveFolder(folder.id);
                            };
                            folder.uploader = {
                                uploader: uploader
                            };
                        });
                        scope.folders = folders;
                    });

                };
                getFolders();

                scope.openFolder = function (folderId) {
                    scope.selectedFolder = folderId;
                };

                var saveFolder = function (folderId, deferred) {
                    angular.forEach(scope.folders, function (folder) {
                        if (folder.id == folderId) {

                            filefolder.update({id: folderId}, {
                                files: folder.files
                                    .pluck('file', false)
                                    .pluck('id', 'file')
                            }).$promise.then(function (data) {
                                getFolders();
                                if (deferred) {
                                    deferred.resolve(deferred.resolve(data));
                                }
                            });
                        }
                    });
                    if (deferred) {
                        deferred.reject('shouldn\'t even be here');
                    }
                };

                scope.preDelete = function (deletedFile) {
                    var deferred = $q.defer();
                    angular.forEach(scope.folders, function (folder) {
                        angular.forEach(folder.files, function (file, fileIndex) {
                            if (file.id == deletedFile.id) {
                                folder.files.splice(fileIndex, 1);
                                saveFolder(folder.id, deferred);
                            }
                        });
                    });
                    return deferred.promise;
                };

                scope.createFolder = function (folderName) {
                    scope.submitted = true;
                    filefolder.save({
                        project: scope.projectId,
                        name: folderName
                    }).$promise.then(function (data) {
                        scope.addFolderModal.close();
                        getFolders();
                    });
                };

                scope.openRenameFolderModal = function (folder) {
                    scope.folderRenameName = folder.name;
                    scope.folderRenameId = folder.id;
                    scope.renameFolderModal.open();
                };

                scope.renameFolder = function (folderId, folderName) {
                    filefolder.update({id: folderId}, {
                        name: folderName
                    }).$promise.then(function (data) {
                        getFolders();
                        scope.renameFolderModal.close();
                    });
                };

                scope.openConfirmDeleteFolderModal = function (folder) {
                    scope.folderDeleteName = folder.name;
                    scope.folderDeleteId = folder.id;
                    scope.confirmDeleteFolderModal.open()
                };

                scope.deleteFolder = function (folderId) {
                    filefolder.delete({id: folderId}).$promise.then(function (data) {
                        getFolders();
                        scope.confirmDeleteFolderModal.close()
                    });
                };

                $rootScope.$on('fileMoved', function () {
                    getFolders();
                });

                scope.openAddLinkModal = function (folder) {
                    scope.folderId = folder.id;
                    scope.folderName = folder.name;
                    scope.addLinkModal.open();
                };
                scope.createLink = function (folderId, label, url) {
                    externallink.save({
                        folder: folderId,
                        label: label,
                        url: url
                    }).$promise.then(function (data) {
                        scope.addLinkModal.close();
                        getFolders();
                    });
                };
                scope.openMoveLinkModal = function (link) {
                    scope.movingLinkId = link.id;
                    scope.movingLinkLabel = link.label;
                    scope.moveLinkModal.open();
                };
                scope.moveLink = function (linkId, folderId) {
                    externallink.update({id: linkId}, {folder: folderId}).$promise.then(function (data) {
                        getFolders();
                        scope.moveLinkModal.close()
                    });
                };
                scope.openConfirmDeleteLinkModal = function (link) {
                    scope.linkDeleteLabel = link.label;
                    scope.linkDeleteId = link.id;
                    scope.confirmDeleteLinkModal.open()
                };
                scope.deleteLink = function (linkId) {
                    externallink.delete({id: linkId}).$promise.then(function (data) {
                        getFolders();
                        scope.confirmDeleteLinkModal.close()
                    });
                };
            }
        };
    });
