import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {Assessment, AssessmentDocumentationFormat} from "../../chalkstring-api/services/assessment.service";
import {SimpleModalService} from "ngx-simple-modal";
import {AssessmentConfigModalComponent} from "../modals/assessment-config-modal.component";
import {first} from "rxjs/operators";
import {AccessResource, PermissionsService} from "../../permissions/permissions.service";

export interface NamedAssessmentDocumentFormat
{
    format: AssessmentDocumentationFormat,
    name: string
}

export class AssessmentConfig
{
    static readonly documentationFormats: NamedAssessmentDocumentFormat[] = [
        {format: AssessmentDocumentationFormat.BILL, name: 'Products x zones | Bill format'},
        {format: AssessmentDocumentationFormat.MATRIX, name: 'Components x zones | Grid format | Values'},
        {format: AssessmentDocumentationFormat.GRID_PZ, name: 'Products x zones | Grid format | Values'},
        {format: AssessmentDocumentationFormat.GRID_ZP, name: 'Zones x products | Grid format | Quantities & values'},
    ];
}

@Component({
    template: require('./assessment-config-panel.component.html'),
    selector: 'assessment-config-panel',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentConfigPanelComponent
{
    canWriteAssessments: boolean = false;
    private _assessment: Assessment;
    get assessment(): Assessment
    {
        return this._assessment;
    }
    @Input() set assessment(a: Assessment)
    {
        this._assessment = a;
        if (a) {
            const ar: AccessResource = a.isAccepted ?
                AccessResource.ProjectHubAssessmentConfiguration : AccessResource.ProjectsOffsiteAssessments;
            this._permissions.hasWritePermission(ar).then(has => {
                this.canWriteAssessments = has;
                this._change.detectChanges();
            });
        }
    }

    @Input() onSite: boolean = false;

    constructor(
        private _modalService: SimpleModalService,
        private _permissions: PermissionsService,
        private _change: ChangeDetectorRef,
    )
    {
    }

    formatToName(format: AssessmentDocumentationFormat): string
    {
        return AssessmentConfig.documentationFormats.find(df => df.format === format)?.name;
    }

    openModal()
    {
        this._modalService.addModal(AssessmentConfigModalComponent, {
            assessment: this.assessment,
            onSite: this.onSite
        }).pipe(first()).subscribe(res => {
            if (res) {
                this.assessment = res;
                this._change.detectChanges();
            }
        });
        return false;
    }
}
