(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:MaterialsCtrl
     * @description
     * # MaterialsCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MaterialCtrl', function ($scope, $rootScope, $routeParams, $route, material, price, packages, $filter, $location, routeType) {

            $scope.showArchived = false;
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

            function getMaterialPrices() {
                var opts = {id: $routeParams.id};
                if ($scope.showArchived) {
                    opts.includeArchived = true;
                }
                price.getMaterialPrices(opts).$promise.then(function (data) {
                    $scope.prices = data;
                });
            }

            if ($routeParams.id) {
                material.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.material = data;
                });
                getMaterialPrices();
            }

            /* Check if quote has expired */
            $scope.quoteExpired = function (date) {
                var today = new Date();
                date = $filter('date')(date, "MM/dd/yyyy");
                var theDate = new Date(date);
                if (theDate < today) {
                    return true;
                }
            };

            /* Delete Guesstimate */
            $scope.deleteGuesstimate = function (priceId, priceIndex) {
                price.delete({id: priceId}).$promise.then(function () {
                    $scope.prices.splice(priceIndex, 1);
                }).catch(function (data) {
                    if (data.status === 409) {
                        var usedIn = Object.keys(data.data.assessmentProducts).length;
                        $scope.$emit('showOrNotification', 'This price cannot be deleted as it is used in ' + usedIn + ' assessment product(s)', true);
                    }
                })
            };

            $scope.viewQuote = function (price) {
                if (price.quote) {
                    $location.path('/material-quote/' + price.quote.id);
                }
            };

            /* Archive Price */
            $scope.archivePrice = function (priceId, index) {
                price.archivePrice({id: priceId}).$promise.then(function () {
                    $scope.prices[index].archivedAt = new Date();
                });
            };

            /* Unarchive Price */
            $scope.unArchivePrice = function (priceId, index) {
                price.unArchivePrice({id: priceId}).$promise.then(function () {
                    $scope.prices[index].archivedAt = null;
                });
            };

            /* Show Archived Prices */
            $scope.showArchivedPrices = function () {
                $scope.showArchived = !$scope.showArchived;
                getMaterialPrices();
            };
        });
})();
