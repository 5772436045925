import {Component, Input} from '@angular/core';
import {SimpleModalService} from "ngx-simple-modal";
import {Assessment} from '../chalkstring-api/services/assessment.service';
import {AssessmentProduct} from "../chalkstring-api/services/assessment-product.service";
import {AssessmentProductPricingModalComponent} from "./modal/assessment-product-pricing-modal.component";

@Component({
    selector: 'assessment-product-price',
    template: `
        <span class="explicit-tooltip" (click)="openModal()">
            <span *ngIf="assessmentProduct.estimate"><strong>{{assessmentProduct.estimate.adjustedPrice | localeCurrency}}</strong> per {{assessmentProduct.quantityUnit | quantityUnit}}</span>
            <span>({{assessmentProduct.estimate.totalCost | localeCurrency}} ex. OHP)</span>
        </span>
    `
})
export class AssessmentProductPriceComponent
{
    @Input() assessment: Assessment;
    @Input() assessmentProduct: AssessmentProduct;

    constructor(private _modalService: SimpleModalService)
    {
    }

    openModal()
    {
        this._modalService.addModal(AssessmentProductPricingModalComponent, {
            assessment: this.assessment,
            assessmentProduct: this.assessmentProduct
        }).subscribe();
    }
}
