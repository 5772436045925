import ManyAssociationPatcher from '../../other/ManyAssociationPatcher.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:QuoteCtrl
     * @description
     * # QuoteCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('QuoteCtrl', function ($routeParams, $rootScope, $route, $scope, supplier, project, FileUploader, quote, $location, routeType, material, quotePrice, $locale) {

            $scope.quoteId = $routeParams.id;
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);
            $scope.patcher = new ManyAssociationPatcher('product');



            let year = new Date().getFullYear();

            $scope.nextYear = parseInt(year + 1);

            $scope.quoteDate = new Date();
            $scope.quoteExpires = new Date();
            $scope.quoteExpires.setYear($scope.nextYear);


            $scope.formatForm = function () {
                $scope.quoteDate = new Date($scope.quote.quoteDate);
                $scope.quoteExpires = new Date($scope.quote.quoteExpires);
            };

            $scope.fileUploader = {
                uploader: new FileUploader()
            };

            $scope.quote = {
                documents: [],
                prices: []
            };
            $scope.pinnedRows = [];
            $scope.quotePrices = [];

            $scope.showDuplicate = false;

            if ($route.current.$$route.isNew) {
                $scope.priceTableParams = {quote: 0};
                supplier.query({limit: 5000}).$promise.then(function (data) {
                    $scope.suppliers = data;
                }).then(function () {
                    project.query({limit: 30000}).$promise.then(function (data) {
                        $scope.projects = data;
                    });
                });
            } else {
                $scope.priceTableParams = {quote: $routeParams.id};
            }

            if ($route.current.$$route.isSingle) {
                quote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                    $scope.formatForm();

                    $scope.toQuoteRequest = function () {
                        quote.toQuoteRequest({id: $scope.quote.id}).$promise.then(function (data) {
                            $location.path('/material-quote-request/' + data.id);
                        });
                    };
                });
            }

            if ($route.current.$$route.isEdit) {
                quote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                    $scope.formatForm();
                    $scope.duplicateQuoteName = data.name + ' (duplicate)';
                }).then(function () {
                    return supplier.query({limit: 5000}).$promise;
                }).then(function (data) {
                    // Map Suppliers
                    angular.forEach(data, function (value) {
                        if ($scope.quote.supplier && value.id === $scope.quote.supplier.id) {
                            value.ticked = true;
                        }
                    });
                    $scope.suppliers = data;
                }).then(function () {
                    return project.query({limit: 30000}).$promise;
                }).then(function (data) {
                    // Map projects
                    angular.forEach($scope.quote.projects, function (value) {
                        angular.forEach(data, function (value2) {
                            if (value.id === value2.id) {
                                value2.ticked = true;
                            }
                        });
                    });
                    $scope.projects = data;
                });
            }

            $scope.toggleModal = function () {
                $scope.showDuplicate = !$scope.showDuplicate;
            };

            $scope.duplicate = function () {
                $scope.duplicateSubmitted = true;

                if ($scope.duplicateForm.$valid) {
                    quote.clone({
                        sourceQuote: $scope.quoteId,
                        modifier: $scope.duplicateData.modifier,
                        name: $scope.duplicateData.name
                    }).$promise.then(function (data) {
                        $scope.duplicateForm.$setPristine();
                        $scope.quoteForm.$setPristine();
                        $location.path('/material-quote/' + data.id);
                        $scope.duplicateSubmitted = false;
                    });
                }
            };

            // Save quote
            $scope.saveQuote = function () {
                $scope.submitted = true;

                $scope.quote.quoteDate = $scope.quoteDate.toISOString().slice(0, 10);
                $scope.quote.quoteExpires = $scope.quoteExpires.toISOString().slice(0, 10);

                var saveQuote = new quote($scope.quote);

                $scope.supplierError = $scope.quote.supplier.length < 1;

                if ($scope.quoteForm.$valid && !$scope.supplierError) {
                    if ($scope.quoteId) {
                        var saveQuoteMethod = function () {
                            quote.update(saveQuote).$promise.then(function () {
                                $scope.quoteForm.$setPristine();

                                if ($route.current.$$route.isEdit) {
                                    $location.path('/material-quote/' + $scope.quoteId);
                                }
                            });
                        };

                        var operations = $scope.patcher.getChangeSet();
                        if ($scope.patcher.isDirty()) {
                            quote.patchPrices({id: $scope.quoteId}, operations, function () {
                                $scope.patcher.clear();
                                saveQuoteMethod();
                            });
                        } else {
                            saveQuoteMethod();
                        }
                    } else {
                        angular.forEach($scope.patcher.getAddQueue(), function (newPriceObject, productId) {
                            saveQuote.prices.push({
                                'product': productId,
                                'price': newPriceObject.price
                            });
                        });

                        saveQuote.$save().then(function (data) {
                            $scope.quoteForm.$setPristine();
                            $location.path('/material-quote/' + data.id);
                        });
                    }
                }
            };

            // Materials table / selection / new material
            $scope.newMaterial = false;

            $scope.showNewMaterial = function () {
                $scope.newMaterial = true;

                //Scroll to top
                window.scrollTo(0, 0);
            };

            $scope.closeMaterialModal = function () {
                $scope.selectMaterial = false;
            };

            $scope.closeNewMaterialModal = function () {
                $scope.newMaterial = false;
            };

            $scope.$on('newMaterialAdded', function (event, item) {
                item.productId = item.id; // So it matches the format returned by the price controller
                $scope.patcher.addAddOperation(
                    item,
                    {
                        'product': item.id
                    },
                    item.id
                );

                $scope.pinnedRows.push(item);

                $scope.newMaterial = false;
                $scope.selectMaterial = false;

                if ($scope.patcher.isDirty()) {
                    $scope.quoteForm.$setDirty();
                }
            });

            // Add material
            $scope.addQuoteMaterial = function () {
                $scope.selectMaterial = true;
            };

            $scope.changeMaterial = function (index) {
                $scope.materialToRemove = index;
                $scope.selectMaterial = true;
                $scope.priceIndex = index;
            };

            var isUniqueMaterial = function(item) {
                let isUnique = true;
                angular.forEach($scope.pinnedRows, function (pr) {
                    if (pr.id === item.id) {
                        isUnique = false;
                    }
                });
                angular.forEach($scope.quotePrices, function (qp) {
                    if (qp.productId === item.id) {
                        isUnique = false;
                    }
                });
                return isUnique;
            };
            $scope.setQuotePrices = function (prices) {
                $scope.quotePrices = prices;
            }
            $scope.addMaterials = function (materials) {
                angular.forEach(materials, function (material) {
                    material.productId = material.id; // So it matches the format returned by the price controller
                    if (isUniqueMaterial(material)) {
                        $scope.patcher.addAddOperation(
                            material,
                            {
                                'product': material.id
                            },
                            material.id
                        );
                        $scope.pinnedRows.push(material);
                    }
                });

                $scope.selectMaterial = false;

                if ($scope.patcher.isDirty()) {
                    $scope.quoteForm.$setDirty();
                }
            };

        });
})();
