import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EmbeddedViewRef,
    Input, NgZone,
    OnDestroy, TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {Project} from "../../chalkstring-api/services/project.service";
import {Assessment} from "../../chalkstring-api/services/assessment.service";
import Popper from "popper.js";
import {Page} from "../../slate/shared/models/slate.model";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {PageService} from "../../slate/services/page.service";

@Component({
    template: require("./takeoff-list-button.component.html"),
    styles: [require("./takeoff-list-button.component.scss")],
    selector: 'takeoff-list-button'
})
export class TakeoffListButtonComponent implements OnDestroy
{
    @Input() project: Project;
    @Input() assessment: Assessment;
    @Input() projectId: number;

    @ViewChild("origin", {static: false}) button: ElementRef;

    private view: EmbeddedViewRef<any> = null;
    private popperRef: Popper;
    pages: Page[];

    constructor(
        private vcr: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private pageListService: PageService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnDestroy() {
        if (this.popperRef) {
            this.close();
        }
    }

    toggle(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
        if (this.view) {
            this.close();
        } else {
            this.open(dropdownTpl, origin);
        }
    }

    open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
        if (this.view) {
            return;
        } else {
            this.view = this.vcr.createEmbeddedView(dropdownTpl);
            const dropdown = this.view.rootNodes[0];
            document.body.appendChild(dropdown);
            dropdown.style.width = `${origin.offsetWidth}px`;
            this.zone.runOutsideAngular(() => {
                this.popperRef = new Popper(origin, dropdown, {
                    removeOnDestroy: true
                });
            });
            this.loadPages();
        }
    }

    close() {
        if (this.popperRef) {
            this.popperRef.destroy();
            this.view.destroy();
            this.view = null;
            this.popperRef = null;
        }
    }

    isOpen(): boolean {
        return this.view != null;
    }

    pageSelected(page: Page) {
        let queryParams = {
            'source': this.router.url,
            'project': this.project ? this.project.id : this.projectId,
            'onsite': this.assessment.isAccepted ? 'true' : 'false'
        }
        this.router.navigate(
            ['takeoff', 'page', String(page.id)],
            { queryParams: queryParams });
    }

    loadPages() {
        let params = new HttpParams({});
        if (this.project) {
            params = params.set('project', String(this.project.id));
        } else if (this.projectId) {
            params = params.set('project', String(this.projectId));
        }
        if (this.assessment) {
            params = params.set('assessment', String(this.assessment.id));
        }
        this.pageListService.getMany(params).subscribe(pages => {
            this.pages = pages;
        });
    }

    routeToDrawingsList() {
        this.router.navigate(
            ['takeoff', 'pages'],
            {
                queryParams: {
                    'project': this.project ? this.project.id : this.projectId,
                    'assessment': this.assessment.id,
                    'onsite': this.assessment.isAccepted ? 'true' : 'false'
                }
            }
        );
    }
}
