'use strict';

angular.module('scswebappApp')
    .directive('operativeListModal', function ($rootScope) {

        return {
            templateUrl: "scripts/directives/modals/operative-list-modal/operative-list-modal.html",
            restrict: 'E',
            scope: {
                'multiSelect': '=?',
                'title': '=?',
                'control': '=',
                'tableIdentifier': '@?'
            },
            link: {
                pre: function preLink(scope, element, attrs) {
                    scope.operativeCreateModal = {};

                    if (!scope.multiSelect) {
                        scope.multiSelect = false;
                    }

                    if (!scope.tableIdentifier) {
                        scope.tableIdentifier = 'OperativeListModal';
                    }

                    scope.$on('operativeSaved', function (event, item) {
                        scope.operativeCreateModal.close();
                        scope.$broadcast('listReload' + scope.tableIdentifier);
                    });

                    $rootScope.$on('itemSelected' + scope.tableIdentifier, function () {
                        scope.control.close();
                    });
                }
            }
        };
    });
