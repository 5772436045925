(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.trades
	 * @description
	 * # trades
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
				 .factory('trades', ['Resource', 'ENV', function ($resource, ENV) {
	         return $resource(
	           ENV.apiEndpoint + '/trades/:id', {id: '@id'}, {
               query:{
                 cache: true,
								 isArray: true,
								 params: {
									 sort: 'name'
								 }
               }
	           }
	         );
				 }]);
})();
