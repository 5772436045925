(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.price
     * @description
     * # price
     * Service in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('projectWorkPackage', ['ENV', 'Resource', 'UNITSTEXT', function (ENV, $resource) {
            return $resource(
                ENV.apiEndpoint + '/projectpackages/:id', { id: '@id', project:'@project'}, {

                }
            );

        }]);
})();