import {CsUtils} from '../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:OperativeCtrl
     * @description
     * # OperativeCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('OperativeCtrl', function ($scope, operative, $rootScope, $routeParams, $route, $location, trades, FileUploader, subcontractor, $http, ENV, $window, COUNTRIES, routeType) {
			$scope.keyWord = routeType.type( $route.current.$$route.isNew, $route.current.$$route.isEdit );
            if ($routeParams.id) {
                $scope.operativeId = $routeParams.id;
                operative.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.operative = data;

                    $scope.operative.selectedTrades = data.trades;

                    angular.forEach($scope.operative.qualifications, function (data) {

                        var uploader = new FileUploader();

                        data.certificateData = {
                            uploader: uploader,
                            list: []
                        };
                    });

                    trades.query(function (data) {
                        $scope.trades = data;
                    }).$promise.then(function () {
                        angular.forEach($scope.operative.trades, function (trade) {
                            angular.forEach($scope.trades, function (availableTrade) {
                                if (trade.id == availableTrade.id) {
                                    availableTrade.ticked = true;
                                }
                            });
                        });
                    });

                }).then(function () {
                    subcontractor.query({limit: 5000, sort: "fullName", hydration: "array"}, function (data) {
                        $scope.subcontractors = data;
                        if ($scope.operative.parent) {
                            angular.forEach($scope.subcontractors, function (value) {
                                if (value.id === $scope.operative.parent.id) {
                                    value.ticked = true;
                                }
                            });
                        }
                    });
                });
            }

            $scope.cscsCardTypes = [
                {
                    name: "Labourer"
                },
                {
                    name: "Apprentice"
                },
                {
                    name: "Experienced Technical, Supervisor or Manager"
                },
                {
                    name: "Experienced Worker"
                },
                {
                    name: "Trainee (temporary card)"
                },
                {
                    name: "Trainee (craft and operative)"
                },
                {
                    name: "Trainee (technical, supervisory and management)"
                },
                {
                    name: "Skilled Worker"
                },
                {
                    name: "Advanced Craft/Supervisory"
                },
                {
                    name: "Manager"
                },
                {
                    name: "Academically Qualified Person"
                },
                {
                    name: "Construction Related Occupation (CRO)"
                },
                {
                    name: "Professionally Qualified Person"
                },
                {
                    name: "Site Visitor"
                },
                {
                    name: "Provisional"
                },
                {
                    name: "Construction Site Operative (CSO)"
                },
                {
                    name: "Plant Operator"
                }
            ];

            $scope.countries = COUNTRIES;

            $rootScope.$on('operativeSaved', function (e, data) {
                $location.path('/operative/' + data.id);
            });

            $scope.exportOperatives = function () {
                $http({
                    method: "GET",
                    responseType: "arraybuffer",
                    url: ENV.apiEndpoint + "/operatives/exportall"
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };
        });
})();
