(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.workscope
	 * @description
	 * # workscope
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('variation', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/variations/:id', {id: '@id'}, {
                    saveVariationDifference: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/variationquantitydifferences/:id'
                    },
                    getVariationDifferences: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/variationquantitydifferences',
                        isArray: true
                    }
	            }
	        );

	  }]);
})();