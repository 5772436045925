(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.externalCostInvoices
     * @description
     * # externalCostInvoices
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('externalCostInvoices', ['ENV', 'Resource', '$filter', function (ENV, $resource, $filter) {
            return $resource(
                ENV.apiEndpoint + '/costentityinvoices/:id', {id: '@id', onlygroups: '@onlygroups', groups: '@groups', projectId: '@projectId'}, {
                    post: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/costentityinvoices',
                        transformRequest: function (data) {
                            if (data.invoiceDate){
                                data.invoiceDate = $filter('date')(new Date(data.invoiceDate), 'yyyy-MM-dd HH:mm:ss');
                            }

                            return angular.toJson(data);
                        }
                    },
                    patch: {
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/costentityinvoices/:id',
                        transformRequest: function (data) {
                            if (data.invoiceDate){
                                data.invoiceDate = $filter('date')(new Date(data.invoiceDate), 'yyyy-MM-dd HH:mm:ss');
                            }
                            return angular.toJson(data);
                        }
                    },
                    approve: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/costentityinvoices/:id/approve'
                    },
                    queryInvoice: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/costentityinvoices/:id/query'
                    },
                    resolve: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/costentityinvoices/:id/resolve'
                    }
                }

            );
        }]);
})();
