'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:deleteButton
 * @description
 * # deleteButton
 * ( index, id to delete, objects array name (for splice), service )
 * <deletebutton data-id="{{product.id}}" data-redirect="product" data-redirectid="{{product.id}}" data-itemservice="product"></deletebutton>
 */
angular.module('scswebappApp')
    .directive('archivebutton', function ($injector, $rootScope, $location) {
        return {
            template: require('./archive.html'),
            restrict: 'E',
            link: function postLink(scope, element, attrs) {

                scope.archiveButtonCopy = '';

                // Check if item is already archived
                var method = 'archive';
                var confirmCopy = 'Confirm archive ';
                scope.title = 'Archive';
                if (attrs.archived) {
                    method = 'unarchive';
                    confirmCopy = 'Confirm unarchive ';
                    scope.title = 'Unarchive';
                }

                // Delete step 1
                scope.archiveItem = function () {
                    var redirectPath = (attrs.redirectid) ? '/' + attrs.redirect + '/' + attrs.redirectid : '/' + attrs.redirect;
                    // Inject the service
                    var service = $injector.get(attrs.itemservice);

                    if (scope.archiveButtonCopy === '') {
                        scope.archiveButtonCopy = confirmCopy;
                    } else {
                        console.log(attrs.archived);
                        console.log(method);
                        // Delete the item
                        service[method]({id: attrs.id}).$promise.then(function () {
                            scope.archiveButtonCopy = '';
                            scope.deleteConfirmCopy = [];
                            $location.path(redirectPath);
                        }).catch(function () {
                            scope.$emit('showOrNotification', 'This ' + attrs.itemservice + ' cannot be archived.' + usedIn, true);
                            scope.cancelArchive();
                        });
                    }
                };

                // Cancel deletion
                scope.cancelArchive = function () {
                    scope.archiveButtonCopy = '';
                };

            }
        };
    });
