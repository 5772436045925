import ManyAssociationPatcher from '../../other/ManyAssociationPatcher.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:QuoteCtrl
     * @description
     * # QuoteCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('LabourQuoteCtrl', function ($routeParams, $rootScope, $route, $scope, subcontractor, project, FileUploader, labourQuote, $location, routeType, material, labourrate, $locale) {

            $scope.quoteId = $routeParams.id;
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);
            if ($scope.keyWord && $scope.keyWord !== '') {
                $scope.title = `${$scope.keyWord} labour quote`;
            } else {
                $scope.title = 'Labour quote';
            }
            $scope.patcher = new ManyAssociationPatcher('product');

            $scope.fileUploader = {
                uploader: new FileUploader
            };

            $scope.quote = {
                documents: [],
                prices: []
            };
            $scope.pinnedRows = [];
            $scope.quotePrices = [];

            $scope.showDuplicate = false;

            // Start and expiry dates
            let year = new Date().getFullYear();

            $scope.nextYear = parseInt(year + 1);

            $scope.quoteDate = new Date();
            $scope.quoteExpires = new Date();
            $scope.quoteExpires.setYear($scope.nextYear);

            if ($route.current.$$route.isNew) {
                $scope.priceTableParams = {quote: 0};
                subcontractor.query({limit: 5000}).$promise.then(function (data) {
                    $scope.subcontractors = data;
                }).then(function () {
                    project.query({limit: 5000}).$promise.then(function (data) {
                        $scope.projects = data;
                    });
                });
            } else {
                $scope.priceTableParams = {quote: $routeParams.id};
            }

            if ($route.current.$$route.isSingle) {
                labourQuote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                });
            }

            if ($route.current.$$route.isEdit) {
                labourQuote.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.quote = data;
                }).then(function (data) {
                    return subcontractor.query({limit: 5000}).$promise;
                }).then(function (data) {
                    // Map Subcontractors
                    angular.forEach(data, function (value) {
                        if ($scope.quote.subcontractor && value.id === $scope.quote.subcontractor.id) {
                            value.ticked = true;
                        }
                    });
                    $scope.subcontractors = data;
                }).then(function () {
                    return project.query({limit: 5000}).$promise;
                }).then(function (data) {
                    // Map projects
                    angular.forEach($scope.quote.projects, function (value) {
                        angular.forEach(data, function (value2) {
                            if (value.id === value2.id) {
                                value2.ticked = true;
                            }
                        })
                    });
                    $scope.projects = data;
                })
            }

            // Remove Labour Activity
            $scope.removeLabourActivity = function (price, index) {
                if (price.id) {
                    // Removing a live price
                    if (confirm('Are you sure you want to delete this rate?')) {
                        labourrate.delete({id: price.id}).$promise.then(function (data) {
                            console.log(data);
                        });
                    }
                } else {
                    // Creating; don't care about price indexes
                    $scope.quote.prices.splice(index, 1);
                }
            };

            $scope.toggleModal = function () {
                $scope.showDuplicate = !$scope.showDuplicate;
            };

            $scope.duplicate = function () {
                $scope.duplicateSubmitted = true;

                if ($scope.duplicateForm.$valid) {
                    labourQuote.clone({
                        sourceQuote: $scope.quoteId,
                        modifier: $scope.duplicateData.modifier,
                        name: $scope.duplicateData.name
                    }).$promise.then(function (data) {
                        $scope.duplicateForm.$setPristine();
                        $scope.quoteForm.$setPristine();
                        $location.path('/labour-quote/' + data.id);
                        $scope.duplicateSubmitted = false;
                    });
                }
            };

            // Save quote
            $scope.saveQuote = function () {
                $scope.quote.quoteDate = $scope.quoteDate.toISOString().split("T")[0];;
                $scope.quote.quoteExpires = $scope.quoteExpires.toISOString().split("T")[0];;

                $scope.submitted = true;
                let saveQuote = new labourQuote($scope.quote);

                $scope.subcontractorError = $scope.quote.subcontractor.length < 1;

                if ($scope.quoteForm.$valid && !$scope.subcontractorError) {
                    if ($scope.quoteId) {
                        let saveQuoteMethod = function () {
                            labourQuote.update(saveQuote).$promise.then(function () {
                                $scope.quoteForm.$setPristine();

                                if ($route.current.$$route.isEdit) {
                                    $location.path('/labour-quote/' + $scope.quoteId);
                                }
                            });
                        };

                        let operations = $scope.patcher.getChangeSet();
                        if ($scope.patcher.isDirty()) {
                            labourQuote.patchPrices({id: $scope.quoteId}, operations, function () {
                                $scope.patcher.clear();
                                saveQuoteMethod();
                            });
                        } else {
                            saveQuoteMethod();
                        }
                    } else {
                        angular.forEach($scope.patcher.getAddQueue(), function (newPriceObject, productId) {
                            saveQuote.prices.push({
                                'product': productId,
                                'price': newPriceObject.price
                            });
                        });

                        saveQuote.$save().then(function (data) {
                            $scope.quoteForm.$setPristine();
                            $location.path('/labour-quote/' + data.id);
                        });
                    }
                }
            };

            // Materials table / selection / new material
            $scope.newLabourActivity = false;
            $scope.showNewLabourActivity = function () {
                $scope.newLabourActivity = true;

                //Scroll to top
                window.scrollTo(0, 0);
            };
            $scope.$on('newLabourActivityAdded', function (event, item) {
                item.productId = item.id; // So it matches the format returned by the price controller
                $scope.patcher.addAddOperation(
                    item,
                    {
                        'product': item.id
                    },
                    item.id
                );
                $scope.pinnedRows.push(item);

                $scope.newLabourActivity = false;
                $scope.selectLabourActivity = false;

                if ($scope.patcher.isDirty()) {
                    $scope.quoteForm.$setDirty();
                }
            });
            $scope.closeNewLabourModal = function () {
                $scope.newLabourActivity = false;
            };
            $scope.closeLabourModal = function () {
                $scope.selectLabourActivity = false;
            };

            // Add Labour Activity
            $scope.addLabourActivity = function () {
                $scope.selectLabourActivity = true;
            };

            $scope.changeLabourActivity = function (index) {
                $scope.labourActivityToRemove = index;
                $scope.selectLabourActivity = true;
                $scope.priceIndex = index;
            };

            let isUniqueActivity = function(item) {
                let isUnique = true;
                angular.forEach($scope.pinnedRows, function (pr) {
                    if (pr.id === item.id) {
                        isUnique = false;
                    }
                });
                angular.forEach($scope.quotePrices, function (qp) {
                    if (qp.productId === item.id) {
                        isUnique = false;
                    }
                });
                return isUnique;
            };
            $scope.setQuotePrices = function (prices) {
                $scope.quotePrices = prices;
            };

            $scope.activitiesSelected = function (activities) {
                angular.forEach(activities, function (activity) {
                    activity.productId = activity.id; // So it matches the format returned by the price controller
                    if (isUniqueActivity(activity)) {
                        $scope.patcher.addAddOperation(
                            activity,
                            {
                                'product': activity.id
                            },
                            activity.id
                        );
                        $scope.pinnedRows.push(activity);
                    }
                });

                $scope.selectLabourActivity = false;

                if ($scope.patcher.isDirty()) {
                    $scope.quoteForm.$setDirty();
                }
            };
        });
})();
