'use strict';

import {EditRateBuildUpProductHelpers} from './edit-rate-build-up-product-helpers';
import {isNumber} from "util";

angular.module('scswebappApp')
       .directive('editRateBuildUpProduct', function(
           $filter,
           TableFactory,
           RateBuildUpProduct,
           assessmentLabourComponent,
           assessmentLabourComponentMaterial,
           assessmentLabourComponentActivity,
           assessmentMaterial,
           assessmentProduct,
           assessment,
           externallink,
           FileUploader,
           $anchorScroll,
           $rootScope,
           $sce,
           SHORTUNITSTEXT,
           PermPermissionStore,
           file,
           nxRbuIssueService
       ) {
           return {
               template: require('./edit-rate-build-up-product-directive.html'),
               restrict: 'E',
               scope: {
                   'project': '=',
                   'assessment': '=',
                   'assessmentId': '=',
                   'assessmentProduct': '=',
                   'assessmentProducts': '=',
                   'assessmentProductIndex': '=',
                   'estimateValues': '=',
                   'currencySymbol': '=',
                   'closeEditProductModal': '&',
                   'onProductReplaced': '&'
               },
               link: function(scope) {
                   const helper = new EditRateBuildUpProductHelpers(nxRbuIssueService);

                   scope.writePermission = scope.assessment.isAccepted ?
                       'canWriteProjectHubPackageAssessments' : 'canWriteProjectsOffsiteAssessments';
                   scope.hasWritePermission = !(!!scope.assessment.snapshotOf) &&
                       PermPermissionStore.hasPermissionDefinition(scope.writePermission);
                   scope.tables = {};
                   scope.addMaterialForm = {};
                   scope.swapMaterialForm = {};
                   scope.swapProductForm = {conversionFactor: 1};
                   scope.addLabourActivityForm = {};
                   scope.swapLabourActivityForm = {};
                   scope.addLabourComponentForm = {};
                   scope.moveComponentForm = {after: 0};
                   scope.scaleComponentForm = {
                       factor: 1,
                       type: 'multiply'
                   };

                   if (!scope.assessmentProducts){
                       let productsQuery = {
                           assessment: scope.assessment.id,
                           limit: 3000,
                           sort: 'assessmentOrder'
                       };

                       assessment.getAssessmentProductsList(productsQuery).$promise.then(function (data) {
                           scope.assessmentProducts = data;
                           for (let i = 0; i<scope.assessmentProducts.length; i++){
                               if (scope.assessmentProduct.id === scope.assessmentProducts[i].id){
                                   scope.assessmentProductIndex = i;
                               }
                           }
                       });
                   }
                   scope.productTemplateForm = {};
                   scope.addLinkForm = {};
                   scope.productTemplate = {};
                   scope.documentsOpen = false;
                   scope.componentJumpListOpen = false;
                   scope.renameComponentModal = false;
                   scope.renameProductModal = false;
                   scope.newName = null;
                   scope.swap = null;
                   scope.loading = false;

                   const quantityTag = 'quantity';
                   const wasteTag = 'waste';
                   const swapTag = 'swap';
                   const editQuoteTag = 'editQuote';
                   const deleteTag = 'delete';
                   const configurationTag = 'configuration';
                   const addMaterialTag = 'addMaterial';
                   const addActivityTag = 'addActivity';
                   const reorderMaterialsTag = 'reorderMaterials';
                   const reorderActivitiesTag = 'reorderActivities';
                   const sectionHeader = -1;
                   const sectionMaterials = 0;
                   const sectionActivities = 1;
                   const dateFormat = 'dd/MM/yyyy';

                   /*
                    * Generate and handle table events
                    */

                   // disable mousewheel on an input number field when in focus
                   document.addEventListener('wheel', function(){
                       if(document.activeElement.type === 'number') {
                           document.activeElement.blur();
                       }
                   });

                   const reload = (component, callback) => {
                       scope.loading = true;
                       helper.reload(scope, assessment, component, () => {
                           scope.loading = false;
                           if (callback) {
                               callback();
                           }
                       });
                   };

                   scope.showPreviousProduct = () => {
                       if (scope.assessmentProductIndex > 0) {
                           scope.assessmentProductIndex -= 1;
                           scope.assessmentProduct = scope.assessmentProducts[scope.assessmentProductIndex];
                           if (!scope.assessmentProduct.assessmentLabourComponents) {
                               console.log("Delay")
                               scope.ensureProductFullyLoaded();
                           }else{
                               reload();
                           }
                       }
                   };

                   scope.showNextProduct = () => {
                       if (scope.assessmentProductIndex < scope.assessmentProducts.length - 1) {
                           scope.assessmentProductIndex += 1;
                           scope.assessmentProduct = scope.assessmentProducts[scope.assessmentProductIndex];
                           if (!scope.assessmentProduct.assessmentLabourComponents) {
                               scope.ensureProductFullyLoaded();
                           }else{
                               reload();
                           }
                       }
                   };

                   let assessmentProductTimeout;
                   let requestCounter = 0;

                   scope.delayedAssessmentProductRequest = function delayedAssessmentProductRequest() {
                       let currentRequest = ++requestCounter;

                       if (assessmentProductTimeout) {
                           clearTimeout(assessmentProductTimeout);
                       }

                       return new Promise(function(resolve, reject) {
                           assessmentProductTimeout = setTimeout(function() {
                               assessment.getAssessmentProduct({
                                   id: scope.assessmentProduct.id,
                                   groups: ['products_externalLinks', 'prices_view']
                               }).$promise.then(function(response) {
                                   if (requestCounter === currentRequest) {
                                       resolve(response);
                                   }
                               }, function(error) {
                                   if (requestCounter === currentRequest) {
                                       reject(error);
                                   }
                               });
                           }, 500);
                       });
                   };

                   scope.ensureProductFullyLoaded = function ensureProductFullyLoaded() {
                       return scope.delayedAssessmentProductRequest().then(function(data) {
                           scope.assessmentProducts[scope.assessmentProductIndex] = data;
                           scope.assessmentProduct = data;
                           scope.assessmentProducts[scope.assessmentProductIndex].extraLoaded = true;
                           scope.createTables();
                       });
                       // return assessment.getAssessmentProduct({
                       //     id: scope.assessmentProduct.id,
                       //     groups: ['products_externalLinks', 'prices_view']
                       // }).$promise.then(function (data) {
                       //     scope.assessmentProducts[scope.assessmentProductIndex] = data;
                       //     scope.assessmentProduct = data;
                       //     scope.assessmentProducts[scope.assessmentProductIndex].extraLoaded = true;
                       //     scope.createTables();
                       // });
                   };

                   scope.generateTable = function generateTable(component) {
                       // Do an in-place sort on the component to ensure that materials
                       // and activities are sorted according to displayOrder.
                       helper.sortComponentMaterialsAndActivities(component);
                       return {
                           columns: [
                               // Material or activity icon
                               TableFactory.iconColumn((section) => {
                                   if (section === sectionMaterials) {
                                       return 'cubes';
                                   } else {
                                       return 'user';
                                   }
                               }, null),
                               // Name of material or activity
                               TableFactory.textColumn((section, row) => {
                                   switch (section) {
                                       case sectionMaterials:
                                           return component.assessmentLabourComponentMaterials[row].name;
                                       case sectionActivities:
                                           return component.assessmentLabourComponentActivities[row].name;
                                       default:
                                           return null;
                                   }
                               }, (section, row) => {
                                   switch (section) {
                                       case sectionMaterials:
                                           const material = component.assessmentLabourComponentMaterials[row];
                                           return TableFactory.tooltip(
                                               material.name,
                                               [
                                                   TableFactory.tooltipRow(material.configurationName, 'Configuration:', [() => [
                                                       material.configurationName,
                                                       `(${material.selectedConfiguration.singularSize})`,
                                                       $filter('units')(material.originalMaterial.quantityUnit, 'UNITSTEXT'),
                                                       'per',
                                                       `${material.originalMaterial.singularName})`].join(' ')
                                                                                                                         ]),
                                                   TableFactory.tooltipRow(true, 'Brand:', [() => material.originalMaterial.brand]),
                                                   TableFactory.tooltipRow(true, 'Type:', [() => material.originalMaterial.materialType.name]),
                                                   TableFactory.tooltipRow(true, 'Specifics:', [() => material.originalMaterial.specifics]),
                                                   TableFactory.tooltipRow(true, 'Size:', [() => [
                                                       material.configurationName ? material.selectedConfiguration.singularSize : material.originalMaterial.singularSize,
                                                       $filter('units')(material.originalMaterial.quantityUnit, 'UNITSTEXT'),
                                                       'per',
                                                       material.originalMaterial.singularName,
                                                       '-',
                                                       material.configurationName ? material.selectedConfiguration.packSize : material.originalMaterial.packSize,
                                                       material.originalMaterial.singularName,
                                                       'per',
                                                       material.originalMaterial.packName
                                                   ].join(' ')])
                                               ],
                                               true,
                                               false
                                           );
                                       case sectionActivities:
                                           const activity = component.assessmentLabourComponentActivities[row];
                                           return TableFactory.tooltip(
                                               activity.name,
                                               [
                                                   TableFactory.tooltipRow(true, 'Trade:', [() => activity.originalLabourActivity.tradeName]),
                                                   TableFactory.tooltipRow(true, 'Description:', [() => activity.originalLabourActivity.description]),
                                                   TableFactory.tooltipRow(true, 'Unit:', [() => $filter('units')(activity.quantityUnit, 'UNITSTEXT')])
                                               ],
                                               true
                                           );
                                       default:
                                           return null;
                                   }
                               }, null, true),
                               // Swap material/activity
                               TableFactory.editColumn(swapTag, (section) => {
                                   let tooltip;
                                   switch (section) {
                                       case sectionMaterials:
                                           tooltip = 'Click to swap material';
                                           break;
                                       case sectionActivities:
                                           tooltip = 'Click to swap labour activity';
                                           break;
                                       default:
                                           tooltip = null;
                                   }
                                   if (tooltip) {
                                       return TableFactory.tooltip(tooltip);
                                   } else {
                                       return null;
                                   }
                               }),
                               // Configuration
                               TableFactory.dropdownColumn(configurationTag, (section, row) => {
                                   if (section === sectionActivities) {
                                       return [];
                                   } else {
                                       const material = component.assessmentLabourComponentMaterials[row];
                                       if (material.originalMaterial.configurations) {
                                           return material.originalMaterial.configurations.map(
                                               configuration => TableFactory.dropdownItem(
                                                   configuration.id,
                                                   configuration.name,
                                                   material.selectedConfiguration && material.selectedConfiguration.id === configuration.id)
                                           );
                                       } else {
                                           return [];
                                       }
                                   }
                               }, null, null, 'Configuration'),
                               // Quantity
                               TableFactory.numberColumn(quantityTag, (section, row) => {
                                   if (section === sectionMaterials) {
                                       return component.assessmentLabourComponentMaterials[row].quantityPerLabourComponent;
                                   } else {
                                       return component.assessmentLabourComponentActivities[row].quantityPerLabourComponent;
                                   }
                               }, (section) => {
                                   if (section === sectionHeader) {
                                       return TableFactory.tooltip('Enter quantity required per unit of product');
                                   } else {
                                       return null;
                                   }
                               }, 'Quantity', () => true),
                               // Quantity unit
                               TableFactory.htmlColumn((section, row) => {
                                   let quantityUnit;
                                   if (section === sectionMaterials) {
                                       quantityUnit = component.assessmentLabourComponentMaterials[row].originalMaterial.quantityUnit;
                                   } else {
                                       quantityUnit = component.assessmentLabourComponentActivities[row].originalLabourActivity.quantityUnit;
                                   }
                                   return helper.htmlShortUnit(quantityUnit, SHORTUNITSTEXT);
                               }),
                               // Waste
                               TableFactory.percentageColumn(wasteTag, (section, row) => {
                                   if (section === sectionMaterials) {
                                       const value = component.assessmentLabourComponentMaterials[row].wasteAllowancePercentage;
                                       return value === null ? Number.NaN : value;
                                   } else {
                                       // No waste for activities
                                       return null;
                                   }
                               }, (section) => {
                                   if (section === sectionHeader) {
                                       return TableFactory.tooltip('Enter wastage allowance for materials');
                                   } else {
                                       return null;
                                   }
                               }, 'Waste', () => true),
                               // Quote price
                               TableFactory.htmlColumn((section, row) => {
                                   if (section === sectionMaterials) {
                                       return helper.priceBadge(component.assessmentLabourComponentMaterials[row].selectedPrice);
                                   } else {
                                       return helper.priceBadge(component.assessmentLabourComponentActivities[row].selectedLabourRate);
                                   }
                               }, (section, row) => {
                                   if (section === sectionHeader) {
                                       return null;
                                   }
                                   let price = null;
                                   if (section === sectionMaterials) {
                                       price = component.assessmentLabourComponentMaterials[row].selectedPrice;
                                   } else {
                                       price = component.assessmentLabourComponentActivities[row].selectedLabourRate;
                                   }
                                   if (price === null) {
                                       return TableFactory.tooltip('To select a price click the edit button to the right of this cell', [], false, false);
                                   } else {
                                       return TableFactory.tooltip(
                                           price.quote ? price.quote.name : null,
                                           [
                                               TableFactory.tooltipRow(
                                                   !price.quote && price.createdAt,
                                                   'Created at:',
                                                   [() => $filter('date')(price.createdAt, dateFormat)]),
                                               TableFactory.tooltipRow(
                                                   !price.quote && price.createdBy,
                                                   'Created by:',
                                                   [() => price.createdBy]),
                                               TableFactory.tooltipRow(
                                                   price.unquotedSupplier || (price.quote && price.quote.supplier),
                                                   'Supplier:',
                                                   [
                                                       () => price.quote && price.quote.supplier ? price.quote.supplier.fullName : null,
                                                       () => price.unquotedSupplier && (price.quote === null || !price.quote.supplier || price.quote.supplier.fullName !== price.unquotedSupplier.fullName) ? price.unquotedSupplier.fullName : null
                                                   ]
                                               ),
                                               TableFactory.tooltipRow(
                                                   price.unquotedSubcontractor || (price.quote && price.quote.subcontractor),
                                                   'Subcontractor:',
                                                   [
                                                       () => price.unquotedSubcontractor ? price.unquotedSubcontractor.fullName : null,
                                                       () => price.quote && price.quote.subcontractor ? price.quote.subcontractor.fullName : null
                                                   ]
                                               ),
                                               TableFactory.tooltipRow(
                                                   price.region && price.region.id,
                                                   'Region:'
                                                   [() => price.region.name]
                                               ),
                                               TableFactory.tooltipRow(
                                                   price.quote && price.quote.quoteDate,
                                                   'Valid from:',
                                                   [() => $filter('date')(price.quote.quoteDate, dateFormat)]
                                               ),
                                               TableFactory.tooltipRow(
                                                   price.quote && price.quote.quoteExpires,
                                                   'Valid to:',
                                                   [() => $filter('date')(price.quote.quoteExpires, dateFormat)]
                                               ),
                                               TableFactory.tooltipRow(
                                                   price.quote && price.quote.archivedAt,
                                                   'Archived:',
                                                   [() => $filter('date')(price.quote.archivedAt, dateFormat)]
                                               )
                                           ], false, false
                                       );
                                   }
                               }, 'Quote'),
                               // Edit quote price
                               TableFactory.editPriceColumn(editQuoteTag, function(section, row) {
                                   if (section === sectionMaterials) {
                                       let material = component.assessmentLabourComponentMaterials[row];
                                       return {
                                           item: material,
                                           selectedPrice: material.selectedPrice,
                                           serviceParams: {
                                               project: scope.project.id,
                                               material: material.originalMaterial.id
                                           }
                                       };
                                   } else {
                                       let activity = component.assessmentLabourComponentActivities[row];
                                       return {
                                           item: activity,
                                           selectedPrice: activity.selectedLabourRate,
                                           serviceParams: {
                                               project: scope.project.id,
                                               activity: activity.originalLabourActivity.id
                                           }
                                       };
                                   }
                               }, function(section) {
                                   if (section === sectionActivities) {
                                       return TableFactory.tooltip('Click to select a subcontractor price');
                                   } else {
                                       return TableFactory.tooltip('Click to select a supplier price');
                                   }
                               }, null, null),
                               // Selected quote price
                               TableFactory.currencyColumn(function(section, row) {
                                   let price;
                                   if (section === sectionMaterials) {
                                       price = component.assessmentLabourComponentMaterials[row].assessmentPrice;
                                   } else {
                                       price = component.assessmentLabourComponentActivities[row].assessmentPrice;
                                   }
                                   return price;
                               }, null, null),
                               // Calculated product unit cost
                               function() {
                                   const column = TableFactory.htmlColumn(function(section, row) {
                                       let rate = 0;
                                       if (section === sectionMaterials) {
                                           const material = component.assessmentLabourComponentMaterials[row];
                                           if (material.assessmentPrice) {
                                               const materialCost = Math.round(material.assessmentPrice * material.quantityPerLabourComponent * 100) / 100;
                                               const wasteCost = Math.round(material.assessmentPrice * material.quantityPerLabourComponent * material.wasteAllowancePercentage / 100 * 100) / 100;
                                               rate = materialCost + wasteCost;
                                           }
                                       } else if (section === sectionActivities) {
                                           const activity = component.assessmentLabourComponentActivities[row];
                                           if (activity.assessmentPrice) {
                                               rate = activity.assessmentPrice * activity.quantityPerLabourComponent;
                                           }
                                       } else {
                                           return null;
                                       }
                                       const unit = helper.htmlShortUnit(scope.assessmentProduct.quantityUnit, SHORTUNITSTEXT);
                                       const formattedRate = $filter('currency')(rate);
                                       return `= ${formattedRate} per ${unit}`;
                                   }, function(section) {
                                       if (section === sectionHeader) {
                                           return TableFactory.tooltip('This is the product unit cost/rate, automatically calculated from the defined quantity (including any waste) and the selected supplier/subcontractor pricing.');
                                       }
                                   }, 'Calculated product unit cost', true, true);
                                   column.disableIfRowEdited = true;
                                   return column;
                               }(),
                               // Delete component
                               TableFactory.deleteColumn(deleteTag, null, function(section) {
                                   switch (section) {
                                       case sectionMaterials:
                                           return TableFactory.tooltip('Click to delete material');
                                       case sectionActivities:
                                           return TableFactory.tooltip('Click to delete labour activity');
                                       default:
                                           return null;
                                   }
                               })
                           ],
                           // First section is materials, second labour activity
                           sectionCount: 2,
                           // Return number of materials or activities to display in each section
                           rowCount: function(section) {
                               if (section === sectionMaterials) {
                                   return component.assessmentLabourComponentMaterials.length;
                               } else {
                                   return component.assessmentLabourComponentActivities.length;
                               }
                           },
                           // Allow users to add new materials or activities
                           sectionAddRowFooters: [
                               {
                                   icon: 'plus',
                                   title: 'Add material',
                                   cssClass: 'add',
                                   tag: addMaterialTag
                               },
                               {
                                   icon: 'plus',
                                   title: 'Add labour activity',
                                   cssClass: 'add',
                                   tag: addActivityTag
                               }
                           ],
                           columnWidths: [
                               40,     // Material/activity icon
                               230,    // Material/activity name
                               40,     // Swap icon
                               200,    // Configuration
                               64,     // Quantity
                               80,     // Unit
                               80,     // Waste
                               120,    // Quote lozenge
                               40,     // Quote edit icon
                               120,    // Price
                               0,      // Calculated product unit cost (take up rest of space)
                               40      // Delete icon
                           ]
                       };
                   };

                   scope.onAction = function(event, component, assessmentProduct) {
                       const tag = event.tag;  // Will be one of 'swap', 'price', 'delete'
                       scope.swap = null;
                       if (tag === addMaterialTag) {
                           addMaterialToComponent(component);
                       } else if (tag === addActivityTag) {
                           addLabourActivityToComponent(component);
                       } else if (tag === reorderMaterialsTag) {
                           helper.reorderMaterials(scope, assessment, component, assessmentLabourComponentMaterial);
                       } else if (tag === reorderActivitiesTag) {
                           helper.reorderActivities(scope, assessment, component, assessmentLabourComponentActivity);
                       } else {
                           const row = event.row;
                           switch (event.section) {
                               case sectionMaterials:
                                   const material = component.assessmentLabourComponentMaterials[row];
                                   switch (tag) {
                                       case swapTag:
                                           scope.swap = swapTag;
                                           helper.chooseSwapMaterial(scope, {
                                               assessmentProduct: assessmentProduct,
                                               assessmentLabourComponentMaterial: material,
                                               assessmentLabourComponent: component
                                           });
                                           break;
                                       case deleteTag:
                                           helper.removeMaterial(scope, component, material, assessmentMaterial, assessment);
                                           break;
                                   }
                                   break;
                               case sectionActivities:
                                   const activity = component.assessmentLabourComponentActivities[row];
                                   switch (tag) {
                                       case swapTag:
                                           scope.swap = swapTag;
                                           assessment.getLabourActivityApplied({assessment: scope.assessmentId, activity:activity.originalLabourActivity.id}).$promise.then(function (data) {
                                               helper.chooseSwapLabourActivity(scope, {
                                                   assessmentLabourComponentActivity: activity,
                                                   assessmentLabourComponent: component
                                               }, data, $rootScope);
                                           });
                                           break;
                                       case deleteTag:
                                           helper.removeActiveLabourActivity(scope, component, activity, assessmentLabourComponentActivity, assessment);
                                           break;
                                   }
                                   break;
                           }
                       }
                   };

                   scope.onChange = function(event, component, callback) {
                       const row = event.row;
                       const tag = event.tag;  // Will be one of 'quantity', 'waste'
                       const value = event.value;

                       switch (event.section) {
                           case sectionMaterials:
                               const material = component.assessmentLabourComponentMaterials[row];

                               switch (tag) {
                                   case configurationTag:
                                       helper.updateMaterialConfiguration(scope, component, material, value, callback, assessmentMaterial, assessment);
                                       break;
                                   case quantityTag:
                                       helper.updateMaterialQuantity(scope, component, material, parseFloat(value), callback, assessmentLabourComponentMaterial, assessment);
                                       break;
                                   case wasteTag:
                                       helper.updateMaterialWaste(scope, component, material, parseFloat(value), callback, assessmentMaterial, assessment);
                                       break;
                                   case editQuoteTag:
                                       helper.updateMaterialPrice(scope, component, material, value, callback, assessmentMaterial, assessment);
                                       break;
                               }
                               break;
                           case sectionActivities:
                               const activity = component.assessmentLabourComponentActivities[row];

                               if (tag === quantityTag) {
                                   helper.updateActivityQuantity(scope, component, activity, parseFloat(value), callback, assessmentLabourComponentActivity, assessment);
                               } else if (tag === editQuoteTag) {
                                   helper.updateActivityRate(scope, component, activity, value, callback, assessmentLabourComponentActivity, assessment);
                               }
                               break;
                       }
                   };

                   scope.onMultipleChanges = function(event, component) {
                       const reloadAll = event.some(change => change.tag === editQuoteTag);
                       const makeChanges = function(remainingChanges) {
                           if (remainingChanges.length === 0) {
                               if (reloadAll) {
                                   reload();
                               } else {
                                   reload(component);
                               }
                           } else {
                               const change = remainingChanges.shift();
                               scope.onChange(change, component, () => makeChanges(remainingChanges));
                           }
                       };
                       makeChanges(event);
                   };

                   scope.reloadProduct = function() {
                       reload();
                   };

                   /*
                    * Edit product name and description
                    */
                   scope.showRenameProductModal = function () {
                       scope.newName = {
                           old: scope.assessmentProduct,
                           name: scope.assessmentProduct.name,
                           description: scope.assessmentProduct.description,
                           pricingNotes: scope.assessmentProduct.pricingNotes,
                           externalNotes: scope.assessmentProduct.externalNotes
                       };
                       scope.renameProductModal = true;
                   };
                   scope.closeRenameModal = function () {
                       scope.resetRename();
                   };
                   scope.renameProduct = function () {
                       assessment.patchAssessmentProducts(
                           {id: scope.newName.old.id},
                           {
                               name: scope.newName.name,
                               description: scope.newName.description,
                               pricingNotes: scope.newName.pricingNotes,
                               externalNotes: scope.newName.externalNotes
                           }).$promise.then(function () {
                               scope.assessmentProduct.name = scope.newName.name;
                               scope.assessmentProduct.description = scope.newName.description;
                               scope.assessmentProduct.pricingNotes = scope.newName.pricingNotes;
                               scope.assessmentProduct.externalNotes = scope.newName.externalNotes;
                               scope.resetRename();
                           }).catch(function () {
                               scope.resetRename();
                           });
                   };
                   scope.resetRename = function () {
                       scope.renameProductModal = false;
                       scope.newName = null;
                   };

                   /*
                    * Supply & fit
                    */
                   scope.makeSupplyAndFit = function () {
                       scope.confirmationQuestion = 'Convert ' + scope.assessmentProduct.name + ' to supply & fit?';
                       scope.confirmationConfirm = function () {
                           assessmentProduct.patch({id: scope.assessmentProduct.id, pricingMode: 'sf'}).$promise.then(function () {
                               scope.confirmModal.close();
                               reload();
                           });
                       };
                       scope.confirmModal.open();
                   };
                   scope.unmakeSupplyAndFit = function () {
                       scope.confirmationQuestion = 'Return ' + scope.assessmentProduct.name + ' to materials & labour?';
                       scope.confirmationConfirm = function () {
                           assessmentProduct.patch({id: scope.assessmentProduct.id, pricingMode: 'ml'}).$promise.then(function () {
                               scope.confirmModal.close();
                               reload();
                           });
                       };
                       scope.confirmModal.open();
                   };
                   scope.saveProductSupplyAndFitPrice = function(pricedItem, oldPrice, newPrice, product) {
                       helper.updateSupplyAndFitPrice(scope, product, newPrice, assessmentProduct, assessment);
                   };
                   scope.saveSupplyAndFitWeight = function (component) {
                       assessment.patchLabourComponent({id: component.id}, {supplyAndFitWeight: component.supplyAndFitWeight})
                           .$promise.then(function () {
                               reload(component);
                       });
                   };

                   /*
                    * Delete product
                    */
                   scope.confirmDeleteProduct = function() {

                       assessment.quantityInTakeoff({id: scope.assessmentProduct.id}).$promise.then(function (data) {
                           if(data.hasQuantity) {
                               scope.confirmationQuestion = 'Are you sure you want to delete this product? This product contains quantities in the bill that have been measured from takeoff. If you delete this product you will also delete all that data.';
                           } else {
                               scope.confirmationQuestion = 'Are you sure you want to delete this product?';
                           }
                       });

                       scope.confirmationConfirm = function() {
                           scope.confirmModal.close();
                           assessment.deleteAssessmentProduct({id: scope.assessmentProduct.id})
                                     .$promise.then(function () {
                                         scope.confirmModal.onClose = function() {
                                             scope.closeEditProductModal();
                                         };
                                         scope.confirmModal.close();
                                     });
                       };
                       scope.confirmModal.open();
                   };

                   /*
                    * Show bill of quantities
                    */
                   scope.showBillOfQuantities = function () {
                       scope.boqForcedParams = {
                           id: scope.assessment.id,
                           assessmentProduct: scope.assessmentProduct.id
                       };
                       scope.boqModal.open();
                   };

                   /*
                    * Update overhead percentage
                    */
                   scope.saveOverheadPercentage = function () {
                       assessment.patchAssessmentProducts({id: scope.assessmentProduct.id}, {overheadPercentage: scope.assessmentProduct.overheadPercentage})
                                 .$promise.then(function () {
                                     reload();
                                 });
                   };

                   /*
                    * Update profit percentage
                    */
                   scope.saveProfitPercentage = function () {
                       assessment.patchAssessmentProducts({id: scope.assessmentProduct.id}, {profitPercentage: scope.assessmentProduct.profitPercentage})
                                 .$promise.then(function () {
                                     reload();
                                 });
                   };

                   /*
                    * Update inflation allowance
                    */
                   scope.saveInflationAllowancePercentage = function () {
                       assessment.patchAssessmentProducts({id: scope.assessmentProduct.id}, {inflationPercentage: scope.assessmentProduct.inflationPercentage})
                                 .$promise.then(function () {
                                     reload();
                       });
                   };

                   /*
                    * Contingency budget
                    */
                   scope.saveContingencyBudget = function(component) {
                       assessment.patchLabourComponent({id: component.id}, {contingencyBudget: component.contingencyBudget})
                                 .$promise.then(function () {
                                     reload(component);
                       });
                   };

                   /*
                    * Documents and external links
                    */
                   scope.fileUploader = {
                       uploader: new FileUploader(),
                       list: []
                   };
                   scope.uploadForm = {datasheets: []};
                   scope.uploadDatasheet = function () {
                       const file = angular.isArray(scope.fileUploader.list) ? scope.fileUploader.list[0] : scope.fileUploader.list;
                       assessmentProduct.addDatasheet({
                           id: scope.uploadDatasheetModal.getContext().assessmentProduct.id,
                           fileId: file.id
                       }).$promise.then(function () {
                           scope.uploadDatasheetModal.close();
                           reload();
                       });
                   };
                   scope.showHideDocuments = function () {
                       scope.documentsOpen = !scope.documentsOpen;
                   };
                   scope.addLink = function () {
                       assessmentProduct.addExternalLink({id: scope.addExternalLinkModal.getContext().assessmentProduct.id}, {
                           label: addLinkForm.label.value,
                           url: addLinkForm.url.value
                       }).$promise.then(function () {
                           scope.addExternalLinkModal.close();
                           reload();
                       });

                   };
                   scope.deleteDatasheet = function (assessmentProduct, datasheet) {
                       scope.confirmationQuestion = 'Are you sure you want to remove ' + datasheet.fileName + ' from Product ' + assessmentProduct.name + '?';
                       scope.confirmationConfirm = function () {
                           const removeFile = new file({id: datasheet.id});

                           removeFile.$remove().then(function () {
                               assessmentProduct.dataSheets.deleteOne('id', datasheet.id);
                               scope.confirmModal.close();
                               reload();
                           });
                       };
                       scope.confirmModal.open();
                   };
                   scope.deleteExternalLink = function (assessmentProduct, link) {
                       scope.confirmationQuestion = 'Are you sure you want to remove ' + link.label + ' from Product ' + assessmentProduct.name + '?';
                       scope.confirmationConfirm = function () {
                           externallink.delete({
                               'id': link.id
                           }).$promise.then(function () {
                               assessmentProduct.externalLinks.deleteOne('id', link.id);
                               scope.confirmModal.close();
                               reload();
                           });
                       };
                       scope.confirmModal.open();
                   };

                   /*
                    * Swap/replace product
                    */
                   scope.isSkeletonProductNeedsReplacing = function (assessmentProduct) {
                       return assessmentProduct.isSkeletonProduct && assessmentProduct.originalProduct.name === 'Client Bill Import Entity';
                   };
                   scope.showReplaceProductModal = function (assessmentProduct) {
                       RateBuildUpProduct.showReplaceProductModal(scope, assessmentProduct);
                   };
                   scope.closeSelectProductModal = function() {
                       RateBuildUpProduct.closeSelectProductModal(scope);
                   };
                   scope.onSelectedProduct = function (product) {
                       RateBuildUpProduct.tryReplaceProduct(scope, product);
                       scope.selectProduct = false;
                   };
                   scope.replaceProduct = function (product, id, conversionFactor, name) {
                       RateBuildUpProduct.replaceProduct(scope, assessment, product, id, conversionFactor, name, () => {
                           scope.onProductReplaced()(scope.assessmentProduct);
                       });
                   };
                   scope.replaceProductWithUnitMismatch = function() {
                       RateBuildUpProduct.replaceWithUnitMismatch(scope);
                   };

                   // Create new product template
                   scope.showCreateProductModal = function () {
                       RateBuildUpProduct.showCreateProductModal(scope, $rootScope);
                   };
                   scope.$on('productSaved', function (event, item) {
                       scope.createProductTemplateModal.close();
                       scope.onSelectedProduct(item);
                   });

                   /*
                    * Component jump list
                    */
                   scope.showHideComponentJumpList = function () {
                       scope.componentJumpListOpen = !scope.componentJumpListOpen;
                   };
                   scope.jumpToComponent = function(component) {
                       const id = `component-${component.id}`;
                       $anchorScroll(id);
                   };

                   /*
                    * Add/delete components
                    */
                   scope.addComponent = function () {
                       assessmentLabourComponent.add({
                           assessmentProduct: scope.assessmentProduct.id,
                           name: scope.addLabourComponentForm.name
                       }).$promise.then(function (component) {
                           assessment.patchLabourComponent({id: component.id}, {contingencyBudget: 0})
                                     .$promise.then(function () {
                                         reload();
                                     });
                       });
                       scope.addLabourComponentModal.close();
                   };
                   scope.closeAddComponentModal = function() {
                       scope.addLabourComponentModal.close();
                   };
                   scope.removeComponent = function (component) {
                       scope.confirmationQuestion = 'Are you sure you want to remove ' + component.name + ' from ' + scope.assessmentProduct.name + '?';
                       scope.confirmationConfirm = function () {
                           assessmentLabourComponent.delete({id: component.id}).$promise.then(function () {
                               scope.confirmModal.close();
                               reload();
                           });
                       };
                       scope.confirmModal.open();
                   };

                   /*
                    * Scale component quantities
                    */
                   const scaleComponentValidationError = () => {
                       const factor = scope.scaleComponentForm.factor;
                       let error = null;
                       if (typeof factor !== 'number' || isNaN(factor)) {
                           error = 'The scale factor must be a number.';
                       } else if (factor === 1) {
                           error = 'A scale factor of 1 will not change any quantity values.';
                       } else if (factor === 0) {
                           error = 'You cannot use a scale factor of 0.';
                       } else if (factor < 0) {
                           error = 'You cannot use a negative scale factor.';
                       }
                       return error;
                   };
                   const calcScaledMaterialQuantity = (material, factor, scaleType) => {
                       if (scaleType === 'divide') {
                           return material.quantityPerLabourComponent / factor;
                       } else {
                           return material.quantityPerLabourComponent * factor;
                       }
                   };
                   const calcScaledActivityQuantity = (activity, factor, scaleType) => {
                       if (scaleType === 'divide') {
                           return activity.quantityPerLabourComponent / factor;
                       } else {
                           return activity.quantityPerLabourComponent * factor;
                       }
                   };
                   scope.scaleComponentAvailable = function() {
                       const component = scope.scaleComponentModal.getContext().assessmentLabourComponent;
                       return component.assessmentLabourComponentMaterials.length + component.assessmentLabourComponentActivities.length > 0;
                   };
                   scope.scaleComponentExample = function() {
                       const error = scaleComponentValidationError();
                       if (error) {
                           return error;
                       }

                       const component = scope.scaleComponentModal.getContext().assessmentLabourComponent;
                       const scaleType = scope.scaleComponentForm.type;
                       const factor = scope.scaleComponentForm.factor;
                       let exampleName;
                       let exampleOriginalValue;
                       let exampleNewValue;
                       let exampleUnit;
                       if (component.assessmentLabourComponentMaterials.length > 0) {
                           const material = component.assessmentLabourComponentMaterials[0];
                           exampleName = material.name;
                           exampleOriginalValue = material.quantityPerLabourComponent;
                           exampleNewValue = calcScaledMaterialQuantity(material, factor, scaleType);
                           exampleUnit = material.originalMaterial.quantityUnit;
                       } else if (component.assessmentLabourComponentActivities.length > 0) {
                           const activity = component.assessmentLabourComponentActivities[0];
                           exampleName = activity.name;
                           exampleOriginalValue = activity.quantityPerLabourComponent;
                           exampleNewValue = calcScaledActivityQuantity(activity, factor, scaleType);
                           exampleUnit = activity.originalLabourActivity.quantityUnit;
                       } else {
                           return $sce.trustAsHtml('');
                       }

                       let unit = helper.htmlShortUnit(exampleUnit, SHORTUNITSTEXT);
                       let changeCount = component.assessmentLabourComponentMaterials.length + component.assessmentLabourComponentActivities.length;
                       const alsoToChange = changeCount > 1 ? `+ ${changeCount - 1} more` : '';
                       const escapedName = helper.escapeHtml(exampleName);
                       const text = `Change ${escapedName} quantity from ${parseFloat(exampleOriginalValue).toFixed(2)} ${unit} to ${parseFloat(exampleNewValue).toFixed(2)} ${unit} ${alsoToChange}`;
                       return $sce.trustAsHtml(text);
                   };
                   scope.scaleComponentQuantities = function() {
                       const error = scaleComponentValidationError();
                       if (error) {
                           window.alert(error);
                       } else {
                           const component = scope.scaleComponentModal.getContext().assessmentLabourComponent;
                           const changes = [];
                           const executeNextChange = () => {
                               if (changes.length === 0) {
                                   reload(component, () => {
                                       scope.scaleComponentModalUpdating = false;
                                       scope.closeScaleQuantitiesModal();
                                   });
                               } else {
                                   const change = changes.splice(0, 1)[0];
                                   change();
                               }
                           };
                           const factor = scope.scaleComponentForm.factor;
                           const scaleType = scope.scaleComponentForm.type;
                           for (let material of component.assessmentLabourComponentMaterials) {
                               changes.push(function () {
                                   helper.updateMaterialQuantity(
                                       scope,
                                       component,
                                       material,
                                       calcScaledMaterialQuantity(material, factor, scaleType),
                                       executeNextChange,
                                       assessmentLabourComponentMaterial,
                                       assessment);
                               });
                           }
                           for (let activity of component.assessmentLabourComponentActivities) {
                               changes.push(function () {
                                   helper.updateActivityQuantity(
                                       scope,
                                       component,
                                       activity,
                                       calcScaledActivityQuantity(activity, factor, scaleType),
                                       executeNextChange,
                                       assessmentLabourComponentActivity,
                                       assessment);
                               });
                           }
                           scope.scaleComponentModalUpdating = true;
                           executeNextChange();
                       }
                   };
                   scope.closeScaleQuantitiesModal = function() {
                       scope.scaleComponentForm = {
                           factor: 1,
                           type: 'multiply'
                       };
                       scope.scaleComponentModal.close();
                   };

                   /*
                    * Move component (change order)
                    */
                   scope.moveComponent = function () {
                       assessmentLabourComponent.changeOrder({
                           movingComponentId: scope.moveComponentModal.getContext().assessmentLabourComponent.id,
                           referenceComponentId: scope.moveComponentForm.after
                       }).$promise.then(function () {
                           scope.moveComponentModal.close();
                           reload();
                       });
                   };
                   scope.closeMoveModal = function() {
                       scope.moveComponentModal.close();
                   };

                   /*
                    * Rename component
                    */
                   scope.showRenameComponentModal = function (component, index) {
                       scope.newComponentName = {
                           old: component,
                           assessmentProduct: scope.assessmentProduct,
                           index: index,
                           name: component.name
                       };
                       scope.renameComponentModal = true;
                   };
                   scope.closeRenameComponentModal = function () {
                       scope.resetComponentRename();
                   };
                   scope.renameComponent = function () {
                       assessmentLabourComponent.patch({id: scope.newComponentName.old.id}, {name: scope.newComponentName.name}).$promise.then(function () {
                           reload();
                           scope.resetComponentRename();
                       }).catch(function () {
                           scope.resetComponentRename();
                       });
                   };
                   scope.resetComponentRename = function () {
                       scope.renameComponentModal = false;
                       scope.newComponentName = null;
                   };

                   /*
                    * Materials
                    */
                   helper.addMaterialSetup(scope);

                   const addMaterialToComponent = function (component) {
                       scope.materialSelected = function (item) {
                           scope.addMaterialForm.selectedConfiguration = null;
                           scope.chooseMaterialModal.close();
                           scope.addMaterialNewMaterial = item;
                           scope.addMaterialModal.open();
                       };
                       scope.chooseMaterialModal.open({
                           assessmentLabourComponent: component,
                           assessmentProduct: scope.assessmentProduct
                       });
                   };

                   scope.addMaterial = function () {
                       assessmentMaterial.add({
                           assessmentLabourComponent: scope.chooseMaterialModal.getContext().assessmentLabourComponent.id,
                           originalMaterial: scope.addMaterialNewMaterial.id,
                           selectedConfiguration: scope.addMaterialForm.selectedConfiguration,
                           quantityPerLabourComponent: scope.addMaterialForm.selectedQuantity
                       }).$promise.then(function() {
                           reload(scope.chooseMaterialModal.getContext().assessmentLabourComponent);
                       });
                       scope.addMaterialModal.close();
                   };

                   // Swapping a Material
                   scope.changeMaterialConversionFactor = function () {
                       let form = scope.swapMaterialForm;
                       form.exampleOut = Math.round(parseFloat(form.exampleIn) * form.conversionFactor * 1000000) / 1000000;
                   };
                   scope.swapMaterial = function (blanket) {
                       const request = {
                           assessmentMaterialId: scope.chooseMaterialModal.getContext().assessmentLabourComponentMaterial.id,
                           newMaterialId: scope.swapMaterialNewMaterial.id,
                           configuration: scope.swapMaterialForm.selectedConfiguration
                       };
                       if (blanket === '1') {
                           request.blanket = true;
                           request.conversionFactor = scope.swapMaterialForm.conversionFactor;
                       } else {
                           request.quantity = scope.swapMaterialForm.selectedQuantity;
                       }
                       assessmentMaterial.swapMaterial(request).$promise.then(function () {
                           reload();
                       });
                       scope.swapMaterialModal.close();
                   };

                   /*
                    * Labour activities
                    */
                   // Creating a Labour Activity
                   scope.$on('newLabourActivityAdded', function (event, item) {
                       scope.newActivityModal.close();
                       scope.activitySelected(item);
                   });

                   // Adding a Labour Activity to an existing Assessment Product
                   const addLabourActivityToComponent = function (component) {
                       scope.activitySelected = function (item) {
                           scope.addLabourActivityForm.configuration = null;
                           scope.chooseLabourActivityModal.close();
                           scope.addLabourActivityNewLabourActivity = item;
                           scope.addLabourActivityModal.open();
                       };
                       scope.chooseLabourActivityModal.open({
                           assessmentLabourComponent: component,
                           assessmentProduct: scope.assessmentProduct
                       });
                   };
                   scope.addLabourActivity = function () {
                       assessmentLabourComponentActivity.add({
                           assessmentLabourComponent: scope.chooseLabourActivityModal.getContext().assessmentLabourComponent.id,
                           originalLabourActivity: scope.addLabourActivityNewLabourActivity.id,
                           quantityPerLabourComponent: scope.addLabourActivityForm.selectedQuantity
                       }).$promise.then(function () {
                           reload(scope.chooseLabourActivityModal.getContext().assessmentLabourComponent);
                       });
                       scope.addLabourActivityModal.close();
                   };

                   // Swapping a Labour Activity in an existing AssessmentProduct
                   scope.changeActivityConversionFactor = function () {
                       scope.swapLabourActivityForm.exampleOut = Math.round(parseFloat(scope.swapLabourActivityForm.exampleIn) * scope.swapLabourActivityForm.conversionFactor * 1000000) / 1000000;
                   };
                   scope.swapLabourActivity = function (blanket) {
                       console.log(scope.chooseLabourActivityModal.getContext().assessmentLabourComponentActivity);
                       const request = {
                           assessmentLabourComponentActivity: scope.chooseLabourActivityModal.getContext().assessmentLabourComponentActivity.id,
                           newLabourActivity: scope.swapLabourActivityNewLabourActivity.id
                       };
                       if (blanket === '1') {
                           request.blanket = true;
                           request.conversionFactor = scope.swapLabourActivityForm.conversionFactor;
                       } else {
                           request.quantity = scope.swapLabourActivityForm.selectedQuantity;
                       }
                       assessmentLabourComponentActivity.swapLabourActivity(request).$promise.then(function () {
                           reload();
                       });
                       scope.swapLabourActivityModal.close();
                   };

                   // Updating product template
                   scope.openProductTemplateModal = function () {
                       scope.productTemplate.name = scope.assessmentProduct.name === scope.assessmentProduct.originalProduct && scope.assessmentProduct.originalProduct.name ?
                           scope.assessmentProduct.name + ' (Copy)' : scope.assessmentProduct.name;
                       scope.productTemplateModal.open({assessmentProduct: scope.assessmentProduct});
                   };
                   scope.updateProductTemplate = function () {
                       switch (scope.productTemplate.mode) {
                           case 'pull':
                               assessmentProduct.applyTemplate({id: scope.productTemplateModal.getContext().assessmentProduct.id}).$promise.then(function () {
                                   scope.productTemplateModal.close();
                                   reload();
                               });
                               break;
                           case 'push':
                               assessmentProduct.patchTemplate({id: scope.productTemplateModal.getContext().assessmentProduct.id}).$promise.then(function () {
                                   scope.productTemplateModal.close();
                                   reload();
                               });
                               break;
                           case 'post':
                               assessmentProduct.postTemplate({
                                   id: scope.productTemplateModal.getContext().assessmentProduct.id,
                                   name: scope.productTemplate.name
                               }).$promise.then(function () {
                                   scope.productTemplateModal.close();
                                   reload();
                               });
                               break;
                           default:
                               break;
                       }
                   };

                   // Create tables
                   scope.createTables = function (){
                       scope.assessmentProduct.assessmentLabourComponents.forEach(component => {
                           scope.tables[component.id] = scope.generateTable(component);
                       });
                   };
                   scope.createTables();

                   scope.apHasInvalidAlcWeights = function (assessmentProduct) {
                       if (nxRbuIssueService.lastResponse && nxRbuIssueService.lastResponse.products.hasOwnProperty(assessmentProduct.id)) {
                           return nxRbuIssueService.lastResponse.products[assessmentProduct.id].filter(i => {
                               // RateBuildUpIssueCode.ProductSFBadWeights
                               return i.code === 2110;
                           }).length;
                       }
                       return false;
                   };

               }
           };
       })
       .factory('RateBuildUpProduct', function() {
           /*
            * Swap/replace product is functionality available on the main RBU products list
            * and when editing a product, so this is made available via a factory.
            *
            * The HTML defining the modals is in swap-product.html in the rate-build-up/partials
            * directory.
            */
           return {
               showSelectProductModal: function(scope) {
                   scope.productTableServiceParams = {package: scope.projectWorkPackageId};
                   scope.selectProduct = true;
                   scope.busy = true;
               },
               closeSelectProductModal: function(scope) {
                   scope.selectProduct = false;
                   scope.busy = false;
               },
               showCreateProductModal: function(scope, rootScope) {
                   scope.createProductTemplateModal.open();
                   rootScope.$broadcast('newProductReset');
               },
               showReplaceProductModal: function(scope, assessmentProduct, index) {
                   scope.swapProduct = true;
                   scope.activeProduct = assessmentProduct;
                   scope.activeProduct.index = index;
                   this.showSelectProductModal(scope);
               },
               tryReplaceProduct: function(scope, newProduct) {
                   scope.swapProductOldProduct = scope.activeProduct;
                   scope.swapProductNewProduct = newProduct;
                   scope.swapProductForm.name = scope.swapProductOldProduct.name;
                   scope.swapProductForm.conversionFactor = 1;
                   scope.swapProductModal.open();
               },
               replaceWithUnitMismatch: function(scope) {
                   scope.replaceProduct(scope.activeProduct, scope.swapProductNewProduct.id, scope.swapProductForm.conversionFactor, scope.swapProductForm.name);
                   scope.swapProductModal.close();
               },
               replaceProduct(scope, assessment, product, originalProductId, conversionFactor, name, onSuccess) {
                   if (conversionFactor === undefined) {
                       conversionFactor = null;
                   }
                   assessment.swapProduct({
                       id: product.id,
                       conversionFactor: conversionFactor
                   }, {
                       assessment: scope.assessmentId,
                       originalProduct: originalProductId,
                       assessmentOrder: product.assessmentOrder,
                       name: name
                   }).$promise.then(function (data) {
                       let index;
                       data.extraLoaded = true;
                       if (scope.activeProduct.index !== undefined) {
                           index = scope.activeProduct.index;
                           scope.assessmentProducts.splice(index, 1, data);
                       } else {
                           scope.assessmentProduct = data;
                       }

                       if (onSuccess) {
                           onSuccess(index, data);
                       }
                   });
               }
           };
       })
       .factory('TableFactory', function() {
           return {
               colTypeIcon: 'icon',
               colTypeText: 'text',
               colTypeEdit: 'edit',
               colTypeDropdown: 'dropdown',
               colTypeNumber: 'number',
               colTypePercentage: 'percentage',
               colTypeCurrency: 'currency',
               colTypeHtml: 'html',
               colTypeDelete: 'delete',
               colTypeEditPrice: 'price',
               dropdownColumn: function(tag, getItemsFn, tooltipFn = null, isEditableFn = null, title = null, expand = false) {
                   return {
                       type: this.colTypeDropdown,
                       tag: tag,
                       getItems: getItemsFn,
                       tooltip: tooltipFn,
                       title: title,
                       expand: expand,
                       editable: isEditableFn
                   };
               },
               iconColumn: function(valueFn, tooltipFn = null) {
                   return {
                       type: this.colTypeIcon,
                       title: null,
                       value: valueFn,
                       tooltip: tooltipFn
                   };
               },
               textColumn: function(valueFn, tooltipFn = null, title = null, expand = false, emphasis = false) {
                   return {
                       type: this.colTypeText,
                       title: title,
                       value: valueFn,
                       tooltip: tooltipFn,
                       expand: expand,
                       emphasis: emphasis
                   };
               },
               editColumn: function(tag, tooltipFn = null, title = null) {
                   return {
                       type: this.colTypeEdit,
                       title: title,
                       tag: tag,
                       tooltip: tooltipFn
                   };
               },
               deleteColumn: function(tag, title = null, tooltipFn = null) {
                   return {
                       type: this.colTypeDelete,
                       title: title,
                       tag: tag,
                       tooltip: tooltipFn
                   };
               },
               numberColumn: function(tag, valueFn, tooltipFn = null, title = null, isEditableFn = null, expand = false) {
                   return {
                       type: this.colTypeNumber,
                       title: title,
                       value: valueFn,
                       isEditable: isEditableFn,
                       expand: expand,
                       tag: tag,
                       tooltip: tooltipFn
                   };
               },
               percentageColumn: function(tag, valueFn, tooltipFn = null, title = null, isEditableFn = null, expand = false) {
                   return {
                       type: this.colTypePercentage,
                       title: title,
                       value: valueFn,
                       isEditable: isEditableFn,
                       expand: expand,
                       tag: tag,
                       tooltip: tooltipFn
                   };
               },
               htmlColumn: function(valueFn, tooltipFn = null, title = null, emphasis = false, highlightNegative = false) {
                   return {
                       type: this.colTypeHtml,
                       title: title,
                       value: valueFn,
                       tooltip: tooltipFn,
                       emphasis: emphasis,
                       highlightNegative: highlightNegative
                   };
               },
               currencyColumn: function(valueFn, tooltipFn = null, title = null) {
                   return {
                       type: this.colTypeCurrency,
                       title: title,
                       value: valueFn,
                       tooltip: tooltipFn
                   };
               },
               editPriceColumn: function(tag, dataFn, tooltipFn = null, isEditableFn = null, title = null) {
                   return {
                       tag: tag,
                       type: this.colTypeEditPrice,
                       title: title,
                       tooltip: tooltipFn,
                       data: dataFn,
                       editable: isEditableFn
                   };
               },
               dropdownItem: function(id, label, selected) {
                   return {
                       id: id,
                       label: label,
                       selected: selected
                   };
               },
               tooltip: function(title, rows = [], extraInfoAvailable = false, showOnlyOnClick = false) {
                   return {
                       title: title,
                       rows: rows.filter(row => row !== null),
                       extraInfoAvailable: extraInfoAvailable,
                       showOnlyOnClick: showOnlyOnClick
                   };
               },
               tooltipRow: function(visible, label, valueFns) {
                   if (visible && valueFns) {
                       const values = valueFns.map(valueFn => valueFn()).filter(value => value !== null);
                       return [label].concat(values);
                   } else {
                       return null;
                   }
               }
           };
       });
