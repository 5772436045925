(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('FixedCostInvoiceCtrl', function ($scope, $rootScope, $routeParams, project, fixedCostInvoices, fixedCostOrders, $filter, $location, $route, $locale, FileUploader, PermPermissionStore) {
            $scope.accountsIntegrationEnabled = PermPermissionStore.hasPermissionDefinition('canReadAccountsNominalCodes');
            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            // Document uploader
            // $scope.attachmentUploader = new FileUploader();
            // $scope.attachmentsList = [];
            $scope.invoiceUploader = {
                uploader: new FileUploader(),
                list: []
            };

            if ($routeParams.projectId) {
                $rootScope.projectId = $routeParams.projectId;
                project.get({id: $routeParams.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                    $scope.project = data;
                });
            } else if ($routeParams.orderId) {
                // Attach Invoice
                fixedCostOrders.get({id: $routeParams.orderId}).$promise.then(function (data) {
                    $scope.order = data;
                    $scope.project = data.project;
                    $rootScope.projectId = $scope.project.id;
                });

                $scope.invoice = {invoiceDate: new Date};
                $scope.raiseInvoiceForm = {};
                $scope.raiseInvoice = function (form, noDocument) {
                    $scope.submitted = true;
                    if (form.$valid) {
                        if (!form.invoiceDocument && !noDocument) {
                            $scope.invoiceDocumentWarning.open();
                        } else {
                            $scope.invoiceDocumentWarning.close();
                            var formData = {
                                order: form.fixedCostOrder.$modelValue,
                                invoiceReference: form.invoiceReference.$modelValue,
                                invoiceDate: form.invoiceDate.$modelValue,
                                invoicedValue: form.invoicedValue.$modelValue,
                            };
                            if ($scope.invoice.vatTaxCode) {
                                formData.vatTaxCode = $scope.invoice.vatTaxCode.id;
                            }
                            if (form.invoiceDocument) {
                                formData.invoiceDocument= form.invoiceDocument.id;
                            }
                            fixedCostInvoices.post(null, formData).$promise.then(function (data) {
                                $location.path('/project-hub/fixed-cost-invoice/' + data.id);
                            });
                        }
                    }
                };

            }

            if ($route.current.$$route.isList) {
                $scope.invoiceTableParams = {'project': $rootScope.projectId, 'groups': 'orders_list', 'orderCategory':'fixedCost'};

            } else if ($routeParams.id) {
                // Single page
                $scope.payForm = {approvedForPayment: 0};
                $scope.queryForm = {};
                $scope.resolutionForm = {};

                fixedCostInvoices.get({id: $routeParams.id}, {groups: 'orders_view'}).$promise.then(function (data) {
                    if (null === data.approvedValue) {
                      data.approvedValue = data.invoicedValue;
                    }
                    $scope.projectId = data.order.project.id;
                    $rootScope.projectId = data.order.project.id;
                    $scope.project = data.order.project;
                    $scope.order = data.order;
                    $scope.invoice = data;

                    $rootScope.projectId = data.order.project.id;
                    $scope.payForm.approvedForPayment = data.approvedValue;
                    $scope.invoice.invoiceDate = new Date(data.invoiceDate);

                    if ($route.current.$$route.isEdit) {
                        // Edit invoice
                        $scope.raiseInvoice = function (form) {
                            $scope.submitted = true;
                            if (form.$valid) {
                                var formData = {
                                    invoicedValue: form.invoicedValue.$modelValue,
                                    invoiceReference: form.invoiceReference.$modelValue,
                                    invoiceDate: form.invoiceDate.$modelValue,
                                    vatTaxCode: $scope.invoice.vatTaxCode.id
                                };
                                if (form.invoiceDocument) {
                                    formData.invoiceDocument = form.invoiceDocument.id;
                                }
                                fixedCostInvoices.patch({id: $scope.invoice.id}, formData).$promise.then(function (data) {
                                    $location.path('/project-hub/fixed-cost-invoice/' + data.id);
                                });
                            }
                        };
                    }
                });

                $scope.approve = function () {
                    if ($scope.invoice.state === 'Approved') {
                      if (!confirm('This Invoice has already been approved. Update approved value?')) {
                        return;
                      }
                    }
                    $scope.submitted = true;
                    if (!$scope.invoice.approvedValue) {
                        $scope.payError = true;
                    } else {
                        fixedCostInvoices.approve({id: $routeParams.id}, {approvedForPayment: $scope.invoice.approvedValue}).$promise.then(function (data) {
                            $scope.payError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.query = function () {
                    $scope.submitted = true;
                    if (!$scope.queryForm.queryNotes) {
                        $scope.queryError = true;
                    } else {
                        fixedCostInvoices.queryInvoice({id: $routeParams.id}, {queryNotes: $scope.queryForm.queryNotes}).$promise.then(function (data) {
                            $scope.queryError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.resolve = function () {
                    $scope.submitted = true;
                    if (!$scope.resolutionForm.queryResolutionNotes) {
                        $scope.resolutionError = true;
                    } else {
                        fixedCostInvoices.resolve({id: $routeParams.id}, {queryResolutionNotes: $scope.resolutionForm.queryResolutionNotes}).$promise.then(function (data) {
                            $scope.resolutionError = false;
                            $scope.invoice = data;
                        });
                    }
                };
                $scope.attachInvoice = function () {
                    var invoice = new fixedCostInvoices({invoiceDocument: $scope.invoice.invoiceDocument.id});
                    invoice.$patch({id: $routeParams.id});
                };
                $scope.pay = function () {
                    fixedCostInvoices.pay({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.invoice = data;
                    });
                };
            }
        });
})();
