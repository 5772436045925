(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.vattaxcode
     * @description
     */
    angular.module('scswebappApp')
        .service('vattaxcode', ['Resource','ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/vattaxcodes/:id', { id: '@id' }, {
                    customSave: {
                        transformRequest: function(data){
                            delete data.special;
                            return angular.toJson(data);
                        }
                    },
                    query:{
                        cache: true,
                        isArray: true,
                        params: {
                            sort: 'name'
                        }
                    }
                }
            );
        }]);
})();
