(function(){
    'use strict';
    angular.module('scswebappApp')
        .filter('zoneList', function ($sce) {
            return function (list) {
                if (typeof(list) == "object" ){
                    var items = [];
                    angular.forEach(list, function(zone){
                        items.push({
                            'id':zone.id,
                            'name': zone.name,
                            'formattedName': '<span class="extra-bold parent-zone">' + zone.name+'</span>',
                            'isParentZone': zone.subZones.length > 0
                        });
                        if (zone.subZones){
                            angular.forEach(zone.subZones, function(subZone){
                                items.push({
                                    'id':subZone.id,
                                    'name': subZone.name,
                                    'formattedName': '<span class="sub-zone">'+subZone.name+'</span>'
                                });
                            })
                        }
                    });
                    return items;
                } else {
                    return list;
                }
            };
        });
})();
