angular.module("scswebappApp")
    .directive("newSubcontractor", function (
        $location,
        $route,
        FileUploader,
        trades,
        region,
        subcontractor,
        PermissionStrategies,
        PermPermissionStore
    ) {
        "use strict";
        return {
            template: require('./new-subcontractor.html'),
            restrict: "E",
            scope: {
                subcontractorId: "=?",
                form: "=?"
            },
            link: function postLink(scope) {
                scope.PermissionStrategies = PermissionStrategies;

                scope.hasWritePermission = false;

                scope.sContractorType = [
                    {
                        name: "Limited Company",
                    },
                    {
                        name: "Partnership",
                    },
                    {
                        name: "Sole Trader",
                    }
                ];

                scope.companyInsuranceExpiry = new Date();

                function getSubcontractorData() {
                    subcontractor.get({id: scope.subcontractorId}).$promise.then(function (data) {
                        scope.data = data;
                        scope.companyInsuranceExpiry = new Date(scope.data.companyInsuranceExpiry);

                        const found = scope.sContractorType.find(e => e.name === data.subcontractorType);
                        if(found) {found.ticked = true;}


                    }).then(function () {
                        trades.query(function (data) {
                            scope.trades = data;
                            angular.forEach(scope.data.trades, function (value) {
                                angular.forEach(scope.trades, function (value2) {
                                    if (value.id === value2.id) {
                                        value2.ticked = true;
                                    }
                                })
                            })
                        });
                        region.query(function (data) {
                            scope.regions = data;
                            angular.forEach(scope.data.regions, function (value) {
                                angular.forEach(scope.regions, function (value2) {
                                    if (value.id === value2.id) {
                                        value2.ticked = true;
                                    }
                                });
                            });
                        });
                    }).then(function () {
                        subcontractor.getOperatives({id: scope.subcontractorId}).$promise.then(function (data) {
                            scope.operatives = data;

                            angular.forEach(scope.operatives, function (operative) {


                                var tmpTrades = [];

                                angular.forEach(operative.trades, function (trade) {
                                    tmpTrades.push(trade.name);
                                });

                                operative.trades = tmpTrades.join(', ');

                                var tmpQualifications = [];

                                angular.forEach(operative.qualifications, function (qualification) {
                                    tmpQualifications.push(qualification.name);
                                });

                                operative.qualifications = tmpQualifications.join(', ');

                                operative.assignmentCount = operative.assignments.length;

                                var totalPerformance = 0;

                                angular.forEach(operative.assignments, function (assignment) {
                                    totalPerformance += assignment.performanceRating;
                                });

                                operative.averagePerformance = totalPerformance / operative.assignmentCount;

                            });
                        });
                    });
                }

                scope.$watch('subcontractorId', function () {
                    scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteContactsSubcontractors');
                    if (scope.subcontractorId == null) {
                        scope.data = {
                            registeredAddress: {},
                            correspondenceAddress: {}
                        };
                    } else {
                        getSubcontractorData();
                    }
                });

                trades.query(function (data) {
                    scope.trades = data;
                });
                region.query(function (data) {
                    scope.regions = data;
                });

                scope.insuranceDocumentUploader = new FileUploader();

                scope.insuranceDocument = {
                    uploader: scope.insuranceDocumentUploader,
                    list: []
                };

                scope.showCIS = true;
                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if (userDetails != null) {
                    var defaultCurrency = userDetails.account.defaultCurrency;
                    if (defaultCurrency == "MYR" || defaultCurrency == "SGD") {
                        scope.showCIS = false;
                    }
                }

                scope.documentsData = new FileUploader();
                scope.data = (scope.data) ? scope.data : {};

                scope.data.documents = (scope.data.documents) ? scope.data.documents : [];

                scope.documents = {
                    uploader: scope.documentsData,
                    list: scope.data.documents
                };

                scope.thisYear = new Date().getFullYear();
                // add a new one

                scope.newEmail = {};
                scope.addEmail = function () {
                    scope.data.emailAddresses = (scope.data.emailAddresses) ? scope.data.emailAddresses : [];
                    scope.data.emailAddresses.push({label: scope.newEmail.label, detail: scope.newEmail.detail})
                    scope.newEmail = {};
                };

                scope.removeEmail = function (index) {
                    scope.data.emailAddresses.splice(index, 1);
                };

                scope.newTel = {};
                scope.addTel = function () {
                    scope.data.telephoneNumbers = (scope.data.telephoneNumbers) ? scope.data.telephoneNumbers : [];
                    scope.data.telephoneNumbers.push({label: scope.newTel.label, detail: scope.newTel.detail})
                    scope.newTel = {};
                };

                scope.removeTel = function (index) {
                    scope.data.telephoneNumbers.splice(index, 1);
                };

                scope.vatDocumentUploader = new FileUploader();

                scope.vatDocument = {
                    uploader: scope.vatDocumentUploader,
                    list: []
                };

                scope.formatSubcontractorType = function () {
                    scope.data.subcontractorType = scope.data.subcontractorType[0]? scope.data.subcontractorType[0].name : '';

                };

                scope.createSubcontractor = function () {
                    scope.data.companyInsuranceExpiry = scope.companyInsuranceExpiry.toISOString().split("T")[0];

                    scope.submitted = true;

                    if (scope.subcontractorForm.$valid && scope.data.subcontractorType) {
                        scope.formatSubcontractorType();

                        scope.saveObj = new subcontractor(scope.data);

                        scope.saveObj.$save().then(function () {
                            scope.subcontractorForm.$setPristine();
                            scope.submitted = false;
                            scope.$emit('subcontractorSaved');
                        });
                    }else {
                        const invalidFields = scope.subcontractorForm.$error;

                        let fieldErrors;

                        // Loop through the invalid fields and log their names and error messages to the console
                        for (const fieldName in invalidFields) {
                            fieldErrors = invalidFields[fieldName];
                            const element = document.getElementById(fieldErrors[0].$name);
                            element.scrollIntoView({block: "end"});
                        }
                        if (!fieldErrors && !scope.data.subcontractorType){
                            const element = document.getElementById('subcontractorType');
                            element.scrollIntoView({block: "end"});
                        }
                    }
                };
            }
        };
    });
