(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.people
	 * @description
	 * # people
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	    .factory('people', ['Resource', 'ENV', function ( $resource, ENV ) {
	        return $resource(
	            ENV.apiEndpoint + '/people/:id', { id: '@id' }, {
                    update: {
                        method: 'PUT',
                        transformRequest: function(data){
                            var peopleData = {
                                'firstName': data.firstName,
                                'lastName': data.lastName,
                                'jobTitle': data.jobTitle,
                                'emailAddresses': data.emailAddresses,
                                'telephoneNumbers': data.telephoneNumbers,
                                'parent': data.parent
                            };
                            angular.forEach(peopleData.telephoneNumbers, function(value, key){
                                delete peopleData.telephoneNumbers[key].id;
                                delete peopleData.telephoneNumbers[key].label;
                            });
                            angular.forEach(peopleData.emailAddresses, function(value, key){
                                delete peopleData.emailAddresses[key].id;
                                delete peopleData.emailAddresses[key].label;
                            });
                            return angular.toJson(peopleData);
                        }
                    }
	            }
	        );

        }]);
})();