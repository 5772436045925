'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.issue
 * @description
 * # issue
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
  .factory('issue', ['Resource','ENV', function ($resource, ENV) {
        return $resource(
            ENV.apiEndpoint + '/issues/:id', { id: '@id', groups: '@groups', projectId: '@projectId' }, {
                get:{
                    url: ENV.apiEndpoint + '/issues/:id?groups=userHistory',
                },
		        saveComment:{
			        method: 'POST',
			        url: ENV.apiEndpoint + '/issuecomments'
		        },
                close: {
                    url: ENV.apiEndpoint + '/issues/:id/transitions/close',
                    method: 'POST'
                },
                reopen: {
                    url: ENV.apiEndpoint + '/issues/:id/transitions/reopen',
                    method: 'POST'
                },
                customSave: {
                    transformRequest: function(data){
                        var newObj = {
                            'name': data.name,
                            'issueType': data.issueType,
                            'project': data.project,
                            'dueDate': data.dueDate,
                            'owner': data.owner,
                            'assignee': data.assignee,
                            'zone': data.zone,
                            'files': []
                        };

                        if (data.workScopeStage) {
                            newObj.workScopeStage = data.workScopeStage;
                        }
                        if (data.progressGroupZoneProgress) {
                            newObj.progressGroupZoneProgress = data.progressGroupZoneProgress;
                        }
                        if( data.files ){
                            angular.forEach(data.files, function(value){
                                var comment = (value.comment) ? value.comment : null;
                                if(value.file && value.file.id){
                                    newObj.files.push({'comment': comment, 'file': value.file.id});
                                }else{
                                    newObj.files.push({'comment': comment, 'file': value.id});
                                }
                            });
                        }

                        if (angular.isArray(data.assessment) && data.assessment[0]) {
                            newObj.assessment = data.assessment[0].id;
                        } else if (angular.isObject(data.assessment)) {
                            newObj.assessment = data.assessment.id
                        }

                        if (angular.isArray(data.assessmentZone) && data.assessmentZone[0]) {
                            newObj.assessmentZone = data.assessmentZone[0].id;
                        } else if (angular.isObject(data.assessmentZone)) {
                            newObj.assessmentZone = data.assessmentZone.id;
                        }

                        return angular.toJson(newObj);
                    }
                },
                getProjectIssueAssigneeOptions: {
                    url: ENV.apiEndpoint + '/projects/:projectId/issue-assignee-options',
                    method: 'GET',
                    isArray: true
                },
                getProjectIssueAssessmentZoneOptionsAction: {
                    url: ENV.apiEndpoint + '/projects/:projectId/issue-assessment-zone-options',
                    method: 'GET',
                    isArray: true
                }
            }
        );

  }]);
