'use strict';
import {CsUtils} from '../../other/csutils.js';

/**
 * @ngdoc function
 * @name scswebappApp.controller:IssueCategoryCtrl
 * @description
 * # IssueCategoryCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
	.controller('QuoteRequestCtrl', function ($scope, $rootScope, $route, $routeParams, UNITSTEXT, routeType, $location, $http, ENV, $window, $injector, supplier, subcontractor, material) {

        var service;

        function tickSelect(item, subcontractor) {
            var type = subcontractor ? 'subcontractor' : 'supplier';
            angular.forEach($scope.sData, function (value) {
                if (item[type] && item[type].id === value.id) {
                    value.ticked = true;
                }
            });
        }

        // Setup config depending on quote request type
        $scope.quoteType = $route.current.$$route.quoteType;
        switch ($route.current.$$route.quoteType) {
            case 'material':
                // $scope.config = {'service': 'materialQuoteRequest'};
                $scope.permissionsFamily = 'ResourcesMaterialQuotes';
                $scope.config = {
                    'quoteType': 'Material',
                    'quoteTypeAddTitle': 'material',
                    'newUrl': 'new-material-quote-request',
                    'editUrl': 'edit-material-quote-request',
                    'singlePageUrl': 'material-quote-request',
                    'entityMethod': 'materialquotetemplates',
                    'service': 'materialQuoteRequest',
                    'serviceMethod': 'query',
                    'itemService': 'material',
                    'itemCols': [
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Brand',
                            data: 'brand',
                            hasTextFilter: true
                        },
                        {
                            text: 'Specifics',
                            data: 'specifics',
                            hasTextFilter: true
                        },
                        {
                            text: 'Type',
                            data: 'materialType',
                            dropdownFilter: {
                                service: 'materialtype',
                                method: 'query',
                                label: 'name',
                                queryString: 'type',
                                filterParam: 'id',
                                emptyText: 'Filter by type'
                            }
                        },
                        {
                            text: 'Pack Size',
                            data: 'packSize'
                        },
                        {
                            text: 'Configurations',
                            object: 'configurations',
                            objectCols: [
                                {
                                    data: 'name'
                                }
                            ]
                        },
                        {
                            text: 'Quantity Unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        },
                        {
                            text: 'Packages',
                            object: 'workPackages',
                            objectCols: [
                                {
                                    data: 'name'
                                }
                            ],

                            dropdownFilter: {
                                service: 'packages',
                                method: 'query',
                                label: 'name',
                                queryString: 'package',
                                filterParam: 'id',
                                emptyText: 'Filter by package'
                            }
                        }
                    ]
                };
                $scope.prTableConfig = {
                    config: {
                        service: 'materialQuoteRequest',
                        serviceMethod: 'getPriceRequests',
                        customQueryKey: 'id',
                        customQueryValue: $routeParams.id,
                        itemsPerPage: 50,
                    },
                    cols: [
                        {
                            text: 'Type',
                            data: 'materialType',
                            dropdownFilter: {
                                service: 'materialtype',
                                method: 'query',
                                label: 'name',
                                queryString: 'type',
                                filterParam: 'id',
                                emptyText: 'Filter by Type'
                            }
                        },
                        {
                            text: 'Brand',
                            data: 'brand',
                            hasTextFilter: true
                        },
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Specifics',
                            data: 'specifics',
                            hasTextFilter: true
                        },
                        {
                            text: 'Quantity unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        }
                    ]
                };

                service = $injector.get($scope.config.service);
                if ($routeParams.id) {
                    var params = {id: $routeParams.id};
                    if ($route.current.$$route.isEdit) {
                        params.groups = "priceRequests_list";
                    }
                    service.get(params).$promise.then(function (data) {
                        return $scope.data = data;
                    }).then(function (data) {
                        supplier.query({limit: 3000}).$promise.then(function (data2) {
                            $scope.sData = data2;
                            tickSelect(data)
                        })
                    });
                } else {
                    supplier.query({limit: 3000}).$promise.then(function (data) {
                        $scope.sData = data;
                    })
                }
                break;

            case 'labour':
                $scope.permissionsFamily = 'ResourcesLabourQuotes';
                $scope.config = {
                    'quoteType': 'Labour',
                    'quoteTypeAddTitle': 'activity',
                    'newUrl': 'new-labour-quote-request',
                    'editUrl': 'edit-labour-quote-request',
                    'singlePageUrl': 'labour-quote-request',
                    'entityMethod': 'labourquotetemplates',
                    'service': 'LabourQuoteRequest',
                    'serviceMethod': 'query',
                    'itemService': 'labouractivity',
                    'itemCols': [
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Description',
                            data: 'description'
                        },
                        {
                            text: 'Trade',
                            data: 'trade.name',
                            dropdownFilter: {
                                service: 'trades',
                                method: 'query',
                                label: 'name',
                                queryString: 'trade',
                                filterParam: 'id',
                                emptyText: 'Filter by trade'
                            }
                        },
                        {
                            text: 'Quantity Unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        }
                    ]
                };
                $scope.prTableConfig = {
                    config: {
                        service: 'LabourQuoteRequest',
                        serviceMethod: 'getPriceRequests',
                        customQueryKey: 'id',
                        customQueryValue: $routeParams.id,
                        itemsPerPage: 50,
                    },
                    cols: [
                        {
                            text: 'Trade',
                            data: 'trade',
                            dropdownFilter: {
                                service: 'trades',
                                method: 'query',
                                label: 'name',
                                queryString: 'trade',
                                filterParam: 'id',
                                emptyText: 'Filter by Type'
                            }
                        },
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Description',
                            data: 'description'
                        },
                        {
                            text: 'Quantity unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        }
                    ]
                };
                service = $injector.get($scope.config.service);
                if ($routeParams.id) {
                    var params = {id: $routeParams.id};
                    if ($route.current.$$route.isEdit) {
                        params.groups = "priceRequests_list";
                    }
                    service.get(params).$promise.then(function (data) {
                        // Convert the labourActivity key to material as we're using the same templates for both material and labour.
                        angular.forEach(data.priceRequests, function (value) {
                            value.material = value.labourActivity;
                        });
                        return $scope.data = data;
                    }).then(function (data) {
                        //$route.current.$$route.isEdit
                        subcontractor.query({limit: 3000, hydration: 'array'}).$promise.then(function (data2) {
                            $scope.sData = data2;
                            tickSelect(data, true)
                        })
                    });
                } else {
                    subcontractor.query({limit: 3000, hydration: 'array'}).$promise.then(function (data) {
                        $scope.sData = data;
                    })
                }
                break;
            case 'sf':
                $scope.permissionsFamily = 'ResourcesSupplyFitQuotes';
                $scope.config = {
                    'quoteType': 'Supply & Fit',
                    'quoteTypeAddTitle': 'product',
                    'newUrl': 'new-sf-quote-request',
                    'editUrl': 'edit-sf-quote-request',
                    'singlePageUrl': 'sf-quote-request',
                    'entityMethod': 'sfquotetemplates',
                    'service': 'SupplyFitQuoteRequest',
                    'serviceMethod': 'query',
                    'itemService': 'product',
                    'itemCols': [
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Description',
                            data: 'description'
                        },
                        {
                            text: 'Quantity Unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        }
                    ]
                };
                $scope.prTableConfig = {
                    config: {
                        service: 'SupplyFitQuoteRequest',
                        serviceMethod: 'getPriceRequests',
                        customQueryKey: 'id',
                        customQueryValue: $routeParams.id,
                        itemsPerPage: 50,
                    },
                    cols: [
                        {
                            text: 'Name',
                            data: 'name',
                            hasTextFilter: true
                        },
                        {
                            text: 'Description',
                            data: 'description'
                        },
                        {
                            text: 'Quantity unit',
                            data: 'quantityUnit',
                            dataFilter: {
                                type: 'units',
                                format: 'UNITSTEXT'
                            }
                        }
                    ]
                };
                service = $injector.get($scope.config.service);
                if ($routeParams.id) {
                    service.get({id: $routeParams.id}).$promise.then(function (data) {
                        // Convert the product key to material as we're using the same templates
                        angular.forEach(data.priceRequests, function (value) {
                            value.material = value.product;
                        });
                        return $scope.data = data;
                    }).then(function (data) {
                        //$route.current.$$route.isEdit
                        subcontractor.query({limit: 3000, hydration: 'array'}).$promise.then(function (data2) {
                            $scope.sData = data2;
                            tickSelect(data, true)
                        })
                    });
                } else {
                    subcontractor.query({limit: 3000, hydration: 'array'}).$promise.then(function (data) {
                        $scope.sData = data;
                    })
                }
                break;
        }

        $scope.newOrEdit = $route.current.$$route.isNew ? 'New' : 'Edit';
        if ($route.current.$$route.isNew || $route.current.$$route.isEdit) {
            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);
        }

        // Remove quote item
        $scope.removeQuoteItem = function (price, index) {
            $scope.data.priceRequests.splice(index, 1);
        };

        // Save quote
        $scope.saveQuote = function () {
            $scope.submitted = true;
            console.log($scope.data);
            var saveObj = new service($scope.data);
            if ($scope.quoteForm.$valid) {
                saveObj.$save().then(function () {
                    $location.path('/' + $scope.config.singlePageUrl + 's');
                })
            }
        };

        // items table / selection / new material
        $scope.newMaterial = false;
        $scope.showNewMaterial = function () {
            $scope.newMaterial = true;

            //Scroll to top
            window.scrollTo(0, 0);
        };
        $scope.closeMaterialModal = function () {
            $scope.newMaterial = false;
            $scope.selectItem = false;
        };

        // Add material
        $scope.addQuoteItem = function () {
            $scope.selectItem = true;
        };

        // New quote item
        $scope.showNewItem = function () {
            $scope.newItem = true;
        };
        $scope.closeNewItem = function () {
            $scope.newItem = false;
        };
        $scope.$on('newLabourActivityAdded', function () {
            $scope.closeNewItem();
        });
        $scope.$on('newMaterialAdded', function () {
            $scope.closeNewItem();
        });
        $scope.$on('newProductTemplateAdded', function () {
            $scope.closeNewItem();
        });

        if ($route.current.$$route.isNew || $route.current.$$route.isEdit) {
            $scope.tableConfig = {
                config: {
                    service: $scope.config.itemService,
                    serviceMethod: 'query',
                    emitEvent: true,
                    multiSelect: true,
                    itemsPerPage: 50,
                    canFilter: true,
                    logData: false
                },
                cols: $scope.config.itemCols
            };
        }

        /* Download Quote Request */
        $scope.downloadQuoteRequest = function () {
            $http({
                method: 'GET',
                responseType:'arraybuffer',
                url: ENV.apiEndpoint + '/' + $scope.config.entityMethod + '/' + $routeParams.id + '/export'
            }).success(function(data, status, headers) {
                CsUtils.download(data, headers, $window);
            });
        };

        function addItems(item) {
            console.log(item);
            if ($scope.data && $scope.data.priceRequests && $scope.data.priceRequests.length > 0) {
                angular.forEach(item, function (value) {
                    var unique = true;
                    // Check the material has now already been selected
                    for (var i = 0; i < $scope.data.priceRequests.length; i++) {

                        if ($scope.data.priceRequests[i].material.id === value.id) {
                            unique = false;
                            $scope.$broadcast('showOrNotification', value.name + ' already exists and has not been included.', true);
                            break;
                        }
                    }
                    if (unique) {
                        $scope.data.priceRequests.push({material: value});
                    }
                })
            } else {
                angular.forEach(item, function (value) {
                    $scope.data = $scope.data ? $scope.data : {priceRequests: []};
                    $scope.data.priceRequests = $scope.data.priceRequests ? $scope.data.priceRequests : [];
                    $scope.data.priceRequests.push({material: value});
                })
            }
            $scope.selectItem = false;
        }

        $scope.onItemsSelected = function(items) {
            console.log('itemsSelected', items);
            addItems(items);
        };
    });
