(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('projectnote', ['Resource', 'ENV', function ($resource, ENV) {

            return $resource(
                ENV.apiEndpoint + '/projectnotes/:id', {id: '@id'}, {
                    updateProjectNote: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projectnotes/:id'
                    },
                }
            );
        }]);
})();
