import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
    Assessment,
    AssessmentCheckResult,
    AssessmentCheckResultItem,
} from "../../chalkstring-api/services/assessment.service";
import {SimpleModalService} from "ngx-simple-modal";
import {AssessmentAuditService} from "../../chalkstring-api/services/assessment.audit.service";
import {Subscription} from "rxjs";
import {RepairAssessmentModalComponent} from "../modals/repair-assessment-modal.component";
import {first} from 'rxjs/operators';

@Component({
    selector: 'assessment-audit-button',
    template: `
        <button *ngIf="isShown()"
                (click)="handleClick()"
                class="btn"
                appTooltip [tooltipText]="getTooltipText()"
                [ngClass]="{'btn-green': !failures.length && !indeterminates.length, 'btn-red': failures.length && !indeterminates.length, 'btn-orange': indeterminates.length}"
        >
            <i [ngClass]="getIconClass()"></i>
            <span *ngIf="failures.length || indeterminates.length">{{failures.length + indeterminates.length}} issue{{failures.length + indeterminates.length === 1 ? '' : 's'}}</span>
        </button>
    `
})
export class AssessmentAuditButtonComponent implements OnDestroy
{
    public overallResultState: AssessmentCheckResult = AssessmentCheckResult.PASS;
    public indeterminates: AssessmentCheckResultItem[] = [];
    public failures: AssessmentCheckResultItem[] = [];
    private _assessment: Assessment;
    @Output() pass = new EventEmitter<number>();
    @Output() indeterminate = new EventEmitter<number>();
    @Output() fail = new EventEmitter<number>();
    @Output() change = new EventEmitter<number>();

    private _checkSub: Subscription;
    private _repairSub: Subscription;

    constructor(private _aAuditService: AssessmentAuditService, private _modalService: SimpleModalService)
    {
        this._checkSub = this._aAuditService.checked.subscribe(items => {
            this._handleCheckResponse(items);
        });
        this._repairSub = this._aAuditService.repaired.subscribe(items => {
            this._handleCheckResponse(items);
        });
    }

    ngOnDestroy()
    {
        if (this._checkSub) this._checkSub.unsubscribe();
        if (this._repairSub) this._repairSub.unsubscribe();
    }

    isShown(): boolean
    {
        return !!this.assessment && AssessmentCheckResult.PASS !== this.overallResultState;
    }

    getTooltipText(): string
    {
        if (this._aAuditService.working) {
            return 'Checking...';
        } else {
            switch (this.overallResultState) {
                case AssessmentCheckResult.PASS:
                    return 'Assessment passes all checks';
                case AssessmentCheckResult.FAIL:
                    return 'Failed ' + this.failures.length + ' check' +
                        (this.failures.length === 1 ? '' : 's');
                case AssessmentCheckResult.INDETERMINATE:
                    return this.indeterminates.length.toString() + ' unknown check results';
                default:
                    return 'Unexpected result state';
            }
        }
    }

    getIconClass(): string
    {
        if (this._aAuditService.working) {
            return 'icon-spin3 inline-spinner';
        } else {
            switch (this.overallResultState) {
                case AssessmentCheckResult.PASS:
                    return 'icon-ok';
                case AssessmentCheckResult.FAIL:
                    return 'icon-warning';
                case AssessmentCheckResult.INDETERMINATE:
                default:
                    return 'icon-help';
            }
        }
    }

    @Input() set assessment(assessment: Assessment)
    {
        this._assessment = assessment;
        if (assessment) this.check();
    }

    get assessment(): Assessment
    {
        return this._assessment;
    }

    check()
    {
        this._aAuditService.check(this.assessment).pipe(first()).subscribe();
    }

    private _handleCheckResponse(response: AssessmentCheckResultItem[])
    {
        // Store previous result
        const previousOverallResult = this.overallResultState;

        // Initialise results
        this.indeterminates = [];
        this.failures = [];
        this.overallResultState = AssessmentCheckResult.PASS;

        // Process response
        this.failures = response.filter(i => AssessmentCheckResult.FAIL === i.result);
        this.indeterminates = response.filter(i => AssessmentCheckResult.INDETERMINATE === i.result);
        if (this.indeterminates.length) {
            this.overallResultState = AssessmentCheckResult.INDETERMINATE;
        } else if (this.failures.length) {
            this.overallResultState = AssessmentCheckResult.FAIL;
        }

        // Emit events
        switch (this.overallResultState) {
            case AssessmentCheckResult.PASS:
                this.pass.emit(AssessmentCheckResult.PASS);
                break;
            case AssessmentCheckResult.FAIL:
                this.fail.emit(AssessmentCheckResult.FAIL);
                break;
            default:
            case AssessmentCheckResult.INDETERMINATE:
                this.indeterminate.emit(AssessmentCheckResult.INDETERMINATE);
                break;
        }

        // New result is different to prior
        if (previousOverallResult !== undefined && previousOverallResult !== this.overallResultState) {
            this.change.emit(this.overallResultState);
        }
    }

    handleClick()
    {
        if (this._aAuditService.working || !this.failures.length) {
            return;
        }
        this._modalService.addModal(RepairAssessmentModalComponent, {
            assessment: this.assessment,
            failures: this.failures
        }).subscribe(res => {
            if (res) {
                this._handleCheckResponse(res);
                this.change.emit(this.overallResultState);
            }
        });
    }
}
