(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.quotes
	 * @description
	 * # quotes
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('supplyFitQuote', ['Resource', 'ENV', '$filter', function ( $resource, ENV, $filter ) {
            var saveTransform = function(data){

                var newData = {
                    'name': data.name,
                    'subcontractor': data.subcontractor,
                    'quoteDate': null,
                    'quoteExpires': null,
                    'projects': [],
                    'documents': []
                };

                // Add leading 0's to date values for Safari and Firefox
                var qDate = data.quoteDate.split('-');
                angular.forEach(qDate, function(value, key){
                    if(value < 10){
                        qDate[key] = '0' + value;
                    }
                });
                qDate = qDate[0] + '-' + qDate[1] + '-' + qDate[2];
                var eDate = data.quoteExpires.split('-');
                angular.forEach(eDate, function(value, key){
                    if(value < 10){
                        eDate[key] = '0' + value;
                    }
                });
                eDate = eDate[0] + '-' + eDate[1] + '-' + eDate[2];
                newData.quoteDate = data.quoteDate + ' 00:00:00';
                newData.quoteExpires = data.quoteExpires + ' 00:00:00';

                // Documents
                var tmpDocs = [];
                angular.forEach(data.documents, function(document){
                    tmpDocs.push(document.id);
                });
                newData.documents = tmpDocs;

                // Suppliers
                if( data.subcontractor[0] ){
                    newData.subcontractor = data.subcontractor[0].id;
                }else{
                    newData.subcontractor = data.subcontractor.id;
                }

                // Projects
                var tmpProjects = [];
                angular.forEach(data.projects, function(project){
                    tmpProjects.push(project.id);
                });

                newData.projects = tmpProjects;

                if (data.prices) {
                    newData.prices = [];
                    // Prices
                    angular.forEach(data.prices, function (value) {
                        if (value.product.id) {
                            newData.prices.push({'product': value.product.id, 'price': value.price});
                        } else {
                            newData.prices.push({'product': value.product, 'price': value.price});
                        }
                    });
                }

                return angular.toJson(newData);
            };

			return $resource(
				ENV.apiEndpoint + '/supplyandfitquotes/:id', { id: '@id', productId: '@productId' }, {
                    customSave: {
                        transformRequest: saveTransform
                    },
                    update: {
                        method: 'PATCH',
                        transformRequest: saveTransform
                    },
                    archive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/supplyandfitquotes/:id/archive'
                    },
                    unarchive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/supplyandfitquotes/:id/unarchive'
                    },
                    patchPrices: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/supplyandfitquotes/:id/prices"
                    },
                    stats: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/supplyandfitquotes/stats"
                    },
                    quoteRatesForProduct: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/products/:productId/supplyandfitquoterates",
                        isArray: true
                    },
                    clone: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/supplyandfitquotes'
                    }
				}
			);
		}]);
})();