(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.package
	 * @description
	 * # package
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
				 .factory('packages', ['Resource', 'ENV', function ( $resource, ENV ) {
	         return $resource(
	           ENV.apiEndpoint + '/packages/:id', { id: '@id' }, {
               commenceWork:{
                 method: 'POST',
                 url: ENV.apiEndpoint + '/projectpackages/:id/transitions/commence'
               },
               assessmentState:{
                 method: 'POST',
                 url: ENV.apiEndpoint + '/projectpackages/:id/transitions/assess'
               },
               customSave: {
                 transformRequest: function(data){
                   var newGroups = [];
                   angular.forEach(data.subGroups, function(value){
                     if( value.name || value.name !== null ){
                       if( value.id ){
                         delete value.id;
                       }
                       newGroups.push(value);
                     }
                   });
                   data.subGroups = newGroups;
                   return angular.toJson(data);
                 }
               },
               getPackagesubgroups: {
                 method: 'GET',
                 url: ENV.apiEndpoint + '/packagesubgroups',
                 isArray: true,
								 params: {
									 'sort': 'name'
								 }
               },
               packagesSuitableForTender: {
                 method: 'GET',
                 url: ENV.apiEndpoint + '/projects/:id/assessments-for-tender',
                 isArray: true

               }
	           },
						 'name'  // default sort order for query
	         );

				 }]);
})();
