(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.material
     * @description
     * # material
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('fixedCostOrders', ['ENV', 'Resource', 'UNITSTEXT', 'DateUtils', function (ENV, $resource, what, DateUtils) {
            return $resource(
                ENV.apiEndpoint + '/fixedcostorders/:id', {id: '@id'}, {
                    saveOrder: {
                        method: 'POST',
                        isArray: true,
                        url: ENV.apiEndpoint + '/fixedcostorders/split'
                    },
                    patchOrder:{
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id',
                        transformRequest: function (data) {
                            return angular.toJson(data);
                        }
                    },
                    customSave:{
                        transformRequest: function (data) {
                            if ( (typeof data.supplier === "object") && (data.supplier !== null)){
                                data.supplier = data.supplier.id;
                            }

                            if ( (typeof data.project === "object") && (data.project !== null)){
                                data.project = data.project.id;
                            }

                            var fieldsToRemove = ['orderTotal','stateStatus','deliveryAddress','expectedDeliveryDate','workScopeStage','invoicedValue', 'approvedValue', 'orderNumber', 'approvedForPayment'];
                            angular.forEach(fieldsToRemove, function (field) {
                               delete data[field];
                            });

                            var fieldsToRemove = ['lineItemTotal','selectedPrice','workScopeStage','quantityInvoiced'];
                            angular.forEach(data.fixedCostOrderItems, function (lineItem) {
                                if ( (typeof lineItem.assessmentFixedCost === "object") && (lineItem.assessmentFixedCost !== null)){
                                    lineItem.assessmentFixedCost = lineItem.assessmentFixedCost.id;
                                }
                                if ( (typeof lineItem.fixedCost === "object") && (lineItem.fixedCost !== null)){
                                    lineItem.fixedCost = lineItem.fixedCost.id;
                                }
                                angular.forEach(fieldsToRemove, function (field) {
                                    delete lineItem[field];

                                });
                            });

                            if (data.documents) {
                                let documents = [];
                                angular.forEach(data.documents, function (value) {
                                    var comment = (value.comment) ? value.comment : null;
                                    if (value.file && value.file.id) {
                                        documents.push({'comment': comment, 'file': value.file.id});
                                    } else {
                                        documents.push({'comment': comment, 'file': value.id});
                                    }
                                });
                                data.documents = documents;
                            }

                            return angular.toJson(data);
                        }
                    },
                    confirmOrder: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/confirm',
                        transformRequest: function (data) {
                            data.expectedDeliveryDate = DateUtils.toServerDateTime(data.expectedDeliveryDate);

                            return angular.toJson(data);
                        }
                    },
                    supplierOptions: {
                        method: 'GET',
                        isArray: true,
                        url: ENV.apiEndpoint + '/fixedcostorders/supplier-options',
                        cache: true
                    },
                    sendOrder: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/transitions/send'
                    },
                    cancelOrder: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/transitions/cancel'
                    },
                    completeOrder:{
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/transitions/complete'
                    },
                    receiveInvoice: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/receive-invoice',
                        transformRequest: function (data) {
                            var transformed = {
                                invoiceQuantities: data.invoiceQuantities,
                                attachments: []
                            };
                            transformed.dateInvoiced = DateUtils.toServerDateTime(data.dateInvoiced);
                            angular.forEach(data.attachments, function (file) {
                                transformed.attachments.push(file.id);
                            });

                            return angular.toJson(transformed);
                        }
                    },
                    markAsInvoiced: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/transitions/invoice'
                    },
                    approvePayment: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/approve-payment'
                    },
                    queryInvoice: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/query-invoice'
                    },
                    resolveQuery: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/fixedcostorders/:id/resolve-query'
                    },
                    getPreviousAddresses: {
                        method: 'GET',
                        url:  ENV.apiEndpoint + '/projects/:id/materialorders/previous-addresses',
                        isArray:true,
                    }
                }
            );
        }]);
})();
