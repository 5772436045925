(function () {
    'use strict';

    angular.module('scswebappApp')
        .controller('DocumentTemplateCtrl', function ($scope, $rootScope, $routeParams, $route, $http, $window, ENV, documenttemplate, PermPermissionStore, PermissionStrategies) {
            $scope.hasWritePermission = false;
            $scope.PermissionStrategies = PermissionStrategies;

            var loadTemplates = function (success) {
                documenttemplate.query().$promise.then(function (data) {
                    $scope.templates = data;
                    if (PermPermissionStore.hasPermissionDefinition('canWriteAdminDocumentTemplates')) {
                        $scope.hasWritePermission = true;
                        $scope.templates.push({id: -1, name: "Add New"});
                    }
                    if (typeof success === 'function') {
                        return success();
                    }
                });
            };

            loadTemplates();

            var selectTemplate = function (id) {
                if (id === null) {
                    $scope.selectedTemplate = null;
                    $scope.template = null;
                    return;
                }
                angular.forEach($scope.templates, function (loadedTemplate) {
                    if (id === loadedTemplate.id) {
                        $scope.selectedTemplate = loadedTemplate;
                        $scope.template = loadedTemplate;
                    }
                });
            };

            $scope.selectionChange = function () {
                if ($scope.selectedTemplate.id === -1) {
                    $scope.template = {
                        name: "",
                        text: ""
                    }
                } else {
                    selectTemplate($scope.selectedTemplate.id);
                }
            };

            $scope.saveTemplate = function () {

                if ($scope.templateForm.$valid) {
                    var saveObj = new documenttemplate($scope.template);
                    var finishSaving = function (data) {
                        $scope.templateForm.$setPristine(data);
                        loadTemplates(function () {
                            selectTemplate(data.id);
                        });
                    };

                    if (saveObj.id > 0) {
                        saveObj.$update().then(function (data) {
                            finishSaving(data)
                        });
                    } else {
                        saveObj.id = null;
                        saveObj.$save().then(function (data) {
                            finishSaving(data)
                        });
                    }
                }
            };

            $scope.deleteSelected = function () {
                var saveObj = new documenttemplate($scope.template);

                saveObj.$delete().then(function () {
                    selectTemplate(null);
                    loadTemplates();
                    $scope.confirmationModal.close();
                });
            }
        });
})();
