'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:ProjectRoleCtrl
 * @description
 * # ProjectRoleCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
	.controller('WorkPackageCtrl', function ( $scope, $route, $routeParams, $rootScope, $location, routeType, packages ) {

		if ( $routeParams.id ){
			packages.get( { id:$routeParams.id }).$promise.then( function(data){
				$scope.data = data;
			});
		}

        if( $route.current.$$route.isNew ){
            $scope.data = {
                subGroups: []
            }
        }

        $scope.addSubgroup = function(){
            $scope.data.subGroups.push({'name': null});
        };

        $scope.removeSubGroup = function(index){
            $scope.data.subGroups.splice(index, 1);
        };

		if( $route.current.$$route.isNew || $route.current.$$route.isEdit ){

			// Set type keyword for edit and new routes
			$scope.keyWord = routeType.type( $route.current.$$route.isNew, $route.current.$$route.isEdit );

			// Form submission
			$scope.saveForm = function(){

				$scope.submitted = true;

				if( $scope.form.$valid ){

					var saveObject = new packages($scope.data);
                    saveObject.$save().then(function(){
						$scope.form.$setPristine();
						$location.path('/admin/work-packages');
					});

				}


			}
		}

	});
