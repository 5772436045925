angular.module('scswebappApp')
    .directive('zoneTools', function (assessment, zone, $filter) {
        return {
            templateUrl: 'scripts/directives/zone-tools/zone-tools.html',
            restrict: 'E',
            scope: {
                zone: '='
            },
            link: function postLink(scope, element, attrs) {
                scope.$emit('gbHeadAdjusted');
                scope.modalInfo = {};

                var parseUkDateString = function (dateString) {
                    if (!dateString) {
                        return '';
                    }
                    var parts = dateString.split('/');
                    var d = parseInt(parts[0], 10),
                        m = parseInt(parts[1], 10),
                        y = parseInt(parts[2], 10);
                    return new Date(y, m-1, d);
                };

                scope.showEditZoneModal = function (az) {
                    scope.modalInfo = {
                        name: az.name,
                        startDate: $filter('date')(az.startDate, 'dd/MM/yyyy'),
                        endDate: $filter('date')(az.endDate, 'dd/MM/yyyy')
                    };
                    scope.editZoneModal.open();
                };

                scope.showAddSubZoneModal = function (az) {
                    scope.modalInfo.zone = az;
                    scope.addSubZoneModal.open();
                };

                scope.updateZone = function (az, zoneForm) {
                    if (!zoneForm.$valid) {
                        return;
                    }

                    zone.update({id: az.id}, {
                        name: scope.modalInfo.name,
                        startDate: scope.modalInfo.startDate === 'Not set' ? '' : $filter('date')(parseUkDateString(scope.modalInfo.startDate), 'yyyy-MM-dd'),
                        endDate: scope.modalInfo.endDate === 'Not set' ? '' : $filter('date')(parseUkDateString(scope.modalInfo.endDate), 'yyyy-MM-dd')
                    }).$promise.then(function () {
                        scope.$emit('gbRefresh', true);
                        scope.editZoneModal.close();
                    });
                };

                scope.deleteZone = function (az) {
                    if (confirm('Are you sure you want to delete this zone?')) {
                        zone.delete({id: az.id}).$promise.then(function () {
                            scope.$emit('gbRefresh', true);
                            scope.editZoneModal.close();
                        });
                    }
                };

                scope.subZone = {};
                scope.createSubZone = function (az, zoneForm) {
                    if (!zoneForm.$valid) {
                        return;
                    }

                    var startDate = $filter('date')(parseUkDateString(scope.subZone.startDate), 'yyyy-MM-dd');
                    console.log(startDate);
                    var endDate = $filter('date')(parseUkDateString(scope.subZone.endDate), 'yyyy-MM-dd');
                    console.log(endDate);
                    zone.addSubZone({id: az.id}, {
                        name: scope.subZone.name,
                        startDate: startDate,
                        endDate: endDate
                    }).$promise.then(function () {
                        scope.$emit('gbRefresh', true);
                        scope.addSubZoneModal.close();
                    });
                    console.log(az);
                    console.log(zoneForm);
                };
            }
        }
    });