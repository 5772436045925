angular.module('scswebappApp')
    .directive('passwordForm', ['zxcvbn', function(zxcvbn) {
        'use strict';
        return {
            templateUrl: 'scripts/directives/password-form/password-form.html',
            restrict: 'E',
            scope: {
                'password': '=?ngModel'
            },
            link:function ($scope) {
                $scope.password_strength_names = ['Unacceptable','Unacceptable','Unacceptable','Acceptable','Excellent'];
                $scope.$watch('password',function(){
                    $scope.$emit('passwordFormChanged');
                });
            }
        };
    }]);
