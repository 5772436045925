(function(){
	'use strict';
	angular.module('scswebappApp')
        .run(function($translate, $locale, currencies){
            try {
                var accountData = JSON.parse( localStorage.getItem('userDetails') );
                var currency = accountData ? accountData.account.defaultCurrency : null;
                currencies.setCurrentCurrency(currency);
            } catch (e) {
                console.log(e);
            }
        });
})();