import {CsUtils} from '../../../other/csutils.js';

(function(){
	'use strict';

	/**
	 * @ngdoc function
     * @name scswebappApp.controller:HubAssessmentCtrl
	 * @description
     * # HubAssessmentCtrl
	 * Controller of the scswebappApp
	 */
	angular.module('scswebappApp')
	    .controller('HubAssessmentCtrl', function ($scope, $rootScope, $routeParams,FileUploader, project, assessment, ENV, $http, $window, $location) {

            $scope.isHub = true;
            $rootScope.assessmentId = $routeParams.id;

            $scope.assessmentBudgetData = function (assessment) {
                return [
                    {name: 'Materials', y: assessment.estimate.materialCost + assessment.variationsEstimate.materialCost},
                    {name: 'Waste', y: assessment.estimate.materialWasteCost + assessment.variationsEstimate.materialWasteCost},
                    {name: 'Inflation', y: assessment.estimate.productInflationCost + assessment.variationsEstimate.productInflationCost},
                    {name: 'Labour', y: assessment.estimate.productLabourCost + assessment.variationsEstimate.productLabourCost},
                    {name: 'Contingency', y: assessment.estimate.productContingencyCost + assessment.variationsEstimate.productContingencyCost},
                    {name: 'Fixed Costs', y: assessment.estimate.fixedCostsCost + assessment.variationsEstimate.fixedCostsCost},
                    {name: 'Supply & Fit', y: assessment.estimate.supplyAndFitCost + assessment.variationsEstimate.supplyAndFitCost},
                    {name: 'OHP', y: assessment.estimate.postAdjustmentOHP + assessment.variationsEstimate.postAdjustmentOHP},
                    {name: 'Adjustments', y: assessment.estimate.adjustmentValue + assessment.variationsEstimate.adjustmentValue},
                    {name: 'Total', y:  assessment.estimate.estimationTotal + assessment.variationsEstimate.estimationTotal}

                ];
            };

            assessment.get({id: $routeParams.id }).$promise.then(function(data){
                $scope.assessment = data;
                $rootScope.projectId = data.projectWorkPackage.project.id;

                $scope.budgetData = {};
                $scope.budgetData.currentBudget = $scope.assessmentBudgetData(data);
                if (data.copyOf) {
                    assessment.get({id: data.copyOf}).$promise.then(function(snapsnotData){
                        $scope.budgetData.tenderBudget = $scope.assessmentBudgetData(snapsnotData);
                    });
                }
            });

            // Variables for quote request buttons
            $scope.ENV = ENV;
            $scope.token = localStorage.getItem("access_token");

            $scope.fileUpload = {
                uploader: new FileUploader
            };

            var updateFiles =function(){
                var documents = [];
                angular.forEach($scope.assessment.projectWorkPackage.documents, function(item){
                    documents.push({file: item.file.id});
                });
                project.updateWorkPackage({ id: $scope.assessment.projectWorkPackage.id },{ documents: documents });
            };

            $scope.deleteFile = function(i){
                $scope.assessment.projectWorkPackage.documents.splice(i, 1);
                updateFiles();
            };

            $scope.fileUpload.uploader.onSuccessItem = function(){
                updateFiles();
            };

            // Supply and fit quote requests
            $scope.supplyFitQuoteRequest = function(){
                $http({
                    method: 'GET',
                    responseType:'arraybuffer',
                    url: ENV.apiEndpoint + '/assessments/' + $scope.assessmentId + '/supplyfitquoterequest'
                }).success(function(data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };

            $scope.showRateBuildUp = function(assessmentId) {
                $location.path('/project-hub/rate-build-up/' + assessmentId);
            };

            $scope.showBillOfQuantities = function(projectId, assessmentId) {
                $location.path('/project/' + projectId + '/hub/assessment/' + assessmentId + '/boq');
            };

            $scope.showFixedCosts = function(projectId, assessmentId) {
                $location.path('/project-hub/assessment-fixed-costs/' + assessmentId).search({'projectId': projectId});
            };

            $scope.downloadFile= function (url) {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + url
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };
        });
})();
