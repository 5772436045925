'use strict';

angular.module('scswebappApp')
    .directive('operativeList', function (PermPermissionStore) {

        return {
            template: require("./operative-list.html"),
            restrict: 'E',
            scope: {
                'multiSelect': '=?',
                'highlightedItems': '=?',
                'pinnedItems': '=?',
                'projectId': '@?',
                'tableIdentifier': '@?',
                'mode': '@',
                'canDeletePermission': '='
            },
            link: function postLink(scope, element, attrs) {
                scope.activeOperativeId = null;

                var validModes = ['assigned', 'unassigned'];
                var serviceMethod = 'query';
                var customQuery = null;

                if (!scope.multiSelect){
                    scope.multiSelect = false;
                }

                if (!scope.tableIdentifier){
                    scope.tableIdentifier = 'OperativeList';
                }

                if (scope.projectId) {
                    if (Object.values(validModes).indexOf(scope.mode) === -1) {
                        throw "No valid mode parameter supplied for operative-list";
                    }

                    customQuery = {project: scope.projectId};

                    if (scope.mode === 'unassigned') {
                        serviceMethod = 'getProjectUnassigned';
                    }
                }

                scope.$on('listReload' + scope.tableIdentifier, function(){
                    scope.$broadcast('tableViewReload' + scope.tableIdentifier);
                });

                scope.$on('tableViewCellClicked' + scope.tableIdentifier, function(e,data){
                    if (scope.multiSelect !== true && data.col.ignoreClick !== true) {
                        scope.activeOperativeId = data.item.id;
                        scope.operativeViewModal.open(null, true);
                    }
                });

                scope.tableConfig = {
                    config: {
                        tableIdentifier: scope.tableIdentifier,
                        service: 'operative',
                        serviceMethod: serviceMethod,
                        customQuery: customQuery,
                        itemsPerPage: 50,
                        canFilter: true,
                        multiSelect: scope.multiSelect,
                        emitEvent: true,
                        sortBy: 'lastName'
                    },
                    cols: [
                        {
                            text: 'Last name',
                            data: 'lastName',
                            hasTextFilter: true
                        },
                        {
                            text: 'First name',
                            data: 'firstName',
                            hasTextFilter: true
                        },
                        {
                            text: 'Trades',
                            object: 'trades',
                            objectCols: [
                                {
                                    data: 'name'
                                }
                            ],
                            dropdownFilter: {
                                service: 'trades',
                                method: 'query',
                                label: 'name',
                                queryString: 'trade',
                                filterParam: 'id',
                                emptyText: 'Filter by trade'
                            }

                        },
                        {
                            text: 'City',
                            object: 'addresses',
                            objectCols: [
                                {
                                    data: 'city'
                                }
                            ],
                            hasTextFilter: true,
                            textFilterKey: 'city'
                        }
                    ]
                };
                if (!attrs.canDeletePermission || PermPermissionStore.hasPermissionDefinition(attrs.canDeletePermission)) {
                    scope.tableConfig.cols.push({
                        text: '',
                        ignoreClick: true,
                        sort: false,
                        template: "scripts/directives/lists/_partials/table-cell-row-delete.html"
                    });
                }
            }
        };
    });
