import {Component} from "@angular/core";
import {AbstractNavigableTableViewComponent} from "../../table-view/abstract-table-view.component";
import {Router} from "@angular/router";
import {TableViewColumn} from "../../table-view/table-view-column";
import {TableViewFilterText} from "../../table-view/filters/table-view-filter-text.component";
import {Observable, of} from "rxjs";
import {TableViewCellContactMethodCollectionComponent} from "../../table-view/cells/table-view-cell-contact-method-collection.component";
import {Subcontractor, SubcontractorService} from "../../chalkstring-api/services/subcontractor.service";
import {TableViewCellNamedCollectionComponent} from "../../table-view/cells/table-view-cell-named-collection.component";
import {TableViewFilterSelect} from "../../table-view/filters/table-view-filter-select.component";
import {OperativeTradeService} from "../../chalkstring-api/services/operative-trade.service";
import {RegionService} from "../../chalkstring-api/services/region.service";

@Component({
    selector: 'subcontractor-list',
    styles: [require('../../table-view/abstract-table-view.component.scss')],
    template: require('../../table-view/abstract-table-view.component.html'),
})
export class SubcontractorListComponent extends AbstractNavigableTableViewComponent<Subcontractor>
{
    navigateTo = '/subcontractor';

    constructor(
        protected _service: SubcontractorService,
        protected _router: Router,
        private _otService: OperativeTradeService,
        private _rService: RegionService,
    )
    {
        super(_service, _router);
    };

    protected _initColumnSet(): Observable<TableViewColumn[]>
    {
        let cols = [];

        const nameCol = new TableViewColumn('fullName', 'Name');
        nameCol.filter = new TableViewFilterText(nameCol);
        nameCol.filter.autoActivate = true;
        cols.push(nameCol);

        const emailCol = new TableViewColumn('emailAddresses', 'Email addresses', TableViewCellContactMethodCollectionComponent);
        cols.push(emailCol);

        const phoneCol = new TableViewColumn('telephoneNumbers', 'Telephone numbers', TableViewCellContactMethodCollectionComponent);
        cols.push(phoneCol);

        const tradesCol = new TableViewColumn('trades', 'Trades', TableViewCellNamedCollectionComponent);
        tradesCol.filter = new TableViewFilterSelect(tradesCol, this._otService);
        tradesCol.filter.paramName = 'trade';
        cols.push(tradesCol);

        const regionsCol = new TableViewColumn('regions', 'Regions', TableViewCellNamedCollectionComponent);
        regionsCol.filter = new TableViewFilterSelect(regionsCol, this._rService);
        regionsCol.filter.paramName = 'region';
        cols.push(regionsCol);

        return of(cols);
    }
}
