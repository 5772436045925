'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.documenttemplate
 * @description
 * # documenttemplate
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
	.factory('documenttemplate', function ($resource, ENV ) {
		return $resource(
			ENV.apiEndpoint + '/documenttemplates/:id', { id: '@id' }, {
                update: {
                    method: 'PATCH'
                }
			}
		);
	});
