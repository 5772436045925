(function(){
    'use strict';
    /**
     * {{ var | decimal }}
     * $filter('decimal')( var );
     */
    angular.module('scswebappApp')
        .filter('decimal', function() {
            return function(input, precision){
                return +parseFloat(input).toFixed(precision);
            };

        });
})();