(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.assessment
	 * @description
	 * # assessment
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('period', function ( TIMEUNITS, $resource ) {
            return $resource(
                '', { id: '@id' }, {
                    getPeriod: {
                        method: 'GET',
                        isArray: true,
                        transformResponse: function () {
                            var period = [];
                            for (var key in TIMEUNITS){
                                period.push({
                                    name: TIMEUNITS[key],
                                    id: key
                                });
                            }
                            return period;
                        }
                    }
                }
            );

		});
})();
