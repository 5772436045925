(function(){
	'use strict';

	/**
	 * @ngdoc directive
	 * @name scswebappApp.directive:orImg
	 * @description
	 * # orImg
	 */
	angular.module('scswebappApp')
		.directive('orFile', function (ENV, $compile, $rootScope) {
			return {
				template: '',
				restrict: 'E',
				scope: {
					data: '=',
					height: '=',
					heading: '=',
					small: '=',
					filesize: '='
				},
				link: function postLink(scope, element) {

					scope.$watch('data', function(){
						if (typeof scope.data !== 'undefined') {
							scope.process();
						}else{
							element.html();
						}
					});


					scope.fileSize = function(filesize){

						var size = filesize / 1024;

						if (size > 1000){
							return (size/1024).toFixed(1) + 'mb';
						} else {
							return size.toFixed(1) + 'kb';
						}

					};

					var showFileSize = true;

					if (typeof scope.filesize !== 'undefined'){
						showFileSize = scope.filesize;
					}

					scope.accessToken = localStorage.getItem('access_token');

					scope.process = function(){

                        if( scope.data && scope.data.s3Url ){
						    scope.url = scope.data.s3Url;
                        }

						if (scope.data && scope.data.mimeType && scope.data.mimeType.indexOf('image') > -1){
							element.html('<img ng-show="data.id" height="{{height}}" ng-src="{{url}}"/>');
							if (showFileSize) {
								element.append('<p>{{fileSize(data.fileSize)}}</p>');
							}
						} else {
							if (scope.small){
								element.html('<a class="icon-upload-cloud-outline btn-sml download" ng-href="{{url}}" download="{{data.fileName}}"></a>');
							} else {
								element.html('<a class="btn download-btn btn-small btn-light inline" ng-href="{{url}}" download="{{data.fileName}}" ng-show="data.fileName">Download {{data.fileName}} ({{fileSize(data.fileSize)}})</a>');
							}

						}

						if (scope.heading){
							element.prepend('<p><strong>{{heading}}</strong></p>');
						}


						$compile(element.contents())(scope);

					};

					//Clear all fileData
					$rootScope.$on('clearFileData', function(){
						scope.data = {};
					});


				}
			};
		});
})();
