'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('imageLoaded', function (user, $rootScope) {
        return {
            restrict: 'A'
        };
    });
