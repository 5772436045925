(function(){
    'use strict';
    angular.module('scswebappApp')
        .controller('Navigation', function($scope, $rootScope, $route, $location, $http, ENV) {
            $scope.refreshPage = function () {
                window.location.reload(true);
            };
            // Open/Close Navigation
            $scope.openNav = function(){
                $scope.navOpen = !$scope.navOpen;
            };
            $scope.$on('$routeChangeStart', function() {
                $scope.navOpen = false;
            });
            // Check isHub to show correct navigation in index.html
            $scope.$on( '$routeChangeSuccess', function() {
                const isOnsite = $location.search()['onsite'] === 'true';  // Using as have tried multiple methods to reliably get query params from $route/$routeParams and failed
                $scope.isHub = isOnsite || ($route.current && $route.current.$$route && $route.current.$$route.isHub);
                // TODO: Could do with finding a more elegant way to determine whether or not the burger menu should be hidden
                $rootScope.isVerticalNavigationHidden = window.location.pathname.startsWith('/takeoff/page/');
                $rootScope.isHub = $scope.isHub;
            });
        });
})();
