'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.projectRole
 * @description
 * # projectRole
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
	.factory('projectRole', ['Resource', 'ENV', function ( $resource, ENV ) {
		return $resource(
			ENV.apiEndpoint + '/projectroles/:id', { id: '@id' }, {
                assign: {
                    url: ENV.apiEndpoint + '/projectroleusers/:id',
                    method: 'POST'
                },
                update: {
                    url: ENV.apiEndpoint + '/projectroleusers/:id',
                    method: 'PATCH'
                },
                patch: {
                    method: "PATCH",
                    url: ENV.apiEndpoint + "/project/:id/roleusers"
                }
			}
		);
	}]);
