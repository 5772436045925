'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:newProduct
 * @description
 * <new-product saveAsNew="true"></new-product>
 * # newProduct
 */
angular.module('scswebappApp')
    .directive('newProduct', function (
        product,
        $route,
        $rootScope,
        $location,
        $routeParams,
        routeType,
        packages,
        FileUploader,
        material,
        $injector,
        assessment,
        UNITSTEXT,
        $filter,
        $locale,
        TableFactory,
        SHORTUNITSTEXT,
        $anchorScroll,
        $timeout,
        file
    ) {
        return {
            template: require('./new-product-directive.html'),
            restrict: 'E',
            scope: {
                newProductTemplateAdded: '&',
                isInModal: '='
            },
            link: function postLink(scope) {
                scope.subGroupArray = [];
                scope.tables = {};
                scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;
                scope.isHub = $route.current.$$route.isHub;
                scope.componentJumpListOpen = false;
                scope.documentsOpen = false;

                const sectionHeader = -1;
                const sectionMaterials = 0;
                const sectionActivities = 1;
                const deleteTag = 'delete';
                const configurationTag = 'configuration';
                const addMaterialTag = 'addMaterial';
                const addActivityTag = 'addActivity';
                const quantityTag = 'quantity';
                const wasteTag = 'waste';
                const swapTag = 'swap';
                let newComponentIds = [];

                var assessmentProductId;
                scope.$on('editProductId', function (e, productId, assessmentProdId) {
                    assessmentProductId = assessmentProdId;
                    getProductData(productId);
                });

                scope.unit = UNITSTEXT;
                scope.tempMaterial = {};

                scope.unitsArray = Object.entries(scope.unit).map(([attribute, name]) => ({ attribute, name }));
                let quantityUnitMap = new Map();
                for (const unit of scope.unitsArray) {
                    quantityUnitMap.set(unit.attribute, unit);
                }
                scope.formatAttribute = function(){
                    if (scope.tempMaterial.quantityUnit[0]){
                        scope.product.quantityUnit = scope.tempMaterial.quantityUnit[0].attribute;
                    }else{
                        scope.product.quantityUnit = '';
                    }
                    scope.quantityUnitChange();
                };

                scope.formatWorkPackage = function(){
                    scope.product.workPackage = scope.tempMaterial.workPackage[0];
                };

                scope.updateQuantityUnit = function(){
                    let quantityUnit = quantityUnitMap.get(scope.product.quantityUnit);
                    if (quantityUnit) {
                        quantityUnit.ticked = true;
                    }
                };

                scope.resetUnits = function (){
                    scope.product.quantityUnit = scope.tempMaterial.originalQuantityUnit;
                    scope.updateQuantityUnit();
                };

                // Set type keyword for edit and new routes
                scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

                if ($route.current.$$route.isEdit) {
                    getProductData($routeParams.id);
                } else {
                    getProductData();
                }
                $rootScope.$on('newProductReset', function () {
                    if ($route.current.$$route.isEdit) {
                        getProductData($routeParams.id);
                    } else {
                        getProductData();
                    }
                });

                // Add Link
                scope.addExternalProductLink = function (form) {
                    const link = {
                        label: form.label.$modelValue,
                        url: form.url.$modelValue
                    };
                    scope.product.externalLinks.push(link);
                    scope.addExternalLinkModal.close();
                };
                // Remove Link
                scope.removeExternalProductLink = function (index) {
                    confirmAction('Delete link?', () => {
                        scope.product.externalLinks.splice(index, 1);
                    });
                };

                // Get Product Data
                const updateComponentTables = function() {
                   scope.product.labourComponents.forEach(component => {
                       scope.tables[component.id] = scope.generateTable(component);
                   });
                }
                function getProductData(productId) {
                    packages.query({limit:1000}).$promise.then(function (data) {
                        return scope.packages = data;
                    }).then(function () {
                        if (productId) {
                            var service;
                            var serviceMethod;
                            var itemId;
                            if (scope.isHub) {
                                service = $injector.get('assessment');
                                serviceMethod = 'assessmentProductAsProduct';
                                itemId = assessmentProductId;
                            } else {
                                service = $injector.get('product');
                                serviceMethod = 'get';
                                itemId = productId
                            }
                            service[serviceMethod]({id: itemId}).$promise.then(function (data) {
                                // Package and Sub package code
                                // Create sub group array
                                angular.forEach(data.subGroups, function (value) {
                                    scope.subGroupArray.push(value.id);
                                });

                                // mark package as ticked
                                angular.forEach(scope.packages, function (availablePackage) {
                                    if (data.workPackage && data.workPackage.id === availablePackage.id) {
                                        availablePackage.ticked = true;
                                    }
                                });

                                // Mark subGroups as ticked
                                angular.forEach(scope.packages, function (value) {
                                    angular.forEach(value.subGroups, function (subGroup) {
                                        if (scope.subGroupArray.indexOf(subGroup.id) > -1) {
                                            subGroup.ticked = true;
                                        }
                                    });
                                });

                                return data;
                            }).then(function (data) {
                                //Refactor materials object
                                angular.forEach(data.labourComponents, function (value) {
                                    angular.forEach(value.labourComponentMaterials, function (value) {
                                        value.name = value.material.name;
                                        value.specifics = value.material.specifics;
                                        value.brand = value.material.brand;
                                        if (typeof value.material.configurations !== 'undefined') {
                                            value.configurations = value.material.configurations;
                                        }
                                    })
                                });
                                return data;
                            }).then(function (data) {
                                scope.product = data;
                                scope.tempMaterial.originalQuantityUnit = scope.product.quantityUnit;
                                scope.updateQuantityUnit();

                                let tickedPackage = scope.packages.find(obj => {
                                    return obj.id === scope.product.workPackage;
                                });
                                if (tickedPackage) {
                                    tickedPackage.ticked = true;
                                }

                                return data;
                            }).then(function () {
                                scope.contingencyBudgetTotal = 0;
                                angular.forEach(scope.product.labourComponents, function (value) {
                                    if (value.contingencyBudget) {
                                        scope.contingencyBudgetTotal += parseFloat(value.contingencyBudget);
                                    }
                                    scope.contingencyBudgetTotal = parseFloat($filter('number')(scope.contingencyBudgetTotal, 2));
                                });
                                updateComponentTables();
                            });
                            scope.editing = true;
                        } else {
                            const initialId = 0;
                            scope.product = {
                                externalLinks: [],
                                workPackage: null,
                                labourComponents: [{
                                    name: '',
                                    labourComponentMaterials: [],
                                    labourComponentsPerProduct: 1,
                                    wasteAllowancePercentage: 7.5,
                                    labourComponentActivities: [],
                                    contingencyBudget: null,
                                    id: initialId
                                }],
                                relatedProducts: [],
                                dataSheets: []
                            };
                            newComponentIds.push(initialId);
                            updateComponentTables();
                            scope.editing = false;
                        }
                    });
                }

                scope.updateProductAncillaryBudget = function () {
                    scope.contingencyBudgetTotal = 0;
                    angular.forEach(scope.product.labourComponents, function (value) {
                        if (value.contingencyBudget) {
                            scope.contingencyBudgetTotal += parseFloat(value.contingencyBudget);
                        }
                        scope.contingencyBudgetTotal = parseFloat($filter('number')(scope.contingencyBudgetTotal, 2));
                    })
                };

                scope.addPackageSubGroup = function (data) {
                    if (scope.subGroupArray && scope.subGroupArray.indexOf(data.id) === -1) {
                        scope.subGroupArray.push(data.id);
                    } else if (scope.subGroupArray && scope.subGroupArray.indexOf(data.id) > -1) {
                        scope.subGroupArray.splice(scope.subGroupArray.indexOf(data.id), 1);
                    }
                };

                // Reset product object when closing modal on RBU
                $rootScope.$on('resetProduct', function () {
                    getProductData();
                });

                function saveTheProduct(productForm, duplicate) {
                    if (!scope.savingProduct) {

                        scope.savingProduct = true;

                        scope.product.subGroups = scope.subGroupArray;

                        var productCopy = angular.copy(scope.product);
                        // It is possible to create what look like duplicate product names by adding
                        // two or more consecutive spaces inside the name. When rendered in a table the
                        // product names will look identical. To prevent this, and show the user the
                        // proper error collapse spaces
                        productCopy.name = productCopy.name.replace(/\s+/g, ' ');

                        // Remove client side generated component IDs
                        productCopy.labourComponents.forEach(c => {
                            if (newComponentIds.includes(c.id)) {
                                delete c.id;
                            }
                        });

                        if (duplicate) {
                            scope.prodCopyId = productCopy.id;
                            delete productCopy.id;
                        }

                        productForm.$setPristine();

                        if (scope.isHub && duplicate) {
                            delete productCopy.id;
                            product.refactor({id: assessmentProductId}, productCopy).$promise.then(function (data) {
                                productForm.$setPristine();
                                scope.submitted = false;
                                scope.product = {};
                                scope.$emit('productSaved', data);
                                // Reset product
                                scope.product = {};
                                productCopy = {};
                                angular.forEach(scope.packages, function (value) {
                                    value.ticked = false;
                                });
                                scope.contingencyBudgetTotal = null;

                                getProductData();
                            }).then(function () {
                                scope.savingProduct = false;
                                delete scope.prodCopyId;
                            }).catch(function () {
                                productCopy.id = scope.prodCopyId;
                                delete scope.prodCopyId;
                                scope.savingProduct = false;
                            });
                        } else {
                            scope.newProduct = new product(productCopy);

                            scope.newProduct.$save().then(function (data) {
                                productForm.$setPristine();
                                scope.submitted = false;
                                console.log($route.current);
                                if ($route.current.$$route.originalPath.indexOf('new-sf-quote') === 1){
                                    scope.$emit('productSaved', data);
                                } else if ($route.current.$$route.originalPath.indexOf('rate-build-up') === -1 && $route.current.$$route.originalPath.indexOf('payment-application') === -1 && $route.current.$$route.originalPath.indexOf('new-sf-quote-request') === -1) {
                                    $location.path('/products');
                                } else if ($route.current.$$route.originalPath.indexOf('new-sf-quote-request') !== -1) {
                                    scope.newProductTemplateAdded({product: data});
                                    scope.$emit('newProductTemplateAdded', data);
                                    scope.newProduct = false;
                                    scope.product = {
                                        dataSheets: []
                                    };
                                } else {
                                    scope.product = {};
                                    scope.$emit('productSaved', data);
                                }
                                // Reset product
                                scope.product = {};
                                productCopy = {};
                                angular.forEach(scope.packages, function (value) {
                                    value.ticked = false;
                                });
                                scope.contingencyBudgetTotal = null;

                                getProductData();
                            }).then(function () {
                                scope.savingProduct = false;
                                delete scope.prodCopyId;
                            }).catch(function () {
                                productCopy.id = scope.prodCopyId;
                                delete scope.prodCopyId;
                                scope.savingProduct = false;
                            });
                        }
                    }
                }

                // save the product
                scope.saveProduct = function (productForm, duplicate) {
                    scope.duplicate = duplicate;
                    scope.submitted = true;
                    scope.packageError = !scope.product.workPackage;

                    var materialError = false;
                    var labourActivityError = false;
                    var noComponentsError = false;
                    var missingQuantities = false;

                    scope.product.labourComponents.forEach(function(component) {
                        if (component.labourComponentMaterials.length == 0) {
                            materialError = true;
                        }
                        if (component.labourComponentActivities.length == 0) {
                            labourActivityError = true;
                        }
                        if (component.labourComponentMaterials.some(m => isNaN(m.quantityPerLabourComponent))) {
                            missingQuantities = true;
                        }
                        if (component.labourComponentActivities.some(a => isNaN(a.quantityPerLabourComponent))) {
                            missingQuantities = true;
                        }
                    });

                    if (scope.product.labourComponents.length == 0) {
                        noComponentsError = true;
                    }

                    if (missingQuantities) {
                        window.alert('You must provide a quantity for each material and labour activity.');
                        return;
                    } else if (productForm.$valid && !scope.packageError) {
                        var message = '';
                        if (materialError && labourActivityError) {
                            message = 'Are you sure you want to save this product with a component that has no materials or labour activities?';
                        } else if (materialError) {
                            message = 'Are you sure you want to save this product with a component that has no materials?';
                        } else if (labourActivityError) {
                            message = 'Are you sure you want to save this product with a component that has no labour activities?';
                        } else if (noComponentsError) {
                            message = 'Are you sure you want to save this product with no components?';
                        }
                        if (scope.editing && !duplicate) {
                            message = 'Are you sure you want to change this product?';
                            message += '\n\nThis will overwrite the product in the main library.\n';
                        }

                        if (message !== '') {
                            message += '\nWould you like to continue?';
                            if (window.confirm(message)){
                                saveTheProduct(productForm, scope.duplicate);
                            }
                        } else {
                            saveTheProduct(productForm, scope.duplicate);
                        }

                    } else {
                        console.log(productForm);
                        var noComponents = true;
                        angular.forEach(scope.product.labourComponents, function (lc, key) {
                            if (lc.deleted == undefined || lc.deleted != true) {
                                noComponents = false;
                            }
                        });
                        if (noComponents) {
                            // If we're saving without components
                            scope.saveModal.text = 'Are you sure you want to save this product without any labour components?<br /> Would you like to continue?';
                            scope.$emit('showModal', scope.saveModal);
                        }
                    }


                    scope.cleanOverwriteProduct = scope.$on('overwriteProduct', function () {
                        saveTheProduct(productForm, scope.duplicate);
                        scope.$broadcast('closeModal');
                    });
                };

                // FUNCTIONS FOR ADDING/REMOVING COMPONENTS, MATERIALS & RELATED

                // Documents
                scope.showHideDocuments = function() {
                    scope.documentsOpen = !scope.documentsOpen;
                }
                scope.fileUploader = {
                    uploader: new FileUploader(),
                    list: []
                };
                scope.uploadForm = {datasheets: []};
                scope.uploadDatasheet = function () {
                    const file = angular.isArray(scope.fileUploader.list) ? scope.fileUploader.list[0] : scope.fileUploader.list;
                    scope.product.dataSheets.push(file);
                    scope.uploadDatasheetModal.close();
                    scope.fileUploader = {
                        uploader: new FileUploader(),
                        list: []
                    };
                };
                scope.deleteDatasheet = function (datasheet) {
                    confirmAction(`Delete document ${datasheet.fileName}?`, () => {
                        const removeFile = new file({id: datasheet.id});

                        removeFile.$remove().then(() => {
                            scope.product.dataSheets = scope.product.dataSheets.filter(d => d.id != datasheet.id);
                        });
                    });
                };

                // Component jump list
                scope.showHideComponentJumpList = function() {
                    scope.componentJumpListOpen = !scope.componentJumpListOpen;
                };
                scope.jumpToComponent = function(component) {
                    const id = `component-${component.id}`;
                    $anchorScroll(id);
                }

                // Add new component
                scope.addComponent = function () {
                    const newComponent = {
                        name: '',
                        labourComponentMaterials: [],
                        labourComponentsPerProduct: 1,
                        wasteAllowancePercentage: 7.5,
                        labourComponentActivities: [],
                        contingencyBudget: null
                    };

                    // The component needs a temporary ID so a table can be generated and cached
                    const newComponentId = Math.max.apply(Math, scope.product.labourComponents.map(o => o.id)) + 1;
                    newComponent.id = newComponentId;
                    newComponentIds.push(newComponent.id);

                    // Get highest stage order and add +10
                    const newStageOrder = Math.max.apply(Math, scope.product.labourComponents.map(o => o.stageOrder)) + 10;

                    newComponent.stageOrder = newStageOrder;

                    scope.product.labourComponents.push(newComponent);
                    scope.tables[newComponent.id] = scope.generateTable(newComponent);

                    // Jump to the new component, giving the page time to update
                    $timeout(() => {
                        const id = `component-${newComponentId}`;
                        $anchorScroll(id);
                    }, 500);
                };
                // Remove material, labour activity or component
                scope.confirmDelete = [];
                scope.removeRow = function (index, type, button, productForm) {
                    if (type !== 'labourComponents') {
                        this.$parent.component[type].splice(index, 1);
                    } else {
                        scope.confirmDelete[index] = !scope.confirmDelete[index];
                        if (button === 'confirm') {
                            scope.product.labourComponents[index].deleted = true;
                            if (productForm && productForm['forms.componentForm_'+index]) {
                                productForm.$removeControl(productForm['forms.componentForm_'+index]);
                            }
                        }
                    }
                };

                scope.closeMaterialModal = function () {
                    scope.selectMaterial = false;
                };

                // Warn users when quantity unit is changed
                scope.quantityUnitChange = function () {
                    if (scope.product.labourComponents[0] && scope.product.labourComponents[0].labourComponentMaterials && scope.product.labourComponents[0].labourComponentMaterials.length > 0) {
                        confirmAction("You have changed the quantity unit. Do you want to delete all material and labour activity quantities?", () => {
                            scope.product.labourComponents.forEach(component => {
                                component.labourComponentMaterials.forEach(material => material.quantityPerLabourComponent = NaN);
                                component.labourComponentActivities.forEach(activity => activity.quantityPerLabourComponent = NaN);
                            });
                            that.reloadAllComponents(scope);
                        }, () => {
                            that.reloadAllComponents(scope);
                        });
                    }
                };
                scope.$on('resetMaterialQuantities', function () {
                    angular.forEach(scope.product.labourComponents, function (value) {
                        angular.forEach(value.labourComponentMaterials, function (value) {
                            value.quantityPerLabourComponent = null;
                        });
                        angular.forEach(value.labourComponentActivities, function (value) {
                            value.quantityPerLabourComponent = null;
                        });
                    });
                    scope.$broadcast('closeModal');
                });

                // Confirmation modal.
                scope.saveModal = {
                    'text': null,
                    'cancelFunction': 'modalClosed()',
                    'buttons': [
                        {
                            'text': 'Yes',
                            'classes': '',
                            'clickFunction': 'overwriteProduct'
                        },
                        {
                            'text': 'Cancel',
                            'classes': 'btn-red',
                            'clickFunction': 'closeModal'
                        }
                    ]
                };

                // Materials table / selection
                scope.selectMaterial = false;
                scope.showMaterialTable = function (component, materialIndex) {
                    scope.selectMaterial = true;
                    const componentIndex = scope.product.labourComponents.findIndex(e => e.id == component.id);
                    scope.currentComponent = (typeof componentIndex != 'undefined') ? componentIndex : null;
                    scope.currentMaterial = (typeof materialIndex != 'undefined') ? materialIndex : null;
                };

                scope.materialSelected = function (item) {
                    that.addMaterialToComponent(scope, scope.currentComponent, item, scope.currentMaterial);

                    scope.currentComponent = null;
                    scope.currentMaterial = null;
                    scope.selectMaterial = false;
                };

                scope.showNewMaterialModal = function (componentIndex) {
                    scope.currentComponent = componentIndex;
                    scope.selectMaterial = false;
                    scope.newMaterial = true;
                };
                scope.closeNewMaterialModal = function () {
                    scope.newMaterial = false;
                    scope.currentComponent = null;
                };
                scope.$on('newMaterialAdded', function (event, item) {
                    event.stopPropagation();

                    that.addMaterialToComponent(scope, scope.currentComponent, item, scope.currentMaterial);
                    scope.closeNewMaterialModal();
                });

                /* UI Sortable options */
                scope.sortableOptions = {
                    handle: '.handle',
                    axis: 'y'
                };

                scope.addLabourActivity = function (component, labourActivityIndex) {
                    const componentIndex = scope.product.labourComponents.findIndex(e => e.id == component.id);
                    scope.currentComponent = componentIndex;
                    scope.selectLabourActivity = true;
                    scope.currentLabourActivity = (typeof labourActivityIndex != 'undefined') ? labourActivityIndex : null;
                };
                scope.activitySelected = function (item) {
                    that.addActivityToComponent(scope, scope.currentComponent, item, scope.currentLabourActivity);

                    scope.currentComponent = null;
                    scope.currentLabourActivity = null;
                    scope.selectLabourActivity = false;
                };

                // New Labour Activity
                scope.showNewLabourActivity = function (componenentIndex) {
                    scope.currentComponent = componenentIndex;
                    scope.selectLabourActivity = false;
                    scope.newLabourActivity = true;
                };
                scope.closeLabourActivityModal = function () {
                    scope.newLabourActivity = false;
                    scope.selectLabourActivity = false;
                    scope.currentComponent = null;
                };
                $rootScope.$on('newLabourActivityAdded', function (event, item) {
                    that.addActivityToComponent(scope, scope.currentComponent, item, scope.currentLabourActivity);
                    scope.closeLabourActivityModal();
                });

                const that = this;
                scope.moveComponentDown = function(event, index) {
                    event.preventDefault();
                    if (index == scope.product.labourComponents.length - 1 || scope.product.labourComponents.length == 1) {
                        return;
                    }
                    [scope.product.labourComponents[index], scope.product.labourComponents[index + 1]] = [scope.product.labourComponents[index + 1], scope.product.labourComponents[index]];
                };
                scope.moveComponentUp = function(event, index) {
                    event.preventDefault();
                    if (index == 0 || scope.product.labourComponents.length == 1) {
                        return;
                    }
                    [scope.product.labourComponents[index - 1], scope.product.labourComponents[index]] = [scope.product.labourComponents[index], scope.product.labourComponents[index - 1]];
                };
                const confirmAction = function(message, confirmCallback, cancelCallback) {
                    scope.confirmationQuestion = message;
                    scope.confirmationConfirm = () => {
                        confirmCallback();
                        scope.confirmModal.close();
                    }
                    scope.confirmationCancel = () => {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                        scope.confirmModal.close();
                    }
                    scope.confirmModal.open();
                }
                scope.deleteComponent = function(event, component, index) {
                    confirmAction(`Delete component "${component.name}"?`, () => {
                        scope.product.labourComponents.splice(index, 1);
                    });
                    event.preventDefault();
                };
                const deleteMaterial = function(event, component, material, materialIndex) {
                    const componentIndex = scope.product.labourComponents.findIndex(e => e.id == component.id);
                    confirmAction(`Delete material "${material.name}"?`, () => {
                        scope.product.labourComponents[componentIndex].labourComponentMaterials.splice(materialIndex, 1);
                        that.reloadComponentWithIndex(scope, componentIndex);
                    });
                };
                const deleteActivity = function(event, component, activity, activityIndex) {
                    confirmAction(`Delete labour activity "${activity.labourActivity.name}?"`, () => {
                        component.labourComponentActivities.splice(activityIndex, 1);
                        that.reloadComponent(scope, component);
                    });
                };
                scope.onAction = function(event, component) {
                    const tag = event.tag;  // Will be one of 'swap', 'delete'

                    switch (tag) {
                        case addMaterialTag:
                            scope.showMaterialTable(component);
                            break;
                        case swapTag:
                            switch (event.section) {
                                case sectionMaterials:
                                    scope.showMaterialTable(component, event.row);
                                    break;
                                case sectionActivities:
                                    scope.addLabourActivity(component, event.row);
                                    break;
                            }
                            break;
                        case addActivityTag:
                            scope.addLabourActivity(component);
                            break;
                        case deleteTag:
                            switch (event.section) {
                                case sectionMaterials:
                                    deleteMaterial(event, component, component.labourComponentMaterials[event.row], event.row);
                                    break;
                                case sectionActivities:
                                    deleteActivity(event, component, component.labourComponentActivities[event.row], event.row);
                                    break;
                            }
                            break;
                    }
                };
                scope.onChange = function(event, component) {
                    const row = event.row;
                    const tag = event.tag;  // Will be one of 'quantity', 'waste'
                    const value = (typeof event.value === 'string') ? event.value.trim() : event.value;
                    const numericValue = value === '' ? NaN : Number(value);

                    switch (event.section) {
                        case sectionMaterials:
                            const material = component.labourComponentMaterials[row];

                            switch (tag) {
                                case configurationTag:
                                    material.materialConfiguration = value;
                                    break;
                                case quantityTag:
                                    material.quantityPerLabourComponent = numericValue;
                                    break;
                                case wasteTag:
                                    material.wasteAllowancePercentage = numericValue;
                                    break;
                            }
                            break;
                        case sectionActivities:
                            const activity = component.labourComponentActivities[row];

                            if (tag == quantityTag) {
                                activity.quantityPerLabourComponent = numericValue;
                            }
                            break;
                    }
                };
                scope.generateTable = function generateTable(component) {
                    let table = {
                        columns: [
                            // Material or activity icon
                            TableFactory.iconColumn((section, row) => {
                                if (section == sectionMaterials) {
                                    return "cubes";
                                } else {
                                    return "user";
                                }
                            }, null),
                            // Name of material or activity
                            TableFactory.textColumn((section, row) => {
                                switch (section) {
                                    case sectionMaterials:
                                        return component.labourComponentMaterials[row].name;
                                    case sectionActivities:
                                        return component.labourComponentActivities[row].labourActivity.name;
                                    default:
                                        return null;
                                }
                            }, (section, row) => {
                                switch (section) {
                                    case sectionMaterials:
                                        const material = component.labourComponentMaterials[row];
                                        return TableFactory.tooltip(
                                            material.name,
                                            [
                                                TableFactory.tooltipRow(material.materialConfiguration, "Configuration:", [() => [
                                                    material.materialConfiguration.name,
                                                    `(${material.materialConfiguration.singularSize})`,
                                                    $filter('units')(material.quantityUnit, 'UNITSTEXT'),
                                                    'per',
                                                    `${material.material.singularName})`].join(' ')
                                                                                                                      ]),
                                                TableFactory.tooltipRow(true, "Brand:", [() => material.brand]),
                                                TableFactory.tooltipRow(true, "Type:", [() => material.material.materialType.name]),
                                                TableFactory.tooltipRow(true, "Specifics:", [() => material.specifics]),
                                                TableFactory.tooltipRow(true, "Size:", [() => [
                                                    material.materialConfiguration ? material.materialConfiguration.singularSize : material.material.singularSize,
                                                    $filter('units')(material.quantityUnit, 'UNITSTEXT'),
                                                    'per',
                                                    material.material.singularName,
                                                    '-',
                                                    material.configurationName ? material.materialConfiguration.packSize : material.material.packSize,
                                                    material.material.singularName,
                                                    'per',
                                                    material.material.packName
                                                ].join(' ')])
                                            ],
                                            true
                                        );
                                    case sectionActivities:
                                        const activity = component.labourComponentActivities[row].labourActivity;
                                        return TableFactory.tooltip(
                                            activity.name,
                                            [
                                                TableFactory.tooltipRow(true, "Trade:", [() => activity.tradeName]),
                                                TableFactory.tooltipRow(true, "Description:", [() => activity.description]),
                                                TableFactory.tooltipRow(true, "Unit:", [() => $filter('units')(activity.quantityUnit, 'UNITSTEXT')])
                                            ],
                                            true
                                        )
                                    default:
                                        return null;
                                }
                            }, null, false),
                            // Swap material/activity
                            TableFactory.editColumn(swapTag, (section, row) => {
                                let tooltip = null;
                                switch (section) {
                                    case sectionMaterials:
                                        tooltip = "Click to swap material";
                                        break;
                                    case sectionActivities:
                                        tooltip = "Click to swap labour activity";
                                        break;
                                    default:
                                        tooltip = null;
                                }
                                if (tooltip) {
                                    return TableFactory.tooltip(tooltip);
                                } else {
                                    return null;
                                }
                            }),
                            // Configuration
                            TableFactory.dropdownColumn(configurationTag, (section, row) => {
                                if (section == sectionActivities) {
                                    return [];
                                } else {
                                    const material = component.labourComponentMaterials[row];
                                    if (material.configurations) {
                                        return material.configurations.map(
                                            configuration => TableFactory.dropdownItem(
                                                configuration.id,
                                                `${configuration.name} - ${configuration.singularSize} ${that.htmlShortUnit(material.material.quantityUnit, true)} per ${material.material.singularName} - ${configuration.packSize} ${material.material.singularName} per ${material.material.packName}`,
                                                material.materialConfiguration && material.materialConfiguration.id === configuration.id)
                                        );
                                    } else {
                                        return [];
                                    }
                                }
                            }, null, null, 'Configuration', true),
                            // Quantity
                            TableFactory.numberColumn(quantityTag, (section, row) => {
                                if (section == sectionMaterials) {
                                    return component.labourComponentMaterials[row].quantityPerLabourComponent;
                                } else {
                                    return component.labourComponentActivities[row].quantityPerLabourComponent;
                                }
                            }, (section, row) => {
                                if (section == sectionHeader) {
                                    return TableFactory.tooltip('Enter quantity required per unit of product');
                                } else {
                                    return null;
                                }
                            }, 'Quantity', (section, row) => true),
                            // Quantity unit
                            TableFactory.htmlColumn((section, row) => {
                                let value = '';
                                if (section == sectionMaterials) {
                                    const material = component.labourComponentMaterials[row];
                                    if (!scope.product.quantityUnit || (!material.quantityUnit && !material.material.quantityUnit)) {
                                        value = "No quantity unit";
                                    } else {
                                        const materialUnit = material.quantityUnit ? material.quantityUnit : material.material.quantityUnit;
                                        value = `${that.htmlShortUnit(materialUnit)} per ${that.htmlShortUnit(scope.product.quantityUnit)}`;
                                    }
                                } else if (section == sectionActivities) {
                                    const activity = component.labourComponentActivities[row];
                                    if (!scope.product.quantityUnit || (!activity.quantityUnit && !activity.labourActivity.quantityUnit)) {
                                        value = "No quantity unit";
                                    } else {
                                        const firstUnit = that.htmlShortUnit(activity.quantityUnit) || that.htmlShortUnit(activity.labourActivity.quantityUnit);
                                        value = `${firstUnit} per ${that.htmlShortUnit(scope.product.quantityUnit)}`;
                                    }
                                }
                                return value;
                            }),
                            // Waste
                            TableFactory.percentageColumn(wasteTag, (section, row) => {
                                if (section == sectionMaterials) {
                                    return component.labourComponentMaterials[row].wasteAllowancePercentage;
                                } else {
                                    // No waste for activities
                                    return null;
                                }
                            }, (section, row) => {
                                if (section == sectionHeader) {
                                    return TableFactory.tooltip("Enter wastage allowance for materials");
                                } else {
                                    return null;
                                }
                            }, 'Waste', (section, row) => true),
                            // Delete component
                            TableFactory.deleteColumn(deleteTag, null, function(section, row) {
                                switch (section) {
                                    case sectionMaterials:
                                        return TableFactory.tooltip('Click to delete material');
                                    case sectionActivities:
                                        return TableFactory.tooltip('Click to delete labour activity');
                                    default:
                                        return null;
                                }
                            })
                        ],
                        // First section is materials, second labour activity
                        sectionCount: 2,
                        // Return number of materials or activities to display in each section
                        rowCount: function(section) {
                            if (section == sectionMaterials) {
                                return component.labourComponentMaterials.length;
                            } else {
                                return component.labourComponentActivities.length;
                            }
                        },
                        // Allow users to add new materials or activities
                        sectionAddRowFooters: [
                            {
                                icon: 'plus',
                                title: 'Add material',
                                cssClass: 'add',
                                tag: addMaterialTag
                            },
                            {
                                icon: 'plus',
                                title: 'Add labour activity',
                                cssClass: 'add',
                                tag: addActivityTag
                            }
                        ]
                    };
                    return table;
                };
            },
            htmlShortUnit: function(type, encoded) {
                /*
                 * Convert quantity unit identifier into human readable
                 * short unit (e.g. m, kg)
                 */
                if (!type) {
                    return '';
                }
                let shortUnit = SHORTUNITSTEXT[type] || '';
                if (encoded) {
                    return shortUnit.replace("2", "²").replace("3", "³");
                } else {
                    return shortUnit.replace(/[0-9]+/gi, (num) => `<sup>${num}</sup>`);
                }
            },
            reloadComponentWithIndex: function(scope, componentIndex) {
                const component = scope.product.labourComponents[componentIndex];
                this.reloadComponent(scope, component);
            },
            reloadComponent: function(scope, component) {
                scope.tables[component.id] = scope.generateTable(component);
            },
            reloadAllComponents: function(scope) {
                scope.product.labourComponents.forEach(component => this.reloadComponent(scope, component));
            },
            addMaterialToComponent: function(scope, componentIndex, material, replacingMaterialIndex) {
                const newMaterial = angular.copy(material);

                newMaterial.material = angular.copy(material);

                if (newMaterial.configurations && newMaterial.configurations.length > 0) {
                    newMaterial.materialConfiguration = newMaterial.configurations[0];
                }

                if (replacingMaterialIndex !== undefined && replacingMaterialIndex !== null) {
                    newMaterial.quantityPerLabourComponent = scope.product.labourComponents[componentIndex].labourComponentMaterials[replacingMaterialIndex].quantityPerLabourComponent;
                    scope.product.labourComponents[componentIndex].labourComponentMaterials[replacingMaterialIndex] = newMaterial;
                } else {
                    newMaterial.quantityPerLabourComponent = NaN;
                    scope.product.labourComponents[componentIndex].labourComponentMaterials.push(newMaterial);
                }

                this.reloadComponentWithIndex(scope, componentIndex);
            },
            addActivityToComponent: function(scope, componentIndex, activity, replacingActivityIndex) {
                const newActivity = angular.copy(activity);

                newActivity.labourActivity = angular.copy(activity);
                if (replacingActivityIndex !== undefined && replacingActivityIndex !== null) {
                    newActivity.quantityPerLabourComponent = scope.product.labourComponents[componentIndex].labourComponentActivities[replacingActivityIndex].quantityPerLabourComponent;
                    scope.product.labourComponents[componentIndex].labourComponentActivities[replacingActivityIndex] = newActivity;
                } else {
                    newActivity.quantityPerLabourComponent = NaN;
                    scope.product.labourComponents[componentIndex].labourComponentActivities.push(newActivity);
                }

                this.reloadComponentWithIndex(scope, componentIndex);
            }
        };
    });
