import {CsUtils} from '../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:BillOfQuantitiesCtrl
     * @description
     * # BillOfQuantitiesCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('BillOfQuantitiesCtrl', function (
            $scope,
            $rootScope,
            $route,
            $routeParams,
            project,
            assessment,
            $window,
            ENV,
            $http,
            PermPermissionStore
        ) {
            $scope.assessmentId = parseInt($routeParams.assessmentId);
            $rootScope.projectId = $scope.projectId = parseInt($routeParams.projectId);

            $scope.isHub = $route.current.$$route.isHub;
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition($scope.isHub ?
                'canWriteProjectHubPackageAssessments' : 'canWriteProjectsOffsiteAssessments');

            assessment.get({id: $routeParams.assessmentId}, {}, function (data) {
                $scope.assessment = data;
            });

            $scope.exportBillOfQuantities = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/projects/' + $scope.projectId + '/assessments/' + $scope.assessmentId + '/bill-of-quantities-export'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                    if (!$scope.assessment.quantificationCompleted && !$scope.assessment.snapshotComment) {
                        // Mark assessment as started
                        assessment.progressSection({id: $scope.assessment.id, section: 'quantification', transition: 'start'});
                    }
                });
            };

            $scope.reloadBoq = function () {
                $scope.assessment = null;
                setTimeout(function () {
                    assessment.get({id: $routeParams.assessmentId}, {}, function (data) {
                        $scope.assessment = data;
                    });
                }, 10);
            };

            $scope.deleteAllZones = function () {
                if (window.confirm('Are you sure you want to delete all zones in this assessment? This cannot be undone.')) {
                    $http({
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/projects/' + $scope.projectId + '/assessments/' + $scope.assessmentId + '/zones/all'
                    }).success(function (data, status, headers) {
                        $route.reload();
                    });
                }
            };
        });
})();
