(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.materialDeliveries
     * @description
     * # materialDeliveries
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('materialOrderInvoices', ['ENV', 'Resource', 'DateUtils', function (ENV, $resource, DateUtils) {
            return $resource(
                ENV.apiEndpoint + '/invoices/:id', {id: '@id', onlygroups: '@onlygroups', groups: '@groups', projectId: '@projectId', orderId: '@orderId', transition: '@transition'}, {
                    post: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/invoices',
                        transformRequest: function (data) {
                            data.invoiceDate = DateUtils.toServerDateTime(data.invoiceDate);

                            return angular.toJson(data);
                        }
                    },
                    patch: {
                        method: 'PATCH',
                        url:  ENV.apiEndpoint + '/invoices/:id',
                        transformRequest: function (data) {
                            data.invoiceDate = DateUtils.toServerDateTime(data.invoiceDate);

                            return angular.toJson(data);
                        }
                    },
                    approve: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/invoices/:id/approve'
                    },
                    queryInvoice: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/invoices/:id/query'
                    },
                    resolve: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/invoices/:id/resolve'
                    },
                    pay: {
                        method: 'POST',
                        url:  ENV.apiEndpoint + '/invoices/:id/transitions/pay'
                    },
                    transition: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/invoices/:id/transitions/:transition'
                    }
                }
            );
        }]);
})();
