'use strict';

angular.module('scswebappApp')
    .directive('budgetPie', function (project, $locale, $q, $filter) {

        return {
            template: '<div></div>',
            restrict: 'E',
            transclude: true,
            scope: {
                'pieTitle': '=',
                'budgetData': '='
            },
            link: function postLink(scope, element, attrs) {
                var patterns = [
                    {
                        'id': 'loss',
                        'path': {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: '#f33',
                            strokeWidth: 3
                        },
                        opacity: 0.75
                    }
                ];
                Highcharts.setOptions({
                    credits: {enabled: false},
                    chart: {
                        style: {
                            fontFamily: 'inherit;'
                        }
                    },
                    defs: {
                        patterns: patterns
                    },
                    lang: {
                        thousandsSep: ','
                    },
                    tooltip: {
                        backgroundColor: '#ffffff'
                    },
                    colors: ['#37a6de', '#f39200', '#ffde00', '#63c94a', '#e6007e', '#bccbd4',
                        '#0f52ba', '#ccc', '#aaa', '#888', '#666']
                });

                var renderChart = function () {
                    var total = 0;
                    var chartData = [];
                    angular.forEach(scope.budgetData, function (data) {
                        if ('Actual' === data.name || 'Accrued' === data.name) {
                            // Don't plot this segment
                        } else if ('Total' !== data.name) {
                            // data.actual = scope.budgetData
                            if (parseFloat(data.y) < 0) {
                                data.y = -parseFloat(data.y);
                                data.name = data.name + ' (LOSS)';
                                data.color = 'url(#loss)';
                                data.isLoss = 'true';
                            }
                            chartData.push(data);
                        } else {
                            total = parseFloat(data.y);
                        }
                    });
                    scope.budgetPie = Highcharts.chart(element[0], {
                        title: {text: scope.pieTitle + ': ' + $filter('currency')(total)},
                        plotOptions: {
                            pie: {
                                size: '75%'
                            }
                        },
                        series: [
                            {
                                type: 'pie',
                                name: 'Tender Budget',
                                data: chartData,
                                tooltip: {
                                    pointFormatter: function() {
                                        if (this.hasOwnProperty('actual')) {
                                            return 'Actual: <b>' + $filter('currency')(this.actual) + '</b><br>' +
                                                'Accrued: <b>' + $filter('currency')(this.accrued) + '</b><br>' +
                                                'Total: <b>' + $filter('currency')(this.y) + '</b> (' + Math.round(this.percentage * 100) / 100 + '%)';
                                        } else if (this.hasOwnProperty('isLoss')) {
                                            return '<b>-' + $filter('currency')(this.y) + '</b>';
                                        } else {
                                            return '<b>' + $filter('currency')(this.y) + '</b> (' + Math.round(this.percentage*100)/100 + '%)';
                                        }
                                    }
                                },
                                dataLabels: {
                                    alignTo: 'plotEdges',
                                    connectorWidth: 2,
                                    filter: {property: 'percentage', operator: '>', value: 0}
                                }
                            }
                        ]
                    });
                    scope.budgetPie.reflow();
                };
                scope.$watch('budgetData', function() {
                    renderChart();
                });
            }
        }
    });
