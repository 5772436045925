'use strict';

/**
 * @ngdoc directive
 * @name or-ng.formchanged
 * @description
 * # orformchanged
 * Usage:  <haschanged scope="newUserForm"></haschanged>
 */

angular.module('orNgLoader', [])
    .directive('loader', function ($timeout) {
        return {
            template: '<div class="loader ng-cloak" ng-show="shown" ng-class="loaderClass"><i class="icon-spin3"></i><i class="icon-ok"></i><i class="icon-cancel"></i></div>',
            restrict: 'E',
            link: function postLink(scope, element) {
                scope.shown = false;
                scope.$on('loader', function (event, response) {
                    scope.loaderClass = null;
                    if (response === 'request') {
                        scope.shown = true;
                    } else if (response === 'response') {
                        scope.loaderClass = 'loadSuccess';
                    } else if (response === 'responseError') {
                        scope.loaderClass = 'loadError';
                    } else {
                        scope.shown = false;
                    }
                });
            }
        };
    })
    .config(function ($httpProvider) {
        // Intercept http calls.
        $httpProvider.interceptors.push(function ($q, $location, $rootScope) {
            $rootScope.numLoadings = 0;
            return {
                // On request success
                request: function (config) {
                    if (config && config.noLoader === true) {
                        return config;
                    }
                    $rootScope.numLoadings++;
                    $rootScope.$broadcast('loader', 'request');
                    return config;
                },

                // On response success
                response: function (response) {
                    if (response.config.noLoader === true) {
                        return response;
                    }
                    if ((--$rootScope.numLoadings) === 0) {
                        $rootScope.$broadcast('loader', 'response');
                    }
                    return response;
                },

                // On response failure
                responseError: function (rejection) {
                    if (rejection.config.noLoader === true) {
                        return $q.reject(rejection);
                    }
                    if (!(--$rootScope.numLoadings)) {
                        $rootScope.$broadcast('loader', 'responseError');
                    }
                    return $q.reject(rejection);
                }
            };
        });
    });

