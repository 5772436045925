import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Assessment} from "../../chalkstring-api/services/assessment.service";
import {HttpClient} from "@angular/common/http";
import {first, map} from "rxjs/operators";

export enum RateBuildUpIssueCode
{
    ProductNoOverhead = 2101,
    ProductNoProfit = 2102,
    ProductNoInflation = 2103,
    ProductEmpty = 2104,
    ProductSkeleton = 2105,
    ProductSFNoPrice = 2106,
    ProductSFGuesstimate = 2107,
    ProductSFArchived = 2108,
    ProductSFInactive = 2109,
    ProductSFBadWeights = 2110,
    ProductVariation = 2111,
    ProductSF = 2112,
    ProductClientHidden = 2113,
    ProductPostTenderContract = 2114,
    ComponentNoContingency = 2201,
    ComponentEmpty = 2202,
    ActivityNoRate = 2301,
    ActivityGuesstimate = 2302,
    ActivityArchived = 2303,
    ActivityInactive = 2304,
    MaterialNoPrice = 2401,
    MaterialGuesstimate = 2402,
    MaterialArchived = 2403,
    MaterialInactive = 2404,
    MaterialNoWaste = 2405,
}

export enum RateBuildUpIssueSeverity
{
    Advisory = 0,
    Warning = 1,
    PreventTender = 10,
}

export interface RateBuildUpIssueCount
{
    code: RateBuildUpIssueCode;
    severity: RateBuildUpIssueSeverity;
    count: number;
}

interface RbuProductIssues
{
    [apId: number]: RateBuildUpIssueCount[];
}

export interface RbuIssuesResponse
{
    products: RbuProductIssues;
    assessment: RateBuildUpIssueCount[];
}

@Injectable()
export class RateBuildUpIssueService
{
    assessment: Assessment;
    working: Subject<boolean> = new Subject<boolean>();
    lastResponse: RbuIssuesResponse;
    issuesFetched: Subject<RbuIssuesResponse> = new Subject<RbuIssuesResponse>();

    constructor(private _http: HttpClient)
    {
    }

    fetchRbuIssues(assessment: Assessment)
    {
        if (!assessment || !assessment.id) {
            console.error(assessment);
            throw new Error('Expected an Assessment with an ID');
        }
        // Store the assessment used in the last request
        this.assessment = assessment;

        // Construct the API url
        const url = '/assessments/' + assessment.id + '/rbu-issues';

        // Mark as fetching for swirly whirlies
        this.working.next(true);

        // Do request
        this._http.get<RbuIssuesResponse>(url)
            .pipe(map(res => {
                // Store response
                this.lastResponse = res;

                // Inform any subscribers
                this.issuesFetched.next(this.lastResponse);

                // Inform subscribers fetching has finished
                this.working.next(false);

                // Return response
                return res;
            })).pipe(first()).subscribe();
    }
}
