(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.labourskills
     * @description
     * # labourskills
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('labourrate', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/labourrates/:id', {id: '@id'}, {
                    create:{
                        method:'POST',
                        transformRequest: function(data){
                            var labourRate = {
                                'notes': data.notes,
                                'price': data.price,
                                'region': data.region,
                                'product': data.product
                            };

                            return angular.toJson(labourRate);
                        }
                    },
                    update:{
                        method:'PUT',
                        transformRequest: function(data){
                            var labourRate = {
                                'notes': data.notes,
                                'price': data.price,
                                'region': data.region,
                                'product': data.product
                            };

                            return angular.toJson(labourRate);
                        }
                    },
                    archive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/labourrates/:id/archive'
                    },
                    unarchive:{
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/labourrates/:id/unarchive'
                    }
                }
            );
        }]);
})();