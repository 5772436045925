import {CsUtils} from '../../../other/csutils.js';

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProgressCtrl
     * @description
     * # ProgressCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProgressMapCtrl', function (
            $scope,
            $route,
            $rootScope,
            $routeParams,
            $filter,
            project,
            workscope,
            assessment,
            $http,
            $window,
            ENV,
            PermPermissionStore
        ) {
            $rootScope.projectId = $scope.projectId = $routeParams.id;
            project.get({id: $routeParams.id}, {}, function (data) {
                $scope.project = data;
            });
            $scope.hasCommercialDataPermission = PermPermissionStore.hasPermissionDefinition('canReadProgressCommercialData');

            var renderChart = function () {
                Highcharts.setOptions({
                    credits: {enabled: false},
                    lang: {
                        drillUpText: 'Back to project hub',
                        thousandsSep: ','
                    },
                    tooltip: {
                        backgroundColor: '#ffffff'
                    },
                    chart: {
                        style: {
                            fontFamily: 'inherit;'
                        }
                    }
                });

                $scope.chart = Highcharts.chart('container', {
                    chart: {
                        height: 450
                    },
                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'middle',
                        y: 0,
                        symbolHeight: 400
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.point.name + '</b><br>' +
                                ($scope.hasCommercialDataPermission ?
                                ('<b>Completed:</b> ' + $filter('currency')(this.point.valueProgressed) + ' <b>/</b> ' + $filter('currency')(this.point.valueRequired) + '<br>') : '') +
                                '<b>Progress:</b> ' + $filter('number')(this.point.completion, 2) + '%';
                        }
                    },
                    colorAxis: {
                        min: 0,
                        max: 100,
                        labels: {
                            format: '{value}%'
                        },
                        stops: [
                            [0, '#f12e2e'],
                            [0.01, '#BCCBD4'],
                            [0.2, '#ABC6D5'],
                            [0.3, '#9AC1D6'],
                            [0.4, '#8ABDD7'],
                            [0.5, '#79B8D9'],
                            [0.6, '#68B3DA'],
                            [0.7, '#58AFDB'],
                            [0.8, '#47AADC'],
                            [0.9, '#37A6DE'],
                            [0.99, '#63c94a']
                        ]
                    },
                    series: [
                        {
                            type: 'treemap',
                            layoutAlgorithm: 'squarified',
                            animationLimit: 1000,
                            turboThreshold: 0,
                            dataLabels: {
                                enabled: false
                            },
                            levelIsConstant: false,
                            levels: [{
                                level: 1,
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        textOutline: false,
                                        color: 'white'
                                    }
                                },
                            }],
                            data: $scope.heatMap,
                            point: {
                                events: {
                                    click: function () {
                                        if (this.hasOwnProperty('workScope')) {
                                            return false; // TODO spawn new progress modal that we use on the progress bill
                                        } else if (this.hasOwnProperty('subZone')) {
                                            $scope.doHeatMap(this.assessment, this.assessmentZone, this.subZone);
                                        } else if (this.hasOwnProperty('assessmentZone')) {
                                            $scope.doHeatMap(this.assessment, this.assessmentZone);
                                        } else if (this.hasOwnProperty('assessment')) {
                                            $scope.doHeatMap(this.assessment);
                                        }
                                    }
                                }
                            }
                        },
                    ],
                    title: false,
                    subtitle: false
                });
            };

            // Function for handling heatmap response
            var handleHeatMapResponse = function (data) {
                console.log('handleHeatMapResponse', data);
                $scope.assessment = data.assessment;
                $scope.assessmentZone = data.assessmentZone;
                $scope.subZone = data.subZone;
                var points = [];

                angular.forEach(data.cells, function (cell) {
                    let point = {
                        id: cell.i,
                        name: cell.n,
                        value: cell.d,
                        colorValue: cell.c,
                        valueRequired: cell.r,
                        valueProgressed: cell.p,
                        completion: cell.c,
                    };
                    if (cell.a) {
                        point.assessment = cell.a;
                    }
                    if (cell.z) {
                        point.assessmentZone = cell.z;
                    }
                    if (cell.s) {
                        point.subZone = cell.s;
                    }
                    if (cell.w) {
                        point.workScope = cell.w;
                    }
                    points.push(point);
                });
                $scope.heatMap = points;
                console.log($scope.heatMap);
                renderChart();
            };

            // Fetch project-level heatmap data on load
            $scope.doHeatMap = function (assessmentId, zoneId, subZoneId) {
                var heatmapParams = {id: $routeParams.id};
                if (subZoneId) {
                    heatmapParams.assessment = assessmentId;
                    heatmapParams.assessmentZone = zoneId;
                    heatmapParams.subZone = subZoneId;
                } else if (zoneId) {
                    heatmapParams.assessment = assessmentId;
                    heatmapParams.assessmentZone = zoneId;
                } else if (assessmentId) {
                    heatmapParams.assessment = assessmentId;
                }
                workscope.getHeatMapData(heatmapParams).$promise.then(handleHeatMapResponse);
            }
            $scope.doHeatMap();

            $scope.exportProgressData = function (isProduct) {
                var httpUrl = (isProduct) ? '/export-progress-products' : '/export-progress-groups';

                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/projects/' + $routeParams.id + httpUrl
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            }
        });
})();
