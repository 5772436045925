(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.workscope
	 * @description
	 * # workscope
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('progressGroup', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/progressgroups/:id', {id: '@id', alcId: '@alcId'}, {
                    customSave: {
                        transformRequest: function(data){
                            var newData = {
                                'name': data.name,
                                'colour': data.colour,
                                'project': data.project
                            };
                            return angular.toJson(newData);
                        }
                    },
                    updateGroup: {
                        method: 'PATCH'
                    },
                    addComponent: {
                        url: ENV.apiEndpoint + '/progressgroups/:id/assessmentlabourcomponents/:alcId',
                        method: 'POST'
                    },
                    removeComponent: {
                        url: ENV.apiEndpoint + '/progressgroups/:id/assessmentlabourcomponents/:alcId',
                        method: 'DELETE'
                    },
                    getComponents: {
                        url: ENV.apiEndpoint + '/progressgroups/:id/assessmentlabourcomponents',
                        method: 'GET',
                        isArray: true
                    },
                    updateProgress: {
                        url: ENV.apiEndpoint + '/progressgroupzoneprogresses/:id',
                        method: 'PATCH'
                    },
                    getZoneProgress: {
                        url: ENV.apiEndpoint + '/progressgroupzoneprogresses/:id',
                        method: 'GET'
                    },
                    getZoneProgresses: {
                        url: ENV.apiEndpoint + '/progressgroupzoneprogresses/',
                        method: 'GET',
                        isArray: true
                    }
	            }
	        );

	  }]);
})();
