import {NgModule} from "@angular/core";
import {ClientListComponent} from "./contacts/tables/client-list.component";
import {TableViewModule} from "./table-view.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SubcontractorListComponent} from "./contacts/tables/subcontractor-list.component";
import {SupplierListComponent} from "./contacts/tables/supplier-list.component";
import {OperativeListComponent} from "./contacts/tables/operative-list.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TableViewModule,
    ],
    exports: [
    ],
    declarations: [
        ClientListComponent,
        OperativeListComponent,
        SubcontractorListComponent,
        SupplierListComponent,
    ],
    entryComponents: [
        ClientListComponent,
        OperativeListComponent,
        SubcontractorListComponent,
        SupplierListComponent,
    ],
    providers: [],
})
export class ContactsModule
{
}
