import {Component, OnDestroy} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {Assessment, AssessmentService} from "../../chalkstring-api/services/assessment.service";
import {NgForm} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {AssessmentProduct} from "../../chalkstring-api/services/assessment-product.service";
import {Subscription} from "rxjs";

export interface MoveProductModel
{
    assessment: Assessment;
    product: AssessmentProduct;
}

class MoveProductForm
{
    apPosition: 'start' | 'after' | 'end' = 'end';
    apAfter: number;
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content modal-content-size-s">
            <form #moveProductForm="ngForm" (ngSubmit)="submitForm(moveProductForm)" novalidate>
                <div class="modal-header">
                    <h2>Move product</h2>
                </div>

                <div class="modal-body">
                    <label for="apPosition">Move to</label>
                    <select name="apPosition" required [(ngModel)]="formModel.apPosition" id="zonePosition">
                        <option value="" disabled selected>Please select…</option>
                        <option value="start">The beginning</option>
                        <option value="after">After product...</option>
                        <option value="end">The end</option>
                    </select>
                    <ng-container *ngIf="formModel.apPosition == 'after'">
                        <ng-select name="columns" [items]="apList"
                                   placeholder="Please select…"
                                   bindLabel="name"
                                   bindValue="id"
                                   [required]="formModel.apPosition == 'after'"
                                   [(ngModel)]="formModel.apAfter"></ng-select>
                    </ng-container>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn inline" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button class="btn inline" [disabled]="moveProductForm.submitted || !moveProductForm.valid">
                        <i class="icon-ok"></i> Move product
                    </button>
                </div>
            </form>
        </div>
    `
})
export class MoveProductModalComponent extends SimpleModalComponent<MoveProductModel, boolean> implements MoveProductModel, OnDestroy
{
    assessment: Assessment;
    product: AssessmentProduct;
    formModel: MoveProductForm;
    apList: AssessmentProduct[];
    listSub: Subscription;
    moveSub: Subscription;

    constructor(private _assessmentService: AssessmentService, private _http: HttpClient)
    {
        super();
        this.formModel = new MoveProductForm();
    }

    ngOnDestroy()
    {
        if (this.listSub) {
            this.listSub.unsubscribe();
        }
        if (this.moveSub) {
            this.moveSub.unsubscribe();
        }
    }

    mapDataObject(data: MoveProductModel)
    {
        super.mapDataObject(data);

        this.listSub = this._assessmentService.getProductList(this.assessment).subscribe(res => {
            this.apList = res;
        });
    }

    submitForm(form: NgForm)
    {
        //@REST\Post("/assessmentproducts/move/{movingObjectId}/after/{referenceObjectId}")
        let afterId: number;
        switch (this.formModel.apPosition) {
            case 'start':
                afterId = 0;
                break;
            case 'after':
                afterId = this.formModel.apAfter;
                break;
            default:
            case 'end':
                afterId = -1;
        }
        this.moveSub = this._http.post('/assessmentproducts/move/' + this.product.id + '/after/' + afterId, {}).subscribe(res => {
            this.result = true;
            this.close();
        });
    }
}
