'use strict';

angular.module('scswebappApp')
    .directive('hubCharts', function (project, $locale, $q, $filter) {

        return {
            template: require('./hub-charts.html'),
            restrict: 'E',
            transclude: true,
            scope: {
                'projectId': '='
            },
            link: function postLink(scope, element, attrs) {
                var drillDownData = [];
                var revenueData = [];
                var costData = [];
                var timeData = [];

                var budgetData = [];
                scope.mostRecentApplicationPieTitle = 'Last Certified Application';

                var currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

                var getChartData = function () {
                    var deferred = $q.defer();
                    project.commercialData({id: scope.projectId}).$promise.then(function (data) {
                        scope.project = data;
                        return data;

                    }).then(function (data) {
                        if (data.hasOwnProperty('mostRecentApplication') && null !== data.mostRecentApplication) {
                            scope.mostRecentApplicationPieTitle = 'Last Certified Application (to ' + $filter('date')(data.mostRecentApplication.applicationPeriodUntilHuman) + ')';
                        } else {
                            scope.mostRecentApplicationPieTitle = 'Last Certified Application (N/A)';
                        }

                        var costsPointFormatter = function () {
                            return '<span style="color:' + this.color + '">Site Progress</span>: <b>' + this.siteProgress + '%</b> complete<br/>' +
                                '<span style="color:' + this.color + '">Costs Progress</span>: <b>' + this.costsProgress + '%</b><br />' +
                                '<span style="color:' + this.color + '">Time Progress</span>: <b>' + this.timeProgress + '%</b><br />' +
                                '<span style="color:' + this.color + '">Projected Costs</span>: <b>' + this.currencySymbol + $filter('number')(this.projectedCosts, 2) + '</b><br />' +
                                '<span style="color:' + this.color + '">Actual Costs To Date</span>: <b>' + this.currencySymbol + $filter('number')(this.actualCosts, 2) + '</b><br />' +
                                '<span style="color:' + this.color + '">Costs Accrued</span>: <b>' + this.currencySymbol + $filter('number')(this.accruedCosts, 2) + '</b><br />';
                        };

                        var revenuePointFormatter = function () {
                            if (this.name === 'Material On Site') {
                                return '<span style="color:' + this.color + '">Site Progress</span>: <b>' + this.siteProgress + '%</b> complete<br/>' +
                                    '<span style="color:' + this.color + '">Time Progress</span>: <b>' + this.timeProgress + '%</b><br />' +
                                    '<span style="color:' + this.color + '">Total Remaining Unordered Material Value</span>: <b>' + this.currencySymbol + $filter('number')(this.projectedRevenue, 2) + '</b><br />' +
                                    '<span style="color:' + this.color + '">Value of Materials currently on site</span>: <b>' + this.currencySymbol + $filter('number')(this.materialValueOnSite, 2) + '</b><br />';
                            } else {
                                var html = '<span style="color:' + this.color + '">Site Progress</span>: <b>' + this.siteProgress + '%</b> complete<br/>' +
                                    '<span style="color:' + this.color + '">Time Progress</span>: <b>' + this.timeProgress + '%</b><br />' +
                                    '<span style="color:' + this.color + '">Projected Revenue</span>: <b>' + this.currencySymbol + $filter('number')(this.projectedRevenue, 2) + '</b><br />';
                                return html
                            }
                        };

                        scope.updatedAt = null;
                        angular.forEach(data.packages, function (report, key) {
                            var reportUpdatedAt = new Date(report.updatedAt);
                            if (null === scope.updatedAt || reportUpdatedAt > scope.updatedAt) {
                                scope.updatedAt = reportUpdatedAt;
                            }

                            var costsDrilldownObject = {
                                name: report.assessmentName,
                                id: report.assessmentId + '_costs',
                                type: 'column',
                                tooltip: {
                                    followPointer: true,
                                    headerFormat: '',
                                    pointFormatter: costsPointFormatter
                                },
                                color: '#37a6de',
                                data: []
                            };

                            // Costs: Supply & Fit
                            costsDrilldownObject.data.push({
                                name: 'Supply & Fit',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                actualCosts: report.costsSupplyAndFitActual ? report.costsSupplyAndFitActual.total : 0,
                                timeProgress: report.timeProgressPercentage,
                                costsProgress: report.costsSupplyAndFitPercentage,
                                projectedCosts: report.costsSupplyAndFitProjected ? report.costsSupplyAndFitProjected.total : 0,
                                accruedCosts: report.costsSupplyAndFitAccrued ? report.costsSupplyAndFitAccrued.total : 0,
                                y: parseFloat(report.costsSupplyAndFitPercentage)
                            });

                            // Costs: Materials
                            costsDrilldownObject.data.push({
                                name: 'Materials',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                actualCosts: report.costsMaterialsActual ? report.costsMaterialsActual.total : 0,
                                timeProgress: report.timeProgressPercentage,
                                costsProgress: report.costsMaterialsPercentage,
                                projectedCosts: report.costsMaterialsProjected ? report.costsMaterialsProjected.total : 0,
                                accruedCosts: report.costsMaterialsAccrued ? report.costsMaterialsAccrued.total : 0,
                                y: parseFloat(report.costsMaterialsPercentage)
                            });

                            // Costs: Labour
                            costsDrilldownObject.data.push({
                                name: 'Labour',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                actualCosts: report.costsLabourActual ? report.costsLabourActual.total : 0,
                                timeProgress: report.timeProgressPercentage,
                                costsProgress: report.costsLabourPercentage,
                                projectedCosts: report.costsLabourProjected ? report.costsLabourProjected.total : 0,
                                accruedCosts: report.costsLabourAccrued ? report.costsLabourAccrued.total : 0,
                                y: parseFloat(report.costsLabourPercentage)
                            });

                            // Costs: FC
                            costsDrilldownObject.data.push({
                                name: 'Fixed Costs',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                actualCosts: report.costsFixedCostsActual ? report.costsFixedCostsActual.total : 0,
                                timeProgress: report.timeProgressPercentage,
                                costsProgress: report.costsFixedCostsPercentage,
                                projectedCosts: report.costsFixedCostsProjected ? report.costsFixedCostsProjected.total : 0,
                                accruedCosts: report.costsFixedCostsAccrued ? report.costsFixedCostsAccrued.total : 0,
                                y: parseFloat(report.costsFixedCostsPercentage)
                            });

                            // Costs: Contingency
                            costsDrilldownObject.data.push({
                                name: 'Contingency',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                actualCosts: report.costsContingencyActual ? report.costsContingencyActual.total : 0,
                                timeProgress: report.timeProgressPercentage,
                                costsProgress: report.costsContingencyPercentage,
                                projectedCosts: report.costsContingencyProjected ? report.costsContingencyProjected.total : 0,
                                accruedCosts: report.costsContingencyAccrued ? report.costsContingencyAccrued.total : 0,
                                y: parseFloat(report.costsContingencyPercentage)
                            });

                            var revenueDrilldownObject = {
                                name: report.assessmentName,
                                id: report.assessmentId + '_revenue',
                                type: 'column',
                                tooltip: {
                                    followPointer: true,
                                    headerFormat: '',
                                    pointFormatter: revenuePointFormatter
                                },
                                color: '#63c94a',
                                data: []
                            };

                            // Revenue: Contract Measured Works
                            revenueDrilldownObject.data.push({
                                name: 'Contract Measured Works',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                // actualRevenue: report.revenueMeasuredWorksLastCertified.contract,
                                timeProgress: report.timeProgressPercentage,
                                projectedRevenue: report.revenueMeasuredWorksProjected ? report.revenueMeasuredWorksProjected.contract : 0,
                                y: parseFloat(report.revenueMeasuredWorksContractPercentage)
                            });

                            // Revenue: Variations Measured Works
                            revenueDrilldownObject.data.push({
                                name: 'Variations Measured Works',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                // actualRevenue: report.revenueMeasuredWorksLastCertified.variations,
                                timeProgress: report.timeProgressPercentage,
                                projectedRevenue: report.revenueMeasuredWorksProjected ? report.revenueMeasuredWorksProjected.variations : 0,
                                y: parseFloat(report.revenueMeasuredWorksVariationsPercentage)
                            });

                            // Revenue: Contract Fixed Costs
                            revenueDrilldownObject.data.push({
                                name: 'Contract Fixed Costs',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                // actualRevenue: report.revenueFixedCostsLastCertified.contract,
                                timeProgress: report.timeProgressPercentage,
                                projectedRevenue: report.revenueFixedCostsProjected ? report.revenueFixedCostsProjected.contract : 0,
                                y: parseFloat(report.revenueFixedCostsContractPercentage)
                            });

                            // Revenue: Variations Fixed Costs
                            revenueDrilldownObject.data.push({
                                name: 'Variations Fixed Costs',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                // actualRevenue: report.revenueFixedCostsLastCertified.variations,
                                timeProgress: report.timeProgressPercentage,
                                projectedRevenue: report.revenueFixedCostsProjected ? report.revenueFixedCostsProjected.variations : 0,
                                y: parseFloat(report.revenueFixedCostsVariationsPercentage)
                            });

                            // Revenue: Materials On Site
                            revenueDrilldownObject.data.push({
                                name: 'Material On Site',
                                currencySymbol: currencySymbol,
                                siteProgress: report.siteProgressTotalPercentage,
                                // actualRevenue: report.revenueMaterialOnSiteLastCertified.total,
                                timeProgress: report.timeProgressPercentage,
                                projectedRevenue: report.revenueMaterialOnSiteUnordered ? report.revenueMaterialOnSiteUnordered.total : 0,
                                materialValueOnSite: report.revenueMaterialOnSiteOnSite ? report.revenueMaterialOnSiteOnSite.total : 0,
                                y: parseFloat(report.revenueMaterialOnSitePercentage)
                            });

                            drillDownData.push(costsDrilldownObject);
                            drillDownData.push(revenueDrilldownObject);

                            costData.push({
                                name: report.assessmentName,
                                y: report.costsPercentage,
                                'currencySymbol': currencySymbol,
                                projectedCost: report.costsProjected ? report.costsProjected.total : 0,
                                actualCost: report.costsActual ? report.costsActual.total : 0,
                                costsDueThisPeriod: report.costsAccrued ? report.costsAccrued.total : 0,
                                drilldown: report.assessmentId + '_costs'
                            });
                            revenueData.push({
                                name: report.assessmentName,
                                y: parseFloat(report.revenueSupplyAndFitPercentage),
                                currencySymbol: currencySymbol,
                                projectedRevenue: report.revenueSupplyAndFitProjected ? report.revenueSupplyAndFitProjected.total : 0,
                                revenueDueThisPeriod: report.revenueSupplyAndFitAccrued ? report.revenueSupplyAndFitAccrued.total : 0,
                                revenueOpenApplications: report.revenueSupplyAndFitAppliedOpen ? report.revenueSupplyAndFitAppliedOpen.total : 0,
                                revenueCertifiedApplications: report.revenueSupplyAndFitLastCertified ? report.revenueSupplyAndFitLastCertified.total : 0,
                                revenueWorkInProgress: report.revenueSupplyAndFitAccrued ? report.revenueSupplyAndFitAccrued.total : 0,
                                drilldown: report.assessmentId + '_revenue'
                            });
                            timeData.push({
                                name: report.assessmentName,
                                y: report.timeProgressPercentage,
                                onSiteStartDate: $filter('date')(report.onSiteStartDate, 'dd/MM/yy'),
                                onSiteEndDate: $filter('date')(report.onSiteEndDate, 'dd/MM/yy')
                            });
                        });

                        budgetData.tenderBudget = [];
                        angular.forEach(data.tenderBudget, function (value, key) {
                            budgetData.tenderBudget.push({
                                name: key,
                                y: value
                            });
                        });
                        budgetData.currentBudget = [];
                        angular.forEach(data.currentBudget, function (value, key) {
                            budgetData.currentBudget.push({
                                name: key,
                                y: value
                            });
                        });
                        budgetData.actualSpend = [];
                        angular.forEach(data.actualSpend, function (value, key) {
                            if ('Actual' !== key && 'Accrued' !== key) {
                                var sliceData = {
                                    name: key,
                                    y: value
                                };
                                if (data.actualSpend.Actual.hasOwnProperty(key)) {
                                    sliceData.actual = data.actualSpend.Actual[key];
                                }
                                if (data.actualSpend.Accrued.hasOwnProperty(key)) {
                                    sliceData.accrued = data.actualSpend.Accrued[key];
                                }
                                budgetData.actualSpend.push(sliceData);
                            }
                        });
                        scope.budgetData = budgetData;
                    }).then(function (data) {
                        renderChart();
                        scope.loading = false;
                        return data;
                    });
                    return deferred.promise;
                };
                getChartData();

                var renderChart = function () {
                    scope.loading = true;
                    Highcharts.setOptions({
                        credits: {enabled: false},
                        lang: {
                            drillUpText: 'Back to project hub',
                            thousandsSep: ','
                        },
                        tooltip: {
                            backgroundColor: '#ffffff'
                        },
                        colors: ['#37a6de', '#f39200', '#ffde00', '#63c94a', '#e6007e', '#bccbd4',
                            '#0f52ba', '#ccc', '#aaa', '#888', '#666']
                    });

                    scope.hubDrilldownChart = Highcharts.chart('project-hub-drilldown', {
                        title: {
                            text: 'Package Progress'
                        },
                        subtitle: {
                            text: 'Click on a column for more detailed information.'
                        },
                        xAxis: {
                            type: 'category',
                            title: {
                                text: 'Packages'
                            }
                        },
                        yAxis: {
                            max: 105,
                            endOnTick: false, //This plus max: 105 stops points at 100% from being hidden outside the top of the chart
                            title: {
                                text: '% complete (site assessed)'
                            }
                        },
                        legend: {
                            enabled: true
                        },
                        plotOptions: {
                            series: {
                                borderWidth: 0,
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.y:,.2f}%',
                                    style: {
                                        fontWeight: 'bold',
                                        color: '#000',
                                        textOutline: 'none'
                                    }
                                }
                            }
                        },
                        series: [
                            {
                                name: 'Revenue',
                                type: 'column',
                                color: '#63c94a',
                                data: revenueData,
                                tooltip: {
                                    headerFormat: '',
                                    followPointer: true,
                                    pointFormat: '<span style="color:{point.color}">Progress</span>: <b>{point.y:,.2f}%</b> complete<br/>' +
                                    '<span style="color:{point.color}">Projected Revenue</span>: <b>{point.currencySymbol}{point.projectedRevenue:,.2f}</b><br />' +
                                    '<span style="color:{point.color}">Certified to Date</span>: <b>{point.currencySymbol}{point.revenueCertifiedApplications:,.2f}</b><br />' +
                                    '<span style="color:{point.color}">Open Applications</span>: <b>{point.currencySymbol}{point.revenueOpenApplications:,.2f}</b><br />' +
                                    '<span style="color:{point.color}">Work In Progress</span>: <b>{point.currencySymbol}{point.revenueWorkInProgress:,.2f}</b>'
                                }
                            },
                            {
                                name: 'Costs',
                                type: 'column',
                                color: '#37a6de',
                                data: costData,
                                tooltip: {
                                    headerFormat: '',
                                    followPointer: true,
                                    pointFormat: '<span style="color:{point.color}">Cost Progress</span>: <b>{point.y:,.2f}%</b> complete</b><br />' +
                                    '<span style="color:{point.color}">Projected Cost</span>: <b>{point.currencySymbol}{point.projectedCost:,.2f}</b><br />' +
                                    '<span style="color:{point.color}">Actual Cost To Date</span>: <b>{point.currencySymbol}{point.actualCost:,.2f}</b><br />' +
                                    '<span style="color:{point.color}">Costs Due This Period</span>: <b>{point.currencySymbol}{point.costsDueThisPeriod:,.2f}</b><br />'
                                }
                            },
                            {
                                name: 'Time',
                                type: 'spline',
                                color: '#e6007e',
                                data: timeData,
                                showInLegend: true,
                                marker: {
                                    lineWidth: 2,
                                    lineColor: '#e6007e',
                                    fillColor: '#ffffff'
                                },
                                tooltip: {
                                    headerFormat: '',
                                    followPointer: true,
                                    pointFormat: '<span style="color:{point.color}">Time Progress</span>: <b>{point.y:,.2f}% complete</b><br />' +
                                    '<span style="color:{point.color}">Start</span>: <b>{point.onSiteStartDate}</b><br />' +
                                    '<span style="color:{point.color}">End</span>: <b>{point.onSiteEndDate}</b><br />'
                                }
                            }
                        ],
                        drilldown: {
                            series: drillDownData,
                            drillUpButton: {
                                position: {
                                    y: -55
                                }
                            }
                        }
                    });
                };
                setTimeout(function () {
                    renderChart();
                }, 0);
            }
        };
    });
