(function(){
    'use strict';

    /**
     * @ngdoc directive
     * @name scswebappApp.directive:dob
     * @description
     * # <entity-details data-entity="products" data-id="{{product.id}}" ng-if="product"></entity-details>
     */
    angular.module('scswebappApp')
        .directive('entityDetails', function (entity) {
            return {
                template:
                '<div class="entity-details">' +
                    '<i class="icon-user" ng-mouseover="getRevisionData()"></i>' +
                    '<div class="entityRevisions">' +
                        '<div class="module-loader" ng-if="!entityHistory"></div>' +
                        '<div ng-show="entityHistory">' +
                            '<div ng-if="entityHistory.oldestRevision">' +
                                'Last updated by: <strong>{{entityHistory.newestRevision.user.person.fullName}}</strong><strong ng-show="!entityHistory.newestRevision.user">CORE</strong><br />' +
                                'Last updated on: <strong>{{entityHistory.newestRevision.timestamp | date:"dd/MM/yyyy"}}</strong><br />' +
                                'Created by: <strong>{{entityHistory.oldestRevision.user.person.fullName}}<span ng-show="!$parent.entityHistory.oldestRevision.user">CORE</span></strong><br />' +
                                'Created on: <strong>{{entityHistory.oldestRevision.timestamp | date:"dd/MM/yyyy"}}</strong>' +
                            '</div>' +
                            '<div ng-if="!entityHistory.oldestRevision">No revisions to show</div>' +
                        '</div>' +
                    '</div>' +
                '</div>',
                restrict: 'E',
                link: function postLink(scope, element, attrs) {
                    scope.getRevisionData = function(){
                        if( !scope.startedGettingEntityHistory ) {
                            scope.startedGettingEntityHistory = true;
                            entity.get({entity: attrs.entity, id: attrs.id}).$promise.then(function (data) {
                                scope.entityHistory = data;
                            }).catch(function(){
                                scope.entityHistory = {};
                            });
                        }
                    };
                }
            };
        });
})();