(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.zone
	 * @description
	 * # zone
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
		.factory('zone', function ( $resource, ENV ) {
			return $resource(
				ENV.apiEndpoint + '/assessmentzones/:id', { id: '@id' }, {
					getAssessmentZones: {
						url: ENV.apiEndpoint + '/assessments/:id/zones',
						method: 'GET',
						isArray: true
					},
                    getOrderableZones: {
						url: ENV.apiEndpoint + '/assessments/:id/zones-orderable',
						method: 'GET',
						isArray: true
					},
					update: {
						method: 'PATCH'
					},
					addSubZone:{
						method: 'POST',
						isArray: true,
						url: ENV.apiEndpoint + '/assessmentzones/:id/subzone',
					}
				});
		});
})();
