(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.preliminary
     * @description
     * # preliminary
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('filefolder', ['Resource', 'ENV', function ( $resource, ENV ) {
            return $resource(
                ENV.apiEndpoint + '/filefolders/:id', { id: '@id', project: '@projectId' }, {
                get:{
                    isArray: true
                },
                update: {
                    method: 'PATCH'
                },
                moveFile: {
                    method: 'POST',
                    url:  ENV.apiEndpoint + '/filefolders/move'
                }
            }
            );
        }]);
})();
