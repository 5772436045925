(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:UserCtrl
     * @description
     * # UserCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('importQuotationCtrl', function ($scope, $rootScope, $route, $location, FileUploader, quote) {

            var serviceMethod = null;
            var redirectPath = '';
            switch ($route.current.$$route.quoteType) {
                case 'material':
                    serviceMethod = 'save';
                    redirectPath = 'material-quote/';
                    $scope.keyword = 'Material';
                    break;
                case 'labour':
                    serviceMethod = 'importLabour';
                    redirectPath = 'labour-quote/';
                    $scope.keyword = 'Labour';
                    break;
                case 'sf':
                    serviceMethod = 'importSupplyAndFit';
                    redirectPath = 'sf-quote/';
                    $scope.keyword = 'Supply & Fit';
                    break;
            }

            // Uploader
            $scope.fileData = new FileUploader();
            $scope.fileList = [];

            $scope.file = {
                uploader: $scope.fileData,
                list: $scope.fileList,
                importFile: null
            };

            // Create Quote
            $scope.save = function () {
                $scope.error = $scope.file.importFile == null;
                $scope.submitted = true;

                if (!$scope.error) {
                    quote[serviceMethod]({file: $scope.file.importFile.id}).$promise.then(function (data) {
                        $scope.submitted = false;
                        $scope.form.$setPristine();
                        $location.path(redirectPath + data.id);
                    })
                }
            }

        });
})();
