'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:deleteButton
 * @description
 * # deleteButton
 * ( index, id to delete, objects array name (for splice), service )
 * <deletebutton data-id="{{product.id}}" data-redirect="product" data-itemservice="product"></deletebutton>
 */
angular.module('scswebappApp')
    .directive('deletebutton', function ( $injector, $rootScope, $location ) {
        return {
            template: require('./delete.html'),
            restrict: 'E',
            scope: {
                query: '=?'
            },
            link: function postLink(scope, element, attrs) {

	            scope.buttonCopy = '';

                // Delete step 1
                scope.deleteItem = function(){

	                scope.id = attrs.id;
	                scope.redirect = attrs.redirect;
	                scope.itemservice = attrs.itemservice;
	                // Inject the service
	                var service = $injector.get(scope.itemservice);

	                if( scope.buttonCopy === '' ){
		                scope.buttonCopy = 'Confirm ';
	                }else{
                        if (scope.id){
                            scope.query = {id: scope.id};
                        }
		                // Delete the item
		                service.delete(scope.query).$promise.then(function(){
			                scope.buttonCopy = '';
			                scope.deleteConfirmCopy = [];
			                $location.path('/' + scope.redirect);
		                }).catch(function(data){
                            if( data.status === 409 ){
                                var usedIn;
                                angular.forEach( data.data, function(value, key){
                                    if( scope.usedIn ){
                                        usedIn += ' and ' + scope.usedIn + ' ' + Object.keys(value).length + ' ' + key;
                                    }else {
                                        usedIn = Object.keys(value).length + ' ' + key;
                                    }
                                });
                                scope.$emit('showOrNotification', 'This ' + scope.itemservice + ' cannot be deleted as it is used in ' + usedIn, true);
                            }
                            scope.cancelDelete();
                        });
	                }
                };

                // Cancel deletion
                scope.cancelDelete = function(){
	                scope.buttonCopy = '';
                };

            }
        };
    });
