(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.materialDeliveries
     * @description
     * # materialDeliveries
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('orderInvoices', ['ENV', 'Resource', '$filter', function (ENV, $resource, $filter) {
            return $resource(
                ENV.apiEndpoint + '/invoices/:id', {id: '@id', onlygroups: '@onlygroups', groups: '@groups', projectId: '@projectId', orderId: '@orderId', transition: '@transition'}, {
                    transition: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/invoices/:id/transitions/:transition'
                    }
                }
            );
        }]);
})();
