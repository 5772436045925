import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GenericModule } from "./generic.module";
import { NgxPopperModule } from "ngx-popper";
import { EditableTableComponent } from "./editable-table/editable-table.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GenericModule,
        NgxPopperModule.forRoot({applyClass: 'new-tooltip'})
    ],
    exports: [
    ],
    declarations: [
        EditableTableComponent,
    ],
    entryComponents: [
        EditableTableComponent
    ]
})
export class EditableTableModule {

}
