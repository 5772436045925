import {Component, Input, OnDestroy} from '@angular/core';
import {Assessment, AssessmentService, AssessmentStateSection} from "../../chalkstring-api/services/assessment.service";
import {Subscription} from "rxjs";
import { first } from 'rxjs/operators';
import {ToasterService} from "@generic/toaster/toaster.service";

@Component({
    template: `
        <button *ngIf="assessment != null" href="" (click)="advanceStatus()" class="btn"
                [ngClass]="{'btn-green': getIsCompleteStatus()}">
            <i class="icon-ok"></i> {{getButtonText()}}</button>
    `,
    selector: 'assessment-status-button'
})
export class AssessmentStatusButtonComponent implements OnDestroy
{
    progressSub: Subscription;

    @Input() assessment: Assessment = null;
    @Input() section: AssessmentStateSection;
    @Input() isHub: boolean = false;

    constructor(private _assessmentService: AssessmentService, private _toast: ToasterService)
    {
    }

    ngOnDestroy()
    {
        if (this.progressSub) {
            this.progressSub.unsubscribe();
        }
    }

    getButtonText()
    {
        if (this.assessment) {
            if (this.getIsCompleteStatus()) {
                return this.isHub ? 'Ready For Site' : 'Complete';
            } else {
                return this.isHub ? 'Mark Ready For Site' : 'Mark as complete';
            }
        } else {
            return '';
        }
    }

    advanceStatus()
    {
        switch (this.section) {
            case 'rateBuildUp':
                this.progressSub = this._assessmentService.progressSection(this.assessment, this.section, 'complete').subscribe((data: any) => {
                    this.assessment.quantificationCompleted = data.quantificationCompleted;
                });
                break;
            case 'quantification':
                this._assessmentService.getBoqValid(this.assessment).pipe(first()).subscribe(valid => {
                    if (valid) {
                        this.progressSub = this._assessmentService.progressSection(this.assessment, this.section, 'complete').subscribe((data: any) => {
                            this.assessment.quantificationCompleted = data.quantificationCompleted;
                        });
                    } else {
                        this._toast.error('Please check the bill for any errors before marking as complete');
                    }
                });
                break;
            default:
                throw new Error('Unexpected section type');
        }

    }

    getIsCompleteStatus()
    {
        let statusProperty = this.section + 'Completed';
        return this.assessment[statusProperty];
    }
}
