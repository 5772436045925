'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:newLabourrate
 * @description
 * # newLabourrate
 */
angular.module('scswebappApp')
	.directive('newLabourrate', function ( $route, $routeParams, $rootScope, $location, routeType, labourrate, packages, UNITSTEXT, labouractivity, region, $locale) {
		return {
		    template: require('./new-labourrate-directive.html'),
			restrict: 'E',
			link: function postLink(scope, element, attrs) {

                scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

                region.query({hydration: 'array'}).$promise.then(function (data) {
                    scope.regions = data;
                });

                labouractivity.query({hydration: 'array'}).$promise.then(function (data) {
                    scope.labouractivities = data;
                });

                if ( $routeParams.id && $route.current.$$route.isEdit ) {
                    labourrate.get({id: $routeParams.id}).$promise.then(function (data) {
                        if( data.region ){
                            data.region = data.region.id;
                        }
                        scope.data = data;
                    }).then(function(data){
                        angular.forEach( scope.labouractivities, function(value){
                            if(scope.data.product.id === value.id){
                                value.ticked = true;
                            }
                        })
                    });
                }

                // Set type keyword for edit and new routes
                scope.keyWord = routeType.type( $route.current.$$route.isNew, $route.current.$$route.isEdit );

                // Form submission
                scope.saveForm = function(){

                    scope.labourRateFormSubmitted = true;

                    if( scope.form.$valid ){

                        scope.data.subGroups = scope.subGroupArray;

                        var saveForm = new labourrate(scope.data);

                        saveForm.$save().then( function(labourRate){
                            scope.form.$setPristine();
                            scope.labourRateFormSubmitted = false;
                            scope.data = {};
                            if( attrs.inline ){
                                $rootScope.$emit('newLabourRateAdded',labourRate.id);
                            }else{
                                $location.path('/labour-rates');
                            }
                        })
                    }
                }
			}
		};
	});
