angular.module('scswebappApp').config(function ($provide, $httpProvider) {
    // Interceptor for errors returned from Web Service
    $httpProvider.interceptors.push('errorHandler');
}).config(function ($provide) {
    // Sentry / Raven
    $provide.decorator('$exceptionHandler', function ($delegate) {
        return (
            function exceptionHandlerProxy(error, cause) {
                if (window.Raven){
                    window.Raven.captureException(error);
                }

                $delegate(error, cause);
            }
        );
    });
}).run(function (ENV) {
    // Sentry / Raven
    if (ENV.ravenEndpoint !== null && window.Raven) {
        window.Raven.config(ENV.ravenEndpoint, {
            // pass along the version of your application
            // release: '1.0.0',

            // we highly recommend restricting exceptions to a domain in order to filter out clutter
            whitelistUrls: [
                'my.chalkstring.com',
                'staging.chalkstring.com',
                'beta.chalkstring.com',
                'training.chalkstring.com',
                '0.0.0.0:9000',
                '0.0.0.0:8080',
                '127.0.0.1:8080'
            ]
        });
        window.Raven.install();
    }
}).factory('errorHandler', function ($q, $rootScope) {
    return {
        responseError: function (rejection) {
            // The resource operation may have used transformResponse to add ignoreError in to stop
            // a toast appearing. For example on showing revisions for an entity and a 404 being
            // returned because there aren't any - which isn't an error from the users perspective
            if (!(rejection.data && rejection.data.ignoreError) && rejection.status !== -1) {
                var error = '';

                switch (true) {
                    case rejection.status === 409:
                        // Conflict error
                        console.log('E409');
                        var usedIn;
                        angular.forEach(rejection.data, function (value, key) {
                            if (usedIn) {
                                usedIn += ' and ' + Object.keys(value).length + ' ' + key;
                            } else if (key !== 'errors') {
                                usedIn = Object.keys(value).length + ' ' + key;
                            }
                        });
                        if (usedIn) {
                            error = 'The action cannot be continued as the entity is already in use in ' + usedIn;
                        } else {
                            error = 'The action cannot be continued due to a conflict. If the issue persists, please contact support';
                        }
                        break;

                    case rejection.status === 400
                        && rejection.hasOwnProperty('data')
                        && rejection.data.hasOwnProperty('errors')
                        && rejection.data.errors[0].hasOwnProperty('formErrors')
                        && angular.isObject(rejection.data.errors[0].formErrors):
                        // Form errors
                        console.log('EFORM');
                        var extractChildErrors = function (child) {
                            var errStrings = [];
                            if (child.errors) {
                                angular.forEach(child.errors, function (errorText) {
                                    errStrings.push(errorText);
                                });
                            }
                            if (child.hasOwnProperty('children')) {
                                angular.forEach(child.children, function (grandChild) {
                                    var grandChildErrors = extractChildErrors(grandChild);
                                    if (grandChildErrors) {
                                        errStrings.push(grandChildErrors);
                                    }
                                });
                            }

                            return errStrings.join(', ');
                        }

                        angular.forEach(rejection.data.errors, function (err) {
                            if (err.formErrors.form.errors.hasOwnProperty('children')) {
                                //errors[0].formErrors.form.errors.children.foo.children.bar.errors
                                angular.forEach(err.formErrors.form.errors.children, function (child) {
                                    error += extractChildErrors(child);
                                });
                            }
                            if (err.formErrors.form.errors.hasOwnProperty('errors')) {
                                //errors[0].formErrors.form.errors.errors
                                angular.forEach(err.formErrors.form.errors.errors, function (errorText) {
                                    if (error === '') {
                                        error += errorText;
                                    } else {
                                        error += ', ' + errorText;
                                    }
                                });
                            }
                            if (err.formErrors.form.hasOwnProperty('children')) {
                                angular.forEach(err.formErrors.form.children, function (child) {
                                    error += extractChildErrors(child);
                                });
                            }
                        });
                        break;

                    case rejection.status === 400 && rejection.hasOwnProperty('data') && rejection.data.hasOwnProperty('errors') && rejection.data.errors.length > 0 && rejection.data.errors[0].hasOwnProperty('formErrors'):
                        console.log('EFORM2');
                        error = 'The form failed to validate:';
                        angular.forEach(rejection.data.errors, function (e) {
                            if (e.hasOwnProperty('formErrors') && angular.isObject(e.formErrors)) {
                                angular.forEach(e.formErrors.form.children, function (child) {
                                    if (child.hasOwnProperty('errors')) {
                                        angular.forEach(child.errors, function (childError) {
                                            error += ' ' + childError;
                                        });
                                    }
                                });
                                angular.forEach(e.formErrors, function (formError) {
                                    if (formError.hasOwnProperty('errors')) {
                                        angular.forEach(formError.errors, function (formErrorMsg) {
                                            error += ' ' + formErrorMsg;
                                        });
                                    }
                                });
                            } else if (e.hasOwnProperty('detail')) {
                                error += ' ' + e.detail;
                            }
                        });
                        break;

                    case rejection.data && rejection.data.error && rejection.data.error.exception && rejection.data.error.exception.length > 0:
                        // Exceptions
                        console.log('EEXCEPTION');
                        angular.forEach(rejection.data.error.exception, function (value) {
                            if (error === '') {
                                error += value.message;
                            } else {
                                error += ', ' + value.message;
                            }
                        });
                        break;

                    case rejection.status === 400:
                        // Bad Request
                        console.log('E400');
                        if (rejection.data.errors && rejection.data.errors.length > 0 && rejection.data.errors[0].title) {
                            error = rejection.data.errors[0].title;
                        } else {
                            error = 'There was a problem with your request. If the issue persists, please contact support.';
                        }
                        break;

                    case rejection.status === 401:
                        // Unauthorized
                        console.log('E401');
                        // error = 'You have been logged out';
                        break;

                    default:
                        // Catch-all error
                        console.log('ESHRUG');
                        error = 'There has been a problem. If the issue persists, please contact support.';
                }

                if (error) {
                    $rootScope.$broadcast('showOrNotification', error, true);
                }
                error = '';
            }

            return $q.reject(rejection);
        }
    };
});
