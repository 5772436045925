(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectRolesCtrl
     * @description
     * # ProjectRolesCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProjectRolesCtrl', function ($scope, $rootScope, $routeParams, $route, project) {

            $rootScope.projectId = $routeParams.projectId;
            $scope.isHub = $route.current.$$route.isHub;

            project.get({id: $routeParams.projectId}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                $scope.project = data;
            });
        });
})();
