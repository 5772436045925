import {Component} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {NgForm} from "@angular/forms";
import { Project } from 'app/modules/chalkstring-api/services/project.service';
import {
    ProductApplication,
    ProductApplicationService,
    OutgoingApplicationGenerateResponse
} from "../../chalkstring-api/services/product-application.service";
import { first } from 'rxjs/operators';
import {HttpParams} from "@angular/common/http";
import {ToasterService} from "@generic/toaster/toaster.service";

export interface GenerateOutgoingApplicationModel
{
    project: Project;
}

class OutgoingApplicationOptions
{
    filterOn: 'c' | 'p' = 'c';
    filterPercentage: number = 0;
}

@Component({
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content">
            <form #generateApplicationForm="ngForm" (ngSubmit)="submitForm(generateApplicationForm)">
                <div class="modal-header">
                    <h2>Generate outgoing application for <strong>{{project.name}}</strong></h2>
                </div>
                <div class="modal-body">
                    <p *ngIf="working"><i class="icon-spin3 inline-spinner"></i> Checking...</p>
                    <p *ngIf="heldApplications.length" class="badge block block-warning">
                        There {{heldApplications.length === 1 ? 'is' : 'are'}} already {{heldApplications.length}}
                        held application{{heldApplications.length === 1 ? '' : 's'}} for this project.
                        Held applications must be marked as sent or deleted before another can be generated.
                    </p>
                    <ul>
                        <ul *ngFor="let pa of heldApplications"><a [href]="'/project-hub/payment-application/' + pa.id" (click)="close()">Application {{pa.applicationNumber}}</a></ul>
                    </ul>
                    <p *ngIf="!working && !heldApplications.length">
                        <label for="generateApplicationModalFilterOn">Include all:</label>
                        <select id="generateApplicationModalFilterOn" name="filterOn" [(ngModel)]="applicationOptions.filterOn">
                            <option value="" disabled selected>Please select…</option>
                            <option [ngValue]="'c'" [selected]="true">Components</option>
                            <option [ngValue]="'p'">Products</option>
                        </select>
                        <label for="generateApplicationModalFilterPercentage">With zone progress:</label>
                        <select id="generateApplicationModalFilterPercentage" name="filterPercentage" [(ngModel)]="applicationOptions.filterPercentage" ng-init="applicationFilter.filterPercentage = '0'">
                            <option value="" disabled selected>Please select…</option>
                            <option [selected]="true" value="0">&ge; 0%</option>
                            <option *ngFor="let i of [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95]" [ngValue]="i">&ge; {{i}}%</option>
                            <option [ngValue]="100">= 100%</option>
                        </select>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button type="submit" class="btn" [disabled]="working || heldApplications.length"><i class="icon-ok"></i> Generate application</button>
                </div>
            </form>
        </div>
    `
})
export class GenerateOutgoingApplicationModalComponent extends SimpleModalComponent<GenerateOutgoingApplicationModel, OutgoingApplicationGenerateResponse> implements GenerateOutgoingApplicationModel
{
    working: boolean = false;
    heldApplications: ProductApplication[] = [];
    project: Project;
    applicationOptions: OutgoingApplicationOptions;

    constructor(private _paService: ProductApplicationService, private _toast: ToasterService)
    {
        super();
        this.applicationOptions = new OutgoingApplicationOptions();
    }

    checkCanGenerate()
    {
        /**
         * This project cannot have any outgoing applications in the Held state
         */
        const params = {project: this.project.id.toString(), direction: 'outgoing', 'state[]': 'Held'};
        this.working = true;
        this._paService.getMany(new HttpParams({fromObject: params}))
            .pipe(first())
            .subscribe(res => {
                this.heldApplications = res;
                this.working = false;
            })
    }

    mapDataObject(data: GenerateOutgoingApplicationModel)
    {
        super.mapDataObject(data);
        this.checkCanGenerate();
    }

    submitForm(form: NgForm)
    {
        this._paService.postOutgoingGenerate(this.project)
            .pipe(first())
            .subscribe(res => {
                if (res) {
                    this.result = res;
                    // this._toast.info(res.message);
                    this.close();
                }
            })
    }
}
