'use strict';

/**
 * Usage: <back-button data-custom-text="Back to Assessments"></back-button>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('pgzpSliders', function ($rootScope, progressGroup) {
        return {
            template: require('./pgzp-sliders.html'),
            restrict: 'E',
            scope: {
                showGroupName: '=',
                projectId: '=',
                zoneProgresses: '=',
                sliderOptions: '='
            },
            link: function postLink(scope) {
                scope.$on('slideEnded', function (data) {
                    progressGroup.updateProgress({id: data.targetScope.$parent.z.id}, {progress: data.targetScope.$parent.z.progress});
                });

                scope.openIssueModal = function (pgzpId) {
                    scope.activeProgressGroupZoneProgressId = pgzpId;
                    scope.issueModal.open();

                    scope.issueModal.onClose = function () {
                        scope.issue = null;
                        scope.activeIssueId = null;
                        scope.activeProgressGroupZoneProgressId = null;
                    };
                };
                $rootScope.$on('issueSaved', function (data) {
                    scope.issueModal.close();
                });
            }
        };
    });
