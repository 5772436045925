(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name scswebappApp.directive:tableView
     * @description
     * # tableView
     *
     * Template:
     * <table-view data-config="{{tableConfig}}"></table-view>
     *
     * Controller:
     $scope.tableConfig = {
                config:{
                    tableClass: 'myclass',
                    service: 'material',
                    serviceMethod: 'query',
                    serviceMethodId: $routeParams.id,
                    customQueryKey: 'id',
                    customQueryValue: $routeParams.id,
                    customQuery: {foo:'bar',baz:'xyzzy'}
                    emitEvent: true,
                    multiSelect: true,
                    selectedItems: [id, id, id]
                    singlePageUrl: 'material',
                    singlePageId: 'id',
                    itemsPerPage: 20,
                    logData: false,
                    sortBy:'fullName',
                    disableDisclosure: false,
                    bottomPadding: false   // If true then a table will have padding added at the bottom
                },
                cols:[
                    {
                        text:'Name',
                        data: 'name',
                        hasTextFilter: true, // adds a text filter for this column
                        textFilterOverride: 'somethingInsteadOfName',
                        sortFilterOverride:'somethingInsteadOfData',
                        greenCondition: 'Created', //e.g. stateStatus === 'Created' - Option for green table cell
                        orangeCondition: 'Held', //e.g. stateStatus === 'Held' - Option for orange table cell
                        redCondition: 'Cancelled', //e.g. stateStatus === 'Cancelled' - Option for red table cell
                        customClickHandler: true // e.g. if button inside cell should handle click
                    },
                    {
                        text:'Brand',
                        data: 'brand',
                        data2Prefix: ' of ',
                        data2: 'brandName',
                        dataFilter2: {
                            type:'units',
                            format: 'UNITSTEXT'
                        },
                        data3Prefix: ' per ',
                        data3: 'anotherBrandName',
                        hasTextFilter: true
                    },
                    {
                        text:'Specifics',
                        data: 'specifics',
                        hasTextFilter: true,
                        maxLines: 4  // Will use -webkit-line-clamp to truncate the number of displayed lines
                    },
                    {
                        text:'Type',
                        data: 'materialType',
                        hasTextFilter: true,
                        textFilterOverride: 'type' // incase the data we are displaying in the table has a different name in the querystring
                    },
                    {
                        text:'Pack Size',
                        data: 'packSize'
                    },
                    {
                        text:'Price',
                        data: 'price',
                        dataFilter: {
                            type:'currency',
                            format: '£'
                        }
                    },
                    {
                        text:'Packages',
                        object: 'workPackages',
                        objectCols: [
                            {
                                data: 'name'
                            }
                        ],
                        dropdownFilter: {
                            service: 'packages', // the service the dropdown should be populated by - can also be aarray of data
                            method: 'query', // method relating to above service
                            serviceQueryStringKey: 'project',
                            serviceQueryStringValue: $routeParams.id,
                            label: 'name', // which value to use as a label in the dropdown
                            queryString: 'package', // query string to use against data in table
                            filterParam: 'id', // value from selected item to use with query string
                            emptyText: 'Filter by package' // placeholder text in dropdown

                    },
                    {
                        allowHtml: true
                    }
                ]
            };

     */
    angular.module('scswebappApp')
        .directive('tableView', function ($injector, $timeout, $log, $filter, $rootScope, $location, $sce, PermPermissionStore) {
            return {
                template: require('./tableview-directive.html'),
                restrict: 'E',
                scope: {
                    "pinnedItems": "=?",
                    "highlightedItems": "=?"
                },
                link: function (scope, element, attrs) {
                    var validFilterTypes = ['text','date','dropdown'];
                    scope.isFiltered = false;
                    scope.focusedFilter = -1;
                    scope.showExtraFilters = false;
                    scope.toggleExtraFilters =  function(){
                        scope.showExtraFilters =!scope.showExtraFilters;
                    };
                    attrs.$observe('config', function (config) {
                        // Get config object
                        try {
                            scope.config = angular.fromJson(config);
                        } catch (e) {
                            console.error("Invalid table config supplied");
                            console.log(config);
                            return;
                        }

                        if(scope.config.config.tableIdentifier) {
                            scope.tableIdentifier = scope.config.config.tableIdentifier;
                        }

                        // Row class map
                        if (scope.config.config.classMap === undefined) {
                            scope.config.config.classMapFunction = function (row) {
                                return {};
                            };
                        } else {
                            scope.config.config.classMapFunction = function (row) {
                                var classNames = [];
                                angular.forEach(scope.config.config.classMap, function (test, className) {
                                    angular.forEach(test, function (v, k) {
                                        if (row[k] === v) {
                                            classNames.push(className);
                                        }
                                    });
                                });
                                return classNames;
                            };
                        }

                        if (scope.config.config.showCheckboxes) {
                            if (scope.config.config.showCheckboxesTest !== undefined) {
                                // Create test function from whitelist object e.g. {state: 'Approved'}
                                scope.config.config.showCheckboxesTestFunction = function (row) {
                                    var test = true;
                                    angular.forEach(scope.config.config.showCheckboxesTest, function (v, k) {
                                        if (row[k] !== v) {
                                            test = false;
                                        }
                                    });
                                    return test;
                                };
                            } else if (scope.config.config.showCheckboxesInverseTest !== undefined) {
                                // Create test function from blacklist object e.g. {state: 'Paid'}
                                scope.config.config.showCheckboxesTestFunction = function (row) {
                                    var test = true;
                                    angular.forEach(scope.config.config.showCheckboxesInverseTest, function (v, k) {
                                        if (row[k] === v) {
                                            test = false;
                                        }
                                    });
                                    return test;
                                };
                            } else {
                                // Default to show checkboxes on all rows
                                scope.config.config.showCheckboxesTestFunction = function (row) {
                                    return true;
                                };
                            }
                        }

                        scope.changes = {
                            add: [],
                            remove: []
                        };

                        function getStorageKey() {
                            let key = 'filters_' + scope.config.config.service + '_' + scope.config.config.serviceMethod;
                            if (scope.config.config.hasOwnProperty('customQueryKey') && scope.config.config.hasOwnProperty('customQueryValue')) {
                                key += '_' + scope.config.config.customQueryKey + '_' + scope.config.config.customQueryValue;
                            }
                            return key;
                        }

                        // Saving filter local storage so they're persistent
                        function saveToLocalStorage(queryString, searchText) {
                            var savedFilters = angular.fromJson(localStorage.getItem(getStorageKey()));
                            savedFilters = (savedFilters) ? savedFilters : {};
                            savedFilters[queryString] = searchText;
                            localStorage.setItem(getStorageKey(), angular.toJson(savedFilters));
                        }

                        function removeFromLocalStorage(queryString) {
                            var savedFilters = angular.fromJson(localStorage.getItem(getStorageKey()));
                            if (savedFilters != null){
                                delete savedFilters[queryString];
                            }
                            localStorage.setItem(getStorageKey(), angular.toJson(savedFilters));
                        }

                        scope.resultsLength = [50, 100];
                        scope.itemsPerPage = scope.config.config.itemsPerPage ? scope.config.config.itemsPerPage:50 ;

                        scope.currentPage = 1;

                        if (scope.config.config.sortBy) {
                            scope.sort = scope.config.config.sortBy;
                        } else {
                            // If no sort explicitly specified then sort by the first available column which allows for sorting
                            const firstSortableColumn = scope.config.cols.find(col => (col.sort === undefined || col.sort === true) && col.data);
                            scope.sort = firstSortableColumn ? firstSortableColumn.data : null;
                        }

                        // Inject the service and get the data
                        var service = $injector.get(scope.config.config.service);

                        // Evaluate string to return data
                        scope.getValue = function (exp, row, dataFilter) {
                            if (dataFilter) {
                                var data = scope.$eval(exp, row);

                                // Set value to lump sum if null
                                if (dataFilter.type === 'units' && data === null) {
                                    data = 'l';
                                }

                                return $filter(dataFilter.type)(data, dataFilter.format);
                            } else {
                                return scope.$eval(exp, row);
                            }
                        };

                        if (!scope.deregisterReloadListener && scope.config.config.service) {
                            scope.deregisterReloadListener = scope.$on('tableViewReload' + scope.config.config.service, function () {
                                getData();
                            });
                        }

                        if (!scope.deregisterReloadByIdentifierListener && scope.config.config.tableIdentifier) {
                            scope.deregisterReloadByIdentifierListener = scope.$on('tableViewReload' + scope.config.config.tableIdentifier, function () {
                                getData();
                            });
                        }

                        scope.toggledCellDepths = {};
                        scope.toggleCellDepthLimit = function(e, item) {
                            const key = item.$$hashKey;
                            if (e.target.scrollHeight > e.target.offsetHeight) {
                                scope.toggledCellDepths[key] = true;
                                e.stopPropagation();
                            } else if (scope.toggledCellDepths[key] == true) {
                                scope.toggledCellDepths[key] = false;
                                e.stopPropagation();
                            }
                            // else the event will be propagated and any click events on the row
                            // should be triggered
                        };
                        scope.isCellDepthLimitToggled = function(item) {
                            return scope.toggledCellDepths[item.$$hashKey];
                        };

                        scope.toggleMore = function (e, id) {
                            scope.tableviewMore = (scope.tableviewMore) ? scope.tableviewMore : [];
                            scope.tableviewMore[id] = !scope.tableviewMore[id];
                            e.stopPropagation();
                        };

                        function getData(pageChange) {
                            scope.isFiltered = false;

                            scope.data = null;
                            var options = {};

                            if (scope.config.config.customQueryKey && scope.config.config.hasOwnProperty('customQueryValue')) {
                                options[scope.config.config.customQueryKey] = scope.config.config.customQueryValue;
                            } else if (scope.config.config.customQuery) {
                                angular.forEach(scope.config.config.customQuery, function (v, k) {
                                    options[k] = v;
                                });
                            }

                            if (!scope.config.config.ignoreSavedFilters && localStorage.getItem(getStorageKey())) {
                                var savedFilters = angular.fromJson(localStorage.getItem(getStorageKey()));

                              angular.forEach(savedFilters, function(v, k) {
                                    options[k] = v;
                                    if (k !== 'page') {
                                        scope.isFiltered = true;
                                    }
                                });
                            }

                            // Get current page from local storage and set scope.currentPage it exists.
                            scope.currentPage = (options.page) ? options.page : 1;

                            options['page'] = scope.currentPage;
                            options['limit'] = scope.itemsPerPage;

                            // Column sorting
                            if (scope.sort) {
                                options['sort'] = scope.sort;
                            }

                            var serviceId = (scope.config.config.serviceMethodId) ? scope.config.config.serviceMethodId : null;

                            if (serviceId) {
                                options['id'] = serviceId;
                            }

                            service[scope.config.config.serviceMethod](options, function (data, headers) {
                                scope.data = data;

                                if (scope.config.config.logData) {
                                    $log.log(data);
                                }

                                // If we're just changing the page then we don't need to modify the pagination
                                if (!pageChange) {
                                    scope.createPagination(headers());
                                }
                            }).$promise.then(function (data) {
                                if (scope.config.config.multiSelect == true && typeof scope.config.config.selectedItems === 'object') {
                                    preSelectItems(scope.config.config.selectedItems);
                                }
                            });

                        }

                        scope.createPagination = function (header) {
                            scope.objectCount = header['x-total-count'];

                            // Create an array of pages
                            scope.numPages = scope.objectCount / scope.itemsPerPage;
                            scope.pagesArray = [];
                            for (var i = 0; i < scope.numPages; i++) {
                                scope.pagesArray.push(i + 1);
                            }
                        };

                        // Pagination
                        scope.changePage = function (page) {
                            if (page !== 0 && page !== scope.pagesArray.length + 1) {
                                scope.currentPage = page;
                                saveToLocalStorage('page', page);
                                getData(true);
                            }
                        };

                        // dropdown Filter
                        scope.filterTable = function () {
                            $timeout(function () {
                                scope.currentPage = 1;
                                getData();
                            }, 600);
                        };

                        scope.filterCol = false;

                        scope.disableDisclosure = scope.config.config.disableDisclosure;

                        /**
                         * Gets the filter type from some filter config check validity and also provides backward
                         * compat for legacy filter type config (e.g. hasTextFilter)
                         * @returns string
                         * @param config {*}
                         */
                        var getFilterType = function(config){
                            var type = null;
                            if (config.filterType) {
                                type = config.filterType.toLowerCase();
                            } else if (config.hasTextFilter) {
                                type = 'text';
                            } else if (config.hasDateFilter) {
                                type = 'date';
                            } else if (config.dropdownFilter) {
                                type = 'dropdown';
                            }

                            if (type != null && validFilterTypes.indexOf(type)===-1) {
                                console.error('Invalid table view filter type set, expected one of ' + validFilterTypes.join(', ') + ' but got \''+ type  +'\'')
                            }
                            return type;
                        };

                        var createDateFilter = function(config, savedFilters){
                            return {
                                visible: true,
                                isDate: true,
                                queryString: config.data
                            };
                        };

                        var createTextFilter = function(config, savedFilters){
                            var queryString = config.data;
                            if (config.textFilterOverride) {
                                queryString = config.textFilterOverride;
                            }

                            var filter = {
                                visible: true,
                                isText: true,
                                queryString: queryString,
                                placeholder: config.hasOwnProperty('placeholder') ? config.placeholder : ('Filter by ' + config.text),
                                textFilterKey: config.textFilterKey
                            };

                            if (savedFilters && typeof config.textFilterOverride !== 'undefined') {
                                filter.searchText = savedFilters[config.textFilterOverride];
                            } else if (savedFilters) {
                                filter.searchText = savedFilters[config.data];
                            }
                            return filter;
                        };

                        var createDropdownFilter = function(config, savedFilters) {
                            let emptyText = "<i class='icon-filter filter-icon'></i>";

                            var filter = {
                                visible: true,
                                isDropdown: true,
                                config: config.dropdownFilter,
                                translations: {
                                    nothingSelected: emptyText,
                                    selectNone: 'Select none',
                                    selectAll: 'Select all',
                                    reset: 'Reset',
                                    search: 'Search'
                                },
                                data: {}
                            };

                            if (typeof(config.dropdownFilter.service) === 'object') {
                              filter.data = config.dropdownFilter.service;
                                if (savedFilters ) {
                                    if (config.dropdownFilter.selectionMode === 'multiple'){
                                        var saved = savedFilters[config.dropdownFilter.queryString];
                                    } else {
                                        var saved = [savedFilters[config.dropdownFilter.queryString]];
                                    }
                                    angular.forEach(saved, function (savedValue) {
                                        angular.forEach(filter.data, function (dataValue) {
                                            if (dataValue[config.dropdownFilter.filterParam] == savedValue) {
                                                dataValue.ticked = true;
                                            }
                                        });
                                    });
                                }
                            } else {
                                var dropdownDataService = new $injector.get(config.dropdownFilter.service);
                                var dropdownQueryString = {
                                    limit: 3000,
                                    hydration: 'array'
                                };
                                if (config.dropdownFilter.hydration) {
                                    dropdownQueryString['hydration'] = config.dropdownFilter.hydration
                                }
                                if (config.dropdownFilter.serviceQueryStringKey) {
                                    dropdownQueryString[config.dropdownFilter.serviceQueryStringKey] = config.dropdownFilter.serviceQueryStringValue;
                                }
                                dropdownDataService[config.dropdownFilter.method](dropdownQueryString).$promise.then(function (data) {

                                    var queryString = config.dropdownFilter.queryString;
                                    // Mark dropdown item as ticked if filter data in localStorage
                                    angular.forEach(data, function (dataValue) {
                                        if (savedFilters && dataValue.id == savedFilters[queryString]) {
                                            dataValue.ticked = true;
                                        }
                                    });
                                    filter.data = data;

                                    // If custom query key and value, mark correct dropdown value as ticked.
                                    if (config.dropdownFilter.service == scope.config.config.customQueryKey) {
                                        angular.forEach(filter.data, function (dataValue) {
                                            if (dataValue.id == scope.config.config.customQueryValue) {
                                                dataValue.ticked = true;
                                            }
                                        });
                                    }
                                });
                            }
                            return filter;
                        };

                        //Get saved filter data
                        var savedFilters = angular.fromJson(localStorage.getItem(getStorageKey()));

                        var createFilter = function(config){

                            var filter = {
                                visible: false
                            };

                            switch (getFilterType(config)) {
                                case 'text':
                                    filter = createTextFilter(config, savedFilters);
                                    break;
                                case 'date':
                                    filter = createDateFilter(config, savedFilters);
                                    break;
                                case 'dropdown':
                                    filter = createDropdownFilter(config, savedFilters);
                                    break;
                            }
                            return filter;
                        };



                        // Check if any columns require a  filter
                        scope.filterCols = [];
                        angular.forEach(scope.config.cols, function (value, key) {
                            var hiddenByPermissions = value.permissionOnly && !PermPermissionStore.getPermissionDefinition(value.permissionOnly);
                            if (hiddenByPermissions) {
                                scope.config.cols.splice(key,1);
                                return;
                            }
                            if (!scope.filterCols) {
                                scope.filterCols = [];
                            }

                            var filter = createFilter(value);

                            if (filter) {
                                if (filter.visible) {
                                    scope.filterCol = true;
                                }
                                scope.filterCols[key] = filter
                            }
                        });

                        var textTimeout;

                        function setTextTimeout() {
                            textTimeout = $timeout(function () {
                                getData();
                            }, 500);
                        }

                        scope.textChange = function (value) {
                            if (value.searchText === '') {
                                removeFromLocalStorage(value.queryString);
                            } else {
                                // If query string is nested value e.g project.name, just use first part (project)
                                if (value.queryString && value.queryString.indexOf('.') > -1) {
                                    value.queryString = value.queryString.split('.')[0];
                                } else if (typeof value.textFilterKey !== 'undefined') {
                                    value.queryString = value.textFilterKey;
                                }
                                scope.currentPage = 1;
                                saveToLocalStorage(value.queryString, value.searchText);
                                // Revert to page 1 when searching
                                saveToLocalStorage('page', 1);
                            }
                            scope.$emit('filterChanged');
                            $timeout.cancel(textTimeout);
                            setTextTimeout();
                        };

                        scope.dateFromChange = function (value) {
                            if (value.dateFrom === null||value.dateFrom === '') {
                                removeFromLocalStorage(value.queryString + 'From');
                            } else {
                                var from = $filter('date')(value.dateFrom, 'yyyyMMdd');
                                scope.currentPage = 1;
                                saveToLocalStorage(value.queryString + 'From', from);
                                // Revert to page 1 when searching
                                saveToLocalStorage('page', 1);
                            }
                            scope.$emit('filterChanged');
                            getData();
                        };
                        scope.dateToChange = function (value) {
                            if (value.dateTo === null||value.dateTo === '') {
                                removeFromLocalStorage(value.queryString + 'To');
                            } else {
                                var to = $filter('date')(value.dateTo, 'yyyyMMdd');
                                scope.currentPage = 1;
                                saveToLocalStorage(value.queryString + 'To', to);
                                // Revert to page 1 when searching
                                saveToLocalStorage('page', 1);
                            }
                            scope.$emit('filterChanged');
                            getData();
                        };
                        scope.clearDateFrom = function(value) {
                            value.dateFrom = null;
                            scope.dateFromChange(value);
                        }
                        scope.clearDateTo = function(value) {
                            value.dateTo = null;
                            scope.dateToChange(value);
                        }

                        scope.clearFilter = function (data) {
                            removeFromLocalStorage(data.config.queryString);
                            angular.forEach(scope.filterCols, function (col) {
                                if (col.selected) {
                                    col.selected = [];
                                    angular.forEach(col.data, function (data) {
                                        if (data.ticked) {
                                            data.ticked = false;
                                        }
                                    });
                                }
                            });
                            scope.$emit('filterChanged');
                            getData();
                        };

                        scope.clearFilters = function () {
                            angular.forEach(scope.filterCols, function (col) {
                                if (col.isDate) {
                                    col.dateFrom = null;
                                    col.dateTo = null;
                                } else if (col.searchText) {
                                    col.searchText = '';
                                }
                                if (col.selected) {
                                    col.selected = [];
                                    angular.forEach(col.data, function (data) {
                                        if (data.ticked) {
                                            data.ticked = false;
                                        }
                                    });
                                }
                            });
                            localStorage.removeItem(getStorageKey());
                        };

                        scope.clearFiltersAndFetch = function () {
                            scope.clearFilters();
                            scope.$emit('filterChanged');
                            getData();
                        };

                        scope.doFilter = function (data, column) {
                            scope.$emit('filterChanged_' + column.config.queryString, column);
                            saveToLocalStorage('page', 1);
                            var dataValue = null;
                            if (column.isDropdown && column.config.selectionMode == 'multiple') {
                                dataValue = [];
                                angular.forEach(column.selected, function (item) {
                                    dataValue.push(item[column.config.filterParam]);
                                });
                            } else {
                                dataValue = data[column.config.filterParam];
                            }
                            saveToLocalStorage(column.config.queryString, dataValue);

                            scope.$emit('filterChanged');
                            getData();
                        };

                        scope.changeItemsPerPage = function () {
                            getData();
                        };

                        scope.sortColumns = function (column) {
                            var columnSort;
                            if (column.sortFilterOverride) {
                                columnSort = column.sortFilterOverride;
                            } else if (typeof column.data === 'undefined') {
                                columnSort = column.objectCols[0].data;
                            } else {
                                columnSort = column.data;
                            }
                            if (scope.sort && scope.sort.indexOf('-') === -1) {
                                scope.sort = columnSort;
                                scope.sort = '-' + scope.sort;
                            } else if (scope.sort && scope.sort.indexOf('-') > -1) {
                                var sortBy = scope.sort.split('-');
                                scope.sort = columnSort;
                                scope.sort = sortBy[1];
                            } else {
                                scope.sort = columnSort;
                            }

                            getData();
                        };

                        // Route change and item selection

                        scope.changeRoute = function (id, item, $index) {
                            // Emit event
                            if (scope.config.config.emitEvent && !scope.config.config.multiSelect) {
                                if (!scope.config.config.tableIdentifier) {
                                    scope.$emit('itemSelected' + scope.config.config.service, item);
                                } else {
                                    scope.$emit('itemSelected' + scope.config.config.tableIdentifier, item);
                                }
                            } else if (!scope.config.config.multiSelect) {
                                if (angular.isObject(scope.config.config.singlePageUrl)) {
                                    $location.path(scope.config.config.singlePageUrl[item.discriminator] + '/' + id);
                                } else {
                                    $location.path(scope.config.config.singlePageUrl + '/' + id);
                                }
                            }

                            if (scope.config.config.multiSelect) {
                                addItem(item);
                            }
                        };

                        // Multiselect
                        scope.selectedItems = [];
                        scope.selectedIds = [];

                        var preSelectItems = function (selectedIds) {
                            angular.forEach(scope.data, function (item) {
                                angular.forEach(selectedIds, function (selectedId) {
                                    if (item.id == selectedId) {
                                        if (scope.selectedIds.indexOf(item.id) === -1) {
                                            scope.selectedItems.push(item);
                                            scope.selectedIds.push(item.id);
                                        }
                                    }
                                });
                            });
                        };

                        var addItem = function (item) {
                            item = angular.copy(item);

                            if (scope.selectedIds.indexOf(item.id) === -1) {
                                scope.selectedItems.push(item);
                                scope.selectedIds.push(item.id);
                                if (scope.changes.add.indexOf(item.id) === -1) {
                                    scope.changes.add.push(item.id);
                                }
                                if (scope.changes.remove.indexOf(item.id) > -1) {
                                    scope.changes.remove.splice(scope.changes.remove.indexOf(item.id), 1);
                                }
                            } else {
                                scope.selectedItems.splice(scope.selectedItems.indexOf(item), 1);
                                scope.selectedIds.splice(scope.selectedIds.indexOf(item.id), 1);
                                if (scope.changes.remove.indexOf(item.id) === -1) {
                                    scope.changes.remove.push(item.id);
                                }
                                if (scope.changes.add.indexOf(item.id) > -1) {
                                    scope.changes.add.splice(scope.changes.add.indexOf(item.id), 1);
                                }
                            }
                        };

                        scope.selectMultiple = function () {
                            if (!scope.config.config.tableIdentifier) {
                                $rootScope.$emit('itemSelected' + scope.config.config.service, scope.selectedItems, scope.changes);
                            } else {
                                $rootScope.$emit('itemSelected' + scope.config.config.tableIdentifier, scope.selectedItems, scope.changes);
                            }
                            scope.selectedItems = [];
                            scope.selectedIds = [];
                        };

                        // Click events
                        const ignoreClickClicked = function (item, col, index, $event) {
                            $rootScope.$emit('tableViewCellClicked' + scope.config.config.service, {
                                'item': item,
                                'col': col,
                                'rowIndex': index
                            });
                            $event.stopPropagation();
                        };
                        const emitGenericClickEvent = function (item, col, index, $event) {
                            scope.$emit('tableViewCellClicked' + scope.config.config.tableIdentifier, {
                                'item': item,
                                'col': col,
                                'rowIndex': index
                            });
                            if (!col.emitGenericClickEventAllowPropagation) {
                                $event.stopPropagation();
                            }
                        };
                        scope.handleCellClick = function (item, col, index, event) {
                            if (col.customClickHandler) {
                                event.stopPropagation();
                                // This is only currently used on the projects table and is only applicable
                                // to iOS browsers. Note in the Simulator the user agent seems to be sometimes
                                // Mac OS X so this needs to be tested on a device
                                const userAgent = navigator.userAgent.toLowerCase();
                                const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                                if (event.target.pathname && iOS) {
                                    $location.path(event.target.pathname);
                                }
                                return;
                            }

                            emitGenericClickEvent(item, col, index, event);

                            if (col.ignoreClick) {
                                ignoreClickClicked(item, col, index, event);
                            } else {
                                scope.changeRoute(item[scope.config.config.singlePageId], item, index);
                            }
                        }

                        // Show archived
                        scope.tvArchived = (savedFilters && savedFilters.includeArchived);
                        scope.showArchived = function () {
                            if (scope.tvArchived) {
                                saveToLocalStorage('includeArchived', true);
                            } else {
                                removeFromLocalStorage('includeArchived');
                            }
                            getData();
                        };

                        scope.getRowClasses = function(item){
                            var classes = [];
                            if (scope.selectedIds.indexOf(item.id) > -1) {
                                classes.push('selected');
                            }

                            if (scope.getValue('archivedAt', item)){
                                classes.push('tv-archived');
                            }

                            angular.forEach(scope.highlightedItems, function(items, cssClass){
                                var isValid = (typeof(items) === 'object');

                                var itemContained = isValid && (Object.values(items).indexOf(item) !== -1);
                                if (itemContained) {
                                    classes.push(cssClass);
                                }
                            });

                            return classes
                        };

                        scope.selected = [];
                        scope.$on('TRANSITION_SELECTED', function (event, transition) {
                            var ids = [];
                            angular.forEach(scope.selected, function (v, k) {
                                if (v) ids.push(k);
                            });
                            if (ids.length) {
                                service.transition({
                                    id: ids.join(),
                                    transition: transition
                                }).$promise.then(function (data) {
                                    getData();
                                    scope.selected = [];
                                });
                            }
                        });

                        scope.$on('TABLE_REFRESH_' + scope.config.config.service + '_' + scope.config.config.serviceMethod, function (event) {
                            getData();
                        });

                        if (scope.config.config.noAutoload !== true) {
                            getData();
                        }
                    })
                }
            };
        });
})();
