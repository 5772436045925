import {Component} from '@angular/core';
import {SimpleModalComponent} from "ngx-simple-modal";
import {NgForm} from "@angular/forms";
import {User} from "../../chalkstring-api/services/user.service";
import {HttpClient} from "@angular/common/http";
import {Assessment} from "../../chalkstring-api/services/assessment.service";

export interface ApplyToAllModel
{
    assessment: Assessment;
}

class ApplyToAllForm
{
    profitPercentage: number;
    overheadPercentage: number;
    inflationPercentage: number;
    wastePercentage: number;
    contingencyPercentage: number;
}

@Component({
    selector: 'apply-to-all',
    styles: ['.margin-top { margin-top: 8px; } .badge { margin-left: 0; }'],
    template: `
        <div class="modal-close" (click)="close()"></div>
        <div class="modal-content">
            <form #applyToAllForm="ngForm" (ngSubmit)="onSubmit(applyToAllForm)">
                <div class="modal-header">
                    <h2>Apply to all products</h2>
                </div>
                <div class="modal-body">
                    <fieldset>
                        <label for="overheadPercentage">Overhead</label>
                        <div class="icon-input">
                            <input type="number" min="0" max="100" step="0.01" id="overheadPercentage" name="overheadPercentage" [(ngModel)]="formModel.overheadPercentage" csTypeOver />
                            <div>%</div>
                        </div>

                        <label for="profitPercentage">Profit</label>
                        <div class="icon-input">
                            <input type="number" min="0" max="100" step="0.01" id="profitPercentage" name="profitPercentage" [(ngModel)]="formModel.profitPercentage" csTypeOver />
                            <div>%</div>
                        </div>

                        <label for="inflationPercentage">Inflation</label>
                        <div class="icon-input">
                            <input type="number" min="0" max="100" step="0.01" id="inflationPercentage" name="inflationPercentage" [(ngModel)]="formModel.inflationPercentage" csTypeOver />
                            <div>%</div>
                        </div>

                        <label for="wastePercentage">Waste</label>
                        <p class="badge block-info margin-top" *ngIf="showWasteInfo">
                            <span class="note-with-icon">
                                Enter the target total waste percentage for the entire assessment. Individual product waste percentages
                                will be adjusted proportionally to approach this value. This process cannot be undone to revert
                                products to their original waste percentages.
                            </span>
                        </p>
                        <div class="icon-input label-spacing">
                            <input type="number" min="0" max="100" step="0.01" id="wastePercentage" name="wastePercentage" csTypeOver (focus)="showWasteInfo = true" (blur)="showWasteInfo = false" [(ngModel)]="formModel.wastePercentage" />
                            <div>%</div>
                        </div>

                        <label for="contingencyPercentage">Contingency</label>
                        <p class="badge block-info margin-top" *ngIf="showContingencyInfo">
                            <span class="note-with-icon">
                                Enter the target total contingency percentage for the entire assessment. Individual product contingency percentages
                                will be adjusted proportionally to approach this value. This process cannot be undone to revert
                                products to their original contingency percentages.
                            </span>
                        </p>
                        <div class="icon-input label-spacing">
                            <input type="number" min="0" max="100" step="0.01" id="contingencyPercentage" name="contingencyPercentage" (focus)="showContingencyInfo = true" (blur)="showContingencyInfo = false" [(ngModel)]="formModel.contingencyPercentage" csTypeOver />
                            <div>%</div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="close()"><i class="icon-cancel"></i> Cancel</button>
                    <button type="submit" class="btn" [disabled]="!applyToAllForm.form.valid"><i class="icon-trash"></i> Apply to all</button>
                </div>
            </form>
        </div>
    `
})
export class ApplyToAllModalComponent extends SimpleModalComponent<ApplyToAllModel, boolean> implements ApplyToAllModel
{
    user: User;
    assessment: Assessment;
    formModel: ApplyToAllForm;
    showWasteInfo = false;
    showContingencyInfo = false;

    constructor(private _http: HttpClient)
    {
        super();
        this.formModel = new ApplyToAllForm();
    }

    mapDataObject(data: ApplyToAllModel)
    {
        super.mapDataObject(data);
        this.formModel.profitPercentage = this.assessment.autoProductProfitPercentage;
        this.formModel.overheadPercentage = this.assessment.autoProductOverheadPercentage;
        this.formModel.inflationPercentage = this.assessment.autoProductInflationPercentage;
        this.formModel.wastePercentage = this.assessment.targetWastePercentage;
        this.formModel.contingencyPercentage = this.assessment.targetContingencyPercentage;
    }

    onSubmit(applyToAllForm: NgForm)
    {
        if (!applyToAllForm.form.valid) {
            return;
        }

        this._http.post('/assessments/' + this.assessment.id + '/blanket-percentages' , this.formModel).subscribe(success => {
            this.assessment.autoProductInflationPercentage = this.formModel.inflationPercentage;
            this.assessment.autoProductOverheadPercentage = this.formModel.overheadPercentage;
            this.assessment.autoProductProfitPercentage = this.formModel.profitPercentage;
            this.assessment.targetWastePercentage = this.formModel.wastePercentage;
            this.assessment.targetContingencyPercentage = this.formModel.contingencyPercentage;
            this.result = true;
            this.close();
        });
    }
}
