import {Component, Input, Inject, LOCALE_ID} from "@angular/core";
import * as Highcharts from 'highcharts';
import Variablepie from "highcharts/modules/variable-pie";
import patternFill from "highcharts/modules/pattern-fill";
import {HttpClient} from "@angular/common/http";
import {LocaleCurrencyPipe} from "@generic/pipes/locale-currency.pipe";
import {DashboardCommercialData} from "../../chalkstring-api/services/dashboard.service";

Variablepie(Highcharts);
@Component({
    selector: 'dashboard-charts',
    template: require('./dashboard-charts.component.html'),
})
export class DashboardChartsComponent
{
    public readonly maxCharts = 32;

    private _commercialData: DashboardCommercialData;
    @Input() set commercialData(data: DashboardCommercialData)
    {
        this._commercialData = {
            projects: data.projects.slice(0, this.maxCharts),
            summary: data.summary
        };
        if (this._commercialData.projects.length < data.projects.length) {
            this.showingSubsetOfCharts = data.projects.length;
        } else {
            this.showingSubsetOfCharts = null;
        }
        this.redrawCharts();
    }
    get commercialData(): DashboardCommercialData
    {
        return this._commercialData;
    }

    public colors: string[];
    public patterns;
    public defaultLegendOptions;
    public defaultTooltipOptions;
    public piePlotOptions;
    public variablePiePlotOptions;
    public pieMargins;
    public updatedAt: string = (new Date()).toString();
    public variationsInfoRender;
    public showingSubsetOfCharts: number = null;

    constructor(
        public _currencyPipe: LocaleCurrencyPipe,
        @Inject(LOCALE_ID) public locale,
        protected http: HttpClient,
    )
    {
        Highcharts.setOptions({
            credits: {enabled: false}
        });

        this.colors = [
            '#37a6de',
            '#63c94a',
            '#f39200',
            '#e6007e',
            '#ffde00',
            '#bccbd4',
            '#f12e2e',
            '#666',
            '#888',
            '#aaa',
            '#ccc'
        ];
        this.patterns = [
            {
                'id': 'variation',
                'path': {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: '#eee',
                    strokeWidth: 3
                },
                opacity: 0.75
            },
            {
                'id': 'variation-ex',
                'path': {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: '#eee',
                    strokeWidth: 3,
                    fill: '#666'
                }
            }
        ];
        this.defaultLegendOptions = {
            enabled: true,
            maxHeight: 80,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor: '#fff',
            itemStyle: {
                fontWeight: 'normal'
            },
            labelFormatter: function () {
                return this.name === 'Variations' ? '<b>Variations</b>' :
                    '<b>' + this.projectNameTruncated + ':</b><br>' + this.name + ': ' + (this.labelCurrency) +
                    (this.percentageOfProjected ? (' (' + this.percentageOfProjected + '%)') : '');
            }
        };
        this.defaultTooltipOptions = {
            formatter: function () {
                return '<b>' + this.point.projectName + '</b><br>' +
                    this.point.name + ': ' + (this.point.labelCurrency) +
                    (this.point.percentageOfProjected ? ' (' + this.point.percentageOfProjected + '%)' : '');
            }
        };
        this.piePlotOptions = {
            pie: {
                center: ['50%', '50%'],
                point: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }
            }
        };
        this.variablePiePlotOptions = {
            variablepie: {
                center: ['50%', '50%'],
                point: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }
            }
        };

        this.pieMargins = [40, 0, 100, 0];

        this.variationsInfoRender = function () {
            let ren = this.renderer;
            ren.rect(14, 36, 13, 13, 5, 1)
                .attr({
                    fill: {
                        pattern: {
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#eee',
                                strokeWidth: 3,
                            },
                            width: 10,
                            height: 10,
                            backgroundColor: '#666',
                        }
                    },
                    //stroke: 'black',
                    zIndex: 10
                })
                .add();
            ren.label('Variations', 10, 32)
                .css({fontWeight: 'bold'})
                .attr({
                    stroke: 'black',
                    'stroke-width': 1,
                    fill: 'white',
                    paddingLeft: 20,
                    zIndex: 9
                })
                .add();
        };

    }

    truncate(string, maxChars)
    {
        if (string != undefined) {
            if (string.length >= maxChars) {
                return (string.substring(0, maxChars) + '...');
            }
            return string;
        }
    };

    redrawCharts()
    {
        if (!this.commercialData || !this.commercialData.projects || !this.commercialData.projects.length) {
            return;
        }

        for (let project of this.commercialData.projects) {
            if (new Date(project.updatedAt) > new Date(this.updatedAt)) {
                this.updatedAt = project.updatedAt;
            }
        }
        this.renderProjectedRevenue();
        this.renderRevenueToDate();
        this.renderProjectedCosts();
        this.renderCostsToDate();
    };

    renderProjectedRevenue()
    {
        let projectRevenueProjectedTotalData = [];
        let projectContractVariationSplit = [];

        let i = 0;
        for (let project of this.commercialData.projects) {
            projectRevenueProjectedTotalData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Total',
                y: project.revenueSupplyAndFitProjected.total,
                label: project.revenueSupplyAndFitProjected.total,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitProjected.total),
                color: this.colors[i]
            });
            projectContractVariationSplit.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Contract Revenue',
                y: project.revenueSupplyAndFitProjected.contract,
                label: project.revenueSupplyAndFitProjected.contract,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitProjected.contract),
                percentageOfProjected: project.revenueSupplyAndFitProjected.total ?
                    (project.revenueSupplyAndFitProjected.contract / project.revenueSupplyAndFitProjected.total * 100).toFixed(2) : 0,
                color: this.colors[i],
                fillOpacity: 0.1,
            });
            projectContractVariationSplit.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Variations Revenue',
                y: project.revenueSupplyAndFitProjected.variations,
                label: project.revenueSupplyAndFitProjected.variations,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitProjected.variations),
                percentageOfProjected: (project.revenueSupplyAndFitProjected.total ?
                    (project.revenueSupplyAndFitProjected.variations / project.revenueSupplyAndFitProjected.total * 100).toFixed(2) : 0),
                color: {
                    pattern: {
                        'id': 'variation',
                        'path': {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: '#eee',
                            strokeWidth: 3
                        },
                        opacity: 0.75,
                        width: 10,
                        height: 10,
                    }
                },
                fillOpacity: 0.1,
            });
            i++;
        }

        Highcharts.chart('db-projected-revenue', {
            chart: {
                type: "pie",
                events: {
                    load: this.variationsInfoRender
                },
                style: {
                    fontFamily: 'inherit;'
                },
                margin: this.pieMargins,
            },
            xAxis: {lineColor: 'transparent'},
            yAxis: {lineColor: 'transparent', /*title: {enabled: false}*/},

            colors: this.colors,
            title: {
                text: 'Projected Revenue'
            },
            legend: this.defaultLegendOptions,
            plotOptions: this.piePlotOptions,
            tooltip: this.defaultTooltipOptions,
            series: [
                {
                    name: 'Total Revenue',
                    type: "pie",
                    size: '100%',
                    data: projectRevenueProjectedTotalData,
                    showInLegend: true,
                    dataLabels: {
                        formatter: function () {
                            return this.point.options['projectName'];
                        }
                    }
                },
                {
                    name: 'Contract/Variations Split',
                    type: "pie",
                    size: '100%',

                    data: projectContractVariationSplit,
                    dataLabels: {
                        enabled: false
                    }
                }
            ]
        });
    };

    renderRevenueToDate()
    {
        let projectRevenueProjectedTotalData = [];
        let projectRevenueToDateWorkInProgressData = [];
        let projectRevenueToDateAppliedData = [];
        let projectRevenueToDateCertifiedData = [];

        let i = 0;
        for (let project of this.commercialData.projects) {
            projectRevenueProjectedTotalData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Projected',
                label: project.revenueSupplyAndFitProjected.total,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitProjected.total),
                y: project.revenueSupplyAndFitProjected.total,
                z: 100,
                color: Highcharts.color(this.colors[i]).brighten(-0.2).get('rgb')
            });
            projectRevenueToDateWorkInProgressData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Work In Progress',
                y: project.revenueSupplyAndFitProjected.total,
                label: project.revenueSupplyAndFitAccrued.total,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitAccrued.total),
                z: project.revenueSupplyAndFitProjected.total ? ((project.revenueSupplyAndFitAccrued.total + project.revenueSupplyAndFitApplied.total) / project.revenueSupplyAndFitProjected.total * 100) : 0,
                color: Highcharts.color(this.colors[i]).brighten(-0.1).get('rgb'),
                percentageOfProjected: project.revenueSupplyAndFitProjected.total ? (project.revenueSupplyAndFitAccrued.total / project.revenueSupplyAndFitProjected.total * 100).toFixed(2) : 0,
                rtdApplied: project.revenueSupplyAndFitApplied.total,
                rtdCertified: project.revenueSupplyAndFitLastCertified.total
            });
            projectRevenueToDateAppliedData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Applied',
                y: project.revenueSupplyAndFitProjected.total,
                label: project.revenueSupplyAndFitApplied.total,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitApplied.total),
                z: project.revenueSupplyAndFitProjected.total ? (project.revenueSupplyAndFitApplied.total / project.revenueSupplyAndFitProjected.total * 100) : 0,
                color: Highcharts.color(this.colors[i]).brighten(0).get('rgb'),
                percentageOfProjected: project.revenueSupplyAndFitProjected.total ? (project.revenueSupplyAndFitApplied.total / project.revenueSupplyAndFitProjected.total * 100).toFixed(2) : 0
            });
            projectRevenueToDateCertifiedData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Certified',
                y: project.revenueSupplyAndFitProjected.total,
                label: project.revenueSupplyAndFitLastCertified.total,
                labelCurrency: this._currencyPipe.transform(project.revenueSupplyAndFitLastCertified.total),
                z: project.revenueSupplyAndFitProjected.total ? (project.revenueSupplyAndFitLastCertified.total / project.revenueSupplyAndFitProjected.total * 100) : 0,
                color: Highcharts.color(this.colors[i]).brighten(0.1).get('rgb'),
                percentageOfProjected: project.revenueSupplyAndFitProjected.total ? (project.revenueSupplyAndFitLastCertified.total / project.revenueSupplyAndFitProjected.total * 100).toFixed(2) : 0
            });
            i++;
        }

        Highcharts.chart('db-revenue-to-date', {
            chart: {
                type: 'variablepie',
                style: {
                    fontFamily: 'inherit;'
                },
                margin: this.pieMargins,
            },
            colors: this.colors,
            title: {
                text: 'Revenue To Date'
            },
            legend: this.defaultLegendOptions,
            plotOptions: this.variablePiePlotOptions,
            tooltip: this.defaultTooltipOptions,
            series: [
                {
                    name: 'Projected Revenue',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectRevenueProjectedTotalData,
                    dataLabels: {
                        formatter: function () {
                            return this.point.options['projectName'];
                        }
                    }
                },
                {
                    name: 'Work In Progress Revenue',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectRevenueToDateWorkInProgressData,
                    dataLabels: {
                        enabled: false
                    }

                },
                {
                    name: 'Applied Revenue',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectRevenueToDateAppliedData,
                    dataLabels: {
                        enabled: false
                    }
                },
                {
                    name: 'Certified Revenue',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectRevenueToDateCertifiedData,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            ]
        });
    };

    renderProjectedCosts()
    {
        let projectCostsTotalProjectedData = [];
        let projectContractVariationSplit = [];

        let i = 0;
        for (let project of this.commercialData.projects) {
            projectCostsTotalProjectedData.push({
                name: 'Total',
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                y: project.costsProjected.total,
                label: project.costsProjected.total,
                labelCurrency: this._currencyPipe.transform(project.costsProjected.total),
                color: this.colors[i],
            });
            projectContractVariationSplit.push({
                name: 'Contract',
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                y: project.costsProjected.contract,
                label: project.costsProjected.contract,
                labelCurrency: this._currencyPipe.transform(project.costsProjected.contract),
                color: this.colors[i],
                fillOpacity: 0.1,
                percentageOfProjected: project.costsProjected.total ?
                    (project.costsProjected.contract / project.costsProjected.total * 100).toFixed(2) : 0
            });
            projectContractVariationSplit.push({
                name: 'Variations',
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                y: project.costsProjected.variations,
                label: project.costsProjected.variations,
                labelCurrency: this._currencyPipe.transform(project.costsProjected.variations),
                color: {
                    pattern: {
                        'id': 'variation',
                        'path': {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: '#eee',
                            strokeWidth: 3
                        },
                        opacity: 0.75,
                        width: 10,
                        height: 10,
                    }
                },
                fillOpacity: 0.1,
                percentageOfProjected: project.costsProjected.total ?
                    (project.costsProjected.variations / project.costsProjected.total * 100).toFixed(2) : 0
            });
            i++;
        }

        Highcharts.chart('db-projected-costs', {
            chart: {
                type: 'pie',
                events: {
                    load: this.variationsInfoRender
                },
                style: {
                    fontFamily: 'inherit;'
                },
                margin: this.pieMargins,
            },
            xAxis: {lineColor: 'transparent'},
            yAxis: {lineColor: 'transparent', /*title: {enabled: false}*/},

            colors: this.colors,
            title: {
                text: 'Projected Costs'
            },
            legend: this.defaultLegendOptions,
            plotOptions: this.piePlotOptions,
            tooltip: this.defaultTooltipOptions,
            series: [
                {
                    name: 'Total Costs',
                    type: 'pie',
                    size: '100%',
                    data: projectCostsTotalProjectedData,
                    showInLegend: true,
                    dataLabels: {
                        formatter: function () {
                            return this.point.options['projectName'];
                        }
                    }
                },
                {
                    name: 'Contract/Variations Split',
                    type: 'pie',
                    size: '100%',
                    data: projectContractVariationSplit,
                    dataLabels: {
                        enabled: false
                    }
                }
            ]
        });
    };

    renderCostsToDate()
    {
        let projectProjectedCostsData = [];
        let projectCostsToDateAccruedData = [];
        let projectCostsToDateActualData = [];

        let i = 0;
        for (let project of this.commercialData.projects) {
            // Total
            projectProjectedCostsData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Projected',
                label: project.costsProjected.total,
                labelCurrency: this._currencyPipe.transform(project.costsProjected.total),
                y: project.costsProjected.total,
                z: 100,
                color: Highcharts.color(this.colors[i]).brighten(-0.1).get('rgb')
            });
            // Accrued costs
            projectCostsToDateAccruedData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Accrued',
                label: project.costsAccrued.total,
                labelCurrency: this._currencyPipe.transform(project.costsAccrued.total),
                y: project.costsProjected.total,
                z: project.costsProjected.total ? (project.costsAccrued.total + project.costsActual.total) / project.costsProjected.total * 100 : 0,
                color: Highcharts.color(this.colors[i]).brighten(0).get('rgb'),
                percentageOfProjected: project.costsProjected.total ? (project.costsAccrued.total / project.costsProjected.total * 100).toFixed(2) : 0
            });
            // Actual costs
            projectCostsToDateActualData.push({
                projectName: project.projectName,
                projectNameTruncated: this.truncate(project.projectName, 24),
                name: 'Actual',
                label: project.costsActual.total,
                labelCurrency: this._currencyPipe.transform(project.costsActual.total),
                y: project.costsProjected.total,
                z: project.costsActual.total / project.costsProjected.total * 100,
                color: Highcharts.color(this.colors[i]).brighten(0.1).get('rgb'),
                percentageOfProjected: project.costsProjected.total ? (project.costsActual.total / project.costsProjected.total * 100).toFixed(2) : 0
            });
            i++;
        }

        Highcharts.chart('db-costs-to-date', {
            chart: {
                type: 'variablepie',
                style: {
                    fontFamily: 'inherit;'
                },
                margin: this.pieMargins
            },
            colors: this.colors,
            title: {
                text: 'Costs to Date'
            },
            legend: this.defaultLegendOptions,
            plotOptions: this.variablePiePlotOptions,
            tooltip: this.defaultTooltipOptions,
            series: [
                {
                    name: 'Projected Costs',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectProjectedCostsData,
                    dataLabels: {
                        formatter: function () {
                            return this.point.options['projectName'];
                        }
                    }
                },
                {
                    name: 'Accrued Costs',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectCostsToDateAccruedData,
                    dataLabels: {
                        enabled: false
                    }
                },
                {
                    name: 'Actual Costs',
                    type: 'variablepie',
                    size: '100%',
                    zMin: 0,
                    zMax: 100,
                    data: projectCostsToDateActualData,
                    showInLegend: true,
                    dataLabels: {
                        enabled: false
                    }
                }
            ],
        });

    };
}

patternFill(Highcharts);
