(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ApplicationEditCtrl', function ($locale, $scope, $rootScope, $routeParams, $route, $http, $window, ENV, project, productApplication, FileUploader, $filter, $location, subcontractor) {

            $scope.fileUploader = new FileUploader();
            $scope.documents = {
                uploader: $scope.fileUploader,
                list: []
            };

            $scope.applicationForm = {};
            $scope.loadApplication = function () {
                productApplication.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.paymentApplication = data;
                    $scope.projectId = $rootScope.projectId = data.project.id;
                    $scope.project = project.get({id: $scope.projectId}, {onlygroups: 'basic,hub'});

                    $scope.applicationForm.effectiveDate = data.effectiveDate ?
                        new Date($filter('date')(data.effectiveDate, "yyyy-MM-dd")) : null;

                    subcontractor.query({limit: 5000}).$promise.then(function (data) {
                        $scope.subcontractors = data;
                        if ($scope.paymentApplication.subcontractor) {
                            angular.forEach($scope.subcontractors, function (value) {
                                if (value.id === $scope.paymentApplication.subcontractor.id) {
                                    value.ticked = true;
                                }
                            });
                        }
                    });
                });
            };
            $scope.loadApplication();

            $scope.editApplication = function () {
                var documentIds = [];
                angular.forEach($scope.paymentApplication.documents, function (document) {
                    documentIds.push({file: document.file ? document.file.id : document.id});
                });
                var request = {
                    effectiveDate: $filter('date')($scope.applicationForm.effectiveDate, 'yyyy-MM-dd HH:mm:ss'),
                    documents: documentIds
                };
                if ($scope.paymentApplication.direction === 'incoming') {
                    request.subcontractor = $scope.applicationForm.subcontractor[0].id;
                }
                productApplication.update({id: $scope.paymentApplication.id}, request).$promise.then(function () {
                    $location.path('/project-hub/payment-application/' + $scope.paymentApplication.id);
                });
            };
        });
})();
