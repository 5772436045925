(function(){
	'use strict';
	/**
	 * {{ var | units : 'UNITSTEXT'  }}
	 * $filter('units')( var , 'UNITSTEXT');
	 * UnitType options are UNITSTEXT, MATERIALUNITS, PACKSIZE, TIMEUNITS, TIMETEXT
	 */
	angular.module('scswebappApp')
		.filter('units', function ( $injector ) {
			return function( input, unitType ) {
				unitType = $injector.get(unitType);
				return unitType[input];
			};
		});
})();