import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleModalService} from "ngx-simple-modal";

import {Router} from "@angular/router";
import {AssessmentProduct} from '../chalkstring-api/services/assessment-product.service';
import {EditAssessmentProductPricingModesModalComponent} from "./modal/edit-assessment-product-pricing-modes-modal.component";
import {Assessment} from '../chalkstring-api/services/assessment.service';
import {ChangeAssessmentProductScopeModalComponent} from "./modal/change-assessment-product-scope-modal.component";

@Component({
    template: `
        <i class="icon-lock-open action-icon" (click)="openPricingModal()" *ngIf="null === assessmentProduct.fixedAdjustedPrice" title="Edit product pricing"></i>
        <i class="icon-lock-filled action-icon" (click)="openPricingModal()" *ngIf="null !== assessmentProduct.fixedAdjustedPrice" title="Edit product pricing"></i>
        <i class="icon-edit action-icon" (click)="openScopeModal()" *ngIf="canChangeScope()" title="Add to contract scope"></i>
    `,
    selector: 'assessment-product-pricing-mode-controls'
})
export class EditAssessmentProductPricingModeControlsComponent
{
    @Input() onSite: boolean;
    @Input() hasApplied: boolean;
    @Input() assessment: Assessment;
    @Input() assessmentProduct: AssessmentProduct;
    @Output() change = new EventEmitter<AssessmentProduct>();

    constructor(private _modalService: SimpleModalService, private _router: Router)
    {
    }

    canChangeScope(): boolean
    {
        /**
         * This should only appear on products flagged as variations
         * and those products not included in an application for payment
         */
        return this.onSite &&
            !this.hasApplied &&
            this.assessmentProduct &&
            !!this.assessmentProduct.variationDate &&
            !this.assessmentProduct.hasOutgoingApplicationItems;
    }

    openScopeModal()
    {
        this._modalService.addModal(ChangeAssessmentProductScopeModalComponent, {
            onSite: this.onSite,
            assessment: this.assessment,
            assessmentProduct: this.assessmentProduct
        }).subscribe(res => {
            if (res) {
                this.change.emit(res);
            }
        });
    }

    openPricingModal()
    {
        this._modalService.addModal(EditAssessmentProductPricingModesModalComponent, {
            onSite: this.onSite,
            assessment: this.assessment,
            assessmentProduct: this.assessmentProduct
        }).subscribe(res => {
            if (res) {
                this.change.emit(res);
            }
        });
    }
}
