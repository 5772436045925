'use strict';

/**
 * @ngdoc directive
 * @name or-ng.formchanged
 * @description
 * # orformchanged
 * Usage:  <haschanged scope="newUserForm"></haschanged>
 */

angular.module('orNgFormChanged', [])
    .directive('haschanged', function ( $location ) {
        return {
            restrict: 'E',
            scope: false,
            transclude: true,
            template:
                '<div class="cs-modal-wrap ng-cloak" ng-class="{\'show-modal\' : changeAlert}">' +
                    '<div class="cs-modal">' +
                        '<p>This form has not been saved. Do you still want to navigate away?</p>' +
                        '<a href="" class="btn align-left" ng-click="leavePage()">Yes, navigate away</a>' +
                        '<a href="" class="btn align-right" ng-click="stayHere()">No, stay on this page</a>' +
                    '</div>' +
                '</div>',
            link: function postLink(scope, element, attrs) {

                scope.$watch(attrs.scope, function(newValue) {
                    scope.$on('$locationChangeStart', function(e, nextLocation) {
                        // Continue if form is not dirty
                        if ( !newValue || newValue.$pristine || scope.allowChange ){
                            return;
                        }else{
                            // Get next page location
                            scope.nextLocation = nextLocation.split('#');
                            scope.changeAlert = true;
                            e.preventDefault();
                        }
                    });

                    scope.$on('$routeChangeSuccess', function() {
                        scope.allowChange = false;
                    });

                }, true);

                scope.stayHere = function(){
                    scope.changeAlert = false;
                };

                scope.leavePage = function(){
                    scope[attrs.scope].$setPristine();
                    scope.allowChange = true;
                    if (scope.nextLocation.length > 0) {
                        let previousUrl = scope.nextLocation[scope.nextLocation.length - 1];
                        if (previousUrl.startsWith("http")) {
                            previousUrl = previousUrl.substring($location.absUrl().length - $location.path().length)
                        }
                        $location.path(previousUrl);
                    }
                };

            }
        };
    });

