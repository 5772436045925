(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.supplier
	 * @description
	 * # supplier
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	    .factory('supplier', ['Resource', 'ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/suppliers/:id', {id: '@id'}, {
                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/suppliers/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/suppliers/:id/unarchive'
                    },
	                prices: {
	                    url:  ENV.apiEndpoint + '/suppliers/:id/prices',
	                    method: 'GET',
	                    isArray: true
	                },
                    update:{
                        method: 'PUT',
                        transformRequest: function(theData) {
                            var data = angular.fromJson(theData);

                            // Remove contact variable from addresses
                            angular.forEach( data.addresses, function(value, key){
                                delete data.addresses[key].contact;
                            });

                            var newData = {
                                'fullName': data.fullName,
                                'supplierType': data.supplierType,
                                'accountRef': data.accountRef,
                                'headOfficeAddress':{
                                    'street1': data.headOfficeAddress ? data.headOfficeAddress.street1 : null,
                                    'street2': data.headOfficeAddress ? data.headOfficeAddress.street2 : null,
                                    'street3': data.headOfficeAddress ? data.headOfficeAddress.street3 : null,
                                    'county': data.headOfficeAddress ? data.headOfficeAddress.county : null,
									'postcode': data.headOfficeAddress ? data.headOfficeAddress.postcode : null,
                                    'country': data.headOfficeAddress ? data.headOfficeAddress.country : null
                                },
                                'emailAddresses': data.emailAddresses,
                                'telephoneNumbers': data.telephoneNumbers,
                                'addresses' : data.addresses
                            };

                            return angular.toJson(newData);
                        }
                    },
					projectDefaultContact: {
						url:  ENV.apiEndpoint + '/suppliers/:id/project/:projectId/default-contact',
						method: 'GET',
						isArray: false
					}
	            },
				'fullName'  // default sort order for query
	        );
	    }]);
})();
