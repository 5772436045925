'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:newMaterial
 * @description
 * # newMaterial
 */
angular.module('scswebappApp')
    .directive('newMaterial', function ($route, $routeParams, material, materialtype, configurableMaterial, UNITSTEXT, MATERIALUNITS, PACKSIZE, FileUploader, $rootScope, $location, routeType, packages) {
        return {
            template: require('./new-material-directive.html'),
            restrict: 'E',
            scope: {
                newMaterialAdded: '&',
                isInModal: '='
            },
            link: function postLink(scope) {

                // Set type keyword for edit and new routes

                scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

                scope.units = UNITSTEXT;
                scope.materialUnits = MATERIALUNITS;
                scope.packsize = PACKSIZE;

                //Converting for isteven-multi-select to understand it
                scope.unitsArray = Object.entries(scope.units).map(([attribute, name]) => ({ attribute, name }));
                scope.materialUnitsArray = Object.entries(scope.materialUnits).map(([name, attribute]) => ({ name, attribute }));
                scope.packsizeArray = Object.entries(scope.packsize).map(([name, attribute]) => ({ name, attribute }));

                let quantityUnitMap = new Map();
                for (const unit of scope.unitsArray) {
                    quantityUnitMap.set(unit.attribute, unit);
                }

                let singularNameMap = new Map();
                for (const unit of scope.materialUnitsArray) {
                    singularNameMap.set(unit.attribute, unit);
                }

                let packNameMap = new Map();
                for (const unit of scope.packsizeArray) {
                    packNameMap.set(unit.attribute, unit);
                }


                scope.cosshUploader = new FileUploader;
                scope.datasheetUploader = new FileUploader;

                scope.cosshUpload = {
                    uploader: scope.cosshUploader
                };

                scope.datasheetUpload = {
                    uploader: scope.datasheetUploader
                };

                scope.material = {
                    dataSheets: []
                };

                scope.materialTypes = [
                    {id: 0, label: 'simple'},
                    {id: 1, label: 'configurable'}
                ];

                // Add Link
                scope.addExternalLink = function () {
                    scope.material = (scope.material) ? scope.material : {externalLinks: []};
                    scope.material.externalLinks = (scope.material.externalLinks) ? scope.material.externalLinks : [];
                    scope.material.externalLinks.push({label: '', url: ''});
                };

                // Remove Link
                scope.removeExternalLink = function (index) {
                    scope.material.externalLinks.splice(index, 1);
                };

                var loadOptions = function(selection){
                    var selectionId = null;
                    if (selection) {
                        selectionId = (typeof selection === 'object' && selection.hasOwnProperty('id')) ? selection.id : selection;
                    }
                    material.getOptions().$promise.then(function (data) {
                        scope.brands = data.brands;
                        angular.forEach(data.types, function(type){
                            if (type.id === selectionId) {
                                type.ticked = true;
                            }
                        });
                        scope.types = data.types;
                        scope.newMaterialType.close();
                    });
                };
                scope.tempMaterial = {};

                scope.formatAttribute = function(attribute){
                    if (scope.tempMaterial[attribute][0]){
                        scope.material[attribute] = scope.tempMaterial[attribute][0].attribute;
                    }else{
                        scope.material[attribute] = '';
                    }
                };

                scope.updateFormValues = function(){
                    scope.updateQuantityUnit();

                    let singularName = singularNameMap.get(scope.material.singularName);
                    if (singularName) {
                        singularName.ticked = true;
                    }

                    let packName = packNameMap.get(scope.material.packName);
                    if (packName) {
                        packName.ticked = true;
                    }
                };

                scope.updateQuantityUnit = function(){
                    let quantityUnit = quantityUnitMap.get(scope.material.quantityUnit);
                    if (quantityUnit) {
                        quantityUnit.ticked = true;
                    }
                };

                scope.resetUnits = function (){
                    scope.material.quantityUnit = scope.tempMaterial.originalQuantityUnit;
                    scope.updateQuantityUnit();
                };

                if ($route.current.$$route.isEdit &&
                    $route.current.$$route.originalPath.indexOf('new-product') < 0 &&
                    $route.current.$$route.originalPath.indexOf('edit-product') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-quote') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-quote-request') < 0 &&
                    $route.current.$$route.originalPath.indexOf('new-material-quote') < 0 &&
                    $route.current.$$route.originalPath.indexOf('edit-material-quote') < 0 &&
                    $route.current.$$route.originalPath.indexOf('edit-material-order') < 0
                ) {
                    material.get({id: $routeParams.id}).$promise.then(function (data) {
                        scope.material = data;
                        scope.tempMaterial.originalQuantityUnit = scope.material.quantityUnit;

                        scope.updateFormValues();

                        // Create array so we can check sub group checkboxes
                        scope.subGroupArray = [];
                        angular.forEach(scope.material.subGroups, function (value) {
                            scope.subGroupArray.push(value.id);
                        });

                        scope.material.configurable = 0;

                        scope.isEdit = $route.current.$$route.isEdit;
                        if (typeof data.configurations !== 'undefined') {
                            scope.material.configurable = 1;
                        }
                        if (typeof scope.material.dataSheets === 'undefined') {
                            scope.material.dataSheets = [];
                        }
                    }).then(function () {
                        loadOptions(scope.material.materialType);

                        packages.query({limit:1000}).$promise.then(function (data) {
                            scope.materialPackages = data;

                            // Set package dropdown items to selected
                            if ($route.current.$$route.isEdit) {
                                angular.forEach(scope.materialPackages, function (value, key) {
                                    angular.forEach(scope.material.workPackages, function (material) {
                                        if (material.id === value.id) {
                                            scope.materialPackages[key].ticked = true;
                                        }
                                    });
                                });

                                // If edit page refactor packages object to array
                                if ($route.current.$$route.isEdit && typeof scope.material.workPackages !== 'undefined') {
                                    var wPackages = scope.material.workPackages;
                                    scope.material.workPackages = [];
                                    angular.forEach(wPackages, function (value) {
                                        scope.material.workPackages.push(value.id);
                                    });
                                }
                            }

                        });

                    });


                } else {
                    loadOptions();
                    packages.query({limit:1000}).$promise.then(function (data) {
                        scope.materialPackages = data;
                    });
                    scope.material.configurable = 0;
                }

                scope.removeConfiguration = function (configuration) {
                    var index = scope.material.configurations.indexOf(configuration);
                    scope.material.configurations.splice(index, 1);
                };

                scope.addConfiguration = function () {
                    scope.material = (scope.material) ? scope.material : {};
                    scope.material.configurations = (scope.material.configurations) ? scope.material.configurations : [];
                    scope.material.configurations.push({
                        name: null,
                        packSize: null,
                        singularSize: null
                    });
                };

                scope.addSubGroup = function (sub) {
                    scope.subGroupArray = (scope.subGroupArray) ? scope.subGroupArray : [];
                    if (scope.subGroupArray.indexOf(sub.id) > -1) {
                        scope.subGroupArray.splice(scope.subGroupArray.indexOf(sub.id), 1);
                    } else {
                        scope.subGroupArray.push(sub.id);
                    }
                };

                scope.createNewMaterialType = function (typeName) {
                    var newMaterialType = new materialtype({name: typeName});
                    newMaterialType.$save(function(data){
                        loadOptions(data.id);
                    })
                };

                scope.saveMaterial = function (form, saveAsNew = false) {
                    // Setting up packages
                    scope.material = ( scope.material ) ? scope.material : [];
                    scope.material.workPackages = ( scope.material.workPackages ) ? scope.material.workPackages : [];

                    if (scope.selectedMaPackages.length > 0) {
                        scope.material.workPackages = [];
                        angular.forEach(scope.selectedMaPackages, function (value) {
                            scope.material.workPackages.push(value.id);
                        });
                        scope.packageError = false;
                    } else {
                        scope.packageError = true;
                    }

                    scope.mSubmitted = true;
                    // Packages form error
                    if (typeof scope.material.workPackages === 'undefined' || scope.material.workPackages && scope.material.workPackages.length === 0) {
                        scope.packageError = true;
                        form.$valid = false;
                    } else if (!form.$invalid) {
                        scope.packageError = false;
                        form.$valid = true;
                        form.$invalid = false;
                    }

                    // material type error
                    if (typeof scope.material.materialType === 'undefined' || scope.material.materialType && scope.material.materialType.length === 0) {
                        scope.materialTypeError = true;
                        form.$valid = false;
                    } else if (!form.$invalid) {
                        scope.materialTypeError = false;
                        form.$valid = true;
                        form.$invalid = false;
                    }

                    if (form.$valid) {
                        scope.material.subGroups = scope.subGroupArray;
                        if (scope.material.configurable == true) {
                            var saveMaterial = new configurableMaterial(scope.material);
                        } else {
                            var saveMaterial = new material(scope.material);
                        }
                        if (saveAsNew) {
                            delete(saveMaterial.id);
                        }
                        saveMaterial.$save().then(function (data) {
                            form.$setPristine();
                            scope.mSubmitted = false;

                            if ($route.current.originalPath === '/new-material' || $route.current.originalPath === '/edit-material/:id') {
                                $location.path('/materials');
                            } else {
                                scope.newMaterialAdded({material: data});
                                scope.$emit('newMaterialAdded', data);
                                scope.newMaterial = false;
                                scope.material = {
                                    dataSheets: []
                                };
                            }

                            // Reset material packages
                            angular.forEach(scope.materialPackages, function (value) {
                                value.ticked = false;
                            });
                        });
                    }
                };


            }
        };
    });
