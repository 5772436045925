(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.materialDeliveries
     * @description
     * # materialDeliveries
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('materialDeliveries', ['ENV', 'Resource', function (ENV, $resource) {
            return $resource(
                ENV.apiEndpoint + '/materialdeliveries/:id', {id: '@id', onlygroups: '@onlygroups', groups: '@groups', projectId: '@projectId'}, {
                    getSchedule:{
                        method: 'GET',
                        isArray: true,
                        url:  ENV.apiEndpoint + '/projects/:projectId/delivery-schedule'
                    }
                }
            );
        }]);
})();
