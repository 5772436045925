'use strict';

import ManyAssociationPatcher from '../../other/ManyAssociationPatcher.js';

angular.module('scswebappApp')
    .directive('projectRoles', function (
        project,
        projectRole,
        user,
        $route,
        $location,
        $rootScope,
        PermPermissionStore
    ) {

        return {
            template: require('./project-roles.html'),
            restrict: 'E',
            transclude: true,
            scope: {
                'projectId': '=',
                'project': '='
            },
            link: function postLink(scope, element, attrs) {
                scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition('canWriteRolesProjectRoles');
                scope.contractorSelectModal = {};
                scope.operativeSelectModal = {};
                scope.userSelectModal = {};

                scope.bigRoles = ['Contractors', 'Operatives'];

                scope.contractorPatcher = new ManyAssociationPatcher('subcontractor');
                scope.operativePatcher = new ManyAssociationPatcher('operative');
                scope.userPatcher = new ManyAssociationPatcher();

                var currentRole = null;

                scope.loadAssignedRoles = function () {
                    project.roleUsers({id: scope.projectId}).$promise.then(function (data) {
                        scope.assignedRoles = data.projectRoleUsers;
                        return data;
                    });
                };
                scope.loadAssignedRoles();

                var isPristine = function () {
                    return !scope.contractorPatcher.isDirty() && !scope.operativePatcher.isDirty() && !scope.userPatcher.isDirty();
                };

                $rootScope.$on('itemSelectedContractorListModal', function (e, items) {
                    angular.forEach(items, function (item) {
                        scope.contractorPatcher.addAddOperation(item, {
                            project: scope.projectId,
                            projectRole: 7,
                            subcontractor: item.id
                        });
                    });
                });

                scope.$on('deleteRowContractorList', function (e, data) {
                    scope.contractorPatcher.addDeleteOperation(
                        data.item
                    );
                });

                $rootScope.$on('itemSelectedOperativeListModal', function (e, items) {
                    angular.forEach(items, function (item) {
                        scope.operativePatcher.addAddOperation(item, {
                            project: scope.projectId,
                            projectRole: 7,
                            operative: item.id
                        });
                    });
                });

                scope.$on('deleteRowOperativeList', function (e, data) {
                    scope.operativePatcher.addDeleteOperation(
                        data.item
                    );
                });

                scope.openUserListModal = function (projectRole) {
                    currentRole = projectRole.projectRoleId;
                    scope.userSelectModal.open();
                };

                scope.removeProjectRoleUser = function (item) {
                    scope.userPatcher.addDeleteOperation(
                        item
                    );
                };

                $rootScope.$on('itemSelectedUserListModal', function (e, items) {
                    angular.forEach(items, function (item) {
                        scope.userPatcher.addAddOperation(item, {
                            project: scope.projectId,
                            projectRole: currentRole,
                            user: item.id
                        });
                    });
                });

                scope.$on('$locationChangeStart', function (e, nextLocation) {
                    // Continue if form is not dirty
                    if (isPristine()) {
                        return;
                    } else {
                        // Get next page location
                        scope.nextLocation = nextLocation.split('#');
                        scope.changeAlert = true;
                        e.preventDefault();
                    }
                });

                scope.stayHere = function () {
                    scope.changeAlert = false;
                };

                scope.leavePage = function () {
                    scope.contractorPatcher.clear();
                    scope.operativePatcher.clear();
                    scope.userPatcher.clear();
                    scope.allowChange = true;
                    if (scope.nextLocation[0].startsWith('http')) {
                        scope.nextLocation[0] = scope.nextLocation[0].substring($location.absUrl().length - $location.path().length);
                    }
                    $location.url(scope.nextLocation[0]);
                };

                scope.save = function () {
                    var operations = scope.contractorPatcher.getChanges().concat(
                        scope.operativePatcher.getChanges(),
                        scope.userPatcher.getChanges()
                    );

                    if (operations.length > 0) {
                        project.patchRoleUsers({id: scope.projectId}, {ops: operations}, function () {
                            scope.contractorPatcher.clear();
                            scope.operativePatcher.clear();
                            scope.userPatcher.clear();
                            $route.reload();
                        });
                    }
                };

                scope.excludeLargeRoles = function (role) {
                    return (scope.bigRoles.indexOf(role.projectRole) === -1)
                };

                scope.isUserRoleDeleted = function (role) {
                    return scope.userPatcher.isItemQueuedForDelete(role);
                };

                scope.filterByRole = function (user, role) {
                    return user.projectRole === role.projectRoleId;
                };

                scope.getAddItemUserFullName = function (userItem) {
                    var addItem = scope.userPatcher.getAddItems()[userItem.user];

                    if (addItem !== undefined) {
                        return addItem.personFullName;
                    }

                    return "";
                }
            }
        };
    });
