(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:WorkscopeCtrl
     * @description
     * # WorkscopeCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('WorkScopeCtrl', function ($scope, $rootScope, workscope, $route, $routeParams, issue, $timeout, operative) {
            $scope.issueModal = {};
            $scope.issueListModals = {};

            $scope.issue = {files: []};
            $scope.workscopeId = $routeParams.id;
            $scope.activeWorkScopeStageId = null;
            $scope.activeIssueId = null;

            var loadWorkScope = function () {
                workscope.get({
                    id: $scope.workscopeId,
                    groups: 'products_externalLinks,workScopeStage_openIssues'
                }).$promise.then(function (data) {
                    // Create arrays for material and labour activity data sheets
                    $scope.materialDatasheets = [];
                    $scope.labourActivityDatasheets = [];

                    angular.forEach(data.assessmentProductZoneQuantity.assessmentLabourComponents, function (value) {
                        angular.forEach(value.assessmentLabourComponentMaterials.originalMaterial.dataSheets, function (mDatasheet) {
                            $scope.materialDatasheets.push(mDatasheet);
                        });
                        angular.forEach(value.assessmentLabourComponentActivities.originalLabourActivity.dataSheets, function (laDatasheet) {
                            $scope.labourActivityDatasheets.push(laDatasheet);
                        });
                    });

                    // Create arrays for material and labour activity links
                    $scope.materialLink = [];
                    $scope.labourActivityLinks = [];

                    angular.forEach(data.assessmentProductZoneQuantity.assessmentLabourComponents, function (value) {
                        angular.forEach(value.assessmentLabourComponentMaterials.originalMaterial.externalLinks, function (mLink) {
                            $scope.materialLink.push(mLink);
                        });
                        angular.forEach(value.assessmentLabourComponentActivities.originalLabourActivity.externalLinks, function (laLink) {
                            $scope.labourActivityLinks.push(laLink);
                        });
                    });


                    return data;
                }).then(function (data) {
                    $scope.workScope = data;
                    $scope.projectId = $rootScope.projectId = data.workPlan.assessment.projectWorkPackage.project.id;
                });
            };
            loadWorkScope();

            // Update assessed quantity
            $scope.saveMeasurement = function () {
                workscope.patchMeasurement({id: $scope.workscopeId}, {siteMeasurement: $scope.workScope.siteMeasurement}).$promise.then(function (data) {
                    $scope.workScope = data;
                });
            };

            $rootScope.$on('issueSaved', function (event, data) {
                loadWorkScope();
            });
        });
})();
