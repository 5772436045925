(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.assessmentLabourComponentMaterial
     * @description
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('assessmentLabourComponentMaterial', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/assessmentcomponentmaterials/:id', {
                    id: '@id',
                }, {
                    patch: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentcomponentmaterials/:id'
                    },
                    changeMaterialOrder: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/assessmentcomponentmaterials/material-order',
                        params: {
                            'ids[]': '@ids'
                        },
                        isArray: true
                    }
                }
            );
        }]);
})();
