import {CsUtils} from '../../other/csutils.js';

(function () {
    "use strict";

    /**
     * @ngdoc function
     * @name scswebappApp.controller:NewSubcontractorCtrl
     * @description
     * # NewSubcontractorCtrl
     * Controller of the scswebappApp
     */
    angular.module("scswebappApp")
        .controller("SubcontractorCtrl", function ($rootScope, $location, $route, $routeParams, subcontractor, $scope, routeType, $http, ENV, $window, trades, region, $q) {
            $scope.exportSubcontractors = function () {
                $http({
                    method: "GET",
                    responseType: "arraybuffer",
                    url: ENV.apiEndpoint + "/contractors/exportall"
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };

            function getSubcontractorData() {
                subcontractor.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.data = data;
                }).then(function () {
                    if ($route.current.$$route.isEdit) {
                        trades.query(function (data) {
                            $scope.trades = data;
                            angular.forEach($scope.data.trades, function (value) {
                                angular.forEach($scope.trades, function (value2) {
                                    if (value.id === value2.id) {
                                        value2.ticked = true;
                                    }
                                })
                            })
                        });
                        region.query(function (data) {
                            $scope.regions = data;
                            angular.forEach($scope.data.regions, function (value) {
                                angular.forEach($scope.regions, function (value2) {
                                    if (value.id === value2.id) {
                                        value2.ticked = true;
                                    }
                                });
                            });
                        });
                    }
                }).then(function () {
                    subcontractor.getOperatives({id: $routeParams.id}).$promise.then(function (data) {
                        $scope.operatives = data;

                        angular.forEach($scope.operatives, function (operative) {
                            var tmpTrades = [];

                            angular.forEach(operative.trades, function (trade) {
                                tmpTrades.push(trade.name);
                            });

                            operative.trades = tmpTrades.join(', ');

                            var tmpQualifications = [];

                            angular.forEach(operative.qualifications, function (qualification) {
                                tmpQualifications.push(qualification.name);
                            });

                            operative.qualifications = tmpQualifications.join(', ');
                            operative.assignmentCount = operative.assignments.length;

                            var totalPerformance = 0;

                            angular.forEach(operative.assignments, function (assignment) {
                                totalPerformance += assignment.performanceRating;
                            });

                            operative.averagePerformance = totalPerformance / operative.assignmentCount;

                        });
                    });
                }).then(function () {
                    //determine whether show CIS label or not
                    $scope.showCIS = true;

                    var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                    if (userDetails != null) {
                        var defaultCurrency = userDetails.account.defaultCurrency;
                        if(defaultCurrency == "MYR" || defaultCurrency == "SGD") {
                            $scope.showCIS = false;
                        }
                    }
                });
            }

            if ($routeParams.id) {
                $scope.confirmDelete = function () {
                    return confirm('Are you sure you want to delete this file?') ?
                        $q.when() : $q.reject();
                };
                $scope.subcontractorId = $routeParams.id;
                getSubcontractorData();
            }

            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

            $rootScope.$on('subcontractorSaved', function (e, data) {
                $location.path('/subcontractors');
            });
        });
})();
