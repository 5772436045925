(function(){
	'use strict';
	angular.module('scswebappApp')
        .factory('currencies', function ($translate, $locale) {
            var service = {};
            service.currencies = {
                'GBP' : {
                    'sym': '£'
                },
                'AED': {
                    'sym': 'AED'
                },
                'AUD': {
                    'sym': 'A$'
                },
                'CAD': {
                    'sym': 'C$'
                },
                'EUR' : {
                    'sym': '€'
                },
                'MYR': {
                    'sym': 'RM'
                },
                'NZD': {
                    'sym': 'NZ$'
                },
                'SGD': {
                    'sym': 'S$'
                },
                'USD': {
                    'sym': '$'
                },
                'ZAR': {
                    'sym': 'R'
                }
            };
            service.getAvailableCurrencies = function() {
                var results = [];
                angular.forEach(service.currencies, function (currency, key) {
                    results.push({
                        text: currency.sym + ' - ' + key,
                        code: key
                    })
                });

                return results;
            };

            service.setCurrentCurrency = function(currencyCode) {
                currencyCode = currencyCode ? currencyCode : 'GBP';
                var currency = this.currencies[currencyCode];

                $locale.NUMBER_FORMATS.CURRENCY_SYM = currency.sym;
                $locale.NUMBER_FORMATS.GROUP_SEP = currency.group_sep ? currency.group_sep: ',';
                $locale.NUMBER_FORMATS.DECIMAL_SEP = currency.decimal_sep ? currency.decimal_sep: '.';
            };

            return service;
        });
})();