import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'toggle-switch',
    template: `
        <label class="switch">
            <input name="name" [ngModel]="toggle" (ngModelChange)="toggleChange.emit($event)" type="checkbox">
            <div class="slider round"></div>
        </label>
    `,
    styles: [':host { vertical-align: middle; }']
})
export class ToggleSwitchComponent
{
    @Input() toggle: boolean = false;
    @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
