'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.tender
 * @description
 * # tender
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
    .factory('tender', ['Resource', 'ENV', function ($resource, ENV) {
        return $resource(
            ENV.apiEndpoint + '/tenders/:id', {id: '@id', type: '@type', groups: '@groups',onlygroups: '@onlygroups'}, {
                'submitTender': {
                    method: 'POST',
                    url: ENV.apiEndpoint + '/tenders/:id/submit'
                },
                'resolveTender': {
                    method: 'POST',
                    url: ENV.apiEndpoint + '/tenders/:id/:type'
                }
            }
        );

    }]);