'use strict';

/**
 * @ngdoc function
 * @name scswebappApp.controller:IssueCtrl
 * @description
 * # IssueCtrl
 * Controller of the scswebappApp
 */
angular.module('scswebappApp')
    .controller('IssueCtrl', function ($scope, $routeParams, issue, $route, $rootScope, workscope, issuecategory, $location, routeType, user, $filter, project, FileUploader) {
        //$scope.issue = {};
        if ($route.current.$$route.isList || $route.current.$$route.isNew) {
            $scope.project = project.get({id: $routeParams.id});
            $rootScope.projectId = $scope.projectId = $routeParams.id;
        } else if ($routeParams.id && !$route.current.$$route.isNew) {
            $scope.issueId = $routeParams.id;
        }

        let cleanUpIssueSavedEvent = $rootScope.$on('issueSaved', function (e, issue) {
            $location.path('/project-hub/issue/' + issue.id);
        });

        let cleanUpIssueLoadedEvent = $rootScope.$on('issueLoaded', function (e, issue) {
            $rootScope.projectId = $scope.projectId = issue.project.id;
        });

        $scope.$on('$destroy', function() {
            cleanUpIssueSavedEvent();
            cleanUpIssueLoadedEvent();
        });

        if ($route.current.$$route.isList) {
            $rootScope.projectId = $routeParams.id;

            $scope.issueTableParams = {project: $routeParams.id};
        }

        function getIssue() {
            issue.get({id: $routeParams.id}, {groups: 'workScopesStage_allNames,userHistory'}).$promise.then(function (data) {
                $scope.issue = data;
                $scope.stateTransition = ($scope.issue.stateResolution === 'Closed') ? 'reopen' : 'close';
                $scope.stateBtnText = ($scope.issue.stateResolution === 'Closed') ? 'Reopen this issue' : 'Resolve this Issue';
                $rootScope.projectId = data.project.id;
                if (data.workScopeStage) {
                    $scope.workScopeStage = data.workScopeStage;
                }
            });
        }

        if ($route.current.$$route.isSingle && !$route.current.$$route.isEdit) {
            getIssue();

            $scope.comment = {};
            $scope.leaveComment = function (commentForm) {
                $scope.submitted = true;

                if (commentForm.$valid) {
                    $scope.comment.issue = $scope.issue.id;

                    issue.saveComment({}, $scope.comment).$promise.then(function () {
                        $scope.submitted = false;
                        commentForm.$setPristine();
                        getIssue();
                        $scope.comment = {};
                    });
                }
            };

            $scope.transitionIssue = function (issueId, transition) {
                issue[transition]({id: $routeParams.id}).$promise.then(function (data) {
                    getIssue();
                });
            };
        }
    });
