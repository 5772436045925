import {NgModule} from '@angular/core';
import {ImportBillOfQuantitiesButtonComponent} from "./assessment/buttons/import-bill-of-quantities-button.component";
import {GenericModule} from "./generic.module";
import {AssessmentStatusButtonComponent} from "./assessment/buttons/assessment-status-button.component";
import {TakeoffListButtonComponent} from "./assessment/buttons/takeoff-list-button.component";
import {CommonModule} from "@angular/common";
import {MoveProductModalComponent} from "./assessment/modals/move-product-modal.component";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {MoveProductLinkComponent} from "./assessment/move-product-link.component";
import {NewAssessmentModalComponent} from "./assessment/modals/new-assessment-modal.component";
import {
    AssessmentListComponent,
    AssessmentStatusCellComponent
} from "./assessment/tables/assessment-list.component";
import {SelectAssessmentModalComponent} from "./assessment/modals/select-assessment-modal.component";
import {NgxPopperModule} from "ngx-popper";
import {TableViewModule} from "./table-view.module";
import {AssessmentProductListComponent} from "./assessment/tables/assessment-product-list.component";
import {SelectAssessmentProductModalComponent} from "./assessment/modals/select-assessment-product-modal.component";
import {AssessmentAuditButtonComponent} from "./assessment/buttons/assessment-audit-button.component";
import {EstimatesComponent} from "./assessment/estimates.component";
import {UiModule} from "./slate/ui/ui.module";
import {RepairAssessmentModalComponent} from "./assessment/modals/repair-assessment-modal.component";
import {AssessmentConfigModalComponent} from "./assessment/modals/assessment-config-modal.component";
import {AssessmentConfigPanelComponent} from "./assessment/panels/assessment-config-panel.component";
import {
    AssessmentProductQuantitiesModalComponent
} from "./assessment/modals/assessment-product-quantities-modal.component";
import {BillModule} from "./bill.module";

@NgModule({
    imports: [
        GenericModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        TableViewModule,
        NgxPopperModule,
        UiModule,
        BillModule,
    ],
    exports: [
        AssessmentConfigPanelComponent,
        AssessmentListComponent,
        AssessmentProductQuantitiesModalComponent,
        EstimatesComponent,
    ],
    declarations: [
        AssessmentAuditButtonComponent,
        AssessmentConfigPanelComponent,
        AssessmentConfigModalComponent,
        AssessmentListComponent,
        AssessmentProductListComponent,
        AssessmentProductQuantitiesModalComponent,
        AssessmentStatusButtonComponent,
        AssessmentStatusCellComponent,
        EstimatesComponent,
        ImportBillOfQuantitiesButtonComponent,
        MoveProductModalComponent,
        MoveProductLinkComponent,
        NewAssessmentModalComponent,
        RepairAssessmentModalComponent,
        SelectAssessmentModalComponent,
        SelectAssessmentProductModalComponent,
        TakeoffListButtonComponent,
    ],
    entryComponents: [
        AssessmentAuditButtonComponent,
        AssessmentConfigModalComponent,
        AssessmentConfigPanelComponent,
        AssessmentProductQuantitiesModalComponent,
        AssessmentStatusCellComponent,
        EstimatesComponent,
        MoveProductModalComponent,
        MoveProductLinkComponent,
        NewAssessmentModalComponent,
        RepairAssessmentModalComponent,
        SelectAssessmentModalComponent,
        SelectAssessmentProductModalComponent,
    ]
})
export class AssessmentModule
{
}
