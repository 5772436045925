(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.material
     * @description
     * # material
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('orders', ['ENV', 'Resource', 'UNITSTEXT', '$filter', function (ENV, $resource, what, $filter) {
            return $resource(
                ENV.apiEndpoint + '/orders/:id', {id: '@id', index:'@index', groups: '@groups'}, {
                    // Things
                }
            );
        }]);
})();
