import {Component} from '@angular/core';
import {SimpleModalComponent, SimpleModalService} from "ngx-simple-modal";
import {AssessmentProduct, AssessmentProductService} from "../../chalkstring-api/services/assessment-product.service";
import {CurrencyUtils} from "@generic/models/currency.model";
import { first } from 'rxjs/operators';
import {Assessment} from 'app/modules/chalkstring-api/services/assessment.service';
import {
    EditAssessmentProductModel,
    EditAssessmentProductPricingModesModalComponent
} from "./edit-assessment-product-pricing-modes-modal.component";
import {
    AssessmentProductQuantitiesModalComponent
} from "../../assessment/modals/assessment-product-quantities-modal.component";
import {ConfirmModalComponent} from "@generic/modal/confirm-modal.component";
import {CsEvent} from "@generic/events";
import {RateBuildUpIssueService} from "../issues/rate-build-up-issue.service";

@Component({
    template: `
        <div class="modal-close" (click)="onClose()"></div>
        <div class="modal-content" id="modal-change-ap-scope">
            <form #productScopeForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="modal-header">
                    <h2>Add product to contract scope</h2>
                </div>
                <div class="modal-body">
                    <dl class="stacked highlighted-labels">
                        <dt>Product</dt>
                        <dd>{{assessmentProduct.name}}</dd>
                        <dt>Current rate</dt>
                        <dd>{{assessmentProduct.assessmentPrice | localeCurrency}} <i class="icon-edit" (click)="openPricingModal()" title="Edit pricing"></i></dd>
                        <dt></dt>
                        <dd>per {{assessmentProduct.quantityUnit | quantityUnit}}</dd>
                        <dt>Current total quantity</dt>
                        <dd>
                            <i class="icon-spin3 inline-spinner" *ngIf="working"></i>
                            <span *ngIf="!working">{{totalCurrentQuantity | number:'.2'}} <i class="icon-edit" (click)="openQuantityModal()" title="Edit quantities"></i></span>
                        </dd>
                        <dt></dt>
                        <dd>per {{assessmentProduct.quantityUnit | quantityUnit}}</dd>
                    </dl>
                    <fieldset>
                        <div class="badge block block-warning">
                            WARNING: Adding this variation to the contract scope
                            will set the current rate and zone quantities as the tendered values
                        </div>
                        <label class="checkbox-container">
                            <input type="checkbox" name="confirm" [(ngModel)]="confirm" required />
                            <span class="checkbox"></span>I confirm the above rate and quantities are to be used as tender values
                        </label>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="onClose()"><i class="icon-cancel"></i>
                        Close
                    </button>
                    <button type="submit" class="btn" [disabled]="!productScopeForm.form.valid || !confirm"><i
                            class="icon-ok"></i> Add to contract scope
                    </button>
                </div>
            </form>
        </div>
    `,
    styles: ['dd .btn { margin: 5px 0 }']
})
export class ChangeAssessmentProductScopeModalComponent extends SimpleModalComponent<EditAssessmentProductModel, AssessmentProduct> implements EditAssessmentProductModel
{
    onSite: boolean;
    assessment: Assessment;
    assessmentProduct: AssessmentProduct;
    currencySymbol: string;
    working: boolean = true;
    totalCurrentQuantity: number;
    confirm: boolean = false;
    onQuantityChange: boolean = false;


    constructor(private _apService: AssessmentProductService, private _modalService: SimpleModalService, private _riService: RateBuildUpIssueService)
    {
        super();
        this.currencySymbol = CurrencyUtils.getCurrencySymbol();
    }

    fetchQuantity()
    {
        this.working = true;
        this._apService.getTotalCurrentQuantity(this.assessmentProduct).pipe(first())
            .subscribe(res => {
                this.working = false;
                this.totalCurrentQuantity = res;
            });
    }

    mapDataObject(data: EditAssessmentProductModel)
    {
        super.mapDataObject(data);
        this.fetchQuantity();
        CsEvent.reloadUserpilot();
        CsEvent.track('apScopeChangeModal', {a: this.assessment.id, p: this.assessmentProduct.id});
    }

    openPricingModal()
    {
        this._modalService.addModal(EditAssessmentProductPricingModesModalComponent, {
            onSite: this.onSite,
            assessment: this.assessment,
            assessmentProduct: this.assessmentProduct
        }).subscribe(res => {
            if (res) {
                this.onQuantityChange = true;
                this.assessmentProduct = res;
            }
        });
    }

    openQuantityModal()
    {
        this._modalService.addModal(AssessmentProductQuantitiesModalComponent, {
            onSite: this.onSite,
            assessment: this.assessment,
            assessmentProduct: this.assessmentProduct
        }).subscribe(() => {
            this.fetchQuantity();
        });
    }

    onSubmit()
    {
        // Changing from variation to contract
        this._modalService.addModal(ConfirmModalComponent, {
            title: 'Confirm scope change',
            message: `Are you sure you want to add this variation to the contract scope?
                This will set the current rate and zone quantities as the tendered values.
                This process cannot be reversed.`,
            yes: 'Add to contract scope',
            no: 'Cancel'
        }).subscribe(yes => {
            if (yes) {
                this._apService.variationToContract(this.assessmentProduct).pipe(first())
                    .subscribe(ap => {
                        this.result = ap;
                        this._riService.fetchRbuIssues(this.assessment); // Refresh RBU issues
                        this.close();
                    });
            }
        });
    }

    onClose()
    {
        //update assessment product price at RBU list
        if (this.onQuantityChange) {
            this.result = this.assessmentProduct;
        }
        this.close();
    }
}
