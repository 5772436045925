(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProjectHubSingleCtrl', function ($scope, $rootScope, $routeParams, $q, $filter, project, projectRole, subcontractor, user, zone, packages, $locale) {

            $scope.projectId = $rootScope.projectId = $routeParams.id;

            $scope.accordion = [true, false, false];
            $scope.accordionToggle = function (n) {
                $scope.accordion[n] = !$scope.accordion[n];
            };

            var getData = function () {
                project.overview({id: $scope.projectId}).$promise.then(function (data) {
                    $scope.project = data;
                });
            };
            getData();

            /* Set start and completion dates */
            $scope.dateModal = function (type, date) {
                $scope.showDateModal = !$scope.showDateModal;
                $scope.theDate = date ? $filter('date')(date, 'yyyy-MM-dd') : null;
                $scope.dateType = type;
            };
            $scope.setDate = function () {
                $scope.submitted = true;
                $scope.dateError = !$scope.theDate;
                if (!$scope.dateError) {
                    var dateData = ($scope.dateType === 'Start') ? {'onSiteStartDate': $scope.theDate} : {'onSiteEndDate': $scope.theDate};
                    project.updateDates({id: $routeParams.id}, dateData).$promise.then(function () {
                        $scope.dateType = null;
                        $scope.theDate = null;
                        $scope.dateModal();
                        getData();
                    });
                }
            };
        });
})();
