(function(){
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.preliminary
     * @description
     * # preliminary
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .factory('externallink', ['Resource', 'ENV', function ( $resource, ENV ) {
            return $resource(
                ENV.apiEndpoint + '/externallinks/:id', { id: '@id', project: '@projectId' }, {
                    update: {
                        method: 'PATCH'
                    },
                    delete: {
                        method: 'DELETE'
                    }
                }
            );
        }]);
})();
