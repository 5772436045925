import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {GenerateOutgoingApplicationModalComponent} from "./application/modals/generate-outgoing-application-modal.component";
import {
    GenerateOutgoingApplicationButtonComponent
} from "./application/buttons/generate-outgoing-application-button.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    exports: [
        GenerateOutgoingApplicationButtonComponent,
        GenerateOutgoingApplicationModalComponent,
    ],
    declarations: [
        GenerateOutgoingApplicationButtonComponent,
        GenerateOutgoingApplicationModalComponent,
    ],
    entryComponents: [
        GenerateOutgoingApplicationButtonComponent,
        GenerateOutgoingApplicationModalComponent,
    ],
    providers: [

    ]
})
export class ApplicationModule
{
}
