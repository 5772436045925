setupMainRoutes = function($routeProvider) {
    $routeProvider
        .when('/', {
            template: require('../modules/login/main.html'),
            controller: 'MainCtrl'
        })
        // .when('/dashboard', {
            // title: 'Dashboard',
            // template: require('../modules/dashboard/dashboard.html'),
            // controller: 'DashboardCtrl',
            // data: {
            //     permissions: {
            //         'redirectTo': '/projects',
            //         'only': ['canReadDashboard']
            //     }
            // }
        // })
        .when('/projects', {
            title: 'Projects'
        })
        .when('/takeoff/projects', {
            title: 'Projects for takeoff',
            data: {
                permissions: {
                    'redirectTo': '/welcome',
                    'only': ['canReadTakeoffPage']
                }
            }
        })
        .when('/project/:id', {
            title: 'Project',
            template: require('../modules/project/project.html'),
            controller: 'ProjectSingleCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjects']
                }
            }
        })
        .when('/products', {
            title: 'Products',
            template: require('../modules/product/products.html'),
            controller: 'ProductCtrl',
            isList: true,
            data: {
                permissions: {
                    'only': ['canReadResourcesProductTemplates']
                }
            }
        })
        .when('/product/:id', {
            title: 'Product',
            template: require('../modules/product/product.html'),
            controller: 'ProductCtrl',
            data: {
                permissions: {
                    'only': ['canReadResourcesProductTemplates']
                }
            }
        })
        .when('/new-product', {
            title: 'New product',
            template: require('../modules/product/new-product.html'),
            controller: 'ProductCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesProductTemplates']
                }
            }
        })
        .when('/edit-product/:id', {
            title: 'Edit product',
            template: require('../modules/product/new-product.html'),
            controller: 'ProductCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesProductTemplates']
                }
            }
        })
        .when('/clients', {
            title: 'Clients',
            template: require('../modules/client/clients.html'),
            controller: 'ClientCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsClients']
                }
            }
        })
        .when('/client/:id', {
            title: 'Client',
            template: require('../modules/client/client.html'),
            controller: 'ClientCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsClients']
                }
            }
        })
        .when('/new-client', {
            title: 'New client',
            template: require('../modules/client/new-client.html'),
            controller: 'ClientCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsClients']
                }
            }
        })
        .when('/edit-client/:id', {
            title: 'Edit client',
            template: require('../modules/client/new-client.html'),
            controller: 'ClientCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsClients']
                }
            }
        })
        .when('/my-account', {
            title: 'Account',
            template: require('../modules/my-account/my-account.html'),
            controller: 'MyaccountCtrl'
        })
        .when('/new-password', {
            title: 'New password',
            template: require('../modules/user-auth/forced-password/forced-password.html'),
            controller: 'ForcedPasswordResetCtrl',
            data: {
                permissions: {
                    'only': ['forcePasswordReset']
                }
            }
        })
        .when('/materials', {
            title: 'Materials',
            template: require('../modules/material/materials.html'),
            controller: 'MaterialCtrl',
            isList: true,
            data: {
                permissions: {
                    'only': ['canReadResourcesMaterials']
                }
            }
        })
        .when('/material/:id', {
            title: 'Material',
            template: require('../modules/material/material.html'),
            controller: 'MaterialCtrl',
            data: {
                permissions: {
                    'only': ['canReadResourcesMaterials']
                }
            }
        })
        .when('/new-material', {
            title: 'New material',
            template: require('../modules/material/new-material.html'),
            controller: 'MaterialCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesMaterials']
                }
            }
        })
        .when('/edit-material/:id', {
            title: 'Edit material',
            template: require('../modules/material/new-material.html'),
            controller: 'MaterialCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesMaterials']
                }
            }
        })
        .when('/suppliers', {
            title: 'Suppliers',
            template: require('../modules/supplier/suppliers.html'),
            controller: 'SupplierCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsSuppliers']
                }
            }
        })
        .when('/supplier/:id', {
            title: 'Supplier',
            template: require('../modules/supplier/supplier.html'),
            controller: 'SupplierCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsSuppliers']
                }
            }
        })
        .when('/new-supplier', {
            title: 'New supplier',
            template: require('../modules/supplier/new-supplier.html'),
            controller: 'SupplierCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsSuppliers']
                }
            }
        })
        .when('/edit-supplier/:id', {
            title: 'Edit supplier',
            template: require('../modules/supplier/new-supplier.html'),
            controller: 'SupplierCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsSuppliers']
                }
            }
        })
        .when('/fixed-costs', {
            title: 'Fixed costs',
            template: require('../modules/fixed-cost/fixedcosts.html'),
            controller: 'FixedCostCtrl',
            data: {
                permissions: {
                    'only': ['canReadResourcesFixedCosts']
                }
            }
        })
        .when('/fixed-cost/:id', {
            title: 'Fixed cost',
            template: require('../modules/fixed-cost/fixedcost.html'),
            controller: 'FixedCostCtrl',
            data: {
                permissions: {
                    'only': ['canReadResourcesFixedCosts']
                }
            }
        })
        .when('/new-fixed-cost', {
            title: 'New fixed cost',
            template: require('../modules/fixed-cost/new-fixedcost.html'),
            controller: 'FixedCostCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesFixedCosts']
                }
            }
        })
        .when('/edit-fixed-cost/:id', {
            title: 'Edit fixed cost',
            template: require('../modules/fixed-cost/new-fixedcost.html'),
            controller: 'FixedCostCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteResourcesFixedCosts']
                }
            }
        })
        .when('/rate-build-up/:id', {
            title: 'Rate build up',
            template: require('../modules/rate-build-up/rate-build-up.html'),
            controller: 'RateBuildUpCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjectsOffsiteAssessments']
                }
            }
        })
        .when('/export-rate-build-up/:id', {
            title: 'Export rate build up',
            template: require('../modules/export-rate-build-up/export-rate-build-up.html'),
            controller: 'ExportRateBuildUpCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjectsOffsiteAssessments']
                }
            }
        })
        .when('/project/:projectId/assessment/:assessmentId/boq', {
            title: 'Bill of quantities',
            template: require('../modules/bill-of-quantities/bill-of-quantities.html'),
            controller: 'BillOfQuantitiesCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjectsOffsiteAssessments']
                }
            }
        })
        .when('/assessment/:id', {
            title: 'Assessment',
            template: require('../modules/assessment/assessment.html'),
            controller: 'AssessmentCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjectsOffsiteAssessments']
                }
            }
        })
        .when('/subcontractors', {
            title: 'Subcontractors',
            template: require('../modules/sub-contractor/subcontractors.html'),
            controller: 'SubcontractorCtrl',
            list: true,
            data: {
                permissions: {
                    'only': ['canReadContactsSubcontractors']
                }
            }
        })
        .when('/subcontractor/:id', {
            title: 'Subcontractor',
            template: require('../modules/sub-contractor/subcontractor.html'),
            controller: 'SubcontractorCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsSubcontractors']
                }
            }
        })
        .when('/new-subcontractor', {
            title: 'New subcontractor',
            template: require('../modules/sub-contractor/new-subcontractor.html'),
            controller: 'SubcontractorCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsSubcontractors']
                }
            }
        })
        .when('/edit-subcontractor/:id', {
            title: 'Edit subcontractor',
            template: require('../modules/sub-contractor/new-subcontractor.html'),
            controller: 'SubcontractorCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsSubcontractors']
                }
            }
        })
        .when('/operatives', {
            title: 'Operatives',
            template: require('../modules/operative/operatives.html'),
            controller: 'OperativeCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsOperatives']
                }
            }
        })
        .when('/operative/:id', {
            title: 'Operative',
            template: require('../modules/operative/operative.html'),
            controller: 'OperativeCtrl',
            data: {
                permissions: {
                    'only': ['canReadContactsOperatives']
                }
            }
        })
        .when('/new-operative', {
            title: 'New operative',
            template: require('../modules/operative/new-operative.html'),
            controller: 'OperativeCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsOperatives']
                }
            }
        })
        .when('/edit-operative/:id', {
            title: 'Edit operative',
            template: require('../modules/operative/new-operative.html'),
            controller: 'OperativeCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only': ['canWriteContactsOperatives']
                }
            }
        })
        .when('/assessment-fixed-costs/:id', {
            title: 'Fixed costs',
            template: require('../modules/assessment-fixed-cost/assessment-fixedcost.html'),
            controller: 'AssessmentFixedCostCtrl',
            data: {
                permissions: {
                    'only': ['canReadProjectsOffsiteAssessments']
                }
            }
        })
        .when('/new-material-quote', {
            title: 'New material quote',
            template: require('../modules/material-quote/new-material-quote.html'),
            controller: 'QuoteCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesMaterialQuotes']
                }
            }
        })
        .when('/edit-material-quote/:id', {
            title: 'Edit material quote',
            template: require('../modules/material-quote/new-material-quote.html'),
            controller: 'QuoteCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesMaterialQuotes']
                }
            }
        })
        .when('/material-quote/:id', {
            title: 'Material quote',
            template: require('../modules/material-quote/material-quote.html'),
            controller: 'QuoteCtrl',
            isSingle: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesMaterialQuotes']
                }
            }
        })
        .when('/material-quotes', {
            title: 'Material quotes',
            template: require('../modules/material-quote/material-quotes.html'),
            controller: 'QuoteCtrl',
            isList: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesMaterialQuotes']
                }
            }
        })
        .when('/import-material-quote', {
            title: 'Import material quote',
            template: require('../modules/import-quotation/import-quotation.html'),
            controller: 'importQuotationCtrl',
            quoteType: 'material',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesMaterialQuotes']
                }
            }
        })

        .when('/new-labour-quote', {
            title: 'New labour quote',
            template: require('../modules/labour-quote/new-labour-quote.html'),
            controller: 'LabourQuoteCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesLabourQuotes']
                }
            }
        })
        .when('/edit-labour-quote/:id', {
            title: 'Edit labour quote',
            template: require('../modules/labour-quote/new-labour-quote.html'),
            controller: 'LabourQuoteCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesLabourQuotes']
                }
            }
        })
        .when('/labour-quote/:id', {
            title: 'Labour quote',
            template: require('../modules/labour-quote/labour-quote.html'),
            controller: 'LabourQuoteCtrl',
            isSingle: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesLabourQuotes']
                }
            }
        })
        .when('/labour-quotes', {
            title: 'Labour quotes',
            template: require('../modules/labour-quote/labour-quotes.html'),
            controller: 'LabourQuoteCtrl',
            isList: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesLabourQuotes']
                }
            }
        })
        .when('/import-labour-quote', {
            title: 'Import labour quote',
            template: require('../modules/import-quotation/import-quotation.html'),
            controller: 'importQuotationCtrl',
            quoteType: 'labour',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesLabourQuotes']
                }
            }
        })
        .when('/new-sf-quote', {
            title: 'New supply &amp; fit quote',
            template: require('../modules/sf-quote/new-sf-quote.html'),
            controller: 'SupplyFitQuoteCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/edit-sf-quote/:id', {
            title: 'Edit supply &amp; fit quote',
            template: require('../modules/sf-quote/new-sf-quote.html'),
            controller: 'SupplyFitQuoteCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/sf-quote/:id', {
            title: 'Supply &amp; fit quote',
            template: require('..//modules/sf-quote/sf-quote.html'),
            controller: 'SupplyFitQuoteCtrl',
            isSingle: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/sf-quotes', {
            title: 'Supply &amp; fit quotes',
            template: require('../modules/sf-quote/sf-quote-list.html'),
            controller: 'SupplyFitQuoteListCtrl',
            isList: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/import-sf-quote', {
            title: 'Import supply &amp; fit quote',
            template: require('../modules/import-quotation/import-quotation.html'),
            controller: 'importQuotationCtrl',
            quoteType: 'sf',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/send-notification/:id', {
            title: 'Send notification',
            template: require('../modules/notification/send-notification.html'),
            controller: 'SendNotificationCtrl'
        })
        .when('/request-password', {
            title: 'Request password',
            template: require('../modules/password/request-password.html'),
            controller: 'ResetPasswordCtrl'
        })
        .when('/reset-password/:username/:token', {
            title: 'Reset password',
            template: require('../modules/password/reset-password.html'),
            controller: 'ResetPasswordCtrl'
        })
        .when('/assessment-adjustments/:assessmentId', {
            title: 'Assessment adjustments',
            template: require('../modules/assessment-adjustment/assessment-adjustments.html'),
            controller: 'AssessmentAdjustmentsCtrl',
            controllerAs: 'assessmentAdjustments'
        })
        .when('/assessment-comparison/:id', {
            title: 'Assessment comparison',
            template: require('../modules/assessment-comparison/assessment-comparison.html'),
            controller: 'AssessmentComparisonCtrl',
            controllerAs: 'assessmentComparison'
        })
        .when('/tender/:id', {
            title: 'Tender',
            template: require('../modules/tender/tender.html'),
            controller: 'TenderCtrl',
            controllerAs: 'tender',
            data: {
                permissions: {
                    'only' : ['canReadProjectsTenders']
                }
            }
        })
        .when('/material-quote-requests', {
            title: 'Material quote requests',
            template: require('../modules/quote-request/quote-requests.html'),
            controller: 'QuoteRequestCtrl',
            isList: true,
            quoteType: 'material',
            data: {
                permissions: {
                    'only' : ['canReadResourcesMaterialQuotes']
                }
            }
        })
        .when('/material-quote-request/:id', {
            title: 'Material quote requests',
            template: require('../modules/quote-request/quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isSingle: true,
            quoteType: 'material',
            data: {
                permissions: {
                    'only' : ['canReadResourcesMaterialQuotes']
                }
            }
        })
        .when('/new-material-quote-request', {
            title: 'New material quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isNew: true,
            quoteType: 'material',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesMaterialQuotes']
                }
            }
        })
        .when('/edit-material-quote-request/:id', {
            title: 'Edit material quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isEdit: true,
            quoteType: 'material',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesMaterialQuotes']
                }
            }
        })
        .when('/labour-quote-requests', {
            title: 'Labour quote requests',
            template: require('../modules/quote-request/quote-requests.html'),
            controller: 'QuoteRequestCtrl',
            isList: true,
            quoteType: 'labour',
            data: {
                permissions: {
                    'only' : ['canReadLabourLabourBill']
                }
            }
        })
        .when('/labour-quote-request/:id', {
            title: 'Labour quote request',
            template: require('../modules/quote-request/quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isSingle: true,
            quoteType: 'labour',
            data: {
                permissions: {
                    'only' : ['canReadLabourLabourBill']
                }
            }
        })
        .when('/new-labour-quote-request', {
            title: 'New labour quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isNew: true,
            quoteType: 'labour',
            data: {
                permissions: {
                    'only' : ['canWriteLabourLabourBill']
                }
            }
        })
        .when('/edit-labour-quote-request/:id', {
            title: 'Edit labour quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isEdit: true,
            quoteType: 'labour',
            data: {
                permissions: {
                    'only' : ['canWriteLabourLabourBill']
                }
            }
        })
        .when('/sf-quote-requests', {
            title: 'Supply &amp; fit quote requests',
            template: require('../modules/quote-request/quote-requests.html'),
            controller: 'QuoteRequestCtrl',
            isList: true,
            quoteType: 'sf',
            data: {
                permissions: {
                    'only' : ['canReadResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/sf-quote-request/:id', {
            title: 'Supply &amp; fit quote request',
            template: require('../modules/quote-request/quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isSingle: true,
            quoteType: 'sf',
            data: {
                permissions: {
                    'only' : ['canReadResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/new-sf-quote-request', {
            title: 'New supply &amp; fit quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isNew: true,
            quoteType: 'sf',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/edit-sf-quote-request/:id', {
            title: 'Edit supply &amp; fit quote request',
            template: require('../modules/quote-request/new-quote-request.html'),
            controller: 'QuoteRequestCtrl',
            isEdit: true,
            quoteType: 'sf',
            data: {
                permissions: {
                    'only' : ['canWriteResourcesSupplyFitQuotes']
                }
            }
        })
        .when('/labour-activities', {
            title: 'Labour activities',
            template: require('../modules/labour-activity/labour-activities.html'),
            controller: 'LabourActivityCtrl',
            isList: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesLabourActivities']
                }
            }
        })
        .when('/labour-activity/:id', {
            title: 'Labour activity',
            template: require('../modules/labour-activity/labour-activity.html'),
            controller: 'LabourActivityCtrl',
            isSingle: true,
            data: {
                permissions: {
                    'only' : ['canReadResourcesLabourActivities']
                }
            }
        })
        .when('/new-labour-activity', {
            title: 'New labour activity',
            template: require('../modules/labour-activity/new-labour-activity.html'),
            controller: 'LabourActivityCtrl',
            isNew: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesLabourActivities']
                }
            }
        })
        .when('/edit-labour-activity/:id', {
            title: 'Edit labour activity',
            template: require('../modules/labour-activity/new-labour-activity.html'),
            controller: 'LabourActivityCtrl',
            isEdit: true,
            data: {
                permissions: {
                    'only' : ['canWriteResourcesLabourActivities']
                }
            }
        });
    return $routeProvider;
};
