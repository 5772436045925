(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.material
	 * @description
	 * # material
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	    .factory('configurableMaterial', ['ENV', 'Resource', 'UNITSTEXT', function (ENV, $resource) {

	        return $resource(
	            ENV.apiEndpoint + '/configurablematerials/:id', {id: '@id'}, {
	                customSave: {
	                    transformRequest: function(data){
	                        var newObj = {
	                            quantityUnit: data.quantityUnit,
	                            wasteAllowancePercentage: data.wasteAllowancePercentage,
		                        name: data.name,
		                        brand: data.brand,
                                materialType: data.materialType,
                                specifics: data.specifics,
		                        singularName: data.singularName,
		                        singularSize: data.singularSize,
		                        packName: data.packName,
		                        packSize: data.packSize,
                                externalLinks: [],
                                workPackages: [],
                                configurations:[],
                                copyFromId: data.copyFromId
                            };

                            angular.forEach(data.externalLinks, function(value){
                                newObj.externalLinks.push({url: value.url, label: value.label});
                            });

		                    if( typeof data.workPackages[0] === 'object' ){
								angular.forEach( data.workPackages, function(value){
									if( value.id !== 'null' ){
										newObj.workPackages.push( value.id );
									}
								});
								newObj.workPackages = newObj.workPackages.filter(function(n){ return n !== undefined; });
		                    }else{
								data.workPackages = data.workPackages.filter(function(n){ return n !== undefined; });
								newObj.workPackages = data.workPackages;
		                    }

		                    if( data.dataSheets ){
			                    newObj.dataSheets = [];
			                    angular.forEach(data.dataSheets, function(datasheet){
				                    newObj.dataSheets.push(datasheet.id);
			                    });
		                    }

                            if(typeof data.materialType[0] === 'object' ){
                                newObj.materialType = data.materialType[0].id;
                            }

							if(data.configurations) {
								angular.forEach( data.configurations, function(configuration){
									newObj.configurations.push( {
										name: configuration.name,
										packSize: configuration.packSize,
										singularSize: configuration.singularSize
									} );
								});
							}

	                        return angular.toJson(newObj);
	                    }
	                }
	            }
	        );

	    }]);
})();
