'use strict';

angular.module('scswebappApp')
    .directive('errorListModal', function () {
        return {
            templateUrl: "scripts/directives/modals/error-list-modal/error-list-modal.html",
            restrict: 'E',
            scope: {
                'control': '=',
                'errors': '='
            },
            link: {
              pre: function preLink(scope, element, attrs) {
                scope.control = {};
              }
            }
        };
    });
