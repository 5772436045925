(function(){
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:MainCtrl
     * @description
     * # MainCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('MainCtrl', function ($scope, ENV) {

        });
})();
