'use strict';

/**
 * Usage: <input type="checkbox" checkbox-indeterminate><input>
 * data-custom-text is not required and will fall back to "Back"
 */
angular.module('scswebappApp')
    .directive('checkboxIndeterminate', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function postLink(scope,element,attrs) {
                scope.$watch(attrs.ngModel, function(newVal){
                    var indeterminate = false;
                    if (newVal === "indeterminate"){
                        indeterminate = true;
                    }

                    element.prop("indeterminate", indeterminate);
                })
            }
        };
    });
