'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.notification
 * @description
 * # notification
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
    .factory('materialtype', ['Resource', 'ENV', function ( $resource, ENV ) {
        return $resource(
            ENV.apiEndpoint + '/materialtypes/:id', { id: '@id' }, {
				archive: {
					method: 'PATCH',
					url: ENV.apiEndpoint + '/materialtypes/:id/archive'
				},
				unarchive: {
					method: 'PATCH',
					url: ENV.apiEndpoint + '/materialtypes/:id/unarchive'
				}
            }
        );
    }]);
