(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.card
	 * @description
	 * # card
	 * Service in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .service('card', ['Resource','ENV', function ($resource, ENV) {
	        return $resource(
	            ENV.apiEndpoint + '/operativecardings/:id', { id: '@id' }, {
	            }
	        );
	  }]);
})();