(function(){
    'use strict';
    angular.module('scswebappApp')
        .filter('firstPermissibleRoute', function(PermPermissionStore, $route) {
            return function(routesToCheck, returnTitle = false) {
                var permittedRoute = null;
                var notExcluded = function (route) {
                    var excluded = false;
                    angular.forEach(route.data.permissions.except, function (permissionExcept) {
                        if (PermPermissionStore.hasPermissionDefinition(permissionExcept)) {
                            excluded = true;
                        }
                    });
                    return !excluded;
                };

                // For each route supplied in argument...
                angular.forEach(routesToCheck, function (routeToCheck) {
                    if (!permittedRoute) {
                        // Search for it in the router...
                        let matchingRoute;
                        angular.forEach($route.routes, function (routerRoute) {
                            if (!permittedRoute && routerRoute.regexp && routerRoute.regexp.test(routeToCheck)) {
                                matchingRoute = routerRoute;
                                if (matchingRoute.hasOwnProperty('data')) {
                                    // This route matches; check permissions
                                    if (!matchingRoute.data.permissions.only.length && notExcluded(matchingRoute)) {
                                        // This route has no permission requirements, but check we're also not excluded
                                        permittedRoute = matchingRoute;
                                    }
                                    angular.forEach(matchingRoute.data.permissions.only, function (permissionOnly) {
                                        if (!permittedRoute) {
                                            if (PermPermissionStore.hasPermissionDefinition(permissionOnly) && notExcluded(matchingRoute)) {
                                                // This route is permitted by the user's permissions, and we're not excluded
                                                permittedRoute = returnTitle ? matchingRoute.title : routeToCheck;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
                return permittedRoute;
            };
        });
})();
