(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProjectSingleCtrl
     * @description
     * # ProjectCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ApplicationOutgoingBillCtrl', function ($locale, $scope, $rootScope, $routeParams, $route, $http, $window, ENV, project, productApplication, FileUploader, $filter) {
            $scope.currentApplicationItems = [];
            $scope.directionOutgoing = true;
            $scope.measuredCosts = true;
            $scope.assessment= null;

            $scope.switchApplicationType = function () {
                $scope.measuredCosts = !$scope.measuredCosts;
            };
            $scope.setAssessment = function (assessment) {
                $scope.assessment = assessment;
            }

            $scope.projectId = $rootScope.projectId = $routeParams.id;
            project.get({id: $routeParams.id}, {onlygroups: 'basic,hub'}).$promise.then(function (data) {
                $scope.project = data;
            });
            $scope.saveApplication = function () {
                var items = [];
                angular.forEach(Object.keys($scope.currentApplicationItems), function (key) {
                    var item = $scope.currentApplicationItems[key];

                    angular.forEach(item.components, function (component) {
                        if (component.completion > 0) {
                            items.push({
                                workScopeStage: component.workScopeStage,
                                assessmentZone: item.zoneId,
                                quantity: component.completion,
                                rate: component.rate
                            });
                        }
                    });
                });

                productApplication.save({}, {
                    productItems: items,
                    direction: $scope.directionOutgoing ? 'outgoing' : 'incoming',
                    project: $scope.project.id//,
                }).$promise.then(function () {
                    //$route.reload();
                });
            };

            $scope.$on('taxCodeSelected', function(e,args){
                var assessmentId = args.element.attributes['data-assessment-id'].value;
                angular.forEach($scope.liveAssessments, function (assessment) {
                    if (assessment.id == assessmentId) {
                        assessment.vatTaxCode = args.item;
                    }
                });
                angular.forEach($scope.appPaymentForm.assessments, function (assessment, assessmentId2) {
                    if (assessmentId2 == assessmentId) {
                        assessment.vatTaxCode = args.item;
                    }
                });
                $scope.updateCertifiedTotal();
            });

            $rootScope.$on('applicationFixedCostItemAdded', function (e, item) {
                $scope.currentApplicationItems[item.cellCoordinate].thisApplicationValue = '-';
                productApplication.addFixedCostItem({projectId: $scope.project.id}, {
                    assessmentFixedCost: item.item.id,
                    quantity: item.item.quantity
                }).$promise.then(function (data) {
                    $scope.currentApplicationItems[item.cellCoordinate].thisApplicationValue = data.thisApplication;
                    $scope.$broadcast('applicationBillRefreshZoneValues');
                });
            });

            $rootScope.$on('applicationItemAdded', function (e, item) {
                var items = [];
                $scope.currentApplicationItems[item.cellCoordinate].thisApplicationValue = '-';
                angular.forEach(item.item.components, function (component) {
                    items.push({
                        "quantity": component.completion,
                        "workScopeStage": component.workScopeStage,
                        "assessmentZone": item.item.assessmentZone
                    });
                });
                productApplication.addItems({projectId: $scope.project.id, direction: $scope.directionOutgoing ? 'outgoing' : 'incoming'}, {items: items}).$promise.then(function (data) {
                    $scope.currentApplicationItems[item.cellCoordinate].thisApplicationValue = data.thisApplication;
                    $scope.$broadcast('applicationBillRefreshZoneValues');
                });
            });

            if ($route.current.$$route.isList) {
                $scope.titleApplicationType = $route.current.$$route.titleApplicationType;
                $scope.applicationStates = $route.current.$$route.applicationStates;
                $scope.direction = $route.current.$$route.applicationDirection;
                $scope.tableServiceParams = {
                    project: $rootScope.projectId,
                    'direction': $scope.direction,
                    'state[]': $scope.applicationStates
                };
            }
        });
})();
