(function(){
	'use strict';

	/**
	 * @ngdoc service
	 * @name scswebappApp.organisation
	 * @description
	 * # organisation
	 * Factory in the scswebappApp.
	 */
	angular.module('scswebappApp')
	  .factory('organisation', ['Resource', 'ENV', function ( $resource, ENV ) {
	        return $resource(
	            ENV.apiEndpoint + '/organisations/:id', { id: '@id' }, {
			        update: {
				        method: 'PUT',
				        transformRequest: function (data) {
					        var newData = angular.fromJson(data);
					        var clientData = {
						        'fullName': newData.fullName,
						        'emailAddresses': newData.emailAddresses,
						        'telephoneNumbers': newData.telephoneNumbers,
						        'addresses': newData.addresses,
                                'parent': data.parent
					        };
                            angular.forEach(clientData.addresses, function(value, key){
                                delete clientData.addresses[key].id;
                                delete clientData.addresses[key].contact;
                                delete clientData.addresses[key].latitude;
                                delete clientData.addresses[key].longitude;
                            });
                            angular.forEach(clientData.telephoneNumbers, function(value, key){
                                delete clientData.telephoneNumbers[key].id;
                                delete clientData.telephoneNumbers[key].label;
                            });
                            angular.forEach(clientData.emailAddresses, function(value, key){
                                delete clientData.emailAddresses[key].id;
                                delete clientData.emailAddresses[key].label;
                            });
					        return angular.toJson(clientData);
				        }
			        }
	            }
	        );
	  }]);
})();