'use strict';

angular.module('scswebappApp')
    .directive('projectWorkPackageList', function () {

        return {
            template: require("./project-work-package-list.html"),
            restrict: 'E',
            scope: {
                'multiSelect': '=?',
                'highlightedItems': '=?',
                'pinnedItems': '=?',
                'projectId': '@?',
                'tableIdentifier': '@?',
                'mode': '@'
            },
            link: function postLink(scope, element, attrs) {
                scope.activeOperativeId = null;

                var validModes = ['assigned', 'unassigned'];
                var serviceMethod = 'query';
                var customQuery = null;

                if (!scope.multiSelect){
                    scope.multiSelect = false;
                }

                if (!scope.tableIdentifier){
                    scope.tableIdentifier = 'ProjectWorkPackageList';
                }

                if (scope.projectId) {
                    if (Object.values(validModes).indexOf(scope.mode) === -1) {
                        throw "No valid mode parameter supplied for project-work-package-list";
                    }

                    customQuery = {project: scope.projectId};

                    if (scope.mode === 'unassigned') {
                        serviceMethod = 'getProjects';
                    }
                }

                scope.$on('listReload' + scope.tableIdentifier, function(){
                    scope.$broadcast('tableViewReload' + scope.tableIdentifier);
                });

                scope.$on('taxCodeSelected', function(e,args){
                    var propertyName = args.element.attributes['model'].value.split(".")[1];
                    scope.$emit('projectTaxCodeSelected',{
                        taxCode: args.item,
                        projectWorkPackage: args.parentObject,
                        propertyName: propertyName
                    });
                });


                scope.tableConfig = {
                    config: {
                        tableIdentifier: scope.tableIdentifier,
                        service: 'projectWorkPackage',
                        serviceMethod: serviceMethod,
                        customQuery: customQuery,
                        itemsPerPage: 1000,
                        multiSelect: scope.multiSelect,
                        emitEvent: true,
                        sortBy: 'workPackage.name',
                        hidePagination: true,
                        disableDisclosure: true
                    },
                    cols: [
                        {
                            text: 'Name',
                            data: 'workPackage.name'
                        },
                        {
                            text: 'Application revenues',
                            permissionOnly: 'canReadAccountsVATCodes',
                            template: "scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-application-revenues.html",
                            emitGenericClickEventAllowPropagation: true
                        },
                        {
                            text: 'Supply & fit costs',
                            permissionOnly: 'canReadAccountsVATCodes',
                            template: "scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-supply-and-fit-costs.html",
                            emitGenericClickEventAllowPropagation: true
                        },
                        {
                            text: 'Material costs',
                            permissionOnly: 'canReadAccountsVATCodes',
                            template: "scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-material-costs.html",
                            emitGenericClickEventAllowPropagation: true
                        },
                        {
                            text: 'Fixed costs',
                            permissionOnly: 'canReadAccountsVATCodes',
                            template: "scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-fixed-costs.html",
                            emitGenericClickEventAllowPropagation: true
                        },
                        {
                            text: 'Labour costs',
                            permissionOnly: 'canReadAccountsVATCodes',
                            template: "scripts/directives/lists/project-work-package-list/_partials/table-cell-vat-labour-costs.html",
                            emitGenericClickEventAllowPropagation: true
                        },
                        {
                            text: '',
                            ignoreClick: true,
                            sort: false,
                            template: "scripts/directives/lists/_partials/table-cell-row-delete.html"
                        }
                    ]
                };
            }
        };
    });
