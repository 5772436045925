import {CsUtils} from "../../other/csutils";

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:SuppliersCtrl
     * @description
     * # SuppliersCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('SupplierCtrl', function ($scope, supplier, $rootScope, $routeParams, $route, routeType, $location, organisation, people, $http, $window, ENV ) {

            $scope.suppliers = [];
            $scope.newAddress = {};

            $scope.exportSuppliers = function () {
                $http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: ENV.apiEndpoint + '/suppliers/exportall'
                }).success(function (data, status, headers) {
                    CsUtils.download(data, headers, $window);
                });
            };

            function getSupplierData() {
                supplier.get({id: $routeParams.id}).$promise.then(function (data) {
                    return $scope.supplier = data;
                });
            }

            // Get trade data if edit or view routes
            if ($routeParams.id) {
                getSupplierData();
            }

            // Set type keyword for edit and new routes
            $scope.keyWord = routeType.type($route.current.$$route.isNew, $route.current.$$route.isEdit);

            $scope.newEmail = null;
            $scope.newTel = null;
            $scope.newAddress = {};
            $scope.addToData = function (e, objectName, data) {
                var duplicate,
                    newData=null;
                $scope.errors = [];

                // If supplier object doesn't exist, create it.
                $scope.supplier = ($scope.supplier) ? $scope.supplier : {};

                //validation for address
                if (objectName === 'addresses') {
                    $scope.submitted = true;
                    $scope.validate = true;

                    if (Object.keys(data).length === 0) {
                        return;
                    }

                    //minimum require fields for address
                    var addressKey = ['street1', 'city', 'postcode'];

                    for (let key of addressKey) {
                        if (!(data.hasOwnProperty(key) && data[key].length > 0)) {
                            return;
                        }
                    }
                }

                // Create array
                $scope.supplier[objectName] = ($scope.supplier[objectName]) ? $scope.supplier[objectName] : [];

                // Add data to the array
                function addData(objectName, data) {
                    $scope.supplier[objectName].push(data);
                    $scope.newEmail = null;
                    $scope.newTel = null;
                    $scope.newAddress = {};
                }

                // Check for duplicate data
                function duplicateCheck(objectName, data) {
                    angular.forEach($scope.supplier[objectName], function (value) {
                        if (angular.toJson(value) === angular.toJson(data)) {
                            duplicate = true;
                            $scope.errors[objectName] = true;
                        }
                    });
                    return duplicate;
                }

                // Add data to array if it exists
                if (data && objectName !== 'addresses') {
                    newData = {'detail': data};
                } else if (objectName === 'addresses'){
                    newData = {
                        'street1': data.street1,
                        'street2': data.street2,
                        'street3': data.street3,
                        'city': data.city,
                        'county': data.county,
                        'postcode': data.postcode,
                        'country': data.country
                    };
                }
                if (newData !== null && !duplicateCheck(objectName, newData)) {
                    addData(objectName, newData);
                }

                e.preventDefault();
            };

            $scope.remove = function (objectName, data) {
                angular.forEach($scope.supplier[objectName], function (value, key) {
                    if (value.detail === data) {
                        delete $scope.supplier[objectName].splice([key], 1);
                    }
                });
            };

            if ($routeParams.id && $route.current.$$route.isEdit) {
                $scope.showProducts = true;

                supplier.get({id: $routeParams.id}, function (data) {
                    $scope.supplier = data;
                });

            } else {
                $scope.supplier = {};
            }

            $scope.saveSupplier = function () {
                $scope.submitted = true;
                var editPage = !!$route.current.$$route.isEdit;
                $scope.validate = true;

                if (editPage || (!editPage && (($scope.supplier.addresses !== undefined && $scope.supplier.addresses.length > 0) && $scope.supplier.addresses[0].street1 && $scope.supplier.addresses[0].city && $scope.supplier.addresses[0].postcode))) {
                    $scope.validate = false;
                }

                if ($scope.supplierForm.$valid && !$scope.validate) {
                    var saveObj = new supplier($scope.supplier);
                    saveObj.$save().then(function () {
                        $scope.supplierForm.$setPristine();
                        $scope.submitted = false;
                        $location.path('/suppliers');
                    });
                } else if ($scope.supplier.addresses === undefined || $scope.supplier.addresses.length === 0) {
                    alert("Please click on 'Add address' button to save the address.");
                }
            };
        });
})();
