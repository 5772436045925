(function () {
    "use strict";

    /**
     * @ngdoc service
     * @name scswebappApp.assessment
     * @description
     * # assessment
     * Factory in the scswebappApp.
     */
    angular.module("scswebappApp")
        .factory("assessment", function ($resource, ENV) {

            return $resource(
                ENV.apiEndpoint + "/assessments/:id", {
                    id: "@id",
                    projectId: "@projectId",
                    targetPrice: "@targetPrice",
                    movingProductId: "@movingProductId",
                    referenceProductId: "@referenceProductId",
                    transition: "@transition",
                    section: "@section",
                    originalMaterialId: "@originalMaterialId",
                    oldPriceId: "@oldPriceId",
                    configId: "@configId",
                    assessmentId: "@assessmentId",
                    quoteId: "@quoteId",
                    assessmentProductId: "@assessmentProduct",
                    conversionFactor: "@conversionFactor",
                    mode: "@mode",
                    fileId: "@fileId"
                }, {
                    addAssessmentProducts: {
                        url: ENV.apiEndpoint + "/assessmentproducts/:id",
                        method: "POST"
                    },
                    patchAssessmentProducts: {
                        url: ENV.apiEndpoint + "/assessmentproducts/:id",
                        method: "PATCH"
                    },
                    getAssessmentProducts: {
                        url: ENV.apiEndpoint + "/assessments/:id/products",
                        method: "GET",
                        isArray: true
                    },
                    getAssessmentProductsForProgress: {
                        url: ENV.apiEndpoint + "/projects/:projectId/product-progress-groups",
                        method: "GET"
                    },
                    getAssessmentProduct: {
                        url: ENV.apiEndpoint + "/assessmentproducts/:id",
                        method: "GET"
                    },
                    getAssessmentProductsList: {
                        url: ENV.apiEndpoint + "/assessmentproducts",
                        method: "GET",
                        isArray: true
                    },
                    deleteAssessmentProduct: {
                        url: ENV.apiEndpoint + "/assessmentproducts/:id",
                        method: "DELETE"
                    },
                    patchLabourComponent: {
                        url: ENV.apiEndpoint + "/assessmentlabourcomponents/:id",
                        method: "PATCH"
                    },
                    saveAssessmentQuantity: {
                        url: ENV.apiEndpoint + "/assessmentquantities",
                        method: "POST"
                    },
                    updateAssessmentQuantity: {
                        url: ENV.apiEndpoint + "/assessmentquantities/:id?groups=parentQuantity",
                        method: "PATCH"
                    },
                    getAssessmentQuantities: {
                        url: ENV.apiEndpoint + "/assessments/:id/quantities",
                        method: "GET",
                        isArray: true
                    },
                    updateAssessmentEstimates: {
                        url: ENV.apiEndpoint + "/assessments/:id/estimate",
                        method: "GET",
                        isArray: false
                    },
                    getAssessmentFixedCosts: {
                        url: ENV.apiEndpoint + "/assessments/:id/fixedcosts",
                        method: "GET",
                        isArray: true
                    },
                    getAssessmentProductEstimate: {
                        url: ENV.apiEndpoint + "/assessmentproducts/:id/estimate",
                        method: "GET"
                    },
                    patchAssessment: {
                        url: ENV.apiEndpoint + "/assessments/:id",
                        method: "PATCH"
                    },
                    checkAssessmentRbuValid: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/assessments/:id/valid-rbu"
                    },
                    swapProduct: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentproducts/:id/swap"
                    },
                    setAssessmentTotal: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/target-price/:targetPrice"
                    },
                    resetAssessmentTotal: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/unfix-products"
                    },
                    addAdjustment: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentadjustments"
                    },
                    getAdjustments: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/assessments/:id/adjustments",
                        isArray: true
                    },
                    deleteAdjustment: {
                        method: "DELETE",
                        url: ENV.apiEndpoint + "/assessmentadjustments/:id",
                        isArray: true
                    },
                    updateAdjustment: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/assessmentadjustments/:id"
                    },
                    changeOrder: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessmentproducts/move/:movingProductId/after/:referenceProductId"
                    },
                    billOfQuantities: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/projects/:projectId/assessments/:id/bill-of-quantities"
                    },
                    quoteRequest: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/assessments/:id/quoterequest"
                    },
                    blanketProfit: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/blanket-percentages/profitPercentage",
                        isArray: true
                    },
                    duplicateAssessment: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/copyof/:id"
                    },
                    assessmentProductAsProduct: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/assessmentproducts/:id/as-product"
                    },
                    progressSection: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/:transition-:section"
                        // transition: start|complete
                        // section: rate-buildup|quantification|fixedcosts
                    },
                    archive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/assessments/:id/archive"
                    },
                    unarchive: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + "/assessments/:id/unarchive"
                    },
                    patchMaterialPrice: {
                        method: "PATCH",
                        isArray: true,
                        url: ENV.apiEndpoint + "/assessmentmaterials/multi-update?assessment=:id&originalMaterial=:originalMaterialId&selectedPrice=:oldPriceId&selectedConfiguration=:configId"
                    },
                    isDirty: {
                        method: "GET",
                        url: ENV.apiEndpoint + "/assessments/:id/dirty",
                        noLoader: true
                    },
                    addContingencyMaterial: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/contingency-material"
                    },
                    removeContingencyMaterial: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/remove-contingency-material"
                    },
                    addContingencyFixedCost: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/contingency-fixed-cost"
                    },
                    removeContingencyFixedCost: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/remove-contingency-fixed-cost"
                    },
                    addContingencyLabourActivity: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/contingency-labour-activity"
                    },
                    removeContingencyLabourActivity: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/remove-contingency-labour-activity"
                    },
                    getLabourActivityApplied: {
                        method: "GET",
                        isArray: true,
                        url: ENV.apiEndpoint + '/assessments/:assessment/labour-activities/:activity/application-status'
                    },
                    applyMaterialQuote: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/apply-material-quote/:quoteId",
                        isArray: false
                    },
                    applyLabourQuote: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/apply-labour-quote/:quoteId",
                        isArray: false
                    },
                    applySupplyAndFitQuote: {
                        method: "POST",
                        url: ENV.apiEndpoint + "/assessments/:id/apply-saf-quote/:quoteId",
                        isArray: false
                    },
                    updateContractScope: {
                        method: 'PATCH'
                    },
                    rbuValidationMessages: {
                        method: 'GET',
                        isArray: false,
                        url: ENV.apiEndpoint + "/assessments/:id/rbu-issues"
                    },
                    blanketPricingMode: {
                        method: 'POST',
                        isArray: true,
                        url: ENV.apiEndpoint + "/assessments/:id/blanket-pricing-mode/:mode"
                    },
                    clientBillImport: {
                        method: 'POST',
                        url: ENV.apiEndpoint + "/assessments/:id/client-bill-import/:fileId"
                    },
                    billOfQuantitiesImport: {
                        method: 'POST',
                        url: ENV.apiEndpoint + "/projects/:projectId/assessments/:id/bill-of-quantities-import/:fileId"
                    },
                    quantityInTakeoff: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/assessmentproducts/:id/quantity-in-takeoff'
                    },
                }
            );

        });
})();
