'use strict';

angular.module('scswebappApp')
    .directive('userList', function () {

        return {
            template: "<table-view data-config=\"{{tableConfig}}\" pinned-items=\"pinnedItems\" highlighted-items=\"highlightedItems\"></table-view>",
            restrict: 'E',
            scope: {
                'multiSelect': '=?',
                'highlightedItems': '=?',
                'pinnedItems': '=?',
                'projectId': '@?',
                'tableIdentifier': '@?',
                'mode': '@'
            },
            link: function postLink(scope, element, attrs) {

                var validModes = ['assigned', 'unassigned'];
                var serviceMethod = 'query';
                var customQuery = null;

                if (!scope.multiSelect){
                    scope.multiSelect = false;
                }

                if (!scope.tableIdentifier){
                    scope.tableIdentifier = 'UserList';
                }

                if (scope.projectId) {
                    if (Object.values(validModes).indexOf(scope.mode) === -1) {
                        throw "No valid mode parameter supplied for user-list";
                    }

                    customQuery = {project: scope.projectId};

                    if (scope.mode === 'unassigned') {
                        serviceMethod = 'getProjectUnassigned';
                    }
                }

                scope.tableConfig = {
                    config:{
                        service: 'user',
                        serviceMethod: serviceMethod,
                        customQuery: customQuery,
                        singlePageUrl: 'user',
                        singlePageId: 'username',
                        sortBy: 'lastName',
                        itemsPerPage: 50,
                        canFilter: true,
                        multiSelect: scope.multiSelect,
                        emitEvent: true,
                        tableIdentifier: scope.tableIdentifier
                    },
                    cols:[
                        {
                            text:'Username',
                            data: 'username',
                            hasTextFilter: true,
                            textFilterOverride: 'uname'
                        },
                        {
                            text:'Name',
                            data: 'person.fullName',
                            sortFilterOverride: 'personFullName'
                        },
                        {
                            text:'Email',
                            data: 'email'
                        },
                        {
                            text: 'Roles',
                            object: 'roles',
                            objectCols: [
                                {
                                    data: 'role'
                                }
                            ]
                        }
                    ]
                };
            }
        };
    });
