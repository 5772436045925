import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {BadgeLevel} from "@generic/badge/badge.component";
import {RateBuildUpIssueCode, RateBuildUpIssueCount, RateBuildUpIssueSeverity} from "./rate-build-up-issue.service";

@Component({
    template: `
        <badge [level]="severityToBadgeLevel(issue.severity)"
               [ngClass]="{clickable: clickable}"
               (click)="onClick(issue)"
        >{{codeToBadgeString(issue.code, issue.count)}}</badge>
    `,
    selector: 'rbu-issue-flag',
    styles: [
        'badge.clickable { cursor: pointer }'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateBuildUpIssueFlagComponent
{
    @Input() issue: RateBuildUpIssueCount;
    @Input() clickable: boolean;
    @Output() issueClicked: EventEmitter<RateBuildUpIssueCount> = new EventEmitter<RateBuildUpIssueCount>();

    onClick(i: RateBuildUpIssueCount)
    {
        if (this.clickable) this.issueClicked.emit(i);
    }

    severityToBadgeLevel(s: RateBuildUpIssueSeverity): BadgeLevel
    {
        switch (s) {
            case RateBuildUpIssueSeverity.Advisory:
                return BadgeLevel.Info;
            case RateBuildUpIssueSeverity.Warning:
                return BadgeLevel.Warning;
            case RateBuildUpIssueSeverity.PreventTender:
                return BadgeLevel.Error;
            default:
                return null;
        }
    }

    codeToBadgeString(c: RateBuildUpIssueCode, count: number): string
    {
        const singularCount = (1 === count ? '' : ': ' + count.toString());
        const pluralCount =  (1 === count ? '' : 's: ' + count.toString());
        switch (c) {
            case RateBuildUpIssueCode.ProductEmpty:
                return 'Empty product' + pluralCount;
            case RateBuildUpIssueCode.ProductNoOverhead:
                return 'Missing overhead' + singularCount;
            case RateBuildUpIssueCode.ProductNoProfit:
                return 'Missing profit' + singularCount;
            case RateBuildUpIssueCode.ProductNoInflation:
                return 'Missing inflation' + singularCount;
            case RateBuildUpIssueCode.ProductVariation:
                return 'Variation' + pluralCount;
            case RateBuildUpIssueCode.ProductSkeleton:
                return 'Empty product' + (1 === count ? '' : 's') + ' needing replacement'
                    + (1 === count ? '' : ': ' + count.toString() );
            case RateBuildUpIssueCode.ProductPostTenderContract:
                return 'Post tender' + singularCount;
            case RateBuildUpIssueCode.ProductClientHidden:
                return 'Hidden from applications' + singularCount;
            case RateBuildUpIssueCode.ProductSF:
                return 'Supply & fit' + singularCount;
            case RateBuildUpIssueCode.ProductSFBadWeights:
                return 'Invalid component weights' + singularCount;
            case RateBuildUpIssueCode.ProductSFNoPrice:
                return 'Missing supply & fit price' + pluralCount;
            case RateBuildUpIssueCode.ProductSFGuesstimate:
                return 'Supply & fit guesstimate' + pluralCount;
            case RateBuildUpIssueCode.ProductSFInactive:
                return 'Inactive supply & fit price' + pluralCount;
            case RateBuildUpIssueCode.ProductSFArchived:
                return 'Archived supply & fit price' + pluralCount;
            case RateBuildUpIssueCode.ComponentEmpty:
                return 'Empty component' + pluralCount;
            case RateBuildUpIssueCode.ComponentNoContingency:
                return 'Missing component contingency' + singularCount;
            case RateBuildUpIssueCode.ActivityNoRate:
                return 'Missing labour rate' + pluralCount;
            case RateBuildUpIssueCode.ActivityGuesstimate:
                return 'Labour guesstimate' + pluralCount;
            case RateBuildUpIssueCode.ActivityInactive:
                return 'Inactive labour price' + pluralCount;
            case RateBuildUpIssueCode.ActivityArchived:
                return 'Archived labour price' + pluralCount;
            case RateBuildUpIssueCode.MaterialNoPrice:
                return 'Missing material price' + pluralCount;
            case RateBuildUpIssueCode.MaterialGuesstimate:
                return 'Material guesstimate' + pluralCount;
            case RateBuildUpIssueCode.MaterialInactive:
                return 'Inactive material price' + pluralCount;
            case RateBuildUpIssueCode.MaterialArchived:
                return 'Archived material price' + pluralCount;
            case RateBuildUpIssueCode.MaterialNoWaste:
                return 'Missing waste allowance' + singularCount;
            default:
                return 'Other';
        }
    }
}
