(function(){
  'use strict';

  /**
   * @ngdoc directive
   * @name scswebappApp.directive:stringToNumber
   * @description
   */
  angular.module('scswebappApp')
    .directive('selectOnClick', ['$window', function ($window) {
      return {
        restrict: 'A',
        link: function (scope, element) {
          var focusedElement;
          element.on('click', function () {
            if (focusedElement != this) {
              this.select();
              focusedElement = this;
            }
          });
          element.on('blur', function () {
            focusedElement = null;
          });
        }
      };
    }]);
})();
