'use strict';

/**
 * @ngdoc service
 * @name scswebappApp.client
 * @description
 * # client
 * Factory in the scswebappApp.
 */
angular.module('scswebappApp')
    .factory('client', ['Resource', 'ENV', function ($resource, ENV) {
        return $resource(
            ENV.apiEndpoint + '/clients/:id', { id: '@id' }, {
                archive: {
                    method: 'PATCH',
                    url: ENV.apiEndpoint + '/clients/:id/archive'
                },
                unarchive: {
                    method: 'PATCH',
                    url: ENV.apiEndpoint + '/clients/:id/unarchive'
                },
                customSave:{
                    method: 'PUT',
                    transformRequest: function (data) {
                        var newData = angular.fromJson(data);

                        if( newData.addresses && newData.addresses[0] ){
                            delete newData.addresses[0].id;
                            delete newData.addresses[0].contact;
                            delete newData.addresses[0].latitude;
                            delete newData.addresses[0].longitude;
                        }
                        var clientData = {
                            'fullName': newData.fullName,
                            'accountRef': newData.accountRef,
                            'addresses': newData.addresses,
                            'emailAddresses': [],
                            'telephoneNumbers': []
                        };

                        angular.forEach(newData.emailAddresses, function( value ){
                            if( value.detail !== '' ){
                                clientData.emailAddresses.push( {'detail': value.detail } );
                            }
                        });

                        angular.forEach(newData.telephoneNumbers, function( value ){
                            if( value.detail !== '' ){
                                clientData.telephoneNumbers.push( {'detail': value.detail } );
                            }
                        });
                        return angular.toJson(clientData);
                    }
                }
            },
            'fullName'  // default sort for query
        );
    }]);
