'use strict';

import {MediaDirective} from "@generic/media.directive";

angular.module('coreNgUser', [])
    .constant('$coreUsersConfig', {
        'loginUrl': '/oauth/v2/token',
        'requestPasswordUrl': '/users/passwordreset',
        'logoutRedirectPath': '/',
        'clientId': '1_5063jshe0pcsosssosggsgc4s884so44c0k0kwwswoccs00k08'
    })
    .config(function ($provide, $httpProvider) {
        // Add the interceptor to the $httpProvider.
        $httpProvider.interceptors.push('interceptor');
    })
    .run(function ($rootScope, $http, coreNgUserFactory, $location, PermPermissionStore) {

        $rootScope.$on('$routeChangeStart', function (event, next) {
            // If logged in don't allow the user to go back to the login screen
            if ($rootScope.loggedIn && next && next.$$route && next.$$route.template && next.$$route.template.indexOf('<login>') > -1) {
                if (PermPermissionStore.hasPermissionDefinition('canReadProjects')) {
                    $location.path('/projects');
                } else if (PermPermissionStore.hasPermissionDefinition('canReadDashboard')) {
                    $location.path('/dashboard');
                } else {
                    $location.path('/projects');
                }
            }
        });

        try {
            $rootScope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        } catch (e) {
            console.log(e);
        }

        $rootScope.loggedIn = false;

        if (localStorage.getItem('access_token')) {
            $rootScope.loggedIn = true;
        } else if ($location.$$path !== '/request-password' && $location.$$path !== '/reset-password' && $location.$$path.indexOf('/reset-password/') !== 0) {
            $rootScope.loggedIn = false;
            $location.path('/');
            localStorage.clear();
        }

    })
    .directive('login', function (coreNgUserFactory, $location, $coreUsersConfig, $rootScope, $window, $http, PermPermissionStore) {
        return {
            template: '<form name="form" class="login-form" ng-submit="login()" novalidate>' +
                '<fieldset>' +
                '<label for="username">Email Address</label>' +
                '<input type="email" id="username" name="username" ng-model="data.username" placeholder="Email Address" required />' +
                '<p ng-show="form.username.$invalid && submitted" class="error">Please enter a valid email address</p>' +
                '<label for="password">Password</label>' +
                '<input type="password" id="password" name="password" ng-model="data.password" placeholder="Password" ng-minlength="4" required />' +
                '<p ng-show="form.password.$invalid && submitted" class="error">Please enter a password</p>' +
                '<div class="align-center">' +
                '<input type="submit" value="Login" class="btn" />' +
                '</div>' +
                '</fieldset>' +
                '</form>',
            restrict: 'E',
            link: function postLink(scope) {

                scope.login = function () {

                    scope.submitted = true;

                    if (scope.form.$valid) {
                        PermPermissionStore.clearStore();

                        scope.data.grant_type = 'password';
                        scope.data.client_secret = '5rhkgaacbu8s4gosks8os0swocg00kso4oc8scww0404gw0wcg';
                        scope.data.client_id = '1_5063jshe0pcsosssosggsgc4s884so44c0k0kwwswoccs00k08';

                        coreNgUserFactory.login({}, scope.data).$promise.then(function (data) {

                            var userDetails = JSON.stringify(data.user);

                            if (parseInt(data.user.account.id) !== parseInt(localStorage.getItem('previous_account_id'))) {
                                localStorage.clear();
                            }
                            // Set session token and user object in local storage
                            localStorage.setItem('previous_account_id', data.user.account.id);
                            localStorage.setItem('access_token', data.access_token);
                            localStorage.setItem('refresh_token', data.refresh_token);
                            localStorage.setItem('userDetails', userDetails);
                            $rootScope.userDetails = data.user;

                            // Call userpilot.identify()
                            userpilot.identify(data.user.id, {
                                name: data.user.person.fullName,
                                email: data.user.email,
                                created_at: data.user.createdAt,
                                company: {
                                    id: data.user.account.id, // Required, used to identify the company
                                    name: data.user.account.name,
                                    created_at: data.user.account.createdAt
                                }
                                // Additional user properties.
                                // is_trial: {{ request.user.is_trial }},
                                // plan: "{{ request.user.plan }}"
                            });

                            return data;
                        }).then(function (data) {
                            PermPermissionStore.clearStore();

                            var permissions = data.user.permissions;

                            PermPermissionStore.defineManyPermissions(permissions, /*@ngInject*/ function (permissionName) {
                                return permissions.indexOf(permissionName) !== false;
                            });

                            $window.location.reload();
                        }).then(function (data) {
                            // Set authheader on login as run only runs once on load, therefor headers wont work with session storage on login.
                            $http.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token');

                            // Redirect to first available destination
                            if (PermPermissionStore.hasPermissionDefinition('canReadProjects')) {
                                // Route to project list first
                                $location.path('/projects');
                            } else if (PermPermissionStore.hasPermissionDefinition('canReadDashboard')) {
                                // Route to dashboard if no project list
                                $location.path('/dashboard');
                            } else if (PermPermissionStore.hasPermissionDefinition('canReadTakeoffPage')) {
                                // Route to takeoff home if no dashboard access
                                $location.path('/takeoff/projects');
                            } else if (PermPermissionStore.hasPermissionDefinition('canReadAccountsOrders')) {
                                // Route to accounts orders list if no takeoff access
                                $location.path('/accounts/orders');
                            } else {
                                // Hail mary
                                $location.path('/projects');
                            }
                            $rootScope.loggedIn = true;
                            $rootScope.tokenRefreshing = false;
                        }).catch(function (e) {
                            $rootScope.$broadcast('showOrNotification', 'Please enter a valid Email Address and Password', true);
                        });
                    }

                };
            }
        };
    })
    .directive('logout', function ($location, $route, $coreUsersConfig, $rootScope) {
        return {
            template: '<a href="" ng-click="logout()"><i class="icon-export-outline"></i>{{text||\'Logout\'}}</a>',
            restrict: 'E',
            scope: {
                text: '=?'
            },
            link: function postLink(scope) {
                scope.logout = $rootScope.logout = function () {
                    const logout = () => {
                        localStorage.clear();

                        try {
                            let details = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null;
                            let previousAccount = details ? (details.account ? details.account.id : null) : null;
                            localStorage.setItem('previous_account_id', `${previousAccount}`);
                        } catch (e) {
                            console.log(e);
                        }

                        $rootScope.loggedIn = false;
                        // Change route to login screen
                        $location.path($coreUsersConfig.logoutRedirectPath);
                        $route.reload();
                    };

                    // On phone sized devices its too easy to accidentally hit the logout button, so
                    // ask the user to confirm
                    if (window.matchMedia(`(max-width: ${MediaDirective.mobileBreakpoint}`)) {
                        if (window.confirm('Do you want to log out?')) {
                            logout();
                        }
                    } else {
                        logout();
                    }
                };
            }
        };
    })
    .directive('requestpassword', function (coreNgUserFactory, $rootScope) {
        return {
            template: '<form name="form" class="login-form" ng-submit="requestPassword()" novalidate>' +
                '<fieldset>' +
                '<label for="email">Please enter your Email Address</label>' +
                '<input type="text" id="username" name="username" ng-model="data.username" placeholder="Email Address" required />' +
                '<div class="align-center"><input type="submit" value="Request password" class="btn" /></div>' +
                '</fieldset>' +
                '</form>',
            restrict: 'E',
            link: function postLink(scope) {
                scope.requestPassword = function () {
                    scope.submitted = true;
                    if (scope.form.$valid) {
                        coreNgUserFactory.requestPassword(null, scope.data).$promise.then(function () {
                            $rootScope.$broadcast('showOrNotification', 'An email has been sent with details on resetting your password');
                        }).catch(function (data) {
                            $rootScope.$broadcast('showOrNotification', 'Please enter a valid email address', true);
                        });
                    } else {
                        $rootScope.$broadcast('showOrNotification', 'Please enter a valid email address', true);
                    }
                };
            }
        };
    })
    .factory('coreNgUserFactory', function ($resource, $coreUsersConfig, ENV) {
        return $resource(
            $coreUsersConfig.baseUrl,
            {url: '@url'}, {
                login: {
                    method: 'POST',
                    url: ENV.apiEndpoint + $coreUsersConfig.loginUrl,
                    isArray: false,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                requestPassword: {
                    method: 'POST',
                    isArray: false,
                    url: ENV.apiEndpoint + $coreUsersConfig.requestPasswordUrl,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            }
        );
    })
    .factory('interceptor', function ($q, $location, $injector, $rootScope, $timeout) {
        return {
            request: function (request) {
                request.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
                return request;
            },
            // On response failure use refresh token
            responseError: function (rejection) {

                function redirectToLogin() {
                    $rootScope.loggedIn = false;
                    localStorage.clear();
                    $location.path('/');
                }

                if (rejection.status === 401) {


                    var deferred = $q.defer();
                    var $http = $injector.get('$http');

                    var AuthService = $injector.get('coreNgUserFactory');
                    var authData = {
                        'grant_type': 'refresh_token',
                        'refresh_token': localStorage.getItem('refresh_token'),
                        'client_secret': '5rhkgaacbu8s4gosks8os0swocg00kso4oc8scww0404gw0wcg',
                        'client_id': '1_5063jshe0pcsosssosggsgc4s884so44c0k0kwwswoccs00k08'
                    };

                    var retryRequest = function (config, deferred) {
                        function successCallback(response) {
                            deferred.resolve(response);
                        }

                        function errorCallback(response) {
                            deferred.reject(response);
                        }

                        $http(config).then(successCallback, errorCallback);
                    };

                    if (!$rootScope.tokenRefreshing) {
                        console.log('refreshing token');
                        $rootScope.tokenRefreshing = true;
                        AuthService.login({}, authData).$promise.then(function (data) {
                            // Set session token and user object in local storage
                            localStorage.setItem('access_token', data.access_token);
                            localStorage.setItem('refresh_token', data.refresh_token);
                            localStorage.setItem('userDetails', JSON.stringify(data.user));

                            userpilot.identify(data.user.id, {
                                name: data.user.person.fullName,
                                email: data.user.email,
                                created_at: data.user.createdAt,
                                company: {
                                    id: data.user.account.id, // Required, used to identify the company
                                    name: data.user.account.name,
                                    created_at: data.user.account.createdAt
                                }
                                // Additional user properties.
                                // is_trial: {{ request.user.is_trial }},
                                // plan: "{{ request.user.plan }}"
                            });

                            return data;
                        }).then(function (data) {
                            console.log('finished refreshing');
                            $rootScope.tokenRefreshing = false;

                            return data;
                        }).then(function (data) {
                            //re run request(s)
                            retryRequest(rejection.config, deferred);
                        }).catch(function () {
                            redirectToLogin();
                        });
                    } else {
                        console.log('pausing retried request for 2 seconds');
                        $timeout(function(){
                            console.log('resuming retried request');

                            //re run request(s)
                            retryRequest(rejection.config, deferred);
                        }, 2000);
                    }


                    return deferred.promise;

                } else {
                    return $q.reject(rejection);
                }
            }
        };
    });
