'use strict';

angular.module('scswebappApp')
    .directive('userListModal', function ($rootScope) {

        return {
            templateUrl: "scripts/directives/modals/user-list-modal/user-list-modal.html",
            restrict: 'E',
            scope: {
                'multiSelect': '=?',
                'title': '=?',
                'control': '=',
                'tableIdentifier': '@?'
            },
            link: {
                pre: function preLink(scope, element, attrs) {

                    if (!scope.multiSelect) {
                        scope.multiSelect = false;
                    }

                    if (!scope.tableIdentifier) {
                        scope.tableIdentifier = 'UserListModal';
                    }

                    $rootScope.$on('itemSelected' + scope.tableIdentifier, function () {
                        scope.control.close();
                    });
                }
            }
        };
    });
