'use strict';

/**
 * @ngdoc directive
 * @name scswebappApp.directive:alerts
 * @description
 * # alerts
 * <div toggle-class="my-class"></div>
 */
angular.module('scswebappApp')
    .directive('toggleClass', function () {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                element.bind('click', function() {
                    element.toggleClass(attrs.toggleClass);
                });
            }
        };
    });
