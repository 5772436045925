import {MediaDirective} from "@generic/media.directive";

(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:RateBuildUpCtrl
     * @description
     * # RateBuildUpCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('RateBuildUpCtrl', function (
            $scope,
            $rootScope,
            $route,
            $routeParams,
            $filter,
            $q,
            $location,
            $anchorScroll,
            UNITSTEXT,
            assessment,
            product,
            price,
            supplier,
            $document,
            region,
            $locale,
            assessmentProduct,
            $timeout,
            quote,
            PermissionStrategies,
            PermPermissionStore,
            subcontractor,
            supplyAndFitPrice,
            RateBuildUpProduct,
            $window,
            nxRbuIssueService
        ) {
            $scope.PermissionStrategies = PermissionStrategies;
            $scope.assessmentId = $routeParams.id;

            $scope.currencySymbol = $locale.NUMBER_FORMATS.CURRENCY_SYM;

            $scope.assessmentProducts = [];
            $scope.assessmentProductValues = {};
            $scope.showAssessmentProductValues = true;
            $scope.setShowAssessmentProductValues = (value) => $scope.showAssessmentProductValues = value;
            $scope.accordion = [];
            $scope.productMenuAssessmentProductId = null;

            $scope.swapProductForm = {conversionFactor: 1};
            $scope.moveComponentForm = {after: 0};
            $scope.productTemplateForm = {};
            $scope.productTemplate = {};

            $scope.filters = {};

            $scope.swapProduct = false;
            $scope.createProduct = false;

            $scope.activeProduct = null;

            $scope.isHub = $route.current.$$route.isHub;
            $scope.hasWritePermission = PermPermissionStore.hasPermissionDefinition($scope.isHub ?
                'canWriteProjectHubPackageAssessments' : 'canWriteProjectsOffsiteAssessments');

            getAssessment();

            // Get assessment details
            function getAssessment() {
                assessment.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.setData(data);
                    return data;
                }).then(function (data) {
                    if (data.snapshotOf) {
                        $scope.hasWritePermission = false;
                    }
                    $scope.reloadAllProducts();
                });
            }

            // This sets the assessment object and id's for navigation
            $scope.setData = function (data) {
                $scope.packageId = data.projectWorkPackage.id;
                // Set project id for navigation
                $rootScope.projectId = $scope.projectId = data.projectWorkPackage.project.id;
                // Mark as complete button text
                if ($scope.isHub && data.rateBuildUpCompleted) {
                    $scope.buttonText = 'Ready For Site';
                } else if ($scope.isHub && !data.rateBuildUpCompleted) {
                    $scope.buttonText = 'Mark Ready For Site';
                } else if (!$scope.isHub && data.rateBuildUpCompleted) {
                    $scope.buttonText = 'Complete';
                } else {
                    $scope.buttonText = 'Mark as complete';
                }
                $scope.assessment = data;
            };

            $scope.clearLoadedProducts = function (exceptForProduct) {
                angular.forEach($scope.assessmentProducts, function (value) {
                    if (!exceptForProduct || exceptForProduct.id !== value.id) {
                        value.extraLoaded = false;
                    }
                });
                angular.forEach($scope.accordion, function (value, key) {
                    if (!exceptForProduct || exceptForProduct.id !== key) {
                        $scope.accordion[key] = false;
                    }
                });
                $scope.busy = false;
            };
            $scope.$on('clearLoadedProducts', function (event) {
                $scope.clearLoadedProducts();
            });

            $scope.updateAssessmentStatus = function(){
                // Mark RBU as started
                if (!$scope.assessment.rateBuildUpStarted && !$scope.assessment.snapshotComment) {
                    assessment.progressSection({
                        id: $scope.assessment.id,
                        section: 'rate-buildup',
                        transition: 'start'
                    });
                };
            };

            /**
             * Update all profit and overheads
             * */
            $scope.global = {};
            $scope.updateAllProfitOverhead = function () {
                if ($scope.global && $scope.global.globalProfitPercentage !== "undefined") {
                    var percentages = {
                        percentage: $scope.global.globalProfitPercentage,
                    };
                    assessment.blanketProfit({id: $routeParams.id}, percentages).$promise.then(function () {
                        $scope.returnToPageOne();
                        nxRbuIssueService.fetchRbuIssues($scope.assessment);
                    });
                    $scope.updateAssessmentStatus();
                }
            };

            var textTimeout;

            function setTextTimeout() {
                textTimeout = $timeout(function () {
                    $scope.returnToPageOne();
                }, 750);
            }

            $scope.productMenuVisibilityChanged = function(product, visibility) {
                if (visibility) {
                    $scope.productMenuAssessmentProductId = product.id;
                } else {
                    $scope.productMenuAssessmentProductId = null;
                }
            };

            $scope.productSearch = function () {
                $timeout.cancel(textTimeout);
                setTextTimeout();
            };
            $scope.clearFilters = function () {
                console.log('Reset');
                $scope.filters = {};
                $scope.busy = false;
                $scope.returnToPageOne();
            };
            $scope.filterByMaterial = function (material) {
                $scope.filters.containsMaterial = material;
                $scope.returnToPageOne();
            };
            $scope.filterByActivity = function (activity) {
                $scope.filters.containsActivity = activity;
                $scope.returnToPageOne();
            };

            // Change pricing mode
            $scope.blanketPricing = {mode: ''};
            $scope.doBlanketPricingMode = function (mode) {
                var params = {id: $scope.assessment.id, mode: mode};
                assessment.blanketPricingMode(params).$promise.then(function () {
                    $scope.returnToPageOne();
                });
            };

            /**
             * Loads the next block of assessment products
             */
            $scope.busy = false;
            $scope.returnToPageOne = function () {
                $scope.clearLoadedProducts();
                $scope.assessmentProducts = [];
                $scope.gotoPage(1);
            };
            $scope.$on('rbuReturnToPageOne', $scope.returnToPageOne);
            $scope.$on('rbuFilterBy', function (event, filters) {
                console.log('rbuFilterBy');
                console.log(filters);
                $scope.filters = filters;
                $scope.returnToPageOne();
                //$scope.filters = {};
            });
            $scope.handleIssueSelection = function (issue) {
                $scope.filters = {issueCode: issue.code, issueSeverity: issue.severity};
                $scope.returnToPageOne();
            }
            $scope.insertProductsIntoList = function (aps) {
                angular.forEach(aps, function (newAp) {
                    var onPage = false;
                    angular.forEach($scope.assessmentProducts, function (oldAp, index) {
                        if (oldAp.id === newAp.id) {
                            onPage = true;
                            newAp.extraLoaded = false;
                            $scope.assessmentProducts[index] = newAp;
                            $scope.accordion[newAp.id] = false;
                        }
                    });
                    if (!onPage) {
                        $scope.assessmentProducts.push(newAp);
                    }
                });
            };
            const boqItemsLimit = 30;  // The maximum supported by the assessment.billOfQuantities request
            $scope.loadProductValues = (projectId, assessmentId, page, maxIndexToLoad) => {
                // The page provided here is the assessment products request page which has a different
                // hardcoded upper limit on the number of items returned than the BoQ request page
                let boqPage = Math.floor(((page - 1) * $scope._pagination.itemsPerPage) / boqItemsLimit) + 1;
                let maxIndexLoaded = (page - 1) * boqItemsLimit;
                const completion = (itemsLoaded) => {
                    maxIndexLoaded += itemsLoaded;
                    if (maxIndexLoaded < maxIndexToLoad) {
                        boqPage += 1;
                        $scope.loadProductValuesForBoQPage(
                            projectId,
                            assessmentId,
                            boqPage,
                            boqItemsLimit,  // We can't specify exactly how many pages to load as otherwise window size not calculated correctly
                            completion);
                    }
                };
                $scope.loadProductValuesForBoQPage(projectId, assessmentId, boqPage, boqItemsLimit, completion);
            };
            $scope.loadProductValuesForBoQPage = (projectId, assessmentId, page, itemsToLoad, completion) => {
                const boqRequest = {
                    projectId: projectId,
                    id: assessmentId,
                    yPage: page,
                    yLimit: itemsToLoad
                };

                assessment.billOfQuantities(boqRequest, data => {
                    for (const row of data.rows) {
                        // TODO: Make sure passing the correct values here depending
                        // on assessment status
                        $scope.assessmentProductValues[row.id] = {
                            quantity: row.quantityRequired,
                            quantityUnit: row.quantityUnit,
                            value: row.valueRequired
                        };
                    }

                    completion(data.rows.length);
                });
            };
            $scope.gotoPage = function (gotoPage, evenIfBusy = false) {
                if (($scope.busy && !evenIfBusy) || gotoPage < 1) return;

                $scope.busy = true;

                var req = {
                    assessment: $routeParams.id,
                    limit: $scope._pagination.itemsPerPage,
                    page: gotoPage,
                    sort: 'assessmentOrder',
                    groups: 'products_externalLinks,prices_view'
                };
                angular.forEach($scope.filters, function (v, k) {
                    req[k] = typeof v === 'object' ? v.id : v;
                });
                if (!req.assessment) {
                    console.log('no assessment');
                    return;
                }

                assessment.getAssessmentProductsList(req, function (data, headers) {
                    $scope.assessmentProducts = [];
                    $scope.insertProductsIntoList(data);

                    angular.forEach($scope.assessmentProducts, function (value) {
                        value.extraLoaded = false;
                    });
                    $scope.busy = false;

                    // Handle pagination
                    const itemsPerPage = $scope._pagination.itemsPerPage;
                    const objectCount = headers()['x-total-count'];
                    const pageFirstObject = (gotoPage - 1) * itemsPerPage + 1
                    $scope._pagination = {
                        itemsPerPage: itemsPerPage,
                        currentPage: gotoPage,
                        objectCount: objectCount,
                        numPages: Math.ceil(objectCount / itemsPerPage),
                        pageFirstObject: pageFirstObject,
                        pageLastObject: pageFirstObject + data.length - 1,
                    };

                    if ($scope.assessment) {
                        $scope.loadProductValues(
                            $scope.assessment.projectWorkPackage.project.id,
                            $routeParams.id,
                            gotoPage,
                            gotoPage * data.length);
                    }
                });
            };
            $scope.reloadAllProducts = function () {
                $scope.gotoPage($scope._pagination.currentPage, true);
                $scope.gotoPage($scope._pagination.currentPage);
                nxRbuIssueService.fetchRbuIssues($scope.assessment);
                $scope.$broadcast('rbuGetValidationMessages');
            };
            $scope.$on('rbuReloadAllProducts', $scope.reloadAllProducts);

            /**
             * If a product is not loaded, load it
             *
             * @param assessmentProduct
             * @param index
             * @returns Promise
             */
            $scope.ensureProductFullyLoaded = function (assessmentProduct, index) {
                return assessment.getAssessmentProduct({
                    id: assessmentProduct.id,
                    groups: ['products_externalLinks', 'prices_view']
                }).$promise.then(function (data) {
                    $scope.assessmentProducts[index] = data;
                    $scope.assessmentProducts[index].extraLoaded = true;
                });
            };

            /**
             * shows/hides the contents of the assessment product, ensuring that it is fully loaded first.
             *
             * @param assessmentProductId
             */
            $scope.accordionShowHide = function (assessmentProductId, index) {
                $scope.ensureProductFullyLoaded($scope.assessmentProducts[index], index).then(function () {
                    $scope.accordion[assessmentProductId] = !$scope.accordion[assessmentProductId];
                });
            };

            $scope.showBillOfQuantities = function (ap) {
                $scope.boqForcedParams = {id: $scope.assessment.id, assessmentProduct: ap.id};
                $scope.boqModal.onClose = function() {
                    $scope.reloadAllProducts();
                };
                $scope.boqModal.open();
            };

            /**
             * Shows the select product modal.
             * only sets up the product table when the button is clicked for the first time
             */
            $scope.showSelectProductModal = function () {
                RateBuildUpProduct.showSelectProductModal($scope);
            };

            /**
             *
             */
            $scope.showReplaceProductModal = function (assessmentProduct, index) {
                RateBuildUpProduct.showReplaceProductModal($scope, assessmentProduct, index);
            };

            $scope.showAddProductModal = function () {
                $scope.swapProduct = false;
                $scope.showSelectProductModal();
            };

            /**
             * Closes the select product modal.
             */
            $scope.closeSelectProductModal = function () {
                RateBuildUpProduct.closeSelectProductModal($scope);
            };

            /**
             * Closes all modals. just to be safe.
             */
            $scope.closeAllModals = function () {
                $scope.closeCreateProductModal();
                $scope.closeSelectProductModal();
            };

            /**
             * Adds a product to the rate buildup by POSTing to /assessmentproducts.
             * Called by the select product modal.
             *
             * @param id
             */
            $scope.addProduct = function (id) {
                $scope.busy = true;
                var data;

                data = {
                    'assessment': $scope.assessmentId,
                    'originalProduct': id
                };

                assessment.addAssessmentProducts({}, data, function (data) {
                    $scope.assessmentProducts.push(data);
                    $scope.ensureProductFullyLoaded(data, $scope.assessmentProducts.length - 1).then(function() {
                        $scope.busy = false;
                        getAssessment();
                        $scope.updateAssessmentStatus();
                    });
                }, function () {
                    $scope.busy = false;
                });
            };

            /**
             * Replaces one product with another using the 'swap' endpoint.
             *
             * @param assessmentProduct
             * @param id
             *
             */
            $scope.replaceProduct = function (product, id, conversionFactor, name) {
                RateBuildUpProduct.replaceProduct($scope, assessment, product, id, conversionFactor, name, (index, data) => {
                    $scope.reloadAllProducts();
                });
            };

            /*
             * Supply & fit conversion
             */
            $scope.makeSupplyAndFit = function (product) {
                $scope.confirmationQuestion = 'Convert ' + product.name + ' to supply & fit?';
                $scope.confirmationConfirm = function () {
                    assessmentProduct.patch({id: product.id, pricingMode: 'sf'}).$promise.then(function () {
                        $scope.confirmModal.close();
                        $scope.reloadProduct(product);
                        $scope.updateAssessmentStatus();
                    });
                };
                $scope.confirmModal.open();
            };
            $scope.unmakeSupplyAndFit = function (product) {
                $scope.confirmationQuestion = 'Return ' + product.name + ' to materials & labour?';
                $scope.confirmationConfirm = function () {
                    assessmentProduct.patch({id: product.id, pricingMode: 'ml'}).$promise.then(function () {
                        $scope.confirmModal.close();
                        $scope.reloadProduct(product);
                        $scope.updateAssessmentStatus();
                    });
                };
                $scope.confirmModal.open();
            };

            /**
             * Reloads a product from the webservice.
             * Needs to be called to get any recalculated totals after values are changed
             *
             * @param assessmentProduct
             * @param open
             * @param scrollTo
             */
            $scope.reloadProduct = function (assessmentProduct, open, scrollTo) {
                open = open === undefined ? false : open;
                scrollTo = scrollTo === undefined ? false : scrollTo;
                assessment.getAssessmentProduct({
                    id: assessmentProduct.id,
                    sort: 'assessmentOrder',
                    groups: ['products_externalLinks', 'prices_view']
                }).$promise.then(function (data) {
                    if (data.archivedAt) {
                        const index = $scope.assessmentProducts.findIndex(p => p.id === data.id);
                        if (index !== -1) {
                            $scope.assessmentProducts.splice(index, 1);
                        }
                    } else {
                        angular.forEach($scope.assessmentProducts, function (value, key) {
                            if (value.id === data.id) {
                                data.extraLoaded = true;
                                $scope.assessmentProducts[key] = data;
                                if (open) {
                                    $scope.accordion[assessmentProduct.id] = true;
                                    if (!scrollTo) {
                                        $scope.scrollToProduct(assessmentProduct.id);
                                    } else {
                                        console.log('scrolling to ' + scrollTo);
                                        setTimeout(function () {
                                            $window.scrollTo(0, scrollTo);
                                        }, 100);
                                    }
                                }
                            }
                        });
                    }
                });
                console.log('making riService call...', nxRbuIssueService, $scope.assessment);
                nxRbuIssueService.fetchRbuIssues($scope.assessment);
            };

            /**
             * Removes a product from the rate build up
             *
             * @param assessmentProduct
             */
            $scope.removeProduct = function (assessmentProduct, $index) {

                assessment.quantityInTakeoff({id: assessmentProduct.id}).$promise.then(function (data) {
                    if(data.hasQuantity) {
                        if (confirm(`Are you sure you want to delete this product? This product contains quantities in the bill that have been measured from takeoff. If you delete this product you will also delete all that data.`)) {
                            assessment.deleteAssessmentProduct({id: assessmentProduct.id}).$promise.then(function () {
                                $scope.assessmentProducts.splice($index, 1);
                                getAssessment();
                                $scope.updateAssessmentStatus();
                            });
                        }
                    } else {
                        if (confirm(`Are you sure you want to delete this product?`)) {
                            assessment.deleteAssessmentProduct({id: assessmentProduct.id}).$promise.then(function () {
                                $scope.assessmentProducts.splice($index, 1);
                                getAssessment();
                                $scope.updateAssessmentStatus();
                            });
                        }
                    }
                });


            };

            /**
             * formats a numeric value for use as a price
             *
             * @param price
             * @returns {*}
             */
            $scope.formatPrice = function (price) {
                return $filter('currency')(price, $scope.currencySymbol, 2);
            };

            /* Faux select */
            $scope.toggleSelect = function (assessmentMaterialId) {
                $scope.openSelect = ($scope.openSelect === assessmentMaterialId) ? null : assessmentMaterialId;
            };

            /**
             * scrolls to an assessment product
             * @param assessmentProductId
             */
            $scope.scrollToProduct = function (assessmentProductId) {
                var cleanNoReloadOnScroll = $scope.$on('$locationChangeStart', function (e) {
                    cleanNoReloadOnScroll();
                    e.preventDefault();
                    $location.hash('assessment-product-' + assessmentProductId);
                    $anchorScroll();
                });
            };

            /**
             * Watch for a selected product event
             * @type {*|(function())}
             */
            $scope.onSelectedProduct = function (product) {
                if (!$scope.swapProduct) {
                    $scope.addProduct(product.id);
                } else {
                    RateBuildUpProduct.tryReplaceProduct($scope, product);
                }
                $scope.selectProduct = false;
            };

            $scope.replaceProductWithUnitMismatch = function() {
                RateBuildUpProduct.replaceWithUnitMismatch($scope);
            };

            /* Mark as complete */
            $scope.markAsComplete = function () {
                assessment.checkAssessmentRbuValid({id: $routeParams.id}).$promise.then(function (data) {
                    if (data.valid) {
                        assessment.progressSection({
                            id: $scope.assessment.id,
                            section: 'rate-buildup',
                            transition: 'complete'
                        }).$promise.then(function (data) {
                            getAssessment();
                        })
                    } else {
                        $rootScope.$broadcast('showOrNotification', 'Please complete fields for all products before marking as complete', true);
                    }
                });
            };

            /* UI Sortable options */
            $scope.sortableOptions = {
                handle: '.rbu-accordion-icon',
                start: function (e, ui) {
                    $scope.dragStartPosition = ui.item.index();
                },
                stop: function (e, ui) {
                    var newPosition = ui.item.index();
                    var previousPosition = ui.item.index() - 1;
                    var productId = $scope.assessmentProducts[newPosition].id;

                    if (newPosition != $scope.dragStartPosition && newPosition === 0) {
                        assessment.changeOrder({
                            movingProductId: productId,
                            referenceProductId: 0
                        }).$promise.then(function (data) {
                            $scope.assessmentProducts[newPosition].assessmentOrder = data.assessmentOrder;
                        })
                    } else if (newPosition != $scope.dragStartPosition) {
                        assessment.changeOrder({
                            movingProductId: productId,
                            referenceProductId: $scope.assessmentProducts[previousPosition].id
                        }).$promise.then(function (data) {
                            $scope.assessmentProducts[newPosition].assessmentOrder = data.assessmentOrder;
                        })
                    }
                },
                axis: 'y'
            };

            /* Rename Modal */
            $scope.showRenameModal = function (assessmentProduct, index) {
                $scope.newName = {
                    old: assessmentProduct,
                    index: index,
                    name: assessmentProduct.name,
                    description: assessmentProduct.description,
                    pricingNotes: assessmentProduct.pricingNotes
                };
                $scope.renameProductModal = true;
            };
            $scope.closeRenameModal = function () {
                $scope.resetRename();
            };
            $scope.renameProduct = function () {
                assessment.patchAssessmentProducts({id: $scope.newName.old.id},
                    {
                        name: $scope.newName.name,
                        description: $scope.newName.description,
                        pricingNotes: $scope.newName.pricingNotes
                    }).$promise.then(function () {
                    $scope.assessmentProducts[$scope.newName.index].name = $scope.newName.name;
                    $scope.assessmentProducts[$scope.newName.index].description = $scope.newName.description;
                    $scope.assessmentProducts[$scope.newName.index].pricingNotes = $scope.newName.pricingNotes;
                    $scope.resetRename();
                    $scope.updateAssessmentStatus();
                }).catch(function () {
                    $scope.resetRename();
                })
            };
            $scope.resetRename = function () {
                $scope.renameProductModal = false;
                $scope.newName = null;
            };

            // Editing a product
            $scope.showEditProductModal = function(index, assessmentProduct, estimateValues) {
                if (MediaDirective.isMobile()) {
                    window.alert('To edit a product you need a larger browser window. ' +
                        'If you are on a desktop/laptop computer resize the browser window ' +
                        'to make it as large as possible. If you are on a phone ' +
                        'switch to a desktop, laptop or tablet.');
                    return;
                }

                $scope.ensureProductFullyLoaded(assessmentProduct, index).then(function () {
                    $scope.editProductModal.assessmentProductIndex = index;
                    $scope.editProductModal.onProductReplaced = function(newProduct) {
                        $scope.assessmentProducts[index] = newProduct;
                    };
                    $scope.editProductModal.onClose = function() {
                        $scope.reloadAllProducts();
                    };
                    $scope.editProductModal.open({
                        assessmentProducts: $scope.assessmentProducts,
                        assessmentProductIndex: index,
                        assessmentProduct: $scope.assessmentProducts[index],
                        estimateValues: estimateValues
                    });
                });
            };

            // Create new product template
            $scope.showCreateProductModal = function () {
                RateBuildUpProduct.showCreateProductModal($scope, $rootScope);
            };
            $scope.$on('productSaved', function (event, item) {
                $scope.createProductTemplateModal.close();
                $scope.onSelectedProduct(item);
            });

            function getWatchers(root) {
                root = angular.element(root || document.documentElement);
                var watcherCount = 0;

                function getElemWatchers(element) {
                    var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
                    var scopeWatchers = getWatchersFromScope(element.data().$scope);
                    var watchers = scopeWatchers.concat(isolateWatchers);
                    angular.forEach(element.children(), function (childElement) {
                        watchers = watchers.concat(getElemWatchers(angular.element(childElement)));
                    });
                    return watchers;
                }

                function getWatchersFromScope(scope) {
                    if (scope) {
                        return scope.$$watchers || [];
                    } else {
                        return [];
                    }
                }

                return getElemWatchers(root);
            }

            getWatchers().length

            // Kick things off by loading the first page
            $scope._pagination = {
                itemsPerPage: 50,
                numPages: 1,
                currentPage: 1,
                objectCount: 0,
                pageFirstObject: 0,
                pageLastObject: 0,
            };
            $scope.returnToPageOne();
        });
})();
