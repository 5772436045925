import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Assessment} from "../chalkstring-api/services/assessment.service";
import {SimpleModalService} from "ngx-simple-modal";
import {MoveProductModalComponent} from "./modals/move-product-modal.component";
import {AssessmentProduct} from "../chalkstring-api/services/assessment-product.service";

@Component({
    template: `
        <a href="" (click)="openModal(); false">
            <i class="icon-move"></i> Move product
        </a>
    `,
    selector: 'assessment-status-button'
})
export class MoveProductLinkComponent
{
    @Input() assessment: Assessment;
    @Input() product: AssessmentProduct;
    @Output() moved = new EventEmitter<boolean>();

    constructor(private _simpleModalService: SimpleModalService)
    {
    }

    openModal()
    {
        this._simpleModalService.addModal(MoveProductModalComponent, {
            assessment: this.assessment,
            product: this.product
        }).subscribe(res => {
            if (res) {
                this.moved.emit(res);
            }
        });
    }
}
