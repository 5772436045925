(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name scswebappApp.controller:ProductCtrl
     * @description
     * # ProductCtrl
     * Controller of the scswebappApp
     */
    angular.module('scswebappApp')
        .controller('ProductCtrl', function ($scope, $routeParams, $rootScope, $location, product, UNITSTEXT, $route, assessment, supplyFitQuote) {
            if ($location.path() === '/new-product') {
                $scope.title = 'New product template';
            } else if ($location.path().startsWith('/edit-product')) {
                $scope.title = 'Edit product template';
            }

            // Get product data if edit or view routes
            // else show table of products if list page
            // else isNew or isEdit page
            if ($routeParams.id) {
                product.get({id: $routeParams.id}).$promise.then(function (data) {
                    $scope.product = data;
                    $scope.oldQuantityUnit = data.quantityUnit;
                });
                product.getPreviousAssessmentProducts({
                    id: $routeParams.id,
                    groups: 'projectDetails,assessmentItem_assessment'
                }).$promise.then(function (data) {
                    $scope.assessmentProducts = data;
                });

                $scope.accordion = false;
                $scope.accordionToggle = function () {
                    $scope.accordion = !$scope.accordion;
                };

                supplyFitQuote.quoteRatesForProduct({productId: $routeParams.id}).$promise.then(function (data) {
                    $scope.supplyAndFitQuotes = data;
                });
            }

        });
})();
