(function () {
    'use strict';
    angular.module('scswebappApp')
        .factory('groupCompany', ['Resource', 'ENV', function ($resource, ENV) {
            return $resource(
                ENV.apiEndpoint + '/groupcompanies/:id', {id: '@id'}, {
                    customSave: {
                        transformRequest: function (data) {
                            var newData = angular.fromJson(data);
                            if ((typeof newData.companyLogo === "object")) {
                                newData.companyLogo = {file: newData.companyLogo ? newData.companyLogo.id : null};
                            }

                            var companyData = {
                                'fullName': newData.fullName,
                                'companyRegistrationNumber': newData.companyRegistrationNumber,
                                'companyVATNumber': newData.companyVATNumber,
                                'emailAddresses': newData.emailAddresses,
                                'telephoneNumbers': newData.telephoneNumbers,
                                'addresses': newData.addresses,
                                'parent': data.parent,
                                'companyLogo': newData.companyLogo
                            };
                            angular.forEach(companyData.addresses, function (value, key) {
                                delete companyData.addresses[key].id;
                                delete companyData.addresses[key].contact;
                                delete companyData.addresses[key].latitude;
                                delete companyData.addresses[key].longitude;
                            });
                            angular.forEach(companyData.telephoneNumbers, function (value, key) {
                                delete companyData.telephoneNumbers[key].id;
                                delete companyData.telephoneNumbers[key].label;
                            });
                            angular.forEach(companyData.emailAddresses, function (value, key) {
                                delete companyData.emailAddresses[key].id;
                                delete companyData.emailAddresses[key].label;
                            });
                            return angular.toJson(companyData);
                        }
                    },
                    updateCompany: {
                        method: 'PATCH'
                    },
                    update: {
                        method: 'PUT',
                    }
                },
                'fullName'  // default sort for query
            );
        }]);
})();
