'use strict';

angular.module('scswebappApp')
       .factory('DateUtils', function($filter) {
           return {
               // Used as a workaround for Safari parsing dates differently to other browsers, e.g. new Date('2020-10-19 12:00:00') would fail on Safari
               // whereas new Date('2020-10-19T12:00:00') works on all browsers tested
               toServerDateTime: function(dateArg) {
                   const date = (dateArg instanceof Date) ? dateArg : new Date(dateArg.replace(' ', 'T'));
                   return $filter('date')(date, 'yyyy-MM-dd HH:mm:ss');
               }
           };
       });
