(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name scswebappApp.project
     * @description
     * # project
     * Factory in the scswebappApp.
     */
    angular.module('scswebappApp')
        .service('project', ['Resource', 'ENV', function ($resource, ENV) {

            function formatData(data, update) {
                var newObj = {
                    'name': data.name,
                    'scsCompanyGroup': data.scsCompanyGroup,
                    'client': data.client[0].id,
                    'enquiryDate': data.enquiryDate,
                    'tenderDueDate': data.tenderDueDate,
                    'siteDeliveryAddress': data.siteDeliveryAddress,
                    'retentionPercentage': data.retentionPercentage === undefined ?  null : data.retentionPercentage,
                    'accountRef': data.accountRef ? data.accountRef : null,
                    'projectWorkPackages': [],
                    'drawings': [],
                    'documents': []
                };

                if (data.siteDeliveryAddress) {
                    newObj.siteDeliveryAddress = data.siteDeliveryAddress;
                }

                // Format projectWorkPackages
                angular.forEach(data.projectWorkPackages, function (value, key) {
                    if (value !== null) {
                        // a hack to resolve CORE-5621 LIVE - Error when trying to add a work package to a project
                        if (value.workPackage) {
                            newObj.projectWorkPackages[key] = {'workPackage': value.workPackage.id};
                        } else {
                            newObj.projectWorkPackages[key] = {'workPackage': value.id};
                        }
                    }
                });
                angular.forEach(newObj.projectWorkPackages, function (value, key) {
                    if (value ===  null) {
                        delete newObj.projectWorkPackages[key];
                    }
                });

                // Format drawings
                angular.forEach(data.drawingsList, function (drawing) {
                    newObj.drawings.push(drawing.id);
                });

                // Format documents
                angular.forEach(data.documentsList, function (document) {
                    newObj.documents.push(document.id);
                });

                if (newObj.siteDeliveryAddress) {
                    if (update) {
                        delete newObj.projectWorkPackages;
                    }
                    delete (newObj.siteDeliveryAddress.archivedAt);
                    delete (newObj.siteDeliveryAddress.createdAt);
                    delete (newObj.siteDeliveryAddress.updatedAt);
                    delete (newObj.siteDeliveryAddress.id);
                    delete (newObj.siteDeliveryAddress.latitude);
                    delete (newObj.siteDeliveryAddress.longitude);
                    delete (newObj.siteDeliveryAddress.contact);
                }
                return newObj;
            }

            return $resource(
                ENV.apiEndpoint + '/projects/:id', {
                    id: '@id',
                    onlygroups: '@onlygroups',
                    subcontractor: '@subcontractor',
                    operative: '@operative'
                }, {
                    headers: {
                        'Access-Control-Expose-Headers': 'X-Total-Count'
                    },
                    generateQuantityBill: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:id/generate-quantity-bill'
                    },
                    submitTender: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projects/:id/tender-packages'
                    },
                    getProjectPackages: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projectpackages/:id'
                    },
                    patchProjectPackages: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/projects/:id/projectpackages'
                    },
                    create: {
                        method: 'POST',
                        transformRequest: function (data) {
                            return angular.toJson(formatData(data));
                        }
                    },
                    update: {
                        method: 'PATCH',
                        transformRequest: function (data) {
                            return angular.toJson(formatData(data, true));
                        }
                    },
                    updateDates: {
                        method: 'PATCH'
                    },
                    addWorkPackage: {
                        method: 'POST',
                        url: ENV.apiEndpoint + '/projectpackages'
                    },
                    deleteWorkPackage: {
                        method: 'DELETE',
                        url: ENV.apiEndpoint + '/projectpackages/:id'
                    },
                    updateWorkPackage: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projectpackages/:id'
                    },

                    archive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:id/archive'
                    },
                    unarchive: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:id/unarchive'
                    },
                    overview: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/overview'
                    },
                    commercialData: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/commercial-data'
                    },
                    roleUsers: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/role-users'
                    },
                    patchRoleUsers: {
                        method: "PATCH",
                        url: ENV.apiEndpoint + '/projects/:id/role-users'
                    },
                    getLiveAssessments: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/live-assessments',
                        isArray: true
                    },
                    getOnSite: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/on-site',
                        isArray: true
                    },
                    patchAdditionalProjectDetails: {
                        method: 'PATCH',
                        url: ENV.apiEndpoint + '/projects/:id',
                        transformRequest: function (pdata) {
                            var data = angular.fromJson(pdata);

                            if (typeof data.assignedTo !== undefined && data.assignedTo.length > 0) {
                                var assignedToUserIds = [];
                                angular.forEach(data.assignedTo, function (obj) {
                                    assignedToUserIds.push(obj.id);
                                });
                                data.assignedTo = assignedToUserIds;
                            }

                            data.responsible = data.responsible.length > 0 ? data.responsible[0].id : null;
                            data.status = data.status.length > 0 ? data.status[0].id : null;

                            return angular.toJson(data);
                        }
                    },
                    getProjectStatuses: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/projectStatuses',
                        isArray: true
                    },
                    getWorkPackageSummary: {
                        method: 'GET',
                        url: ENV.apiEndpoint + '/projects/:id/:workPackageId/workPackageSummary',
                    }
                },
                'name'  // default sort order for query
            );

        }]);
})();
