import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {
    Assessment,
    AssessmentEstimatesResponse,
    AssessmentService
} from "../chalkstring-api/services/assessment.service";
import {DecimalPipe} from "@angular/common";
import {MediaDirective} from "@generic/media.directive";

class EstimateCategory
{
    public accepted: number;
    public live: number;
    public variations: number;
    public postTenderContract: number;
    constructor(
        public title: string,
        private _res: AssessmentEstimatesResponse,
        private _estimateProperty: string,
        public total: boolean = false
    )
    {
        this.accepted = this._res.accepted ?
            (this._res.accepted[this._estimateProperty] ?? null) : null;
        this.live = this._res.live[this._estimateProperty] ?? null;
        this.variations = this._res.variations ?
            (this._res.variations[this._estimateProperty] ?? null) : null;
        this.postTenderContract = this._res.postTenderContract ?
            (this._res.postTenderContract[this._estimateProperty] ?? null) : null;
    }
}

@Component({
    template: require('./estimates.component.html'),
    selector: 'estimates',
    styles: [require('./estimates.component.scss')],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstimatesComponent implements OnInit, OnDestroy
{
    private _assessment: Assessment;
    @Input() set assessment(a: Assessment)
    {
        this._assessment = a;
        if (a) {
            this.fetchEstimateData();
        }
    }
    get assessment(): Assessment
    {
        return this._assessment;
    }

    private _onSite: boolean = false;
    @Input() set onSite (onSite: boolean)
    {
        this._onSite = onSite;
    }
    get onSite(): boolean
    {
        return this._onSite;
    }

    @Input() showHeader: boolean = true;
    desktopMode: boolean = true;
    accordion: any = {};
    estimates: AssessmentEstimatesResponse;
    mwCategories: EstimateCategory[] = [];
    mwOpen: boolean = false;
    fcCategories: EstimateCategory[] = [];
    fcOpen: boolean = false;
    ohpCategories: EstimateCategory[] = [];
    ohpOpen: boolean = false;
    totalCategories: EstimateCategory[] = [];
    totalOpen: boolean = false;
    mediaQueryList: any;

    /**
     * This listener is triggered whenever the window media query changes
     * @param event
     */
    mediaListener = event => {
        this.desktopMode = event.matches;
        this._change.detectChanges();
    };

    constructor(
        private _aService: AssessmentService,
        private _change: ChangeDetectorRef,
        private _numberPipe: DecimalPipe
    )
    {
    }

    ngOnInit(): void
    {
        this.mediaQueryList = window.matchMedia(MediaDirective.desktopQuery());
        this.desktopMode = this.mediaQueryList.matches;
        this.mediaListener(this.mediaQueryList);
        this.mediaQueryList.addEventListener('change', this.mediaListener);
    }

    ngOnDestroy(): void
    {
        this.mediaQueryList.removeEventListener('change', this.mediaListener);
    }

    handleEstimateResponse(res: AssessmentEstimatesResponse)
    {
        this.mwCategories = [
            new EstimateCategory('Supply & fit', res, 'supplyAndFitCost'),
            new EstimateCategory('Materials', res, 'materialCost'),
            new EstimateCategory('Wastage', res, 'materialWasteCost'),
            new EstimateCategory('Labour', res, 'productLabourCost'),
            new EstimateCategory('Contingencies', res, 'productContingencyCost'),
            new EstimateCategory('Inflation', res, 'productInflationCost'),
            new EstimateCategory('Total', res, 'measuredCostsSubtotal', true),
        ];
        this.fcCategories = [
            new EstimateCategory('Cost', res, 'fixedCostsCost'),
            new EstimateCategory('Profit', res, 'fixedCostsProfit'),
            new EstimateCategory('Total', res, 'fixedCostsBillPrice', true),
        ];
        this.ohpCategories = [
            new EstimateCategory('Measured costs overhead', res, 'measuredCostsOverhead'),
            new EstimateCategory('Measured costs profit', res, 'measuredCostsProfit'),
            new EstimateCategory('Fixed costs profit', res, 'fixedCostsProfit'),
            new EstimateCategory('Pre-adjustment subtotal', res, 'preAdjustmentSubtotal'),
            new EstimateCategory('Adjustment', res, 'adjustmentValue'),
            new EstimateCategory('Post adjustment OHP', res, 'postAdjustmentOHP', true),
        ];
        this.totalCategories = [
            new EstimateCategory('Tendered value', res, 'estimationTotal'),
            new EstimateCategory('Post-tender contract changes', res, 'estimationTotal'),
            new EstimateCategory('Post-tender remeasure', res, 'estimationTotal'),
            new EstimateCategory('Total', res, 'estimationTotal', true),
        ];
        this.estimates = res;
        this._change.detectChanges();
    }

    refreshEstimates()
    {
        this.fetchEstimateData();
    }

    pcOfAcceptedTotal(v: number): string
    {
        const pc = Math.round(v / this.estimates.accepted.estimationTotal * 100 * 100) / 100;
        return this._numberPipe.transform(pc, '.2') + '% of accepted total';
    }

    pcOfLiveTotal(v: number): string
    {
        const pc = Math.round(v / this.estimates.live.estimationTotal * 100 * 100) / 100;
        return this._numberPipe.transform(pc, '.2') + '% of ' + (this.onSite ? 'live ' : '') + 'total';
    }

    pcOfVariationsTotal(v: number): string
    {
        const pc = Math.round(v / this.estimates.variations.estimationTotal * 100 * 100) / 100;
        return this._numberPipe.transform(pc, '.2') + '% of variations total';
    }

    fetchEstimateData()
    {
        if (this.onSite) {
            this._aService.getLiveEstimates(this.assessment).subscribe(res => {
                this.handleEstimateResponse(res);
            });
        } else {
            this._aService.getEstimate(this.assessment).subscribe(res => {
                this.handleEstimateResponse({live: res.estimate});
            });
        }
    }

    colSpanFullWidth(): number
    {
        return this.onSite ? 4 : 2;
    }
}
